import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    Stack,
    StackDivider,
    Text,
    Textarea,
  } from '@chakra-ui/react'
  import * as React from 'react';
  import { Dropzone } from '../../core/components/forms/Dropzone';
  import ProfileUpdate from './Forms/Profile';
import ArchiveAll from './ArchiveAll';
import Mode from '../onboarding/Mode';
import Attributes from './Parameters';

  export default function ProfilePage(){

    return  (
    <Container
      py={{
        base: '4',
        md: '8',
      }}
      maxW='800px'
      m='auto'
    >

      <Heading size='smalltitle' pt={12} pb={8}>Profile</Heading>

      <ProfileUpdate />


      <Heading size='smalltitle' pt={12} pb={8}>Administration</Heading>
      <Mode />
      <ArchiveAll />

      <Heading size='smalltitle' pt={12} pb={8}>User Values</Heading>
      <Attributes />
    </Container>
  )

            }