import React, { useState, useEffect, useContext } from 'react';
import { useToast, Menu, MenuButton, MenuList, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuItem, color } from '@chakra-ui/react';
import API from "../../../core/utils/API";
import {MdAttachFile, MdAdd, MdAutoFixHigh,  MdFlag, MdInsertLink, MdWarning, MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility, MdPhone, MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdMail, MdContentCopy } from "react-icons/md";
import LoadingCircle from '../../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../../static/layout/Loading';
import Splash from '../../../static/layout/Splash';
import Layout from '../../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../../auth/SubscriptionWrapper';
import Modal from '../../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../../core/components/lists/List';
import pluralize from 'pluralize';
import { BsTags } from 'react-icons/bs';
import InputWrapper from '../../../core/components/forms/inputs/InputWrapper';
import { random } from 'sjcl';

import { set } from 'date-fns';

let sourceIcons = {
    'folder': MdFolderOpen,
    'website': MdInsertLink,
    'document': MdAttachFile,
}
let sourceIconsComponents = {
    'folder': <MdFolderOpen/>,
    'website': <MdInsertLink/>,
    'document': <MdAttachFile/>,
    undefined: <MdInsertLink/>
}

export default function Sources(props) {


    return (
        <Box>
            <Box p={4} px={6}>
                <Heading size='sm'>
                    Sources
                </Heading>
            </Box>
            {props.sources && props.sources.map((source, index) => {

                return (
                    <Box borderBottom='1px solid whitesmoke' p={4} px={6}>
                        <HStack>
                            <Icon as={sourceIcons[source.type] ? sourceIcons[source.type] : MdInsertLink} boxSize='20px'/>
                            <Text>
                                {source.name ? source.name : source}
                            </Text>
                        </HStack>
                    </Box>  
                )
            })}
            <Box p={4} px={6}>
                        <HStack
                            cursor='pointer'
                            role='group'
                            >
                            <Icon as={MdAdd}  color='' boxSize='20px' 
                                _groupHover={{
                                    color: 'blue.500'
                                }}
                                />
                            <Menu>
                                <MenuButton as={Text} rightIcon={<MdExpandCircleDown/>}
                                    colorScheme='blue'
                                    variant='ghost'
                                    fontSize='sm'
                                    _hover={{
                                        color: 'blue.500'
                                    }}
                                    _groupHover={{
                                        color: 'blue.500'
                                    }}
                                    >
                                    Add Source
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => props.onAddSource('folder')}>Folder</MenuItem>
                                    <MenuItem onClick={() => props.onAddSource('website')}>Website</MenuItem>
                                    <MenuItem onClick={() => props.onAddSource('document')}>Document</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
            </Box>
        </Box>
    )
}