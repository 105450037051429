import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Text, SimpleGrid, Flex, Button, Heading, Stack, Input, InputGroup, InputLeftAddon, InputRightAddon, InputRightElement, InputLeftElement, FormControl, FormLabel, FormErrorMessage, FormHelperText, Select, Checkbox, CheckboxGroup, HStack, VStack, Radio, RadioGroup, Textarea, Switch, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Progress, Spinner, useToast } from '@chakra-ui/react';
import API from '../../../core/utils/API';
import Modal from '../../../core/components/modal/Modal';
import FullPageModal from '../../../core/components/modal/FullPageModal';
import moment from 'moment';

export default function OriginalEdit(props) {

    function normaliseHours(time) {

        console.log("NORMALISE", time);
        time = time.toString();
        if (time === undefined || undefined === null) {
            return 0;
        }
        // replace . with :
        if (time.indexOf(".") > -1) {
            let decMin = time.split(".")[1] * 60;
            let decHour = time.split(".")[0];
            
            if (decMin < 10) {
                decMin = '0' + decMin;
            }
            if (decHour < 10) {
                decHour = '0' + decHour;
            }
            return decHour + ':' + decMin;

        }
        
        
        if (time == undefined || time == '' || time == '00:00' || time.indexOf(":") == -1) {
            return 0;
        }
        let hours = time.split(':')[0];
        let minutes = time.split(':')[1];
        // ensure format is HH:MM
        if (hours.length == 1) {
            hours = '0' + hours;
        }
        if (minutes.length == 1) {
            minutes = '0' + minutes;
        }
        return hours + ':' + minutes;
    }

    const [updated, setUpdated] = useState({});
    const [formMessage, setFormMessage] = useState('');

    const [data, setData] = useState({
        basicPay: props.originalSchedule.basicPay,
        weekendPercentage: props.originalSchedule.weekendPercentage,
        weekendPay: props.originalSchedule.weekendPay,
        weekendFrequency: props.originalSchedule.weekendFrequency,
        additionalPay: props.originalSchedule.additionalPay,
        additional: normaliseHours(props.originalSchedule.additional),
        enhancedPay: props.originalSchedule.enhancedPay,
        enhanced: normaliseHours(props.originalSchedule.enhanced),
        totalNonPensionablePay: props.originalSchedule.totalNonPensionablePay,
        totalPay: props.originalSchedule.totalPay,
        payPremia: props.schedule.payPremia,
        londonWeighting: props.schedule.londonWeighting,
        grade: props.schedule.grade,
        fte: props.schedule.fte,
        leave: props.schedule.leave,
        study: props.schedule.study,
        specialty: props.schedule.specialty,
        hee: props.schedule.hee,
        site: props.schedule.site,
        organisation: props.schedule.organisation,
        host: props.schedule.host,
        start: moment(props.schedule.start).format('YYYY-MM-DD'),
        end: moment(props.schedule.end).format('DD/MM/YYYY'),

    });

    console.log("ORIGINAL EDIT", props.originalSchedule, data);
    const [show, setShow] = useState(false);

    function updateData(key, value) {
        let newData = {...data};
        newData[key] = value;
        let newUpdated = {...updated};
        newUpdated[key] = value;
        setData(newData);
        setUpdated(newUpdated);
    }

    function saveData() {

        
        console.log("Submit Questions", props.uuid, updated);

        let url = 'schedules';
        let config = {
            params: {
                ...updated,
                uuid: props.uuid
            }
        };

        let resp = API.patchItems({url, config})
        .then(response => {
            console.log(response);
            setFormMessage("Details saved successfully");
            props.refreshSchedule();
            setShow(false);
        })
        .catch(error => {
            console.log(error);
            setFormMessage("There was an error saving the details");
        });


    }

    return (

        <>
            <Button variant='outline' size='sm' onClick={() => setShow(true)} colorScheme='blue' >
                Edit
            </Button>

            {show &&


                <Modal show={show}
                    title="Original Pay Details"
                    onClose={() => {setShow(false); props.refreshSchedule(); setFormMessage("");}}
                    maxW='1200px'
                    footer={
                        <>
                            <Button size='sm' colorScheme='blue' onClick={() => saveData()}>
                                Update
                            </Button>
                        </>
                    }
                    >
                    
                    <>
                    
                    {formMessage != '' &&
                                <Text color='red.500' fontSize='xs' mb={4}>
                                    {formMessage}
                                </Text>
                            }
            

                    <SimpleGrid minChildWidth='300px' spacing='40px' mb={4} pb={4}>

                        <Stack spacing={2}>
                            <Heading size='label'>
                                Basic Salary
                            </Heading>
                            <Text color='subtle' fontSize='xs'>
                                This is your basic salary before any additional pay or enhancements, there is a national pay scheme depending on your grade. This can be reduced if your average weekly hours are less than 40.
                            </Text>
                            <InputGroup>
                                <InputLeftAddon children='£' />
                                <Input type='number' placeholder='Basic Salary' onChange={(e) => updateData('basicPay', e.target.value)} value={data.basicPay} />
                            </InputGroup>
                        </Stack>
 
                        <Stack spacing={2}>

                                <Heading size='label'>
                                    Weekend Supplement
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    If you work any weekends then you will recieve an additional payment, proportional to the frequency of weekends across the schedule. There are percentage pay supplements specified in the national contract.
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='£' />
                                    <Input type='number' placeholder='Pay' onChange={(e) => updateData('weekendPay', e.target.value)} value={data.weekendPay} />
                                </InputGroup>

                                <HStack spacing={2}>
                                    <InputGroup>
                                        <InputLeftAddon children='1 in ' />
                                        <Input type='number' placeholder='Frequency' onChange={(e) => updateData('weekendFrequency', e.target.value)} value={data.weekendFrequency} />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input type='number' placeholder='Percentage' onChange={(e) => updateData('weekendPercentage', e.target.value)} value={data.weekendPercentage} />
                                        <InputRightAddon children='%' />
                                    </InputGroup>
                                </HStack>

                        </Stack>

                        <Stack spacing={2}>
                                <Heading size='label'>
                                    Additional Pay
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    Any work above 40 hours is paid in addition to your base salary, you are paid at your hourly rate for the average additional hours (normally rounded up to the nearest 15 minutes).
                                </Text>
                                <HStack spacing={2}>
                                    <InputGroup>
                                        <InputLeftAddon children='£' />
                                        <Input type='number' placeholder='Pay' onChange={(e) => updateData('additionalPay', e.target.value)} value={data.additionalPay} />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input type='time' placeholder='Enter your additional hours per week' onChange={(e) => updateData('additional', e.target.value)} value={data.additional} />
                                        <InputRightAddon children='hours' />
                                    </InputGroup>
                                </HStack>
                        </Stack>


                        <Stack spacing={2}>

                                <Heading size='label'>
                                    Enhanced Pay
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    You will be paid for your enhanced hours - night shifts and those after 9pm are paid at an enhanced rate, an additional 37% of the hourly rate you are paid for additional hours.<br/>
                                </Text>
                                <HStack spacing={2}>
                                    <InputGroup>
                                        <InputLeftAddon children='£' />
                                        <Input type='number' placeholder='Enter your enhanced pay' onChange={(e) => updateData('enhancedPay', e.target.value)} value={data.enhancedPay} />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input type='time' placeholder='Enter your enhanced hours per week' onChange={(e) => updateData('enhanced', e.target.value)} value={data.enhanced} />
                                        <InputRightAddon children='hours' />
                                    </InputGroup>
                                </HStack>
                            </Stack>


                            <Stack spacing={2}>

                                <Heading size='label'>
                                    Total Non-Pensionable Pay
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    This is the total of all the additional pay per year - weekend supplement, additional pay and enhanced pay. This does not include your basic salary. We can use this non-pensionable total to help error check.
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='£' />
                                    <Input type='number' placeholder='Enter your total non-pensionable pay' onChange={(e) => updateData('totalNonPensionablePay', e.target.value)} value={data.totalNonPensionablePay} />
                                </InputGroup>
                                
                            </Stack>


                            <Stack spacing={2}>

                                <Heading size='label'>
                                    Total Pay
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    This is the final average salary for the year, including your basic salary and all additional pay. Some NHS Trusts will include pay premia and London weighting in this amount, others do not.
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='£' />
                                    <Input type='number' placeholder='Enter your total pay' onChange={(e) => updateData('totalPay', e.target.value)} value={data.totalPay} />
                                </InputGroup>
                            </Stack>


                            <Stack spacing={2}>

                                <Heading size='label'>
                                    Pay Premia
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    Some specialty doctors will recieve a pay premia, this is a fixed amount per year. This may not be included in the total pay.
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='£' />
                                    <Select placeholder='Select your pay premia' onChange={(e) => updateData('payPremia', e.target.value)} value={data.payPremia}>
                                        <option value='0'>No pay premia</option>
                                        <option value='1000'>£1,000</option>
                                        <option value='2000'>£2,000</option>
                                        <option value='3000'>£3,000</option>
                                    </Select>
                                </InputGroup>

                            </Stack>

                            <Stack spacing={2}>
                                <Heading size='label'>
                                    London Weighting
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    If you work in London then you will recieve a London weighting, this is a fixed amount per year. This may not be included in the total pay.
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='£' />
                                    <Select placeholder='Select your London weighting' onChange={(e) => updateData('londonWeighting', e.target.value)} value={data.londonWeighting}>
                                        <option value='0'>No London weighting</option>
                                        <option value='2162'>£2162 (Inner)</option>
                                        <option value='527'>£527 (Outer)</option>
                                        <option value='149'>£149 (Fringe)</option>
                                    </Select>
                                </InputGroup>
                            </Stack>
    

                    </SimpleGrid>
                    </>
                </Modal>
            }

        </>

    )
}