import {Box, Wrap, Heading, Text}  from '@chakra-ui/react';
import {MdError} from 'react-icons/md';

export default function Error(props) {


    return (

        <Wrap {...props}>
            <MdError color='red'/>
            <Text color='red'>
                {props.message ?
                    props.message :
                    'No error message provided'
                }
                </Text>
        </Wrap>

    )
}