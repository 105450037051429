
import Hero from './Hero';
import Footer from '../layout/Footer';
export default function Features() {


    return (

        <>
        <Hero/>
        <Footer />
        </>
    )

}