import React, { useEffect } from 'react';
import { HStack, ButtonGroup, Wrap, Stack, Heading, Box, Input, Button, Text, Flex } from '@chakra-ui/react';
import { useState } from 'react';

import Splash from '../../static/layout/Splash';
import Registration from './Registration';
import Photo from './Photo';
import { Link as ReachLink} from 'react-router-dom';
import API from '../../core/utils/API';
import {useNavigate} from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { set } from 'date-fns';
import Generic from './Generic';
import  { Redirect } from 'react-router-dom';
import ImageManage from '../images/Manage';

let onBoardingGroups = [
    {
        name: 'Doctor',
        type: 'Healthcare Professional',        
    },
    {
        name: 'Nurse',
        type: 'Healthcare Professional',
    }, 
    {
        name: 'AHP',
        type: 'Healthcare Professional',
    },
    {
        name: 'Human Resources',
        type: 'Human Resources',
    },
    {
        name: 'Operations Staff',
        type: 'Operations Staff',
    }
];

export default function Onboarding(props) {

    const [step, setStep] = useState('Welcome');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [count, setCount] = useState(0);
    const [profession, setProfession] = useState('');
    const [mode, setMode] = useState('');
    const [originalMode, setOriginalMode] = useState('');


    const actuallyEnsuringRegistration = () => {
        API.getItems({url: 'users/ensureregistration'}).then((response) => {
            //console.log("Response from API: ")
            //console.log(response)
            getMe();

        }).catch((error) => {
            console.log("Error from API: ", error)

            if (count < 5) {
                ensureregistration();
            } else {
                setLoading(false);
            }
        })
    }
    const ensureregistration = () => {
        setCount(count + 1);
    }

    useEffect(() => {
        actuallyEnsuringRegistration();
    }, [count]);


    const getMe = () => {

        API.getItems({url: 'users/me'})
        .then(response => {
            setMode(response.data['me']['mode']);
            setOriginalMode(response.data['me']['mode']);
            if (response.data['me']['firstName'] != undefined && searchParams.get("forward") === 'true') {
                endProcess(response.data['me']['mode'], false);
            }
           setLoading(false);
        })
        .catch(error => {
            console.log("getMe Error", error);
            setLoading(false);

        })
    }

    const endProcess = (tmpMode, refresh) => {

        // Check if mode set
        if (mode !== '' && tmpMode === null) {
            tmpMode = mode;
        }    

        if (tmpMode === null) {
            setStep('Welcome');
            setLoading(false);
            return;
        }

        console.log("Mode", mode, tmpMode);

        if (tmpMode === 'Operations Staff' || mode === 'Operations Staff') {
            if (refresh) {
                window.location.href = "/my/organisations";
            } else {
                navigate('/my/organisations');
            }
        } else if (tmpMode === 'Healthcare Professional' || mode === 'Healthcare Professional') {
            if (refresh) {
                window.location.href = "/my/dashboard";
            } else {
                navigate('/my/dashboard');
            }
        } else if (tmpMode === 'Human Resources' || mode === 'Human Resources') {
            if (refresh) {
                window.location.href = "/hr/summary";
            } else {
                return navigate('/hr/summary');
            }
        } else {
            //setStep('Registration-Generic');
            setLoading(false);
        }
    }

    const updateMode = (mode, profession) => {

        setProfession(profession);

        API.postItems({url: 'users/me/mode', data: {mode}})
        .then(response => {
            setMode(mode);
            if (profession === 'Doctor') {
                setStep('Registration');
            } else {    
                setStep('Registration-Generic');
            }
        })
        .catch(error => {
            console.log(error);
        });

    }

    React.useEffect(()=>{
        ensureregistration();
    }, []);




    
    return (
        <>
        {!loading &&

        <Splash minW='500px'>
            
                <>
                    {step === 'Welcome' && 
                        <Box>
                            <Heading size='smalltitle' mb={4}>Welcome</Heading>
                            <Text mb={4}>Please select which type of user you are.</Text>
                            <Text mb={8}>This means we can personalise your experience so that you can see only what you need to.</Text>
                            <Wrap spacing={2}>
                            <Wrap display='flex' flexWrap='wrap' spacing={2}>
                                {onBoardingGroups.map((group, index) => {
                                    return (
                                        <Button className='btn-8' onClick={() => updateMode(group.type, group.name)} colorScheme='blue' variant='outline'>{group.name}</Button>
                                    )
                                })}
                            </Wrap>
                            </Wrap>
                        </Box>
                    }
                    {step === 'Registration' && <Registration setStep={setStep} />}
                    {step === 'Registration-Generic' && <Generic setStep={setStep} profession={profession} />}

                    {step === 'Photo' && 
                        <Stack spacing={2}>
                            <Heading size='smalltitle' mb={4}>Profile pictures</Heading>
                            <Text pb={4}>A profile picture helps staff identify you, and means everyone can be more sure of who they are sharing their records with.</Text>
                            <ImageManage />
                            <Flex justifyContent='flex-end'>
                                <Button w='auto' onClick={() => endProcess(null, true)} colorScheme='blue' variant='outline'>Continue</Button>
                            </Flex>
                        </Stack>
                    }

                    {step === 'Dashboard' && <Text>Dashboard</Text>}
                </>
            

        </Splash>
        }
        </>

    )
}