import {
  Avatar,
  AvatarGroup,
  ButtonGroup,
  Box,
  Badge, 
  Button,
  Heading,
  Input, 
  Container,
  Flex,
  Checkbox,
  List,
  Image,
  SimpleGrid,
  Grid, GridItem,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Divider,
  HStack,
  Tag, TagLabel, TagLeftIcon, TagCloseButton,
  Link,
  Icon,
  Select,
  Wrap,
  Menu, MenuItem, MenuButton, MenuList,
  InputRightElement,
  
  Tab, TabList, TabPanels, TabPanel, Tabs,
  TableContainer, Table, Thead, Tbody, Tr, Th, Td, TableCaption,
  FormControl, FormLabel, FormHelperText, FormErrorMessage, MenuDivider, InputGroup, SkeletonText, SkeletonCircle, TagRightIcon,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as ReachLink, useSearchParams } from "react-router-dom";
import Layout from '../../core/layout/Layout';
import { useState, useEffect } from 'react';
import moment from 'moment';
import {MdFilterAlt, MdSortByAlpha, MdFilter, MdOutlineExpandMore, MdOutlineVisibility, MdOutlineEdit, MdOutlineDelete, MdSearch} from 'react-icons/md';
import Card from '../../core/components/cards/Card';
import { Dropzone } from '../../core/components/forms/Dropzone';
import Reminder from './Reminder';
import Invite from '../../access/Invite';
import Moment from 'react-moment';
import { useAuth0 } from "@auth0/auth0-react";
import API from '../../core/utils/API';
import AccessSummary from './AccessSummary';
import {MdCheck, MdClose} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
//import useStateWithCallback from 'use-state-with-callback';
import pluralize from 'pluralize';

import SummaryRow from './SummaryRow';
import { useLocation } from 'react-router-dom';

export default function Dashboard(props) {

let [requirementGroups, setRequirementGroups] = useState([]);
let [users, setUsers] = useState([]);
let [summaryData, setSummaryData] = useState([]);
let [loading, setLoading] = useState(true);
let [options, setOptions] = useState([]);
let [sort, setSort] = useState('lastName');
let [filter, setFilter] = useState('all');
let [search, setSearch] = useState('');
let [sortDirection, setSortDirection] = useState('asc');
let [originalData, setOriginalData] = useState([]);
let [showFilter, setShowFilter] = useState(false);
let [showSearch, setShowSearch] = useState(false);
let [sortCount, setSortCount] = useState(0);

let [uuids, setUuids] = useState([]);

const [searchParams] = useSearchParams();




useEffect(() => {
  if (searchParams.get("uuids")) {
    //console.log("UUIDs", searchParams.get("uuids").split(","));
    let queryUuids = searchParams.get("uuids").split(",");
    console.log("Query UUIDs", queryUuids, uuids === queryUuids, uuids !== queryUuids, uuids != queryUuids);
    if (uuids !== queryUuids) {
      console.log("Different UUIDs");
      setUuids(queryUuids);
    } else {
      console.log("Same UUIDs");
    }
  }
}, []);



// Load info from state passed in navigation
const location = useLocation();
const state = location.state;
//console.log("State early", state);
if (state && state.uuids) {
  if (uuids != state.uuids) {
    setUuids(state.uuids ? state.uuids : state.uuid ? [state.uuid] : []);
  }
}

const { user, getAccessTokenSilently } = useAuth0();
const navigate = useNavigate();


const handleSortChange = (e) => {
  setSort(e);
  setSortDirection('asc');
}

const handleFilterChange = (e) => {
  setFilter(e);
}

const searchChange = (e) => {
  setSearch(e);

}

const handleSortDirectionChange = (e) => {
  setSortDirection(e);
  setSort(sort);
}

const organiseData = () => {

  //console.log("Organising data");
//  console.log(sort, sortDirection, filter, search);

  let tmpData = originalData;

  //console.log("Original Data", tmpData);

  if (filter === 'outstanding') {

    tmpData = tmpData.filter((user) => {
      return user.outstanding.length > 0;
    })
  }

  //console.log("Filtered Data", tmpData);

  if (search != '') {
    tmpData = tmpData.filter((user) => {
      return (user.displayName.toLowerCase()).includes(search.toLowerCase() );
    })
  }

  //console.log("Searched Data", tmpData);

  let sortValue = 1;
  if (sortDirection != 'asc') {
    sortValue = -1;
  }

  tmpData = tmpData.sort((a, b) => {
    if (a[sort] < b[sort]) {
      return -sortValue;
    }
    if (a[sort] > b[sort]) {
      return sortValue;
    }
    return 0;
  });


  //console.log("Sorted Data", tmpData);
  setLoading(true);

  setSummaryData(tmpData);
  setLoading(false);

  setSortCount(sortCount + 1);

  return tmpData;

}



useEffect(() => {
  setSummaryData(organiseData());  // this shows the last value of gridData setted by the other useEffect
}, [originalData, sort, filter, search, sortDirection]);

// Update the data when the sort, filter or search changes
 useEffect(() => {
   organiseData();
 }, [sort, filter, search, sortDirection]);

const getData = async () => {
  try {
    
    setLoading(true);

   
    let config = {
      params: {
        uuids: uuids
      }
    };



    console.log("Config", config);

    const response = await API.getItems({url: 'requirementgroups/hr', config: config});

    const data = response.data;
    setSummaryData(data.users);
    setRequirementGroups(data.requirementGroups);
    setOriginalData(data.users);
    

    
  } catch (error) {
    //console.error(error);
  }
}
useEffect(() => {

  if (uuids.length > 0) {
    getData();
  }
}, [uuids]);


return (

    <Layout
      title='Staff Summary'
      layout='dense'
      description={
        <>
        </>
      }
      actionContent={
      <>
        {showSearch ?
          <InputGroup maxW='200px' >
            <Input id='search-input' placeholder='Search' border='2px gray solid !important' borderRadius='24px' onChange={(e) => searchChange(e.target.value)}/>
            <InputRightElement>
                <Icon as={MdClose} cursor='pointer' _hover={{color: 'red.500'}} onClick={(e) => {setSearch(''); setShowSearch(false);}}/>
            </InputRightElement>
        </InputGroup>
          :
          <Button variant='outline' p='4px' _hover={{bg: 'blue.500', borderColor: 'blue.500', color: 'white'}} colorScheme='gray' onClick={() => {setShowSearch(true); document.getElementById('search-input').focus();}}><MdSearch/></Button>
        }
        <Button display='none' variant='outline' colorScheme='gray' onClick={() => setShowFilter(!showFilter)}>{showFilter ? 'Hide' : 'Show'} Filter</Button>
        {!loading && <Invite type='request' refresh={getData}
          requirementGroups={requirementGroups}
          />
        }
      </>}
      >

      <GridItem colSpan='6' alignItems='flex-start' width='fill-available'>

        {showFilter &&

          <HStack w='100%' spacing={4} mb={12}>
            <Stack>
              <Heading size='label'>Filter</Heading>
              <Select onChange={(e) => handleFilterChange(e.target.value)}>
                <option value="all">All</option>
                <option value="outstanding">Outstanding</option>
              </Select>
            </Stack>
            <Stack display='none'>
              <Heading size='label'>Sort</Heading>
              <HStack>
                <Select 
                  onChange={(e) => handleSortChange(e.target.value)}
                  defaultValue={{ value: 'firstName', label: 'First Name' }}
                  >
                  <option value='firstName'>First Name</option>
                  <option value='lastName'>Last Name</option>
                  <option value='email'>Email</option>
                </Select>

                <Select
                  onChange={(e) => handleSortDirectionChange(e.target.value)}
                  defaultValue={{ value: 'asc', label: 'Ascending' }}
                  >
                  <option value='asc'>Ascending</option>
                  <option value='desc'>Descending</option>
                </Select>
              </HStack>
            </Stack>
            <Stack>
              <Heading size='label'>Search</Heading>
              <Input placeholder='Search' onChange={(e) => searchChange(e.target.value)} />
            </Stack>
              
          </HStack>

        }
   
      

            <SimpleGrid spacing={{base: 2, md: 4}} display={{base: 'none', md: 'grid'}} pl={4}  pb={4} columns={(requirementGroups.length * 2) + 5} spacing={4} borderBottom='2px solid black'>
              <GridItem gridColumn={{base: 'span 1', md: 'span 3'}}>
                <Heading size='label'>
                  Staff Member
                  { (summaryData.length != originalData.length) &&
                    <Tag pl={3} pr={2} mt='-2px' colorScheme='blue' cursor='pointer' size='xs' ml='8px'>
                      {summaryData.length} of {originalData.length}
                      <TagRightIcon ml='4px' as={MdClose} onClick={() => {setFilter('all'); document.getElementById('search-input').value = ''; setSearch('');showSearch(false);}}/>
                    </Tag>
                  }
                  <Menu>
                    <MenuButton p='2px' pl='6px!important' className='show_on_parent_hover' mt='-2px' ml={1} variant='ghost' size='xs' colorScheme='gray' as={Button}>
                      <MdSortByAlpha />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setSort('firstName')} color={sort==='firstName' ? 'blue.500': ''}>First Name</MenuItem>
                      <MenuItem onClick={() => setSort('lastName')} color={sort==='lastName' ? 'blue.500': ''}>Last Name</MenuItem>
                      <MenuItem onClick={() => setSort('email')} color={sort==='email' ? 'blue.500': ''}>Email</MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={() => setSortDirection('asc')} color={sortDirection==='asc' ? 'blue.500': ''}>Ascending</MenuItem>
                      <MenuItem onClick={() => setSortDirection('desc')} color={sortDirection==='desc' ? 'blue.500': ''}>Descending</MenuItem>
                    </MenuList>
                  </Menu>
                </Heading>
              </GridItem>

              {loading && [1,2,3].map((item) => {
                
                <GridItem gridColumn={{base: 'span 1', md: 'span 2'}}>
                    <SkeletonText noOfLines={1} w='120px' pt={2}/>
                </GridItem>
              })}
              
              {!loading && requirementGroups?.map((group) => {
                return (
                  <GridItem gridColumn={{base: 'span 1', md: 'span 2'}}>
                    <Heading size='label'>
                      {group.title}
                      <Box pl={1} display='inline-block' className={filter === 'all' && 'show_on_parent_hover'}>

                      {filter === 'outstanding' ?
                                <Button p='4px' size='xs' variant='ghost' colorScheme='red' mt='-2px'  onClick={() => handleFilterChange('all')}>
                                  <MdFilterAlt />
                                </Button>
                              :
                                <Button p='4px' size='xs' variant='ghost' colorScheme='gray' mt='-2px' onClick={() => handleFilterChange('outstanding')}>
                                  <MdFilterAlt />
                                </Button>
                              }
                              </Box>

                    </Heading>
                  </GridItem>
                )
              })}
              <GridItem gridColumn={{base: 'span 1', md: 'span 2'}}>
                <Heading size='label'>Actions</Heading>
              </GridItem>



            </SimpleGrid>


                <Card p={0}>


            {!loading && summaryData.map((user, idx) => {

              return (

                <SummaryRow user={user} requirementGroups={requirementGroups} refresh={getData} />

              )
            })}
            </Card>

      </GridItem>

    </Layout>
)}
