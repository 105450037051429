import React, {useState, Component, Fragment} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Heading, VStack, Checkbox, WrapItem, useDisclosure, Collapse,  Divider, SkeletonText, Grid, GridItem, Flex, Button, List, ListItem, Badge, Wrap, Box, Stack, Text } from '@chakra-ui/react';
import { MdExpandLess, MdExpandMore, MdThumbUp, MdThumbDown, MdSettings} from "react-icons/md";
import pluralize from 'pluralize';

import Moment from 'react-moment';

export class RequirementTagDisplayRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currState: true }
    this.state = {
        isOpen: false
      };
    }
    

  render() {


    return (
        <Stack>
            <Flex>
                <Box w='40px'>
                    <Button onClick={() => this.setState({isOpen: !this.state.isOpen})} borderRadius='50%' p='4px' width='20px' height='20px' minWidth='20px'>
                       { this.state.isOpen ? <MdExpandLess />:<MdExpandMore />}
                    </Button>
                </Box>
                <Box w='-webkit-fill-available'>
                    <Flex justifyContent='space-between'>
                        <Box>
                            <Text color='emphasized' fontSize='md'>
                                {this.props.data['tag']}
                            </Text>
                            
                            <Wrap mt={1}>

                                { this.props.data['active'] ? 
                                    <Badge size='sm'>Active</Badge> 
                                    : 
                                    <Badge size='sm' colorScheme='red'>Inactive</Badge> 
                                    }
                                { this.props.data['automated'] ? 
                                    <Badge size='sm' colorScheme='blue'>Automated</Badge> 
                                    : 
                                    <Badge size='sm'>Manual</Badge> 
                                    }

                                <Badge size='xs'>{this.props.data['RequirementTaggings'].length} {pluralize('requirement', this.props.data['RequirementTaggings'].length)}</Badge>
                                   
                            </Wrap>

                        </Box>
                        <Box>
                            <Text fontSize='xs' color="subtle">Created: <Moment date={this.props.data["createdAt"]} format='hh:mm DD/MMM/YYYY' /></Text>
                            <Text fontSize='xs' color="subtle">Updated: <Moment date={this.props.data["updatedAt"]} format='hh:mm DD/MMM/YYYY' /></Text>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            <Box>
            <Collapse in={this.state.isOpen} animateOpacity >

                <Table ml={8} mt={4} mb={8}>
                    <Thead>
                        <Tr>
                            <Th>Requirement</Th>
                            <Th>Upvotes</Th>
                            <Th>Downvotes</Th>
                            <Th>Cancelled</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {this.props.data['RequirementTaggings'].map((item) => (
                            <Tr>
                                <Td>
                                    {item['Requirement']['title']}
                                    {item['automated'] &&
                                        <MdSettings/>
                                    }
                                </Td>
                                <Td>{item['upVote']}</Td>
                                <Td>{item['downVote']}</Td>
                                <Td>
                                    {item["cancelled"] != null &&
                                        <Moment date={item["cancelled"]} format='hh:mm DD/MMM/YYYY' />
                                    }           
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
</Collapse>
            </Box>
        </Stack>
    )
  }
}