import React, {useState} from 'react';
import {Heading, Text, Box, Stack, Button, Input} from '@chakra-ui/react';
import userPool from './UserPool';
import Signup from './components/Signup';
import Confirm from './components/Confirm';
import Login from './components/LoginModal';
import { Account } from './components/Accounts';
import Status from './components/Status';
import Settings from './components/Settings';
import APIAccess from './components/APIAccess';
import LoginManager from './components/LoginManager';
import MFAWrapper from './components/MFAWrapper';

export default () => {


    return (
        <Account>

            <Stack spacing={12} p={4}>
                <Status/>
                <Settings/>
                <Box display='none'>
                    <APIAccess/>
                </Box>
                <MFAWrapper>
                    <Box>
                        Access granted.
                    </Box>
                </MFAWrapper>
                
            </Stack>
        </Account>
    );

    
};

