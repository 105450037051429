import { Wrap, RadioGroup, Radio, Input } from "@chakra-ui/react";
import React, {useState, useEffect} from 'react';


function RadioIndividual(props) {

    const [showOther, setShowOther] = useState((props.option === 'Other' && props.isChecked )? true : false);

    function switchOther() {
        if (props.option === 'Other') {
            setShowOther(!showOther);
        }

        //console.log("Radio setting", props.option);
        props.setValue(props.name, props.option.value);

    }

    return (

        <>
            {props.isChecked ?
                <>
                    <Radio value={props.option.value} pr={2} isChecked
                        onChange={() => {switchOther();}}
                        >
                        {props.option.label}
                    </Radio>
                    {showOther && 
                        <Input placeholder='Other' mt={2} />
                    }
                </>
                :
                <Radio value={props.option.value} pr={2} 
                    onChange={() => {switchOther();}}
                    >
                    {props.option.label}
                </Radio>
            }
        </>

    )
}

export default function RadioWrapper(props) {


    const [value, setValue] = React.useState(props.defaultValue);
    
    console.log("RadioWrapper", props, value);

    // Sort options, make sure if there is an other put it to the end

    let options = typeof props.options === 'string' ? JSON.parse(props.options) : props.options;

    let radioOptions = [];

    let other = false;

    if (options && options.other && options.other === true) {
        other = true;
    }

    if (options && Object.keys(options).includes('values')) {
        radioOptions = options.values;
    }
    // else check if options is an array 
    else if (Array.isArray(options)) {
        radioOptions = options;
    }

    // Check if options is an array of objects, or an array of strings
    if (typeof radioOptions[0] === 'object') {

        // Sory by label
        radioOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    } else if (typeof radioOptions[0] === 'string') {
        
        // Sort by value
        radioOptions.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
        });

        // Convert to array of objects
        radioOptions = radioOptions.map((option) => {
            return {
                value: option,
                label: option
            }
        });

    } else {
        console.error("RadioWrapper: options must be an array of strings or an array of objects", options);
    }

    // Push certain options to the end
    if (typeof radioOptions === 'object' ) {
        let endOptions = ['Other', 'N/A'];
        endOptions.forEach((option) => {
            let index =-1;
            radioOptions.forEach((opt, i) => {
                if (opt.value === option || opt.label === option) {
                    index = i;
                }
            });
            if (index !== -1) {
                radioOptions.push(radioOptions.splice(index, 1)[0]);
            }
        });
    }


    return (

        <RadioGroup onChange={(v) => {
            setValue(v);
            console.log("Setting value", v);
            props.setValue(v);
        }} value={value} >
            <Wrap>
                {radioOptions.map((option, i) => {

                    

                    if (option.value === value) {
                        console.log("FOUND MATCH", option.value, value, option.value === value )
                        return (
                            <RadioIndividual option={option} isChecked={true} setValue={props.setValue} name={props.name}/>
                        )
                    }
                    return (
                        <RadioIndividual option={option} isChecked={false} setValue={props.setValue} name={props.name}/>
                    )
                })}
                
            </Wrap>
        </RadioGroup>
    )


}
