"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = {
    opacity: 'unset',
    borderColor: 'bg-subtle',
    borderWidth: '2px',
};
exports.default = {
    baseStyle: baseStyle,
};
