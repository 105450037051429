
import React from 'react';
import { Box, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { set } from 'date-fns';


export default function Code(props) {


    const [error, setError] = useState('');

    let inputName = 'code-input-' + props.name;

    const updateCode = (i) => {

        let tmpCode = '';

        document.querySelectorAll('.'+inputName).forEach((item, index) => {
            //console.log(item.value);
            tmpCode += item.value;
        });

        if (document.getElementById(inputName + i).value.length === 1 && i < props.codeLength - 1) {

            i = i + 1;
            document.getElementById(inputName + i).focus();
        }

        if (props.return !== undefined && tmpCode.length === props.codeLength) {
            props.return(tmpCode);
        }

    }

    const validate = (e, i) => {

        setError('');

        // Handle backspace
        if (e.keyCode === 8 && i > 0) {
            if (document.getElementById(inputName + i).value.length === 0 && i > 0) {

                i = i - 1;

                document.getElementById(inputName + i).focus();

            }
        }

        // Check if numerical or numpad key OR we are pasting
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.type === 'paste') {

            if (document.getElementById(inputName + i).value.length === 1 && i < props.codeLength - 1) {

                i = i + 1;
                document.getElementById(inputName + i).focus();

            }

        } else {

            setError('Please enter a valid number');

        }

    }

    const paste = (e, i) => {

        e.preventDefault();

        let tmpCode = e.clipboardData.getData('Text');

        if (tmpCode.length === props.codeLength) {

            for (let j = 0; j < props.codeLength; j++) {
                document.getElementById(inputName + j).value = tmpCode[j];
            }

            if (props.return !== undefined) {

                props.return(tmpCode);

            }

        }

    }

    
    const codeInputs = [];
    for (let i = 0; i < props.codeLength; i++) {

        let autoFocus = false;
        if (i === 0) {
            autoFocus = true;
        }
        
       codeInputs.push(
            <Input key={inputName +i} 
                type="number" pattern="[0-9]*" inputmode="numeric"
                onChange={(e) => {updateCode(i);}}  
                id={inputName + i}
                className={'code-input ' + inputName } 
                onKeyDown={(e) => {validate(e, i);}}
                fontSize={{base: '1.5em', mb: '2em'}}
                onPaste={(e) => {e.preventDefault(); paste(e, i);}}
                textAlign='center'
                w='1.75em'
                h='2em'
                m='0.25em'
                p='0'
                border='1px solid #ccc'
                borderRadius='0.25em'
                type='text'
                maxLength='1'
                autoFocus={autoFocus}
                />
       );
    }

    return (

        <Box>
            <Box textAlign={props.align ? props.align : 'center'} pt={4}>
                {codeInputs}
            </Box>

            {props.hideClear != true &&
                <Text mt={4} textAlign={props.align ? props.align : 'center'} color='subtle' _hover={{color: 'blue.500'}} cursor='pointer'
                    
                    onClick={() => {
                        document.querySelectorAll('.'+inputName).forEach((item) => {
                            item.value = '';
                        });
                        props.setMessage('');
                        document.getElementById(inputName + 0).focus();
                    }}
                    >
                    Clear Code
                </Text> 
            }

            {error &&
                <Text pt={4} color='red.500'>{error}</Text>
            }

            { inputName.length === props.codeLength &&
            document.getElementById(inputName + 0).focus() }

        </Box>

    )

}