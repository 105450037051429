
import {
    Flex,
    Box,
    Circle,
    useColorModeValue,
    useColorMode,
    Image,
    Spinner,
    Text,
    Progress,
    Button,
    Stack,
    Center
  } from '@chakra-ui/react';
  import Splash from './Splash';

  import Logo from './logo.svg';
export default function Loading(props) {

    const { colorMode, toggleColorMode } = useColorMode();


    return (


        <Circle p={2}    
            height={props.h || '96px'}
            padding={props.p || '26px'}
            >
            <Box className="loading active" w='100%'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </Box>
        </Circle>

    )
}
      