import React from 'react';
import { useContext } from 'react';
import { Link, ButtonGroup, Icon, HStack, Banner, Flex, Center, Wrap, Heading, Text, Box, Stack, Button, Input } from '@chakra-ui/react';
import API from '../../core/utils/API';
import { useEffect, useState } from 'react';
import { get } from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { MdLock, MdAvTimer } from 'react-icons/md';
import Moment from 'react-moment';
import moment from 'moment';
import Clock from '../../core/components/countdown/Clock';
import ReactCodeInput from 'react-code-input';
import AuthCode from 'react-auth-code-input';
import { set } from 'date-fns';
import {MdClose, MdLockOpen} from 'react-icons/md';
import { AccountContext } from '../../auth/cognito/components/Accounts';
import Code from '../../core/components/forms/Code';
import PhoneMFAWrapper from '../phone/PhoneMFAWrapper';

import Loading from '../../static/layout/Loading';

import { useToast } from '@chakra-ui/react';

export default function TOTPWrapper(props) {

    const toast = useToast();

    const [stage, setStage] = useState('check'); // 0 = QR Code, 1 = TOTP Code
    const [qrCode, setQRCode] = useState();
    const [qrMessage, setQRMessage] = useState();
    const [qrSecret, setQRSecret] = useState();
    const [showSecret, setShowSecret] = useState(false);
    const [TOTPMessage, setTOTPMessage] = useState();
    const [masterError, setMasterError] = useState();
    const [expires, setExpires] = useState();
    const [phone, setPhone] = useState();

    const { getSession } = useContext(AccountContext);
    const [jti, setJti] = useState();

    const [codeLength, setCodeLength] = useState(6);

    const [code, setCode] = useState('');



    const updateCode = (tmpCode) => {

        setTOTPMessage('');
        setCode(tmpCode);

    }


    const checkSession = () => {
        getSession()
        .then(session => {
        //  console.log('Cognito Access JTI', session);
          setJti(session.accessToken.payload.jti);
          setPhone(session.phone_number);
        })
        .catch(err => {
        //  console.log('Cognito not logged in');
        });
    }
    
    const checkRegistration = (forward) => {
    
        console.log("Checking reg", jti);

        if (!jti) {
            console.log("No JTI");
            checkSession();
            return;
        }

        API.getItems({url: '/totp/registered?jti=' + jti}).then(res => {
            //console.log("TOTP registered", res);
            if (!forward) {
                return;
            }
            if (res.data.session === true) {
                console.log("Session active");
                setStage('activesession');
                setExpires(res.data.sessionExpires);
            } else if (res.data.registered === true) {
                console.log("Registered");
                setStage('totp');
            } else {
                setStage('qr');
            }
        }).catch(err => {
          //  console.error("TOTP registered error", err);
            setMasterError(err.response.data.message);
        });

    }


    const getQRCode = () => {

      //  console.log("Getting QR code");
        API.getItems({url: '/totp/qr'}).then(res => {
        //    console.log("QR", res);
            setQRCode(res.data.qr.qr);
            setQRSecret(res.data.qr.secret.base32);
        }).catch(err => {
        //    console.error("QR ERROR:", err);
        });

    }

    const verify = () => {

        //const code = document.getElementById('totpcode').value;
        //console.log('TOTP code', code);

        API.postItems({url: 'totp/validate', data: {code: code, jti}}).then(res => {

            setStage('activesession');
            setExpires(res.data.expires);

        }).catch(err => {
                
           console.error("TOTP verify error", err);
           // Check if status is 401
            if (err.response.status === 401 || err.response.status === 403) {
                 setTOTPMessage('Code is incorrect, please try again');
                 return;
            }


            setTOTPMessage("Unrecognised error");
            //setTOTPMessage(err.response.data.message);
    
        }); 

    
    }

    const revoke = () => {

        API.postItems({url: 'totp/revoke'}).then(res => {

            setStage('check');

        }).catch(err => {

           // console.error("TOTP revoke error", err);

        });

    }

    useEffect(() => {
        if (stage === 'qr') {
            getQRCode();
        }
        if (stage === 'checkqr') {
            checkSession();
        }
        if (stage === 'totp') {
        }
        if (stage === 'check') {
            checkRegistration(true);
        }
    }, [stage, jti]);

    useEffect(() => {
        
    
    }, []);


    useEffect(() => {
        if (code.length === 0) {
            setTOTPMessage('');
        } else if (code.length === codeLength) {
            verify();
        }
    }, [code]);

    return (

        <>

        {jti ? 

        <>

        {stage === 'activesession' ?
        
            <Box className='MFA-wrapped'>
                <Box className='MFA-wrapper-banner gradient-background' color='white' pt={2} pb={2}>
                    <HStack className='MFA-wrapper-banner-content' m={{base: '0', lg: 'auto'}} pl={props.pl || 8} pr={props.pr || 8}>
                        <Icon as={MdLockOpen} w={8} h={8} p='2px' color='white'/>
                    
                        <Text color='white'>MFA Active
                            {expires && 
                                <Text as='span' color='white'>
                                    : expires in <Clock deadline={expires} style='list'
                                        end={() => { 
                                            if (stage != 'check' && stage != 'totp' && stage != 'activesession') {
                                                console.log("End session", stage);
                                                setStage('check')
                                            }
                                        }}
                                        />
                                </Text>
                            }
                            <Text as='span' color='white' _hover={{textDecoration: 'underline'}} ml={2} cursor='pointer' onClick={() => {revoke()}}>
                                End Session
                            </Text>
                        </Text>

                        <Button display='none' ml='auto!important' _hover={{bg: 'rgba(255,255,255,0.2) !important', textShadow: "1px 1px #333" }} size='sm' variant='outline' colorScheme='white' onClick={() => {revoke()}}>End</Button>
                        
                    </HStack>
                </Box>
                {props.children}

            </Box>
        
        :

        <Stack spacing={4} p={4} minH='400px' maxW='500px' ml='auto!important' mr='auto!important'>
            {stage !== 'check' &&
                <Box  m={-4} p={4} textAlign='center' pt={12} pb={8}>
                    <Heading as="h1" size="label" fontSize='2em' >Multi-Factor Authentication</Heading>
                </Box>
            }
            <Stack pt={4} pb={4} spacing={4} textAlign='center'>

            {stage === 'check' &&
                
                <Loading
                    message='Checking your TOTP MFA Status'
                    />

            }

            {stage === 'checkqr' &&

                <>
                    <Text color='red.500' fontWeight='bold' fontSize='1.5em' mt={-4} pb={2}>Warning</Text>

                    <Text>All existing devices will be de-registered immediately.
                        You must have your mobile with you to continue, we will
                        send a code via SMS to confirm your identity.
                    </Text>
                    
                    <Text fontWeight='bold' fontSize='1.25em'>{phone}</Text>

                

                    <Stack pt={2} spacing={2}>
                        <Button mt={4} mb={2} w='fit-content' alignSelf='center' variant='outline' colorScheme='blue' className='btn-8' onClick={() => {setStage('check'); checkRegistration();}}>Return</Button>
                        <Text fontSize='sm' onClick={()=>{setStage('qr')}} textAlign='center' color='subtle' cursor='pointer' _hover={{color: 'red.500'}}>Reset Devices</Text>
                    </Stack>

                </>
            
            }

            {stage === 'qr' &&
                <PhoneMFAWrapper>

                    <Center mb={2} mt={-2}>
                    {qrCode && <img width='200px' margins='auto' src={qrCode} alt="QR Code"/>}
                    </Center>
                    <Stack spacing={4} pb={4}>
                        <Text textAlign='center'>1. Download an authenticator app.<br/>
                        <Text as='span' color='subtle' fontSize='sm'>We sent a link in the confirmation SMS and to your email, you can download 'Google Authenticator' from the app store.
                        <br/><Link href='/authenticator' color='blue.500' _hover={{textDecoration: 'underline'}} target='_blank' >Click here for more information.</Link>
                        <Text display='none'><br/>If you have not received it, <Text as='span' color='subtle' cursor='pointer' _hover={{color: 'blue.500'}}>click here to resend the email.</Text></Text>
                        </Text></Text>
                        <Text mb={8} textAlign='center'>2. Scan the QR code with the app.
                            <br/><Text as='span' color='subtle' fontSize='sm'>Once you can see a six digit code you can continue.</Text>
                            <br/><Text as='span' color='subtle' fontSize='sm' cursor='pointer' _hover={{color: 'blue.500'}} onClick={() => {setShowSecret(!showSecret)}}>Click here if you need to manually enter the code.</Text>
                        </Text>
                        {showSecret && <Text textAlign='center' fontSize='sm' fontWeight='bold'>{qrSecret}</Text>}
                    </Stack>
                    <Button mt={4} alignSelf='center' w='fit-content' variant='outline' colorScheme='blue' className='btn-8' onClick={() => setStage('totp')}>Enter Code</Button>

                </PhoneMFAWrapper>
            }

            {stage === 'totp' &&
    <>       
                <Text textAlign='center' pb={0}>
                    You have previously seen a QR code for device registration,
                    please enter the code from your authenticator app.
                </Text>
                <Text fontSize='sm' color='subtle' as='span' cursor='pointer' _hover={{color: 'blue.500'}} onClick={() => {setStage('checkqr');}}>Click here to register a new device</Text>
                <Code 
                    codeLength={codeLength}
                    return={(code) => {updateCode(code)}}
                    name='totp'
                    setMessage={(message) => {setTOTPMessage(message)}}
                />            
                <Box minH={8}>
                    {TOTPMessage && <Text textAlign='center' color='red.500'>{TOTPMessage}</Text>}
                </Box>

            </>
            }

            {stage === 'success' &&
            
                <>
                    <Text>Success!</Text>
                    <Text>You have successfully registered your device.</Text>
                    <Text>Click the button below to continue.</Text>
                    <Flex justifyContent='center'>
                        <Button size='sm' variant='outline' colorScheme='blue' className='btn-8' >Continue</Button>
                    </Flex>
            
                
                </>
            
            
            
            }

        </Stack>
            {props.onClose &&
                <Button ml='auto !important' mr='auto !important' size='sm' alignSelf='flex-start' variant='ghost' colorScheme='gray' onClick={() => {props.onClose();}}>Close</Button>
            }
        </Stack>


        }


        </>
        
        :
        <Stack spacing={4} p={4} minH='400px' maxW='500px' ml='auto!important' mr='auto!important'>
            <Box  m={-4} p={4} textAlign='center' pt={12} pb={8}>
                <Heading as="h1" size="label" fontSize='2em' >Multi-Factor Authentication</Heading>
            </Box>
            <Stack pt={4} pb={4} spacing={4} textAlign='center'>
                <Loading
                    message='Awaiting your session details'
                />
            </Stack>
        </Stack>
}

        </>

    

    )
}