import {
    Avatar,
    AvatarGroup,
    Box,
    Center,
    DarkMode,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    Image,
    useBreakpointValue,
    useColorModeValue as mode,
  } from '@chakra-ui/react';
  import { NavLink } from "react-router-dom";

  export const PromoWrapper = (props) => (
    <Flex
      minH={{ base: 'auto', md: '100vh' }}
      bgGradient={{
        md: mode(
          'linear(to-r, "linear-gradient(-45deg, #ffae00, #7e03aa, #00fffb)" 50%, white 50%)',
          'linear(to-r, blue.500 50%, gray.900 50%)',
        ),
      }}
    >
      <Flex mx="0" width="full" >
        <Box flex="1" display={{ base: 'none', md: 'block' }}  className='gradient-background'>
          <DarkMode>
            <Flex direction="column" px={{ base: '4', md: '8' }} height="full" color="on-accent">
              <Flex align="center" h="24">
                

              <a href='http://www.pyrexia.io'  display='flex' id='logo-home-nav' >
                <Image src='/logo-transparent-white.png' h='30px' mt='12px' mr={2} alt='Logo' /> 
              </a>
              </Flex>
              <Flex flex="1" align="center">
                <Stack spacing="8">
                  <Stack spacing="6">
                    <Heading size={{ md: 'lg', xl: 'xl' }}color="on-accent">
                        AI automated workflows for healthcare
                    </Heading>
                    <Text fontSize="lg" maxW="md" fontWeight="medium" color="on-accent">
                      An intelligent suite of tools for healthcare's administrators, managers, workforce and HR teams.
                    </Text>
                  </Stack>
                  <HStack spacing="4">
                    <AvatarGroup
                      size="md"
                      max={useBreakpointValue({ base: 2, lg: 5 })}
                      borderColor="on-accent"
                    >
                      <Image zIndex='5' border='2px solid white' borderRadius='24px' boxShadow='md' w='48px' h='48px' name="Ryan Florence" src="./profile1.jpg" />
                      <Image zIndex='4' border='2px solid white' borderRadius='24px' boxShadow='md' w='48px' h='48px' name="Ryan Florence" src="./profile2.jpg" />
                      <Image zIndex='3' border='2px solid white' borderRadius='24px' boxShadow='md' w='48px' h='48px' name="Ryan Florence" src="./profile3.jpg" />
                      <Image zIndex='2' border='2px solid white' borderRadius='24px' boxShadow='md' w='48px' h='48px' name="Ryan Florence" src="./oliver.jpg" />
                      <Image zIndex='1' border='2px solid white' borderRadius='24px' boxShadow='md' w='48px' h='48px' name="Ryan Florence" src="./ali.jpg" />
                    </AvatarGroup>
                    <Text fontWeight="medium" color="on-accent">Making life easier</Text>
                  </HStack>
                </Stack>
              </Flex>
              <Flex align="center" h="24">
                <Text color="on-accent-subtle" fontSize="sm">
                  © 2024 Pyrexia Ltd. All rights reserved.
                </Text>
              </Flex>
            </Flex>
          </DarkMode>
        </Box>
        <Center flex="1">
          <Box m={4}>
          {props.children}
          </Box>
        </Center>
      </Flex>
    </Flex>
  )