import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Image, Menu, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, MenuOptionGroup } from '@chakra-ui/react';
import API from "../../../core/utils/API";
import {MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos, MdList } from "react-icons/md";
import LoadingCircle from '../../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../../static/layout/Loading';
import Splash from '../../../static/layout/Splash';
import Layout from '../../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../../auth/SubscriptionWrapper';
import Modal from '../../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../../core/components/lists/List';
import pluralize from 'pluralize';
import InputWrapper from '../../../core/components/forms/inputs/InputWrapper';
import ClientLink from '../ClientLink';

import AutoForm from '../../../core/models/AutoForm';

import GoogleMap from '../../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import e from 'cors';

import FileView from '../../../core/components/files/View';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { set } from 'date-fns';
import { de } from 'date-fns/locale';

export default function AccountsBankDetails(props) {

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [details, setDetails] = useState({});
    const [expand, setExpand] = useState(false);

    function updateDetails(key, value) {

        setUpdating(true);
        let tmp = details;
        tmp[key] = value;

        console.log(tmp);

        setDetails(tmp);
        setUpdating(false);

    }


    return (

        <Stack spacing={4}>


            <InputWrapper   
                label='Require PDF'
                description='Do you want to require a PDF invoice for all transactions?'
                name='requirePDF'
                type='radio'
                options={[
                    {label: 'Yes', value: 'yes'},
                    {label: 'No', value: 'no'}
                ]}
                isDisabled={loading}
                setValue={(key, val) => {updateDetails(key, val)}}
                />

            <InputWrapper
                label='PDF Prefix'
                description='Accountancy platforms often require a prefix to be added to the invoice number.'
                defaultValue=''
                name='pdfPrefix'
                isDisabled={loading}
                setValue={(key, val) => {updateDetails(key, val)}}
                />

            <InputWrapper
                label='PDF Suffix'
                description='Accountancy platforms often require a suffix to be added to the invoice number.'
                defaultValue=''
                name='pdfSuffix'
                isDisabled={loading}
                setValue={(key, val) => {updateDetails(key, val)}}
                />
         

            <Flex justify='flex-end' pt={4}>
                <Button
                    variant='outline'
                    colorScheme='blue'
                    isDisabled={updating}
                    isLoading={loading}    
                    onClick={() => {
                        // Open a tab to Pyrexia.io/invoicing
                        setLoading(true);
                        // Wait 2 seconds
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000);
                    }}
                    >
                        Update
                </Button>
            </Flex>

        </Stack>

    )

}