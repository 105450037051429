
import React from 'react';
import { InputGroup, InputLeftElement, Box, Input, Text, Heading, FormControl, FormLabel, Flex, Stack, Button } from '@chakra-ui/react';
import { useState } from 'react';
import API from '../../core/utils/API';

export default function Generic(props) {


    let [error, setError] = useState(null);

    function updateProfile () {


        let data = {
            firstName: document.getElementById('firstName').value,
            lastName: document.getElementById('lastName').value,
            profession: document.getElementById('profession').value,
        }

        // Check for null values
        for (let key in data) {
            if (data[key] === '') {
                setError('Please fill in all fields');
                return;
            }
        };


        API.patchItems({url: 'users/me', data: data}).then((response) => {
            props.setStep('Photo');
        }).catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });

    }


    return (

        <Box>
            <Stack spacing={4}>
                
                <Heading size='smalltitle' mb={4}>A few bits</Heading>

                <Text>We just need a little bit of information from you to finish registration!</Text>

                    <Stack direction={{base: 'column', md: 'row'}} w='100%'>
                        <Input
                            maxW={{
                            md: '3xl',
                            }}
                            placeholder="First Name"
                            required
                            id = 'firstName'
                        />
                        <Input
                            maxW={{
                            md: '3xl',
                            }}
                            placeholder="Last Name"
                            required
                            id = 'lastName'
                        />
                    </Stack>


                    <Input placeholder='Profession' 
                        id='profession'
                        defaultValue={props.profession === 'AHP' ? '' : props.profession}
                        />


                            {error && <Text color='red'>{error}</Text>}
                <Flex justifyContent='space-between' pt={4}>
                    <Button onClick={() => props.setStep('Welcome')} variant='outline' colorScheme='red' className='btn-8'>Back</Button>
                    <Button onClick={() =>  {updateProfile();}} variant='outline' colorScheme='blue' className='btn-8'>Next</Button>
                </Flex>

            </Stack>

        </Box>

    )
}