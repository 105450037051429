import React, { useEffect } from 'react';

import {useState} from 'react';
import { useForm } from "react-hook-form";
import {Checkbox, InputRightAddon, Divider, IconButton, InputGroup, InputRightElement, Alert, ListIcon, Stack, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, List, ListItem, HStack, Text, Link, Flex, Tag, TagLabel, TagRightIcon, Box,Heading, Container, Input, Button, ButtonGroup } from '@chakra-ui/react';
import Modal from '../../core/components/modal/Modal';
import MFAWrapper from '../../auth/cognito/components/MFAWrapper';
import {Dropzone} from '../../core/components/forms/Dropzone';
import { Link as ReachLink } from 'react-router-dom';
import LoadingCircle from '../../static/layout/LoadingCircle';

import BasicBanner from '../../core/components/banners/BasicBanner';

import { useNavigate } from "react-router-dom";

import Pluralize from 'pluralize';
import { useAuth0 } from "@auth0/auth0-react";
import API from '../../core/utils/API';
import { useToast } from '@chakra-ui/react';
import Resizer from "react-image-file-resizer";
import jsPDF from 'jspdf';
import { Buffer } from "buffer";
import { MdSearch, MdCheck, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";
import { set } from 'date-fns';
import { bg } from 'date-fns/locale';
import secureLocalStorage from 'react-secure-storage';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';

export default function ClientLink(props) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tradeshiftClients, setTradeshiftClients] = useState([]);
    const [searchTerms, setSearchTerms] = useState(props.search);
    const [source, setSource] = useState(props.source);
    const [sourceClient, setSourceClient] = useState(props.sourceClient);
    const [selected, setSelected] = useState(null);
    const [blocked, setBlocked] = useState(false);

    function searchTradeshift() {

        setLoading(true);
        setSelected(null);
        setError(null);

        let url = 'organisations/accounts/clients/search';
        let params = {
            query: searchTerms
        }

        API.getItems({url: url, config: {params: params}}).then((response) => {
            console.log(response.data);
            if (response.data.Connection.length == 1) {
                setSelected(response.data.Connection[0].CompanyAccountId);
            }
            setTradeshiftClients(response.data.Connection);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setError(error);
            setLoading(false);
        });
        
    }

    useEffect(() => {
        searchTradeshift();
    }, []);

    function linkClient(client) {

        let tradeshiftClient = tradeshiftClients.find((c) => c.CompanyAccountId == client);

        let url = 'organisations/accounts/clients/newlink';
        let config = {
            params: {
                create: true,
                source: source,
                blocked
            },
            // Send data / body
            data: {
                type: 'testing',
            }
        }

        let data = {
            sourceClient: sourceClient,
            tradeshiftClient,
        }

        console.log(config);

        let resp = API.postItems({url, timeout: 30, config, data}).then((response) => {
            console.log(response);
            props.onSuccess();
            props.onClose();
        })
        .catch((error) => {
            console.log(error);
            setError(error);
        });
    }

    return (
        <Modal onClose={props.onClose} title="Link Client"
        
            actionContent={
                <ButtonGroup size='sm' variant='outline'>
                    <Button colorScheme='red' display='none'>
                        Skip Client
                    </Button>
                    <Button colorScheme='blue'
                        isDisabled={selected == null}
                        onClick={() => linkClient(selected)}
                        >Link</Button>
                </ButtonGroup>
            }
            >

            <Stack spacing={4} mb={4}>
                <Text>Search for a client to link</Text>
                <InputGroup size='sm'>
                    <Input placeholder="Search" value={searchTerms} 
                        onChange={(e) => setSearchTerms(e.target.value)}
                        onKeyPress={(e) => {    
                            if (e.key === 'Enter') {
                                searchTradeshift();
                            }
                        }}
                        isDisabled={loading}
                        />
                    <InputRightAddon
                        _hover={{
                            cursor: 'pointer', 
                            bg: 'blue.500', 
                            color: 'white',
                            borderColor: 'blue.500'
                        }}
                        transition='all 0.2s ease-in-out'
                        onClick={() => searchTradeshift()}
                        isDisabled={loading}
                        disabled={loading}
                        >Search</InputRightAddon>
                </InputGroup>
            </Stack>

            <Stack spacing={0} pt={2} pb={4}>


                <Checkbox colorScheme='red'
                    isChecked={blocked}
                    onChange={(e) => setBlocked(e.target.checked)}
                    >
                    Block Pyrexia's automatic invoice processing
                </Checkbox>

                <Text fontSize='xs' pl='29px' color='subtle'>
                    If you invoice this client via a different method then check this box to prevent Pyrexia from automatically invoicing this client.
                </Text>
            
            </Stack>

            <Divider/>
        

            {loading && 
            <Box mt={4} mb={4}>
                <LoadingCircle /> 
            </Box>
            }


            {error &&
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error</AlertTitle>
                    <AlertDescription>{error.message}</AlertDescription>
                    <CloseButton position="absolute" right="8px" top="8px" 
                        onClick={() => setError(null)}
                        />
                </Alert>
            }

            {!loading && tradeshiftClients && tradeshiftClients.length > 0 &&
            
                <List spacing={3} pt={4}>
                    {tradeshiftClients.map((client) => {
                        console.log(client);
                        return (
                            <ListItem key={client.CompanyAccountId}
                                border='1px solid transparent'
                                borderRadius='sm'
                                p={2}
                                bg={selected == client.CompanyAccountId ? 'blue.50' : 'transparent'}
                                borderColor={selected == client.CompanyAccountId ? 'blue.500' : 'transparent'}
                                _hover={{borderColor: 'accent'}}
                                onClick={() => setSelected(client.CompanyAccountId)}
                                cursor='pointer'
                                >
                                <Flex>
                                    <Stack>
                                        <Text>{client.CompanyName}</Text>
                                        <Text color='subtle'>{client.AddressLines.map((line) => line.value).join(", ")}</Text>
                                    </Stack>
                                </Flex>
                            </ListItem>
                        )
                    })}
                </List>

            }

        </Modal>
    )
}