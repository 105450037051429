import React from 'react';
import { ButtonGroup, Checkbox, Wrap, Tag, Table, Tbody, Tr, Th, Td, Icon, Circle, Avatar, Stack, HStack, GridItem, Flex, useToast, Box, Text, Heading, Input, Button, List, ListItem, Grid } from '@chakra-ui/react';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import { useState, useEffect } from 'react';
import API from '../../core/utils/API';
import {MdCheck, MdClose, MdList, MdAccessTime} from 'react-icons/md';
import Moment from 'react-moment';
import Splash from '../../static/layout/Splash';
import axios from 'axios';
import Loading from '../../static/layout/LoadingCircle';
import Layout from '../../core/layout/Layout';
import { set } from 'date-fns';
import pluralize from 'pluralize';

const ProductDisplay = (props) => {


    const toast = useToast();

    function createCheckoutSession() {

        toast({
            title: "Creating checkout session",
            description: props.price_id,
            status: "info",
            duration: 9000,
            isClosable: true,
        })
        
        API.postItems({
            url: 'organisations/stripe/create-checkout-session',
            config: {
                params: {
                    priceId: props.price_id,
                }
            }
        }).then((response) => {

            console.log(response);
            window.location.href = response.data.url;

        }).catch((error) => {
            console.log(error);

        })

    }
    
    return (

      <Button colorScheme='blue' type="submit" onClick={()=>{createCheckoutSession();}}
        inProgress={props.loading}
        >
        Subscribe
      </Button>
    )
    
};


function Card(props) {
    return (
        <GridItem colSpan={{base: 0, md: 1}} borderRadius='md' boxShadow='md' p={4} mb={4} bg='white'>
            {props.children}
        </GridItem>
    )
}


function removePennies(value, discount) {

    let newValue = value;
    if (discount != 0 && discount != null) {
        newValue = value - (value * discount / 100);
    }

    // Ensure there are either 0 or 2 decimal places in newValue
    newValue = newValue.toFixed(2);

    // If newValue is a whole number, remove the decimal places
    if (newValue.endsWith('.00')) {
        newValue = newValue.slice(0, -3);
    }

    // ensure value is a number
    return newValue;

    // Remove .00 if the value ends in .00
    if (value.endsWith('.00')) {
        return value.slice(0, -3);
    }

    return value;

}

export default function Product(props) {

    const productId = props.productId;

    const toast = useToast();

    console.log("Product", props);

    function cancel() {

        props.setLoading(true);
        console.log("Cancel", props.subscriptionId);

        let response = API.postItems({
            url: 'organisations/stripe/cancel',
            config: {
                params: {
                    subscriptionId: props.subscriptionId
                }
            }
        }).then((response) => {
            props.setLoading(false);
            console.log(response);
            props.refresh();
            toast({
                title: "Subscription cancelled",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        })
        .catch((error) => {
            props.setLoading(false);
            console.log(error);
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        });

    }


    function subscribe() {

        props.setLoading(true);

        API.postItems({
            url: 'organisations/stripe/subscribe',
            config: {
                params: {
                    priceId: props.price_id,
                }
            }
        }).then((response) => {

            console.log(response);
            window.location.href = response.data.url;

        }).catch((error) => {
            console.log(error);

        });

    }


    return (

        <Stack
            direction={{ base: 'column', md: 'row' }}
            borderBottom='1px solid'
            borderColor='text'
            justifyContent='space-between'
            key={props.key}
            order={props.active ? 1 : 0}
            pb={4}
          //  opacity={props.active ? 0.3 : 1}
            _hover={{   
                opacity: 1
            }}
            transition='opacity 0.3s'

            >
            <Box>
                <Heading size='label'>
                    {props.name}
                </Heading>
                <Text>
                    {props.description}
                </Text>
                <Text>
                    {props.benefits} <Text as='span' color='blue.500'>Read evidence.</Text>
                </Text>
                {props.warning  &&
                    <Text color='red.500'>
                        {props.warning}
                    </Text>
                }
            </Box>

            <HStack>
            <Box alignItems='start' display='none'>
                <Button variant='ghost' size='sm' colorScheme='blue' display='none'>
                    Evidence
                </Button>
                <Button variant='ghost' size='sm' colorScheme='blue'>
                    Demo
                </Button>
            </Box>


            <Stack 
                    minW='100px'
                    alignItems='center'  
                    spacing={0}
                    >
            {props.active ?

                <Button colorScheme='red' variant='ghost'
                    size='sm'
                    onClick={() => {cancel()}}
                    isLoading={props.loading}
                    >
                    Cancel
                </Button>

                :

                <Button colorScheme='blue' type="submit" onClick={()=>{subscribe();}}
                    isLoading={props.loading}
                    >
                    Subscribe
                </Button>

            }
            <Text fontSize='sm' color='subtle' pt={2}>
                {!props.active && '£' + removePennies(props.price * props.scale, props.discount) + "pcm"}
            </Text>

            {!props.active && props.discount != 0 && props.discount != null &&
                <Text as='s' fontSize='xs' color='subtle'>
                £{props.price}pcm
                </Text>
            }

            </Stack>

            </HStack>

        </Stack>

    )
}