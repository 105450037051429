import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Container,
    Divider,
    Text,
    Flex,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    useBreakpointValue,
    useColorModeValue,
    useColorMode,
    Image,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Skeleton,
    SkeletonCircle,
    useDisclosure

  } from '@chakra-ui/react';
  import * as React from 'react';
  import { useEffect, useState, useContext } from "react";
  import { AccountContext } from '../../auth/cognito/components/Accounts';
  
  import { FiHelpCircle, FiMenu, FiSearch, FiSettings } from 'react-icons/fi';
  import { NavLink } from "react-router-dom";
 // import LoginButton from '../../auth/LoginButton';
  import LoginPanel from '../../auth/LoginPanel';
  import LogoutButton from '../../auth/LogoutButton';
  import { useAuth0 } from "@auth0/auth0-react";
import {MdMail, MdNotifications, MdDashboard, MdFolder, MdSettings} from 'react-icons/md';
  import { Link as ReachLink } from "react-router-dom";
  import { HamburgerIcon} from '@chakra-ui/icons';
  
import API from '../../core/utils/API';
import { InterceptorContext } from '../../auth/Interceptor';
import { KyberEncryptionContext } from '../../auth/KyberEncryption';
import { ProtectedRoute } from '../../auth/ProtectedRoute';
import Status from '../../auth/cognito/components/Status';
import Notifications from '../../my/notifications/Notifications';
import MessagingWindow from '../../my/messages/MessagingWindow';
import {setupInterceptors} from '../../core/utils/AxiosConfig';
import { set } from 'date-fns';

const HamburgerMenu = (props) => {

  const mode = props.mode;
  const [show, setShow] = useState(false);
  
  

  return (
    <>
    {(mode === null || mode === undefined || mode === '' ) &&
      <MenuItem as={ReachLink} to="onboarding" className={({ isActive }) => isActive ? activeClassName : inactiveClassName } >
        Onboarding
      </MenuItem>
    }
    { mode === 'Healthcare Professional' &&
      <MenuItem as={ReachLink} to="my/dashboard" className={({ isActive }) => isActive ? activeClassName : inactiveClassName } >
        Dashboard
      </MenuItem>
    }
    { !props.isDesktop && 
      <>

      </>
    }
    { mode === 'Human Resources' &&
    <>
      <MenuItem as={ReachLink} to='hr/summary' className={({ isActive }) => isActive ? activeClassName : inactiveClassName }>
        Staff Records
      </MenuItem>
      </>
    }
    <MenuItem to='my/profile' as={ReachLink}>
      Profile
    </MenuItem>
    <MenuItem to='my/subscriptions' as={ReachLink}>
      Subscriptions
    </MenuItem>
    <MenuItem display='none' onClick={() =>  props.toggleSidebar('messages')}>
      Messages
    </MenuItem>
    <MenuItem display='none' onClick={() =>  props.toggleSidebar('notifications')}>
      Notifications
    </MenuItem>

    <MenuDivider />

    {show &&
      <>
        <MenuItem href='https://www.pyrexia.io/about' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
          About
        </MenuItem>
        <MenuItem href='https://www.pyrexia.io/features' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
          Features
        </MenuItem>
        <MenuItem href='https://www.pyrexia.io/workflowpay' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
          For Pay
        </MenuItem>
        <MenuItem href='https://www.pyrexia.io/workflowpro' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
          For Professionals
        </MenuItem>
        <MenuItem href='https://www.pyrexia.io/workflow' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
          For Organisations
        </MenuItem>
      </>
    }

    <Text p={2} pl={4} pr={4} cursor='pointer' _hover={{color:'white', bg: 'blue.500'}} onClick={(e) => {e.preventDefault(); setShow(!show)}} >
      {show ? 'Less' : 'More'}
    </Text>
    
    </>
  )
}

let activeClassName = "activeNavLink";
let inactiveClassName = "inactiveNavLink";

  const Profile = (props) => {
   
    let loggedIn = props.loggedIn;
    let mode = props.mode;
    let picture = props.picture;        

    return  (


      <HStack spacing={6}>
        {loggedIn ?
          <>
          
            <Notifications menuWrapper={true} />
                
            {(mode === null || mode === undefined || mode === '' ) &&
              <NavLink id='defaultNavLink' to="onboarding" end className={({ isActive }) => isActive ? activeClassName : inactiveClassName } >
                Onboarding
              </NavLink>
            }
            { mode === 'Healthcare Professional' &&
              <NavLink id='defaultNavLink' to="my/dashboard" end className={({ isActive }) => isActive ? activeClassName : inactiveClassName } >
                Dashboard
              </NavLink>
            }
            { (mode === 'Human Resources' || mode === 'Operations Staff') &&
              <NavLink id='defaultNavLink' to='my/organisations' end className={({ isActive }) => isActive ? activeClassName : inactiveClassName }>
                Dashboard
              </NavLink>
            }
              {process.env.REACT_APP_AUTH_SERVICE === 'COGNITO' &&
                <Status
                  menu={props.menu}
                  picture={picture}
                  />
              }
              {process.env.REACT_APP_AUTH_SERVICE === 'AUTH0' &&
                <>
                  <LogoutButton />
                  <NavLink className='profile-circle' to='my/profile' key={picture}>
                    <Avatar boxSize="10" src={'data:image/jpeg;base64,' + picture} background='primary'/>
                  </NavLink>
                </>
              }
          </>
          :
          <>
            {props.desktop ?
                <>
                  <MenuItem href='https://www.pyrexia.io/about' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                    About
                  </MenuItem>
                  <MenuItem href='https://www.pyrexia.io/features' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                    Features
                  </MenuItem>
                  <Menu>
                    <MenuButton>
                      How it works
                    </MenuButton>
                    <MenuList>
                      <MenuItem href='https://www.pyrexia.io/workflowpay' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                        For Pay
                      </MenuItem>
                      <MenuItem href='https://www.pyrexia.io/workflowpro' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                        For Professionals
                      </MenuItem>
                      <MenuItem href='https://www.pyrexia.io/workflow' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                        For Organisations
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              :
              <Menu >
                <MenuButton order='2' ml={4} className='' borderRadius='50%' p='4px' w='36px' h='36px'>
                  <HamburgerIcon color='black' boxSize='22px' mt='-2px' />
                </MenuButton>
                <MenuList>

                {process.env.REACT_APP_AUTH_SERVICE === 'COGNITO' &&
              <>
                <MenuItem to='login'  as={ReachLink} className={({ isActive }) => isActive ? activeClassName : undefined }>
                Login
                </MenuItem>
                <MenuItem id='signupButton' to='signup'  as={ReachLink} className={({ isActive }) => isActive ? activeClassName : undefined }>
                Sign Up
                </MenuItem>
                <Divider />
                
                </>
              }
                  <MenuItem href='https://www.pyrexia.io/about' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                    About
                  </MenuItem>
                  <MenuItem href='https://www.pyrexia.io/features' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                    Features
                  </MenuItem>
                  <MenuItem href='https://www.pyrexia.io/workflowpay' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                    For Pay
                  </MenuItem>
                  <MenuItem href='https://www.pyrexia.io/workflowpro' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                    For Professionals
                  </MenuItem>
                  <MenuItem href='https://www.pyrexia.io/workflow' as={Link} target="_blank" className={({ isActive }) => isActive ? activeClassName : undefined }>
                    For Organisations
                  </MenuItem>

                </MenuList>
              </Menu>
            }
              {process.env.REACT_APP_AUTH_SERVICE === 'AUTH0' &&
                <LoginPanel />
              }
              {process.env.REACT_APP_AUTH_SERVICE === 'COGNITO' && props.desktop &&
                <Status />
                }
          </>
        }
      </HStack>
    );
  };


  export default function NavBar(props) {

    const [ loggedIn, setLoggedIn ] = useState(null);
    const [token, setToken] = useState('');
    const [mode, setMode] = useState(null);
    const [loading, setLoading] = useState(true);
    // auth0
    const { user, isAuthenticated, isLoading } = useAuth0();
    //Cognito
    const { getSession } = useContext(AccountContext);

    const Interceptor = useContext(InterceptorContext);
    const InterceptorSetup = Interceptor.setup;

    const Kyber = useContext(KyberEncryptionContext);
    let KyberSetup = false;
    if (Kyber) {
      KyberSetup = Kyber.setup;
    }

  
    const [picture, setPicture] = useState(null);


    const getPicture = async (tokenIn) => {

      
      let config = {
        params: {
          userUuid: 'me'
        },
        headers: {
          'Authorization': `Bearer ${tokenIn}`
        }
      }
      
      let picture = await API.getItems({
        url: 'users/picture', 
        config
      })
      .then(res => {
        setPicture(res.data.image);
      })
      .catch(err => {
        console.log("Error getting picture: ", err);
      });

    };
 

    const getMode = async (tokenIn) => {

      let config = {
        headers: {
          'Authorization': `Bearer ${tokenIn}`
        }
      }

      let tmpMode = await API.getItems({
        url: 'users/me/mode', 
        config
      })
      .then(res => {
        setMode(res.data.mode);
      })
      .catch(err => {
        console.log("Error getting mode: ", err);
      });



    };
  
    useEffect(() => {
      
      //console.log("NAVBAR", mode, loggedIn, InterceptorSetup, KyberSetup);
      if (props.test) {
        return
      }

      if (!InterceptorSetup) {
        setLoading(false);
        return
      }
      if (InterceptorSetup && KyberSetup) {
        
        getSession()
            .then(session => {

                setLoggedIn(true);
                // Ensure axios interceptor is set up

                //let resp = setupInterceptors(session.accessToken.jwtToken);
                setToken(session.accessToken.jwtToken);
                getPicture(session.accessToken.jwtToken);
                getMode(session.accessToken.jwtToken);

            })
            .catch(err => {
              setLoggedIn(false);
              setMode('');
            });

      } else {
        setLoggedIn(false);
        setMode(''); 
      }
    }, [InterceptorSetup]);

    useEffect(() => {

      if (mode !== null && loggedIn !== null) {
        setLoading(false);
      }
    }, [mode, loggedIn]);



    const { colorMode, toggleColorMode } = useColorMode();

    const isDesktop = useBreakpointValue({ base: false, md: true });

    let activeClassName = "activeNavLink";

    return (
      <Box as="section" pb='0px'
        h='70px'
        >
        <Box as="nav" h='70px'>
          <Container maxW='unset' h='70px'>
            <Flex justify="space-between" py={{ base: '3'}}>

              <a href='http://www.pyrexia.io' target='_blank'  display='flex' id='logo-home-nav' className={({ isActive }) => isActive ? activeClassName : undefined }>
                <Image src='/logo.png' h='30px' minW='112px' mt='12px' mr={2} alt='Logo' />               
              </a>
              
              <HStack spacing={4} key={mode + picture}>
                
                {loading ?
                <>
                  <Skeleton height='10px' width='100px' ml={6}/>
                  <SkeletonCircle  boxSize='48px' />
                </>
                :
                <Profile 
                  desktop={isDesktop}
                  picture={picture}
                  mode={mode}
                  loggedIn={loggedIn}
                  menu={
                    <HamburgerMenu 
                      desktop={isDesktop} 
                      mode={mode} 
                      toggleSidebar={props.toggleSidebar}/>
                  }
                  />
                }
              </HStack>
              
            </Flex>
          </Container>
        </Box>
      </Box>
    )
  }