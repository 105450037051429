"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var theme_tools_1 = require("@chakra-ui/theme-tools");
var getBaseStyle = function (props) {
    return {
        root: {
            pos: 'relative',
        },
        tab: {
            flex: props.isFitted ? 1 : undefined,
            color: 'subtle',
            textTransform: 'uppercase',
            fontSize: 'sm',
            _selected: {
                color: 'accent',
            },
            _focusVisible: {
                boxShadow: 'none',
            },
        },
    };
};
var getVariantLine = function (props) {
    var _a;
    var colorScheme = props.colorScheme;
    var borderProp = props.orientation === 'vertical' ? 'borderStart' : 'borderBottom';
    return {
        tablist: {
            borderBottom: '2px solid black',
            borderColor: 'gray.400',
            borderStart: 'unset',
        },
        tab: {

            borderBottom: '2px solid black',
            borderColor: 'brand-mid',
            _selected: (_a = {
                    color: 'brand-light'
                },
                _a[borderProp + 'Width'] = '2px',
                _a),
            _disabled: {
                color: 'disabled',
                cursor: 'not-allowed',
            },
        },
        indicator: {
            bg: (0, theme_tools_1.mode)("".concat(colorScheme, ".500"), "".concat(colorScheme, ".400"))(props),
            mt: -1,
            height: 1,
            borderTopRadius: 'base',
            position: 'absolute',
        },
    };
};
exports.default = {
    baseStyle: getBaseStyle,
    variants: {
        line: getVariantLine,
    },
    defaultProps: {
        variant: 'line',
        colorScheme: 'green',
    },
};
