
import React, { useState, useEffect, useContext } from 'react';
import { UnorderedList, SimpleGrid, Grid, Box, MenuList, MenuItem, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, Menu } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdUndo, MdOutlineModelTraining,MdOutlineVisibility, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import Navigation from './components/Navigation';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Card from '../../core/components/cards/Card';
import { set } from 'date-fns';
import Sidebar from './components/Sidebar';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import { AITask } from '../../mixed/assistant/Assistant';

let applicant = {
    'name': 'John Doe',
    'status': 'Shortlisted',
    'date': '2021-09-01',
    'scores': {
        'Application': {
            "score": 4.3,
            "average": 3.5,
            "appointableScore": 4.0,
            "comments": "Very good application."
        },
        'Interview': {
            "score": 3.8,
            "average": 3.5,
            "appointableScore": 4.0,
            "comments": "Good interview."
        },
        'References': {
            "score": 4.5,
            "average": 3.5,
            "appointableScore": 4.0,
            "comments": "Excellent references."
        }
    }
}

export default function Decision(props) {
    const location = useLocation();

    const [outcome, setOutcome] = useState(null);
    const [email, setEmail] = useState(null);
    const [sent, setSent] = useState(false);

    return (

        <Layout
            title={applicant.name}
            layout='dense'
            subtitle='This is a summary of the applicant and their scores.'
            actionContent={
                <Navigation job={location.state.job} />
            }
            >

                <GridItem colSpan={4}>

                    <Card title='Scores' p={0}>

                            <Row c={6} buttons='hide'>
                                <Cell c={3}>
                                    <strong>Category</strong>
                                </Cell>
                                <Cell c={1}>
                                    <strong>Appointable</strong>
                                </Cell>
                                <Cell c={1}>
                                    <strong>Average</strong>
                                </Cell>
                                <Cell c={1}>
                                    <strong>Score</strong>
                                </Cell>
                            </Row>

                            {Object.keys(applicant.scores).map((key, index) => (
                                <Row c={6} buttons='hide'>

                                    <Cell c={3} key={index}>
                                        <strong>{key}</strong>
                                    </Cell>
                                    <Cell c={1} key={index}>
                                        {applicant.scores[key].appointableScore}
                                    </Cell>
                                    <Cell c={1} key={index}>
                                        {applicant.scores[key].average}
                                    </Cell>
                                    <Cell c={1} key={index}>
                                        {applicant.scores[key].score}
                                    </Cell>

                                    <Cell c={6} key={index}>
                                        {applicant.scores[key].comments}
                                    </Cell>
                                </Row>

                            ))}

                            <Row c={6} buttons='hide' bg='blue.50'>
                                
                                <Cell c={5}>
                                    <strong>Total</strong>
                                </Cell>
                                <Cell c={1}>
                                    <strong>
                                    {// Summ of all scores
                                        Object.keys(applicant.scores).reduce((total, key) => total + applicant.scores[key].score, 0)    
                                    }
                                    </strong>
                                </Cell>

                            </Row>

                            <Row c={6} buttons='hide'>

                                <Cell c={5}>
                                    <strong>Decision</strong>
                                </Cell>
                                <Cell c={1}>

                                    {outcome ?
                                        <Wrap spacing={2}>
                                            <Tag size='sm' variant='outline' colorScheme={outcome === 'Accepted' ? 'green' : 'red'}>
                                                {outcome}
                                            </Tag>
                                            <IconButton colorScheme='gray' variant='outline' size='xs'
                                                onClick={() => setOutcome(null)}
                                                icon={<MdUndo />} />
                                        </Wrap>
                                        :
                                        <ButtonGroup size='xs'>
                                            <Button colorScheme='green' variant='outline'
                                                onClick={() => setOutcome('Accepted')}
                                                >Accept</Button>
                                            <Button colorScheme='red' variant='outline'
                                                onClick={() => setOutcome('Rejected')}
                                                >Reject</Button>
                                        </ButtonGroup>
                                    }

                                </Cell>

                            </Row>
                    </Card>



                    <Card title='Comments' p={0} >

                        {outcome &&
                            <Alert status={outcome === 'Accepted' ? 'success' : 'error'} variant='subtle'>
                                <AlertIcon />
                                <AlertTitle>{outcome === 'Accepted' ? 'Accepted' : 'Rejected'}</AlertTitle>
                                <AlertDescription>
                                    The applicant has been {outcome.toLowerCase()}.
                                </AlertDescription>
                            </Alert>
                        }

                        <Box p={4}>
                        {outcome ?
                            <AITask
                                title='E-mail response'
                                description='Generate a response to the applicant.'
                                prompt={'Write a response to the applicant called ' + applicant.name + ' to inform them of the outcome of their application. The response should be polite and professional and the outcome is that they have been ' + outcome.toLowerCase() + '. The detailed scoring is: ' + JSON.stringify(applicant.scores) + '. Please include any additional comments or feedback.'}
                                onCompletion={(response) => setEmail(response)}
                                />
                            :

                            <Text>
                                Please select an outcome.
                            </Text>
                        }

                        {email &&
                        
                            <Stack spacing={4}>
                            <InputWrapper
                                label='E-mail'
                                value={email}
                                type='textarea'
                                isDisabled={sent}
                                onChange={(e) => setEmail(e.target.value)}
                                />

                            {sent ?
                                <Text color='green.500'>
                                    E-mail sent to {applicant.name} at {moment().format('MMMM Do YYYY, h:mm:ss a')}.
                                </Text>
                                :
                                <Button colorScheme='blue' variant='outline'
                                    onClick={() => setSent(true)}
                                    >Send</Button>
                            }

</Stack>
                        }

                        </Box>

                    </Card>

                </GridItem>

                <GridItem colSpan={2}>

                        {location.state && location.state.job &&
                            <Sidebar job={location.state.job} />
                        }

                </GridItem>

        </Layout>
    )
}