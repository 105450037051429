import {Button, Icon} from '@chakra-ui/react';
import { MdArrowForward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export default function CallToActionButton(props) {

    const navigate = useNavigate;
    return (

        <Button
        className={'call-to-action-btn ' + props.className} 
        borderRadius='24px'
        pl={6}
        pr={6}
        mt={6} mb={4}
        backgroundImage='linear-gradient(to left, #fb594b, #5321bf)'
        onClick={props.onClick}
        id={props.id}
        border='0px'
      >
        {props.text}
      </Button>
    )
}