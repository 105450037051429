const current = {
        stage: 'Clinical Review',
        label: 'ADHD Digital Tool',
        procurementDescription: 'This is the procurement of an ADHD digital tool for use in the paediatric department.',
        status: 'completed',
        description: 'This is the clinical review of the procurement, this is important to define the clinical requirements of the procurement and consider factors like clinical governance and clinical leads.',
        totalSteps: 7,
        completedSteps: 7,
        start: '2024-02-01',
        due: '2024-09-01',
        owner: 'Oliver',
        steps: [
          {
            label: 'Clinical Specification',
            status: 'completed',
            assigned: {
                name: 'Oliver Trampleasure',
                email: 'oliver@pyreixa.io',
                role: 'Paediatric Consultant',
            },
            completed: '2021-09-01',
            outcome: 'text',
            description: 'This is the clinical specification of the procurement, it will define the clinical requirements of the procurement.',
            sources: [{
                name: 'Paediatric Guidelines',
                type: 'folder',
            },
            {
                name: 'Clinical Guidelines',
                type: 'folder',
            },
            {
                name: 'NICE Guidelines',
                type: 'website',
            },
            {
                name: 'ADHD Tools',
                type: 'document'
            }],
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
            locked: '2021-09-01',
            user_edited: true,
          },
          {
            label: 'Clinical Description',
            status: 'completed',
            assigned: {
                name: 'Oliver Trampleasure',
                email: 'oliver@pyreixa.io',
                role: 'Paediatric Consultant',
            },
            completed: '2021-09-01',
            outcome: 'text',
            description: 'Describe the product and its intended use in a clinical setting.',
            sources: [{
                name: 'Paediatric Guidelines',
                type: 'folder',
            },
            {
                name: 'Clinical Guidelines',
                type: 'folder',
            }],
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',            locked: null,
          },
          {
            label: 'Clinical Review',
            status: 'completed',
            assigned: {
                name: 'Ali Jawad',
                email: 'ali@pyreixa.io',
                role: 'Clinical Lead',
            },
            completed: '2021-09-01',
            outcome: 'text',
            description: 'This is the clinical review of the procurement, it is a short passage of text.',
            sources: [{
                name: 'Paediatric Guidelines',
                type: 'folder',
            },
            {
                name: 'Clinical Guidelines',
                type: 'folder',
            }],
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
            locked: '2021-09-01',
          },
          {
            label: 'Clinical Governance',
            status: 'completed',
            assigned: {
                name: 'Ali Jawad',
                email: 'ali@pyreixa.io',
                role: 'Clinical Lead',
            },
            completed: '2021-09-01',
            outcome: 'text',
            description: 'This is the clinical review of the procurement, it is a short passage of text.',
            sources: [{
                name: 'Paediatric Guidelines',
                type: 'folder',
            },
            {
                name: 'Clinical Guidelines',
                type: 'folder',
            }],
            locked: null,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
            aiValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
          },
          {
            label: 'Clinical Leads',
            status: 'completed',
            assigned: {
                name: 'Oliver Trampleasure',
                email: 'oliver@pyreixa.io',
                role: 'Paediatric Consultant',
            },
            completed: '2021-09-01',
            outcome: 'text',
            sources: [{
                name: 'Paediatric Guidelines',
                type: 'folder',
            },
            {
                name: 'Clinical Guidelines',
                type: 'folder',
            }],
            locked: null,
          },
          {
            label: 'Clinical Team',
            status: 'completed',
            assigned: {
                name: 'Sarah Jane',
                email: 'sarah@pyreixa.io',
                role: 'Clinical Nurse Specialist',
            },
            completed: '2021-09-01',
            outcome: 'text',
            sources: [{
                name: 'Paediatric Guidelines',
                type: 'folder',
            },
            {
                name: 'Clinical Guidelines',
                type: 'folder',
            }],
            locked: null,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
            aiValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
          },
          {
            label: 'Clinical Sign Off',
            status: 'completed',
            assigned: {
                name: 'Sarah Jane',
                email: 'sarah@pyreixa.io',
                role: 'Clinical Nurse Specialist',
            },
            completed: '2021-09-01',
            outcome: 'approval',
            sources: [{
                name: 'Paediatric Guidelines',
                type: 'folder',
            },
            {
                name: 'Clinical Guidelines',
                type: 'folder',
            }],
            locked: null,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
            aiValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut est sem. Fusce cursus elementum nunc vel egestas. Mauris luctus mi non condimentum lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum mattis pretium sem sed elementum. Donec vehicula magna et pulvinar tempus. Sed interdum vel tortor in blandit.',
          }
        ]
};

export default current;
