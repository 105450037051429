import React, { useState, useEffect, useContext } from 'react';
import { Image, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../core/utils/API";
import { MdAdd, MdInfoOutline, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../static/layout/Loading';
import Splash from '../static/layout/Splash';
import Layout from '../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import {Header, Row, Cell} from '../core/components/lists/List';
import pluralize from 'pluralize';

import OrganisationOnboarding from '../organisations/onboarding/Onboarding';

import { router } from "../App";
import Card from '../core/components/cards/Card';
import secureLocalStorage from 'react-secure-storage';
import { set } from 'date-fns';

export default function RoleSelect(props) {

    const [organisations, setOrganisations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showOnboarding, setShowOnboarding] = useState(false);

    const navigate = useNavigate();
    // Get list of organisations available (via list of roles)

    function setOrganisation(uuid, title, count, roles, permissions, subscriptions, product_ids) {

        secureLocalStorage.removeItem('organisationUuid');
        secureLocalStorage.removeItem('organisationTitle');
        secureLocalStorage.removeItem('organisationRoles');
        secureLocalStorage.removeItem('organisationPermissions');
        secureLocalStorage.removeItem('organisationCount');
        secureLocalStorage.removeItem('organisationSubscriptions');
        secureLocalStorage.removeItem('organisationProductIds');

        secureLocalStorage.setItem('organisationTitle', title);
        secureLocalStorage.setItem('organisationRoles', roles);
        secureLocalStorage.setItem('organisationPermissions', permissions);
        secureLocalStorage.setItem('organisationCount', count);
        secureLocalStorage.setItem('organisationUuid', uuid);
        secureLocalStorage.setItem('organisationSubscriptions', subscriptions);
        secureLocalStorage.setItem('organisationProductIds', product_ids);

        console.log("Set organisation", title, roles, permissions, count, uuid, subscriptions, product_ids);
 
        navigate('/organisation/');
    
    }

    function getOrganisations() {

        let url = 'roles/mine';
        let config = {
            params: {
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log("Orgs in role select", response.data);
            
            let orgs = response.data.organisations;
            
            // check if length is 1, therefore just load that org
            if (Object.keys(orgs).length === 1) {
                let key = Object.keys(orgs)[0];

                setOrganisation(key, orgs[key].organisation.title, 1, orgs[key].roles, orgs[key].permissions, orgs[key].subscriptions, orgs[key].product_ids);

            }   

            
            setOrganisations(orgs);
            setLoading(false);

        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        }

        );

    }

    useEffect(() => {

        // Reset local storage
        secureLocalStorage.removeItem('organisationUuid');
        secureLocalStorage.removeItem('organisationTitle');
        secureLocalStorage.removeItem('organisationRoles');
        secureLocalStorage.removeItem('organisationPermissions');
        secureLocalStorage.removeItem('organisationCount');

        getOrganisations();

    }, []);

    return (
        <>
            {showOnboarding &&
                <OrganisationOnboarding 
                    onCancel={() => {
                        console.log("Cancel onboarding");
                        setShowOnboarding(false);
                    }}  
                    refresh={() => {
                        getOrganisations();
                        setShowOnboarding(false);
                    }}
                    />
            }
            {!loading && //!showOnboarding &&
                <Layout title="Organisations"
                    description="Select the organisation you would like to work with today"
                    actionContent={
                        <ButtonGroup variant='outline'>
                        <Button onClick={() => {
                            navigate('/my/dashboard');
                        }}      
                        colorScheme='blue'                   
                        >Switch Role</Button>
                            <Button onClick={() => {
                                setShowOnboarding(true);

                            }} colorScheme='red'>Create</Button>

                        </ButtonGroup>
                    }
                    >
                        <GridItem colSpan={12}>
                            {loading ? <LoadingCircle /> : 
                                    <Wrap spacing="4" pb='70px'>
                                        {Object.keys(organisations).length > 1 &&
                                            Object.keys(organisations).map(orgKey => {
                                            let organisation = organisations[orgKey];
                                            return (
                                                <Card
                                                    onClick={() => {
                                                        setOrganisation(orgKey, organisation.organisation.title, organisation.count, organisation.roles, organisation.permissions, organisation.subscriptions, organisation.product_ids);
                                                    }}
                                                    borderWidth='2px'
                                                    borderColor='transparent'
                                                    cursor='pointer'
                                                    w='292px'
                                                    _hover={{bg: 'bg.surface', borderColor: 'primary.500', shadow: 'lg' }}
                                                    >
                                                    <Flex justifyContent='space-between' alignItems='center' flexDirection='column'>
                                                        <Box w='180px' h='100px' mb={6}>
                                                            <Image objectFit='contain' h='100%' w='100%'
                                                                src={'/images/partners/' + orgKey + '.png'}
                                                                fallbackSrc='/images/partners/default.png' 
                                                                />
                                                        </Box>
                                                        <Heading size='label' key={orgKey} textAlign='center' mb={1}>
                                                            {organisation.organisation.title}
                                                        </Heading>
                                                        {// check if organisation.roles is an array
                                                            organisation.roles && Array.isArray(organisation.roles) &&
                                                            <Text color='subtle' textAlign='center'  lineHeight='1.2em' fontSize='sm'>
                                                                {organisation.roles.join(', ')}
                                                            </Text>
                                                        }
                                                    </Flex>
                                                </Card>
                                            )
                                        })}
                                        {Object.keys(organisations).length === 0 &&
                                                <Card
                                                    onClick={() => {
                                                        // Bring up onboarding process
                                                        setShowOnboarding(true);
                                                    }}
                                                    borderWidth='2px'
                                                    borderColor='transparent'
                                                    cursor='pointer'
                                                    w='292px'
                                                    _hover={{bg: 'bg.surface', borderColor: 'primary.500', shadow: 'lg' }}
                                                    >
                                                    <Flex justifyContent='space-between' alignItems='center' flexDirection='column'>
                                                        <Icon as={MdAdd} w='180px' h='100px'  mb={6}/>
                                                        <Heading size='label' key='new-org' textAlign='center' mb={1}>
                                                            Create Organisation
                                                        </Heading>
                                                        <Text color='subtle' textAlign='center' lineHeight='1.2em' fontSize='sm'>
                                                            You can create a new organisation.
                                                        </Text>
                                                    </Flex>
                                                </Card>
                                        }
                                        
                                                <Card
                                                    onClick={() => {
                                                        // Bring up onboarding process
                                                        setShowOnboarding(true);
                                                    }}
                                                    borderWidth='2px'
                                                    borderColor='transparent'
                                                    cursor='pointer'
                                                    w='292px'
                                                    m={0}
                                                    _hover={{bg: 'bg.surface', borderColor: 'primary.500', shadow: 'lg' }}
                                                    >
                                                    <Flex justifyContent='space-between' alignItems='center' flexDirection='column'>
                                                        <Icon as={MdInfoOutline} w='180px' h='100px'  mb={6}/>
                                                        <Heading size='label' key='new-org' textAlign='center' mb={1} >
                                                            Existing Organisations
                                                        </Heading>
                                                        <Text color='subtle' textAlign='center' lineHeight='1.2em' fontSize='sm' >
                                                            Contact your organisation's administrator for access.
                                                        </Text>
                                                    </Flex>
                                                </Card>
                                        
                                        

                                    </Wrap>
                            }
                        
                        </GridItem>

                </Layout>
            }
        </>
    )

}