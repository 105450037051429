import {Flex, Icon, Button, Box, Container, Heading, Image, Stack, Text, useColorMode, HStack } from '@chakra-ui/react'
import Hero from './Hero.png';
import { MdArrowForward } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';

export default function Features() {
  const { colorMode } = useColorMode()
  const navigate = useNavigate();
  return (
    <Box as="section">
      <Box pb={12}>
        <Container
          pt={{
            base: '16',
            md: '36',
          }}
          pb={{
            base: '18',
            md: '28',
          }}
          mb={{
            base: '16',
            md: '10',
          }}
        >
          <Stack
            spacing={{
              base: '8',
              md: '10',
            }}
            align="center"
          >
            <Stack
              spacing={{
                base: '4',
                md: '5',
              }}
              textAlign="center"
            >
              <Stack spacing="3" alignItems='center'>
               
                <Heading size='pagetitle'  mb="12">
                Is every new job a frustrating HR experience?
                </Heading>
                

              </Stack>

              <Flex maxW='100%' justifyContent='center' direction={{base:'column' }}  pt={8}>
                <Box m={{base: 'auto', md: '4'}}>
                  <Image src='/images/CombinedLandscape.png' alt="Flow Diagram" />
                </Box>
              </Flex>

            </Stack>
          </Stack>
        </Container>

      </Box>
    </Box>
  )
}