import React, { useEffect, useState } from "react";
import { Badge, Box, Text } from "@chakra-ui/react";

const Clock = ({ deadline, style, end }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const units = ['days', 'hours', 'minutes', 'seconds'];
  const [listString, setListString] = useState('');
  const [color, setColor] = useState('inverted');

  let interval = null;

  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      end();
      
    } else {
        let tmpDays = Math.floor(time / (1000 * 60 * 60 * 24));
        let tmpHours = Math.floor((time / (1000 * 60 * 60)) % 24);
        let tmpMinutes = Math.floor((time / 1000 / 60) % 60);
        let tmpSeconds = Math.floor((time / 1000) % 60);
        
        setDays(tmpDays);
        setHours(tmpHours);
        setMinutes(tmpMinutes);
        setSeconds(tmpSeconds);
/*
        let listUnits = [];

        if (tmpDays > 0) {
            listUnits.push(tmpDays + ' days');
        }
        if (tmpHours > 0) {
            listUnits.push(tmpHours + ' hours');
        }
        if (tmpMinutes > 0) {
            listUnits.push(tmpMinutes + ' minutes');
        }
        if (tmpSeconds > 0) {
            listUnits.push(tmpSeconds + ' seconds');
        }

        let tmpListString = listUnits.join(', ');
        // Replace last comma with 'and'
        tmpListString = tmpListString.replace(/,([^,]*)$/, ' and $1');

        */

        setListString(leading0(tmpMinutes) + ':' + leading0(tmpSeconds));

        // Check if under 5min
        if (tmpDays === 0 && tmpHours === 0 && tmpMinutes < 5) {
            setColor('red.500');
        }
    }
  };

  useEffect(() => {
    
    // Check if deadline is in the past
    if (Date.parse(deadline) - Date.parse(new Date()) < 0) {
      
      console.log("clearing");
      // Stop timeout as reached deadline
      // need to clear timeout
      clearInterval(interval);
      interval = null;

    } else if (!interval) {

      // Start timeout for 1 second to update clock
      interval = setInterval(() => getTimeUntil(deadline), 1000);

    }

    return () => getTimeUntil(deadline);
  }, [deadline]);

  return (
    <Text as='span' color='white' borderRadius='0.5em'
        >
    
        {style === 'list' ?
            <>
                {listString ? 
                    listString
                :   
                '...'}
            </>
            
            :

            <>
                <div className="Clock-days">{leading0(days)} Days</div>
                <div className="Clock-hours">{leading0(hours)} Hours</div>
                <div className="Clock-minutes">{leading0(minutes)} Minutes</div>
                <div className="Clock-seconds">{leading0(seconds)} Seconds</div>

            </>
        }   
    </Text>
  );
};

export default Clock;
