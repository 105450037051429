import httpClient from './AxiosConfig';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';

const MYAPI= process.env.REACT_APP_API_SERVER_URL;
const ROOTAPI = process.env.REACT_APP_ROOT_SERVER_URL;

export default {


    getItemsRaw: async (props) => {

        if (props.root === true) {
            httpClient.defaults.baseURL = ROOTAPI;
        }
        if (props.timeout != undefined) {
            console.log('Setting timeout to ' + props.timeout);
            httpClient.defaults.timeout = props.timeout * 1000;
        }

/*        let config = {
            headers: {  
                'Authorization': `Bearer ${token}`,
            }
        }*/
        
        const response = await httpClient.get(`${props.url}`, props.config);

        return response;

    },

    getItems: async (props) => {

        //console.log('API CALL');
        //console.log(props);
        //console.log("API UTIL URL = " + props.url);
        //console.log("API UTIL URL = " + MYAPI + props.url);
//        const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

if (props.timeout != undefined) {
    //console.log('Setting timeout to ' + props.timeout);
    httpClient.defaults.timeout = props.timeout * 1000;
}

        /*let config = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'sub': props.sub
            }
        }
*/
if (props.root === true) {
    httpClient.defaults.baseURL = ROOTAPI;
}
        const response = await httpClient.get(`${props.url}`, props.config);
        
        //console.log(response);

        return response;

    },


    postItems: async (props) => {



        // Check if props includes a higher timeout value
        // If so, set the timeout to that value
        if (props.timeout != undefined) {
            //console.log('Setting timeout to ' + props.timeout);
            httpClient.defaults.timeout = props.timeout * 1000;
        }
       
        if (props.root === true) {
            httpClient.defaults.baseURL = ROOTAPI;
        }

        
/*
        if (props.data) {
            try {
                for (let [key, value] of props.data.entries()) {
                   // console.log(`${key}: ${value}`);
                }
                if (props.data.file) {
                    for (let [key, value] of props.data.file.entries()) {
                      //  console.log(`${key}: ${value}`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
*/

        let tmpConfig = props.config;
        let tmpData = props.data;
        if (!props.data) {
            tmpData = props.config.data;
            // remove data from config
            delete tmpConfig.data;
        }
        
        const response = await httpClient.post(`${MYAPI+props.url}`, tmpData, tmpConfig);
        
        //console.log(response); 

        return response;

    },



    postItemsRaw: async (props) => {



        // Check if props includes a higher timeout value
        // If so, set the timeout to that value
        if (props.timeout != undefined) {
           // console.log('Setting timeout to ' + props.timeout);
            httpClient.defaults.timeout = props.timeout * 1000;
        }
       
        if (props.root === true) {
            httpClient.defaults.baseURL = ROOTAPI;
        }

        let tmpConfig = props.config;
        let tmpData = props.data;
        if (!props.data) {
            tmpData = props.config.data;
            // remove data from config
            delete tmpConfig.data;
        }

        const response = await httpClient.post(`${props.url}`, tmpData, tmpConfig);
        
        //console.log(response);

        return response;

    },
    

    putItems: async (props) => {


        if (props.root === true) {
            httpClient.defaults.baseURL = ROOTAPI;
        }

        if (props.timeout != undefined) {
            //console.log('Setting timeout to ' + props.timeout);
            httpClient.defaults.timeout = props.timeout * 1000;
        }
        const response = await httpClient.put(`${MYAPI+props.url}`, props.data);
        
        //console.log(response);

        return response;

    },


    patchItems: async (props) => {


        if (props.timeout != undefined) {
            console.log('Setting timeout to ' + props.timeout);
            httpClient.defaults.timeout = props.timeout * 1000;
        }
        if (props.root === true) {
            httpClient.defaults.baseURL = ROOTAPI;
        }
        const response = await httpClient.patch(`${MYAPI+props.url}`, props.data, props.config);

        return response;

    },

    deleteItems: async (props) => {


        if (props.timeout != undefined) {
            console.log('Setting timeout to ' + props.timeout);
            httpClient.defaults.timeout = props.timeout * 1000;
        }
        if (props.root === true) {
            httpClient.defaults.baseURL = ROOTAPI;
        }
        const response = await httpClient.delete(`${MYAPI+props.url}`, props.config);

        return response;

    }


}
