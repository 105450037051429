import React from 'react'
import { Box, Text, Icon, Flex } from '@chakra-ui/react'
import {MdCheckCircle, MdErrorOutline, MdInfoOutline, MdWarning} from 'react-icons/md'

export default function Splash(props) {

    //console.log("Splash props", props);
    let maxW = '400px'
    if (props.maxW) {
        maxW = props.maxW
    }

    return (
        <Flex flexDirection='column' className='splash-wrapper' justifyContent='center' alignItems='center' flex='1 1 100%' height='fill-available' maxH='100vh' overflowY='scroll'>
            <Flex maxH='100vh' overflowY='scroll' justifyContent='center' flexWrap='wrap' className='splash-container' ml={4} mr={4} id='splash-box' {...props} minH='fit-content' maxW={{base: '100%', md: maxW}} minW={{base: 'auto', md: props.minW}} boxShadow='' p={4} borderRadius='sm' border='0px solid black'>

                {props.icon ? 
                    <Box lineHeight='1em' className='splash-icon' width='100%' textAlign='center' fontSize='96px' color={props.iconColor ? props.iconColor : 'gray.500'}>
                        <Icon as={props.icon} />
                    </Box> 
                    : 
                    null
                }
                {props.children}
            </Flex>
            {props.footer ? <Box mt={4} p='0' pl='4.5em' pr='4.5em' maxW={{base: '100%', md: maxW}} >{props.footer}</Box> : null}
        </Flex>
    )
}