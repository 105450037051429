
import {Input ,Text, InputRightElement, InputGroup, Button, FormControl, FormLabel, FormHelperText, Checkbox, Radio, RadioGroup } from '@chakra-ui/react';

export default function InputWrapper(props) {

    let onChange = props.onChange;

    if (onChange === undefined ) {

        //console.log("No onChange function passed to InputWrapper, using default");
        onChange = function(e) {
            props.setValue( props.name, e.target.value);
        }
    }

    return (
        <>
        {props.type === 'email' &&
    <>
        <Input 
            {...props}
            type='email'
            onChange={onChange}
            fontSize='md'
            />
        <Text>{props.options}
        </Text>
    </>

        }
        
        {(props.type !== 'password' && props.type != 'email' )&&

            <Input 
                {...props}
                onChange={onChange}
                />

        }

        {props.type === 'password' &&

            <Input 
                {...props}
                type='password'
                onChange={onChange}
                />

        }
</>

    )
}