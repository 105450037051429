import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import WorkSchedule from '../prospectiveleave/WorkSchedule';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';


export default function Schedule(props) {

    const [assignedForms, setAssignedForms] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    

    function updateList() {


        let url = 'forms/assigned';
        let config = {
            params: {
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log("Assigned forms", response.data);
            let sorted = response.data.sort((a, b) => {
                return moment(a.due).diff(moment(b.due));
            });

            setAssignedForms(sorted);
            setLoading(false);

        })
        .catch(error => {
            console.log(error);
        }

        );

    }
    useEffect(() => {

        updateList();

    }, []);

    function viewForm(formSubmissionUuid, formUuid) {
        //console.log("View Schedule", uuid);
        navigate('view', {state: {formSubmissionUuid: formSubmissionUuid, uuid: formUuid}});
    }



    return (

        <>

        <Layout
            title="Your Forms"
            description="This is a list of forms you have been assigned."
            actionContent={
                <>
                </>
            }
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>


                    {loading ? <LoadingCircle /> : null}

                    {assignedForms ?

                        
                    <Box>
            
                    <Header c={8}>
                        <Cell c={2}>
                            Form
                        </Cell>
                        <Cell c={2}>
                            Requester
                        </Cell>
                        <Cell c={2}>
                            Due
                        </Cell>
                        <Cell>
                            Status
                        </Cell>
                    </Header>


                    {assignedForms.map((form) => {

                        let status = form.status;
                        let color = 'gray';
                        let variant = 'outline';
                        let buttonText='Fill in';
                        let buttonColor='blue';

                        if (status === 'complete') {
                            status='Complete'
                            color = 'green';
                            buttonText='View';
                            buttonColor='gray';
                        }
                        if (status === 'pending') {
                            status='Pending'
                            color = 'yellow';
                        }
                        if (form.due && new Date(form.due) < new Date() && status !== 'complete') {
                            status = 'Overdue';
                            color = 'red';
                        }
                        if (form.expires && new Date(form.expires) < new Date() && status !== 'complete') {
                            status = 'Expired';
                            color = 'red';
                            variant = 'solid';
                            buttonText='View';
                            buttonColor='gray';
                        }

                        return (
                            <Row c={8}
                                buttons={
                                        <Button 
                                            colorScheme={buttonColor}
                                            variant={variant}
                                            onClick={() => {
                                                viewForm(form.uuid, form.formUuid);
                                            }}
                                            >
                                            {buttonText}
                                        </Button>
                                }
                                >
                                <Cell c={2}>
                                    <Heading size='label'>{form.Form.title}</Heading>
                                    <Text fontSize='xs' color='subtle'>{form.Form.description}</Text>
                                </Cell>
                                <Cell c={2}>
                                    {form.Requester &&
                                        <Text>{form.Requester.firstName + ' ' + form.Requester.lastName}</Text>
                                    }
                                    {form.Organisation && 
                                        <Text>{form.Orgnisation.uuid}</Text>
                                    }
                                </Cell>
                                <Cell c={2}>
                                    {form.due &&
                                        <Text><Moment format="HH:mm DD/MM/YYYY">{form.due}</Moment></Text>
                                    }
                                    {status != 'Complete' && form.expires &&
                                        <Text>Expires: <Moment format="HH:mm DD/MM/YYYY">{form.expires}</Moment></Text>
                                    }
                                </Cell>
                                <Cell>
                                    <Tag size='xs' colorScheme={color} variant={variant}>{status}</Tag>
                                </Cell>
                            </Row>
                        )
                    })}

                </Box>

                        :

                        <Box>
                            <Text>
                                You have no forms.
                            </Text>
                        </Box>

                    }

                </GridItem>

            </Layout>

        </>
    )
}