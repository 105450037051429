import React from 'react';
import { useState} from 'react';
import { useEffect } from "react";
import { 
    Wrap,
    ButtonGroup,
    Select,
    Divider,
    Link,
    FormControl,
    FormLabel,
    Tooltip,
    FormErrorMessage,
    FormHelperText,
    Radio,
    RadioGroup,
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    ListItem,
    OrderedList,
    UnorderedList,
    SliderMark,
    Editable,
    EditableInput,
    EditableTextarea,
    Tag, 
    EditablePreview, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Spinner, chakra, useCheckboxGroup, useCheckbox, GridItem, Checkbox, Box, Heading, Text, Button, Input, Stack, useColorModeValue, Center, Image, Flex, Spacer, useToast, CheckboxGroup, TagCloseButton, InputGroup, TagLeftIcon, Grid, SimpleGrid} from '@chakra-ui/react';
import API from '../../core/utils/API';
import { useForm, useController, Controller } from "react-hook-form";
import Splash from '../../static/layout/Splash';
import CreatableSelect from 'react-select/creatable';
import Specialties from './GeneralPractice';
import Loading from '../../static/layout/Loading';
import { MdCheck, MdClose, MdContentCopy } from 'react-icons/md';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';

let Teacher = [
    "Medical Student",
    "Foundation Year 1 Doctor (FY1)",
    "Foundation Year 2 Doctor (FY2)",
    "Core Trainee (CT1/2) or Junior Clinical Fellow (JCF)",
    "Specialty Registrar (StR) or Senior Clinical Fellow (SCF)",
    "General Practice Trainee (GP Trainee)",
    "General Practitioner (GP)",
    "Consultant"
]

let Audience = [
    "Pre-clinical medical students",
    "Clinical medical students",
    "Foundation Year 1 doctors (FY1)",
    "Foundation Year 2 doctors (FY2)",
    "Core Trainees (CT1/2)",
    "Speciality Trainees (ST4-8)",
    "Consultants",
    "General practitioners",
    "Nurses",
    "Allied Health Professionals"
]



let Duration = [
    "10 minutes",
    "15 minutes",
    "20 minutes",
    "30 minutes",
    "45 minutes",
    "1 hour",
    "1.5 hours",
    "2 hours",
    "2.5 hours",
    "3 hours",
    "Half-day session",
    "Full-day session",
]


let Setting = [
    "Bedside",
    "Small group seminar/tutorial",
    "Lecture",
    "Clinical skills lab",
    "Simulation centre (high-fidelity clinical scenarios)",
    "Online webinar",
    "Virtual learning environment (VLE) module",
    "Video-based teaching",
    "Grand rounds",
    "Case-based discussion (CBD) session",
    "Problem-Based Learning (PBL) session",
]


        

function CheckBoxButtons(props) {

    return (
        <Box>
            {props.data.values &&
                <Wrap spacing={2}>
                    {props.data.values.map((option, index) => {

                        return (
                            <Button colorScheme='blue' variant='outline' className='btn-8' onClick={() => {console.log("clicked", props.data.label, option.valueLabel); props.setCheckboxButtonValue(props.data.valueLabel, option.label, index);}}>{option.label}</Button>
                        )
                    })}
                </Wrap>
            }
        </Box>
    )
}


function CopyeableText(props) {

    const toast = useToast();

    const generateToast = () => {
        toast({
            title: "Copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
        })
    }

    return (
        <Box cursor='pointer' _hover={{color: 'blue.500'}}
            onClick={() => {
                navigator.clipboard.writeText(props.text); 
                generateToast();
            }}>
            <Heading size='label' mb='0.5em' {...props.titleProps}>
                {props.title}
                <Icon ml='0.5em' 
                    boxSize='12px' 
                    opacity='0.5' 
                    as={MdContentCopy} color='black' w={4} h={4} />
            </Heading>
            <Text>
                {props.text} 
            </Text>
        </Box>
    )
}


function DesignBox(props) {

    return (

        <Box className='hover-only' p='2px' borderRadius="lg" overflow="hidden" {...props}>
            <Box position='relative' p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" bg='white'>

                {props.children}

            </Box>
        </Box>


    )
}

function ListWithReflection(props) {

    //console.log(props.title, props.array);

    return (

        <Stack spacing={2}>
            {props.array.map((el) => {
                return (

                    <CopyeableText 
                        title={el.title}
                        titleProps={{size: 'label', mb: '4px', color: 'blue.500'}}
                        text={el.reflection}
                        />

                    )
                }
            )}    
        </Stack>

    )
}

export default function Reflection(props) {


    const navigate = useNavigate();
    // Import JSON data from ./GeneralPractice.js
    const [specialties, setSpecialties] = useState(Specialties);
    const [tags, setTags] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const { setValue, getValues, control, register, handleSubmit, watch, formState: { errors } } = useForm();
    const [apiResponse, setApiResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [data, setData] = useState({});
    const [json, setJson] = useState({});
    const [attempts, setAttempts] = useState(0);

    const [questionObject, setQuestionObject] = useState(Specialties);

    const reset = () => {

        setStep(1);
        setTags([]);
        setKeywords([]);
        setQuestionObject(Specialties);
        setApiResponse("");

    }

    useEffect(() => {
        //console.log(tags, tags.length, step);

        // 4 5 too low
        if (step === 6 && tags !== undefined && tags.length === 4) {
            console.log("Submitting");
            onSubmit();
        }
    }, [tags, step])


    const onSubmit = async (data = {}) => {

        //console.log("Submitting 2")
        setLoading(true);

        
        data['tree'] = tags;
        data['keywords'] = keywords;

        //console.log(data);

        const result = await API.postItems({url: "generate/teaching", data}).then(function (response) {
            //console.log(response);

            // Extract JSON data from response
            // there is other text before and after the json object
            // so we need to extract it
            let uuid = response.data.uuid;

            // Redirect to TeachingView page
            navigate('/my/ai/teaching/view', {state: {uuid: uuid}});

        })
    }

    return (

        <>

        {loading &&
            
            <Loading 
                message='Submitting teaching session'
                />
        
        }

        {!apiResponse && !loading && 

            <Splash
            
                maxW={'600px'}
                p={4}
                >

                <Box p={4} >


                    <Stack spacing={4}>


                        { step === 1 &&

                            <FormControl>
                                <Heading size='smallertitle' mb='1em'>Who is your target audience?</Heading>
                                <Wrap spacing={2} mt={4}>
                                    {Audience.map((option, index) => {
                                        return (
                                            <Button variant='outline' colorScheme='blue' size='sm'
                                            onClick={() => {
                                                let key = 'Audience'
                                                let value = option;
                                                let obj = {
                                                    key: key,
                                                    value: value
                                                }
                                                let newTags = tags;
                                                newTags.push(obj);
                                                setTags(newTags);
                                                setStep(step+1); 
                                                
                                                }}>
                                                {option}
                                            </Button>
                                        )
                                    })}
                                </Wrap>
                            </FormControl>

                        
                        }

                        { step === 2 &&

                            <FormControl>
                            <Heading size='smallertitle' mb='1em'>How long should the teaching be?</Heading>
                            <Wrap spacing={2} mt={4}>
                                {Duration.map((option, index) => {
                                    return (
                                        <Button variant='outline' colorScheme='blue' size='sm'
                                            onClick={() => {
                                                let key = 'Duration'
                                                let value = option;
                                                let obj = {
                                                    key: key,
                                                    value: value
                                                }
                                                let newTags = tags;
                                                newTags.push(obj);
                                                setTags(newTags);
                                                setStep(step+1); 
                                                
                                                }}>
                                            {option}
                                        </Button>
                                    )
                                })}
                            </Wrap>
                            </FormControl>

                        }

                        { step === 3 &&

                            <FormControl>
                            <Heading size='smallertitle' mb='1em'>What is the setting for the teaching?</Heading>
                            <Wrap spacing={2} mt={4}>
                                {Setting.map((option, index) => {
                                    return (
                                        <Button variant='outline' colorScheme='blue' size='sm'
                                            onClick={() => {
                                            let key = 'Setting'
                                            let value = option;
                                            let obj = {
                                                key: key,
                                                value: value
                                            }
                                            let newTags = tags;
                                            newTags.push(obj);
                                            setTags(newTags);
                                            setStep(step+2); 
                                            
                                            }}>
                                            {option}
                                        </Button>
                                    )
                                })}
                            </Wrap>
                            </FormControl>

                        }


                        { step === 4 &&

                            <FormControl>
                                <Heading size='smallertitle' mb='1em'>What is your role as teacher?</Heading>
                                <Wrap spacing={2} mt={4}>
                                    {Teacher.map((option, index) => {
                                        return (
                                            <Button variant='outline' colorScheme='blue' size='sm'
                                                onClick={() => {
                                                let key = 'Teacher'
                                                let value = option;
                                                let obj = {
                                                    key: key,
                                                    value: value
                                                }
                                                let newTags = tags;
                                                newTags.push(obj);
                                                setTags(newTags);
                                                setStep(step+1); 
                                                setQuestionObject(Specialties);
                                                
                                                }}>
                                                {option}
                                            </Button>
                                        )
                                    })}
                                </Wrap>
                            </FormControl>

                        }

                        { step === 5 && 

                            <FormControl>
                                <Heading size='smallertitle' mb='1em'>What topic should be covered?</Heading>
                                <Input placeholder='Topic' />
                                
                            </FormControl>

                        }

                        

                    </Stack>
            
                    <ButtonGroup variant='outline' spacing={2} mt={4}>


                        {step !== 5 && tags !== undefined && tags.length > 0 &&
                            <Button variant='outline' onClick={() => reset()} variant='outline' colorScheme='red'>
                                Reset
                            </Button>
                        }
                        

                        { step === 5 &&
                            <Button variant='outline' colorScheme='blue' onClick={() => {
                                    setLoading(true);
                                    let key = 'Topic'
                                    let value = document.querySelector('input').value;
                                    let obj = {
                                        key: key,
                                        value: value
                                    }
                                    let newTags = tags;
                                    newTags.push(obj);
                                    setTags(newTags);
                                    setStep(step+1);
                                }}
                                >
                                Generate
                            </Button>
                        }



                    </ButtonGroup>

                </Box>  

            </Splash>

        }
            

        </>

    )
}
