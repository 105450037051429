import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Divider,
  Image,
  Heading,
  Flex,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
  ButtonGroup,
  Wrap,
  Badge,
  Table, 
  Tr, 
  Td,
  Th,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid, GridItem,
  useToast
} from '@chakra-ui/react';
import React from 'react';
import PDFIcon from './pdf.jpg';
import {MdOutlineExpandMore} from 'react-icons/md';
import Validation from '../../hr/documents/Validation';
import WarningDialogue from '../components/dialogues/WarningDialogue';
import { Link, Link as ReachLink } from "react-router-dom";
import moment from 'moment';
import API from '../utils/API';

const DocumentMenuItem = (props) => {

  return (
      <MenuItem size='sm'
        as={ReachLink} 
        to={'/my/document'} 
        state={{
          uuid: props.uuid, 
          requirementGroupUuid: props.requirementGroupUuid,
          returnURL: window.location.pathname,
          returnState: {
            userUuid: props.userUuid
          }
        }} >
          {props.text}
      </MenuItem>
  )
}





export default function DocumentRow(props) {

  const toast = useToast();

  //console.log("Document", props.document);
  let document = props.document;
  let requirementGroupUuid = props.requirementGroupUuid;

  let expiryTime = moment(document.expiration).diff(moment(new Date()), 'days');
  let expiryColor = 'green';
  if (expiryTime < 0) {
    expiryColor = 'red';
  } else if (expiryTime < 28) { 
    expiryColor = 'orange';
  }

  function LinkWrapper(props) {

    return (
      <Box
      as={ReachLink} 
      to={'/my/document'} 
      state={{
        uuid: document.uuid, 
        requirementGroupUuid: requirementGroupUuid,
        returnURL: window.location.pathname,
        returnState: {
          userUuid: document.userUuid
        }
      }} >
        {props.children}
      </Box>
    )
  }


  function archiveMe() {


    API.putItems({url: 'evidences/' + document.uuid + "/archive", data: {
      archived: true
    }})
    .then(response => {
      props.refresh();
      toast({
        title: 'Document Archived',
        description: "The document has been archived.",
        status: 'success',
        //duration: 9000,
        isClosable: true,
      });

    })
    .catch(error => {
      console.log("Archiving Error:", error);
      toast({
        title: 'Problem Archiving',
        description: "Sorry but we couldn't archive that file. Please try again later.",
        status: 'error',
        //duration: 9000,
        isClosable: true,
      });
    })
  }

  return (

      <SimpleGrid position='relative' pr={{base: '100px', md: 0}} borderBottom='1px solid whitesmoke' display={{base: 'block', md: 'grid'}} columns={{base: 0, md: 5}} p={{base: 4, md: 2}} spacing={{base: 2, md: 0}}>
      <GridItem pb={{base:4, md: 0}} pl={{base: 0, md: 4}} display='grid' alignContent='center'>
        <LinkWrapper>
        <HStack>
        <Image src={PDFIcon} alt='PDF Icon' w='18px' alignSelf='flex-start' />
        <Stack spacing='4px'>
          <Text fontSize='sm' lineHeight='18px' m={0}>
            {document.title.replace(/\.[^/.]+$/, "")}
          </Text>
 
          {document.issuer != null &&
              <Text fontSize='sm' opacity='0.7' variant='subtle' >
                  {document.issuer}
              </Text>
          }
        </Stack>
        </HStack>
        </LinkWrapper>
      </GridItem>


      <GridItem pl={{base: 7, md: 8}} pr={{md: 8}} gridColumn='span 3' alignItems='center' display={{base: 'inline-block', md: 'flex'}} justifyContent={{base: 'flex-start', md: ''}}>
 
        <SimpleGrid columns={2} spacing={2} display={{base: 'flex', md: 'grid'}} width={{base: 'inherit', md: 'fill-available'}} >
          
          <GridItem align='center'>
          <LinkWrapper>

          {document.lastTagCheck === null && moment(document.createdAt).isBefore(moment().subtract(props.staleTime, 'seconds')) &&
            <Tag size='sm' borderRadius='full' colorScheme='red'>
              <TagLabel>Missing Analysis</TagLabel>
            </Tag>
          }
          {document.lastTagCheck === null && moment(document.createdAt).isAfter(moment().subtract(props.staleTime, 'seconds')) &&
            <Tag size='sm' borderRadius='full' colorScheme='blue'>
              <TagLabel>Analysing</TagLabel>
            </Tag> 
          }
          {document.lastTagCheck !== null && 
            <> 
            {requirementGroupUuid == null ?
                  <Tag size='sm'  borderRadius='full' to={'/my/document'} color='red.500'>
                      No Tags found
                  </Tag>
                :
                  <Tag size='sm'  borderRadius='full' color='green.500'>
                      {document.requirements.length} requirement tags
                  </Tag>    

            }
            </>
          }
                          </LinkWrapper>

          </GridItem>

          <GridItem align='center' display='none'>
    <LinkWrapper>
          {(!moment(document.issued).isValid() ) ?
              
              <Tag size='sm' variant='subtle' borderRadius='full'>
                  No Issue Date
              </Tag>
              :
              <Tag size='sm' variant='subtle' borderRadius='full' color='green.500'>
                  Issued {moment(document.issued).format("DD/MM/YYYY")}
              </Tag>
          }
          </LinkWrapper>
          </GridItem>
          <GridItem align='center'>
    <LinkWrapper>
              {document.expiration === null ?
                  <Tag size='sm' variant='subtle' borderRadius='full'  >
                      No Expiry Date
                  </Tag>
                  :
                  <Tag size='sm' variant='subtle' borderRadius='full' colorScheme={expiryColor} >
                      {expiryColor === 'red' ? 'Expired' : 'Expires'} {moment(document.expiration).format("DD/MM/YYYY") }
                  </Tag>
              }
              </LinkWrapper>
              </GridItem>
        </SimpleGrid>
      </GridItem>


      <GridItem pl={{base: '7', md: '0'}} pt={{base:'3', md: '0'}} alignItems='center' display={{base: 'inline-block', md: 'flex'}} justifyContent={{base: 'flex-start', md: 'flex-end'}}>
                  <HStack spacing={{base: 0, md: 4}} display='flex' alignItems='center' justifyContent={{base: 'flex-start', md: 'flex-end'}}>
                  <ButtonGroup  variant='ghost' colorScheme='blue' size='xs' >
                  <LinkWrapper>
                    <Button display={{base: 'none', md: 'inherit'}} colorScheme='gray'>View</Button>
                  </LinkWrapper>
                  
                  { props.mode != 'hr' &&
                    <Menu >
                    <MenuButton colorScheme='gray' position={{base: 'absolute', md: 'inherit'}} right='8px' top='12px' as={Button} rightIcon={<MdOutlineExpandMore />}>
                      Actions
                    </MenuButton>
                    

                      <MenuList>
                        <DocumentMenuItem uuid={document.uuid} requirementGroupUuid={requirementGroupUuid} userUuid={document.userUuid} text='View'/>
                        <DocumentMenuItem uuid={document.uuid} requirementGroupUuid={requirementGroupUuid} userUuid={document.userUuid} text='Edit'/>
                        <DocumentMenuItem uuid={document.uuid} requirementGroupUuid={requirementGroupUuid} userUuid={document.userUuid} text='Manage Tags'/>
                        <MenuItem display='none'>Download</MenuItem>
                        <WarningDialogue
                          triggerText='Archive'
                          type='MenuItem'
                          title='Archive Document'
                          description='Are you sure you want to archive this document?'
                          confirm='Archive'
                          actionFunction={archiveMe}
                          refresh={props.refresh}
                        />
                      </MenuList>
                    </Menu>
                  }
                  </ButtonGroup>
                  </HStack>

                  { props.mode === 'hr' &&
                  <Box pl={2} position={{base: 'absolute', md: 'inherit'}} right='12px' top='42px'>
                      <Validation evidenceUuid={document.uuid} validations={document.DocumentValidations}/>
                      </Box>
                  }

      </GridItem>
    </SimpleGrid>

  )
}