import React, { useState } from 'react';
import { HStack, ButtonGroup, Icon, Wrap, Heading, Box, Text, Button, Flex, Image, Center, Stack, useColorModeValue } from '@chakra-ui/react';
import { MdWarning, MdCheck } from 'react-icons/md';
import { BsHddStack } from 'react-icons/bs';
export default function Checker(props) {

    const [cover, setCover] = useState(null);
    const [any, setAny] = useState(null);

    let stage = 'Question 1 of 2';
    if (cover === false) {
        stage = 'Quesions complete';
    } else if (cover === true && any === false) {
        stage = 'Questions complete';
    } else if (cover === true && any === true) {
        stage = 'Questions complete';
    } else if (cover === true && any === null) {
        stage = 'Question 2 of 2';
    }


    return (

        <Stack spacing={4}>

            {cover === null &&
                <>
                    <Heading size='label'>
                        Are doctors required to provide internal cover for colleagues on the rota when they take study leave?
                    </Heading>
                    <ButtonGroup variant='outline' colorScheme='blue' direction='row' spacing={4}>
                        <Button onClick={() => setCover(true)}>Yes</Button>
                        <Button onClick={() => setCover(false)}>No</Button>
                    </ButtonGroup>
                </>
            }

            {cover && any === null &&
                <>
                    <Heading size='label'>
                        Are junior doctors allowed to take study leave against any shift on the rota without arranging a shift swap with a colleague to cover their shift?
                    </Heading>
                    <ButtonGroup variant='outline' colorScheme='blue' direction='row' spacing={4}>
                        <Button onClick={() => setAny(true)}>Yes</Button>
                        <Button onClick={() => setAny(false)}>No</Button>
                    </ButtonGroup>
                </>
            }

            {cover && any === false &&
                                <HStack spacing={4} border='2px solid' borderColor='transparent' borderRadius='sm'>
                                <Icon as={MdWarning} boxSize='48px' color='white' w='24px' fontSize='lg' bg='red.500' borderRadius='50%' p={4} />
                                <Text color='red.500' pt={2} pb={2}>
                                Prospective cover was in use, and your study leave should have been included when calculating your average hours. <b>It is very possible you have been underpaid.</b>
                                </Text>
                            </HStack>
            }
            {(cover === false || (cover === true && any === true)) &&
                <HStack spacing={4} border='2px solid' borderColor='transparent' borderRadius='sm'>
                    <Icon as={MdCheck} boxSize='48px' color='white' w='24px' fontSize='lg' bg='green.500' borderRadius='50%' p={4} />
                    <Text color='green.500' pt={2} pb={2}>
                        Prospective cover was not in use and so study leave should not have been included in your work schedule calculations. 
                        It is unlikely that you have been underpaid.
                    </Text>
                </HStack>
            }

            <Wrap>
                <Text color='subtle'>{stage}</Text>
                { cover !== null &&
                    <Text color='subtle' cursor='pointer' _hover={{color: 'blue.500'}} size='small' onClick={() => { setCover(null); setAny(null) }}>Reset</Text>
                }
            </Wrap>
        </Stack>



    )
}