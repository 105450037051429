import React, { useState, useEffect } from 'react';
import {Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table } from '@chakra-ui/react';
import { MdEdit, MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";
import API from '../../../core/utils/API';

export default function FTE(props) {

    const [loading, setLoading] = useState(false);
    const [fte, setFTE] = useState(props.fte ? props.fte : null);
    const [changeable, setChangeable] = useState(props.fte ? false : true);

    function updateFTE(inputFTE) {

        console.log("Update FTE", inputFTE);
        setLoading(true);
        
        url = 'schedule'
        
        inputFTE = inputFTE.replace('%', '');
        inputFTE = parseInt(inputFTE) / 100;

        let url = 'schedules';
        let config = {
            params: {
                uuid: props.uuid,
                fte: inputFTE
            }
        };

        console.log("Sending update");

        let resp = API.patchItems({url, config})
        .then(response => {
            setFTE(inputFTE);
            props.analyseSchedule();
            setChangeable(false);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });


    }

    let fteOptions = [
        '100%',
        '90%',
        '80%',
        '70%',
        '60%',
        '50%',
    ]


    return (

        <Box>
            {props.fteCalculated < 1 && (fte === 0 || fte === null) ?
                <Alert status="error" mb={4}>
                    <AlertIcon />
                    <Stack>
                        <AlertTitle>Missing LTFT Rate</AlertTitle>
                        <AlertDescription>This schedule has calculated as a LTFT rota ({Math.round(props.fteCalculated*100)}%), we need the pre-agreed level of LTFT to accurately calculate your salary.</AlertDescription>
                    </Stack>
                </Alert>
                :
                <>
                    <Tag cursor='pointer'>
                        <TagLabel>Full Time Equivalent {fte ? fte*100 + '%' : 'unset'}</TagLabel>
                        <TagRightIcon as={MdEdit} onClick={() => {setChangeable(!changeable);}}/>
                    </Tag>
                </>
            }

            {changeable === true &&


                <Box pb={8}>
                    <Heading size='label' mb={2}>Select FTE:</Heading>

                    <ButtonGroup variant='outline' colorScheme='blue' size='sm'>

                        {fteOptions.map((option, index) => {

                            return (

                                <Button key={index} onClick={() => updateFTE(option)}>{option}</Button>

                            )


                        })}

                        <Button onClick={() => setChangeable(false)}>Close</Button>

                    </ButtonGroup>
                </Box>

            }


            {loading ? <CircularProgress isIndeterminate color="green.300" /> : null}

        
        </Box>

    )
}