import {
  Box,
  Button,
  Circle,
  Heading,
  HStack,
  Icon,
  Tag,
  Wrap,
  List,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'
import { PricingCardBadge } from './PricingCardBadge'
import { Link } from 'react-router-dom'
import Product from './Product';
import API from '../../core/utils/API';
import moment from 'moment';

export const PricingCard = (props) => {
  const { product } = props

  let activeTerm = 'Activated';
  let borderColor = 'black';
  let active = false;
  let trial = false;
  let backgroundImage = 'none';
  let bannerText = '';
  let bannerColor = props.bannerColor ? props.bannerColor : 'blue.500';
  console.log('props', props.priceId, props.highestSubscription)
  let currentSubscription = undefined;
  let buttonExplaination = '';
  props.allSubscriptions.map((subscription) => {
    if (subscription.plan_id === props.priceId) {
      if (subscription.status === 'active' && subscription.cancel_at_period_end === false) {
        active = true;
        borderColor = 'blue.500';
        backgroundImage = 'linear-gradient(180deg, rgb(49,5,194,0) 10%, rgb(49,5,194,0) 100%) !important';
        buttonExplaination = 'Your next payment is on ' + moment(props.highestSubscription.current_period_end).format('LLL');
        bannerText = activeTerm;
        if (currentSubscription === undefined) {
          currentSubscription = subscription;
        }
      } else if (subscription.status === 'trialing' && subscription.cancel_at_period_end === false) {
        trial = true;
        bannerText = activeTerm;
        buttonExplaination = 'Your first payment is on ' + moment(props.highestSubscription.current_period_end).format('LLL');
        if (currentSubscription === undefined) {
          currentSubscription = subscription;
        }
      } else if ((subscription.status === 'trialing' || subscription.status === 'active') && subscription.cancel_at_period_end === true) {
        bannerText = 'Cancelled';
        bannerColor = 'red.500';
        buttonExplaination = 'Your subscription will end on ' + moment(props.highestSubscription.current_period_end).format('LLL');
        if (currentSubscription === undefined) {
          currentSubscription = subscription;
        }
      }
    }
  });

  console.log(product.name, bannerText);

  if (props.priceId === props.highestSubscription.plan_id) {
    active = true;
    borderColor = 'blue.500';
    backgroundImage = 'linear-gradient(180deg, rgb(49,5,194,0) 10%, rgb(49,5,194,0) 100%) !important';
  }

  
  function cancelSubscription() {
    console.log('cancelSubscription', props.highestSubscription, props.highestSubscription.stripeSubscriptionId);
    let response = API.postItems({
      url: 'users/cancelStripeSubscription',
      data: {
        userUuid: props.userUuid,
        stripeCustomerId: props.stripeCustomerId,
        subscriptionId: currentSubscription.stripeSubscriptionId,
      }
    })
    .then((response) => {
      if (response.status === 200) {
        window.location.reload();
      }
    })
    .catch((error) => {
      console.log(error);
    });

  }

  function reactivateSubscription() {
    console.log('reactivateSubscription', props.highestSubscription, props.highestSubscription.stripeSubscriptionId);
    let response = API.postItems({
      url: 'users/reactivateStripeSubscription',
      data: {
        userUuid: props.userUuid,
        stripeCustomerId: props.stripeCustomerId,
        subscriptionId: currentSubscription.stripeSubscriptionId,
      }
    })
    .then((response) => {
      if (response.status === 200) {
        window.location.reload();
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function switchSubscription() {

    console.log("Switching");

    let response = API.postItems({
      url: 'users/switchStripeSubscription',
      data: {
        userUuid: props.userUuid,
        stripeCustomerId: props.stripeCustomerId,
        subscriptionId: props.highestSubscription.stripeSubscriptionId,
        priceId: props.priceId
      }
    })
    .then((response) => {
      if (response.status === 200) {
        window.location.reload();
      }
    })
    .catch((error) => {
      console.log(error);
    });

  }


  return (

    <Box className='gradient-background hover-only' p='2px' borderRadius="lg" 
      position="relative"
      overflow="hidden"
      display='flex'
      mb='24px !important'
      >

    <Box
      bg="bg-surface"
      borderRadius="lg"
      px={{
        base: '6',
        md: '8',
      }}
      py="8"
      backgroundImage={backgroundImage}
      w='fill-available'
    >
      {bannerText === '' && product.isPopular && (
        <PricingCardBadge className='gradient-background' bg=''>
          <Text fontWeight="medium" color='invert'>Popular</Text>
        </PricingCardBadge>
      )}
      {bannerText != '' && (
        <PricingCardBadge bg={bannerColor !== 'gradient-background' ? bannerColor : ''} className={bannerColor}>
          <Text fontWeight="medium" color='invert'>{bannerText}</Text>
        </PricingCardBadge>
      )}
      <Stack spacing="8" textAlign="center">
        <Stack spacing="5" align="center">
          <Stack spacing="4">
            <Heading
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              {product.name}
            </Heading>
            <Stack spacing="1" alignItems='center'>
              <Text fontSize="xl" fontWeight="semibold">
              {product.price}<Text as='span' fontSize='18px' mb='-10px'>{product.price_suffix}</Text> <Text as="span" fontSize="sm" color="muted">{product.price_addendum}</Text>
              </Text>
              <Text color="muted" pb={4}>{product.description}</Text>
              {product.deal != undefined &&
                <Tag display='none' colorScheme='blue' w='fit-content' m='auto'>
                  {product.deal}
                </Tag>
              }
              <Tag display='none' mt={4} cursor='pointer' colorScheme='blue' w='fit-content' m='auto' onClick={props.switchShowMore}>{props.showMore ? 'Hide details' : 'Show details'}</Tag>

            </Stack>
          </Stack>
        </Stack>
        {props.showMore &&
          <List as="ul" spacing="4">
            {product.features.map((feature) => (
              <HStack key={feature} as="li" spacing="3">
                <Circle size="6" bg={mode('whitesmoke', 'whiteAlpha.50')}>
                  <Icon as={FiCheck} color="black" />
                </Circle>
                <Text color="muted" align='left'>{feature}</Text>
              </HStack>
            ))}
          </List>
        }

        <Text color='black'>
            {buttonExplaination}
        </Text>

          <Button display='none' onClick={props.switchShowMore}>{props.showMore ? 'Hide details' : 'Show details'}</Button>
          {props.priceId != undefined && bannerText === activeTerm &&
            <Button onClick={() => cancelSubscription()} variant='ghost' colorScheme='red'>
              Cancel
            </Button>
          }
          {props.priceId != undefined && bannerText === 'Cancelled' && 
            <Button onClick={() => reactivateSubscription()} colorScheme='blue'>
              Restart
            </Button>
          }
          {props.priceId != undefined && bannerText !== activeTerm && bannerText != 'Cancelled' && Object.keys(props.highestSubscription).length != 0 &&
            <Button onClick={() => {switchSubscription()}} colorScheme='blue' variant='outline'>
              Switch
            </Button>
          }
          {props.priceId != undefined && bannerText !== activeTerm && bannerText != 'Cancelled' && Object.keys(props.highestSubscription).length === 0 &&
            <Product 
              priceId={props.priceId}
              stripeCustomerId={props.stripeCustomerId}
              userUuid={props.userUuid}
            />
          }
          
      </Stack>
    </Box>
    </Box>
  )
}

export default PricingCard