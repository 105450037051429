"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var theme_tools_1 = require("@chakra-ui/theme-tools");
var _1 = require(".");
var baseStyle = {
    menu: {
        maxW: 'xs',
        border: 'unset',
    },
};
var variants = {
    outline: function (props) { return ({
        menu: {
            mt: 1.5,
            py: 0,
            bg: 'bg-surface',
            overflow: 'hidden',
            boxShadow: 'md',
            _focus: {
                boxShadow: "".concat((0, theme_tools_1.mode)("0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)", "0px 0px 1px #0D0D0D, 0px 4px 8px rgba(13, 13, 13, 0.9)")(props), " !important"),
            },
        },
        option: {
            _selected: {
                color: 'on-accent',
                bg: 'accent',
            },
            _hover: {
                bg: 'accent',
                color: 'on-accent',
            },
        },
        field: __assign(__assign({}, _1.Select.variants.outline(props).field), { _expanded: {
                borderColor: (0, theme_tools_1.mode)('brand.500', 'brand.200')(props),
                boxShadow: (0, theme_tools_1.mode)("0px 0px 0px 1px ".concat((0, theme_tools_1.transparentize)("brand.500", 1.0)(props.theme)), "0px 0px 0px 1px ".concat((0, theme_tools_1.transparentize)("brand.200", 1.0)(props.theme)))(props),
            } }),
    }); },
};
exports.default = {
    baseStyle: baseStyle,
    variants: variants,
};
