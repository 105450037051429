


import React, {useContext, useState, useEffect} from 'react';
import {Divider, FormControl, FormLabel, InputGroup, InputRightElement, Heading, Text, Flex, Box, Stack, Button, Input} from '@chakra-ui/react';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import { AccountContext } from './Accounts';
import { CognitoUser, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Splash from '../../../static/layout/Splash';
import PhoneNumberInput from '../../../core/components/form_elements/PhoneNumberInput';
import TOTPWrapper from '../../totp/TOTP';
import { Outlet } from 'react-router-dom';
import userPool from '../UserPool';
import Code from '../../../core/components/forms/Code';
import API from '../../../core/utils/API';

export default (props) => {

    const [stage, setStage] = useState('Check');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [phone, setPhone] = useState(null);
    const [phoneConfirmed, setPhoneConfirmed] = useState('false');
    const [storeSession, setStoreSession] = useState(null);
    const [submitPhone, setSubmitPhone] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [error, setError] = useState(null);
    const [requireSession, setRequireSession] = useState(props.requireSession || false);
    const [jti, setJti] = useState('');
    const [mfaExpires, setMfaExpires] = useState(null);

    let maxW='400px';
    if (props.maxW) {
        maxW = props.maxW;
    }

    const { getSession, authenticate } = useContext(AccountContext);

    const check = () => {

        setError(null);

        getSession()
            .then(session => {

                setLoggedIn(true);
                setStoreSession(session);
                //console.log("Phone", session.phone_number);
                //console.log("Phone Verification", session.phone_number_verified);
                setPhone(session.phone_number);
                setPhoneConfirmed(session.phone_number_verified);
                setJti(session.idToken.payload.jti);

                //console.log("Prep", session.phone_number_verified, session.phone_number);
                //console.log("Check 1", (session.phone_number === undefined || session.phone_number === null));
                //console.log("Check False", (session.phone_number_verified === 'false'));
                //console.log("Check True", (session.phone_number_verified === true));

                if  (session.phone_number === undefined || session.phone_number === null ) {
                    //console.log("change");
                    setStage('Change');
                } else if (session.phone_number_verified === 'false') {
                    //console.log("Phone not verified");   
                    sendSMSCode(session.phone_number);
                    setStage('Confirm'); 
                } else if (session.phone_number_verified === 'true') {
                    //console.log('pass');
                    setStage('Pass');
                }


            })
            .catch(err => {
                console.error(err);
                setError(err);
            });
    };

    const checkMFASession = (type) => {

        //console.log("Checking MFA session");

        API.getItems({url: 'mfa/session', data: {type}})
        .then(res => {

            //console.log('MFA session', res);
            setMfaExpires(res.data.expires);
            
        }).catch(err => {

            //console.log('MFA session error', err);
            setMfaExpires(null);
            setError(err.message);

        });

    };

    useEffect(() => {
        check();
    }, []);

    const sendSMSCode = (manualPhone) => {

        //console.log("Sending SMS Code");

        let tmpPhone = manualPhone || phone;
        if (tmpPhone === undefined) {
          //  console.log("No phone set");
            setError('Phone number not set');
            return;
        }
        
        API.postItems({url: 'sms/send', data: {to: tmpPhone}})
        .then(response => {
           // console.log(response);
        })
        .catch(error => {
            console.log(error);
            setError(error.response.data.message);
        });


    };

    const validateCode = (code) => {

       // console.log("Validating code ", code);

        if (code === undefined) {
           // console.log("No code set");
            setError('Code not set');
            return;
        }

        API.postItems({url: 'sms/validate', data: {code: code, phone: phone}})
        .then(response => {
            //setPhoneConfirmed('true'); 
            check();
        })
        .catch(error => {
           // console.log(error);
            setError(error.response.data.message);
        });


    };



    const onSubmit = event => {

        event.preventDefault();
       // console.log("submit");


        getSession()
            .then(({user, email}) => {
                authenticate(email,password).then(() => {

                    // Clean phone number to IE format
                    submitPhone.replaceAll(" ", '');
                    submitPhone.replaceAll("+", '');
                    submitPhone.replaceAll("(", '');
                    submitPhone.replaceAll(")", '');
                    submitPhone.replaceAll("-", '');
                    submitPhone.replaceAll(".", '');
                    submitPhone.replaceAll(",", '');
                    submitPhone.replaceAll(";", '');
                    submitPhone.replaceAll(":", '');
                    submitPhone.replaceAll("/", '');
                    submitPhone.replaceAll("\\", '');
                    
                   // console.log("Submitting phone: ", submitPhone);
                    const attributes = [
                        new CognitoUserAttribute({
                        Name: 'phone_number',
                        Value: submitPhone.replaceAll(" ", '')
                        })
                    ];
                    
                    user.updateAttributes(attributes, (err, result) => {
                        if (err) {
                            setError(err.message);
                        } else {
                          //  console.log(result);
                            check();
                        }
                    });
                });
            })
            .catch(err => {
                //console.error(err);
                setError(err);
            }
        );

            
    };

    const changePhone = event => {

        if (event) {
            event.preventDefault();
        }
        console.log("change");


        getSession()
            .then(({user, email}) => {
                authenticate(email,password).then(() => {
                    
                    //console.log("Submitting phone: ", submitPhone);
                    const attributes = [
                        new CognitoUserAttribute({
                        Name: 'phone_number',
                        Value: null
                        })
                    ];
                    
                    user.updateAttributes(attributes, (err, result) => {
                        if (err) {
                            setError(err.message);
                        } else {
                        //    console.log(result);
                        }
                    });
                });
            })
            .catch(err => {
              //  console.error(err);
                setError(err);
            }
        );

            
    };
    const clearNumber = () => {

        console.log("Clearing number");

        if (phone === undefined) {
            console.log("No phone set");
            setError('Phone number not set');
            return;
        }

        // Get email from session
        const cognitoUser = userPool.getCurrentUser();
        
        console.log("cognitoUser", cognitoUser);
        // Update phone number
        let attributeList = [];
        let attribute = new CognitoUserAttribute({
            Name: 'phone_number',
            Value: ''
        });
        attributeList.push(attribute);

        // Authenticate user
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.log(err);
                setError(err.message);
                return;
            }
            console.log('session validity: ' + session.isValid());
            console.log(session);
        });

        
        // Clear phone number in Cognito
        cognitoUser.updateAttributes(attributeList, (err, result) => {
            if (err) {
                console.log(err);
                setError(err.message);
                return;
            }
            console.log(result);
            setPhone('');
            setPhoneConfirmed(false);
            check();
            // Refresh page
            //window.location.reload();
            setError('Phone number cleared');
        });

        

    };


    return (



        <>

            {stage === 'Check' &&
               <Stack spacing={4} p={4} minH='400px' maxW='500px' ml='auto!important' mr='auto!important'>
               <Box  m={-4} p={4} textAlign='center' pt={12} pb={8}>
                   <Heading as="h1" size="label" fontSize='2em' >Multi-Factor Authentication</Heading>
               </Box>
               <Stack pt={4} pb={4} spacing={4} textAlign='center'>
   
                   <Box className="loading" w='100%' pb={4}>
                       <span></span>
                       <span></span>
                       <span></span>
                       <span></span>
                       <span></span>
                   </Box>
   
                   <Text color='subtle'>Checking your phone MFA status</Text>
                     {error && <Text color='red.500'>{error}</Text>}

                </Stack>

                </Stack>

            }

            { stage === 'Change' &&

                <Stack spacing={4} p={4} minH='400px' maxW='500px' m='auto'>
                    <Box  m={-4} p={4} textAlign='center' pt={12} pb={8}>
                        <Heading as="h1" size="label" fontSize='2em' >High-Security Page</Heading>
                    </Box>
                    <Stack pl={8} pr={8} pt={4} pb={4} spacing={4} textAlign='center'>

                    <Text mb={2}>
                        This page requires you to increase your security settings to protect your data.
                        We will set up Multi-Factor Authentication using your mobile.
                    </Text>
                     <form onSubmit={onSubmit}>
                        <Stack spacing={3} 
                        textAlign='center'
                        alignItems='center'
                                >
                            <Text>{email}</Text>
                            <InputGroup size='md' maxW='300px' ml='auto!important' mr='auto!important' >
                                    <Input type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
                                    <InputRightElement width='4.5rem'>
                                    <Button mr={1} colorScheme='blue' variant='outline' className='btn-8' h='1.75rem' size='sm' onClick={()=>setShowPassword(!showPassword)}>
                                            {showPassword ? 'Hide' : 'Show'}
                                    </Button>
                                    </InputRightElement>
                                </InputGroup>

                            <FormControl mb="32px">
                            <PhoneNumberInput
                            maxW='300px' m='auto'
                                value={submitPhone}
                                placeholder="Enter phone number"
                                country='GBR'
                                m='auto'
                                onChange={value => setSubmitPhone(value)}
                            />
                            </FormControl>
                            <Text mb={8} pb={4} pt={2} color='subtle' fontSize='sm'>Your phone number is needed for account recovery and we will send a confirmation code via SMS.</Text>

                            <Button w='fit-content' size='md' className='btn-8' colorScheme='blue' variant='outline' onClick={onSubmit}>Add Phone</Button>

                            {error && <Text color='red.500'>{error}</Text>}
                                
                        </Stack>
                    </form>

                    </Stack>

                    {props.onClose &&
                        <Button alignSelf='center' w='fit-content' size='sm' variant='ghost' onClick={props.onClose}>Close</Button>
                    }
                </Stack>

            }

            { stage === 'Confirm' &&

                <Stack spacing={4} p={4} minH='400px' maxW='500px' m='auto'>
                    <Box  m={-4} p={4} textAlign='center' pt={12} pb={8}>
                        <Heading as="h1" size="label" fontSize='2em' >Phone Confirmation</Heading>
                    </Box>
                    <Stack pl={8} pr={8} pt={4} pb={4} spacing={4} textAlign='center' alignContent='center'>

                        <Text maxW='300px' alignSelf='center'>
                            We have sent a confirmation code to your phone, we can 
                            <Text ml='3px' as='span' cursor='pointer' _hover={{color: 'blue.500'}} onClick={() => (sendSMSCode())} >
                                send a new code
                            </Text> or you can 
                                <Text ml='3px' as='span' cursor='pointer' _hover={{color: 'blue.500'}} onClick={() => (clearNumber())} >
                                    change your phone number
                                </Text>.
                        </Text>


                        <Text fontSize='1.5em' lineHeight='2em' color='black' fontWeight='strong'>{phone}</Text>

                        <Text maxW='300px' alignSelf='center'>
                            Please enter the code below to confirm your phone number.
                        </Text>

                        <Code 
                            inputName='code'
                            codeLength={6}
                            setMessage={setConfirmationCode}
                            return={validateCode}
                            />

                        {error && <Text color='red.500'>{error}</Text>}

                    </Stack>

                    {props.onClose &&
                        <Button alignSelf='center' w='fit-content' size='sm' variant='ghost' onClick={props.onClose}>Close</Button>
                    }
                </Stack>
            }

            {stage === 'Pass' &&
            <>
                {error && <Text id='phone-MFA-error' color='red.500'>{error}</Text>}
                <TOTPWrapper 
                    onClose={props.onClose}

                    pl={props.pl || 8}
                    pr={props.pr || 8}
                    >

                    {props.children }
                    {props.outlet !== false && <Outlet/>}

                </TOTPWrapper>
                </>
            }
        </>
    )
}

