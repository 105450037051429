import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Loading from '../static/layout/Loading';
import Splash from '../static/layout/Splash';
import Layout from '../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { Outlet } from 'react-router-dom';
import {Header, Row, Cell} from '../core/components/lists/List';
import pluralize from 'pluralize';
import BasicBanner from '../core/components/banners/BasicBanner';
import Sidebar from './landing/Sidebar';
import MFAWrapper from '../auth/cognito/components/MFAWrapper';

export default function Landing(props) {

    console.log("Lnading", props);

    // Get state
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showSidebar, setShowSidebar] = useState(true);

    return (
        <>
        
        <Flex h='fill-available'
        bg='#FCFCFC'
        backgroundImage='radial-gradient(#cfcfcf 0.5px, #FCFCFC 0.5px)'
        backgroundSize='10px 10px'
            >
            <Sidebar show={showSidebar} setShow={setShowSidebar} test={props.test} hideNav={props.hideNav} />
            <Box
                w='-webkit-fill-available'
                transition='all 0.2s ease-in-out'
                marginLeft={showSidebar ? '200px' : '45px'}
                h='fill-available'
                id='organisation-main'
                >

       
                {props.test ?
                <Outlet />
                :
                <MFAWrapper outlet={false} pl={0} pr={0}>
                    <Outlet />
                </MFAWrapper>
                }
            </Box>
        </Flex>
        </>
    )
}