import React, {useEffect, useState} from 'react';
import API from "../../core/utils/API";
import {Flex, Button, Avatar,Icon, Wrap, AvatarBadge, Box, Heading, Image, Text, Input, Circle} from "@chakra-ui/react";
import {MdUploadFile, MdStar, MdStarOutline, MdSettings, MdCloud, MdAdd, MdClose} from 'react-icons/md';
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useForm } from "react-hook-form";
import Pluralize from 'pluralize';



const CustomAvatar = (props) => {

    let borderWidth = '5px';
    let inset = '3px';
    let borderColor = 'blue.100';
    let cursor='pointer';
    let order = 2;
    if (props.primary) {
      borderColor='blue.500';
      cursor='unset';
      order = 1;
    }
  
    return (
  
        <Box position='relative' order={order}>
        <Avatar size='xl' 
          borderWidth={borderWidth} 
          borderColor={borderColor} 
          cursor={cursor} 
          _hover={{borderColor: 'blue.500'}} 
          src={'data:image/jpeg;base64,' + props.image} // TODO - make sure this is compatible with all images
          onClick={props.updatePrimaryImage} />

            <Circle
            className='show_on_parent_hover' 
            _hover={{bg: 'red.500', color: 'white', borderColor: 'red.500', boxShadow: 'md'}} 
            color='red.500' 
            cursor='pointer' 
            bg='red.50' 
            borderColor='red.50'
            borderWidth='3px' boxSize='0.75em' bottom={inset} right={inset} 
            w='24px' h='24px'   
            onClick={props.archiveImage}
            position='absolute'
            right={inset}
            bottom={inset}
            zIndex={1}
                >
            <Icon as={MdClose} fontSize='1em'/>
            </Circle>
        
        </Box>
  
    )
  }


function Upload(props) {
    const {
        register,
        handleSubmit,
        reset,
        formState,
        formState: { errors },
      } = useForm();
      const onError = (errors, e) => console.log(errors, e);
      const [primary, setPrimary] = useState(props.primary);
      const [multipleFiles, setMultipleFiles] = useState([]);
        const [formMessage, setFormMessage] = useState(null);

         // Functions to preview multiple images
         const changeMultipleFiles = (e) => {
            
            // Update loading via props
            props.updateLoading(true);

            if (e.target.files) {
      
                const formData = new FormData();

                Array.from(e.target.files).forEach(function(file) 
                    {
                        formData.append('files', file);
                    }
                );
                
                if (props.primary === null) {
                    formData.append('primary', props.primary);
                } 
      
      
              let response = API.postItems({url: 'users/images', data: formData}).then(function (response) {
                  props.updateMessage(response.data.message);
                  props.updateImages();
                //window.location.href = "/my/dashboard";
  
      
              })
              .catch(function (error) {
                //console.log("failed to upload");
                props.updateError(error.message);
                props.updateLoading(false);
              });

            }
        };

        // Submit form after multiple files has been set
        // React useEffect is required
        useEffect(() => {
            //console.log("Change in multiple files");
            if (multipleFiles.length > 0) {
                //console.log("Submitting form");
                onSubmit();
            }
        }, [multipleFiles]);

        const onSubmit = async (data, e, files) => {

            const formData = new FormData();
      
            multipleFiles.forEach(function(file) {
                formData.append('files', file);
            });
    
            formData.append('primary', props.primary);
    
            //console.log("End Form Data", multipleFiles);
            //console.log("Form Data", formData);
    
            let response = API.postItems({url: 'users/images', data: formData}).then(function (response) {
                props.updateMessage(response.data.message);
                props.updateImages();
              //window.location.href = "/my/dashboard";

    
            })
            .catch(function (error) {
              //console.log("failed to upload");
              props.updateError(error.message);
              props.updateLoading(false);
            });
      
          }
    

    const removeFile = (file) => {

        // Remove file from multipleFiles array
        const newFiles = [...multipleFiles];
  
        newFiles.splice(newFiles.indexOf(file), 1);
  
        setMultipleFiles(newFiles);
      }

    return (


        <Box order={3}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>

        {!props.loading &&
          <Flex 
            w='96px' 
            h='96px' 
            borderRadius='50px' 
            border='5px solid'
            borderColor='blue.100'
            bg='blue.100'
            justifyContent='center'
            alignItems='center'
            position='relative'
            className='btn-8'
            color="blue.500"
            _hover={{
              bg: 'blue.500', 
              color: 'white',
              borderColor: 'blue.500',
              boxShadow: 'md'
            }}
            >
              <Icon as={MdAdd} boxSize="40px"  />
            <Input
                  type="file"
                  name="evidence"
                  multiple
                  {...register('files', { required: true })}
                  onDrop={function() {console.log("dropped")}}
                  onChange={changeMultipleFiles}
                  h="100%"
                  w="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  opacity="0"
                  accept="image/*"
                  cursor='pointer'
                /> 
          </Flex>

        }

      </form>
      </Box>

    )
}


export default function Images(props) {

    const [images, setImages] = useState([]);
    const [primaryImage, setPrimaryImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    function updateImages() {
        API.getItems({url: '/users/profileimages'})
        .then(res => {

            let array = [];
            //console.log("res.data.images", res.data.images);
            res.data.images.forEach(image => {
                
                // Split base64 string into two parts
                if (!image) return;

                if (image.primary) setPrimaryImage(image.primary);
            
                let i = {
                    primary: image.primary,
                    data: image.data,
                    uuid: image.uuid
                }
                // Append blob to array
                array.push(i);

            });

            //console.log(array);
            setImages(array);
            setMessage(null);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
        })

    }

    function updatePrimaryImage(uuid) {

        setLoading(true);
        if (primaryImage === uuid) return;

        //console.log("Updating primary image to", uuid);

        let config = {
            params: {
                uuid
            }
        }


        API.postItems({
            url: 'users/primaryimage', 
            config
        })
        .then(res => {
            //console.log(res);
            setMessage('Primary image updated');
            updateImages();
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        })


    }


    function archiveImage(uuid) {

        setLoading(true);

        let config = {
            params: {
                uuid
            }
        }

        API.postItems({
            url: 'users/archiveimage',
            config
        })
        .then(res => {
            //console.log(res);
            setMessage('Image archived');
            updateImages();
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        })

    }





    useEffect(() => {
        updateImages();
    }, []);



    return (
        <Box key={images.length}>
            
            <Wrap pt={4} pb={4} spacing={4}>

                {images.map(image => (

                    <CustomAvatar
                        key={image.uuid}
                        image={image.data}
                        primary={image.primary}
                        updatePrimaryImage={() => {updatePrimaryImage(image.uuid)}}
                        archiveImage={() => {archiveImage(image.uuid)}}
                    />

                ))}

                {loading ?

                    <Box order={3}>
                    <LoadingCircle />
                    </Box>
                    :

                    <Upload 
                        updateImages={updateImages}
                        updateMessage={setMessage}
                        updateError={setError}
                        primary={primaryImage}
                        loading={loading}
                        updateLoading={setLoading}
                        />

                }
            
            </Wrap>

            {message && <Text color='subtle'>{message}</Text>}
            {error && <Text color='red'>{error}</Text>}

        </Box>
    )
}



