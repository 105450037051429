
import React, { useState, useEffect } from 'react';
import { Avatar, Tag, HStack, ButtonGroup, Textarea, Input, GridItem, Heading, Box, Stack, Button, Text, Flex, Spacer, Collapse, useDisclosure, IconButton } from '@chakra-ui/react';
import Message from './Message';
import API from '../../core/utils/API';
import Autoform from '../../core/models/AutoForm';
import { useLocation } from 'react-router-dom';
import Layout from '../../core/layout/Layout';
import { Link as ReachLink } from "react-router-dom";
import Modal from '../../core/components/modal/Modal';
import { MdSend, MdClose, MdOutlineArrowUpward } from 'react-icons/md';
import {AutoResizeTextarea} from '../../core/components/forms/AutoResizeTextarea';
import Moment from 'react-moment';
import moment from 'moment';

export default function Conversation(props) {

    const location = useLocation();
    let recipientUuid = props.recipientUuid;
    
    if (!location.state && !recipientUuid) {
        recipientUuid = location.state;
    }

    // setup disclosure
    const { isOpen, onToggle } = useDisclosure();

    let [user, setUser] = useState(null);
    let [loading, setLoading] = useState(true);
    let [error, setError] = useState(null);
    let [showReply, setShowReply] = useState(false);
    let [messageUuid, setMessageUuid] = useState(null);
    let [lastChecked, setLastChecked] = useState(null);

    let getMessages = async () => {

        let url = 'messages?recipientUuid=' + recipientUuid;
        console.log("URL = ", url);
        let response = await API.getItems({url});
        let data = response.data;

        console.log("DATA = ", data);
        console.log("USER = ", data['users']['0']);
        setUser(data['users']['0']);

        setLastChecked(moment().format('YYYY-MM-DD HH:mm:ss'));

        setLoading(false);

    }

    useEffect(() => {
        getMessages();
    }, []);


    let handleReply = (messageUuid) => {
        setShowReply(true);
        setMessageUuid(messageUuid);
    }

    const showMessage = (id_string) => { 
        console.log("scrolling to " + id_string);
        document.getElementById(`${id_string}`).scrollIntoView({ behavior: 'smooth' });
        //let dims = document.getElementById(`${id_string}`).getBoundingClientRect();
        //window.scrollTo(window.scrollX, dims.top - 20);
       }

    const submitReply = async (e) => {

        e.preventDefault();

        let form = e.target;
        let formData = new FormData(form);

        let inputData = {
            recipientUuid: recipientUuid,
            replyUuid: messageUuid,
            type: 'message',
            flag: false,
            body: document.getElementById('reply-body').value,
        };

        console.log(inputData);

        let url = 'messages';
        let response = await API.postItems({url, data: inputData});
        let data = response.data;

        console.log("DATA = ", data);

        setMessageUuid(null);
        document.getElementById('reply-body').value = '';
        

        getMessages();

    }


    return (


    
        <Box>

            {!loading && user === null &&
                <Text>No messages with {recipientUuid}</Text>
            }

            {!loading  && user !== null &&


            <Box mb='90px'>

                <HStack zIndex='3' h='70px' position='fixed' top='0' right='0' w='400px' bg='blue.500' color='white' pl={2} pr={4}>

                    <Avatar mr={2} name={user && user.firstName + " " + user.lastName} src={user && user.picture} />
                    <Stack spacing={0}>
                        <Text color='white' fontSize='sm'>Conversation with:</Text>
                        <Text color='white' fontSize='sm' fontWeight='bold'>{user && user.firstName + " " + user.lastName}</Text>
                    </Stack>
                    <Spacer />
                    <IconButton size='xs' onClick={() => props.conversation(null)} icon={<MdOutlineArrowUpward />} colorScheme='blue' className='btn-8' />

                </HStack>

                <Stack spacing={4} p={2} mt='70px' overflowY='scroll' id='messages'>


                    
                    {user && user.Messages.sort((a, b) => a.createdAt.localeCompare(b.createdAt)).map((message) => {
                        return (
                            <Message message={message} messageUuid={messageUuid} reply={handleReply} showMessage={showMessage} recipientUuid={recipientUuid} />
                        )
                    })}

                    <Box
                        w='fit-content'
                        alignSelf='center'
                        pt={8}
                        textAlign='center'
                        >
                    <Button 
                        size='sm' 
                        variant='ghost'
                        mb={1}
                        onClick={() => getMessages()}
                        >
                        Check for new messages
                    </Button>
                    <Text fontSize='xs' color='subtle'>
                        Last checked <Moment fromNow>{lastChecked}</Moment>
                    </Text>
                    </Box>

                </Stack>

                <Stack w='400px' boxShadow='md' spacing={0} mt={4} position='fixed' bottom='0' right='0' bg='#FCFCFC'>

                    {messageUuid != null &&
                        <Box  p={4} borderBottom='1px solid gainsboro'>
                            <HStack pb={2}>
                                <Text fontSize='xs'>Replying to message</Text>
                                <Spacer />
                                <Text onClick={() => setMessageUuid(null)} fontSize='xs' cursor='pointer' color='subtle' _hover={{color: 'red.500'}}>Cancel</Text>
                            </HStack>
                            <Box cursor='pointer' bg='gray.200' borderRadius='md' p={1} onClick={() => showMessage('message-' + messageUuid)}>
                                <Text fontSize='xs' noOfLines='1' fontWeight='bold'>{user && user.Messages.find(message => message.uuid === messageUuid).subject}</Text>
                                <Text fontSize='xs' noOfLines='1'>{user && user.Messages.find(message => message.uuid === messageUuid).body}</Text>
                            </Box>
                        </Box>
                    }
                    

                    <form id='new-message' onSubmit={submitReply} alignSelf='flex-end' >
                        <HStack p={4}  >

                            
                            <AutoResizeTextarea 
                                fontSize='sm'
                                minH='36px'
                                borderRadius='18px'
                                p='6px 12px'
                                placeholder="Type a message"
                                id='reply-body'
                                onLoad={() => {
                                    document.getElementById('reply-body').style.height = document.getElementById('reply-body').scrollHeight+'px';
                                }}
                                onChange={(e) => {
                                    e.target.style.height = e.target.scrollHeight+'px';
                                }}
                                whiteSpace='pre-line'
                                display='inherit'
                                />

                            <IconButton type='submit' p={2} w='36px'
                                h='36px' minW='36px' minH='36px' 
                                onClick={onToggle} icon={<MdSend boxSize='18px'/>} 
                                variant='outline' colorScheme='blue' className='btn-8' />        
                            
                            <Input type='hidden' name='recipientUuid' value={recipientUuid} />
                            <Input type='hidden' name='replyUuid' value={messageUuid} />
                    
                        </HStack>
                    </form>
                
                </Stack>

            </Box>
        }
        </Box>
        

    )
}