import {
  Box,
  Button,
  Circle,
  Heading,
  HStack,
  Icon,
  Tag,
  Wrap,
  List,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'
import { PricingCardBadge } from './PricingCardBadge'
import { Link } from 'react-router-dom'
import Product from './ProductOld';
import API from '../../core/utils/API';
import moment from 'moment';

export const PricingCard = (props) => {
  const { product } = props

  let activeTerm = 'Activated';
  let borderColor = 'black';
  let active = false;
  let trial = false;
  let backgroundImage = 'none';
  let bannerText = '';
  let bannerColor = props.bannerColor ? props.bannerColor : 'blue.500';
  let currentSubscription = undefined;
  let buttonExplaination = '';
  


  return (

    <Box className='gradient-background hover-only' p='2px' borderRadius="lg" 
      position="relative"
      overflow="hidden"
      display='flex'
      mb='24px !important'
      >

    <Box
      bg="bg-surface"
      borderRadius="lg"
      px={{
        base: '6',
        md: '8',
      }}
      py="8"
      backgroundImage={backgroundImage}
      w='fill-available'
    >
      {bannerText === '' && product.isPopular && (
        <PricingCardBadge className='gradient-background' bg=''>
          <Text fontWeight="medium" color='invert'>Popular</Text>
        </PricingCardBadge>
      )}
      {bannerText != '' && (
        <PricingCardBadge bg={bannerColor !== 'gradient-background' ? bannerColor : ''} className={bannerColor}>
          <Text fontWeight="medium" color='invert'>{bannerText}</Text>
        </PricingCardBadge>
      )}
      <Stack spacing="8" textAlign="center">
        <Stack spacing="5" align="center">
          <Stack spacing="4">
            <Heading
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              {product.name}
            </Heading>
            <Stack spacing="1" alignItems='center'>
              <Text fontSize="xl" fontWeight="semibold">
              {product.price}<Text as='span' fontSize='18px' mb='-10px'>{product.price_suffix}</Text> <Text as="span" fontSize="sm" color="muted">{product.price_addendum}</Text>
              </Text>
              <Text color="muted" pb={4}>{product.description}</Text>
              {product.deal != undefined &&
                <Tag display='none' colorScheme='blue' w='fit-content' m='auto'>
                  {product.deal}
                </Tag>
              }
              <Tag display='none' mt={4} cursor='pointer' colorScheme='blue' w='fit-content' m='auto' onClick={props.switchShowMore}>{props.showMore ? 'Hide details' : 'Show details'}</Tag>

            </Stack>
          </Stack>
        </Stack>
        {props.showMore &&
          <List as="ul" spacing="4">
            {product.features.map((feature) => (
              <HStack key={feature} as="li" spacing="3">
                <Circle size="6" bg={mode('whitesmoke', 'whiteAlpha.50')}>
                  <Icon as={FiCheck} color="black" />
                </Circle>
                <Text color="muted" align='left'>{feature}</Text>
              </HStack>
            ))}
          </List>
        }

        <Text color='black'>
            {buttonExplaination}
        </Text>

          <Button display='none' onClick={props.switchShowMore}>{props.showMore ? 'Hide details' : 'Show details'}</Button>
         
          {props.subscriptions.includes(product.id) ?

            <Button colorScheme='red' onClick={() => {}} variant='outline'>
              Cancel
            </Button>

            :

            <Button colorScheme='blue' onClick={() => {}} variant='solid'
              onClick={() => {
                props.createCheckoutSession(props.product.price_id);
              }}
              >
              Subscribe
            </Button>
          }
          
      </Stack>
    </Box>
    </Box>
  )
}

export default PricingCard