import { 
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper, 
    HStack,
    Text,
    Input,
    InputGroup,
    InputLeftAddon,

} from "@chakra-ui/react";

import InputMask from "react-input-mask";
import React from "react";

export default function NumericalWrapper(props) {


    let options = props.options;
    if (options !== undefined) {
        options = JSON.parse(options);
    }

    const [localValue, setLocalValue] = React.useState(props.defaultValue);

    return (

        <>

            {props.type === 'integer' &&
            
                <NumberInput defaultValue={props.defaultValue} precision={0} min={options && options.min} max={options && options.max} onChange={(val) => props.setValue(props.name, val)} size={props.size} w={props.w ? props.w : 'fill-available'

                } maxW={props.maxW}>
                    <NumberInputField w={props.w ? props.w : 'fill-available'} maxW={props.maxW}

                    />
                    {(props.stepper === true || props.stepper === undefined) &&
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    }
                </NumberInput>
            
            
            }

            {props.type === 'decimal' && 
            
                <NumberInput defaultValue={props.defaultValue} precision={options ? options.precision : 2} min={options && options.min} max={options && options.max} onChange={(val) => props.setValue(props.name, val)} size={props.size}>
                    <NumberInputField />
                </NumberInput>

            }

            {props.type === 'currency' &&

            <>
            
                <InputGroup>
                    <InputLeftAddon>£</InputLeftAddon>
                    
                    <Input
                        defaultValue={localValue ? localValue : props.defaultValue}
                        value={localValue}
                        onChange={(val) => {
                            // Add commas after every 3 digits
                            let commaVal = val.target.value.replace(/,/g, '');

                            // Remove all non-numeric characters
                            commaVal = commaVal.replace(/[^0-9.]/g, '');
                            
                            let formattedVal = commaVal.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            console.log(formattedVal);
                            if (props.setValue) {
                                props.setValue(props.name, formattedVal);
                            }
                            setLocalValue(formattedVal);
                            return formattedVal;
                        }}
                        />
                </InputGroup>

                </>
            }

        </>

    )
}