import { get } from "react-hook-form";
import Layout from "../core/layout/Layout";
import Images from "../users/images/Manage";
import API from "../core/utils/API";

export default function Blank(props) {
    

    const getPicture = async (tokenIn) => {

        console.log("Loading profile picture");
        let config = {
          params: {
            userUuid: 'me'
          }
        }
  
        let picture = await API.getItems({
          url: 'users/picture', 
          config
        });
  
        console.log("Picture: ", picture);
  
        if (picture.data.images[0] === undefined || picture.data.images[0].url === null) {
          //setPicture('/profile.png');
        } else {
          console.log(picture.data.images[0].url);
        }
      };
 
      getPicture('test');
    return (

        <Layout
            title='Blank'
            description="A blank page to test core functionality without confusing thhe API logs with unnecessary calls."
        >



        </Layout>
    )
}