
import Footer from '../layout/Footer';
import CallToAction from '../shared/CallToAction';
import PyrexiaDivider from '../layout/PyrexiaDivider';
import { Box, Heading, Text, Image, UnorderedList, ListItem } from '@chakra-ui/react';
export default function WorkflowProfessionals() {


    return (
        <Box>
          <Box as="section" py="7.5rem">
            <Box
              maxW={{
                base: 'xl',
                md: '7xl',
              }}
              mx="auto"
              px={{
                base: '6',
                md: '8',
              }}
            >
                <Heading as="h1" size="pagetitle">
                  How it works<Text as='span' color='#0166ff'>.<br/>
                  For professionals
                </Text>
                </Heading>
                <Heading size='statictagline'>
                Tired of repeating paperwork and digging up documents?<br/>
                Pyrexia is here to help. 
                </Heading>
                <Text size='staticsmalltext'>
                <sup>Ø</sup>Pyrexia is a cloud-based platform that automates HR onboarding and compliance for healthcare professionals and organisations in the UK.                 
                </Text>
                <Text size='staticsmalltext'>
                  As a healthcare professional, you entered this field to help patients - not battle endless administrative HR tasks. Yet outdated onboarding and compliance processes steal precious hours you could be spending with those in need.
                </Text>
              </Box>
    
            </Box>
  

              <PyrexiaDivider mb="12" pb={12} />
  
            <Box as='section' pb='7.5rem' pt='7.5em'>
              <Box
                maxW={{base: 'xl',md: '7xl',}}
                mx="auto"
                px={{base: '6',md: '8',}}
                >
                <Heading size="smalltitle">
                Introducing <sup>Ø</sup>Pyrexia.
                </Heading>
                <Heading size='statictagline' fontSize='22px' mb={12} mt={8}>
                  Our intelligent HR automation platform is purpose-built to eliminate the repetitive paperwork that's burning you out. Here's how:
                </Heading>
                <UnorderedList>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Upload your credentials just once. Our AI engine instantly extracts key details - no more data re-entry across multiple forms.                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Compliance renewals are proactively tracked with automated alerts - no more last minute scramble to maintain credentials.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Your secure <sup>Ø</sup>Pyrexia Passport enables one-click sharing of qualifications across NHS systems - frictionless mobility.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Intuitive access lets you manage compliance on-the-go.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Built-in tools like Multi-factor Authentication, notifications, and access tracking keep your data ultra secure.
                    </Text>
                  </ListItem>

                </UnorderedList>
                <Text size='staticsmalltext'>
                <sup>Ø</sup>Pyrexia gives you back the hours wasted on repetitive admin - so you can focus on patient care, not paperwork.
                </Text>
              </Box>
            </Box>
  
  
  
            <Box as='section' bg='#000' color='white' py='7.5rem'>
              <Box
                maxW={{base: 'xl',md: '7xl',}}
                mx="auto"
                px={{base: '6',md: '8',}}
                >
                <Heading size="smalltitle" color='inverted'>
                  We want to make life easier for everyone.
                </Heading>
                <Heading size='statictagline' fontSize='22px' mb={12} mt={8} color='inverted'>
                <sup>Ø</sup>Pyrexia is working with healthcare professionals around the UK to make HR simpler.
                </Heading>
                <Image src='/images/WeDoThis.png' />
              </Box>
            </Box>
  
  
            <CallToAction/>
            
            <Footer/>
  
          </Box>
    )

}