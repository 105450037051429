import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Heading, Text, Stack, Box, Container, Flex, Image, Button, Link, GridItem } from '@chakra-ui/react';
import API from '../../core/utils/API';

function ProcesStep(props) {

    let color = 'gray.500';
    let buttonStyle = 'outline';
    let buttonDisabled = 'disabled';
    if (props.status === 'complete') {
        color='green.500'
    } else if (props.status === 'rejected') {
        color = 'red.500'
    } else if (props.status === 'inprogress') {
        color='blue.500'
    } else if (props.status === 'available') {
        color='blue.500'
        buttonDisabled = '';
        buttonStyle = 'solid';
    }

    return (

        <Box p={4} borderLeft='4px solid black' borderColor={color}>
            <Heading size='label'>{props.title}</Heading>
            <Text>{props.description}</Text>
            <Button variant={buttonStyle} disabled={buttonDisabled} colorScheme={color}>{props.actionText}</Button>
        </Box>
    )
}

export default function Apply(props) {

    const [iframeURL, setIframeURL] = useState(null);

    const [status, setStatus] = useState({
        idcheck: null,
        details: null,
        submitted: null,
        outcome: null,
    });


    useEffect(() => {

        API.getItems({url: 'dbs/'})
        .then(response => {

            //console.log("response", response);
            setIframeURL(response.data.url);

        })

        
    }, []); 

    return (
        
        <Layout 
            title='DBS Check'
            description='Apply for a DBS check'
            >

            <GridItem colSpan={6}>



                        <Box p={4} borderLeft='4px solid black' borderColor='blue.500'>
                            <Heading size='label'>Document Verification</Heading>
                            <Text>To apply for a Disclosure and Barring Service check you need to provide identification documents, we will validate these digitaly.</Text>
                            <Button variant='solid' colorScheme='blue'>Verify</Button>
                        </Box>

                        <Box p={4} borderLeft='4px solid black' borderColor='gray.500'>
                            <Heading size='label'>Disclosure & Barring Service Check</Heading>
                            <Text>Once your documents are verified we need some basic information to submit the DBS check</Text>
                            <Button disbaled variant='outline' colorScheme='blue'>Complete</Button>
                        </Box>

                        <Box p={4} borderLeft='4px solid black' borderColor='grey.500'>
                            <Heading size='label'>Disclosure & Barring Service Check</Heading>
                            <Text>We can submit the application once your documents are verified and the addiitional information is submitted.</Text>
                            <Button variant='outline' colorScheme='grey'>Not Started</Button>
                        </Box>

            </GridItem>

        </Layout>
    )
}