

// we need to have the following translations:
/*
    English
    Urdu
    Punjabi
    Bengali
    Pakistani Pahari
    Polish
    Somali
    Arabic
    Pashto
    Mandarin
    Gujarati
*/

/*
let translations = [
    'Contact Details',
    'Please enter your contact details so we can get back to you.',
    'Patient Details',
    'Please enter the details of the patient, if you are not the patient.',
    'Overview',
    'Please enter the overview of your complaint.'
    'Comments and Details',
    'Please enter as much information as possible so we can help you.'
    'Your Demographics',
    'This information is important so we can ensure we are serving all of our community.',
    'Firstname',
    'Lastname',
    'Email',
    'Phone',
    'Address',
    'First Line',
    'Second Line',
    'City',
    'Country',
    'Postcode',
    'Step 1 of 5',
    'Step 2 of 5',
    'Step 3 of 5',
    'Step 4 of 5',
    'Step 5 of 5',
    'Next',
    'Back',
    'Submit',
    'Review',
    'Are you filing this complaint on behalf of someone else?',
    'No',
    'Yes',
    'NHS Number',
    'Hospital Number',
    'Date of Birth',
    'Consent is required',
    'We need consent from the person you are filing this complaint on behalf of, we will contact them to confirm this.',
    'Is the patient aware of this submission?',
    'Why are you contacting us?',
    'I want to file a complaint',
    'I want to file a compliment',
    'I want to file a suggestion',
    'I want to file a comment',
    'Which site is this about?',
    'Which department(s) is this about?',
    'You can select multiple departments, or add on if it is not listed.',
    'What is your expected outcome?',
    'I want an apology',
    'I want an explanation',
    'I want an investigation',
    'I want to ensure this is a learning opportunity',
    'None',
    'Other',
    'Description',
    'Please describe the event in as much detail as possible.',
    'Do you have a disability?',
    'What is your preferred language?',
    'Do you need an interpreter?',
]

*/

let translationsAll = 
    {
        "Contact Details": {
            "Urdu": "رابطہ کی تفصیلات",
            "Punjabi": "ਸੰਪਰਕ ਵੇਰਵੇ",
            "Bengali": "যোগাযোগের বিবরণ",
            "Pakistani Pahari": "رابطہ دی معلومات",
            "Polish": "Dane kontaktowe",
            "Somali": "Faahfaahinta Xiriirka",
            "Arabic": "تفاصيل الاتصال",
            "Pashto": "د اړیکو جزییات",
            "Mandarin": "联系方式",
            "Gujarati": "સંપર્ક વિગતો"
        },
        "Please enter your contact details so we can get back to you.": {
            "Urdu": "براہ کرم اپنی رابطہ کی تفصیلات درج کریں تاکہ ہم آپ سے رابطہ کر سکیں۔",
            "Punjabi": "ਕ੍ਰਿਪਾ ਕਰਕੇ ਆਪਣੇ ਸੰਪਰਕ ਵੇਰਵੇ ਦਰਜ ਕਰੋ ਤਾਂ ਜੋ ਅਸੀਂ ਤੁਹਾਨੂੰ ਜਵਾਬ ਦੇ ਸਕੀਏ।",
            "Bengali": "দয়া করে আপনার যোগাযোগের বিবরণ প্রদান করুন যাতে আমরা আপনাকে জবাব দিতে পারি।",
            "Pakistani Pahari": "براہ کرم اپنی رابطہ دی معلومات درج کرو تاکہ اسی تہاڈے نال رابطہ کر سکاں۔",
            "Polish": "Proszę wprowadzić swoje dane kontaktowe, abyśmy mogli się z Państwem skontaktować.",
            "Somali": "Fadlan geli faahfaahinta xiriirkaaga si aan dib kuuula soo xiriirno.",
            "Arabic": "يرجى إدخال تفاصيل الاتصال الخاصة بك حتى نتمكن من العودة إليك.",
            "Pashto": "مهرباني وکړئ خپل د اړیکو جزییات ولیکئ چې موږ ستاسو ته بیرته اړیکه ونیسو.",
            "Mandarin": "请提供您的联系方式，以便我们与您联系。",
            "Gujarati": "કૃપા કરીને તમારી સંપર્ક વિગતો દાખલ કરો જેથી અમે તમારી પાસે પાછા આવી શકીએ."
        },
        "Patient Details": {
            "Urdu": "مریض کی تفصیلات",
            "Punjabi": "ਮਰੀਜ਼ ਦੇ ਵੇਰਵੇ",
            "Bengali": "রোগীর বিবরণ",
            "Pakistani Pahari": "مریض دی معلومات",
            "Polish": "Dane pacjenta",
            "Somali": "Faahfaahinta Bukaanka",
            "Arabic": "تفاصيل المريض",
            "Pashto": "د ناروغ جزییات",
            "Mandarin": "患者详情",
            "Gujarati": "દર્દીની વિગતો"
        },
        "Please enter the details of the patient, if you are not the patient.": {
            "Urdu": "براہ کرم مریض کی تفصیلات درج کریں، اگر آپ مریض نہیں ہیں۔",
            "Punjabi": "ਜੇ ਤੁਸੀਂ ਮਰੀਜ਼ ਨਹੀਂ ਹੋ ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਮਰੀਜ਼ ਦੇ ਵੇਰਵੇ ਦਰਜ ਕਰੋ।",
            "Bengali": "আপনি যদি রোগী না হন, তাহলে দয়া করে রোগীর বিবরণ প্রদান করুন।",
            "Pakistani Pahari": "براہ کرم مریض دی معلومات درج کرو، جے تسی مریض نہیں ہو۔",
            "Polish": "Proszę wprowadzić dane pacjenta, jeśli nie jesteś pacjentem.",
            "Somali": "Fadlan geli faahfaahinta bukaanka, haddii aadan ahayn bukaanka.",
            "Arabic": "يرجى إدخال تفاصيل المريض، إذا لم تكن أنت المريض.",
            "Pashto": "مهرباني وکړئ د ناروغ جزییات ولیکئ که تاسو ناروغ نه یاست.",
            "Mandarin": "如果您不是患者，请输入患者的详细信息。",
            "Gujarati": "જો તમે દર્દી ન હોવ તો કૃપા કરી દર્દીની વિગતો દાખલ કરો."
        },
        "Overview": {
            "Urdu": "جائزہ",
            "Punjabi": "ਝਲਕ",
            "Bengali": "সারসংক্ষেপ",
            "Pakistani Pahari": "جائزہ",
            "Polish": "Przegląd",
            "Somali": "Guudmar",
            "Arabic": "نظرة عامة",
            "Pashto": "لنډیز",
            "Mandarin": "概述",
            "Gujarati": "ઝાંખી"
        },
        
            "Please enter the overview of your complaint.": {
                "Urdu": "براہ کرم اپنی شکایت کا خلاصہ درج کریں۔",
                "Punjabi": "ਕ੍ਰਿਪਾ ਕਰਕੇ ਆਪਣੀ ਸ਼ਿਕਾਇਤ ਦਾ ਝਲਕ ਦਰਜ ਕਰੋ।",
                "Bengali": "দয়া করে আপনার অভিযোগের সারাংশ প্রদান করুন।",
                "Pakistani Pahari": "براہ کرم اپنی شکایت دا خلاصہ درج کرو۔",
                "Polish": "Proszę wprowadzić ogólny zarys skargi.",
                "Somali": "Fadlan geli guudmarka cabashadaada.",
                "Arabic": "يرجى إدخال نظرة عامة على شكواك.",
                "Pashto": "مهرباني وکړئ خپل د شکایت لنډیز ولیکئ.",
                "Mandarin": "请提供您投诉的概述。",
                "Gujarati": "કૃપા કરીને તમારી ફરિયાદનું સારાંશ દાખલ કરો."
            },
            "Comments and Details": {
                "Urdu": "تبصرے اور تفصیلات",
                "Punjabi": "ਟਿੱਪਣੀਆਂ ਅਤੇ ਵੇਰਵੇ",
                "Bengali": "মন্তব্য এবং বিবরণ",
                "Pakistani Pahari": "تبصرے تے تفصیلات",
                "Polish": "Komentarze i szczegóły",
                "Somali": "Faallooyin iyo Faahfaahin",
                "Arabic": "تعليقات وتفاصيل",
                "Pashto": "تبصرې او جزییات",
                "Mandarin": "评论和详情",
                "Gujarati": "ટિપ્પણીઓ અને વિગતો"
            },
            "Please enter as much information as possible so we can help you.": {
                "Urdu": "براہ کرم جتنی ممکن ہو سکے معلومات درج کریں تاکہ ہم آپ کی مدد کر سکیں۔",
                "Punjabi": "ਕ੍ਰਿਪਾ ਕਰਕੇ ਜਿੰਨੀ ਸੰਭਵ ਹੋਵੇ ਉੰਨੀ ਜਾਣਕਾਰੀ ਦਰਜ ਕਰੋ ਤਾਂ ਜੋ ਅਸੀਂ ਤੁਹਾਨੂੰ ਮਦਦ ਕਰ ਸਕੀਏ।",
                "Bengali": "দয়া করে যতটা সম্ভব তথ্য প্রদান করুন যাতে আমরা আপনাকে সাহায্য করতে পারি।",
                "Pakistani Pahari": "براہ کرم جنی ممکن ہووے اونی معلومات درج کرو تاکہ اسی تہاڈی مدد کر سکاں۔",
                "Polish": "Proszę wprowadzić jak najwięcej informacji, abyśmy mogli Państwu pomóc.",
                "Somali": "Fadlan geli inta macluumaad ah ee suurtagal ah si aan kuugu caawinno.",
                "Arabic": "يرجى إدخال أكبر قدر ممكن من المعلومات حتى نتمكن من مساعدتك.",
                "Pashto": "مهرباني وکړئ چې ډېر معلومات ورکړئ چې موږ ستاسو سره مرسته کولی شو.",
                "Mandarin": "请尽可能提供更多信息，以便我们帮助您。",
                "Gujarati": "કૃપા કરી જેટલી શક્ય હોય તેટલી માહિતી આપો જેથી અમે તમારી મદદ કરી શકીએ."
            },
            "Your Demographics": {
                "Urdu": "آپ کی آبادیاتی معلومات",
                "Punjabi": "ਤੁਹਾਡੀ ਆਬਾਦੀ ਦੀ ਜਾਣਕਾਰੀ",
                "Bengali": "আপনার জনমিতি",
                "Pakistani Pahari": "تہاڈی آبادیاتی معلومات",
                "Polish": "Twoje dane demograficzne",
                "Somali": "Macluumaadkaaga Dadweynaha",
                "Arabic": "بياناتك الديموغرافية",
                "Pashto": "ستاسو ډیموګرافیک معلومات",
                "Mandarin": "您的人口统计数据",
                "Gujarati": "તમારી વસ્તીવિજ્ઞાનની માહિતી"
            },
            "This information is important so we can ensure we are serving all of our community.": {
                "Urdu": "یہ معلومات اہم ہیں تاکہ ہم یقینی بنا سکیں کہ ہم اپنی پوری کمیونٹی کی خدمت کر رہے ہیں۔",
                "Punjabi": "ਇਹ ਜਾਣਕਾਰੀ ਮਹੱਤਵਪੂਰਣ ਹੈ ਤਾਂ ਜੋ ਅਸੀਂ ਯਕੀਨੀ ਬਣਾ ਸਕੀਏ ਕਿ ਅਸੀਂ ਸਾਡੀ ਪੂਰੀ ਭਾਈਚਾਰੇ ਦੀ ਸੇਵਾ ਕਰ ਰਹੇ ਹਾਂ।",
                "Bengali": "এই তথ্য গুরুত্বপূর্ণ যাতে আমরা নিশ্চিত করতে পারি যে আমরা আমাদের সমস্ত সম্প্রদায়ের সেবা করছি।",
                "Pakistani Pahari": "اے معلومات اہم نیں تاکہ اسی یقینی بنا سکاں کہ اسی اپنی پوری کمیونٹی دی خدمت کر رہے آں۔",
                "Polish": "Te informacje są ważne, abyśmy mogli upewnić się, że obsługujemy całą naszą społeczność.",
                "Somali": "Macluumaadkan waa muhiim si aan u xaqiijinno inaan u adeegeyno bulshadeena oo dhan.",
                "Arabic": "هذه المعلومات مهمة حتى نتمكن من التأكد من خدمتنا لجميع أفراد مجتمعنا.",
                "Pashto": "دا معلومات مهم دي ترڅو موږ ډاډ ترلاسه کړو چې موږ زموږ ټول ټولنه خدمت کوو.",
                "Mandarin": "这些信息很重要，以确保我们为整个社区服务。",
                "Gujarati": "આ માહિતી મહત્વપૂર્ણ છે જેથી અમે ખાતરી કરી શકીએ કે અમે આપણા સમુદાયના તમામ લોકોની સેવા કરીએ છીએ."
            }
        ,
        
        
            "Firstname": {
                "Urdu": "پہلا نام",
                "Punjabi": "ਪਹਿਲਾ ਨਾਂ",
                "Bengali": "প্রথম নাম",
                "Pakistani Pahari": "پہلا ناں",
                "Polish": "Imię",
                "Somali": "Magaca Koowaad",
                "Arabic": "الاسم الأول",
                "Pashto": "لومړی نوم",
                "Mandarin": "名字",
                "Gujarati": "પ્રથમ નામ"
            },
            "Lastname": {
                "Urdu": "آخری نام",
                "Punjabi": "ਆਖਰੀ ਨਾਂ",
                "Bengali": "শেষ নাম",
                "Pakistani Pahari": "آخری ناں",
                "Polish": "Nazwisko",
                "Somali": "Magaca Dambe",
                "Arabic": "اسم العائلة",
                "Pashto": "وروستی نوم",
                "Mandarin": "姓氏",
                "Gujarati": "છેલ્લું નામ"
            },
            "Email": {
                "Urdu": "ای میل",
                "Punjabi": "ਈ-ਮੇਲ",
                "Bengali": "ইমেইল",
                "Pakistani Pahari": "ای میل",
                "Polish": "E-mail",
                "Somali": "Iimayl",
                "Arabic": "البريد الإلكتروني",
                "Pashto": "برېښنالیک",
                "Mandarin": "电子邮件",
                "Gujarati": "ઇમેઇલ"
            },
            "Phone": {
                "Urdu": "فون",
                "Punjabi": "ਫੋਨ",
                "Bengali": "ফোন",
                "Pakistani Pahari": "فون",
                "Polish": "Telefon",
                "Somali": "Telefoon",
                "Arabic": "الهاتف",
                "Pashto": "ټیلیفون",
                "Mandarin": "电话",
                "Gujarati": "ફોન"
            },
            "Address": {
                "Urdu": "پتہ",
                "Punjabi": "ਪਤਾ",
                "Bengali": "ঠিকানা",
                "Pakistani Pahari": "پتہ",
                "Polish": "Adres",
                "Somali": "Cinwaan",
                "Arabic": "العنوان",
                "Pashto": "پته",
                "Mandarin": "地址",
                "Gujarati": "સરનામું"
            },
            "First Line": {
                "Urdu": "پہلی لائن",
                "Punjabi": "ਪਹਿਲੀ ਲਾਈਨ",
                "Bengali": "প্রথম লাইন",
                "Pakistani Pahari": "پہلی لائن",
                "Polish": "Pierwsza linia",
                "Somali": "Khadka Koowaad",
                "Arabic": "السطر الأول",
                "Pashto": "لومړی کرښه",
                "Mandarin": "第一行",
                "Gujarati": "પ્રથમ લાઇન"
            },
            "Second Line": {
                "Urdu": "دوسری لائن",
                "Punjabi": "ਦੂਜੀ ਲਾਈਨ",
                "Bengali": "দ্বিতীয় লাইন",
                "Pakistani Pahari": "دوجی لائن",
                "Polish": "Druga linia",
                "Somali": "Khadka Labaad",
                "Arabic": "السطر الثاني",
                "Pashto": "دوهم کرښه",
                "Mandarin": "第二行",
                "Gujarati": "બીજી લાઇન"
            },
            "City": {
                "Urdu": "شہر",
                "Punjabi": "ਸ਼ਹਿਰ",
                "Bengali": "শহর",
                "Pakistani Pahari": "شہر",
                "Polish": "Miasto",
                "Somali": "Magaalo",
                "Arabic": "المدينة",
                "Pashto": "ښار",
                "Mandarin": "城市",
                "Gujarati": "શહેર"
            },
            "Country": {
                "Urdu": "ملک",
                "Punjabi": "ਦੇਸ਼",
                "Bengali": "দেশ",
                "Pakistani Pahari": "ملک",
                "Polish": "Kraj",
                "Somali": "Waddan",
                "Arabic": "البلد",
                "Pashto": "هېواد",
                "Mandarin": "国家",
                "Gujarati": "દેશ"
            },
            "Postcode": {
                "Urdu": "پوسٹ کوڈ",
                "Punjabi": "ਡਾਕ ਕੋਡ",
                "Bengali": "পোস্ট কোড",
                "Pakistani Pahari": "پوسٹ کوڈ",
                "Polish": "Kod pocztowy",
                "Somali": "Lambarka Boostada",
                "Arabic": "الرمز البريدي",
                "Pashto": "پوسټ کوډ",
                "Mandarin": "邮政编码",
                "Gujarati": "પોસ્ટ કોડ"
            }
        ,
        
            
                "Are you filing this complaint on behalf of someone else?": {
                    "Urdu": "کیا آپ یہ شکایت کسی اور کی طرف سے دائر کر رہے ہیں؟",
                    "Punjabi": "ਕੀ ਤੁਸੀਂ ਇਹ ਸ਼ਿਕਾਇਤ ਕਿਸੇ ਹੋਰ ਦੀ ਤਰਫੋਂ ਦਰਜ ਕਰ ਰਹੇ ਹੋ?",
                    "Bengali": "আপনি কি অন্য কারো পক্ষ থেকে এই অভিযোগ দায়ের করছেন?",
                    "Pakistani Pahari": "کیا تسی ایہہ شکایت کسی ہور دی طرفوں دائر کر رہے او؟",
                    "Polish": "Czy składasz tę skargę w imieniu kogoś innego?",
                    "Somali": "Ma waxaad dacwadan u gudbinaysaa qof kale?",
                    "Arabic": "هل تقدم هذه الشكوى نيابةً عن شخص آخر؟",
                    "Pashto": "ایا تاسو دا شکایت بل چا په استازیتوب کې کوئ؟",
                    "Mandarin": "你是代表别人提出这个投诉吗？",
                    "Gujarati": "શું તમે બીજા કોઈની તરફથી આ ફરિયાદ દાખલ કરી રહ્યા છો?"
                },
                "No": {
                    "Urdu": "نہیں",
                    "Punjabi": "ਨਹੀਂ",
                    "Bengali": "না",
                    "Pakistani Pahari": "نہیں",
                    "Polish": "Nie",
                    "Somali": "Maya",
                    "Arabic": "لا",
                    "Pashto": "نه",
                    "Mandarin": "不是",
                    "Gujarati": "ના"
                },
                "Yes": {
                    "Urdu": "ہاں",
                    "Punjabi": "ਹਾਂ",
                    "Bengali": "হ্যাঁ",
                    "Pakistani Pahari": "ہاں",
                    "Polish": "Tak",
                    "Somali": "Haa",
                    "Arabic": "نعم",
                    "Pashto": "هو",
                    "Mandarin": "是",
                    "Gujarati": "હા"
                },
                "NHS Number": {
                    "Urdu": "این ایچ ایس نمبر",
                    "Punjabi": "ਐਨ.ਐਚ.ਐਸ. ਨੰਬਰ",
                    "Bengali": "এনএইচএস নম্বর",
                    "Pakistani Pahari": "این ایچ ایس نمبر",
                    "Polish": "Numer NHS",
                    "Somali": "Lambarka NHS",
                    "Arabic": "رقم الخدمات الصحية الوطنية",
                    "Pashto": "د NHS شمیره",
                    "Mandarin": "NHS 编号",
                    "Gujarati": "એનએચએસ નંબર"
                },
                
                    "Hospital Number": {
                        "Urdu": "ہسپتال نمبر",
                        "Punjabi": "ਹਸਪਤਾਲ ਦਾ ਨੰਬਰ",
                        "Bengali": "হাসপাতালের নম্বর",
                        "Pakistani Pahari": "ہسپتال دا نمبر",
                        "Polish": "Numer szpitala",
                        "Somali": "Lambarka Isbitaalka",
                        "Arabic": "رقم المستشفى",
                        "Pashto": "د روغتون شمیره",
                        "Mandarin": "医院号码",
                        "Gujarati": "હોસ્પિટલ નંબર"
                    },
                    "Date of Birth": {
                        "Urdu": "تاریخ پیدائش",
                        "Punjabi": "ਜਨਮ ਮਿਤੀ",
                        "Bengali": "জন্ম তারিখ",
                        "Pakistani Pahari": "تاریخ پیدائش",
                        "Polish": "Data urodzenia",
                        "Somali": "Taariikhda Dhalashada",
                        "Arabic": "تاريخ الميلاد",
                        "Pashto": "د زېږون نېټه",
                        "Mandarin": "出生日期",
                        "Gujarati": "જન્મ તારીખ"
                    },
                    "Consent is required": {
                        "Urdu": "رضامندی ضروری ہے",
                        "Punjabi": "ਸਹਿਮਤੀ ਦੀ ਲੋੜ ਹੈ",
                        "Bengali": "সম্মতি প্রয়োজন",
                        "Pakistani Pahari": "رضامندی ضروری اے",
                        "Polish": "Wymagana jest zgoda",
                        "Somali": "Oggolaansho ayaa loo baahan yahay",
                        "Arabic": "الموافقة مطلوبة",
                        "Pashto": "موافقه ته اړتیا لري",
                        "Mandarin": "需要同意",
                        "Gujarati": "સંમતિ આવશ્યક છે"
                    },
                    
                        "I want to file a complaint": {
                            "Urdu": "میں شکایت درج کرنا چاہتا ہوں",
                            "Punjabi": "ਮੈਂ ਇਕ ਸ਼ਿਕਾਇਤ ਦਰਜ ਕਰਨਾ ਚਾਹੁੰਦਾ ਹਾਂ",
                            "Bengali": "আমি একটি অভিযোগ দায়ের করতে চাই",
                            "Pakistani Pahari": "میں شکایت درج کرنا چاہندا ہاں",
                            "Polish": "Chcę złożyć skargę",
                            "Somali": "Waxaan rabaa inaan dacwad soo gudbiyo",
                            "Arabic": "أريد تقديم شكوى",
                            "Pashto": "زه غواړم شکایت وکړم",
                            "Mandarin": "我想要提出投诉",
                            "Gujarati": "હું ફરિયાદ કરવા માંગુ છું"
                        },
                        "I want to file a compliment": {
                            "Urdu": "میں تعریف درج کرنا چاہتا ہوں",
                            "Punjabi": "ਮੈਂ ਤਾਰੀਫ਼ ਦਰਜ ਕਰਨਾ ਚਾਹੁੰਦਾ ਹਾਂ",
                            "Bengali": "আমি একটি প্রশংসা দায়ের করতে চাই",
                            "Pakistani Pahari": "میں تعریف درج کرنا چاہندا ہاں",
                            "Polish": "Chcę złożyć komplement",
                            "Somali": "Waxaan rabaa inaan bogaadin soo gudbiyo",
                            "Arabic": "أريد تقديم مجاملة",
                            "Pashto": "زه غواړم ستاینه وکړم",
                            "Mandarin": "我想要表扬",
                            "Gujarati": "હું પ્રશંસા કરવા માંગુ છું"
                        },
                     
                        
                            "I want an explanation": {
                                "Urdu": "میں وضاحت چاہتا ہوں",
                                "Punjabi": "ਮੈਂ ਇਕ ਸਪਸ਼ਟੀਕਰਨ ਚਾਹੁੰਦਾ ਹਾਂ",
                                "Bengali": "আমি একটি ব্যাখ্যা চাই",
                                "Pakistani Pahari": "میں وضاحت چاہندا ہاں",
                                "Polish": "Chcę wyjaśnienia",
                                "Somali": "Waxaan rabaa sharaxaad",
                                "Arabic": "أريد توضيحًا",
                                "Pashto": "زه تشریح غواړم",
                                "Mandarin": "我想要一个解释",
                                "Gujarati": "હું સ્પષ્ટીકરણ માંગુ છું"
                            },
                            "I want an investigation": {
                                "Urdu": "میں تحقیقات چاہتا ہوں",
                                "Punjabi": "ਮੈਂ ਇਕ ਜਾਂਚ ਚਾਹੁੰਦਾ ਹਾਂ",
                                "Bengali": "আমি একটি তদন্ত চাই",
                                "Pakistani Pahari": "میں تحقیقات چاہندا ہاں",
                                "Polish": "Chcę dochodzenia",
                                "Somali": "Waxaan rabaa baaritaan",
                                "Arabic": "أريد تحقيقًا",
                                "Pashto": "زه تحقیق غواړم",
                                "Mandarin": "我想要一个调查",
                                "Gujarati": "હું તપાસ માંગુ છું"
                            },
                            
                                "None": {
                                    "Urdu": "کوئی نہیں",
                                    "Punjabi": "ਕੋਈ ਨਹੀਂ",
                                    "Bengali": "কোনটিই নয়",
                                    "Pakistani Pahari": "کوئی نہیں",
                                    "Polish": "Żaden",
                                    "Somali": "Midna",
                                    "Arabic": "لا شيء",
                                    "Pashto": "هیڅ نه",
                                    "Mandarin": "无",
                                    "Gujarati": "કોઈ નહીં"
                                },
                                "Other": {
                                    "Urdu": "دیگر",
                                    "Punjabi": "ਹੋਰ",
                                    "Bengali": "অন্যান্য",
                                    "Pakistani Pahari": "ہور",
                                    "Polish": "Inny",
                                    "Somali": "Kale",
                                    "Arabic": "آخر",
                                    "Pashto": "نور",
                                    "Mandarin": "其他",
                                    "Gujarati": "અન્ય"
                                },
                                "Description": {
                                    "Urdu": "تفصیل",
                                    "Punjabi": "ਵੇਰਵਾ",
                                    "Bengali": "বিবরণ",
                                    "Pakistani Pahari": "تفصیل",
                                    "Polish": "Opis",
                                    "Somali": "Sharaxaad",
                                    "Arabic": "وصف",
                                    "Pashto": "تشریح",
                                    "Mandarin": "描述",
                                    "Gujarati": "વર્ણન"
                                },
                                "Please describe the event in as much detail as possible.": {
                                    "Urdu": "براہ کرم واقعہ کی جتنی تفصیل ہو سکے بیان کریں۔",
                                    "Punjabi": "ਕ੍ਰਿਪਾ ਕਰਕੇ ਘਟਨਾ ਦਾ ਜਿੱਤਣਾ ਹੋ ਸਕੇ ਵੇਰਵਾ ਕਰੋ।",
                                    "Bengali": "দয়া করে ঘটনাটি যথাসম্ভব বিস্তারিতভাবে বর্ণনা করুন।",
                                    "Pakistani Pahari": "براہ کرم واقعہ دی جنی تفصیل ہووے بیان کرو۔",
                                    "Polish": "Proszę opisać wydarzenie tak szczegółowo, jak to możliwe.",
                                    "Somali": "Fadlan faahfaahi dhacdada intii suurtagal ah.",
                                    "Arabic": "يرجى وصف الحدث بأكبر قدر ممكن من التفاصيل.",
                                    "Pashto": "مهرباني وکړئ پېښه په امکان توگه په تفصیل سره وړاندې کړئ.",
                                    "Mandarin": "请尽可能详细地描述事件。",
                                    "Gujarati": "કૃપા કરીને બનાવનું જેટલું વર્ણન શક્ય હોય તેટલું કરો."
                                },
                                "Do you have a disability?": {
                                    "Urdu": "کیا آپ معذور ہیں؟",
                                    "Punjabi": "ਕੀ ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਅਯੋਗਤਾ ਹੈ?",
                                    "Bengali": "আপনার কি কোনো প্রতিবন্ধীতা আছে?",
                                    "Pakistani Pahari": "کیا تسی معذور او؟",
                                    "Polish": "Czy masz jakąś niepełnosprawność?",
                                    "Somali": "Ma leedahay naafonimo?",
                                    "Arabic": "هل لديك إعاقة؟",
                                    "Pashto": "ایا تاسو ناباوري لرئ؟",
                                    "Mandarin": "你有残疾吗？",
                                    "Gujarati": "શું તમારી કોઈ અપંગતા છે?"
                                },
                                "What is your preferred language?": {
                                    "Urdu": "آپ کی پسندیدہ زبان کیا ہے؟",
                                    "Punjabi": "ਤੁਹਾਡੀ ਪਸੰਦੀਦਾ ਭਾਸ਼ਾ ਕੀਹ ਹੈ?",
                                    "Bengali": "আপনার পছন্দের ভাষা কী?",
                                    "Pakistani Pahari": "تہاڈی پسندیدہ زبان کی اے؟",
                                    "Polish": "Jaki jest Twój preferowany język?",
                                    "Somali": "Waa maxay luqadda aad doorbideyso?",
                                    "Arabic": "ما هي اللغة المفضلة لديك؟",
                                    "Pashto": "ستاسو غوره ژبه کومه ده؟",
                                    "Mandarin": "你偏好哪种语言？",
                                    "Gujarati": "તમારી પસંદગીની ભાષા કઈ છે?"
                                },
                                "Do you need an interpreter?": {
                                    "Urdu": "کیا آپ کو ترجمان کی ضرورت ہے؟",
                                    "Punjabi": "ਕੀ ਤੁਹਾਨੂੰ ਦੁਭਾਸ਼ੀਏ ਦੀ ਲੋੜ ਹੈ?",
                                    "Bengali": "আপনার কি দোভাষীর প্রয়োজন?",
                                    "Pakistani Pahari": "کیا تہانوں ترجمان دی لوڑ اے؟",
                                    "Polish": "Czy potrzebujesz tłumacza?",
                                    "Somali": "Ma u baahan tahay turjumaan?",
                                    "Arabic": "هل تحتاج إلى مترجم؟",
                                    "Pashto": "ایا تاسو ترجمان ته اړتیا لرئ؟",
                                    "Mandarin": "你需要翻译吗？",
                                    "Gujarati": "શું તમને દુભાષિયાની જરૂર છે?"
                                },
                                
                                    "Next": {
                                        "Urdu": "اگلا",
                                        "Punjabi": "ਅਗਲਾ",
                                        "Bengali": "পরবর্তী",
                                        "Pakistani Pahari": "اگلا",
                                        "Polish": "Następny",
                                        "Somali": "Xiga",
                                        "Arabic": "التالي",
                                        "Pashto": "بل",
                                        "Mandarin": "下一个",
                                        "Gujarati": "આગલું"
                                    },
                                    "Back": {
                                        "Urdu": "پیچھے",
                                        "Punjabi": "ਪਿੱਛੇ",
                                        "Bengali": "পিছনে",
                                        "Pakistani Pahari": "پچھے",
                                        "Polish": "Wstecz",
                                        "Somali": "Dib",
                                        "Arabic": "العودة",
                                        "Pashto": "شاته",
                                        "Mandarin": "返回",
                                        "Gujarati": "પાછળ"
                                    },
                                    "We need consent from the person you are filing this complaint on behalf of, we will contact them to confirm this.": {
                                        "Urdu": "ہمیں اس شخص کی رضامندی کی ضرورت ہے جس کی طرف سے آپ یہ شکایت دائر کر رہے ہیں، ہم ان سے اس کی تصدیق کے لیے رابطہ کریں گے۔",
                                        "Punjabi": "ਸਾਨੂੰ ਉਸ ਵਿਅਕਤੀ ਦੀ ਸਹਿਮਤੀ ਦੀ ਲੋੜ ਹੈ ਜਿਸ ਦੀ ਤਰਫੋਂ ਤੁਸੀਂ ਇਹ ਸ਼ਿਕਾਇਤ ਦਰਜ ਕਰ ਰਹੇ ਹੋ, ਅਸੀਂ ਇਸ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਉਨ੍ਹਾਂ ਨਾਲ ਸੰਪਰਕ ਕਰਾਂਗੇ।",
                                        "Bengali": "আমাদের এই অভিযোগ দায়ের করার জন্য আপনি যার পক্ষে কাজ করছেন, তার সম্মতি প্রয়োজন, আমরা তাদের সাথে যোগাযোগ করে এটি নিশ্চিত করব।",
                                        "Pakistani Pahari": "سانوں اوس شخص دی رضامندی دی لوڑ اے جس دے طرفوں تسی اے شکایت دائر کر رہے او، اسی اوہناں نال ایس دی تصدیق لئی رابطہ کراں گے۔",
                                        "Polish": "Potrzebujemy zgody osoby, w imieniu której składasz tę skargę, skontaktujemy się z nią, aby to potwierdzić.",
                                        "Somali": "Waxaan u baahanahay oggolaanshaha qofka aad dacwaddan u gudbineyso, waxaan la xiriiri doonnaa si aan u xaqiijino.",
                                        "Arabic": "نحتاج إلى موافقة الشخص الذي تقدم هذه الشكوى نيابةً عنه، وسنتواصل معهم لتأكيد ذلك.",
                                        "Pashto": "موږ ته د هغه کس د رضايت ضرورت دی چې تاسو په استازیتوب يې دا شکایت کوئ، موږ به ورسره اړیکه ونیسو ترڅو دا تصدیق کړو.",
                                        "Mandarin": "我们需要你代表的那个人的同意，我们将联系他们确认这一点。",
                                        "Gujarati": "તમે જે વ્યક્તિની તરફથી આ ફરિયાદ દાખલ કરી રહ્યા છો તેમની સંમતિની અમને જરૂર છે, અમે તેમનો સંપર્ક કરીને આની પુષ્ટિ કરીશું."
                                    },
                                    "Is the patient aware of this submission?": {
                                        "Urdu": "کیا مریض اس جمع کرانے سے آگاہ ہے؟",
                                        "Punjabi": "ਕੀ ਮਰੀਜ਼ ਇਸ ਜਮਾ ਕਰਨ ਬਾਰੇ ਜਾਣਕਾਰ ਹੈ?",
                                        "Bengali": "রোগী কি এই জমা দেওয়ার বিষয়ে অবগত?",
                                        "Pakistani Pahari": "کیا مریض اس جمع کرانے دے بارے وچ جانکاری رکھدا اے؟",
                                        "Polish": "Czy pacjent jest świadomy tego zgłoszenia?",
                                        "Somali": "Ma bukaanku ka warqabaa gudbintaan?",
                                        "Arabic": "هل المريض على علم بهذا الإرسال؟",
                                        "Pashto": "ایا ناروغ د دې لېږد په اړه خبر دی؟",
                                        "Mandarin": "病人是否知道这个提交？",
                                        "Gujarati": "શું દર્દી આ સબમિશનની જાણ ધરાવે છે?"
                                    },
                                    "Why are you contacting us?": {
                                        "Urdu": "آپ ہم سے کیوں رابطہ کر رہے ہیں؟",
                                        "Punjabi": "ਤੁਸੀਂ ਸਾਨੂੰ ਕਿਉਂ ਸੰਪਰਕ ਕਰ ਰਹੇ ਹੋ?",
                                        "Bengali": "আপনি আমাদের সাথে কেন যোগাযোগ করছেন?",
                                        "Pakistani Pahari": "تسی اسی نال کیوں رابطہ کر رہے او؟",
                                        "Polish": "Dlaczego się z nami kontaktujesz?",
                                        "Somali": "Maxaad noola soo xiriireysaa?",
                                        "Arabic": "لماذا تتواصل معنا؟",
                                        "Pashto": "ولې زموږ سره اړیکه نیسئ؟",
                                        "Mandarin": "你为什么联系我们？",
                                        "Gujarati": "તમે અમને કેમ સંપર્ક કરો છો?"
                                    },
                                    "Which site is this about?": {
                                        "Urdu": "یہ کس سائٹ کے بارے میں ہے؟",
                                        "Punjabi": "ਇਹ ਕਿਸ ਸਾਈਟ ਬਾਰੇ ਹੈ?",
                                        "Bengali": "এটি কোন সাইট সম্পর্কে?",
                                        "Pakistani Pahari": "اے کس سائٹ دے بارے وچ اے؟",
                                        "Polish": "O jaką witrynę chodzi?",
                                        "Somali": "Waa kuwee goobtan?",
                                        "Arabic": "عن أي موقع يتعلق الأمر؟",
                                        "Pashto": "دا د کومې وېبپاڼې په اړه دی؟",
                                        "Mandarin": "这是关于哪个网站的？",
                                        "Gujarati": "આ કઈ સાઇટ વિશે છે?"
                                    },
                                    "Which department(s) is this about?": {
                                        "Urdu": "یہ کس شعبے کے بارے میں ہے؟",
                                        "Punjabi": "ਇਹ ਕਿਹੜੇ ਵਿਭਾਗ(ਾਂ) ਬਾਰੇ ਹੈ?",
                                        "Bengali": "এটি কোন বিভাগ সম্পর্কে?",
                                        "Pakistani Pahari": "اے کس شعبے دے بارے وچ اے؟",
                                        "Polish": "O jaki dział chodzi?",
                                        "Somali": "Waa waaxdee tan?",
                                        "Arabic": "عن أي قسم يتعلق الأمر؟",
                                        "Pashto": "دا د کومې څانگې په اړه دی؟",
                                        "Mandarin": "这是关于哪个部门的？",
                                        "Gujarati": "આ કયા વિભાગ(ઓ) વિશે છે?"
                                    },
                                    "What is your expected outcome?": {
                                        "Urdu": "آپ کا متوقع نتیجہ کیا ہے؟",
                                        "Punjabi": "ਤੁਹਾਡਾ ਉਮੀਦਵਾਰ ਨਤੀਜਾ ਕੀ ਹੈ?",
                                        "Bengali": "আপনার প্রত্যাশিত ফলাফল কী?",
                                        "Pakistani Pahari": "تہاڈا متوقع نتیجہ کی اے؟",
                                        "Polish": "Jakiego wyniku się spodziewasz?",
                                        "Somali": "Waa maxay natiijada aad filaneyso?",
                                        "Arabic": "ما هو النتيجة المتوقعة لديك؟",
                                        "Pashto": "ستاسو توقع شوې پایله څه ده؟",
                                        "Mandarin": "您预期的结果是什么？",
                                        "Gujarati": "તમારી અપેક્ષિત પરિણામ શું છે?"
                                    },
                                    "You can select multiple departments, or add on if it is not listed.": {
                                        "Urdu": "آپ متعدد شعبے منتخب کر سکتے ہیں، یا اگر وہ فہرست میں نہیں ہیں تو شامل کر سکتے ہیں۔",
                                        "Punjabi": "ਤੁਸੀਂ ਕਈ ਵਿਭਾਗ ਚੁਣ ਸਕਦੇ ਹੋ, ਜਾਂ ਜੇਕਰ ਉਹ ਸੂਚੀਬੱਧ ਨਹੀਂ ਹਨ ਤਾਂ ਸ਼ਾਮਲ ਕਰ ਸਕਦੇ ਹੋ।",
                                        "Bengali": "আপনি একাধিক বিভাগ নির্বাচন করতে পারেন, অথবা তা তালিকাভুক্ত না থাকলে যোগ করতে পারেন।",
                                        "Pakistani Pahari": "تسی کئی شعبے چُن سکدے او، جاں جے او فہرست وچ نئیں ہن تے شامل کر سکدے او۔",
                                        "Polish": "Możesz wybrać wiele działów, lub dodać, jeśli nie są wymienione.",
                                        "Somali": "Waxaad dooran kartaa dhowr waaxood, ama ku dari kartaa haddii aan liiska ku jirin.",
                                        "Arabic": "يمكنك اختيار عدة أقسام، أو إضافة إذا لم يتم سردها.",
                                        "Pashto": "تاسو کولی شئ ګڼ شمېر څانگې غوره کړئ، یا که لیست کې نشته نو ورزیات کړئ.",
                                        "Mandarin": "您可以选择多个部门，或者如果未列出则添加。",
                                        "Gujarati": "તમે અનેક વિભાગો પસંદ કરી શકો છો, અથવા જો તે યાદીમાં ન હોય તો ઉમેરી શકો છો."
                                    },

  
    "I want an apology": {
        "Urdu": "میں معافی چاہتا ہوں",
        "Punjabi": "ਮੈਂ ਮਾਫੀ ਚਾਹੁੰਦਾ ਹਾਂ",
        "Bengali": "আমি একটি ক্ষমা চাই",
        "Pakistani Pahari": "میں معافی چاہندا ہاں",
        "Polish": "Chcę przeprosin",
        "Somali": "Waxaan rabaa raalli-gelin",
        "Arabic": "أريد اعتذارًا",
        "Pashto": "زه بښنه غواړم",
        "Mandarin": "我想要一个道歉",
        "Gujarati": "હું માફી માંગુ છું"
    },
    "I want to ensure this is a learning opportunity": {
        "Urdu": "میں یقینی بنانا چاہتا ہوں کہ یہ ایک سیکھنے کا موقع ہے",
        "Punjabi": "ਮੈਂ ਇਹ ਯਕੀਨੀ ਬਣਾਉਣਾ ਚਾਹੁੰਦਾ ਹਾਂ ਕਿ ਇਹ ਇਕ ਸਿਖਲਾਈ ਦਾ ਮੌਕਾ ਹੈ",
        "Bengali": "আমি নিশ্চিত করতে চাই যে এটি একটি শিক্ষা সুযোগ",
        "Pakistani Pahari": "میں یقینی بنانا چاہندا ہاں کہ اے اک سکھنے دا موقع اے",
        "Polish": "Chcę upewnić się, że jest to okazja do nauki",
        "Somali": "Waxaan rabaa inaan hubiyo inay tahay fursad waxbarasho",
        "Arabic": "أريد التأكد من أن هذه فرصة للتعلم",
        "Pashto": "زه غواړم ډاډمن شم چې دا د زده کړې فرصت دی",
        "Mandarin": "我想确保这是一个学习的机会",
        "Gujarati": "હું આ ખાતરી કરવા માંગુ છું કે આ એક શીખવાની તક છે"
    },
    "What is your ethnicity?": {
        "Urdu": "آپ کی نسل کیا ہے؟",
        "Punjabi": "ਤੁਹਾਡੀ ਜਾਤੀਯਤਾ ਕੀ ਹੈ?",
        "Bengali": "আপনার জাতিগত পরিচয় কী?",
        "Pakistani Pahari": "تہاڈی نسل کی اے؟",
        "Polish": "Jakie jest Twoje pochodzenie etniczne?",
        "Somali": "Waa maxay qowmiyaddaada?",
        "Arabic": "ما هي عرقيتك؟",
        "Pashto": "ستاسو د نژاد څه دی؟",
        "Mandarin": "您的种族是什么？",
        "Gujarati": "તમારી જાતિયતા શું છે?"
    },
    "Review": {
        "Urdu": "جائزہ لیں",
        "Punjabi": "ਸਮੀਖਿਆ ਕਰੋ",
        "Bengali": "পর্যালোচনা করুন",
        "Pakistani Pahari": "جائزہ لو",
        "Polish": "Przejrzyj",
        "Somali": "Dib u eeg",
        "Arabic": "مراجعة",
        "Pashto": "کتنه",
        "Mandarin": "审查",
        "Gujarati": "સમીક્ષા કરો"
    },
    "Edit": {
        "Urdu": "ترمیم کریں",
        "Punjabi": "ਸੋਧੋ",
        "Bengali": "সম্পাদনা করুন",
        "Pakistani Pahari": "ترمیم کرو",
        "Polish": "Edytuj",
        "Somali": "Tafatir",
        "Arabic": "تحرير",
        "Pashto": "سمول",
        "Mandarin": "编辑",
        "Gujarati": "ફેરફાર કરો"
    },
    "Submit": {
        "Urdu": "جمع کروائیں",
        "Punjabi": "ਜਮ੍ਹਾਂ ਕਰੋ",
        "Bengali": "জমা দিন",
        "Pakistani Pahari": "جمع کرواؤ",
        "Polish": "Zatwierdź",
        "Somali": "Gudbi",
        "Arabic": "إرسال",
        "Pashto": "وسپارئ",
        "Mandarin": "提交",
        "Gujarati": "સબમિટ કરો"
    },
    "I want to file a comment": {
        "Urdu": "میں تبصرہ جمع کروانا چاہتا ہوں",
        "Punjabi": "ਮੈਂ ਇੱਕ ਟਿੱਪਣੀ ਦਰਜ ਕਰਨਾ ਚਾਹੁੰਦਾ ਹਾਂ",
        "Bengali": "আমি একটি মন্তব্য দায়ের করতে চাই",
        "Pakistani Pahari": "میں تبصرہ جمع کرانا چاہندا ہاں",
        "Polish": "Chcę złożyć komentarz",
        "Somali": "Waxaan rabaa inaan faallo soo gudbiyo",
        "Arabic": "أريد تقديم تعليق",
        "Pashto": "زه غواړم تبصره وکړم",
        "Mandarin": "我想要提交评论",
        "Gujarati": "હું ટિપ્પણી દાખલ કરવા માંગુ છું"
    },
    "I want to file a suggestion": {
        "Urdu": "میں تجویز جمع کروانا چاہتا ہوں",
        "Punjabi": "ਮੈਂ ਇੱਕ ਸੁਝਾਅ ਦਰਜ ਕਰਨਾ ਚਾਹੁੰਦਾ ਹਾਂ",
        "Bengali": "আমি একটি প্রস্তাব দায়ের করতে চাই",
        "Pakistani Pahari": "میں تجویز جمع کرانا چاہندا ہاں",
        "Polish": "Chcę złożyć sugestię",
        "Somali": "Waxaan rabaa inaan talo soo gudbiyo",
        "Arabic": "أريد تقديم اقتراح",
        "Pashto": "زه غواړم وړاندیز وکړم",
        "Mandarin": "我想要提交建议",
        "Gujarati": "હું સૂચન દાખલ કરવા માંગુ છું"
    },
    "Asian British": {
        "Urdu": "ایشیائی برطانوی",
        "Punjabi": "ਏਸ਼ੀਆਈ ਬ੍ਰਿਟਿਸ਼",
        "Bengali": "এশিয়ান ব্রিটিশ",
        "Pakistani Pahari": "ایشیائی برطانوی",
        "Polish": "Brytyjski Azjata",
        "Somali": "British Aasiya",
        "Arabic": "بريطاني آسيوي",
        "Pashto": "آسیایی بریتانوی",
        "Mandarin": "亚洲英国人",
        "Gujarati": "એશિયન બ્રિટિશ"
    },
    "Indian": {
        "Urdu": "بھارتی",
        "Punjabi": "ਭਾਰਤੀ",
        "Bengali": "ভারতীয়",
        "Pakistani Pahari": "بھارتی",
        "Polish": "Indyjski",
        "Somali": "Hindi",
        "Arabic": "هندي",
        "Pashto": "هندي",
        "Mandarin": "印度人",
        "Gujarati": "ભારતીય"
    },
    "Pakistani": {
        "Urdu": "پاکستانی",
        "Punjabi": "ਪਾਕਿਸਤਾਨੀ",
        "Bengali": "পাকিস্তানি",
        "Pakistani Pahari": "پاکستانی",
        "Polish": "Pakistański",
        "Somali": "Baakistaani",
        "Arabic": "باكستاني",
        "Pashto": "پاکستانی",
        "Mandarin": "巴基斯坦人",
        "Gujarati": "પાકિસ્તાની"
    },
    "Bangladeshi": {
        "Urdu": "بنگلہ دیشی",
        "Punjabi": "ਬੰਗਲਾਦੇਸ਼ੀ",
        "Bengali": "বাংলাদেশী",
        "Pakistani Pahari": "بنگلہ دیشی",
        "Polish": "Bangladeski",
        "Somali": "Bangaladheshi",
        "Arabic": "بنغلاديشي",
        "Pashto": "بنګله دېشی",
        "Mandarin": "孟加拉国人",
        "Gujarati": "બાંગ્લાદેશી"
    },
    "Chinese": {
        "Urdu": "چینی",
        "Punjabi": "ਚੀਨੀ",
        "Bengali": "চীনা",
        "Pakistani Pahari": "چینی",
        "Polish": "Chiński",
        "Somali": "Shiine",
        "Arabic": "صيني",
        "Pashto": "چینایی",
        "Mandarin": "中国人",
        "Gujarati": "ચાઇનીઝ"
    },
    "Any other Asian background": {
        "Urdu": "کوئی دیگر ایشیائی پس منظر",
        "Punjabi": "ਕੋਈ ਹੋਰ ਏਸ਼ੀਆਈ ਪਿਛੋਕੜ",
        "Bengali": "অন্য কোনো এশিয়ান পটভূমি",
        "Pakistani Pahari": "کوئی ہور ایشیائی پس منظر",
        "Polish": "Inne azjatyckie pochodzenie",
        "Somali": "Asal Aasiya oo kale",
        "Arabic": "أي خلفية آسيوية أخرى",
        "Pashto": "بل هر آسیایی پس منظر",
        "Mandarin": "任何其他亚洲背景",
        "Gujarati": "કોઈ અન્ય એશિયન પૃષ્ઠભૂમિ"
    },
    "Black": {
        "Urdu": "سیاہ",
        "Punjabi": "ਕਾਲਾ",
        "Bengali": "কালো",
        "Pakistani Pahari": "کالا",
        "Polish": "Czarny",
        "Somali": "Madow",
        "Arabic": "أسود",
        "Pashto": "تور",
        "Mandarin": "黑色",
        "Gujarati": "કાળો"
    },
    "Black British": {
        "Urdu": "سیاہ برطانوی",
        "Punjabi": "ਕਾਲਾ ਬ੍ਰਿਟਿਸ਼",
        "Bengali": "কালো ব্রিটিশ",
        "Pakistani Pahari": "کالا برطانوی",
        "Polish": "Czarny Brytyjczyk",
        "Somali": "Madow British",
        "Arabic": "بريطاني أسود",
        "Pashto": "تور بریتانوی",
        "Mandarin": "黑色英国人",
        "Gujarati": "કાળો બ્રિટિશ"
    },
    "Black Caribbean": {
        "Urdu": "سیاہ کیریبین",
        "Punjabi": "ਕਾਲਾ ਕੈਰੇਬੀਅਨ",
        "Bengali": "কালো ক্যারিবিয়ান",
        "Pakistani Pahari": "کالا کیریبین",
        "Polish": "Czarny Karaibski",
        "Somali": "Madow Caribbean",
        "Arabic": "كاريبي أسود",
        "Pashto": "تور کیریبین",
        "Mandarin": "黑色加勒比人",
        "Gujarati": "કાળો કેરેબિયન"
    },
    "Black African": {
        "Urdu": "سیاہ افریقی",
        "Punjabi": "ਕਾਲਾ ਅਫਰੀਕੀ",
        "Bengali": "কালো আফ্রিকান",
        "Pakistani Pahari": "کالا افریقی",
        "Polish": "Czarny Afrykański",
        "Somali": "Madow Afrikaan",
        "Arabic": "أفريقي أسود",
        "Pashto": "تور افریقایی",
        "Mandarin": "黑色非洲人",
        "Gujarati": "કાળો આફ્રિકન"
    },
    "Any other Black, Black British, or Caribbean background": {
        "Urdu": "کوئی دیگر سیاہ، سیاہ برطانوی، یا کیریبین پس منظر",
        "Punjabi": "ਕੋਈ ਹੋਰ ਕਾਲਾ, ਕਾਲਾ ਬ੍ਰਿਟਿਸ਼, ਜਾਂ ਕੈਰੇਬੀਅਨ ਪਿਛੋਕੜ",
        "Bengali": "অন্য কোনো কালো, কালো ব্রিটিশ, অথবা ক্যারিবিয়ান পটভূমি",
        "Pakistani Pahari": "کوئی ہور کالا، کالا برطانوی، یا کیریبین پس منظر",
        "Polish": "Inne czarne, czarny Brytyjczyk lub karaibskie pochodzenie",
        "Somali": "Asal kale oo Madow, Madow British, ama Caribbean ah",
        "Arabic": "أي خلفية أخرى سوداء، بريطانية سوداء، أو كاريبية",
        "Pashto": "بل هر تور، تور بریتانوی، یا کیریبین پس منظر",
        "Mandarin": "任何其他黑色、黑色英国人或加勒比背景",
        "Gujarati": "કોઈ અન્ય કાળો, કાળો બ્રિટિશ, અથવા કેરેબિયન પૃષ્ઠભૂમિ"
    },
    "White and Black Caribbean": {
        "Urdu": "سفید اور سیاہ کیریبین",
        "Punjabi": "ਚਿੱਟਾ ਅਤੇ ਕਾਲਾ ਕੈਰੇਬੀਅਨ",
        "Bengali": "সাদা ও কালো ক্যারিবিয়ান",
        "Pakistani Pahari": "چٹا تے کالا کیریبین",
        "Polish": "Biały i czarny Karaibski",
        "Somali": "Caddaan iyo Madow Caribbean",
        "Arabic": "أبيض وأسود كاريبي",
        "Pashto": "سپين او تور کیریبین",
        "Mandarin": "白色和黑色加勒比人",
        "Gujarati": "સફેદ અને કાળો કેરેબિયન"
    },
    "White and Black African": {
        "Urdu": "سفید اور سیاہ افریقی",
        "Punjabi": "ਚਿੱਟਾ ਅਤੇ ਕਾਲਾ ਅਫਰੀਕੀ",
        "Bengali": "সাদা ও কালো আফ্রিকান",
        "Pakistani Pahari": "چٹا تے کالا افریقی",
        "Polish": "Biały i czarny Afrykański",
        "Somali": "Caddaan iyo Madow Afrikaan",
        "Arabic": "أبيض وأسود أفريقي",
        "Pashto": "سپين او تور افریقایی",
        "Mandarin": "白色和黑色非洲人",
        "Gujarati": "સફેદ અને કાળો આફ્રિકન"
    },
    "White and Asian": {
        "Urdu": "سفید اور ایشیائی",
        "Punjabi": "ਚਿੱਟਾ ਅਤੇ ਏਸ਼ੀਆਈ",
        "Bengali": "সাদা ও এশিয়ান",
        "Pakistani Pahari": "چٹا تے ایشیائی",
        "Polish": "Biały i azjatycki",
        "Somali": "Caddaan iyo Aasiya",
        "Arabic": "أبيض وآسيوي",
        "Pashto": "سپين او آسیایی",
        "Mandarin": "白色和亚洲人",
        "Gujarati": "સફેદ અને એશિયન"
    },
    "Any other Mixed or multiple ethnic background": {
        "Urdu": "کوئی دیگر مخلوط یا متعدد نسلی پس منظر",
        "Punjabi": "ਕੋਈ ਹੋਰ ਮਿਸ਼ਰਿਤ ਜਾਂ ਬਹੁ-ਨਸਲੀ ਪਿਛੋਕੜ",
        "Bengali": "অন্য কোনো মিশ্র বা বহুজাতিক পটভূমি",
        "Pakistani Pahari": "کوئی ہور ملی جلی یا کئی نسلی پس منظر",
        "Polish": "Inne mieszane lub wielorasowe pochodzenie",
        "Somali": "Asal kale oo Isku-jir ah ama dhowr jinsiyadeed",
        "Arabic": "أي خلفية مختلطة أو متعددة الأعراق",
        "Pashto": "بل هر مخلوط یا څو نسلی پس منظر",
        "Mandarin": "任何其他混合或多种族背景",
        "Gujarati": "કોઈ અન્ય મિશ્ર અથવા બહુ-જાતિય પૃષ્ઠભૂમિ"
    },
    "White English": {
        "Urdu": "سفید انگریز",
        "Punjabi": "ਚਿੱਟਾ ਅੰਗਰੇਜ਼",
        "Bengali": "সাদা ইংরেজ",
        "Pakistani Pahari": "چٹا انگریز",
        "Polish": "Biały Anglik",
        "Somali": "Caddaan Ingiriis",
        "Arabic": "إنجليزي أبيض",
        "Pashto": "سپين انګرېز",
        "Mandarin": "白色英格兰人",
        "Gujarati": "સફેદ અંગ્રેજ"
    },
    "White Welsh": {
        "Urdu": "سفید ویلش",
        "Punjabi": "ਚਿੱਟਾ ਵੈਲਸ਼",
        "Bengali": "সাদা ওয়েলশ",
        "Pakistani Pahari": "چٹا ویلش",
        "Polish": "Biały Walijczyk",
        "Somali": "Caddaan Welsh",
        "Arabic": "ويلزي أبيض",
        "Pashto": "سپين ویلش",
        "Mandarin": "白色威尔士人",
        "Gujarati": "સફેદ વેલ્શ"
    },
    "White Scottish": {
        "Urdu": "سفید اسکاٹش",
        "Punjabi": "ਚਿੱਟਾ ਸਕਾਟਲੈਂਡੀ",
        "Bengali": "সাদা স্কটিশ",
        "Pakistani Pahari": "چٹا اسکاٹش",
        "Polish": "Biały Szkot",
        "Somali": "Caddaan Scottish",
        "Arabic": "اسكتلندي أبيض",
        "Pashto": "سپين سکاټلنډی",
        "Mandarin": "白色苏格兰人",
        "Gujarati": "સફેદ સ્કોટિશ"
    },
    "White Northern Irish": {
        "Urdu": "سفید شمالی آئرش",
        "Punjabi": "ਚਿੱਟਾ ਉੱਤਰੀ ਆਇਰਿਸ਼",
        "Bengali": "সাদা উত্তর আইরিশ",
        "Pakistani Pahari": "چٹا شمالی آئرش",
        "Polish": "Biały Północnoirlandczyk",
        "Somali": "Caddaan Woqooyi Irish",
        "Arabic": "أيرلندي شمالي أبيض",
        "Pashto": "سپين شمالي آيرلېنډی",
        "Mandarin": "白色北爱尔兰人",
        "Gujarati": "સફેદ ઉત્તર આયરિશ"
    },
    "White British": {
        "Urdu": "سفید برطانوی",
        "Punjabi": "ਚਿੱਟਾ ਬ੍ਰਿਟਿਸ਼",
        "Bengali": "সাদা ব্রিটিশ",
        "Pakistani Pahari": "چٹا برطانوی",
        "Polish": "Biały Brytyjczyk",
        "Somali": "Caddaan British",
        "Arabic": "بريطاني أبيض",
        "Pashto": "سپين بریتانوی",
        "Mandarin": "白色英国人",
        "Gujarati": "સફેદ બ્રિટિશ"
    },
    "White Irish": {
        "Urdu": "سفید آئرش",
        "Punjabi": "ਚਿੱਟਾ ਆਇਰਿਸ਼",
        "Bengali": "সাদা আইরিশ",
        "Pakistani Pahari": "چٹا آئرش",
        "Polish": "Biały Irlandczyk",
        "Somali": "Caddaan Irish",
        "Arabic": "أيرلندي أبيض",
        "Pashto": "سپين آيرلېنډی",
        "Mandarin": "白色爱尔兰人",
        "Gujarati": "સફેદ આયરિશ"
    },
    "White Gypsy or Irish Traveller": {
        "Urdu": "سفید جپسی یا آئرش مسافر",
        "Punjabi": "ਚਿੱਟਾ ਜਿਪਸੀ ਜਾਂ ਆਇਰਿਸ਼ ਟ੍ਰੈਵਲਰ",
        "Bengali": "সাদা জিপসি বা আইরিশ ভ্রমণকারী",
        "Pakistani Pahari": "چٹا جپسی یا آئرش مسافر",
        "Polish": "Biały Cygan lub Irlandzki Podróżnik",
        "Somali": "Caddaan Gypsy ama Irish Traveller",
        "Arabic": "غجري أبيض أو مسافر أيرلندي",
        "Pashto": "سپين جپسي یا آیرلنډی سیاح",
        "Mandarin": "白色吉普赛人或爱尔兰旅行者",
        "Gujarati": "સફેદ જિપ્સી અથવા આયરિશ ટ્રાવેલર"
    },
    "White Roma": {
        "Urdu": "سفید روما",
        "Punjabi": "ਚਿੱਟਾ ਰੋਮਾ",
        "Bengali": "সাদা রোমা",
        "Pakistani Pahari": "چٹا روما",
        "Polish": "Biały Rom",
        "Somali": "Caddaan Roma",
        "Arabic": "روما أبيض",
        "Pashto": "سپين روما",
        "Mandarin": "白色罗姆人",
        "Gujarati": "સફેદ રોમા"
    },
    "Any other White background": {
        "Urdu": "کوئی دیگر سفید پس منظر",
        "Punjabi": "ਕੋਈ ਹੋਰ ਚਿੱਟਾ ਪਿਛੋਕੜ",
        "Bengali": "অন্য কোনো সাদা পটভূমি",
        "Pakistani Pahari": "کوئی ہور چٹا پس منظر",
        "Polish": "Inne białe pochodzenie",
        "Somali": "Asal kale oo Caddaan ah",
        "Arabic": "أي خلفية بيضاء أخرى",
        "Pashto": "بل هر سپين پس منظر",
        "Mandarin": "任何其他白色背景",
        "Gujarati": "કોઈ અન્ય સફેદ પૃષ્ઠભૂમિ"
    },
    "Arab": {
        "Urdu": "عرب",
        "Punjabi": "ਅਰਬ",
        "Bengali": "আরব",
        "Pakistani Pahari": "عرب",
        "Polish": "Arab",
        "Somali": "Carab",
        "Arabic": "عربي",
        "Pashto": "عرب",
        "Mandarin": "阿拉伯",
        "Gujarati": "અરબ"
    },
    "Any other": {
        "Urdu": "کوئی دیگر",
        "Punjabi": "ਕੋਈ ਹੋਰ",
        "Bengali": "অন্য কোনো",
        "Pakistani Pahari": "کوئی ہور",
        "Polish": "Inny",
        "Somali": "Wax kale",
        "Arabic": "أي آخر",
        "Pashto": "بل",
        "Mandarin": "其他",
        "Gujarati": "કોઈ અન્ય"
    },
    "Accident and Emergency": {
        "Urdu": "حادثاتی اور ہنگامی",
        "Punjabi": "ਹਾਦਸਾ ਅਤੇ ਐਮਰਜੈਂਸੀ",
        "Bengali": "দুর্ঘটনা এবং জরুরী",
        "Pakistani Pahari": "حادثہ تے ہنگامی",
        "Polish": "Sytuacje awaryjne i wypadki",
        "Somali": "Shil iyo Gurmad",
        "Arabic": "الحوادث والطوارئ",
        "Pashto": "پېښې او اضطراري",
        "Mandarin": "意外与急诊",
        "Gujarati": "અકસ્માત અને કટોકટી"
    },

    "Administration": {
        "Urdu": "انتظامیہ",
        "Punjabi": "ਪਰਸ਼ਾਸਨ",
        "Bengali": "প্রশাসন",
        "Pakistani Pahari": "انتظامیہ",
        "Polish": "Administracja",
        "Somali": "Maamul",
        "Arabic": "الإدارة",
        "Pashto": "اداره",
        "Mandarin": "行政管理",
        "Gujarati": "વહીવટ"
    },
    "Anaesthetics": {
        "Urdu": "بے ہوشی",
        "Punjabi": "ਬੇਹੋਸ਼ੀ",
        "Bengali": "অ্যানেস্থেটিক্স",
        "Pakistani Pahari": "بے ہوشی",
        "Polish": "Anestezjologia",
        "Somali": "Suuxdinta",
        "Arabic": "التخدير",
        "Pashto": "بې هوښۍ",
        "Mandarin": "麻醉学",
        "Gujarati": "એનેસ્થેટિક્સ"
    },
    "Cardiology": {
        "Urdu": "امراض قلب",
        "Punjabi": "ਦਿਲ ਦੇ ਰੋਗ",
        "Bengali": "কার্ডিওলজি",
        "Pakistani Pahari": "امراض قلب",
        "Polish": "Kardiologia",
        "Somali": "Cudurrada Wadnaha",
        "Arabic": "أمراض القلب",
        "Pashto": "زړه ناروغۍ",
        "Mandarin": "心脏病学",
        "Gujarati": "હૃદય રોગ"
    },
    "Chaplaincy": {
        "Urdu": "مذہبی رہنمائی",
        "Punjabi": "ਧਾਰਮਿਕ ਪ੍ਰਬੰਧ",
        "Bengali": "ধর্মীয় পরিচালনা",
        "Pakistani Pahari": "مذہبی رہنمائی",
        "Polish": "Duszpasterstwo",
        "Somali": "Wadaadnimo",
        "Arabic": "الإرشاد الديني",
        "Pashto": "روحاني رهبري",
        "Mandarin": "宗教事务",
        "Gujarati": "ચેપ્લેનસી"
    },
    "Critical Care": {
        "Urdu": "نازک نگہداشت",
        "Punjabi": "ਨਾਜ਼ੁਕ ਦੇਖਭਾਲ",
        "Bengali": "সংকটাপন্ন যত্ন",
        "Pakistani Pahari": "نازک دیکھ بھال",
        "Polish": "Intensywna terapia",
        "Somali": "Daryeelka Muhiimka ah",
        "Arabic": "العناية الحرجة",
        "Pashto": "حساسه پاملرنه",
        "Mandarin": "重症监护",
        "Gujarati": "ગંભીર સંભાળ"
    },
    "Dermatology": {
        "Urdu": "امراض جلد",
        "Punjabi": "ਚਮੜੀ ਦੇ ਰੋਗ",
        "Bengali": "চর্মরোগবিদ্যা",
        "Pakistani Pahari": "امراض جلد",
        "Polish": "Dermatologia",
        "Somali": "Cudurada Maqaarka",
        "Arabic": "الأمراض الجلدية",
        "Pashto": "پوستکي ناروغۍ",
        "Mandarin": "皮肤病学",
        "Gujarati": "ચામડીના રોગ"
    },
    "Diabetes": {
        "Urdu": "ذیابیطس",
        "Punjabi": "ਸ਼ੁਗਰ",
        "Bengali": "ডায়াবেটিস",
        "Pakistani Pahari": "ذیابیطس",
        "Polish": "Cukrzyca",
        "Somali": "Sonkorow",
        "Arabic": "السكري",
        "Pashto": "شکری",
        "Mandarin": "糖尿病",
        "Gujarati": "ડાયાબિટીસ"
    },
    "Ear, Nose and Throat": {
        "Urdu": "کان، ناک اور گلا",
        "Punjabi": "ਕੰਨ, ਨੱਕ ਅਤੇ ਗਲਾ",
        "Bengali": "কান, নাক এবং গলা",
        "Pakistani Pahari": "کان، ناک تے گلہ",
        "Polish": "Laryngologia",
        "Somali": "Dheg, San, iyo Cunno",
        "Arabic": "الأذن والأنف والحنجرة",
        "Pashto": "غوږ، پوزه او ژاړه",
        "Mandarin": "耳鼻喉",
        "Gujarati": "કાન, નાક અને ગળું"
    },
    "Endocrinology": {
        "Urdu": "غدودیات",
        "Punjabi": "ਐਂਡੋਕ੍ਰਾਈਨੋਲੋਜੀ",
        "Bengali": "এন্ডোক্রিনোলজি",
        "Pakistani Pahari": "غدودیات",
        "Polish": "Endokrynologia",
        "Somali": "Cilmiga Hurdada",
        "Arabic": "الغدد الصماء",
        "Pashto": "انډوکراینولوژي",
        "Mandarin": "内分泌学",
        "Gujarati": "એન્ડોક્રિનોલોજી"
    },
    "Gastroenterology": {
        "Urdu": "معدہ کی بیماریاں",
        "Punjabi": "ਗੈਸਟ੍ਰੋਐਂਟਰੋਲੋਜੀ",
        "Bengali": "গ্যাস্ট্রোএন্টেরোলজি",
        "Pakistani Pahari": "معدے دیاں بیماریاں",
        "Polish": "Gastroenterologia",
        "Somali": "Cudurada Caloosha iyo Mindhicirka",
        "Arabic": "أمراض الجهاز الهضمي",
        "Pashto": "د معدې ناروغۍ",
        "Mandarin": "胃肠病学",
        "Gujarati": "ગેસ્ટ્રોએન્ટેરોલોજી"
    },
    "General Surgery": {
        "Urdu": "عمومی سرجری",
        "Punjabi": "ਜਨਰਲ ਸਰਜਰੀ",
        "Bengali": "সাধারণ সার্জারি",
        "Pakistani Pahari": "عمومی سرجری",
        "Polish": "Chirurgia ogólna",
        "Somali": "Qalliinka Guud",
        "Arabic": "الجراحة العامة",
        "Pashto": "عمومي جراحي",
        "Mandarin": "普通外科",
        "Gujarati": "જનરલ સર્જરી"
    },
    "Gynaecology": {
        "Urdu": "امراض نسواں",
        "Punjabi": "ਸਤ੍ਰੀ ਰੋਗ",
        "Bengali": "গাইনোকোলজি",
        "Pakistani Pahari": "امراض نسواں",
        "Polish": "Ginekologia",
        "Somali": "Cudurada Haweenka",
        "Arabic": "أمراض النساء",
        "Pashto": "ښځینه ناروغی",
        "Mandarin": "妇科",
        "Gujarati": "સ્ત્રી રોગ"
    },
    "Haematology": {
        "Urdu": "خون کی بیماریاں",
        "Punjabi": "ਖੂਨ ਦੇ ਰੋਗ",
        "Bengali": "হেমাটোলজি",
        "Pakistani Pahari": "خون دیاں بیماریاں",
        "Polish": "Hematologia",
        "Somali": "Dhiig-karka",
        "Arabic": "أمراض الدم",
        "Pashto": "د وينې ناروغۍ",
        "Mandarin": "血液病学",
        "Gujarati": "રક્તશાસ્ત્ર"
    },
    "Imaging": {
        "Urdu": "تصویر کشی",
        "Punjabi": "ਇਮੇਜਿੰਗ",
        "Bengali": "ইমেজিং",
        "Pakistani Pahari": "تصویر کشی",
        "Polish": "Obrazowanie medyczne",
        "Somali": "Sawir-qaadista",
        "Arabic": "التصوير الطبي",
        "Pashto": "انځورونه",
        "Mandarin": "医学影像",
        "Gujarati": "ઇમેજિંગ"
    },
    "Infection Control": {
        "Urdu": "انفیکشن کنٹرول",
        "Punjabi": "ਸੰਕ੍ਰਮਣ ਨਿਯੰਤਰਣ",
        "Bengali": "সংক্রমণ নিয়ন্ত্রণ",
        "Pakistani Pahari": "انفیکشن کنٹرول",
        "Polish": "Kontrola zakażeń",
        "Somali": "Xakamaynta Infekshanka",
        "Arabic": "مكافحة العدوى",
        "Pashto": "د ناروغۍ کنټرول",
        "Mandarin": "感染控制",
        "Gujarati": "ચેપ નિયંત્રણ"
    },
    "Maternity": {
        "Urdu": "میٹرنٹی",
        "Punjabi": "ਮਾਤ੍ਰਤਾ",
        "Bengali": "মাতৃত্ব",
        "Pakistani Pahari": "میٹرنٹی",
        "Polish": "Położnictwo",
        "Somali": "Hooyada",
        "Arabic": "الأمومة",
        "Pashto": "مورتوب",
        "Mandarin": "产科",
        "Gujarati": "મેટર્નિટી"
    },
    "Microbiology": {
        "Urdu": "جراثیم کی سائنس",
        "Punjabi": "ਸੂਕਸ਼ਮ ਜੀਵ ਵਿਗਿਆਨ",
        "Bengali": "মাইক্রোবায়োলজি",
        "Pakistani Pahari": "جراثیم کی سائنس",
        "Polish": "Mikrobiologia",
        "Somali": "Cilmiga Jeermiska",
        "Arabic": "علم الأحياء الدقيقة",
        "Pashto": "مايکروبيولوژي",
        "Mandarin": "微生物学",
        "Gujarati": "માઇક્રોબાયોલોજી"
    },
    "Neonatology": {
        "Urdu": "نوزائیدہ بچوں کی دیکھ بھال",
        "Punjabi": "ਨਵਜਨਮੇ ਬੱਚਿਆਂ ਦੀ ਦੇਖਭਾਲ",
        "Bengali": "নিওন্যাটোলজি",
        "Pakistani Pahari": "نوزائیدہ بچیاں دی دیکھ بھال",
        "Polish": "Neonatologia",
        "Somali": "Daryeelka Dhallaanka Cusub",
        "Arabic": "طب الأطفال حديثي الولادة",
        "Pashto": "د نوي زیږیدلو ماشومانو درملنه",
        "Mandarin": "新生儿科",
        "Gujarati": "નિયોનેટોલોજી"
    },
    "Nephrology": {
        "Urdu": "گردے کی بیماریاں",
        "Punjabi": "ਗੁਰਦੇ ਦੇ ਰੋਗ",
        "Bengali": "নেফ্রোলজি",
        "Pakistani Pahari": "گردے دیاں بیماریاں",
        "Polish": "Nefrologia",
        "Somali": "Cudurada Kelyaha",
        "Arabic": "أمراض الكلى",
        "Pashto": "د ګرده ناروغۍ",
        "Mandarin": "肾脏病学",
        "Gujarati": "નેફ્રોલોજી"
    },
    "Neurology": {
        "Urdu": "امراض اعصاب",
        "Punjabi": "ਨਰਵਾਂ ਦੇ ਰੋਗ",
        "Bengali": "নিউরোলজি",
        "Pakistani Pahari": "امراض اعصاب",
        "Polish": "Neurologia",
        "Somali": "Cudurada Dareemayaasha",
        "Arabic": "علم الأعصاب",
        "Pashto": "د اعصابو ناروغۍ",
        "Mandarin": "神经病学",
        "Gujarati": "ન્યુરોલોજી"
    },
    "Nutrition and Dietetics": {
        "Urdu": "غذائیت اور خوراک",
        "Punjabi": "ਪੋਸ਼ਣ ਅਤੇ ਡਾਇਟੈਟਿਕਸ",
        "Bengali": "পুষ্টি এবং ডায়েটেটিক্স",
        "Pakistani Pahari": "غذائیت تے خوراک",
        "Polish": "Żywienie i dietetyka",
        "Somali": "Nafaqada iyo Daawada Cuntada",
        "Arabic": "التغذية وعلم الحمية",
        "Pashto": "غذاییت او د خواړو علم",
        "Mandarin": "营养与饮食疗法",
        "Gujarati": "પોષણ અને ડાયટેટિક્સ"
    },
    "Occupational Therapy": {
        "Urdu": "پیشہ ورانہ تھراپی",
        "Punjabi": "ਕਿਰਤੀ ਥੈਰੇਪੀ",
        "Bengali": "কর্মজীবন থেরাপি",
        "Pakistani Pahari": "پیشہ ورانہ تھراپی",
        "Polish": "Terapia zajęciowa",
        "Somali": "Daawaynta Shaqada",
        "Arabic": "العلاج الوظيفي",
        "Pashto": "دنده ایز درملنه",
        "Mandarin": "职业治疗",
        "Gujarati": "ઓક્યુપેશનલ થેરાપી"
    },
    "Oncology": {
        "Urdu": "سرطانیات",
        "Punjabi": "ਕੈਂਸਰ ਦਾ ਇਲਾਜ",
        "Bengali": "অনকোলজি",
        "Pakistani Pahari": "سرطانیات",
        "Polish": "Onkologia",
        "Somali": "Daawaynta Kansarka",
        "Arabic": "علم الأورام",
        "Pashto": "د سرطان علم",
        "Mandarin": "肿瘤学",
        "Gujarati": "ઓન્કોલોજી"
    },
    "Ophthalmology": {
        "Urdu": "امراض چشم",
        "Punjabi": "ਅੱਖਾਂ ਦੇ ਰੋਗ",
        "Bengali": "চক্ষুরোগবিদ্যা",
        "Pakistani Pahari": "امراض چشم",
        "Polish": "Okulistyka",
        "Somali": "Cudurada Indhaha",
        "Arabic": "طب العيون",
        "Pashto": "سترګو ناروغۍ",
        "Mandarin": "眼科",
        "Gujarati": "નેત્રવિજ્ઞાન"
    },
    "Orthopaedics": {
        "Urdu": "ہڈیوں کی بیماریاں",
        "Punjabi": "ਹੱਡੀਆਂ ਦੇ ਰੋਗ",
        "Bengali": "অর্থোপেডিক্স",
        "Pakistani Pahari": "ہڈیاں دیاں بیماریاں",
        "Polish": "Ortopedia",
        "Somali": "Cudurada Lafaha",
        "Arabic": "جراحة العظام",
        "Pashto": "د هډوکو ناروغۍ",
        "Mandarin": "骨科",
        "Gujarati": "ઓર્થોપેડિક્સ"
    },
    "Pain Management": {
        "Urdu": "درد کا انتظام",
        "Punjabi": "ਦਰਦ ਪ੍ਰਬੰਧਨ",
        "Bengali": "ব্যথা ব্যবস্থাপনা",
        "Pakistani Pahari": "درد دا انتظام",
        "Polish": "Leczenie bólu",
        "Somali": "Maareynta Xanuunka",
        "Arabic": "إدارة الألم",
        "Pashto": "د درد مدیریت",
        "Mandarin": "疼痛管理",
        "Gujarati": "પીડા મેનેજમેન્ટ"
    },
    "Pathology": {
        "Urdu": "پیتھولوجی",
        "Punjabi": "ਬਿਮਾਰੀ ਵਿਗਿਆਨ",
        "Bengali": "প্যাথোলজি",
        "Pakistani Pahari": "پیتھولوجی",
        "Polish": "Patologia",
        "Somali": "Cudurrada",
        "Arabic": "علم الأمراض",
        "Pashto": "پاتولوژي",
        "Mandarin": "病理学",
        "Gujarati": "પેથોલોજી"
    },
    "Pharmacy": {
        "Urdu": "فارمیسی",
        "Punjabi": "ਫਾਰਮੇਸੀ",
        "Bengali": "ফার্মেসি",
        "Pakistani Pahari": "فارمیسی",
        "Polish": "Apteka",
        "Somali": "Farmashiye",
        "Arabic": "الصيدلية",
        "Pashto": "دواخانه",
        "Mandarin": "药房",
        "Gujarati": "ફાર્મસી"
    },
    "Physiotherapy": {
        "Urdu": "فزیوتھراپی",
        "Punjabi": "ਫਿਜ਼ੀਓਥੈਰੇਪੀ",
        "Bengali": "ফিজিওথেরাপি",
        "Pakistani Pahari": "فزیوتھراپی",
        "Polish": "Fizjoterapia",
        "Somali": "Daawaynta Jirka",
        "Arabic": "العلاج الطبيعي",
        "Pashto": "فزيوتراپي",
        "Mandarin": "物理治疗",
        "Gujarati": "ફિઝિયોથેરાપી"
    },
    "Plastic Surgery": {
        "Urdu": "پلاسٹک سرجری",
        "Punjabi": "ਪਲਾਸਟਿਕ ਸਰਜਰੀ",
        "Bengali": "প্লাস্টিক সার্জারি",
        "Pakistani Pahari": "پلاسٹک سرجری",
        "Polish": "Chirurgia plastyczna",
        "Somali": "Qalliinka Caaga",
        "Arabic": "جراحة التجميل",
        "Pashto": "پلاستيکي جراحي",
        "Mandarin": "整形外科",
        "Gujarati": "પ્લાસ્ટિક સર્જરી"
    },
    "Radiology": {
        "Urdu": "ریڈیولوجی",
        "Punjabi": "ਰੇਡੀਓਲੋਜੀ",
        "Bengali": "রেডিওলজি",
        "Pakistani Pahari": "ریڈیولوجی",
        "Polish": "Radiologia",
        "Somali": "Raajiyoolaji",
        "Arabic": "الأشعة",
        "Pashto": "راډيولوژي",
        "Mandarin": "放射科",
        "Gujarati": "રેડિયોલોજી"
    },
    "Radiotherapy": {
        "Urdu": "ریڈیو تھراپی",
        "Punjabi": "ਰੇਡੀਓਥੈਰੇਪੀ",
        "Bengali": "রেডিওথেরাপি",
        "Pakistani Pahari": "ریڈیو تھراپی",
        "Polish": "Radioterapia",
        "Somali": "Daaweynta Shucaaca",
        "Arabic": "العلاج الإشعاعي",
        "Pashto": "راډیوتراپي",
        "Mandarin": "放射治疗",
        "Gujarati": "રેડિયોથેરાપી"
    },
    "Renal": {
        "Urdu": "گردے",
        "Punjabi": "ਗੁਰਦੇ",
        "Bengali": "রিনাল",
        "Pakistani Pahari": "گردے",
        "Polish": "Nerkowe",
        "Somali": "Kalyaha",
        "Arabic": "الكلى",
        "Pashto": "ګرده",
        "Mandarin": "肾脏",
        "Gujarati": "મૂત્રાશય"
    },
    "Respiratory": {
        "Urdu": "تنفسی",
        "Punjabi": "ਸਾਹ",
        "Bengali": "শ্বাসকষ্ট",
        "Pakistani Pahari": "تنفسی",
        "Polish": "Oddechowe",
        "Somali": "Neefta",
        "Arabic": "التنفسية",
        "Pashto": "تنفس",
        "Mandarin": "呼吸",
        "Gujarati": "શ્વસન"
    },
    "Rheumatology": {
        "Urdu": "رومیٹولوجی",
        "Punjabi": "ਰਿਊਮੇਟੋਲੋਜੀ",
        "Bengali": "রিউম্যাটোলজি",
        "Pakistani Pahari": "رومیٹولوجی",
        "Polish": "Reumatologia",
        "Somali": "Cudurada Lafaha iyo Murqaha",
        "Arabic": "أمراض الروماتيزم",
        "Pashto": "روماتولوژي",
        "Mandarin": "风湿病学",
        "Gujarati": "ર્યુમેટોલોજી"
    },
    "Speech and Language Therapy": {
        "Urdu": "تقریر اور زبان کی تھراپی",
        "Punjabi": "ਬੋਲੀ ਅਤੇ ਭਾਸ਼ਾ ਥੈਰੇਪੀ",
        "Bengali": "বাক ও ভাষা চিকিৎসা",
        "Pakistani Pahari": "تقریر تے زبان دی تھراپی",
        "Polish": "Terapia mowy i języka",
        "Somali": "Daawaynta Hadalka iyo Luuqadda",
        "Arabic": "العلاج النطقي واللغوي",
        "Pashto": "د خبرو او ژبې درملنه",
        "Mandarin": "言语和语言治疗",
        "Gujarati": "ભાષણ અને ભાષા થેરાપી"
    },
    "Urology": {
        "Urdu": "پیشاب کی بیماریاں",
        "Punjabi": "ਮੂਤਰ ਵਿਭਾਗ",
        "Bengali": "ইউরোলজি",
        "Pakistani Pahari": "پیشاب دیاں بیماریاں",
        "Polish": "Urologia",
        "Somali": "Qaliinka Kaadi Mareenka",
        "Arabic": "أمراض المسالك البولية",
        "Pashto": "د پیشاب ناروغۍ",
        "Mandarin": "泌尿科",
        "Gujarati": "યુરોલોજી"
    },
    "Vascular Surgery": {
        "Urdu": "رگوں کی سرجری",
        "Punjabi": "ਨਸਾਂ ਦੀ ਸਰਜਰੀ",
        "Bengali": "ভাস্কুলার সার্জারি",
        "Pakistani Pahari": "رگاں دی سرجری",
        "Polish": "Chirurgia naczyniowa",
        "Somali": "Qaliinka Xididdada Dhiigga",
        "Arabic": "جراحة الأوعية الدموية",
        "Pashto": "د وینې لارې جراحي",
        "Mandarin": "血管外科",
        "Gujarati": "વાસ્ક્યુલર સર્જરી"
    },
    "Birmingham Women's Hospital": {
        "Urdu": "برمنگھم خواتین کا ہسپتال",
        "Punjabi": "ਬਰਮਿੰਘਮ ਔਰਤਾਂ ਦਾ ਹਸਪਤਾਲ",
        "Bengali": "বার্মিংঘাম মহিলা হাসপাতাল",
        "Pakistani Pahari": "برمنگھم عورتاں دا ہسپتال",
        "Polish": "Szpital dla Kobiet w Birmingham",
        "Somali": "Isbitaalka Haweenka Birmingham",
        "Arabic": "مستشفى برمنغهام للنساء",
        "Pashto": "د برمنګهام ښځینه روغتون",
        "Mandarin": "伯明翰妇女医院",
        "Gujarati": "બર્મિંગહામ મહિલા હોસ્પિટલ"
    },
    "Birmingham Children's Hospital": {
        "Urdu": "برمنگھم بچوں کا ہسپتال",
        "Punjabi": "ਬਰਮਿੰਘਮ ਬੱਚਿਆਂ ਦਾ ਹਸਪਤਾਲ",
        "Bengali": "বার্মিংহাম শিশু হাসপাতাল",
        "Pakistani Pahari": "برمنگھم بچیاں دا ہسپتال",
        "Polish": "Szpital Dziecięcy w Birmingham",
        "Somali": "Isbitaalka Carruurta Birmingham",
        "Arabic": "مستشفى برمنغهام للأطفال",
        "Pashto": "د برمنګهام ماشومانو روغتون",
        "Mandarin": "伯明翰儿童医院",
        "Gujarati": "બર્મિંગહામ બાળકોની હોસ્પિટલ"
    },
    "Other": {
        "Urdu": "دیگر",
        "Punjabi": "ਹੋਰ",
        "Bengali": "অন্যান্য",
        "Pakistani Pahari": "ہور",
        "Polish": "Inne",
        "Somali": "Kale",
        "Arabic": "آخر",
        "Pashto": "نور",
        "Mandarin": "其他",
        "Gujarati": "અન્ય"
    },
    "Mental Health Services": {
        "Urdu": "ذہنی صحت کی خدمات",
        "Punjabi": "ਮਾਨਸਿਕ ਸਿਹਤ ਸੇਵਾਵਾਂ",
        "Bengali": "মানসিক স্বাস্থ্য পরিষেবা",
        "Pakistani Pahari": "ذہنی صحت دی خدمات",
        "Polish": "Usługi zdrowia psychicznego",
        "Somali": "Adeegyada Caafimaadka Dhimirka",
        "Arabic": "خدمات الصحة العقلية",
        "Pashto": "د رواني روغتيا خدمات",
        "Mandarin": "心理健康服务",
        "Gujarati": "માનસિક આરોગ્ય સેવાઓ"
    },
    "Step 1 of": {
        "Urdu": "مرحلہ 1 کا",
        "Punjabi": "ਕਦਮ 1 ਦਾ",
        "Bengali": "ধাপ 1 এর",
        "Pakistani Pahari": "مرحلہ 1 دا",
        "Polish": "Krok 1 z",
        "Somali": "Tallaabada 1aad ee",
        "Arabic": "الخطوة 1 من",
        "Pashto": "مرحله 1 د",
        "Mandarin": "第1步",
        "Gujarati": "પગલું 1 નું"
    },
    "Step 2 of": {
        "Urdu": "مرحلہ 2 کا",
        "Punjabi": "ਕਦਮ 2 ਦਾ",
        "Bengali": "ধাপ 2 এর",
        "Pakistani Pahari": "مرحلہ 2 دا",
        "Polish": "Krok 2 z",
        "Somali": "Tallaabada 2aad ee",
        "Arabic": "الخطوة 2 من",
        "Pashto": "مرحله 2 د",
        "Mandarin": "第2步",
        "Gujarati": "પગલું 2 નું"
    },
    "Step 3 of": {
        "Urdu": "مرحلہ 3 کا",
        "Punjabi": "ਕਦਮ 3 ਦਾ",
        "Bengali": "ধাপ 3 এর",
        "Pakistani Pahari": "مرحلہ 3 دا",
        "Polish": "Krok 3 z",
        "Somali": "Tallaabada 3aad ee",
        "Arabic": "الخطوة 3 من",
        "Pashto": "مرحله 3 د",
        "Mandarin": "第3步",
        "Gujarati": "પગલું 3 નું"
    },
    "Step 4 of": {
        "Urdu": "مرحلہ 4 کا",
        "Punjabi": "ਕਦਮ 4 ਦਾ",
        "Bengali": "ধাপ 4 এর",
        "Pakistani Pahari": "مرحلہ 4 دا",
        "Polish": "Krok 4 z",
        "Somali": "Tallaabada 4aad ee",
        "Arabic": "الخطوة 4 من",
        "Pashto": "مرحله 4 د",
        "Mandarin": "第4步",
        "Gujarati": "પગલું 4 નું"
    },
    "Step 5 of": {
        "Urdu": "مرحلہ 5 کا",
        "Punjabi": "ਕਦਮ 5 ਦਾ",
        "Bengali": "ধাপ 5 এর",
        "Pakistani Pahari": "مرحلہ 5 دا",
        "Polish": "Krok 5 z",
        "Somali": "Tallaabada 5aad ee",
        "Arabic": "الخطوة 5 من",
        "Pashto": "مرحله 5 د",
        "Mandarin": "第5步",
        "Gujarati": "પગલું 5 નું"
    },
    "Step 6 of": {
        "Urdu": "مرحلہ 6 کا",
        "Punjabi": "ਕਦਮ 6 ਦਾ",
        "Bengali": "ধাপ 6 এর",
        "Pakistani Pahari": "مرحلہ 6 دا",
        "Polish": "Krok 6 z",
        "Somali": "Tallaabada 6aad ee",
        "Arabic": "الخطوة 6 من",
        "Pashto": "مرحله 6 د",
        "Mandarin": "第6步",
        "Gujarati": "પગલું 6 નું"
    },
    "Step 7 of": {
        "Urdu": "مرحلہ 7 کا",
        "Punjabi": "ਕਦਮ 7 ਦਾ",
        "Bengali": "ধাপ 7 এর",
        "Pakistani Pahari": "مرحلہ 7 دا",
        "Polish": "Krok 7 z",
        "Somali": "Tallaabada 7aad ee",
        "Arabic": "الخطوة 7 من",
        "Pashto": "مرحله 7 د",
        "Mandarin": "第7步",
        "Gujarati": "પગલું 7 નું"
    },
    "Please review your answers before submitting.": {
        "Urdu": "براہ کرم جمع کرانے سے پہلے اپنے جوابات کا جائزہ لیں۔",
        "Punjabi": "ਕ੍ਰਿਪਾ ਕਰਕੇ ਜਮ੍ਹਾਂ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ ਆਪਣੇ ਜਵਾਬਾਂ ਦੀ ਸਮੀਖਿਆ ਕਰੋ।",
        "Bengali": "দয়া করে জমা দেওয়ার আগে আপনার উত্তরগুলি পর্যালোচনা করুন।",
        "Pakistani Pahari": "براہ کرم جمع کرانے توں پہلے آپݨے جوابات دا جائزہ لو۔",
        "Polish": "Przed wysłaniem proszę przejrzeć swoje odpowiedzi.",
        "Somali": "Fadlan dib u eeg jawaabahaaga ka hor intaadan gudbin.",
        "Arabic": "يرجى مراجعة إجاباتك قبل التقديم.",
        "Pashto": "مهرباني وکړئ له ورکړې دمخه خپل ځوابونه وګورئ.",
        "Mandarin": "请在提交前审阅您的答案。",
        "Gujarati": "કૃપા કરીને સબમિટ કરતાં પહેલાં તમારા જવાબોની સમીક્ષા કરો."
    },
    "Date of Birth": {
        "Urdu": "پیدائش کی تاریخ",
        "Punjabi": "ਜਨਮ ਮਿਤੀ",
        "Bengali": "জন্ম তারিখ",
        "Pakistani Pahari": "پیدائش دی تریخ",
        "Polish": "Data urodzenia",
        "Somali": "Taariikhda Dhalashada",
        "Arabic": "تاريخ الميلاد",
        "Pashto": "د زیږون نیټه",
        "Mandarin": "出生日期",
        "Gujarati": "જન્મ તારીખ"
    },
    "Are you filing this complaint on behalf of someone else?": {
        "Urdu": "کیا آپ یہ شکایت کسی اور کی جانب سے دائر کر رہے ہیں؟",
        "Punjabi": "ਕੀ ਤੁਸੀਂ ਇਹ ਸ਼ਿਕਾਇਤ ਕਿਸੇ ਹੋਰ ਦੀ ਤਰਫੋਂ ਦਰਜ ਕਰ ਰਹੇ ਹੋ?",
        "Bengali": "আপনি কি অন্য কারো পক্ষ থেকে এই অভিযোগ দায়ের করছেন?",
        "Pakistani Pahari": "کیا تسی ایہ شکایت کسے ہور دی طرفوں دائر کر رہے او؟",
        "Polish": "Czy składasz tę skargę w imieniu kogoś innego?",
        "Somali": "Ma adiga oo matalaya qof kale ayaad dacwadan gudbinaysaa?",
        "Arabic": "هل تقدم هذه الشكوى نيابة عن شخص آخر؟",
        "Pashto": "آیا تاسو د بل چا په استازیتوب دا شکایت کوئ؟",
        "Mandarin": "您是否代表他人提交此投诉？",
        "Gujarati": "શું તમે બીજા કોઈની વતી આ ફરિયાદ કરી રહ્યા છો?"
    },
    "Service User Firstname": {
        "Urdu": "خدمت استعمال کنندہ کا پہلا نام",
        "Punjabi": "ਸੇਵਾ ਵਰਤੋਂਕਾਰ ਦਾ ਪਹਿਲਾ ਨਾਮ",
        "Bengali": "সেবা ব্যবহারকারীর প্রথম নাম",
        "Pakistani Pahari": "خدمت استعمال کنندہ دا پہلا ناں",
        "Polish": "Imię użytkownika usługi",
        "Somali": "Magaca Koowaad ee Adeegsade",
        "Arabic": "الاسم الأول لمستخدم الخدمة",
        "Pashto": "د خدمت کارونکي لومړی نوم",
        "Mandarin": "服务用户的名字",
        "Gujarati": "સર્વિસ યુઝરનું પ્રથમ નામ"
    },
    "Service User Lastname": {
        "Urdu": "خدمت استعمال کنندہ کا آخری نام",
        "Punjabi": "ਸੇਵਾ ਵਰਤੋਂਕਾਰ ਦਾ ਆਖਰੀ ਨਾਮ",
        "Bengali": "সেবা ব্যবহারকারীর শেষ নাম",
        "Pakistani Pahari": "خدمت استعمال کنندہ دا آخری ناں",
        "Polish": "Nazwisko użytkownika usługi",
        "Somali": "Magaca Dambe ee Adeegsade",
        "Arabic": "اسم العائلة لمستخدم الخدمة",
        "Pashto": "د خدمت کارونکي وروستۍ نوم",
        "Mandarin": "服务用户的姓氏",
        "Gujarati": "સર્વિસ યુઝરનું છેલ્લું નામ"
    },
    "Service User Email": {
        "Urdu": "خدمت استعمال کنندہ کا ای میل",
        "Punjabi": "ਸੇਵਾ ਵਰਤੋਂਕਾਰ ਦਾ ਈਮੇਲ",
        "Bengali": "সেবা ব্যবহারকারীর ইমেইল",
        "Pakistani Pahari": "خدمت استعمال کنندہ دا ای میل",
        "Polish": "E-mail użytkownika usługi",
        "Somali": "Emailka Adeegsade",
        "Arabic": "البريد الإلكتروني لمستخدم الخدمة",
        "Pashto": "د خدمت کارونکي برېښنالیک",
        "Mandarin": "服务用户的电子邮件",
        "Gujarati": "સર્વિસ યુઝરનું ઇમેઇલ"
    },
    "Service User Phone": {
        "Urdu": "خدمت استعمال کنندہ کا فون",
        "Punjabi": "ਸੇਵਾ ਵਰਤੋਂਕਾਰ ਦਾ ਫੋਨ",
        "Bengali": "সেবা ব্যবহারকারীর ফোন",
        "Pakistani Pahari": "خدمت استعمال کنندہ دا فون",
        "Polish": "Telefon użytkownika usługi",
        "Somali": "Telefoonka Adeegsade",
        "Arabic": "هاتف مستخدم الخدمة",
        "Pashto": "د خدمت کارونکي ټیلیفون",
        "Mandarin": "服务用户的电话",
        "Gujarati": "સર્વિસ યુઝરનો ફોન"
    },
    "Service User is Aware": {
        "Urdu": "خدمت استعمال کنندہ آگاہ ہے",
        "Punjabi": "ਸੇਵਾ ਵਰਤੋਂਕਾਰ ਨੂੰ ਪਤਾ ਹੈ",
        "Bengali": "সেবা ব্যবহারকারী সচেতন",
        "Pakistani Pahari": "خدمت استعمال کنندہ آگاہ اے",
        "Polish": "Użytkownik usługi jest świadomy",
        "Somali": "Adeegsaduhu wuu ka warqabaa",
        "Arabic": "مستخدم الخدمة على علم",
        "Pashto": "د خدمت کارونکی خبر دی",
        "Mandarin": "服务用户已知晓",
        "Gujarati": "સર્વિસ યુઝર જાણકાર છે"
    },
    "Type of Feedback": {
        "Urdu": "رائے کی قسم",
        "Punjabi": "ਫੀਡਬੈਕ ਦੀ ਕਿਸਮ",
        "Bengali": "প্রতিক্রিয়ার ধরণ",
        "Pakistani Pahari": "رائے دی قسم",
        "Polish": "Rodzaj opinii",
        "Somali": "Nooca Jawaab-celinta",
        "Arabic": "نوع الملاحظات",
        "Pashto": "د نظر ډول",
        "Mandarin": "反馈类型",
        "Gujarati": "પ્રતિસાદનો પ્રકાર"
    },
    "Expected Outcome": {
        "Urdu": "متوقع نتیجہ",
        "Punjabi": "ਉਮੀਦਵਾਰ ਨਤੀਜਾ",
        "Bengali": "প্রত্যাশিত ফলাফল",
        "Pakistani Pahari": "متوقع نتیجہ",
        "Polish": "Oczekiwany wynik",
        "Somali": "Natiijada la Filayo",
        "Arabic": "النتيجة المتوقعة",
        "Pashto": "تمه شوې پایله",
        "Mandarin": "预期结果",
        "Gujarati": "અપેક્ષિત પરિણામ"
    },
    "Interpreter Required": {
        "Urdu": "ترجمان کی ضرورت ہے",
        "Punjabi": "ਅਨੁਵਾਦਕ ਦੀ ਲੋੜ ਹੈ",
        "Bengali": "দোভাষী প্রয়োজন",
        "Pakistani Pahari": "ترجمان دی ضرورت اے",
        "Polish": "Wymagany tłumacz",
        "Somali": "Turjumaan ayaa loo baahan yahay",
        "Arabic": "مطلوب مترجم",
        "Pashto": "ژباړونکی ته اړتیا ده",
        "Mandarin": "需要口译员",
        "Gujarati": "દુભાષિયાની જરૂર છે"
    },
    "Location": {
        "Urdu": "مقام",
        "Punjabi": "ਸਥਾਨ",
        "Bengali": "অবস্থান",
        "Pakistani Pahari": "جگہ",
        "Polish": "Lokalizacja",
        "Somali": "Goobta",
        "Arabic": "الموقع",
        "Pashto": "ځای",
        "Mandarin": "位置",
        "Gujarati": "સ્થાન"
    },
    "Team": {
        "Urdu": "ٹیم",
        "Punjabi": "ਟੀਮ",
        "Bengali": "দল",
        "Pakistani Pahari": "ٹیم",
        "Polish": "Zespół",
        "Somali": "Kooxda",
        "Arabic": "الفريق",
        "Pashto": "ټیم",
        "Mandarin": "团队",
        "Gujarati": "ટીમ"
    },
    "Body": {
        "Urdu": "متن",
        "Punjabi": "ਲਿਖਤ",
        "Bengali": "পাঠ্য",
        "Pakistani Pahari": "متن",
        "Polish": "Treść",
        "Somali": "Qoraalka",
        "Arabic": "نص",
        "Pashto": "متن",
        "Mandarin": "文本",
        "Gujarati": "માળખું"
    },
    "Disability": {
        "Urdu": "معذوری",
        "Punjabi": "ਅਯੋਗਤਾ",
        "Bengali": "অক্ষমতা",
        "Pakistani Pahari": "معذوری",
        "Polish": "Niepełnosprawność",
        "Somali": "Naafonimo",
        "Arabic": "إعاقة",
        "Pashto": "معلولیت",
        "Mandarin": "残疾",
        "Gujarati": "અક્ષમતા"
    },

    "Language": {
        "Urdu": "زبان",
        "Punjabi": "ਭਾਸ਼ਾ",
        "Bengali": "ভাষা",
        "Pakistani Pahari": "زبان",
        "Polish": "Język",
        "Somali": "Luqadda",
        "Arabic": "اللغة",
        "Pashto": "ژبه",
        "Mandarin": "语言",
        "Gujarati": "ભાષા"
    },
    "Urdu": {
        "Urdu": "اردو"
    },
    "Punjabi": {
        "Punjabi": "ਪੰਜਾਬੀ"
    },
    "Bengali": {
        "Bengali": "বাংলা"
    },
    "Pakistani Pahari": {
        "Pakistani Pahari": "پاکستانی پہاڑی"
    },
    "Polish": {
        "Polish": "Polski"
    },
    "Somali": {
        "Somali": "Soomaali"
    },
    "Arabic": {
        "Arabic": "العربية"
    },
    "Pashto": {
        "Pashto": "پښتو"
    },
    "Mandarin": {
        "Mandarin": "普通话"
    },
    "Gujarati": {
        "Gujarati": "ગુજરાતી"
    },
    "Compliment": {
        "Urdu": "تعریف",
        "Punjabi": "ਸ਼ਲਾਘਾ",
        "Bengali": "প্রশংসা",
        "Pakistani Pahari": "تعریف",
        "Polish": "Komplement",
        "Somali": "Ammaan",
        "Arabic": "مجاملة",
        "Pashto": "ستاینه",
        "Mandarin": "赞美",
        "Gujarati": "પ્રશંસા"
    },
    "Investigation": {
        "Urdu": "تحقیقات",
        "Punjabi": "ਜਾਂਚ",
        "Bengali": "তদন্ত",
        "Pakistani Pahari": "تحقیقات",
        "Polish": "Śledztwo",
        "Somali": "Baaritaan",
        "Arabic": "تحقيق",
        "Pashto": "تحقیق",
        "Mandarin": "调查",
        "Gujarati": "તપાસ"
    },
    "Learning": {
        "Urdu": "سیکھنا",
        "Punjabi": "ਸਿੱਖਿਆ",
        "Bengali": "শেখা",
        "Pakistani Pahari": "سیکھنا",
        "Polish": "Uczenie się",
        "Somali": "Barashada",
        "Arabic": "تعلم",
        "Pashto": "زده کړه",
        "Mandarin": "学习",
        "Gujarati": "શીખવાનું"
    },
    "Investigations": {
        "Urdu": "تحقیقات",
        "Punjabi": "ਜਾਂਚਾਂ",
        "Bengali": "তদন্তসমূহ",
        "Pakistani Pahari": "تحقیقات",
        "Polish": "Badania",
        "Somali": "Baaritaano",
        "Arabic": "تحقيقات",
        "Pashto": "تحقیقات",
        "Mandarin": "调查",
        "Gujarati": "તપાસો"
    },
    "Explanation": {
        "Urdu": "وضاحت",
        "Punjabi": "ਵਿਆਖਿਆ",
        "Bengali": "ব্যাখ্যা",
        "Pakistani Pahari": "وضاحت",
        "Polish": "Wyjaśnienie",
        "Somali": "Sharaxaad",
        "Arabic": "توضيح",
        "Pashto": "څرګندونه",
        "Mandarin": "解释",
        "Gujarati": "સ્પષ્ટીકરણ"
    },
    "Other": {
        "Urdu": "دیگر",
        "Punjabi": "ਹੋਰ",
        "Bengali": "অন্যান্য",
        "Pakistani Pahari": "ہور",
        "Polish": "Inne",
        "Somali": "Kale",
        "Arabic": "آخر",
        "Pashto": "نور",
        "Mandarin": "其他",
        "Gujarati": "અન્ય"
    },
    "None": {
        "Urdu": "کوئی نہیں",
        "Punjabi": "ਕੋਈ ਨਹੀਂ",
        "Bengali": "কোনোটিই না",
        "Pakistani Pahari": "کوئی نہیں",
        "Polish": "Żaden",
        "Somali": "Midna",
        "Arabic": "لا شيء",
        "Pashto": "هیڅ",
        "Mandarin": "无",
        "Gujarati": "કોઈ નહીં"
    },
    "Ethnicity": {
        "Urdu": "نسلیت",
        "Punjabi": "ਜਾਤੀਯਤਾ",
        "Bengali": "জাতিতাত্ত্বিকতা",
        "Pakistani Pahari": "نسلیت",
        "Polish": "Etniczność",
        "Somali": "Qowmiyadda",
        "Arabic": "الإثنية",
        "Pashto": "قومیت",
        "Mandarin": "民族性",
        "Gujarati": "જાતિયતા"
    }




}


export default translationsAll; 