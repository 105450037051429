import React, { useState, useEffect, useContext } from 'react';
import {SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import WorkSchedule from '../prospectiveleave/WorkSchedule';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';

import {Header, Row, Cell} from '../../core/components/lists/List';


export default function Calendar(props) {

    console.log("Loading iCal Schedules");

    const [schedules, setSchedules] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAdmin , setShowAdmin] = useState(false);
    const navigate = useNavigate();
    const [refreshing, setRefreshing] = useState(false);
    

    const subscriptionData = useContext(SubscriptionContext);
    //console.log("SubscriptionProtectedRoute", subscriptionData);
   // console.log("SubscriptionProtectedRoute Context", subscription);
    if (subscriptionData !== null && subscriptionData !== undefined && showAdmin === false) {
        //console.log("SubscriptionContext in Schedules", subscriptionData);
        // Loop through subscription data
        subscriptionData.subscriptionIds.forEach((subscription, index) => {

            if (subscription === 'admin') {
                //console.log("Admin subscription found");
                setShowAdmin(true);
            }

        });
    }


    function updateList() {

        
        let url = 'schedules/all';
        let config = {
            params: {
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log(response.data);
            // Sort schedules by .lastAnalysed
            let sorted = response.data.schedules.sort(function(a, b) {
                return (a.lastAnalysed < b.lastAnalysed) ? 1 : ((b.lastAnalysed < a.lastAnalysed) ? -1 : 0);
            });

            // Loop through each and check if more than 3 minutes old and no ai analysis available
            let analysing = false;
            sorted.forEach((schedule, index) => {
                if (schedule.lastAnalysed === null && moment(schedule.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isAfter(moment().subtract(3, 'minutes'))) {
                    analysing = true;
                }
            });

            if (analysing) {
                // Set timeout to update list
                setRefreshing(true);
                setTimeout(() => {
                    updateList();
                }, 10000);
            } else {
                setRefreshing(false);
            }


            setSchedules(sorted);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
        }

        );

    }
    useEffect(() => {

        updateList();

    }, []);

    function viewSchedule(uuid) {
        //console.log("View Schedule", uuid);
        navigate('/pay/schedule', {state: {uuid: uuid}});
    }


    return (

        <Layout
            title="Your Schedules"
            description="This is a list of your work schedules, you can upload new schedules or download a calendar file for your existing schedules."
            actionContent={<Button variant='outline' colorScheme='blue'  onClick={() => navigate('/pay/select')}>Add Schedule</Button>}
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>

                    {refreshing && <Tag colorScheme='blue' mb={4}>Refreshing for analysis updates</Tag>}

                    {loading ? <LoadingCircle /> : null}

                    {schedules ?

                        <Box>

                                <Header c={6}>

                                    <Cell c={2}>
                                        Organisation
                                    </Cell>
                                    <Cell c={2}>
                                        Specialty
                                    </Cell>
                                    <Cell c={1}>
                                        Status
                                    </Cell>

                                </Header>


                                {schedules.map((schedule, index) => {
                                    
                                    
                                    if (schedule.lastAnalysed === null){
                                        return 
                                    } 
                                    
                                    return (
                                        <Row c={6}
                                        
                                            buttons='hide'
                                        
                                            >

                                                    <Cell c={2}>
                                                        <Text fontWeight='600'>{schedule.site}</Text>
                                                        {schedule.organisation !== schedule.site &&
                                                            <Text color='subtle'>{schedule.organisation}</Text>
                                                        }
                                                        {schedule.host !== null && schedule.host !== schedule.site && schedule.host !== schedule.organisation &&
                                                            <Text color='subtle'>{schedule.host}</Text>
                                                        }

                                                    </Cell>

                                                    <Cell c={2}>
                                                        <Text as='span' fontWeight='600'>{schedule.specialty}</Text><br/>
                                                        <Text as='span' color='subtle'>{schedule.grade} at {Math.round(schedule.fteCalculated*100)}% FTE, <Moment format="DD MMM YYYY">{schedule.start}</Moment></Text>
                                                    </Cell>

                                                    <Cell>
                                                        <ButtonGroup size="xs" variant="outline">
                                                            <Button
                                                                onClick={() =>
                                                                    {
                                                                        let url = '/calendars';
                                                                        let config = {
                                                                            params: {
                                                                                uuid: schedule.uuid
                                                                            }
                                                                        };
                                                                        let response = API.getItems({url, config})
                                                                        .then(response => {
                                                                            console.log(response.data);
                                                                            const blob = new Blob([response.data.ical], { type: "text/plain" });
                                                                            const url = URL.createObjectURL(blob);
                                                                            const link = document.createElement("a");
                                                                            let title = schedule.site + ' ' + schedule.specialty + ' ' + schedule.grade + ' ' + schedule.fteCalculated;
                                                                            // Append start date to title if not null dd-mm-yyyy
                                                                            if (schedule.start !== null && schedule.start !== undefined) {
                                                                                title = title + ' ' + moment(schedule.start, 'YYYY-MM-DD').format('DD-MM-YYYY');
                                                                            }
                                                                            // Remove double spaces
                                                                            title = title.replace(/\s+/g,' ');
                                                                            link.download = title + '.ics';
                                                                            link.href = url;
                                                                            link.click();
                                                                        }).catch(error => {
                                                                            console.log(error);
                                                                        });
                                                                    }
                                                                }
                                                                >Download Calendar File</Button>
                                                        </ButtonGroup>
                                                    </Cell>
                                                
                                        </Row>
                                    )
                                })}
                                
                        </Box>


                        : null}



                </GridItem>

        </Layout>

    )

}



