import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Heading, Text, Stack, Box, Container, Flex, Image, Button, Link, GridItem } from '@chakra-ui/react';
import API from '../../core/utils/API';

export default function Apply(props) {

    const [iframeURL, setIframeURL] = useState(null);

    useEffect(() => {

        API.getItems({url: 'dbs/session'})
        .then(response => {

            console.log("response", response);
            setIframeURL(response.data.url);

        })

        
    }, []); 

    return (
        
        <Layout 
            title='DBS Check'
            description='Apply for a DBS check'
            >

            <GridItem colSpan={6}>
                <iframe border='none' width="100%" height="750" allow="camera" src={iframeURL} allowfullscreen></iframe>
            </GridItem>

        </Layout>
    )
}