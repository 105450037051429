import React, { useState, useEffect } from 'react';
import {SimpleGrid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import WorkSchedule from '../prospectiveleave/WorkSchedule';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import pluralize from 'pluralize';

import {Header, Row, Cell} from '../../core/components/lists/List';
import { set } from 'date-fns';



export default function Teachings(props) {

    const [teachings, setTeachings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAdmin , setShowAdmin] = useState(false);
    const navigate = useNavigate();
    const [refreshing, setRefreshing] = useState(false);

    function updateList() {

        let url = 'ai';
        let config = {
            params: {
                model: 'teaching'
            }
        };

        let response = API.getItems({url, config})
        .then(response => {

            // Create am array of objects from response.data
            let items = Object.keys(response.data).map(key => {
                return response.data[key];
            });
            setTeachings(items);
            setLoading(false);



        })
        .catch(error => {

            console.log("error", error);
            setLoading(false);

        }

        );

    }

    useEffect(() => {

        updateList();

    }, []);

    function viewSession(uuid) {
        //console.log("View Session", uuid);
        navigate('/my/ai/teaching/view', {state: {uuid: uuid}});
    }



    return (

        <Layout
            title="Your Teaching Sessions"
            description="This is a list of your teaching sessions."
            actionContent={
                <>
                    <SubscriptionProtectedRoute
                        message="Subscribe to Generate"
                        requiredSubscription="prod_OYd6qBn5Qe5ZDg"  // Pay plan, the lowest
                        allowTrial={false}
                        fullWindow={false}
                        fullMessage={
                            <>
                                {teachings && teachings.length <= 9 && // zero indexed
                                    <Button w='min-content' variant='outline' colorScheme='blue'  onClick={() => navigate('/my/ai/teaching/generate')}>Generate Session</Button>
                                }
                                {teachings && teachings.length > 9 && // zero indexed
                                
                                    <Button w='min-content' variant='solid' colorScheme='red' onClick={() => navigate('/my/subscriptions/')}>Subscribe</Button>
                                }
                            </>
                        }
                        >
                        {teachings && teachings.length <= 50 ?
                            <Button w='min-content' variant='outline' colorScheme='blue'  onClick={() => navigate('/my/ai/teaching/generate')}>Generate Session</Button>
                            :
                            <a href='mailto:oliver@pyrexia.io' target='_blank'>
                                <Button  w='min-content' variant='solid' colorScheme='red'>Contact Us</Button>
                            </a>
                        }
                    </SubscriptionProtectedRoute>
                </>
            }
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>

                    {loading ? <LoadingCircle /> : null}

                    {teachings &&

                        <Box >

                            <Header c={6}>

                                <Cell c={2}>
                                    Session Title
                                </Cell>
                                <Cell c={2}>
                                    Target / Audience
                                </Cell>
                                <Cell>
                                    Duration
                                </Cell>

                            </Header>

                            {teachings &&
                                    <SubscriptionProtectedRoute
                                        message="Subscribe to Generate"
                                        requiredSubscription="prod_OYd6qBn5Qe5ZDg"  // Pay plan, the lowest
                                        allowTrial={false}
                                        fullWindow={false}
                                        fullMessage={

                                            <Row c={6}                                    
                                                buttons='hide'
                                                >
                                                <Cell c={6}>
                                                    <Alert status='loading' w='auto' m={-4} borderRadius={0} className='gradient-background'>
                                                        {teachings.length <= 9 ? 
                                                            <Box bg='white' color='black' p='6px 14px' m='-6px -14px' w='-webkit-fill-available'>
                                                                You can generate a further {pluralize('sessions', 10 - (teachings.length), true)} on the free plan. 
                                                            </Box>
                                                            : 
                                                            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent='space-between' w='-webkit-fill-available'>
                                                                <Text color='white'>You have reached the limit of 10 sessions on your plan, subscribe to add more.</Text>
                                                                <Button w='min-content' variant='outline' colorScheme='white' onClick={() => navigate('/my/subscriptions/')} size='xs'>Subscribe</Button>
                                                            </Flex>
                                                        }
                                                    </Alert>
                                                </Cell>
                                            </Row>
                                        }
                                    >
                                        
                                        <Row c={6}                                    
                                                buttons='hide'
                                                >
                                                <Cell c={6}>
                                                    <Alert status='loading' w='auto' m={-4} borderRadius={0}  className='gradient-background'>
                                                        {teachings.length <= 49 ? 
                                                            <Box bg='white' color='black' p='6px 14px' m='-6px -14px' w='-webkit-fill-available'>
                                                                You can generate a further {pluralize('session', 50 - (teachings.length), true)} on the premium plan. 
                                                            </Box>
                                                            : 
                                                            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent='space-between' w='-webkit-fill-available'>
                                                                <Text color='white'>You have reached the limit of sessions on your plan, contact us to discuss other options.</Text>
                                                                <a href='mailto:oliver@pyrexia.io' target='_blank' line-height='1.4em'>
                                                                    <Button variant='outline' colorScheme='white' size='xs'>Contact Us</Button>
                                                                </a>
                                                            </Flex>
                                                        }
                                                    </Alert>
                                                </Cell>
                                            </Row>
                                    </SubscriptionProtectedRoute>
                                } 

                                {teachings.map((teaching, index) => {
                                    
                                    let json = teaching.json;

                                    if (!json) {
                                        return null;
                                    }

                                    return (


                                        <Row c={6}
                                            buttons={
                                                <Button onClick={() => viewSession(teaching.uuid)}>View</Button>
                                            }
                                            >

                                            <Cell c={2}>
                                                <Text color='black' fontWeight='bold'>{json.Topic}</Text>
                                            </Cell>
                                            <Cell c={2}>
                                                {json.UserParameters && json.UserParameters.Audience}<br/>
                                                {json.UserParameters && json.UserParameters.Setting}
                                            </Cell>
                                            <Cell>
                                                {json.UserParameters && json.UserParameters.Duration}
                                            </Cell>

                                        </Row>

                                    )

                                })}
                                
                        </Box>

                    }

                </GridItem>

        </Layout>

    )

}



