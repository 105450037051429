
import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Card from '../../core/components/cards/Card';
import Sidebar from './components/Sidebar';
// Import and parse JSON from ./demo.json
import demo from './demo.json';
 
let dummyJobs = demo;


export default function Job(props) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [jobs, setJobs] = useState(dummyJobs);

    const navigate = useNavigate();

    return (
        <Layout
            title='Jobs'
            layout='dense'
            subtitle='This is a summary of jobs across your organisation.'
            actionContent={
                <Button colorScheme='blue' variant='outline'
                    onClick={() => {
                        navigate('job', { state: { job: null } });
                    }}
                    >Post Job</Button>
            }
            >


            <GridItem colSpan={12}>

                {loading && <Loading />}

                {error && <Alert status='error'>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }


                {!loading && jobs &&

                    <Box>

                        <Header c={6}
                            >
                            <Cell c={2} pl='48px'>
                                Job
                            </Cell>
                            <Cell>
                                State
                            </Cell>
                            <Cell>
                                Dates
                            </Cell>
                            <Cell>
                                Candidates
                            </Cell>
                        </Header>

                        {jobs.map((job, index) => {

                            let start = moment(job.opens).format('MMM D');
                            let end = moment(job.closes).format('MMM D');
                            let fullStart = moment(job.opens).format('MMM D, YYYY');
                            let fullEnd = moment(job.closes).format('MMM D, YYYY');

                            if (job.status === 'Interviews') {
                                start = moment(job.interviewsStart).format('MMM D');
                                end = moment(job.interviewsEnd).format('MMM D');
                                fullStart = moment(job.interviewsStart).format('MMM D, YYYY');
                                fullEnd = moment(job.interviewsEnd).format('MMM D, YYYY');
                            }

                            if (job.status === 'Appointed') {
                                start = moment(job.closes).format('MMM D');
                                end = moment(job.closes).format('MMM D');
                                fullStart = moment(job.closes).format('MMM D, YYYY');
                                fullEnd = moment(job.closes).format('MMM D, YYYY');
                            }
                            
                            let dateMessage = '';
                            // Check if end time is in the next week
                            // update date message to remaining time in days
                            if (moment(fullEnd).isBefore(moment().add(1, 'week'))
                                && moment(fullEnd).isAfter(moment())
                                ) {
                                dateMessage = moment(fullEnd).fromNow();
                            }

                            let statusColor = 'gray';
                            if (job.status === 'Open') statusColor = 'blue';
                            if (job.status === 'Draft') statusColor = 'gray';
                            if (job.status === 'Interviews') statusColor = 'blue';
                            if (job.status === 'Appointed') statusColor = 'green';



                            return (


                                <Row c={6}
                                    buttons={
                                        <ButtonGroup size='xs' variant='outline'>
                                            <Button
                                                // onClick go to /job
                                                onClick={() => {
                                                    console.log('View job', job.details.jobSpecification);
                                                    navigate('job', { 
                                                        state: {
                                                            job
                                                        }
                                                    });
                                                }}
                                                >View</Button>
                                        </ButtonGroup>
                                    }
                                    >

                                    <Cell c={2}>
                                        <Text fontWeight='bold'>{job.title}</Text>
                                        <Text>{job.location}</Text>
                                    </Cell>
                                    <Cell>
                                        <Tag colorScheme={statusColor} variant='outline'>{job.status}</Tag>
                                    </Cell>
                                    <Cell>
                                        <Text>{job.status === 'Appointed' ?
                                            'Filled ' + start : start + ' - ' + end}
                                        </Text>
                                        {dateMessage && <Text  fontSize='xs' color='red.500'>{dateMessage}</Text>}
                                    </Cell>
                                    <Cell>
                                        <Text>{job.status === 'Appointed' ? job.appointee : job.applicants}</Text>
                                    </Cell>
                                </Row>

                                         

                            )
                        })}

                    </Box>
                }

            </GridItem>


            <GridItem colSpan={6}>

                {props.job &&
                    <Sidebar job={props.job} />
                }

            </GridItem>

        </Layout>
    )
}