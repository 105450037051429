import React, { useState, useEffect, useContext } from 'react';
import { useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, Menu, MenuList, MenuItem, MenuButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import {Header, Row, Cell} from '../../core/components/lists/List';
import AutoForm from '../../core/models/AutoForm';
import Modal from '../../core/components/modal/Modal';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import AssignRole from './AssignRole';
import secureLocalStorage from 'react-secure-storage';


function UnassignRoleModal(props) {

    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [error, setError] = useState(null);
    const [reason, setReason] = useState('');

    function submit() {

        console.log('submit', props.roleUuid, props.userUuid);
        let url = 'roles/unassign';
        let data = {
            uuid: props.roleUuid,
            reason: reason
        };
        let resp = API.postItems({url, data})
        .then(response => {
            console.log("Role unassigned", response.data);
            setShowModal(false);
            props.refresh();
        })
        .catch(error => {
            console.log(error);
            setError(error);
        });

    }

    return (
        <>
            <ButtonGroup isAttached size='xs' variant='outline'>
                <Button onClick={() => setShowDetails(true)}>
                    {props.name}
                </Button>
                <IconButton icon={<MdClose />} onClick={() => setShowModal(true)} />
            </ButtonGroup>

            {showDetails &&
                <Modal
                    title={props.name}
                    onClose={() => setShowDetails(false)}
                    >
                    <Table>
                        <Tbody>
                            {Object.keys(props.role).map((key) => {
                                // Check if props.role[key] is an object
                                if (typeof props.role[key] === 'object') {
                                    return (
                                        <Tr>
                                            <Td>{key}</Td>
                                            <Td>{JSON.stringify(props.role[key])}</Td>
                                        </Tr>
                                    )
                                }
                                return (
                                    <Tr>
                                        <Td>{key}</Td>
                                        <Td>{props.role[key]}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Modal>
            }

            {showModal &&

                <Modal
                    title='Unassign Role'
                    onClose={() => setShowModal(false)}
                    actionContent={
                        <Button colorScheme='blue' size='sm' onClick={() => submit()}>Unassign</Button>
                    }
                    >
                        {error && <Alert status="error">
                            <AlertIcon />
                            <AlertTitle mr={2}>Error</AlertTitle>
                            <AlertDescription>{error.message}</AlertDescription>
                            <CloseButton position="absolute" right="8px" top="8px" />
                        </Alert>}

                        <InputWrapper
                            type='textarea'
                            name='reason'
                            label='Reason'
                            required={true}
                            onChange={(e) => setReason(e.target.value)}
                            />

                </Modal>

            }

        </>

    )

}

function AssignRoleModal(props) {

    const [showModal, setShowModal] = useState(false);
    const [emails, setEmails] = useState([]);
    const [error, setError] = useState(null);
    const [duration, setDuration] = useState(props.duration);

    function submit() {

        console.log('submit', emails);

        let url = 'roles/assign';
        let data = {
            roleTypeUuid: props.roleTypeUuid,
            organisationUuid: props.organisationUuid,
            emails: emails
        };
        let resp = API.postItems({url, data})
        .then(response => {

            console.log("Role assigned", response.data);
            setShowModal(false);
            props.refresh();

        })
        .catch(error => {
            console.log(error);
            setError(error);
        });
    }

    return (
        <>
            <Button colorScheme='blue' variant='ghost' size='xs' onClick={() => setShowModal(true)}>Assign Role</Button>

            {showModal &&

                <Modal
                    title='Assign Role'
                    onClose={() => setShowModal(false)}
                    actionContent={
                        <Button colorScheme='blue' size='sm' onClick={() => submit()}>Assign</Button>
                    }
                    >
                        {error && <Alert status="error">
                            <AlertIcon />
                            <AlertTitle mr={2}>Error</AlertTitle>
                            <AlertDescription>{error.message}</AlertDescription>
                            <CloseButton position="absolute" right="8px" top="8px" />
                        </Alert>}

                        <InputWrapper
                            type='email'
                            name='email'
                            label='E-mails'
                            description='Separate multiple e-mails with a comma'
                            required={true}
                            onChange={(e) => setEmails(e.target.value)}
                            />

                        <InputWrapper
                            type='integer'
                            name='duration'
                            label='Duration (months)'
                            required={true}
                            defaultValue={props.duration}
                            onChange={(e) => setDuration(e.target.value)}
                            />

                </Modal>

            }

        </>

    )

}

function DeactivateRoleTypeModal(props) {

    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(null);
    const [reason, setReason] = useState('');

    function submit() {

        let url = 'roles/types/deactivate';
        let data = {
            uuid: props.uuid,
            reason
        };
        console.log('submit', props.uuid, data);

        let resp = API.postItems({url, data})
        .then(response => {
            console.log("RoleType deactivated", response.data);
            setShowModal(false);
            props.refresh();
        })
        .catch(error => {
            console.log(error);
            setError(error);
        });

    }

    return (
        <>
            <Button colorScheme='red' variant='ghost' size='xs' onClick={() => setShowModal(true)} mt={4}>
                Deactivate
            </Button>
            {showModal &&
                <Modal
                    title='Deactivate Role'
                    onClose={() => setShowModal(false)}
                    actionContent={
                        <Button variant='ghost' colorScheme='red' size='sm' onClick={() => submit()}>Deactivate</Button>
                    }
                    >
                        {error && <Alert status="error">
                            <AlertIcon />
                            <AlertTitle mr={2}>Error</AlertTitle>
                            <AlertDescription>{error.message}</AlertDescription>
                            <CloseButton position="absolute" right="8px" top="8px" />
                        </Alert>}

                        <InputWrapper
                            type='textarea'
                            name='reason'
                            label='Reason'
                            required={true}
                            onChange={(e) => setReason(e.target.value)}
                            />


                </Modal>
            }
        
        </>
    )
}

export default function Roles(props) {


    const params = useLocation();

    const [users, setUsers] = useState(null);
    const [roles, setRoles] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [requirementGroups, setRequirementGroups] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);

    let toast = useToast();


    function getRoles() {

        let uuid = secureLocalStorage.getItem('organisationUuid');
        let url = 'roles/types';
        let config = {
            params: {
                organisationUuid: uuid
            }
        };

        console.log("Get roles", url, config);
        let resp = API.getItems({url, config})
        .then(response => {

            console.log("Roles and Permissions", response.data);

            let tmpRoles = response.data.roles;
            // Sort by title
            tmpRoles.sort((a, b) => (a.title > b.title) ? 1 : -1);
            setRoles(tmpRoles);

            let tmpPermissions = response.data.permissions;
            // Sort by title
            tmpPermissions.sort((a, b) => (a.title > b.title) ? 1 : -1);
            setPermissions(tmpPermissions);

            let tmpRequirementGroups = response.data.requirementGroups;
            // Sort by title
            tmpRequirementGroups.sort((a, b) => (a.title > b.title) ? 1 : -1);
            setRequirementGroups(tmpRequirementGroups);

            

        })
        .catch(error => {
            console.log(error);
            setError(error);
        }

        );

    }


    useEffect(() => {
        if (roles && permissions) {
            setLoading(false);
        }
    }, [roles, permissions]);


    useEffect(() => {

        getRoles();

    }, []);

    function addPermission(roleUuid, permissionUuid) {

        let url = 'permissions/link';
        let data = {
            roleTypeUuid: roleUuid,
            permissionUuid: permissionUuid
        };

        let resp = API.postItems({url, data})
        .then(response => {

            console.log("Permission added", response.data);
            getRoles();

        })
        .catch(error => {
            console.log(error);
            setError(error);
        }

        );

    }

    function removePermission(permissionTagUuid, roleUuid, permissionUuid) {

        let url = 'permissions/unlink';
        let data = {
            uuid: permissionTagUuid
        };

        let resp = API.postItems({url, data})
        .then(response => {

            console.log("Permission removed", response.data);
            getRoles();

        })
        .catch(error => {
            console.log(error);
            setError(error);
        }

        );

    }

    function addTag(roleTypeUuid, tagModel, tagModelUuid) {

        console.log("Add requirement group", roleTypeUuid, tagModel, tagModelUuid);

        let url = 'roles/types/tags/add';
        let data = {
            roleTypeUuid,
            tagModel,
            tagModelUuid
        };

        let resp = API.postItems({url, data})
        .then(response => {

            console.log("Tag added", response.data);
            getRoles();

        })
        .catch(error => {
            console.log(error);
            setError(error);
        });

    }

    function removeTag(roleTypeTagUuid) {

        let url = 'roles/types/tags/remove';
        let data = {
            uuid: roleTypeTagUuid
        };

        let resp = API.postItems({url, data})
        .then(response => {

            console.log("Requirement group removed", response.data);
            getRoles();

        })
        .catch(error => {
            console.log(error);
            setError(error);
        });

    }

    return (

        <Layout
            title='Roles'
            description='Manage roles and permissions for your organisation'
            layout='dense'
            actionContent={
                <>
                    <Button colorScheme='blue' variant='outline' onClick={() => setShowModal(true)}>Add Role</Button>
                </>
            }
            >
            <GridItem colSpan={12}>


{showModal &&
    <Modal
        title='Add Role'
        onClose={() => setShowModal(false)}
        >
        <AutoForm
            url = 'roles/types'
            refresh={() => {
                getRoles();
                setShowModal(false);
            }}
            inputs = {[
                {
                    name: 'title',
                    label: 'Title',
                    required: true
                },
                {
                    type: 'textarea',
                    name: 'description',
                    label: 'Description',
                    required: true
                },
                {
                    type: 'checkbox',
                    name: 'requiresApproval',
                    label: 'Requires Approval',
                    value: true
                },
                {
                    type: 'number',
                    name: 'durationMonths',
                    label: 'Duration (months)',
                    value: 6,
                    max: 60,
                },
                {
                    type: 'hidden',
                    name: 'organisationUuid',
                    value: props.organisationUuid
                }, 
                {
                    type: 'select',
                    name: 'permissions',
                    options: permissions ? permissions.map((item) =>{
                        return {
                            value: item.uuid,
                            label: item.title
                        }
                    }) : []
                }
            ]}
            />
    </Modal>
}

            {loading && <LoadingCircle />}
            {error && <Splash message={error} />}

            {roles &&
            
                <Box>

                    <Header c={7}
                        buttons='hide'
                        >
                        <Cell c={2}>
                            Roles
                        </Cell>
                        <Cell c={2}>
                            Permissions
                        </Cell>
                        <Cell c={2}>
                            Users
                        </Cell>
                        <Cell>
                            Requirement Groups
                        </Cell>
                    </Header>

                    {roles.map((item) => {

                        let unassignedPermissions = permissions.filter(permission => {
                            let returnEarly = false;
                            item.PermissionTags && item.PermissionTags.map((PermissionTags) => {
                                if (PermissionTags.permissionUuid === permission.uuid) {
                                    // already assigned, move to next map
                                    returnEarly = true;
                                }
                            });

                            if (returnEarly) {
                                return;
                            }

                            return permission;
                        });

                        let tmpRequirementGroupUuids = item.RoleTypeTags.filter(RoleTypeTag => {
                            console.log(RoleTypeTag);
                            if (RoleTypeTag.tagModel === 'RequirementGroup') {
                                return RoleTypeTag.tagModelUuid;
                            }
                        });

                        tmpRequirementGroupUuids = tmpRequirementGroupUuids.map((RoleTypeTag) => {
                            return {
                                groupUuid: RoleTypeTag.tagModelUuid,
                                uuid: RoleTypeTag.uuid
                            }
                        });

                        let selectedRequirementGroups = [];
                        let missingRequirementGroups = [];

                        

                        requirementGroups.map((requirementGroup) => {

                            let foundTagUuid = null;
                            tmpRequirementGroupUuids.map((tmpRequirementGroupUuid) => {
                                if (tmpRequirementGroupUuid.groupUuid === requirementGroup.uuid) {
                                    foundTagUuid = tmpRequirementGroupUuid.uuid;
                                }
                            });

                            if (foundTagUuid) {
                                selectedRequirementGroups.push({
                                    title: requirementGroup.title,
                                    tagUuid: foundTagUuid
                                });
                            }
                            else {
                                missingRequirementGroups.push({
                                    title: requirementGroup.title,
                                    requirementGroupUuid: requirementGroup.uuid,
                                    roleTypeUuid: item.uuid 
                                });
                            }


                        });



                        return (

                            <Row c={7}
                                buttons='hide'
                                >

                                <Cell c={2}>
                                    <Heading size='label'>{item.title}</Heading>
                                    <Text color='subtle'>{item.description}</Text>
                                    <Text color='subtle'>Default Duration: {item.durationMonths ? item.durationMonths + ' months' : 'No expiration'}</Text>
                                    <DeactivateRoleTypeModal uuid={item.uuid} refresh={() => getRoles()} />
                                </Cell>
                                <Cell c={2}>
                                    <Wrap spacing={2}>
                                        {item.PermissionTags && item.PermissionTags.map((PermissionTag) => {                                            
                                            return (
                                                <>
                                                <ButtonGroup isAttached size='xs' variant='outline'>
                                                    <Button
                                                        onClick={() => {
                                                            // Create a toast with description
                                                            toast({
                                                                title: PermissionTag.Permission.title,
                                                                description: PermissionTag.Permission.description,
                                                                status: "info",
                                                                duration: 9000,
                                                                isClosable: true,
                                                            });
                                                        }}
                                                        >
                                                        {PermissionTag.Permission.title}
                                                    </Button>
                                                    <IconButton aria-label='Remove permission' icon={<MdClose/>} onClick={() => {removePermission(PermissionTag.uuid)}} />
                                                </ButtonGroup>
                                                </>
                                            )
                                        })}
                                        {!item.PermissionTags &&
                                            <Tag size='sm'>
                                                None assigned
                                            </Tag>
                                        }
                                        {unassignedPermissions.length > 0 &&

                                            <Menu>
                                                <MenuButton>
                                                    <Button size='xs' variant='ghost' colorScheme='blue'>
                                                        Add Permission
                                                    </Button>
                                                </MenuButton>
                                                <MenuList>
                                                    {unassignedPermissions.map((permission) => {
                                                        return (
                                                            <MenuItem onClick={() => {addPermission(item.uuid, permission.uuid)}}>
                                                                {permission.title}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </MenuList>
                                            </Menu>

                                        }
                                        
                                    </Wrap>

                                </Cell>
                                <Cell c={2}>
                                    <Wrap spacing={2}>
                                        {item.Roles && item.Roles.map((role) => {

                                            if (!role.User) {
                                                return <Tag size='sm' colorScheme='red'>No user found</Tag>
                                            }

                                            // Combine first and last name
                                            let uname = role.User.firstName + ' ' + role.User.lastName;
                                            uname = uname.trim();
                                            if (uname === '') {
                                                uname = role.User.email;
                                            }
                                            uname = uname.trim();
                                            if (uname === '') {
                                                uname = 'No name or email';
                                            }


                                            let user = role.User;
                                            return (
                                                <UnassignRoleModal
                                                    name={user.firstName + ' ' + user.lastName}
                                                    role={role}
                                                    roleUuid={role.uuid}
                                                    refresh={() => getRoles()}
                                                    />
                                            )
                                        })}
                                        {!item.Roles &&
                                            <Tag size='sm'>
                                                None assigned
                                            </Tag>
                                        }
                                        <AssignRoleModal roleTypeUuid={item.uuid} duration={item.durationMonths} organisationUuid={props.organisationUuid} 
                                            refresh={() => getRoles()} />
                                    </Wrap> 
                                </Cell>
                                <Cell>
                                    <Wrap spacing={2}>
                                    {selectedRequirementGroups.map((requirementGroup) => {
                                        return (
                                            <Tag size='sm' variant='outline'>
                                                {requirementGroup.title}
                                                <TagRightIcon cursor='pointer' _hover={{color: 'red.500'}} as={MdClose} onClick={() => {removeTag(requirementGroup.tagUuid)}} />
                                            </Tag>
                                        )
                                    })}

                                    {missingRequirementGroups.length > 0 &&
                                        <Menu>
                                            <MenuButton>
                                                <Button size='xs' variant='ghost' colorScheme='blue'>
                                                    Add Requirement Group
                                                </Button>
                                            </MenuButton>
                                            <MenuList>
                                                {missingRequirementGroups.map((requirementGroup) => {
                                                    return (
                                                        <MenuItem onClick={() => {addTag(requirementGroup.roleTypeUuid, 'RequirementGroup', requirementGroup.requirementGroupUuid)}}>
                                                            {requirementGroup.title}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </MenuList>
                                        </Menu>
                                    }
                                    
                                    </Wrap>
                                </Cell>

                            </Row>

                        )


                    })}


                
                </Box>
            
            
            
            }

            </GridItem>
        </Layout>
    )

}