import React, { useState, useEffect, createContext, useContext } from 'react';
import { Tag, HStack, ButtonGroup, Wrap, Stack, Heading, Box, Input, Button, Text } from '@chakra-ui/react';
import API from '../core/utils/API';
import Loading from '../static/layout/Loading';
import { set } from 'date-fns';
import crypto from 'crypto-js';
import Kyber1024 from './Kyber1024';
import {Buffer} from 'buffer';
import { AESClient } from 'e2ee-ts';
import { Outlet } from 'react-router-dom';
import {SubscriptionWrapper} from './SubscriptionContext';
import  secureLocalStorage  from  "react-secure-storage";

import Encryption from '../core/utils/Encryption';
import { te } from 'date-fns/locale';

import { InterceptorContext } from './Interceptor';
import Splash from '../static/layout/Splash';

export const KyberEncryptionContext = createContext();

export const KyberEncryption = (props) => {

    const [publicKey, setPublicKey] = useState('');
    const [privateKey, setPrivateKey] = useState('');
    const [symmetricKey, setSymmetricKey] = useState(''); // This is the key that is used to encrypt the data
    const [encapsulatedSymmetricKey, setEncapsulatedSymmetricKey] = useState(''); // This is the key that is used to encrypt the data
    const [channelSecured, setChannelSecured] = useState(false); // This is the key that is used to encrypt the data

    const [serverDecrypted, setServerDecrypted] = useState(''); // This is the key that is used to encrypt the data
    const [clientDycrypted, setClientDecrypted] = useState(''); // This is the key that is used to encrypt the data
   
    const Interceptor = useContext(InterceptorContext);
    const InterceptorSetup = Interceptor.setup;

    const createSymmetricKey = async (pk) => {

        //console.log("Creating symmetric key with public key: ", pk);

        let c_ss = Kyber1024.Encrypt1024(pk); // Using the public key sent by the server to encrypt the data
        //console.log("Encrypted symmetric key: ", c_ss);
        let c = c_ss[0]; // c is the encapsulation of the symmetric key, this is sent to the server to decrypt data after transfer
        let ss1 = c_ss[1]; // ss is the symmetric key, this is used to encrypt the data
        setSymmetricKey(ss1);
        setEncapsulatedSymmetricKey(c);
        
        //console.log("Encapsulated symmetric key: ", c);
        
      
        //console.log("Symmetric key: ", ss1);
        //console.log("Buffer symmetric key: ", new Buffer.from(Buffer.from(ss1)));
        //console.log("Buffer symmetric key: ", Buffer.from(ss1, 'base64'));

        ss1 = new Buffer.from(ss1).toString('base64');
        //console.log("Saved symmetric key (ss1) base64: ", ss1);

        ss1 = Buffer.from(ss1, 'base64');
        //console.log("Saved symmetric key (ss1) buffer: ", ss1);
        ss1 = new Buffer.from(ss1).toString('base64');
        // Save the symmetric key in local storage
        secureLocalStorage.setItem('symmetricKey', ss1);
        secureLocalStorage.setItem('encapsulatedSymmetricKey', c); // TODO - security - delete this?

        let testString = "This is a test string (client)";
        //console.log("Test string: ", testString);
        
        let encryptedTestString = await Encryption.AES256_GCM_ENCRYPT(testString);
        //console.log("Encrypted test string: ", encryptedTestString);

        let decryptedTestString = await Encryption.AES256_GCM_DECRYPT(encryptedTestString);
        //console.log("Decrypted test string: ", decryptedTestString);
        
        // We need to send the encapsulated symmetric key to the server so that it can decrypt the data
        // We can then start sending encrypted data back and forth
        API.postItems({
            timeout: 30,
            url: 'admin/encryption', 
            data: {
                c: JSON.stringify(c),
                clientTestData: encryptedTestString,
                encryption: false,
            }
        }).then((response) => {

            // If response is 200 then approve
            setChannelSecured(true);
        
            //console.log("Sent encapsulated symmetric key to server: ", response.data);

            if (response.data.decryptedClientTestData === "This is a test string (client)") {
                setClientDecrypted(true);
            } else {
                console.log("Client not decrypted correctly");
                setClientDecrypted(false);
                setChannelSecured(false);
            }

            let encryptedTestData = response.data.encryptedNodeTestData;
            //console.log("Encrypted SERVER test data: ", encryptedTestData)
            // Convert the encrypted test data from base64 to a buffer
            encryptedTestData = Buffer.from(encryptedTestData, 'base64');
            //console.log("Encrypted SERVER test data (buffer): ", encryptedTestData)

            
    
            
            let decryptedTestData = Encryption.AES256_GCM_DECRYPT(encryptedTestData)
            .then((decrypted) => {
                //console.log("Decrypted SERVER test data: ", decrypted);
                if (decrypted === "This is a test string (server)") {

                    setServerDecrypted(true);
                    setChannelSecured(true);
                } else {
                    console.log("Server not decrypted correctly");
                    setServerDecrypted(false);
                    setChannelSecured(false);
                }
            });


        }).catch((error) => {
            console.log("KyberEncryption Error from API: ", error)
            setChannelSecured(false);
            // TODO - SECURITY - REFRESH GETTING PUBLIC KEY AND START PROCESS AGAIN
        })

    }

    const checkLocalKey = async () => {

        // Check if we have a local key already
        let ss1 = secureLocalStorage.getItem('symmetricKey');
        if (ss1) {
            //console.log("Found local symmetric key: ", ss1);
            //setSymmetricKey(ss1);
            setChannelSecured(true);
            //return; 
        } else {
            console.log("No local symmetric key found");
            getPublicKey();
            return;
        }

        //console.log("Checking keys with server");
        let testString = "This is a test string (client)";
        //console.log("Test string: ", testString);
        let encryptedTestString = await Encryption.AES256_GCM_ENCRYPT(testString);


        // Check if the local key matches server key
        await API.postItems({
            url: 'admin/checkencryption',
            data: {
                encryption: false,
                clientTestData: encryptedTestString,
                //ss1 // Can't send the key!
            }
        }).then((response) => {
            //console.log("Got response from checkencryption: ", response.data);
            if (response.data.decryptedClientTestData === testString) {
                setClientDecrypted(true);
                let serverEncryptedTestString = response.data.serverTestData;
                //console.log("Server encrypted test string: ", serverEncryptedTestString);

                //setServerDecrypted(true);
                //setSymmetricKey(ss1);
                //setChannelSecured(true);

                let decryptedServerTestString = Encryption.AES256_GCM_DECRYPT(serverEncryptedTestString)
                .then((decrypted) => {

                    if (decrypted === "This is a test string (server)") {

                        setServerDecrypted(true);
                        setSymmetricKey(ss1);
                        setChannelSecured(true);
                        //console.log("CHECK: Channel secured");
                        return;
                    }  else {
                        setChannelSecured(false);
                    }
                })
                .catch((error) => {
                    console.log("Kyber Check: Error from API: ", error);
                    setChannelSecured(false);
                    getPublicKey();
                    return;
                });
            } else {
                console.log("Kyber Check: Channel not secured as client test data incorrect");
                setChannelSecured(false);
            }
        }).catch((error) => {
            // Delete the local key
            secureLocalStorage.removeItem('symmetricKey');
            //console.log("Error from API: ", error); 
            setChannelSecured(false);
            getPublicKey();
        });

    }

    const getPublicKey = async () => {

        // Before getting a new key make sure we've reset everything
        // Remove keys from local storage
        secureLocalStorage.removeItem('symmetricKey');
        setChannelSecured(false);
        setClientDecrypted(false);
        setServerDecrypted(false);

        //console.log("Getting public key, starting handshake");
        API.getItems({timeout: 30, url: 'admin/encryption'}).then((response) => {
            //console.log("Got public key: ", response.data.pk);
            setPublicKey(JSON.parse(response.data.pk));
        }).catch((error) => {
            console.log("Error from API: ", error)

            // Refresh window
            window.location.reload();

            // If we can't get the public key then we can't encrypt the data
            // We need to stop the process here
            /*
            setChannelSecured(false);
            setClientDecrypted(false);
            setServerDecrypted(false);
            secureLocalStorage.removeItem('symmetricKey');

            getPublicKey();
            */


        })

    }

    useEffect(() => {
        if (InterceptorSetup) {
            checkLocalKey();
        }
    }, [InterceptorSetup]);

    useEffect(() => {
        if (publicKey) {
            createSymmetricKey(publicKey);
        }
    }, [publicKey]);


    // Check if environment variable is development
    // If so, use the local public key

    return (    

        <>
        <Wrap textAlign='right' spacing={2} display='none'>

            <Text>
                End-to-end Encryption Channel status:
            </Text>

            <Tag colorScheme={channelSecured ? 'green' : 'red'} size='sm'>
                Channel
            </Tag>

            <Tag colorScheme={clientDycrypted ? 'green' : 'red'} size='sm'>
                Client
            </Tag>

            <Tag colorScheme={serverDecrypted ? 'green' : 'red'} size='sm'>
                Server
            </Tag>

            <Tag colorScheme={InterceptorSetup ? 'green' : 'red'} size='sm'>
                Interceptor
            </Tag>

        </Wrap>

        {InterceptorSetup ?
            <KyberEncryptionContext.Provider value={{ setup: channelSecured }}>


                {channelSecured || process.env.NODE_ENV === 'development' ?
                    < >
                        <Box display='none'>
                            Channel secured with Kyber encryption
                        </Box>
                        {props.children}

                            <Outlet />

                    </>
                    :
                    <Splash >

                    
                        <Loading timeout='30' blankTimeout='0' message='Creating a quantum secure channel'/>

                    </Splash>
                }
            </KyberEncryptionContext.Provider>
        :
        <>
        {props.children}
        </>
        }
        </>
    )
}