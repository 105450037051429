
import React, { useState, useEffect, useContext } from 'react';
import { Accordion, AccordionIcon, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineCircle, MdCheckCircleOutline, MdOutlineModelTraining,MdOutlineVisibility, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdCardGiftcard } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Card from '../../core/components/cards/Card';
import { set } from 'date-fns';
import Navigation from './components/Navigation';
import Sidebar from './components/Sidebar';
let dummyJob = {
    title: 'Oncology CNS',
    location: 'Praed St Clinic',
    description: 'We are looking for a Clinical Nurse Specialist to join our team at Praed St Clinic. The successful candidate will have experience in oncology and be able to work in a fast-paced environment. The role will involve working with patients and their families to provide support and advice on their treatment and care. The CNS will also work closely with the medical team to ensure that patients receive the best possible care. The successful candidate will have excellent communication skills and be able to work as part of a team. They will also have a positive attitude and be committed to providing high-quality care to patients.',
    status: 'Open',
    applicants: 3,
    opens: '2024-04-01',
    closes: '2024-04-30',
    interviewsStart: '2024-05-01',
    interviewsEnd: '2024-05-15',
    appointee: 'Dr. Jane Doe',
}

let dummyMatrix = [
    {
        "question": "Can you provide an overview of your experience working as a Clinical Nurse Specialist in oncology, including the types of patients you have worked with and the scope of your responsibilities?",
        "type": "application",
        "scoreMatrix": {
            "0": "Does not meet criteria",
            "1": "Limited experience, lacks depth or relevance",
            "2": "Some relevant experience but lacks breadth or depth",
            "3": "Solid experience with various oncology patients and responsibilities",
            "4": "Extensive experience covering a wide range of oncology cases and responsibilities",
            "5": "Exceptional experience with diverse oncology cases, demonstrating leadership and innovation"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    },
    {
        "question": "Describe your approach to providing holistic care to oncology patients and their families. How do you ensure their physical, emotional, and psychological needs are met?",
        "type": "interview",
        "scoreMatrix": {
            "0": "Inadequate understanding of holistic care principles",
            "1": "Limited understanding, lacks practical application",
            "2": "Basic understanding but lacks depth or consistency",
            "3": "Shows competence in addressing physical, emotional, and psychological needs",
            "4": "Demonstrates a thorough understanding and consistent application of holistic care principles",
            "5": "Exemplary approach to holistic care, integrating innovative strategies to meet diverse needs"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    },
    {
        "question": "In what ways do you stay current with advancements and best practices in oncology nursing? Please provide examples of how you have incorporated evidence-based practice into your work.",
        "type": "application",
        "scoreMatrix": {
            "0": "No evidence of staying current with advancements or best practices",
            "1": "Limited evidence of staying current, lacks examples of incorporating evidence-based practice",
            "2": "Some efforts made to stay current, with a few examples of incorporating evidence-based practice",
            "3": "Demonstrates regular efforts to stay current, with clear examples of incorporating evidence-based practice",
            "4": "Consistently stays current with advancements and best practices, with several strong examples of incorporating evidence-based practice",
            "5": "Demonstrates exceptional commitment to staying current with advancements and best practices, with innovative approaches to incorporating evidence-based practice"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    
    },
    {
        "question": "Communication is crucial in a multidisciplinary oncology team. How do you facilitate effective communication and collaboration between healthcare professionals, patients, and their families?",
        "type": "interview",
        "scoreMatrix": {
            "0": "Lacks understanding of the importance of communication in a multidisciplinary team",
            "1": "Limited understanding, provides vague or impractical strategies",
            "2": "Basic understanding, provides some relevant strategies but lacks depth or consistency",
            "3": "Shows competence in facilitating effective communication and collaboration",
            "4": "Demonstrates strong skills in facilitating communication and collaboration, with clear examples of success",
            "5": "Exemplary ability to facilitate communication and collaboration, with innovative and effective strategies"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    
    },
    {
        "question": "Oncology nursing often involves managing complex symptom management and treatment side effects. Can you discuss a challenging case you've encountered and how you addressed it?",
        "type": "interview",
        "scoreMatrix": {
            "0": "Unable to provide relevant example or lacks understanding of complex symptom management",
            "1": "Limited understanding, provides irrelevant or superficial example",
            "2": "Basic understanding, provides a relevant example but lacks depth in addressing the challenge",
            "3": "Provides a relevant example and demonstrates competence in addressing the challenge",
            "4": "Demonstrates strong skills in managing complex symptoms and treatment side effects, with a detailed example of success",
            "5": "Exemplary ability to manage complex symptoms and treatment side effects, with innovative approaches and outstanding outcomes"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    
    },
    {
        "question": "How do you approach educating patients and their families about their diagnosis, treatment options, and potential side effects? Provide examples of educational materials or strategies you have used.",
        "type": "application",
        "scoreMatrix": {
            "0": "Lacks understanding of patient education principles",
            "1": "Limited understanding, provides irrelevant or impractical strategies",
            "2": "Basic understanding, provides some relevant strategies but lacks depth or consistency",
            "3": "Shows competence in patient education, with clear examples of relevant strategies used",
            "4": "Demonstrates strong skills in patient education, with detailed examples of effective strategies used",
            "5": "Exemplary ability in patient education, with innovative approaches and outstanding outcomes demonstrated"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    
    },
    {
        "question": "The NHS values emphasize compassion, respect, and dignity in patient care. How do you incorporate these values into your practice as an oncology CNS?",
        "type": "interview",
        "scoreMatrix": {
            "0": "Unable to articulate or demonstrate understanding of NHS values",
            "1": "Limited understanding, provides vague or generic responses",
            "2": "Basic understanding, provides some relevant examples but lacks depth or consistency",
            "3": "Demonstrates competence in incorporating NHS values into practice, with clear examples provided",
            "4": "Consistently incorporates NHS values into practice, with strong examples of compassionate, respectful, and dignified care",
            "5": "Exemplifies NHS values in practice, consistently demonstrating compassion, respect, and dignity in all aspects of care delivery"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    
    },
    {
        "question": "Continuous professional development is essential in nursing. How do you envision furthering your skills and knowledge in oncology nursing, particularly within the NHS context?",
        "type": "application",
        "scoreMatrix": {
            "0": "Shows no interest or understanding of the importance of continuous professional development",
            "1": "Limited understanding, provides vague or generic responses",
            "2": "Basic understanding, provides some relevant examples but lacks depth or specificity",
            "3": "Demonstrates a clear plan for furthering skills and knowledge in oncology nursing, with some specific examples provided",
            "4": "Shows a proactive approach to continuous professional development, with detailed plans and strategies outlined",
            "5": "Exemplifies a commitment to continuous professional development, with innovative approaches and clear goals for advancement within the NHS context"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    
    },
    {
        "question": "The NHS is committed to promoting equality, diversity, and inclusion. How do you ensure culturally sensitive care for patients from diverse backgrounds, including those with language barriers or unique cultural practices?",
        "type": "interview",
        "scoreMatrix": {
            "0": "Unable to articulate or demonstrate understanding of cultural sensitivity and diversity",
            "1": "Limited understanding, provides vague or generic responses",
            "2": "Basic understanding, provides some relevant examples but lacks depth or consistency",
            "3": "Demonstrates competence in providing culturally sensitive care, with clear examples provided",
            "4": "Consistently provides culturally sensitive care, with strong examples of effectively addressing diverse needs",
            "5": "Exemplifies cultural sensitivity and diversity awareness, consistently providing inclusive care and adapting practices to meet diverse needs"
        },
        "aiScore": "2",
        "aiReason": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porttitor tellus at erat dictum, nec scelerisque arcu fringilla."
    
    }
]

  
let dummJobSpec = [
        {
          "title": "Job Title",
          "description": "Oncology Clinical Nurse Specialist (CNS)"
        },
        {
          "title": "Location",
          "description": "National Health Service (NHS) hospitals or oncology clinics"
        },
        {
          "title": "Job Overview",
          "description": "The Oncology Clinical Nurse Specialist (CNS) plays a vital role in providing expert nursing care to patients undergoing treatment for cancer within the NHS. They are responsible for coordinating and delivering high-quality care, providing education and support to patients and their families, and collaborating with multidisciplinary teams to ensure comprehensive treatment plans."
        },
        {
          "title": "Key Responsibilities",
          "description": [
            "Assessing and monitoring oncology patients throughout the treatment process, including administering chemotherapy, managing symptoms, and evaluating treatment responses.",
            "Providing education and emotional support to patients and their families, including information about treatment options, side effects, and coping strategies.",
            "Collaborating with oncologists, surgeons, radiologists, and other healthcare professionals to develop individualized care plans and ensure continuity of care.",
            "Participating in clinical research and quality improvement initiatives to advance oncology nursing practice and patient outcomes.",
            "Maintaining accurate medical records and documentation, adhering to regulatory standards and NHS policies.",
            "Participating in staff development and education activities to enhance clinical expertise and promote best practices in oncology nursing."
          ]
        },
        {
          "title": "Qualifications and Skills",
          "description": [
            "Registered Nurse (RN) with current registration and licensure with the Nursing and Midwifery Council (NMC).",
            "Specialist Oncology Nursing qualification or equivalent experience.",
            "Advanced clinical skills in oncology nursing, including chemotherapy administration, symptom management, and patient education.",
            "Excellent communication and interpersonal skills, with the ability to establish rapport and provide compassionate care to patients and families.",
            "Strong critical thinking and problem-solving abilities, with the capacity to assess complex patient situations and implement appropriate interventions.",
            "Ability to work effectively within multidisciplinary teams, collaborating with healthcare professionals from diverse backgrounds.",
            "Commitment to professional development and evidence-based practice, with a willingness to engage in ongoing learning and education."
          ]
        },
        {
          "title": "Experience",
          "description": [
            "Minimum of [X] years of experience in oncology nursing, preferably within a hospital or clinical setting.",
            "Demonstrated experience in the administration of chemotherapy, management of oncology-related symptoms, and provision of patient education and support.",
            "Experience working with diverse patient populations and managing complex care needs.",
            "Previous experience in a CNS or advanced practice nursing role is desirable."
          ]
        },
        {
          "title": "Working Conditions",
          "description": "The Oncology CNS position within the NHS typically involves full-time employment, with potential for rotation through different shifts and weekend/on-call duties as required. The role may require frequent interaction with patients who may be undergoing intense treatment regimens and experiencing emotional distress. Work settings may include hospital wards, outpatient clinics, and community-based oncology services."
        }
      ]
      
export default function Job(props) {

    // Using useLocation to access location state
    const location = useLocation();
    console.log(location.state);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [job, setJob] = useState(location.state ?  location.state.job : {});

    const [feedback, setFeedback] = useState({
        0: {answer: 0, comments: ''},
        1: {answer: 0, comments: ''},
        2: {answer: 0, comments: ''}, 
        3: {answer: 0, comments: ''},
        4: {answer: 0, comments: ''},
        5: {answer: 0, comments: ''},
        6: {answer: 0, comments: ''},  
        7: {answer: 0, comments: ''},
    });

    function updateFeedback(question, answer, comments) {

        let tmpFeedback = feedback;
        tmpFeedback[question] = {
            answer: parseInt(answer),
            comments: comments
        }
        setFeedback(tmpFeedback);

        console.log(tmpFeedback);

    }

    const navigate = useNavigate();

    let marking = false;
    marking = props.marking;

    return (
        <Layout
            title={job.title}
            layout='dense'
            description={'Interview marking for ' + job.title + ' and candidate Oliver Trampleasure'}
            actionContent={
                <Navigation job={job} />
            }
            >


            <GridItem colSpan={12}>

                {loading && <Loading />}

                {error && <Alert status='error'>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }

            </GridItem>

            <GridItem colSpan={8}>

                <Stack spacing={4} pr={4}>

                    <Card title='Application' p={0}>
                        <Heading fontSize='xl' mb={1} p={4} lineHeight='1'>Application</Heading>

                        <Accordion allowMultiple>

                            {job.details.interview.questions.map((question, index) => {

                                return (

                                    <AccordionItem>
                                        <AccordionButton textAlign='left'>
                                            <Flex justifyContent='space-between' w='100%'>
                                                <Box>
                                                    <HStack alignItems='flex-start'>
                                                        <Flex align='center' w='18px' minW='18px' maxW='18px'>{index+1}</Flex>
                                                        <Text fontWeight='600'>{question.question}</Text>
                                                    </HStack>
                                                </Box>
                                            </Flex>  
                                        </AccordionButton>
                                        <AccordionPanel pl='42px'>
                                            <Stack spacing={4}>
                                                    {JSON.stringify(job.details.interview.scoreMatrix)}
                                                    <InputWrapper type='radio'
                                                        label='Score' 
                                                        options={Object.keys(job.details.interview.scoreMatrix).map((scoreKey) => {
                                                            return job.details.interview.scoreMatrix[scoreKey]
                                                        }
                                                        )}
                                                        /> 

                                                    <InputWrapper type='textarea'
                                                        label='Comments'
                                                        name={'question_' + index + '_comments'}
                                                        description="Provide feedback on the applicant's response, they will be able to view this"
                                                        defaultValue={feedback && feedback[index] ? feedback[index].comments : null}
                                                        />
                                               
                                            </Stack>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            })}

                        </Accordion>

                    </Card>

                </Stack>

            </GridItem>


                <GridItem colSpan={4}>

                    {job && job.applicants > 0 &&
                        <Sidebar job={job} />
                    }

                </GridItem>




        </Layout>
    )
}