import React, { useState } from 'react';
import { Flex, Box, Text, Tag, Wrap, Button, Stack } from '@chakra-ui/react';

export default function Summary(props) {

    // Get state
    const [loading, setLoading] = useState(false);

    return (
        <Stack spacing={2}>

            {props.data != undefined && Object.keys(props.data).map((key, index) => {

                // manipulate the key to be more human readable
                let humanKey = key.replace(/_/g, ' ');
                // Capitalize the first letter after each space
                humanKey = humanKey.replace(/\b\w/g, l => l.toUpperCase());
                
                console.log(props.data[key]);
                console.log(props.data[key]['value']);

                // Check value
                

                return (
                    <Wrap>
                        <Text>{humanKey}:</Text>
                        <Text>{props.data[key]['value']}</Text>
                        {props.data[key]['required'] &&  
                            (props.data[key]['value'] === null || props.data[key]['value'] === undefined || props.data[key]['value'] === '') &&
                            <Tag size='sm' colorScheme='red'>Required</Tag>}
                    </Wrap>
                )
            })}

        </Stack>

    )

}