
import React, { useState, useEffect, useContext } from 'react';
import { Accordion, AccordionIcon, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineCircle, MdCheckCircleOutline, MdOutlineModelTraining,MdOutlineVisibility, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdCardGiftcard } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Card from '../../core/components/cards/Card';
import { set } from 'date-fns';
import Navigation from './components/Navigation';
import Sidebar from './components/Sidebar';
      
export default function Job(props) {

    // Using useLocation to access location state
    const location = useLocation();
    console.log(location.state);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [job, setJob] = useState(location.state ?  location.state.job : {});

    const [feedback, setFeedback] = useState({
        0: {answer: 0, comments: ''},
        1: {answer: 0, comments: ''},
        2: {answer: 0, comments: ''}, 
        3: {answer: 0, comments: ''},
        4: {answer: 0, comments: ''},
        5: {answer: 0, comments: ''},
        6: {answer: 0, comments: ''},  
        7: {answer: 0, comments: ''},
    });

    function updateFeedback(question, answer, comments) {

        let tmpFeedback = feedback;
        tmpFeedback[question] = {
            answer: parseInt(answer),
            comments: comments
        }
        setFeedback(tmpFeedback);

        console.log(tmpFeedback);

    }

    const navigate = useNavigate();

    let marking = false;
    marking = props.marking;

    return (
        <Layout
            title={job.title}
            layout='dense'
            description={props.marking ? 'Reviewing application for Dr Oliver Trampleasure': 'Draft application for Oncology CNS at Praed St Clinic'}
            actionContent={
                <Navigation job={job} />
            }
            >


            <GridItem colSpan={12}>

                {loading && <Loading />}

                {error && <Alert status='error'>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }

            </GridItem>

            <GridItem colSpan={6}>
            
                <Stack spacing={4} pr={4}>

                    <Card title='Application' p={0}>
                        <Heading fontSize='xl' mb={1} p={4} lineHeight='1'>Application</Heading>

                        <Accordion allowMultiple>

                            {job.details.applicationScoring.map((matrix, index) => {
                                
                                // Randomly select complete to be true or false
                                let complete = true;
                                if (Math.random() > 0.5){
                                    complete = false
                                }

                                let options = Object.keys(matrix.scoreMatrix).map((key) => {
                                    return matrix.scoreMatrix[key]
                                })
                                return (

                                    <AccordionItem>
                                        <AccordionButton textAlign='left'>
                                            <Flex justifyContent='space-between' w='100%'>
                                                <Box>
                                                    <HStack alignItems='flex-start'>
                                                        <Flex align='center'  w='18px' minW='18px' maxW='18px'>{index+1}</Flex>
                                                        <Text fontWeight='600'>{matrix.question}</Text>
                                                    </HStack>
                                                </Box>
                                                <Box p={2}>
                                                    <Icon 
                                                        as={complete ? MdCheckCircleOutline  : MdOutlineCircle} 
                                                        color={complete ? 'green.500' : 'red.500'}
                                                        boxSize='24px'
                                                        />
                                                </Box>
                                            </Flex>  
                                        </AccordionButton>
                                        <AccordionPanel pl='42px'>
                                            <Stack spacing={4}>

                                            {marking ?

                                                <>
                                                    <Stack spacing={2}>
                                                    <Heading fontSize='sm' lineHeight='1' m={0}>
                                                        Candidate Answer
                                                    </Heading>
                                                    <Text>
                                                        {matrix.answer}
                                                    </Text>
                                                    </Stack>
                                                         
                                                    <Box fontSize='sm' pt={4}>
                                                        <Box p={2} border='1px solid' borderRadius='md' borderColor='blue.500' mb={2} size='sm' mr={2} colorScheme='blue' variant='outline'>
                                                            <Text color='black' fontWeight='600'>AI Recommendation: {matrix.aiScore}</Text>
                                                            <Text>{matrix.aiReason}
                                                            <Text as='span'
                                                                pl={1}
                                                                cursor='pointer'
                                                                _hover={{
                                                                    color: 'blue.500',
                                                                }}
                                                                onClick={() => {
                                                                    // get element by name (which is unique in this case)
                                                                    // name is question_0_comments
                                                                    //let element = document.getElementsByName('question_' + index + '_comments')[0];
                                                                    // add AI reason to value of element textarea
                                                                    //element.value = matrix.aiReason;
                                                                    updateFeedback(index, matrix.aiScore, matrix.aiReason)
                                                                    // Select second value for 
                                                                }}
                                                                color='blue.500'
                                                                >
                                                                Click to accept and copy to comments.
                                                            </Text>
                                                            </Text>
                                                        </Box>
                                                    </Box>
  
                                                    <InputWrapper type='radio'
                                                        label='Score' 
                                                        options={options} 
                                                        defaultValue={options[feedback[index].answer] }
                                                        setValue={(value) => {
                                                            console.log("finding index", value, options);
                                                            // Find index of value in options
                                                            let indexInternal = options.indexOf(value);
                                                            console.log("indexInternal", indexInternal);
                                                            if (indexInternal === -1) {
                                                                return
                                                            }
                                                            updateFeedback(index, indexInternal, feedback[index].comments)
                                                        }}
                                                        /> 

                                                    <InputWrapper type='textarea'
                                                        label='Comments'
                                                        name={'question_' + index + '_comments'}
                                                        description="Provide feedback on the applicant's response, they will be able to view this"
                                                        defaultValue={feedback && feedback[index] ? feedback[index].comments : null}
                                                        />
                                                </>

                                                :
                                                <>
                                                    <InputWrapper type='textarea'/>
                                                    <Text lineHeight='1'>
                                                        <Text as='span' mr={2} color='black' fontSize='xs' lineHeight='1'>
                                                            Scoring Matrix:
                                                        </Text>
                                                        {options.map((opt, ind) => {
                                                            return (
                                                                <Text mr={2} as='span' fontSize='xs' color='subtle' lineHeight='1'>
                                                                    <Text as='span' color='black' mr={1}>
                                                                        {ind}.
                                                                    </Text>
                                                                    {opt}
                                                                </Text>
                                                            )
                                                        })}
                                                    </Text>
                                                </>

                                            }
                                            </Stack>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            })}

                        </Accordion>

                    </Card>

                </Stack>    
            </GridItem>
            
            <GridItem colSpan={6}>

                {job && job.applicants > 0 &&
                    <Sidebar job={job} showApplicants={false} />
                }

            </GridItem>



        </Layout>
    )
}