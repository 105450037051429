import {Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';
import sc from "states-cities-db";
import { set } from "date-fns";
import Flag from "react-world-flags";

const COUNTRIES = sc.getCountries();

export default function Details(props) {

    let locations = [
            "Birmingham Women's Hospital",
            "Birmingham Children's Hospital",
            "Mental Health Services",
            "Other"
        ];

    let specialties = [
        'Accident and Emergency',
        'Administration',
        'Anaesthetics',
        'Cardiology',
        'Chaplaincy',
        'Critical Care',
        'Dermatology',
        'Diabetes',
        'Ear, Nose and Throat',
        'Endocrinology',
        'Gastroenterology',
        'General Surgery',
        'Gynaecology',
        'Haematology',
        'Imaging',
        'Infection Control',
        'Maternity',
        'Microbiology',
        'Neonatology',
        'Nephrology',
        'Neurology',
        'Nutrition and Dietetics',
        'Occupational Therapy',
        'Oncology',
        'Ophthalmology',
        'Orthopaedics',
        'Pain Management',
        'Pathology',
        'Pharmacy',
        'Physiotherapy',
        'Plastic Surgery',
        'Radiology',
        'Radiotherapy',
        'Renal',
        'Respiratory',
        'Rheumatology',
        'Speech and Language Therapy',
        'Urology',
        'Vascular Surgery'
    ];

    return (

        <Stack spacing={4}>

            <InputWrapper
                name='type'
                type='select'
                label={props.translate('Why are you contacting us?', props.lang)}
                options={
                    [
                        {
                            value: 'Complaint',
                            label: props.translate('I want to file a complaint', props.lang)
                        },
                        {
                            value: 'Compliment',
                            label: props.translate('I want to file a compliment', props.lang)
                        },
                        {
                            value: 'Suggestion',
                            label: props.translate('I want to file a suggestion', props.lang)
                        },
                        {
                            value: 'Comment',
                            label: props.translate('I want to file a comment', props.lang)
                        }
                    ]
                }
                required={true}
                setValue={props.setValue}
                />
            
            <InputWrapper
                name='location'
                type='select'
                label={props.translate('Which site is this about?', props.lang)}
                options={
                    locations.map((location) => {
                        return {
                            value: location,
                            label: props.lang === 'English' ? location : props.translate(location, props.lang) + " (" + location + ")"
                        }
                    })
                }
                required={true}
                setValue={props.setValue}
                />

            <InputWrapper
                name='team'
                type='select-creatable'
                label={props.translate('Which department(s) is this about?', props.lang)}
                description={props.translate('You can select multiple departments, or add on if it is not listed.', props.lang)}
                options={
                    specialties.map((specialty) => {
                        return {
                            value: specialty,
                            label: props.lang === 'English' ? specialty : props.translate(specialty, props.lang) + " (" + specialty + ")"
                        }
                    })
                }
                required={true}
                setValue={props.setValue}
                />

            <InputWrapper
                name='expectedOutcome'
                type='select'
                label={props.translate('What is your expected outcome?', props.lang)}
                options={
                    [
                        {
                            value: 'Apology',
                            label: props.translate('I want an apology', props.lang)
                        },
                        {
                            value: 'Investigation',
                            label: props.translate('I want an investigation', props.lang)
                        },
                        {
                            value: 'Explanation',
                            label: props.translate('I want an explanation', props.lang)
                        },
                        {
                            value: 'Learning',
                            label: props.translate('I want to ensure this is a learning opportunity', props.lang)
                        },
                        {
                            value: 'Other',
                            label: props.translate('Other', props.lang)
                        },
                        {
                            value: 'None',
                            label: props.translate('None', props.lang)
                        }
                    ]
                }
                required={true}
                setValue={props.setValue}
                />

        </Stack>

    )

}