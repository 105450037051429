import {
    Avatar,
    Circle,
    AvatarGroup,
    Box,SkeletonText,
    Badge, 
    Button,
    Heading,
    Input, 
    Container,
    Flex,

    Checkbox,
    List,
    Image,
    SimpleGrid,
    Grid, GridItem,
    Progress, 
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Divider,
    HStack,
    Link,
    Icon,
    Wrap,
    Tag, Menu, MenuItem, MenuButton, MenuList,
    Tab, TabList, TabPanels, TabPanel, Tabs,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td, TableCaption,
    FormControl, FormLabel, FormHelperText, FormErrorMessage,
  } from '@chakra-ui/react';
  import * as React from 'react';
  import { Link as ReachLink } from "react-router-dom";
  import Layout from '../layout/Layout';
  import { useState, useEffect } from 'react';
import Error from '../../core/utils/Error';
  import Invite from '../../access/Invite';
  import GroupCard from './GroupCard';
import API from '../../core/utils/API';
import moment from 'moment';
import { useLocation } from 'react-router-dom';



export default function Records(props) {

    let [requirementGroups, setRequirementGroups] = useState([]);
    let [userName, setUserName] = useState('');
    let [picture, setPicture] = useState('');
    let [loading, setLoading] = useState(true);
    let [error, setError] = useState(null);



    const location = useLocation();
    const { userUuid } = location.state;
    let mode = 'hr';

    //console.log("RECORD HR FOR USER = ", userUuid);

    useEffect(() => {

        const getRecords = async () => {
        
            setLoading(true);
            setError(null);

            try {

                let url = 'requirementgroups/' + userUuid;
                let response = await API.getItems({url});
                let data = response.data;
        
               // console.log("DATA = ", data);
                //console.log("REQUIREMENT GROUPS = ", data['requirementGroups']);
                setRequirementGroups(data['requirementGroups']);
            
                if (mode === 'hr') {
                    if (data['user']['firstName'] === null && data['user']['lastName'] === null) {
                        setUserName(data['user']['email']);
                    } else {
                        setUserName(data['user']['firstName'] + ' ' + data['user']['lastName']);
                    }
                }
        
      
                    let config = {
                      params: {
                        userUuid: data['user']['uuid']
                    }
                    }
                    
                    let picture = await API.getItems({
                      url: 'users/picture', 
                      config
                    })
                    .then(res => {
                      setPicture(res.data.image);
                    })
                    .catch(err => {
                      console.log("Error getting picture: ", err);
                    });
              

                setLoading(false);
                
            } catch (error) {

                //console.log(error);
                setError(error);
                setLoading(false);

            }

        }

        getRecords();

      }, []);
    



    return (

        <Layout 
            title={"Records for " + userName}
/*            titleSecondLine=*/
            titleSize='title'
            actionContent={
                <Circle border='0px solid black'     padding='8px'
                mt='-28px'
                display='flex'
                justifyContent='center'
                alignItems= 'center'>
                <Avatar size='massive' name={userName} src={picture ? 'data:image/jpeg;base64,' + picture : '/images/avatars/' + Math.floor(Math.random() * 4 + 1) + '.png'} />
                </Circle>
            }

        >

            <GridItem colSpan='6' rowSpan='2' alignItems='flex-start' width='fill-available'>

                {requirementGroups.map((requirementGroup) => (        
                    <GroupCard mode='hr' colSpan={2} rowSpan={1} p={0} requirementGroup={requirementGroup} staleTime={30000}/>
                ))}

            </GridItem>

        </Layout>


    )


}