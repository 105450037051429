import {
    Avatar,
    AvatarGroup,
    Box,SkeletonText,
    Badge, 
    Button,
    Heading,
    Input, 
    Container,
    Flex,
    Checkbox,
    List,
    Image,
    SimpleGrid,
    Grid, GridItem,
    Progress, 
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider,
    HStack,
    Link,
    Icon,
    Wrap,
    Tag, Menu, MenuItem, MenuButton, MenuList,
    Tab, TabList, TabPanels, TabPanel, Tabs,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td, TableCaption,
    FormControl, FormLabel, FormHelperText, FormErrorMessage,
  } from '@chakra-ui/react';
  import { RadioButton, RadioButtonGroup } from '../components/forms/RadioButtonGroup'
  import * as React from 'react';
  import { FiDownloadCloud } from 'react-icons/fi';
  import { MdMailOutline } from 'react-icons/md';
  import { Link as ReachLink } from "react-router-dom";
  import Layout from '../layout/Layout';
  import Select from 'react-select'
  import makeAnimated from 'react-select/animated';
  import { useState, useEffect, useRef } from 'react';
import Error from '../../core/utils/Error';
  import UploadEvidence from '../../records/evidences/UploadEvidence';
  import {MdOutlineExpandMore} from 'react-icons/md';
import DocumentRow from './DocumentRow';  
  import { Dropzone } from '../components/forms/Dropzone';
  import AccessForm from '../../requirements/group/AccessForm';
  import PDFIcon from './pdf.jpg';
  import Invite from '../../access/Invite';
  import Profile from './Profile';
import DocumentProgress from './DocumentProgress';
  import GroupTabPanel from './GroupTabPanel';
  import Modal from '../components/modal/Modal';
  import GroupCard from './GroupCard';

import { useAuth0 } from "@auth0/auth0-react";
import API from '../../core/utils/API';
import {useAPI} from '../../core/utils/useAPI';
import Evidence from '../../records/evidences/Evidence';
import MiniUserCard from '../../users/pages/MiniUserCard';
import moment from 'moment';
import DocumentList from './DocumentList';
import pluralize from 'pluralize';

import LoadingCircle from '../../static/layout/LoadingCircle';

export default function Dashboard() {

  let [requirementGroups, setRequirementGroups] = useState([]);
  let [evidences, setEvidences] = useState([]);
  let [analysing, setAnalysing] = useState([]);
  let [maxAnalysing, setMaxAnalysing] = useState(0);
  let [stale, setStale] = useState([]);
  let [staleTime, setStaleTime] = useState(180); // In milliseconds, this shows as stale after 30secs
  let [loading, setLoading] = useState(true);
  let [loadedOnce, setLoadedOnce] = useState(false);


  //console.log(useAPI('requirementgroups', null));
  /*const {
    loading,
    error,
    refresh,
    data: requirementgroups,
  } =
  const resp = useAPI('requirementgroups', null);
  console.log(resp);

  */


  let updateProgress = useRef(null)
   

  useEffect(() => {

    getData();
  }, []);
  
  const getData = async () => {
    try {
      
      setLoading(true);

      //setLoading(true);
      let url = 'requirementgroups';
      let response = await API.getItems({url});
      let data = response.data;

      //console.log("DATA = ", data);
      setRequirementGroups(data['requirementgroups']);

      
      setEvidences(data['evidences']);
      setAnalysing([]);
      setStale([]);
      let tmpAnalysing = [];
      let tmpStale = [];
    //  console.log(new Date(Date.now() - staleTime));
      data['evidences'].map((evidence) => {
     //   console.log("Evidence", evidence);
        if (evidence.lastTagCheck === null && moment(evidence.createdAt).isAfter(moment().subtract(staleTime, 'seconds'))) {
       //   console.log("Analysing", evidence);
          tmpAnalysing.push(evidence.title);
          //setAnalysing(analysing => [...analysing, evidence.title]);
        } else if (evidence.lastTagCheck === null ) {
         // console.log("Stale", evidence);
           tmpStale.push(evidence.title);
        } else {
         // console.log("OK", evidence);
        }
      });

      setAnalysing(tmpAnalysing);
      setStale(tmpStale);

      if (tmpAnalysing.length > maxAnalysing) {
        setMaxAnalysing(tmpAnalysing.length);
      }
     // console.log("ANALYSING", analysing);
      //console.log("ANALYSING LENGTH === 0", (analysing.length === 0));
      

      setLoadedOnce(true);
      setLoading(false);

    } catch (error) {
     // console.log(error);
      setLoading(false);

    }
  }






  return (

      <Layout
        title="Your Passport"
        actionContent={
          <>
            <Invite 
              type='invite'
              refresh={getData}
              />
            <UploadEvidence
              refresh={getData}
              />
          </>
        }
        >

        <GridItem colSpan='6' rowSpan='2' alignItems='flex-start' width='fill-available'>
          
          
            <Stack spacing={6} width='fill-available' justifyContent='flex-start'>

                 
              {loading && !loadedOnce ? <LoadingCircle /> : null}


              {(!loading || loadedOnce) && requirementGroups.length === 0 &&
                <Card>
                  <Error message='No records found'/>
                </Card>
              }

              <Text display='none'>
                {JSON.stringify(analysing)}
              </Text>

              {analysing.length > 0 && 
              <Box className='gradient-background' p='4px' borderRadius='md' boxShadow='md'>
                    <Stack spacing={4} background='rgba(255,255,255, 1)' p={4} borderRadius='md'>    
                        <Box>
                            <HStack justifyContent='space-between'>
                                <Stack marginTop='-10px' pl={{base: '0px', md: '24px'}} pr='12px'>
                                    <Heading size='smalltitle'>Analysing</Heading>
                                    <Text>We are currently analysing {analysing.length} {pluralize('file', analysing.length)} for you.
                                  
                                      {maxAnalysing != analysing.length &&
                                                <> We've finished analysing {maxAnalysing - analysing.length} {pluralize('file', maxAnalysing - analysing.length)}.
                                                </>
                                            }
                                    </Text>
                                    {analysing.map((docName) => {
                                        <Tag>{docName}</Tag>
                                    })}
                                </Stack>

      <Box className="loading" w='fit-content' pr='32px' >
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Box>

                                <Box 
    position='relative'
    mr='72px!important'
    paddingTop='48px'
    borderBottom='1px solid whitesmoke'
    display='none'
    >
                      <Box className="loader" display='none' >
                        <div class="box box-1">
                          <div class="side-left"></div>
                          <div class="side-right"></div>
                          <div class="side-top"></div>
                        </div>
                        <div class="box box-2">
                          <div class="side-left"></div>
                          <div class="side-right"></div>
                          <div class="side-top"></div>
                        </div>
                        <div class="box box-3">
                          <div class="side-left"></div>
                          <div class="side-right"></div>
                          <div class="side-top"></div>
                        </div>
                        <div class="box box-4">
                          <div class="side-left"></div>
                          <div class="side-right"></div>
                          <div class="side-top"></div>
                        </div>
                      </Box>
                      </Box>
                            </HStack>
                        </Box>
                    </Stack>
                </Box>
              }

              {analysing.length > 0 &&
                <Box display='none'>
                  <DocumentProgress 
                    analysing={analysing} 
                    refreshFunction={getData}
                    staleTime={staleTime}
                    maxAnalysing={maxAnalysing}
                    />
                </Box>
              }

              {requirementGroups.map((requirementGroup) => (
                <GroupCard 
                  mode='me' 
                  colSpan={2} 
                  rowSpan={1} 
                  p={0} 
                  requirementGroup={requirementGroup} 
                  staleTime={staleTime}
                  refresh={getData}
                  />
              ))}

              {!loading &&
                <>
                  <Heading size='smalltitle' pt={6} pb={{base: 2, md: 0}}>Untagged Documents</Heading>
                  <Card p={0}>
                    <DocumentList
                      documents={evidences.filter(x => x.Records.length == 0)}
                      staleTime={staleTime}
                      refresh={getData}
                    />
                  </Card>
                </>
              }
              
              </Stack>
        
        </GridItem>

      </Layout>
  )}

  const Card = (props) => (
    <GridItem
      bg="bg-surface"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      borderRadius="lg"
      rowSpan={1}
      colSpan={2}
      p={4}
      {...props}
    />
  )