
import {Box,ButtonGroup,GridItem, Stack, HStack, Wrap, Button, Heading, Text} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/Input';
import AutoForm from '../../core/models/AutoForm';
import { useNavigate } from 'react-router-dom';
import { Link as ReachLink } from "react-router-dom";
import {Header, Row, Cell} from '../../core/components/lists/List';
import Loading from '../../static/layout/Loading';
import LoadingCircle from '../../static/layout/LoadingCircle';
import pluralize from 'pluralize';
import { set } from 'date-fns';

export default function Forms() {

    const navigate = useNavigate();

    const [forms, setForms] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);


    // Get form
    function getForms() {
        setLoading(true);

        let response = API.getItems({url: 'forms/all'})
        .then((response) => {

            let data = response.data;
            console.log(data);
            setForms(data);
            setLoading(false);
    
        })
        .catch((error) => {
            setError(error);
            setLoading(false);
            
        });
    }

    useEffect(() => {
        getForms();
    }, []);

    function viewForm(uuid) {
        //console.log("View Schedule", uuid);
        navigate('view', {state: {uuid: uuid}});
    }

    return (
        
        <Layout 
            title='Forms'
            description={'These forms can be edited and sent to users'}
            primaryButtonText="Create Form"
            primaryButtonModal={true}
            modalContent={<AutoForm
                url = 'forms/'
                inputs = {[
                    {
                        name: 'title'
                    },
                    {
                        name: 'description'
                    }, 
                ]}
                refresh={
                    getForms
                }
                />}
            >

            <GridItem colSpan={{ base: 12, md: 12 }}>


                {loading ? <LoadingCircle /> : null}

                {forms ?

                    <Box>
            
                        <Header c={3}>
                            <Cell c={2}>
                                Form
                            </Cell>
                        </Header>


                        {forms.map((form) => {
                            return (
                                <Row c={3}
                                    buttons={
                                            <Button 
                                                variant='outline'
                                                onClick={() => {
                                                    viewForm(form.uuid);
                                                }}
                                                >
                                                View
                                            </Button>
                                    }
                                    >
                                    <Cell c={2}>
                                        <Heading size='label'>{form.title}</Heading>
                                        <Text fontSize='xs' color='subtle'>{form.description}</Text>
                                    </Cell>
                                </Row>
                            )
                        })}

                    </Box>


                    :

                    <Box>
                        <Text>
                            You have no forms.
                        </Text>
                    </Box>

                }

            </GridItem>

        </Layout>
        
    )

}