import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Text, SimpleGrid, Flex, Button, Heading, Stack, Input, InputGroup, InputLeftAddon, InputRightAddon, InputRightElement, InputLeftElement, FormControl, FormLabel, FormErrorMessage, FormHelperText, Select, Checkbox, CheckboxGroup, HStack, VStack, Radio, RadioGroup, Textarea, Switch, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Progress, Spinner, useToast } from '@chakra-ui/react';
import API from '../../../core/utils/API';
import Modal from '../../../core/components/modal/Modal';
import FullPageModal from '../../../core/components/modal/FullPageModal';
import moment from 'moment';

export default function OriginalEdit(props) {

    function normaliseHours(time) {
   
        console.log("NORMALISE", time);
        time = time.toString();
        if (time === undefined || undefined === null) {
            return 0;
        }
        // replace . with :
        if (time.indexOf(".") > -1) {
            let decMin = time.split(".")[1] * 60;
            let decHour = time.split(".")[0];
            
            if (decMin < 10) {
                decMin = '0' + decMin;
            }
            if (decHour < 10) {
                decHour = '0' + decHour;
            }
            return decHour + ':' + decMin;

        }
        
        
        if (time == undefined || time == '' || time == '00:00' || time.indexOf(":") == -1) {
            return 0;
        }
        let hours = time.split(':')[0];
        let minutes = time.split(':')[1];
        // ensure format is HH:MM
        if (hours.length == 1) {
            hours = '0' + hours;
        }
        if (minutes.length == 1) {
            minutes = '0' + minutes;
        }
        return hours + ':' + minutes;
    }

    const [updated, setUpdated] = useState({});
    const [formMessage, setFormMessage] = useState('');
    let weekRatio = props.schedule.totalWeeks / 52;
    const [data, setData] = useState({
        basicPay: props.originalSchedule.basicPay,
        weekendPercentage: props.originalSchedule.weekendPercentage,
        weekendPay: props.originalSchedule.weekendPay,
        weekendFrequency: props.originalSchedule.weekendFrequency,
        additionalPay: props.originalSchedule.additionalPay,
        additional: normaliseHours(props.originalSchedule.additional),
        enhancedPay: props.originalSchedule.enhancedPay,
        enhanced: normaliseHours(props.originalSchedule.enhanced),
        totalNonPensionablePay: props.originalSchedule.totalNonPensionablePay,
        totalPay: props.originalSchedule.totalPay,
        payPremia: props.schedule.payPremia,
        londonWeighting: props.schedule.londonWeighting,
        grade: props.schedule.grade,
        fte: props.schedule.fte,
        leave: props.schedule.leave,
        study: props.schedule.study,
        specialty: props.schedule.specialty,
        hee: props.schedule.hee,
        site: props.schedule.site,
        organisation: props.schedule.organisation,
        host: props.schedule.host,
        start: moment(props.schedule.start).format('YYYY-MM-DD'),
        end: moment(props.schedule.end).format('MM/DD/YYYY'),

    });

    console.log("ORIGINAL EDIT", moment(props.schedule.end).format('MM/DD/YYYY'), moment(props.schedule.start).format('YYYY-MM-DD'));

    const [show, setShow] = useState(false);

    function updateData(key, value) {
        let newData = {...data};
        newData[key] = value;
        let newUpdated = {...updated};
        newUpdated[key] = value;
        setData(newData);
        setUpdated(newUpdated);
    }

    function saveData() {

        
        console.log("Submit Questions", props.uuid, updated);

        let url = 'schedules';
        let config = {
            params: {
                ...updated,
                uuid: props.uuid
            }
        };

        let resp = API.patchItems({url, config})
        .then(response => {
            console.log(response);
            setFormMessage("Details saved successfully");
            props.refreshSchedule();
            setShow(false);
        })
        .catch(error => {
            console.log(error);
            setFormMessage("There was an error saving the details");
        });


    }

    return (

        <>
            <Button variant='outline' size='sm' onClick={() => setShow(true)} colorScheme='blue' >
                Edit
            </Button>

            {show &&


                <Modal show={show}
                    title="Original Job Details"
                    onClose={() => {setShow(false); props.refreshSchedule(); setFormMessage("");}}
                    maxW='1200px'
                    footer={
                        <>
                            <Button size='sm' colorScheme='blue' onClick={() => saveData()}>
                                Update
                            </Button>
                        </>
                    }
                    >
                    
                    <>
                    
                    {formMessage != '' &&
                                <Text color='red.500' fontSize='xs' mb={4}>
                                    {formMessage}
                                </Text>
                            }
                    <SimpleGrid minChildWidth='300px' spacing='40px' mb={4} pb={4}>


                            <Stack spacing={2}>
                                <Heading size='label'>
                                    Grade
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    Your grade is used to determine your basic salary, and is used to calculate your pension contributions. This is normally specified in your contract.
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='Grade' />
                                    <Select placeholder='Select your grade' onChange={(e) => updateData('grade', e.target.value)} value={data.grade}>
                                        <option value='FY1'>FY1</option>
                                        <option value='FY2'>FY2</option>
                                        <option value='CT1/2'>CT1/2</option>
                                        <option value='ST3/4/5'>ST3/4/5</option>
                                        <option value='ST6/7/8'>ST6/7/8</option>
                                    </Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='FTE' />
                                    <Input type='number' placeholder='Enter your FTE' onChange={(e) => updateData('fte', e.target.value / 100)} value={data.fte * 100} />
                                    <InputRightAddon children='%' />
                                </InputGroup>
                            </Stack>


                            <Stack spacing={2}>
                                <Heading size='label'>
                                    Annual Leave
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    Staff have 27 days and if you have worked for the NHS for 5 years then you recieve 32 days.
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='Annual Leave' />
                                    <Select placeholder='Select your annual leave' onChange={(e) => updateData('leave', e.target.value )} value={data.leave} >
                                        <option value='27'>27</option>
                                        <option value='32'>32</option>
                                    </Select>
                                    <InputRightAddon children='days' />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='Study Leave' />
                                    <Input type='number' placeholder='Enter your study leave' onChange={(e) => updateData('study', e.target.value  )} value={data.study} />
                                    <InputRightAddon children='days' />
                                </InputGroup>
                            </Stack>



                            <Stack spacing={2}>
                                <Heading size='label'>
                                    Specialty
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    Your specialty
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='Specialty' />
                                    <Input type='text' placeholder='Enter your specialty' onChange={(e) => updateData('specialty', e.target.value)} value={data.specialty} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='HEE Specialty' />
                                    <Input type='text' placeholder='Enter your HEE specialty' onChange={(e) => updateData('hee', e.target.value)} value={data.hee} />
                                </InputGroup>
                            </Stack>

                            <Stack spacing={2}>
                                <Heading size='label'>
                                    Location
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    Your organisation
                                </Text>
                                <InputGroup>
                                    <InputLeftAddon children='Site' />
                                    <Input type='text' placeholder='Enter your site' onChange={(e) => updateData('site', e.target.value)} value={data.site} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='Organisation' />
                                    <Input type='text' placeholder='Enter your organisation' onChange={(e) => updateData('organisation', e.target.value)} value={data.organisation} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='Host' />
                                    <Input type='text' placeholder='Enter your host' onChange={(e) => updateData('host', e.target.value)} value={data.host} />
                                </InputGroup>
                            </Stack>

                            <Stack spacing={2}>
                                <Heading size='label'>
                                    Dates
                                </Heading>
                                <Text color='subtle' fontSize='xs'>
                                    Your start and end dates
                                </Text>

                                <InputGroup>
                                    <InputLeftAddon children='Start' />
                                    <Input type='date' placeholder='Enter your start date' onChange={(e) => updateData('start', e.target.value)} defaultValue={moment(props.schedule.start).format('YYYY-MM-DD')} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='End' />
                                    <Input type='date' placeholder='Enter your end date' onChange={(e) => updateData('end', e.target.value)} defaultValue={moment(props.schedule.end).format('YYYY-MM-DD')} />
                                </InputGroup>
                            </Stack>
    

                    </SimpleGrid>
                    </>

                </Modal>
            }

        </>

    )
}