import React from 'react';
import {useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { Wrap, Stack, Input ,InputRightElement, InputGroup, Button, FormControl, FormLabel, FormHelperText, Checkbox, Radio, RadioGroup } from '@chakra-ui/react';

import Association from '../../core/components/form_elements/Association';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Select from 'react-select';
import API from '../../core/utils/API';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


function capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

function Password(props) {

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
  
    return (
      <InputGroup size='md'>
        <Input
          pr='4.5rem'
          type={show ? 'text' : 'password'}
          {...props.register}
        />
        <InputRightElement width='4.5rem'>
          <Button h='1.75rem' size='sm' onClick={handleClick}>
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
    )
  
}


function RadioCreator(props) {
    const [value, setValue] = React.useState('1')
    return (
      <RadioGroup onChange={setValue} value={value}>
        <Stack direction='row'>
            {props.options.map((option, index) => {
                return (
                    <Radio value={option.value}>{option.label}</Radio>
                )
            })}
        </Stack>
      </RadioGroup>
    )
  }


  <RadioGroup>
  
</RadioGroup>

function DateInput(props) {

    const [date, setDate] = useState();

    if (props.date !== null && props.date !== undefined) {
        setDate(props.date);
    }
    
    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                render={({ onChange, value }) => (
                    <SingleDatepicker
                        date={date}
                        onDateChange={(date) => {
                            onChange();
                            setDate(date);
                        }}
                        selected={date}
                        configs={{
                            dateFormat: 'dd MMMM yyy'
                        }}
                    />
                )} />
            
        </>
    )
}

function NewDateInput(props) {

    const [date, setDate] = useState();

    if (props.date !== null && props.date !== undefined) {
        setDate(props.date);
    }
    
    return (
        <SingleDatepicker 
            selected={date} 
            onChange={(d) => setDate(d)} 
            ref={props.register}
            {...props.reg(props.name, {required: props.required })}
            />
    )
}


export default function AutoEdit(props) {
    
    const [data, setData] = useState("");
    const [formMessage, setFormMessage] = useState("");
    const { register, formState: { errors }, handleSubmit, reset, control } = useForm();
    const onError = (errors, e) => console.log(errors, e);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());

    const onSubmit = (data, e) => {
        setLoading(true);
        console.log(data);
        let response = API.patchItems({url: props.url + "/" + props.uuid, data})
        .then(function (response) {
            console.log(response);
           // window.location.reload(false);
            setFormMessage("Record updated successfully");
            props.onSubmit();

            // Reset inputs
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
           // window.location.reload(false);
            setFormMessage("Error creating record");
            setLoading(false);
           // window.location.reload(false);

        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} >
            
            <Stack spacing="10" pt={6}>
            {formMessage && 
                <BasicBanner message={formMessage} />
            }
            
            {props.inputs.map((item, index) => {
                return (

                    <FormControl className={item.type}>


                        {(item.type === null || item.type === undefined || item.type === 'hidden' ) && 
                            <Input id={item.name} name={item.name}
                                {...register(item.name, {required: item.required })}
                                variant='flushed'
                                placeholder=' '
                                defaultValue={item.value}
                                data-peer
                                />
                        }

                        {(item.type === 'date') &&
                            <>
                            <Controller
                                control={control}
                                name={item.name}
                                render={({ field }) => (
                                    <DatePicker
                                        dateFormat='dd/MM/yyyy'
                                        selected={field.value}
                                        placeholderText={moment(item.value).format('DD/MM/YYYY')}
                                        onChange={(date) => field.onChange(date)}
                                    />
                                )}
                            />   

                            </>           
                        }
                        
                        {(item.type === 'password') && 
                            <Password 
                                register={register(item.name, {required: item.required })}
                                variant='flushed'
                                />
                        }

                        {(item.type === 'checkbox') && 
                            <>
                            { (item.value === true) ?
                                <Checkbox 
                                    name={item.name} 
                                    {...register(item.name, {required: item.required })}
                                    type={item.type} 
                                    defaultChecked
                                    />
                                :
                                <Checkbox 
                                    name={item.name} 
                                    {...register(item.name, {required: item.required })}
                                    type={item.type} 
                                    />
                            }
                            </>
                        }
                        {(item.type === 'radio') && 
                            <RadioCreator options={item.options} value={item.value} />
                        }

                        {(item.type === 'association') &&
                            <Association
                                url = {item.assocation.url}
                                delim = {item.assocation.delim}
                                isMulti= {item.assocation.isMulti}
                                model= {item.assocation.model}
                                label = {item.assocation.label}
                                />
                        }

                        {(item.type === 'select') &&
                            
                            <Select 
                                name={item.name} 
                                {...register(item.name, {required: item.required })}
                                options={item.options}
                            />
                        }


                        <FormLabel variant='floating' htmlFor={item.name}>
                            {(item.label === null || item.label === undefined) ?
                                capitalizeFirstLetter(item.name)
                                :
                                item.label
                            }
                        </FormLabel>
                        
                        {item.helperText !== null && item.type !== undefined &&
                            <FormHelperText>{item.helperText}</FormHelperText>
                        }


                    </FormControl>


                );
            })}

            </Stack>

            <FormControl mt={4} pb={2} textAlign='right'>
                <Wrap spacing={4}>
                {props.footer}
                <Button type="submit" colorScheme='blue'>Save</Button>
                </Wrap>
            </FormControl>

        </form>

    )
}
