import React from 'react';
import Layout from '../../core/layout/Layout';
//import ShowModel from './RequirementTagShowModel';
import ShowModel from '../../core/models/ShowModel';
import {RequirementTagDisplayRecord} from './RequirementTagDisplayRecord';
import AutoForm from '../../core/models/AutoForm';

export default function RequirementTags () {

    return (

            
            <ShowModel
                title="Requirement Tags"
                description="View and manage your requirement tags."
                secondaryButtonText=""
                primaryButtonText="Add Tag"
                modalTitle="Add Tag"
                autoFormUrl='requirementtags/'
                autoFormInputs={[
                    {
                        name: 'tag'
                    },
                    {
                        name: 'description'
                    }, 
                    {
                        name: 'active',
                        type: 'checkbox'
                    },
                    {
                        name: 'automated',
                        type: 'hidden',
                        value: 'false'
                    }
                ]}
                filterBy={[]}
                setTitle={['tag']}
                dataKey='requirementTags'
                url='requirementTags'
                />        



    )   
}

/*
modalContent={<AutoForm
    url = 'requirementtags/'
    inputs = {[
        {
            name: 'tag'
        }
    ]}
    />}
    customDisplayRecord={<RequirementTagDisplayRecord />}

    */
