
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';




export default function SelectWrapper(props) {


    let defaults = [];
    let options = [];

    let newDefaults = [];

    console.log("SELECT", props.defaultValue);

    if (Array.isArray(props.defaultValue)) {
        newDefaults = props.defaultValue;
    } else {
        newDefaults.push(props.defaultValue);
    }



    if (props.options != undefined && props.options.length > 0 ) {

        // check if props.option is an array of strings or objects
        if (typeof props.options[0] === 'string') {

            props.options.forEach((option, i) => {

                options.push({
                    value: option,
                    label: option
                })

            })

        } else {

            options = props.options;

        }


        // Now we can sort alphabetically
        options.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });

        props.options.forEach((option, i) => {

            newDefaults.forEach((defaultVal) => {

                if (defaultVal === option.value) {
                    defaults.push(option);

                }

            })
        })

    }


    return (

        <> 

            {props.type === 'select' && props.isMulti &&


                <Select
                    onChange={(val) => {
                        console.log(props.name, val.map((el) => el.value)); 
                        if (props.setValue) {
                            props.setValue(props.name, val.map((el) => el.value));
                        }
                    }}
                    {...props}
                    defaultValue={defaults}
                    options={options}

                    />


            }

            {props.type === 'select' && props.isMulti === undefined &&


                <Select

                onChange={(val) => {console.log(props.name, val); props.setValue(props.name, val.value);}}
                {...props}
                defaultValue={defaults}
                className='select'
                options={options}

                    />


            }
            { props.type ==='select-creatable' &&
                <CreatableSelect

                    {...props}
                    isMulti

                    onChange={(val) => {console.log(props.name, val.map((el) => el.value)); props.setValue(props.name, val.map((el) => el.value));}}
                    options={options}
                    />
            }
        
        
        </>

    )

}