import React from 'react';
import { SimpleGrid, GridItem, ButtonGroup, Box } from '@chakra-ui/react';

let spacing = 4;
let gap = 4;
let pl = 4;
let pr = 4;
let p = 4;
let fontSize = 'sm';
let bg = 'white';
let boxShadow = 'md';
let borderBottom = '1px solid whitesmoke';

export function Header(props) {

    let bp = props.bp ? props.bp : 'md';
    let cols = props.columns ? props.columns : props.c ? props.c : 1;
    
    return (

        <SimpleGrid columns={{ base: 1, md: cols }}
            spacing={spacing}
            display={{base: 'none', md: 'grid'}} 
            borderBottom='1px solid black'
            gap={gap} p={p}
            pl={pl}
            pr={pr}
            pb={2}
            fontWeight={600}
            {...props}
            >
            {props.children}
            {props.buttons === 'hide' ?
                <></>
                :
                <Cell>
                    Actions
                </Cell>
            }

        </SimpleGrid>
    )

}


export function Row(props) {


    let bp = props.bp ? props.bp : 'md';
    let cols = props.columns ? props.columns : props.c ? props.c : 1;
    
    return (

        <SimpleGrid columns={{ base: 1, md: cols }} 
            spacing={spacing}
            borderBottom={borderBottom}
            bg={props.bg ? props.bg : bg}
            gap={gap} p={p}
            fontSize={{base: fontSize}}
            boxShadow={boxShadow}
            pl={pl} pr={pl}
            {...props}
            >

            {props.children}

            {props.buttons !== 'hide' &&
                (
                    (props.buttons !== undefined && props.buttons !== null)
                    || 
                    (props.actionContent !== undefined && props.actionContent !== null)
                ) &&
                <GridItem colSpan={{ base: 1, md: 1 }}>
                    <ButtonGroup size="xs" variant="outline" colorScheme='blue'>
                        {props.buttons}
                    </ButtonGroup>
                </GridItem>
            }

        </SimpleGrid>


    )

}


export function Cell(props) {

    let bp = props.bp ? props.bp : 'md';
    let cols = props.columns ? props.columns : props.c ? props.c : 1;
    
    return (

        <GridItem colSpan={{ base: 1, md: cols }}>
            {props.children}
        </GridItem>
    )

}