import React, { useState, useEffect } from 'react';
import {Box, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link } from '@chakra-ui/react';
import ScheduleUpload from "./ScheduleUpload";
import ScheduleQuestions from "./ScheduleQuestions";
import Splash from "../../static/layout/Splash";

import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
export default function ScheduleProcess(props) {

    
    const { state } = useLocation();
    // Get uuid passed as a state prop
    console.log("props", state);


    let stagePrep = state ? state.stage : null;
    let uuidPrep = state ? state.uuid : null;



    const [stage, setStage] = useState(stagePrep ? stagePrep : 1);
    const [uuid, setUUID] = useState(uuidPrep ? uuidPrep : null);

    const navigate = useNavigate();

    function progressStage() {
        console.log("progressing stage", stage, stage + 1);
        setStage(stage + 1);

  
    }


    useEffect(() => {

        console.log("stage", stage);
        if (stage == 2) {

            // redirect to schedule page
            console.log("redirecting to schedule page with uuid as a prop");

            navigate("/pay/schedule/", { state: { uuid: uuid, showQuestions: true } });

        }

    }, [stage]);



    return (
        
        <Splash>

            {stage === 1 && uuid === null && <ScheduleUpload progressStage={progressStage} setUUID={setUUID} />}

            {stage === 2 && uuid !== null && 
                <Box bg='white' boxShadow='md' borderRadius='md' w='100%' p={4} w='400px' minH='300px'>
                    <Heading size='label' fontSize='md'>
                        Schedule Questions
                    </Heading>
                    <ScheduleQuestions progressStage={progressStage} uuid={uuid} />
                </Box>
            }

            <Text display='none' fontSize='xs' color='subtle'>
                {uuid}
            </Text>

        </Splash>

    );

}