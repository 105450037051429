import {Box, Text, Button, Link} from '@chakra-ui/react';
import LoginButton from './LoginButton';
import { useAuth0 } from "@auth0/auth0-react";
import LoginPanel from './LoginPanel';
import { Link as ReachLink } from 'react-router-dom';
import Splash from '../static/layout/Splash';
export const PageBlocked = (props) => {
    const { loginWithRedirect } = useAuth0();

    //        {loginWithRedirect()} // was below in return functoin
    return (
        <Splash>
            <Text fontSize='lg' mb={4} textAlign='center'>
                {props.message ? props.message : "You need to log in to view this page."}
            </Text>
            {props.button &&

                <>
                    {props.buttonAction.includes('mailto:') ?
                        <a target='_blank' href={props.buttonAction}>
                            <Button colorScheme='blue'>
                                {props.button}
                            </Button>
                        </a>
                        :
                        <Link as={ReachLink} to={props.buttonAction}>
                            <Button colorScheme='blue'>
                                {props.button}
                            </Button>
                        </Link>
                    }
                </>
            }
        </Splash>
    )
}   