import { Button, Divider, InputGroup, InputLeftElement, Input, Flex, ButtonGroup, Icon, Container, Text } from '@chakra-ui/react';
import { Outlet } from "react-router-dom";
import {MdSearch} from 'react-icons/md';
import { NavLink } from "react-router-dom";

export default function Admin() {

    return (

      <>
            <Container py="3" >
              <Flex justify="space-between" justifyContent='flex-end'>
                <ButtonGroup variant="ghost" spacing="1">
                  <NavLink to='permissions' end className={({ isActive }) => isActive ? 'activeNavLink' : undefined } >
                    <Button>Permissions</Button>
                  </NavLink>
                  <NavLink to='organisations' end className={({ isActive }) => isActive ? 'activeNavLink' : undefined } >
                    <Button>Organisations</Button>
                  </NavLink>
                  <NavLink to="group" end className={({ isActive }) => isActive ? 'activeNavLink'  : undefined } >
                     <Button>Records</Button>
                  </NavLink>
                  <NavLink to="requirements" end className='hide' >
                    <Button>Requirements</Button>
                  </NavLink>
                  <NavLink to="requirementtags"  end className='hide'>
                    <Button>Tags</Button>
                  </NavLink>
                  <NavLink to="users" end className={({ isActive }) => isActive ? 'activeNavLink' : undefined } >
                    <Button>Users</Button>
                  </NavLink>
                  <NavLink to="usage" end className={({ isActive }) => isActive ? 'activeNavLink'  : undefined } >
                     <Button>Usage</Button>
                  </NavLink>
                  <NavLink to="encryption" end className={({ isActive }) => isActive ? 'activeNavLink'  : undefined } >
                     <Button>Encryption</Button>
                  </NavLink>
                  <NavLink to="forms" end className={({ isActive }) => isActive ? 'activeNavLink'  : undefined } >
                     <Button>Forms</Button>
                  </NavLink>
                  <NavLink to="attributes" end className={({ isActive }) => isActive ? 'activeNavLink'  : undefined } >
                     <Button>Attributes</Button>
                  </NavLink>
                  <NavLink to="emailtemplates" end className={({ isActive }) => isActive ? 'activeNavLink' : undefined } >
                    <Button>Emails</Button>
                  </NavLink>
                </ButtonGroup>
              </Flex>
            </Container>

        <Outlet/>
        
      </>
    )
  }