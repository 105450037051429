import React, { useState, useEffect } from 'react';
import {Box, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table } from '@chakra-ui/react';
import { MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";

function Mathematical(props) {

    return (

        <Box pr={2}>
            {props.icon ?
                <Icon as={props.icon} boxSize='32px' ml='-2' mt={1} mb={-1} color={props.color}/>
            :
                <Text fontSize='32px'>
                    {props.value}
                </Text>
            }
        </Box>

    )
}


export default function Group(props) {

    let pt = props.pt ? props.pt : 'inherit';
        

    return (

        <Box w='fit-content' pt={pt}>
            <Heading fontSize='1.5em' fontWeight="bold" pb={0} lineHeight='2em'>
                {props.title}
            </Heading>
                {props.explaination && 
                    <Text color='subtle' fontSize='md' maxW='700px' pb={2}>
                        {props.explaination}
                    </Text>
                }
            <Wrap spacing={4} p={1}>

                <Box w='fit-content' bg='white' p={props.p ? props.p : 4} pt={0} borderRadius='md' boxShadow='md' mb={12} mt={4}>
                    

                    <Wrap spacing={4} pt={4}>

                        {props.children}

                    </Wrap>

                </Box>

                {props.addendumBlock}
                
            </Wrap>
        </Box>

    )

}



function roundtodp(number, dp = 0) {
    return Math.round(100 * number) / 100;
}

function currency(number) {

    number = roundtodp(number, 2);

    // Add commas to thousands
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Check if only one figure after decimal point
    let numberSplit = number.split(".");
    
    if (numberSplit.length === 1) return number;

    if (numberSplit[1].length === 2) return number;

    return numberSplit[0] + '.' + numberSplit[1] + '0';

}
