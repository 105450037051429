import {Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';

export default function Behalf(props) {


    return (


        <Stack spacing={4} >

            <InputWrapper
                name='behalf'
                type='select'
                label={props.translate('Are you filing this complaint on behalf of someone else?', props.lang)}
                options={
                    [
                        {
                            value: 'Yes',
                            label: props.translate('Yes', props.lang)
                        },
                        {
                            value: 'No',
                            label: props.translate('No', props.lang)
                        }
                    ]
                }
                required={true}
                setValue={props.setValue}
                />

                <InputWrapper
                    name='nhs'
                    type='input'
                    label={props.translate('NHS Number', props.lang)}
                    required={true}
                    setValue={props.setValue}
                    />
                
                <InputWrapper
                    name='mrn'
                    type='input'
                    label={props.translate('Hospital Number', props.lang)}
                    required={true}
                    setValue={props.setValue}
                    />
                
                <InputWrapper   
                    name='pDOB'
                    type='date'
                    label={props.translate('Date of Birth', props.lang)}
                    required={true}
                    setValue={props.setValue}
                    />

                <InputWrapper
                    name='pFirstname'
                    type='input'
                    label={props.translate('Firstname', props.lang)}
                    required={true}
                    setValue={props.setValue}
                    />
                <InputWrapper
                    name='pLastname'
                    type='input'
                    label={props.translate('Lastname', props.lang)}
                    required={true}
                    setValue={props.setValue}
                    />
                <InputWrapper
                    name='pEmail'
                    type='input'
                    label={props.translate('Email', props.lang)}
                    required={true}
                    setValue={props.setValue}
                    />
                <InputWrapper
                    name='pPhone'
                    type='phone'
                    label={props.translate('Phone', props.lang)}
                    required={true}
                    setValue={props.setValue}
                    />
                
                <Box pt={4}>
                    <Heading size='label' color='blue.500'>
                        {props.translate("Consent is required", props.lang)}
                    </Heading>
                    <Text color='blue.500'>
                        {props.translate("We need consent from the person you are filing this complaint on behalf of, we will contact them to confirm this.", props.lang)}
                    </Text>
                </Box>

                
            <InputWrapper
                name='pAware'
                type='select'
                label={props.translate('Is the patient aware of this submission?', props.lang)}
                options={
                    [
                        {
                            value: 'Yes',
                            label: props.translate('Yes', props.lang)
                        },
                        {
                            value: 'No',
                            label: props.translate('No', props.lang)
                        }
                    ]
                }
                required={true}
                setValue={props.setValue}
                />

    
        </Stack>


    )

}