

// This will be a wrapper for any pages specific to an organisation
// We will instigate the addOrganisationUuidInterceptor from AxiosConfig, and pass it the organisationUuid

import React, { useEffect, useState } from 'react';
import MFAWrapper from '../../auth/cognito/components/MFAWrapper';


import { useLocation, useNavigate } from 'react-router-dom';
import { addOrganisationUuidInterceptor } from '../../core/utils/AxiosConfig';
import BasicBanner from '../../core/components/banners/BasicBanner';
import { Outlet } from 'react-router-dom';
import {Text} from '@chakra-ui/react'; 
import OrganisationLanding from '../../organisations/Landing';
import  secureLocalStorage  from  "react-secure-storage";

// Need to create a context for the organisation, so that we can pass the organisationUuid to children
// This will be used to set the organisationUuid in the AxiosConfig
import { createContext } from 'react';
import { set } from 'date-fns';
export const OrganisationContext = createContext(); //({ isTokenSet: false });


export default function OrganisationInterceptor(props) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [organisation, setOrganisation] = useState({});


    useEffect(() => {

        // Get from secureLocalStorage
        let uuid = secureLocalStorage.getItem('organisationUuid');
        let title = secureLocalStorage.getItem('organisationTitle');
        let roles = secureLocalStorage.getItem('organisationRoles');
        let permissions = secureLocalStorage.getItem('organisationPermissions');
        let count = secureLocalStorage.getItem('organisationCount');

        if (uuid) {
            setOrganisation({uuid, title, roles, permissions, count});
            console.log("Found organisation uuid", uuid);
            addOrganisationUuidInterceptor(uuid)    
            //setLoading(false);
            
        } else {
            console.log("No organisation uuid found");
            //navigate('/admin/organisations');
        }

    }, []);
  
    useEffect(() => {
        // Check if we have an organisation, if not, redirect to the organisation select page
        setLoading(false);
    }, [organisation]);

 
    return (
        <>
            {error &&
                <Text color='red'>
                    {JSON.stringify(error)}
                </Text>
            }
            {!loading &&

                <OrganisationContext.Provider 
                    uuid={organisation.uuid}
                    title={organisation.title}
                    permissions={organisation.permissions}
                    roles={organisation.roles}
                    count={organisation.count}
                    value={{
                        uuid: organisation.uuid,
                        title: organisation.title,
                        permissions: organisation.permissions,
                        roles: organisation.roles,
                        count: organisation.count
                    }} 
                    >
                            <OrganisationLanding test={props.test} hideNav={props.hideNav}>
                                <Outlet/>
                            </OrganisationLanding>
                </OrganisationContext.Provider>
            }
        </>
    )
}