import NavBar from './NavBar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { Flex,Box, useDisclosure } from '@chakra-ui/react';
import React, {useState, useEffect} from 'react';
import MessagingWindow from '../../my/messages/MessagingWindow';
import Notifications from '../../my/notifications/Notifications';


export default function Layout(props, { children }) {

  let [show, setShow] = useState(false);
  let [sidebarContent, setSidebarContent] = useState(null);

  let toggleSidebar = (mode) => {
    setSidebarContent(mode);
    //console.log("TOGGLE SIDEBAR");
    setShow(!show);
  }


  return (
    <Flex alignItems='center' w='100%'>
      <Box w='100%'  mr={show ? '400px' : '0px'}  transition='0.2s margin ease' >
        {props.hideNav !== true &&
          <Box id='header' 
            zIndex='110'
            position='fixed'
            top='0'
            left='0'
            right='0'
            bg='bg-canvas'
            // Add box shadow if href contains 'organisation'
            boxShadow={(window.location.href.includes('organisation') || window.location.href.includes('orgtest') ) ? 'md' : 'none'}
            >
            <NavBar toggleSidebar={toggleSidebar} test={props.test} />
          </Box>
        }
        <Box id='main' zIndex='1' height='100vh' mt='0px' pt={props.hideNav ? '0px' : '70px'} 
          
          >
          <Outlet/>
        </Box>
      </Box>
      {show &&
        <Box transition='0.2s right ease' position='fixed' top='0' bottom='0' right={show ? '0' : '-400px'} minW='400px' boxShadow='lg' bg='gray.100' borderLeft='1px solid gainsboro' h='100vh' overflowY='scroll'>
          {sidebarContent === 'messages' &&
            <MessagingWindow toggleSidebar={toggleSidebar} />
          }
          {sidebarContent === 'notifications' &&
            <Notifications toggleSidebar={toggleSidebar} />
          }
        </Box>
      }
    </Flex>  
  );
}