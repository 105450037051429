import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Box,
    Wrap,
    Icon,
    Text,
    HStack
    
  } from '@chakra-ui/react'
  import React, { useState } from 'react';
import MFAWrapper from '../../../auth/cognito/components/MFAWrapper';

import { MdClose } from 'react-icons/md';


function ConditionalWrapperComponent(props) {

    return (

        <>
          {props.MFA ?
            <MFAWrapper
              onClose={props.onClose}
              >
              {props.children}
            </MFAWrapper>
            :
            <>
              {props.children}
            </>
          }
        </>
      )
    }
    

  export default function CustomModal(props) {

    // Cannot have maxW below 500px because of MFA reasons
    // MFAWrapper will override the maxW to 500px
    // MFAWrapper will also override the minW to 500px

    let maxW = props.maxW;

    if (maxW != undefined && maxW < 500) {
      console.error('CustomModal maxW cannot be less than 500px');
      maxW = 500;
    }


    return (

        <Modal isOpen='true' p={4} 
          w={props.w}
          >
          <ModalOverlay />
          <ModalContent ml={4} mr={4} border='solid black 0px' maxW={maxW}
            >
          <ConditionalWrapperComponent
            MFA={props.MFA}
            onClose={props.onClose}
          >
            <Icon as={MdClose} onClick={props.onClose} position='absolute' top='0' right='0' m={4} cursor='pointer' _hover={{color: 'red.500'}} />
            {props.title != undefined &&
              <ModalHeader mb={0}>{props.title}
              {props.titleSecondLine != undefined &&
                <>
                  <br/>
                  <Text as='span' lineHeight='1.4em'>{props.titleSecondLine}</Text>
                </>
                }

              </ModalHeader>
            }
            <ModalBody p={props.bodyPadding} >
              {props.children}
            </ModalBody>
            {props.hideFooter !== true &&
              <ModalFooter>
                <Flex justifyContent='space-between' width='fill-available'>
                  {(props.hideClose === false || props.hideClose === undefined ) &&
                    <Button size='sm' alignSelf='flex-start' variant='ghost' colorScheme='gray' onClick={props.onClose}>Close</Button>
                  }
                  {props.hideClose === true &&
                    <Box></Box>
                  }
                  <HStack spacing={4}>
                  {props.footer}
                  {props.actionContent}
                  {props.submitButton != undefined &&
                    <Button size='sm' alignSelf='flex-end' colorScheme='brand' onClick={(e) => {e.target.closest('.chakra-modal__content').querySelector("form button[type='submit']").click();}}>
                      {props.submitButton.text}
                    </Button>
                  }
                  </HStack>
                </Flex>
              </ModalFooter>
            }
            
          </ConditionalWrapperComponent>


          </ModalContent>

        </Modal>
  
    )
  }

  