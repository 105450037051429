import {
    Box,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Link,
    Skeleton,
    Stack,
    useColorModeValue,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { FaArrowRight } from 'react-icons/fa'
  import Features from './Features';
  import Testimonial from './Testimonial';
import Hero from './Hero';
import Logos from './Logos';
import Footer from '../layout/Footer';
import Diagram from './Diagram';
import Overview from './Overview';

import PyrexiaDivider from '../layout/PyrexiaDivider';

  export default function Home() {
    return (
      <>
    <Hero />
    <PyrexiaDivider />
    <Features />
    <PyrexiaDivider />

    <Overview />
    <PyrexiaDivider />

    <Diagram />

    <PyrexiaDivider />
    
    <Logos />
    
    <Footer />

    </>
  )
        }