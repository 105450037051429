import {
    Avatar,
    Box,
    Button,
    Image,
    Container,
    Heading,
    HStack,
    Icon,
    Link,
    SimpleGrid,
    Stack,
    Text,
  } from '@chakra-ui/react'
  import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
  import { members } from './data.js'

  import Ali from './Ali.jpeg';
  import Oliver from './Oliver.jpeg';

  export default function Team() {

  return (
    <Box display='none'>
      <Container
        py={{
          base: '16',
          md: '24',
        }}
      >
      <Stack
        spacing={{
          base: '4',
          md: '5',
        }}
      >
        <Heading size='title' mb={24}>
          Meet our team
        </Heading>
        <Text
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          color="muted"
          maxW="3xl"
          display='none'
        >
          Jelly brownie candy dessert lemon drops marshmallow pastry.
        </Text>
      </Stack>
        <Stack
          spacing={{
            base: '12',
            xl: '24',
          }}
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Image  />
          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
            }}
            columnGap={{
              base: "8",
              md: '28',
            }}
            rowGap={{
              base: '10',
              lg: '12',
            }}
            flex="1"
          >
            
            <Stack
                key='Ali'
                spacing={{
                  base: '4',
                  md: '5',
                }}
                direction="row"
              >
                <Avatar
                  src='./ali.jpg'
                  boxSize={{
                    base: '16',
                    md: '20',
                  }}
                />
                <Stack spacing="4">
                  <Stack>
                    <Box>
                      <Text fontWeight="medium" fontSize="lg">
                        Dr Ali Jawad
                      </Text>
                      <Text color="accent">Founder / CEO</Text>
                    </Box>
                    <Text color="muted"></Text>
                  </Stack>
                  <HStack spacing="4" color="subtle">
                      <Link href="https://www.linkedin.com/in/ali-jawad-ba386b46/" target="_blank" key='oliver-linkedin'>
                        <Icon as={FaLinkedin} boxSize="5" />
                      </Link>
                      <Link href="https://twitter.com/aj_jawad" target="_blank"  key='oliver-twitter'>
                        <Icon as={FaTwitter} boxSize="5" />
                      </Link>
                  </HStack>
                </Stack>
              </Stack>


              <Stack
                key='Oliver'
                spacing={{
                  base: '4',
                  md: '5',
                }}
                direction="row"
              >
                <Avatar
                  src='oliver.jpg'
                  boxSize={{
                    base: '16',
                    md: '20',
                  }}
                />
                <Stack spacing="4">
                  <Stack>
                    <Box>
                      <Text fontWeight="medium" fontSize="lg">
                        Dr Oliver Trampleasure
                      </Text>
                      <Text color="accent">Founder / CTO</Text>
                    </Box>
                    <Text color="muted"></Text>
                  </Stack>
                  <HStack spacing="4" color="subtle">
                      <Link href="https://www.linkedin.com/in/olivertrampleasure/" target="_blank"  key='oliver-linkedin'>
                        <Icon as={FaLinkedin} boxSize="5" />
                      </Link>
                      <Link href="https://twitter.com/ojtramp" target="_blank"  key='oliver-twitter'>
                        <Icon as={FaTwitter} boxSize="5" />
                      </Link>
                  </HStack>
                </Stack>
              </Stack>


          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  )
}