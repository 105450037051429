"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var theme_tools_1 = require("@chakra-ui/theme-tools");
var getSolidVariant = function () {
    return {
        bg: 'brand-grey',
        color: 'brand-text-body',
    };
};
var getSubtleVariant = function (props) {
    var theme = props.theme;
    return {
        color: 'accent',
        bg: (0, theme_tools_1.mode)((0, theme_tools_1.transparentize)('brand.500', 0.1)(theme), (0, theme_tools_1.transparentize)('brand.400', 0.1)(theme))(props),
    };
};
exports.default = {
    baseStyle: {
        h: 8,
        minW: 8,
        fontSize: 'sm',
        px: 4,
        pl: 4,
        pr: 4,
        borderWidth: '1px',
        borderRadius: 'full',
    },
    variants: {
        solid: getSolidVariant,
        subtle: getSubtleVariant,
    },
    defaultProps: {
        variant: 'solid',
    },
};
