import React from 'react';
import ShowModel from '../../core/models/ShowModel';

export default function Requirements (props) {

    console.log("LOADING COSTS GROUPS");

    return (
            <ShowModel
                title="Usage"
                description=""
                secondaryButtonText=""
                primaryButtonModal={false}
                autoFormUrl= 'admin/costs'
                filterBy={['type']}
                setTitle={['type']}
                dataKey='costs'
                url='admin/costs'
                />        


    )   
}