import React, { useState, useEffect } from 'react';
import {Box, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table } from '@chakra-ui/react';
import { MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";

import Group from './Group.js';
import Figure from './Figure.js';
import Mathematical from './Mathematical.js';




function roundtodp(number, dp = 0) {

    // Round to the dp specified
    let roundFactor = Math.pow(10, dp);
    number = Math.round(roundFactor * number) / roundFactor;

    return number;
    
}

function currency(number) {

    number = roundtodp(number, 2);

    // Add commas to thousands
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Check if only one figure after decimal point
    let numberSplit = number.split(".");
    
    if (numberSplit.length === 1) return number;

    if (numberSplit[1].length === 2) return number;

    return numberSplit[0] + '.' + numberSplit[1] + '0';

}



export default function ScheduleCalculations(props) {

    let schedule = props.schedule;
    let duties = props.duties;


    return (

        <Stack spacing={4}>


<Group
                        title='Initial Analysis'
                        explaination={'This is our initial analysis and figures extracted from your work schedule. ' +
                                'We can then use this to calculate your final salary after multiple corrections.'
                            }
                        >

                       
                        <Figure
                            heading='Total Hours'
                            value={schedule.totalHours}
                            addendum='hrs'
                            round={true}
                            explaination='The total number of hours'
                            />


                        
                        <Figure
                            heading='Total Enhanced'
                            value={schedule.totalEnhanced}
                            addendum='hrs'
                            round={true}
                            explaination='The total number of enhanced hours'
                            />



                        <Figure
                            heading='Weeks'
                            value={schedule.totalWeeks}
                            explaination='Total for rota before correction'
                            />

                        <Figure
                            heading='Base Salary'
                            value={"£" + currency(schedule.annualSalary)}
                            explaination='The base salary for the grade'
                            />

                    </Group>

                    <Box display='none'>
                    <Group 
                        title='Shifts'
                        explaination={'These are the types of shift we have found in the work schedule.'}
                        >

                        {Object.keys(duties).map((key) => {

                            let shift = duties[key];

                            console.log(key, shift);

                            if (shift.shorthand === '?') return;
                            if (shift.shorthand === 'OFF') return;

                            return (

                                <Figure
                                maxW='fit-content'
                                heading={
                                    <>
                                        {shift.shorthand}
                                        <Circle 
                                            bg={shift.color} 
                                            w='0.8em'
                                            h='0.8em'
                                            display='inline-block'
                                            ml='0.2em'
                                            pt='0.3em'
                                            />
                                    </>
                                }
                                value={shift.name}
                                explaination={shift.start + '-' + shift.end + ', ' + shift.hoursText + ' hrs, ' + shift.enhancedText + ' enhanced'}
                                />

                            )
                        })}

                    </Group>
                    </Box>

                    <Group
                        title='Leave Allowance'
                        explaination={
                            'The leave allowance is set by the length of service, ' +   
                            '32 days for 5 years or more, 27 days for less than 5 years. ' +
                            'Study leave is typically 30 days per year for trainees. '
                        }
                        >

                        <Figure
                            heading='Annual Leave'
                            value={schedule.leave}
                            round={true}
                            addendum='days'
                            explaination={''}
                            extra={true}
                            />

                        
                        <Mathematical
                            icon={MdAdd}
                            />

                        <Figure
                            heading='Study Leave'
                            value={schedule.study}
                            round={true}
                            addendum='days'
                            explaination={''}
                            extra={true}
                            />

                        <Mathematical
                            icon={MdAdd}
                            />

                        <Figure
                            heading='Bank Holidays'
                            value={8}
                            round={true}
                            addendum='days'
                            extra={true}
                            />


                        <Mathematical
                            color='blue.500'
                            value='='
                            />

                        <Figure
                            heading='Total Leave'
                            value={roundtodp(schedule.totalLeave,3)}
                            round={true}
                            addendum='days'
                            highlight={true}
                            extra={true}
                            />      





                    </Group>


                    <Group
                        title='Corrected Weeks'
                        explaination={
                            'Additional hours and enhanced hours are paid due to the intensity of your work, ' + 
                            ' this should be calculated based on your working weeks only, and not also your leave weeks.' +
                            ' We will calculate this for the entire year rather than the length of the rota.'
                        }
                        >

                        <Figure
                            heading='Total Leave'
                            value={roundtodp(schedule.totalLeave,0)}
                            addendum='days'
                            explaination='Total leave entitlement'
                            />

                        <Mathematical
                            value='/'
                            />

                        <Figure
                            heading='Working Days'
                            value='5'
                            explaination='Number of days worked per week'
                            />

                        <Mathematical
                            value='='
                            />

                        <Figure
                            heading='Leave Weeks'
                            value={roundtodp(schedule.totalLeave/5,3)}
                            addendum='weeks'
                            explaination='Total estimated leave entitlement'
                            />

                        <Mathematical
                            icon={MdArrowForward}
                            />

                        <Figure
                            heading='Corrected Weeks'
                            value={roundtodp(schedule.correctedWeeks,3)}
                            highlight={true}
                            explaination={'52 wks - ' + roundtodp(schedule.totalLeave/5,3) + ' leave wks'}
                            />

                    </Group>


                    <Group
                        title='Average Hours'
                        explaination={
                            'Removing the ' + schedule.leaveHours + ' hours per day of leave from the total, ' +
                            'before calculating the average weekly hours. ' +
                            'Additional hours are paid at your basic hourly rate £' + currency(schedule.rate) + '.'
                        }
                        >
                                               
                        <Figure
                            heading='Total Hours'
                            value={schedule.totalHours}
                            addendum='hrs'
                            round={true}
                            currency={false}
                            explaination='Total hours in the rota'
                            extra={true}
                            />

                        
                        <Mathematical
                            value='/'
                            />

                        <Figure
                            heading='Original Weeks'
                            value={schedule.totalWeeks}
                            explaination='Number of weeks in the rota'
                            extra={true}
                            />

                        <Mathematical   
                            value='='
                            />

                        <Figure
                            heading='Weekly Hours'
                            value={roundtodp(schedule.totalHours / schedule.totalWeeks, 3)}
                            round={true}
                            currency={false}
                            addendum='hrs/wk'
                            explaination='Average hours / week in the rota'
                            extra={false}
                            />


                        <Mathematical
                                icon={MdArrowForward}
                                />

                        <Figure
                            heading='Hours per year'
                            value={roundtodp((schedule.totalHours / schedule.totalWeeks) * 52, 3)}
                            explaination={'Average hrs/wk, multiplied by 52'}
                            round={true}
                            /> 

                    </Group>

                    <Group>

                        <Figure
                            heading='Hours per year'
                            value={roundtodp((schedule.totalHours / schedule.totalWeeks) * 52, 3)}
                            explaination={'Hours before leave correction'}
                            /> 

                        <Mathematical
                            value='-'
                            />

                        <Figure
                            heading='Leave Hours'
                            value={roundtodp(schedule.leaveHours * schedule.totalLeave,3)}
                            addendum='hrs'
                            round={true}
                            currency={false}
                            explaination={'Hours spent on leave, ' + schedule.leaveHours + 'hrs/day x ' + schedule.totalLeave + ' days'}
                            extra={true}
                            />

                        <Mathematical
                            value='='
                            />

                        <Figure
                            heading='Corrected Hours'
                            value={roundtodp(((schedule.totalHours / schedule.totalWeeks) * 52) - (schedule.leaveHours * schedule.totalLeave),3)}
                            addendum='hrs'
                            round={true}
                            currency={false}
                            explaination='Corrected total annual hours'
                            extra={true}
                            />

                        <Mathematical
                            value='/'
                            />

                        <Figure
                            heading='Corrected Weeks'
                            value={roundtodp(schedule.correctedWeeks,3)}
                            explaination='Number of weeks after leave correction'
                            />

                        <Mathematical
                            value='='
                            color={'blue.500'}
                            />

                        <Figure
                            heading='Hours'
                            value={roundtodp(schedule.hours,3)}
                            round={true}
                            currency={false}
                            highlight={true}
                            addendum='hrs/wk'
                            explaination='Corrected average hrs/wk rounded up to 15min'
                            />


                    </Group>

                    {schedule.hours > 40 &&

                        <Group>

                            <Figure
                                heading='Hours'
                                value={roundtodp(schedule.hours,3)}
                                round={true}
                                currency={false}
                                addendum='hrs/wk'
                                explaination='Average hrs/wk rounded up to 15min'
                                />

                            <Mathematical
                                value='-'
                                />

                            <Figure
                                heading='Normal hours'
                                value={40}
                                currency={false}
                                addendum='hrs/wk'
                                explaination='Hours in a normal contracted week'
                                />

                            <Mathematical
                                value='='
                                />

                            <Figure
                                heading='Additional Hours'
                                value={roundtodp(schedule.additional,3)}
                                explaination={'After removing the standard 40hrs/wk'}
                                />

                            
                            <Mathematical
                                color='blue.500'
                                icon={MdArrowForward}
                                />

                            <Figure
                                heading='Additional Pay'
                                value={"£" + currency(schedule.additionalPay)}
                                explaination={'Annually, paid at ' + "£" + currency(schedule.rate) + 'ph'}
                                highlight={true}
                                />


                        </Group>

                    
                    }

                    {schedule.hours <= 40 &&
                    
                        <Group>
                            <Figure 
                                heading='Additional Hours'
                                value={0}
                                explaination='No additional hours above 40 hours'
                                />

                            <Mathematical
                                color='blue.500'
                                icon={MdArrowForward}
                                />

                            <Figure
                                heading='Additional Pay'
                                value={"£0"}
                                explaination={''}
                                highlight={true}
                                />

                        </Group>
                    
                    }



                    <Group
                        title='Average Enhanced Hours'
                        explaination={'The average number of enhanced hours worked per week, corrected for leave. ' +
                            'These are paid at your enhanced hourly rate £' + currency(schedule.enhancedRate) + 
                            ', which is 37% of your hourly rate £' + currency(schedule.rate) + '.'
                            }
                        >


                                               
                        <Figure
                            heading='Total Hours'
                            value={schedule.totalEnhanced}
                            addendum='hrs'
                            round={true}
                            currency={false}
                            explaination='Total enhanced hours in the rota'
                            extra={true}
                            />

                        <Mathematical
                            value='/'
                           />
    
                        <Figure
                            heading='Rota Weeks'
                            value={schedule.totalWeeks}
                            explaination='weeks in the rota'
                            extra={true}
                            />
    
                        <Mathematical
                            value='='
                            />
    
                        <Figure
                            heading='Enhanced Hours'
                            value={roundtodp(schedule.totalEnhanced / schedule.totalWeeks, 3)}
                            round={true}
                            currency={false}
                            addendum='hrs/wk'
                            explaination='The average number of hours / week'
                            extra={false}
                            />

                            
                        <Mathematical
                            color='blue.500'
                            icon={MdArrowForward}
                            />

                        <Figure
                            heading='Annual Enhanced Hours'
                            value={roundtodp(52 * (schedule.totalEnhanced / schedule.totalWeeks), 3)}
                            explaination={'Average rota hrs/wk, multiplied by 52'}
                            round={false}
                            highlight={true}
                            extra={true}
                            />


                    </Group>

                    <Group>


                        <Figure
                            heading='Annual Enhanced Hours'
                            value={roundtodp(52 * (schedule.totalEnhanced / schedule.totalWeeks), 3)}
                            explaination={'Average rota hrs/wk, multiplied by 52'}
                            round={false}
                            extra={true}
                            />
                    
                        <Mathematical
                           /* icon={FaDivide}  TOO BOLD*/
                            value='/'
                            />

                        <Figure
                            heading='Corrected Weeks'
                            value={roundtodp(schedule.correctedWeeks,3)}
                            explaination='Number of weeks after leave correction'
                            extra={true}
                            />

                        <Mathematical
                            value='='
                            />

                        <Figure
                            heading='Enhanced Hours'
                            value={roundtodp(schedule.enhanced, 3)}
                            round={false}
                            currency={false}
                            highlight={true}
                            addendum='hrs/wk'
                            explaination='Corrected enhanced hrs/wk rounded up to 15min'
                            extra={false}
                            />

                        <Mathematical
                            color='blue.500'
                            icon={MdArrowForward}
                            />

                        <Figure
                            heading='Enhanced Pay'
                            value={"£" + currency(schedule.enhancedPay)}
                            explaination={'Annualy, paid at £' + currency(schedule.enhancedRate) + 'ph'}
                            highlight={true}
                            extra={true}
                            />

                    </Group>
                    
                    <Group 
                        title='Weekend Frequency'
                        explaination={
                            'Frequency of weekend work is calculated across all weeks, not the corrected. ' +
                            'This dictates the percentage increase linked to your annual salary, ' +
                            ' this is set nationally.'
                        }
                        >


                        <Figure
                            heading='Weekends'
                            value={schedule.totalWeekends}
                            explaination='The total number of weekends worked'
                            extra={true}
                            />

                        <Mathematical
                            value=':'
                            />

                        <Figure
                            heading='Weeks'
                            value={schedule.totalWeeks}
                            explaination='Uncorrected number of weeks'
                            extra={true}
                            />

                        <Mathematical
                            value='='
                            />

                        <Figure
                            heading='Frequency'
                            value={'1:' + roundtodp(schedule.weekendFrequency, 1)}
                            explaination='The weekend frequency'
                            extra={true}
                            />

                        <Mathematical
                            icon={MdArrowForward}
                            />

                        <Figure
                            heading='Supplement'
                            value={schedule.weekendPercentage + "%"}
                            explaination={'of your annual salary'}
                            />

                        <Mathematical
                            icon={MdClose}
                            />

                        <Figure
                            heading='Salary'
                            value={"£" + currency(schedule.annualSalary)}
                            explaination={'Annual salary for ' + schedule.grade + 's'}
                            />

                        <Mathematical
                            color='blue.500'
                            value='='
                            />

                        <Figure
                            heading='Weekend Pay'
                            value={"£" + currency(schedule.weekendPay)}
                            explaination='Additional pay for weekend working'
                            highlight={true}
                            extra={true}
                            />
                        

                    </Group>

        </Stack>


    )

}