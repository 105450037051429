import React from 'react';
import ShowModel from '../../core/models/ShowModel';
import AutoForm from '../../core/models/AutoForm'; 
import API from '../../core/utils/API';

import { useState, useEffect } from 'react';

import { Spacer, Icon, IconButton, Circle, Box, Heading, Text, Flex, Button, Stack, Avatar, AvatarGroup, Divider, HStack, VStack, Wrap, WrapItem, Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton, useColorModeValue, GridItem } from '@chakra-ui/react';
import { MdLockClock, MdLock, MdDeleteSweep, MdDelete, MdOutlineArchive, MdVisibility, MdAutoFixHigh, MdNotificationsNone, MdWarningAmber, MdInbox, MdEdit, MdOutlineExpandMore, MdCheck, MdClose, MdLabelOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { AccountContext } from '../../auth/cognito/components/Accounts';
import Layout from '../../core/layout/Layout';
import Notifications from './Notifications';

export default function NotificationsAll (props) {

    return (
        
        <Layout 
            title='Notifications'
            >

                <GridItem colSpan='12'>
            <Notifications 
                all={true}
                />
                </GridItem>

        </Layout>

    )
    
}
    