import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {setupInterceptors} from '../core/utils/AxiosConfig';
import { AccountContext } from './cognito/components/Accounts';

export const InterceptorContext = createContext(); //({ isTokenSet: false });


const Interceptor = ({ children }) => {
    
    const { getAccessTokenSilently } = useAuth0();
    const { getSession, logout } = useContext(AccountContext);
    const [accessToken, setAccessToken] = useState();
    const [idToken, setIdToken] = useState();

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                let token = '';
                let tmpIdToken = '';
                
                if (process.env.REACT_APP_AUTH_SERVICE === 'AUTH0') {

                    token = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    });
//                    console.log('Interceptor.js - Token Set (' + process.env.REACT_APP_AUTH0_AUDIENCE + ")", (token === null ? 'null' : token.substring(1, 5) + '...'));

                } else if (process.env.REACT_APP_AUTH_SERVICE === 'COGNITO') {
                
                    await getSession().then(session => {
                        //console.log('Session:', session);
                       // console.log('Session ID Token:', session.idToken.jwtToken);
                        token = session.accessToken.jwtToken; // Is this the token we want? Or Access?
                        tmpIdToken = session.idToken.jwtToken;
                    })
                    .catch(err => {
                      //  console.error(err);
                        setAccessToken(null);
                    });

                    setAccessToken(token);
                    setIdToken(tmpIdToken);
                 //   console.log('Interceptor.js - Token Set', (token === null ? 'null' : token.substring(0, 5) + '...'));

                }

                setAccessToken(token);
            } catch (error) {       
                setAccessToken(null);         
               // console.log(error);
            }
        }
        getAccessToken();
    }, [getAccessTokenSilently]);

    if (accessToken) {
       // console.log('Interceptor token', accessToken);
        setupInterceptors(accessToken);
    }

    
    return (
    
        <InterceptorContext.Provider 
            accessTokenSet={!!accessToken}
            value={{ setup: !!accessToken, mode: 'Healthcare Professional 2' }} 
            mode='Healthcare Professional'>

            {!!accessToken ?
              <>
                {children}
              </>
              :
                <>
                {children}
                </>
            }

        </InterceptorContext.Provider>
        
    )
};


function TestInterceptor() {

    const Interceptor = useContext(InterceptorContext);
    
    return (
        <>
        <p>Interceptor Wrapper</p>
        <p>Token Set: {JSON.stringify(Interceptor.setup)} Mode: {Interceptor.mode}</p>
        <p>{JSON.stringify(Interceptor)}</p>
        </>
    )
}

export default Interceptor;