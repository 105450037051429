"use strict";
var tokens_1 = require("../foundations/tokens");

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = {
    lineHeight: '1em',
    borderRadius: 'full',
    borderColor: 'blue.500',
    border: '2px solid',
    ':focus:not(:focus-visible)': {
        boxShadow: 'none',
    },
    textTransform: 'none',
    fontWeight: 'bold',
    _focus: {
        boxShadow: 'none',
    },
    _focusVisible: {
        boxShadow: 'none',
    },
};
var dark = function () { return ({
    color: 'brand-white',
    bg: 'brand-dark',
    _hover: {
        bg: 'brand-light',
    }
}); };
var highlight = function () { return ({
    color: 'brand-white',
    bg: 'brand-accent',
    _hover: {
        bg: 'brand-accent-dark',
    }
}); };
var solid = function (props) { return ({
    color: 'invert',
    bg: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
    _hover: {
        bg: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.900',
        _disabled: {
            bg: 'accent-disabled',
            color: 'on-disabled',
        },
    },
    _active: {
        bg: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.900',
    },
    _disabled: {
        color: 'on-disabled',
        bg: 'accent-disabled',
    },
}); };
var outline = function (props) { return ({
    bg: 'transparent',
    borderColor: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
    color: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
    _before: {
        background: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
    },
    _after: {
        background: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
    },
    _hover: {
        bg: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
        borderColor: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
        color: 'inverted',
        '>svg *, >span>svg *': {
            color: 'inverted',
        },
        _disabled: {
            color: 'disabled',
            borderColor: 'disabled',
            '>svg *, >span>svg *': {
                color: 'disabled',
            },
        },
    },
    _active: {
        color: 'brand-white',
        bg: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.200',
    },
    _disabled: {
        color: 'disabled',
        borderColor: 'disabled',
        '>svg *, >span>svg *': {
            color: 'disabled',
        },
        _hover: {
            color: 'disabled',
            borderColor: 'disabled',
            '>svg *, >span>svg *': {
                color: 'disabled',
            },
        }
    },
}); };
var ghost = function (props) { return ({
    color: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
    bg: 'none',
    borderWidth: '0',
    _hover: {
        _disabled: {
            color: 'disabled',
        },
    },
    _active: {
        color: 'emphasized',
        bg: 'bg-muted',
    },
    _activeLink: {
        color: 'on-accent',
        bg: 'accent',
        '*>svg': {
            color: 'on-accent',
        },
    },
    _disabled: {
        color: 'disabled',
    },
}); };
var link = function () { return ({
    textTransform: 'normal',
    fontWeight: 'normal',
    fontSize: 'md',
    border: 'none',
    borderWidth: '0px !important',
}); };
var primary = function (props) {
    return solid(__assign(__assign({}, props), { variant: 'solid', colorScheme: 'primary' }));
};
var secondary = function (props) {
    return outline(__assign(__assign({}, props), { variant: 'outline', colorScheme: 'primary' }));
};
var variants = {
    primary: primary,
    'primary-on-accent': function () { return ({
        bg: 'on-accent',
        color: 'accent-emphasis',
        _hover: {
            _disabled: {
                bg: 'on-accent',
            },
        },
        _active: { bg: 'on-accent-emphasis' },
    }); },
    secondary: secondary,
    'secondary-on-accent': {
        color: 'on-accent',
        _hover: {
        },
        _active: {
            color: 'on-accent-emphasis',
            bg: 'accent-emphasis',
        },
    },
    solid: solid,
    outline: outline,
    ghost: ghost,
    dark: dark,
    highlight: highlight,
    link: link,
    'outline-on-accent': function () { return ({
        border: '1px',
        borderColor: 'on-accent',
        color: 'on-accent',
        _hover: {
        },
        _active: {
            color: 'on-accent-emphasis',
            bg: 'accent-emphasis',
        },
    }); },
    'ghost-on-accent': function () { return ({
        color: 'on-accent',
        bg: 'bg-accent',
        _hover: {
        },
        _activeLink: {
            color: 'on-accent',
            bg: 'bg-accent-muted',
        },
        _active: {
            bg: 'bg-accent-muted',
        },
    }); },
};
var sizes = {
    xs: {
        h: 6,
        minW: 6,
        fontSize: 'xs',
        px: 2,    
        borderWidth: '1px',
    },
    sm: {
        h: 8,
        minW: 8,
        fontSize: 'sm',
        px: 4,
        borderWidth: '1px'
    },
    md: {
        h: 10,
        minW: 10,
        fontSize: 'md',
        px: 4,
        borderWidth: '2px'
    },
    lg: {
        h: 12,
        minW: 12,
        px: 4,
        fontSize: '21px',
        borderWidth: '2px'
    },
    xl: {
        h: 16,
        minW: 16,
        px: 4,
        fontSize: '21px',
        borderWidth: '2px'
    },
};
exports.default = {
    baseStyle: baseStyle,
    variants: variants,
    sizes: sizes,
};
