import {
    Box,
    Button,
    Circle,
    Heading,
    Img,
    LightMode,
    SimpleGrid,
    Stack,
    Text,
    Image,
    Icon,
    useColorModeValue as mode,
    VisuallyHidden,
    Link,
  } from '@chakra-ui/react'
  import { FaPlay } from 'react-icons/fa'
  import * as Logos from './Brands'
  import HeroImage from './Hero.png';
  import { MdArrowForward } from 'react-icons/md';
  import { useNavigate } from "react-router-dom";

  import CallToAction from '../shared/CallToAction';

  export default function Hero() {
    const navigate = useNavigate();

    return (
      <Box>
        <Box as="section" py="7.5rem">
          <Box
            maxW={{
              base: 'xl',
              md: '7xl',
            }}
            mx="auto"
            px={{
              base: '6',
              md: '8',
            }}
          >
              <Heading size="pagetitle">
                Features.
              </Heading>
              <Heading size='statictagline'>
              <sup>Ø</sup>Pyrexia combines cutting-edge AI, intuitive design, and purpose-built automation to revolutionise healthcare HR. Our extensive features simplify even the most complex administrative processes:
              </Heading>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Digital Staff Passport</Text><br/>
                Centralised, shareable profile lets clinicians easily move credentials between NHS organisations. Enables frictionless mobility.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>AI tools</Text><br/>
                A collection of tools that leverage AI to make administrative tasks faster and easier, saving clinicians time and energy.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>AI Engine</Text><br/>
                Our natural language processing and intelligent OCR instantly extract key details from documents, populating forms automatically. Avoid tedious data re-entry.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Compliance Tracking</Text><br/>
                Proactive alerts ensure licences, certificates, and requirements stay current. Custom reminders based on expiry dates.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Automated Onboarding</Text><br/>
                Streamlines the process of inducting new healthcare professionals, significantly reducing manual data entry and associated errors.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Document Management</Text><br/>
                Store all credentials in one secure place. Digitally sign forms in seconds. Maintain version control.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Communication Tools</Text><br/>
                Secure notifications keeps everyone in sync. Share updates between HR, managers, and employees.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Workflow Automation</Text><br/>
                Programmatically completes repetitive tasks like document routing, data validation, and status alerts.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Reporting & Analytics</Text><br/>
                Provides insightful reports and analytics to track onboarding progress, compliance levels, and other critical HR metrics.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>SLAs and Audit Logs</Text><br/>
                Ensure compliance with service delivery standards. Complete visibility into platform activity.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Access Controls</Text><br/>
                Sophisticated role-based permission model. Control access and actions at a granular level.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Real-Time Updates</Text><br/>
                Allows immediate updates to staff records, ensuring all information is current and accurate.
              </Text>

            </Box>
  
          </Box>


          <Box as='section' bg='#000' color='white' py='7.5rem'>
              <Box
                maxW={{base: 'xl',md: '7xl',}}
                mx="auto"
                px={{base: '6',md: '8',}}
                >
                <Heading size="smalltitle" color='inverted'>
                  We’re bringing the power of automation to everyone.                
                </Heading>
                <Heading size='statictagline' fontSize='22px' mb={12} mt={8} color='inverted'>
                <sup>Ø</sup>Pyrexia is working with organisations around the UK to make HR simpler.
                </Heading>
                <Image src='/images/WeDoThis.png' />
              </Box>
            </Box>


          <CallToAction/>

        </Box>
  
    )
  }