import React, { useEffect, useState } from "react";
import { Divider, Icon, Tooltip, Badge,Tag, WrapItem, Wrap, HStack, Flex, GridItem, Box, Text, Heading, Button, Stack, Center, Image, useColorModeValue, Table, Th, Thead, Tr, Td, Tbody, ButtonGroup, TableCaption, SimpleGrid } from '@chakra-ui/react';
import {MdAdd, MdArrowForward} from 'react-icons/md';

import Group from './schedule/Group.js';
import Figure from './schedule/Figure.js';
import Mathematical from './schedule/Mathematical.js';



function roundtodp(number, dp = 0) {
    return Math.round(100 * number) / 100;
}

function currency(number) {

    number = roundtodp(number, 2);

    // Add commas to thousands
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Check if only one figure after decimal point
    let numberSplit = number.split(".");
    
    if (numberSplit.length === 1) return number;

    if (numberSplit[1].length === 2) return number;

    return numberSplit[0] + '.' + numberSplit[1] + '0';

}

function convertToHours(time) {
    let hours = Math.floor(time);
    let minutes = Math.round((time - hours) * 60);

    // Add a leading zero if minutes is less than 10
    if (minutes < 10) minutes = '0' + minutes;

    // Add a leading zero if hours is less than 10
    if (hours < 10) hours = '0' + hours;

    return hours + ':' + minutes;

}


export default function PayCalculations(props) {

    let correct = true;
    let blockHeading = 'Schedule';
    let blockValue = 'Unknown';
    let blockExplaination = 'No auto check';
    let bgColor = 'gray.500';

    if (props.difference != 0 && props.totalPay != null && props.scheduleTotalSalary != null) {
        correct = false;
        blockExplaination = 'vs schedule of £' + currency(props.scheduleTotalSalary);
        bgColor = 'red.500';
        console.log(props.totalPay, props.scheduleTotalSalary, props.totalPay - props.scheduleTotalSalary);
        let blockFigure = props.totalPay - props.scheduleTotalSalary;
        if (blockFigure > 0) blockValue = '£' + currency(blockFigure);
        if (blockFigure < 0) blockValue = '-£' + currency(blockFigure * -1);
    } else if (props.difference === 0 && props.totalPay != null && props.scheduleTotalSalary != null) {
        blockValue = 'Correct';
        bgColor = 'green.500';
        blockExplaination = 'vs schedule of £' + currency(props.scheduleTotalSalary);
    }

    if (props.difference === 0 && props.totalPay != null && props.scheduleTotalSalary != null) {
        // Already set when defined above
    } else if (props.totalPay > props.scheduleTotalSalary) {
        blockHeading = 'Underpayment';
    } else if (props.totalPay < props.scheduleTotalSalary) {
        blockHeading = 'Overpayment';
    }


    let salaryExplaination = roundtodp(props.fteCalculated * 100, 0) + '% FTE ' + props.grade;

    if (props.baseSalary != null) {
        salaryExplaination = salaryExplaination + " (" + roundtodp(props.fteCalculated * 100, 0) + '% of £' + props.baseSalary + ')';
    }

                        

    return (

        <Group
            title='Pyrexia Pay Calculations'
            explaination='These figures are the correct amount you should be paid for this rota across a year.'
            addendumBlock={
                <Figure
                    maxW='fit-content'
                    boxStyle={true}
                    heading={blockHeading}
                    value={blockValue}
                    explaination={blockExplaination}
                    bgColor={bgColor}
                    />
                }
            >

                <Figure
                    heading='Basic salary'
                    value={'£' + props.annualSalary}
                    explaination={salaryExplaination}
                    />

                <Mathematical
                    icon={MdAdd}
                    />

                <Figure
                    heading='Weekend pay'
                    value={'£' + props.weekendPay}
                    explaination={'1:' + props.weekendFrequency + ' weekends, ' + props.weekendPercentage + '% of basic pay'}
                    />

                <Mathematical
                    icon={MdAdd}
                    />

                <Figure
                    heading='Additional pay'
                    value={'£' + props.additionalPay}
                    explaination={convertToHours(props.additional) + ' hours / week, paid at £' + props.rate + 'ph'}
                    />

                <Mathematical
                    icon={MdAdd}
                    />

                <Figure
                    heading='Enhanced pay'
                    value={'£' + props.enhancedPay}
                    explaination={convertToHours(props.enhanced) + ' hours / week, paid at £' + props.enhancedRate + 'ph'}
                    />

                {props.londonInclude &&
                    <>
                        <Mathematical
                            icon={MdAdd}
                            />

                        <Figure
                            heading='London Weighting'
                            value={'£' + props.londonWeighting}
                            explaination=''
                            />                
                    </>
                }

                {props.payPremiaInclude &&
                    <>
                        <Mathematical
                            icon={MdAdd}
                            />

                        <Figure
                            heading='Pay Premia'
                            value={'£' + props.payPremiaPay}
                            explaination=''
                            />                
                    </>
                }

                <Mathematical
                    value='='
                    color='blue.500'
                    />

                <Figure
                    heading='Annual Pay'
                    value={'£' + currency(props.totalPay)}
                    highlight={true}
                    explaination='Total annual pay'
                    />
                    


        </Group>




    )
}