import React, { useState, useEffect } from 'react';
import {Box, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table } from '@chakra-ui/react';
import { MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";

function Mathematical(props) {

    return (

        <Box pr={2}>
            {props.icon ?
                <Icon as={props.icon} boxSize='32px' ml='-2' mt={1} mb={-1} color={props.color}/>
            :
                <Text fontSize='32px'>
                    {props.value}
                </Text>
            }
        </Box>

    )
}


export default function Figure(props) {

    let maxW = props.maxW ? props.maxW : '140px';

    if (props.currency) {
        props.value = currency(props.value);
    } else if (props.round) {
        props.value = roundtodp(props.value);
    }

    let fontColor = 'black';
    let fontSubtleColor = 'subtle';
    if (props.highlight) fontColor = 'blue.500';
    if (props.highlight) fontSubtleColor = 'blue.300';
    if (props.bgColor) fontColor = 'white';
    if (props.bgColor) fontSubtleColor = 'white';

    return (

        <> 
            {props.boxStyle ?

                <Box w={maxW} bg={props.bgColor} boxShadow='md'  p={4} borderRadius='md'>
                    <Heading size="label" fontWeight="bold" pt={2} color={fontColor}>
                        {props.heading}
                    </Heading>
                    <Heading size="label" fontSize='2xl' fontWeight="bold" color={fontColor}>
                        {props.value} <Text as='span' color={fontSubtleColor} fontSize='sm'>{props.addendum}</Text>
                    </Heading>
                    <Text color={fontSubtleColor} fontSize='sm'>
                        {props.explaination}
                    </Text>
                </Box>

            :
                <Box w='140px' bg={props.bgColor}>
                    <Heading size="label" fontWeight="bold" pt={2} color={fontColor}>
                        {props.heading}
                    </Heading>
                    <Heading size="label" fontSize='2xl' fontWeight="bold" color={fontColor}>
                        {props.value} <Text as='span' color={fontSubtleColor} fontSize='sm'>{props.addendum}</Text>
                    </Heading>
                    <Text color={fontSubtleColor} fontSize='sm'>
                        {props.explaination}
                    </Text>
                </Box>


            }
        </>

    )
}


function roundtodp(number, dp = 0) {
    return Math.round(100 * number) / 100;
}

function currency(number) {

    number = roundtodp(number, 2);

    // Add commas to thousands
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Check if only one figure after decimal point
    let numberSplit = number.split(".");
    
    if (numberSplit.length === 1) return number;

    if (numberSplit[1].length === 2) return number;

    return numberSplit[0] + '.' + numberSplit[1] + '0';

}
