import React, { useState } from 'react';
import { Link as ReachLink } from "react-router-dom";

import API from '../../core/utils/API';

import Moment from 'react-moment';
import 'moment-timezone';

import { ButtonGroup, Tag, TagLeftIcon, IconButton, HStack, Tooltip, Spinner, Table, Icon, Thead, Th, Tr, Td, Tbody, Heading, VStack, Checkbox, WrapItem, useDisclosure, Collapse,  Divider, SkeletonText, Grid, GridItem, Flex, Button, List, ListItem, Badge, BadgeLeftIcon, Wrap, Box, Stack, Text } from '@chakra-ui/react';
import {MdManageSearch, MdOutlineErrorOutline, MdOutlineAttachFile,  MdLabel, MdOutlineLabel, MdCheck, MdClose, MdAdd, MdAttachment, MdOutlineWarning, MdExpandLess, MdExpandMore, MdCheckCircle, MdHelp} from "react-icons/md";

import FullPageModal from '../../core/components/modal/FullPageModal';
import { Document, Page, pdfjs } from 'react-pdf';
import ShowEvidence from './ShowEvidence';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



export class EvidenceDisplayRecord extends React.Component {



    constructor(props) {
      super(props);
      this.state = { currState: true }
      this.state = {
          isOpen: false
        };
      }

  
      
  
      render() {
//        const { isOpen, onToggle } = useDisclosure()

        const getRequirementGroups = (records) => {

            console.log(records);
            var requirementGroups = [];

            records.forEach(function(record) {
            
                let reqGroupings = record['Requirement']['RequirementGroupings']
                
                reqGroupings.forEach(function(reqGrouping) {
            
                    if (reqGrouping['RequirementGroup']['title'] != null) {
                        requirementGroups.push({
                            title: reqGrouping['RequirementGroup']['title'],
                            uuid: reqGrouping['RequirementGroup']['uuid']
                        });
                    }
                
                });

            });

            // Remove duplicates
            requirementGroups = Array.from(new Set(requirementGroups.map(JSON.stringify)), JSON.parse);
            return requirementGroups;

        }

        let requirementGroups = getRequirementGroups(this.props.data['Records']);

        return (


                
        <Stack>
            <Flex>
                
                <Box w='-webkit-fill-available'>
                    <Flex justifyContent='space-between'>
                        <Box>
                            <Stack spacing={4} direction={{base: 'column', md: 'row'}}>

                                <ShowEvidence 
                                            evidenceUuid={this.props.data['uuid']}
                                            filePath={this.props.data['filePath']}
                                            //requirementGroupUuid={props.requirementGroupUuid}
                                        // onClose={props.onClose}
                                        />

                                <Stack>
                            
                                    <Heading as='h6'  mt={1} mb={2} fontSize='md' lineHeight='1.4em' color='emphasized' mb={2}>
                                        {this.props.data["title"]}
                                    </Heading>

                                    
                                    <Wrap spacing={2} mt={0}>

                                    
                                        {this.props.data['Records'].length > 0 && 
                                        
                                            <ButtonGroup size='sm' isAttached variant='outline'>


                                                {requirementGroups.map((item, i) => (
                                                    <Button p={2} as={ReachLink} to={'/records/group/me/' + item["uuid"]} textTransform='none'>
                                                        <Icon as={MdAttachment} color='primary' mr={2} />
                                                        {item['title']}
                                                    </Button>
                                                ))}

                                                {this.props.data['Records'].length > 0 &&
                                                    <Button p={2} as={ReachLink} onClick={() => this.setState({isOpen: !this.state.isOpen})} textTransform='none'>
                                                        <Icon as={MdLabel} color='primary' mr={2} />
                                                        {this.props.data['Records'].length} Tags
                                                    </Button>
                                                }


                                            </ButtonGroup>
                                        }

                                        {requirementGroups.length == 0 && this.props.data['Records'].length == 0 &&
                                                <Tag size='sm' colorScheme='red'>
                                                    <TagLeftIcon as={MdOutlineErrorOutline} color='warning' mr={1} />
                                                    Not tagged to any records
                                                </Tag>
                                            }
                                        {this.props.data['rawText'] == null &&
                                            <Tag size='sm' colorScheme='red'>
                                                <TagLeftIcon as={MdOutlineErrorOutline} color='warning' mr={1} />
                                                No text found
                                            </Tag>
                                        }   



                                        {this.props.data['rawTextJSON'] == null ?
                                            <Tag size='sm' colorScheme='red'>
                                                <TagLeftIcon as={MdOutlineErrorOutline} color='warning' mr={1} />
                                                No JSON found
                                            </Tag>

                                            :
                                            <>
                                            
                                            </>
                                        }

                                        {this.state.isOpen &&
                                            <>
                                                {this.props.data['Records'].map((item, i) => (
                                                    <Tag size='sm'>
                                                        <TagLeftIcon boxSize='12px' as={MdLabel} />
                                                        {item['Requirement']['title']}
                                                    </Tag>
                                                ))}
                                            </>
                                        }                                        

                                    </Wrap>


                                
                                </Stack>

                            </Stack>

                        </Box>
                        
                    </Flex>
                </Box>
            </Flex>
        </Stack>

        );

      }
}