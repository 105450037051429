"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBgMuted = exports.getColorDefault = void 0;
var theme_tools_1 = require("@chakra-ui/theme-tools");
var getColorDefault = function (props) {
    return (0, theme_tools_1.mode)("".concat(props.colorScheme, ".500"), "".concat(props.colorScheme, ".400"))(props);
};
exports.getColorDefault = getColorDefault;
var getBgMuted = function (props) {
    return (0, theme_tools_1.mode)("".concat(props.colorScheme, ".300"), "".concat(props.colorScheme, ".600"))(props);
};
exports.getBgMuted = getBgMuted;
exports.default = {
    colors: {
        'bg-canvas': {
            default: 'gray.50',
            _dark: 'black',
        },
        'bg-surface': {
            default: 'white',
            _dark: 'gray.950',
        },
        'bg-subtle': {
            default: 'gray.200',
            _dark: 'gray.900',
        },
        'bg-muted': {
            default: 'gray.300',
            _dark: 'gray.600',
        },
        default: {
            default: 'black',
            _dark: 'white',
        },
        emphasized: {
            default: 'gray.800',
            _dark: 'gray.100',
        },
        muted: {
            default: 'gray.700',
            _dark: 'gray.200',
        },
        subtle: {
            default: 'gray.500',
            _dark: 'gray.500',
        },
        disabled: { default: 'gray.400', _dark: 'gray.600' },
        'on-disabled': { default: 'gray.600', _dark: 'gray.300' },
        'on-accent': 'white',
        'on-accent-muted': 'brand.100',
        'on-accent-subtle': 'brand.50',
        'on-accent-emphasis': 'white',
        accent: { default: '#150359', _dark: '#150359' },
        'bg-accent': '#150359',
        'accent-subtle': { default: '#5321bf', _dark: '#1B0273' },
        'bg-accent-subtle': '#1B0273',
        'accent-muted': { default: '#150359', _dark: '#150359' },
        'bg-accent-muted': '#1B0273',
        'accent-emphasis': { default: '#150359', _dark: '#150359' },
        'accent-disabled': { default: 'gray.300', _dark: 'gray.600' },
        'input-border-default': 'gray.300',
        'input-border-disabled': 'gray.200',
        'input-placeholder': 'gray.500',
        /* Brand */
        'brand-dark': '#150359',
        'brand-mid': '#1B0273',
        'brand-light': '#5321bf',
        'brand-white': '#f5f5f7',
        'brand-accent': '#3066be',
        'brand-accent-dark': '#be0e0e', //'#f03838',
        'brand-text-body': '#626262',
        'brand-text-heading': '#000000',
        'brand-grey': 'gray.200',
        'ui': 'blue.500',
    },
};
