import { useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Stack, Circle, Heading, Flex, Box, Text, Icon,Wrap,  Tag, Avatar, TagLabel } from '@chakra-ui/react';
import { MdCheck, MdClose, MdAccessTime, MdLock , MdArrowForward} from 'react-icons/md';

const handleStyle = { left: 10 };

function Node({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  let colors = {
    'completed': 'green.500',
    'inprogress': 'yellow.500',
    'notstarted': 'red.500',
    'locaked': 'gray.500',
  }

  let icons = {
    'completed': MdCheck,
    'inprogress': MdAccessTime,
    'notstarted': MdClose,
    'locked': MdLock,
  }

  return (
    <>
      <Flex
        border='1px solid #e2e8f0'
        borderBottom='4px solid'
        borderBottomColor='green.500'
        borderRadius='md' 
        boxShadow='md'
        px={2}
        py={2}
        // On double click, open the modal
        onDoubleClick={data.onDoubleClick}
        bg='green.500'
        alignItems='center'
        >
          
          <Icon as={MdArrowForward} pr={2}
            color='white'
            boxSize='50px'
            />
  <Stack spacing={0} pr={4}>
            <Text color='white' mb={0}>
              Start
            </Text>
            <Text fontSize='sm' color='white' opacity='0.7'>
              The entry point of this flow.
            </Text>
            </Stack>
      </Flex>
    <Handle
      type="source"
      position={Position.Right}
      isConnectable={isConnectable}
    />

    </>
  );
}

export default Node;
