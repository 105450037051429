import React, { useState, useEffect, useContext } from 'react';
import { useToast, Menu, MenuButton, MenuList, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuItem, color } from '@chakra-ui/react';
import API from "../../core/utils/API";
import {MdAttachFile, MdAdd, MdAutoFixHigh,  MdFlag, MdInsertLink, MdWarning, MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility, MdPhone, MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdMail, MdContentCopy } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import Modal from '../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import { BsTags } from 'react-icons/bs';
import ClinicalDemoData from './demo/ClinicalStage';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import { random } from 'sjcl';

import Source from './stages/Source';
import { set } from 'date-fns';
import DirectAward from './creation/DirectAward';
import OpenTender from './creation/OpenTender';
import Process from './creation/Process';
import Card from './components/Card';


export default function NewProcurement(props) {

    const [type, setType] = useState(null);
    const navigate = useNavigate();

    let typeLabel = {
        'direct-award': 'Direct Award',
        'open-tender': 'Open Tender',
    }

    return (


        <Layout

            title='New Procurement'
            description='Create a new procurement'
            layout='dense'
            actionContent={
                <Button variant='outline'
                    onClick={() =>{
                        navigate('/orgtest/procurement')
                    }}
                    >
                        Return
                    </Button>
            }
            >

            <GridItem colSpan={12}>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} pb={12}>


                    <Card p={0}>

                        {type !== null &&
                            <Box p={4} px={10} bg='blue.500' color='white'>
                                <Flex justifyContent='space-between'>
                                    <Text color='white'>
                                        Creating a new {typeLabel[type]} procurement
                                    </Text>
                                    <Button colorScheme='white' variant='outline' size='xs'
                                        onClick={() => setType(null)}
                                        >
                                        Return
                                    </Button>
                                </Flex>
                            </Box>
                        }


                            <Box p={10} display={type === null ? 'block' : 'none'}>

                                <Heading size='xs' mb={4} mt={2} color='blue.500'>
                                    New Procurement
                                </Heading>

                                <Stack spacing={10} pt={4}>

                                <InputWrapper
                                    label='Name'
                                    type='text'
                                    placeholder='Name of the procurement'
                                    />

                                <InputWrapper
                                    label='Budget'
                                    type='currency'
                                    placeholder='Amount of the procurement'
                                    />

                                <InputWrapper
                                    label='Description'
                                    description='We will use this to aid in the AI generation of the procurement documents, please provide as much information as you can.'
                                    type='textarea'
                                    minH='6em'
                                    placeholder='Description of the procurement'
                                    />

                                <InputWrapper
                                    label='Evidence'
                                    description='Please provide any evidence sources you have for this procurement, this could be a link to a website, a document or a folder.'
                                    type='select'
                                    isMulti
                                    placeholder='Evidence sources'
                                    defaultValue={['PreviousProcurements', 'LocalGuidelines', 'NationalGuidelines', 'NationalFrameworks']}
                                    options={[
                                        {
                                            label: 'Clinical Guidelines',
                                            value: 'ClinicalGuidelines'
                                        },
                                        {
                                            label: 'NICE Guidelines',
                                            value: 'NICEGuidelines'
                                        },
                                        {
                                            label: 'Local Guidelines',
                                            value: 'LocalGuidelines'
                                        },
                                        {
                                            label: 'National Guidelines',
                                            value: 'NationalGuidelines'
                                        },
                                        {
                                            label: 'National Frameworks',
                                            value: 'NationalFrameworks'
                                        },
                                        {
                                            label: 'Previous Procurements',
                                            value: 'PreviousProcurements'
                                        }
                                    ]}
                                    />

                                </Stack>

                                <Flex justifyContent='space-between' pt={12} pb={4}>
                                    <Box>

                                    </Box>
                                    <ButtonGroup size='md' colorScheme='blue' variant='outline' spacing={4}>
                                    <Button 
                                        onClick={() => setType('direct-award')}
                                        >
                                        I have a provider in mind
                                    </Button>
                                    <Button 
                                        onClick={() => setType('open-tender')}
                                        ml={8}
                                        >
                                        I want to open this to the market
                                    </Button>
                                    </ButtonGroup>
                                </Flex>



                            </Box>

                        {type === 'direct-award' &&

                            <DirectAward />

                        }

                        {type === 'open-tender' &&
                            <Process />
                        }



                    </Card>
    

                    <Card p={0}>
                        <Stack p={10} spacing={4} borderBottom='1px solid whitesmoke' pb={10}>
                            <Heading size='xs' pb={6}>
                                Previous Procurements
                            </Heading>
                            <InputWrapper w='fill-available' 
                                label='Search'
                                placeholder='Search procurements'
                                />
                        </Stack>



                        {[
                            {
                                title: 'Syringes',
                                date: '2021-09-23',
                                amount: '100000'
                            },
                            {
                                title: 'PPE',
                                date: '2021-09-23',
                                amount: '100000'
                            },
                            {
                                title: 'Vaccines',
                                date: '2021-09-23',
                                amount: '100000'
                            },
                            // Five more examples
                            {
                                title: 'Cannulas',
                                date: '2021-09-23',
                                amount: '100000'
                            },
                            {
                                title: '238 more',
                                date: '2021-09-23',
                                amount: '23920202'
                            }

                        ].map((procurement, index) => {
                            return (
                                <Flex spacing={0} py={6} px={10} borderBottom='1px solid whitesmoke'
                                    justifyContent='space-between'
                                    >
                                    <HStack>
                                        <Text color='black' fontWeight='600' fontSize='lg'>
                                            {procurement.title}
                                        </Text>
                                    </HStack>
                                    <Text fontSize='md'>
                                        <Moment format='MMM DD, YYYY'>
                                            {moment(procurement.date)}
                                        </Moment>
                                    </Text>
                                </Flex>
                            )
                        })}
                    </Card>

                </Grid>

            </GridItem>

        </Layout>

    )

}