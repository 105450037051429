import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
const ENDPOINT = "ws://localhost:3001";// + process.env.API_SERVER_PORT;

export default function WebSocket() {
  const [response, setResponse] = useState("");

  
  useEffect(() => {
    console.log("Trying to connect to socket");
    const socket = socketIOClient(ENDPOINT);
    socket.on("FromAPI", data => {
      console.log("Got data from socket");
      setResponse(data);
    });

    socket.on("disconnect", () => {
        console.log("Disconnected", socket.id); // undefined
    });

  }, []);
  

  return (
    <p>
      It's <time dateTime={response}>{response}</time>. Using port {process.env.API_SERVER_PORT}
    </p>
  );
}
