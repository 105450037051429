import Splash from '../../static/layout/Splash';
import { Text, Button, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdErrorOutline } from 'react-icons/md';
export default function Error(props) {

    const navigate = useNavigate();

    return (

        <Splash title='Error' subtitle='Something went wrong' 
            icon={MdErrorOutline}
            iconColor='red.500'
            {...props}>

            <Stack spacing={4} textAlign='center'>
                <Text color='emphasis' fontWeight='600'>ID Check Failed</Text>
                <Text pb={4}>Please try to complete the check again, if this happens repeatedly please contact support.</Text>
                <Button colorScheme='blue' ml='auto !important' mr='auto !important' w='fit-content' variant='outline'  onClick={() => navigate('/my/dbs/list')}>Return</Button>    
            </Stack>

        </Splash>

    )
}