import React from 'react';
import { 
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    ButtonGroup, Checkbox, Wrap, Tag, Table, Tbody, Tr, Th, Td, Icon, Circle, Avatar, Stack, HStack, GridItem, Flex, useToast, Box, Text, Heading, Input, Button, List, ListItem, Grid } from '@chakra-ui/react';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import { useState, useEffect } from 'react';
import API from '../../core/utils/API';
import {MdCheck, MdClose, MdList, MdAccessTime} from 'react-icons/md';
import Moment from 'react-moment';
import Splash from '../../static/layout/Splash';
import axios from 'axios';
import Loading from '../../static/layout/LoadingCircle';
import Layout from '../../core/layout/Layout';
import { set } from 'date-fns';
import pluralize from 'pluralize';
import { Outlet } from 'react-router-dom';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Product from './Product';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

// Import Products from ./
import Products from './Products';

// Build a carousel of products

function Carousel(props) {

    // props.products is an array of text strings we want to display and animate
    // We want to display them in a carousel

    // We need to keep track of the current index
    const [index, setIndex] = useState(0);

    // Create an interval to change the index
    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % props.items.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [props.items.length]);


    return (
        <Box>
            {props.items.map((item, innerIndex) => {

                // Slide the item in and out
                let slide = innerIndex === index ? 'slide-in' : 'slide-out';

                return (
                    <Box key={innerIndex} className={slide}>
                        <Text>
                            {item}
                        </Text>
                    </Box>
                )
            })}
        </Box>
    )
}


const SubscriptionWrapper = (props) => {


    const [subscriptions, setSubscriptions] = useState(secureLocalStorage.getItem('organisationProductIds'));
    const [product, setProduct] = useState(Products[props.product] ? Products[props.product] : null);
    const [access, setAccess] = useState(null);

    console.log(Products);
    console.log(product);

    const navigate = useNavigate();

    function updateSubscriptions(refresh = false) {

        let subscriptionProductIds = secureLocalStorage.getItem('organisationProductIds');

        console.log('Subscription product ids', subscriptionProductIds);

        if (subscriptionProductIds) {
            console.log("Setting from local storage");
            setSubscriptions(subscriptionProductIds);
        } else {

            // Load subcsriptions
            console.log('Loading subscriptions in wrapper');

            let url = 'roles/mine';
            let config = {
                params: {
                }
            };

            let resp = API.getItems({url, config})
            .then(response => {

                // Get the active subscriptions for this org
                let organisationUuid = secureLocalStorage.getItem('organisationUuid');

                // Loop through response.data.organisations
                secureLocalStorage.setItem('organisationSubscriptions', response.data.organisations[organisationUuid].subscriptions);
                secureLocalStorage.setItem('organisationProductIds', response.data.organisations[organisationUuid].product_ids);

                setSubscriptions(response.data.organisations[organisationUuid].product_ids);

            })
            .catch(error => {
                console.log(error);
            });
        
        }

    }
    
    useEffect(() => {

       // updateSubscriptions();
       // Check if any of the subscriptions are in product Id
       let subscription_product_ids = subscriptions ? Object.keys(subscriptions) : [];

       // Get product_ids from product
       let product_ids = product ? product.product_ids : [];

       // Check if there are any of the product_ids in subscription_product_ids
        let tmpAccess = false;
            product_ids.forEach(product_id => {
                if (subscription_product_ids.includes(product_id)) {
                    tmpAccess = true;
                }
        });

        setAccess(tmpAccess);

    }, [subscriptions, product]);


    return (
        <>
            {access ?
            
                <Box>
                    {props.children}
                    <Outlet/>
                </Box>
                :

                <Splash title="Not approved" message="You do not have access to this product">

                    {// Check if we have a product
                        !product ?

                        <>
                            <Text>
                                No product found for that product id.
                            </Text>
                            
                        </>

                        :

                        <Stack spacing={4}>
                            <Text>
                                You have not subscribed to the product <Text as='span' color='blue.500'>{product.name}</Text>, which is required to access this feature. 
                            </Text>
                            <Text>
                                To subscribe to this product, click the button below.
                            </Text>
                            <Button 
                                colorScheme='blue'
                            onClick={() => {
                                
                                // Navifate to {root}/organisation/manage
                                navigate('/organisation/manage');

                            }}>Subscribe</Button>

                            <Stack alignItems='center'>
                                <Heading size='label'>
                                    Features
                                </Heading>
                                {product.features && product.features.map(feature => {

                                    return (
                                        <Tag variant='outline' colorScheme='blue'>
                                            {feature}
                                        </Tag>
                                    )
                                })}

                                <Carousel items={product.features}/>
                            </Stack>

                        </Stack>
                    
                    }


                </Splash>
        
            }
        </>
        
    )


}

export default SubscriptionWrapper;