import { MarkerType } from '@xyflow/react';

const generalInitialNodes = [
    {
      id: 'entry',
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'entry',
      position: { x: -300, y: 50 },
      data: { label: 'Entry' },
    },
    {
      id: 'clinical',
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'node',
      data: { 
        label: 'Clinical Review',
        status: 'completed',
        description: 'This is the clinical review of the procurement',
        totalSteps: 7,
        completedSteps: 7,
        owner: 'Oliver',
        steps: [
          {
            label: 'Clinical Specification',
            status: 'completed',
            assigned: 'Oliver',
            completed: '2021-09-01',
            outcome: 'text',
            sources: ['Clinical Team', 'Clinical Leads'],
          },
          {
            label: 'Clinical Description',
            status: 'completed',
            assigned: 'Oliver',
            completed: '2021-09-01',
            outcome: 'text',
            description: 'This is the clinical review of the procurement, it is a short passage of text.',
            sources: ['Clinical Team', 'Clinical Leads'],
          },
          {
            label: 'Clinical Review',
            status: 'completed',
            assigned: 'Oliver',
            completed: '2021-09-01',
            outcome: 'text',
            description: 'This is the clinical review of the procurement, it is a short passage of text.',
            sources: ['Clinical Team', 'Clinical Leads'],
          },
          {
            label: 'Clinical Governance',
            status: 'completed',
            assigned: 'Oliver',
            completed: '2021-09-01',
            outcome: 'text',
            description: 'This is the clinical review of the procurement, it is a short passage of text.',
            sources: ['Clinical Team', 'Clinical Leads', 'Governance Documents'],
          },
          {
            label: 'Clinical Leads',
            status: 'completed',
            assigned: 'Oliver',
            completed: '2021-09-01',
            outcome: 'text',
            sources: ['Clinical Team', 'Clinical Leads'],
          },
          {
            label: 'Clinical Team',
            status: 'completed',
            assigned: 'Oliver',
            completed: '2021-09-01',
            outcome: 'text',
            sources: ['Clinical Team', 'Clinical Leads'],
          },
          {
            label: 'Clinical Sign Off',
            status: 'completed',
            assigned: 'Oliver',
            completed: '2021-09-01',
            outcome: 'approval',
            sources: ['Clinical Director'],
          }
        ]
      },
      position: { x: 0, y: 50 },
    },
    {
      id: 'ig',
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'node',
      status: 'inprogress',
      data: { 
        label: 'Information Governance',
        status: 'inprogress',
        totalSteps: 7,
        completedSteps: 3,
        owner: 'Ali',
        steps: [
          {
            label: 'Information Governance',
            status: 'completed',
          },
          {
            label: 'Information Governance Description',
            status: 'completed',
          },
          {
            label: 'Information Governance Review',
            status: 'completed',
          },
          {
            label: 'Information Governance Governance',
            status: 'inprogress',
          },
          {
            label: 'Information Governance Leads',
            status: 'inprogress',
          },
          {
            label: 'Information Governance Team',
            status: 'notstarted',
          },
          {
            label: 'Information Governance Sign Off',
            status: 'notstarted',
          }
        ]
      },
      position: { x: 300, y: -50 },
    },
    {
      id: 'procurement',
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'node',
      data: { 
        label: 'Procurement Review',
        status: 'locked',
        totalSteps: 7,
        completedSteps: 0,  
        steps: [
          {
            label: 'Procurement Specification',
            status: 'notstarted',
          },
          {
            label: 'Procurement Description',
            status: 'notstarted',
          },
          {
            label: 'Procurement Review',
            status: 'notstarted',
          },
          {
            label: 'Procurement Governance',
            status: 'notstarted',
          },
          {
            label: 'Procurement Leads',
            status: 'notstarted',
          },
          {
            label: 'Procurement Team',
            status: 'notstarted',
          },
          {
            label: 'Procurement Sign Off',
            status: 'notstarted',
          }
        ]
      },
      position: { x: 600, y: 50 },
    },
    {
      id: 'contracting',
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'node',
      data: { 
        label: 'Contracting Team' ,
        status: 'locked',
        
      },
      position: { x: 1100, y: 62 },
    },
    {
      id: 'it',
  //    type: 'input',
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'node',
      data: { 
        label: 'Information Technology',
        status: 'notstarted',
        totalSteps: 7,
        completedSteps: 0,
        steps: [
          {
            label: 'IT Specification',
            status: 'notstarted',
          },
          {
            label: 'IT Description',
            status: 'notstarted',
          },
          {
            label: 'IT Review',
            status: 'notstarted',
          },
          {
            label: 'IT Governance',
            status: 'notstarted',
          },
          {
            label: 'IT Leads',
            status: 'notstarted',
          },
          {
            label: 'IT Team',
            status: 'notstarted',
          },
          {
            label: 'IT Sign Off',
            status: 'notstarted',
          }
        ]
      },
      position: { x: 300, y: 150 },
    },
    {
      id: 'final',
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'node',
      status: 'notstarted',
      data: { 
        label: 'Final Approval',
        status: 'locked',
      },
      position: { x: 1400, y: 62 },
    },
    {
      id: 'exit',
      targetPosition: 'left',
      type: 'exit',
      status: 'notstarted',
      data: { 
        status: 'locked',
        target: 'complete',
      },
      position: { x: 1700, y: 51 },
    },
    /*
    {
      id: 'clinical-group',
      type: 'group',
      sourcePosition: 'right',
      targetPosition: 'left',
      data: { label: 'Clinical' },
      position: { x: 0, y: 300 },
      style: {
          width: 380,
          height: 180,
          backgroundColor: 'blue.50',
        },
    },
    {
      id: 'specifications',
      sourcePosition: 'right',
      targetPosition: 'left',
      parentId: 'clinical-group',
      data: { 
        label: 'Clinical Specification',
        status: 'completed',
      },
      position: { x: 100, y: 100 },
  
      extent: 'parent',
    },*/
  ];
  
  const generalInitialEdges = [
      {
        id: 'horizontal-entry-1',
        source: 'entry',
        type: 'smoothstep',
        target: 'clinical',
        reconnectable: 'target',
        animated: true,
        markerEnd: {
          type: MarkerType.Arrow,
        }
      },
      {
        id: 'horizontal-e1-2',
        source: 'clinical',
        type: 'smoothstep',
        target: 'it',
        reconnectable: 'target',
        animated: true,
        markerEnd: {
          type: MarkerType.Arrow,
      },
      },
      {
          id: 'horizontal-e1-2',
          source: 'clinical',
          type: 'smoothstep',
          target: 'ig',
          reconnectable: 'target',
          animated: true,
          markerEnd: {
              type: MarkerType.Arrow,
          },
      },
      {
        id: 'horizontal-e1-4',
        source: 'ig',
        type: 'smoothstep',
        animated: true,
        reconnectable: 'target',
        target: 'procurement',
        markerEnd: {
          type: MarkerType.Arrow,
      },
      },
      {
        id: 'horizontal-e3-5',
        source: 'procurement',
        type: 'smoothstep',
        target: 'contracting',
        animated: true,
        reconnectable: 'target',
        label: '1 Month\nPublic Contract Period',        
        markerEnd: {
          type: MarkerType.Arrow,
      },
      },
      {
        id: 'horizontal-e3-6',
        source: 'clinical',
        type: 'smoothstep',
        target: 'it',
        reconnectable: 'target',
        animated: true,        
        markerEnd: {
          type: MarkerType.Arrow,
          },
      },
      {
        id: 'horizontal-e3-7',
        source: 'it',
        type: 'smoothstep',
        target: 'procurement',
        reconnectable: 'target',
        animated: true,
  
          markerEnd: {
              type: MarkerType.Arrow,
          },
      },
      {
          id: 'horizontal-e3-8',
          source: 'contracting',
          type: 'smoothstep',
          target: 'final',
          animated: true,
          reconnectable: 'target',
          label: '',
      markerEnd: {
          type: MarkerType.Arrow,
        },
      },
      {
        id: 'horizontal-e3-91',
        source: 'final',
        type: 'smoothstep',
        target: 'exit',
        animated: true,
        reconnectable: 'target',
        label: '',
        markerEnd: {
          type: MarkerType.Arrow,
        },
      }
  ];

  
    export {
        generalInitialNodes as demoInitialNodes,
        generalInitialEdges as demoInitialEdges
    }