
import Layout from '../../core/layout/Layout';
import { GridItem, Spacer, Icon, IconButton, Circle, Box, Heading, Text, Flex, Button, Stack, Avatar, AvatarGroup, Divider, HStack, VStack, Wrap, WrapItem, Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton, useColorModeValue } from '@chakra-ui/react';
import API from '../../core/utils/API';
import React, {useState, useEffect} from 'react';
import AutoForm from '../../core/models/AutoForm';
import AttributesForm from './AttributesForm';
import Modal from '../../core/components/modal/Modal';
import {MdEdit} from 'react-icons/md';


function AttributeRow(props) {

    let attribute = props.attribute;

    return (
        <Box mb={2} borderBottom='1px solid whitesmoke' p={2} key='attribute.uuid'>
                                <HStack justifyContent='space-between'>
                                    <HStack>
                                <Button mr={4} variant='outline' pl={0} pr={0} colorScheme='blue' onClick={() => props.setUuid(attribute)} size='xs'>
                                    <Icon as={MdEdit}/>
                                </Button>
                                
                                <Box>
                                    <Heading size='label'>
                                        {attribute.question}
                                    </Heading> 
                                    <Text color='subtle' fontSize='sm'>{attribute.description}</Text>
                                </Box>
                                </HStack>
                                <Wrap spacing={2} display='inline'>
                                        <Tag size='sm' colorScheme='blue' variant='subtle'>{attribute.key}</Tag> 
                                        {attribute.active === false ?
                                            <Tag size='sm' colorScheme='red' variant='subtle'>Inactive</Tag> 
                                            :
                                            <Tag size='sm' colorScheme='grey' variant='subtle'>Active</Tag>     
                                        }
                                        </Wrap>
                                </HStack>

        </Box>

    )


}


export default function Attributes(props) {


    let [loading, setLoading] = useState(true);
    let [attributes, setAttributes] = useState([]);
    let [error, setError] = useState();
    let [uuid, setUuid] = useState();

    function getAttributes() {

        let response = API.getItems({url: '/usersparameters'})
        .then((response) => {

            let data = response.data;
            console.log(data);
            setAttributes(data);
    
            setLoading(false);
        })
        .catch((error) => {
            setError(error);
            setLoading(false);
            
        });
    }

    useEffect(() => {
        getAttributes();
    }, []);

    function updateAttribute() {
        
    }

    return (
        <Layout
            title='Attributes'
            description={'This are the ' + attributes.length + ' preset options available to those creating customised forms.'}
            primaryButtonText="Add Attribute"
            primaryButtonModal={true}
            modalContent={<AttributesForm full={true}/>}
            >

                {uuid &&
                    <Modal 
                        maxW='500px' 
                        submitButton={true} 
                        submitButtonText='Save'
                        onClose={()=> setUuid(null)}
                        >
                        <AttributesForm full={false} attribute={uuid} onSubmit={(att) => {setUuid(null); updateAttribute(att);}} />
                    </Modal>
                }
                

                <GridItem colSpan='12'>

                    {!loading &&
                    
                        <>
                        {attributes.map((attribute) => {

                            return(

                                <AttributeRow 
                                    attribute={attribute} 
                                    setUuid={setUuid}
                                    />
                            
                            )
                        })}
                    
                        </>
                    
                    }
                </GridItem>

            </Layout>
    )
}