import {Box, Heading, Text, Button, Icon, Flex, Stack, Center, Container, Image, useColorModeValue} from '@chakra-ui/react'
import {MdArrowForward} from 'react-icons/md'
import {useNavigate} from 'react-router-dom';
import TryNowButton from './TryNowButton';

export default function CallToAction() {

    const navigate = useNavigate();

    return (

        <Box as="section" py="7.5rem"
        

        >
        <Box
          maxW={{
            base: 'xl',
            md: '5xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
          textAlign='center'
        >
            

                <Heading maxWidth='650px' m='auto' mb={8}>
                    Try it out and automate your HR today.    
                </Heading>


                <TryNowButton/>


</Box>
        </Box>

    )

                }