import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { Line, Bar, Pie, Radar} from 'react-chartjs-2';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import secureLocalStorage from 'react-secure-storage';
import Compliance from './Compliance';
import PALS from './PALS';
import Recruitment from './Recruitment';
import Accounts from './Accounts';

let components = {
    'View Compliance Summary': <Compliance />,
    'View Feedback Summary': <PALS />,
    'View Recruitment Summary': <Recruitment />
}


const piedata = {
    labels: ['Draft', 'Sent', 'Overdue', 'Paid'],
    datasets: [
      {
        label: '# of Invoices',
        data: [12, 19, 3, 5],
        backgroundColor: [
          'rgba(72, 149, 239, 0.7)',
          'rgba(63, 55, 201, 0.7)',
          'rgba(114, 9, 183, 0.7)',
          'rgba(181, 23, 158, 0.7)',
          'rgba(247, 37, 133, 0.7)'
        ],
        borderColor: [
          '#4895EF',
          '#3F37C9',
          '#7209B7',
          '#B5179E',
          '#F72585',
        ],
        borderWidth: 1,
      },
    ],
  };



function AIToolBox(props) {
    const navigate = useNavigate();

    function navigateOptions(e) {
        
        // check if props.url has mailto: or http: or https: or www. or .com or .co.uk or .org or .net
        // if so, navigate to props.url
        // else, navigate to /ai/props.url

        if (props.url.substring(0, 7) == 'mailto:') {

            // Open new window
            window.open(props.url, "_blank");
            
        } else if (props.url.substring(0, 5) == 'http:' || props.url.substring(0, 6) == 'https:') {

            window.open(props.url, "_blank");

        } else if (props.url.substring(0, 4) == 'www.') {

            window.open(props.url, "_blank");
        } else if (props.url.substring(props.url.length - 4, props.url.length) == '.com' || props.url.substring(props.url.length - 7, props.url.length) == '.co.uk' || props.url.substring(props.url.length - 4, props.url.length) == '.org' || props.url.substring(props.url.length - 4, props.url.length) == '.net') {

            window.open(props.url, "_blank");
        } else {

            navigate(props.url);

        }

    }

    return (

        <Box onClick={(e)=> {navigateOptions(e)}} className='gradient-background hover-only' p='2px' cursor='pointer' borderRadius="lg" overflow="hidden" {...props}>
            <Box position='relative' p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" bg='white'>

                {props.children}

                {props.hideIcon ? '' : <Icon as={MdArrowForward} color='black' w={6} h={6} position='absolute' right={4} bottom={4} />}

            </Box>
        </Box>


    )
}


function Figure(props) {


    return (
        <Box align='center' minW='100px'>
            <Text fontSize='4xl' fontWeight='600' color={props.color}>{props.figure}</Text>
            <Text fontSize='xs' color={props.color}>{props.label}</Text>
        </Box>
    )
}

function Info(props) {

    return (
        /*
        <Box align='center' minW='fit-content' px={4}>
            <Text fontSize='4xl' fontWeight='600' color={props.color}>{props.title}</Text>
            <Text fontSize='xs' color={props.color}>{props.label}</Text>
        </Box>
        */
       <Tag size='lg' borderColor={props.color} variant='outline'
       boxShadow='none' //{'inset 0 0 0px 1px ' + props.color}
       borderColor={props.color}
       borderWidth='1px'
        >
           <TagLabel pr={2} color={props.color}>{props.title}</TagLabel>
           <TagLabel color={props.color}>{props.label}</TagLabel>
       </Tag>
    )

}

export default function Landing(props) {

    // Get state
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        // Get from secureLocalStorage
        setPermissions(secureLocalStorage.getItem('organisationPermissions'));
        setLoading(false);
    }
    , []);


    function subLink(url) {

        // Open a new window
        window.open(url, "_blank");
        
    }


    let warningColor = '#8612A3';
    let successColor = '#3F37C9';
    let normalColor = '#7374CD';

    return (
            <Layout
                title='Overview'
                layout='dense'
                description='View and manage your organisation'
                >
                

            <GridItem pr={{base: '0', lg: '0'}} pb={{base: '1em', lg: '72px'}} colSpan={{base: '6', md: '2'}} rowSpan='2' alignItems='stretch' display='flex' 
                width='fill-available'
                flexDirection='column'
                spacing={4}
                >

                <AIToolBox url='/organisation/accounts' display='flex' alignItems='stretch' mb={4}>

                    <Accounts />

                </AIToolBox>


                <AIToolBox hideIcon={true}>

                    <Heading size='smallertitle' mb={4}>Staff Directory</Heading>
                    <Text mb={4}>Contact one of your {Math.floor(Math.random()*500)} colleagues and staff.</Text>
                    <Input placeholder='Search for a colleague' />

                </AIToolBox>   

                </GridItem>


                <GridItem pb='72px' colSpan='4' rowSpan='2' alignItems='flex-start' width='fill-available'>

                <Stack spacing={4}>

                    <AIToolBox url='/organisation/recruitment?uuids=57156688-a247-4ed4-ac24-693c7cfed938'>

                        <Heading size='smallertitle' mb={4}>Recruitment</Heading>
                        <Text mb={4}>View and manage your recruitment schedule.</Text>

                        <HStack spacing={4}>
                            <Figure figure={Math.floor(Math.random() * 100)} color={successColor} label='Completed' />
                            <Figure figure={Math.floor(Math.random() * 100)} color={warningColor} label='Overdue' />
                            <Figure figure={Math.floor(Math.random() * 100)} color={normalColor} label='In Progress' />
                        </HStack>

                    </AIToolBox>

                    <AIToolBox url='/organisation/compliance?uuids=c91a41f0-e3a9-42ed-89b9-91229768f418'>

                        <Heading size='smallertitle' mb={4}>Compliance</Heading>
                        <Text mb={4}>View and manage your compliance schedule.</Text>

                        <HStack spacing={4}>
                            <Info title='Paediatrics' label='54%' color={warningColor} />
                            <Info title='Adults' label='95%' color={successColor} />
                            <Info title='Mental Health' label='75%' color={normalColor} />
                        </HStack>

                    </AIToolBox>
                    
                    <AIToolBox url='/organisation/health?uuids=2b68e94c-3506-4cba-b908-3dc42f6db1e3'>

                        <Heading size='smallertitle' mb={4}>Occupational Health</Heading>
                        <Text mb={4}>View and manage your occupation health compliance.</Text>

                        <HStack spacing={4}>
                            <Info title='Paediatrics' label={Math.floor(Math.random()*10)+50 + '%'} color={warningColor} />
                            <Info title='Adults' label={Math.floor(Math.random()*10)+90 + '%'} color={successColor} />
                            <Info title='Mental Health' label={Math.floor(Math.random()*10)+70 + '%'} color={normalColor} />
                        </HStack>

                    </AIToolBox>
                    
                    <AIToolBox url='/organisation/feedback'>

                        <Heading size='smallertitle' mb={4}>Feedback</Heading>
                        <Text mb={4}>Manage your organisation's feedback and complaints.</Text>

                        <HStack spacing={4}>
                            <Figure figure={Math.floor(Math.random() * 100)} color={successColor} label='Completed' />
                            <Figure figure={Math.floor(Math.random() * 100)} color={warningColor} label='Overdue' />
                            <Figure figure={Math.floor(Math.random() * 100)} color={normalColor} label='In Progress' />
                        </HStack>
                    </AIToolBox>
                                     

                </Stack>

                </GridItem>


            </Layout>
    )
}