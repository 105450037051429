import React, {useState, Component, Fragment} from 'react';
import { Icon, Link, HStack, Table, Thead, Tbody, Tr, Th, Td, Tag, Heading, VStack, Checkbox, WrapItem, useDisclosure, Collapse,  Divider, SkeletonText, Grid, GridItem, Flex, Button, List, ListItem, Badge, Wrap, Box, Stack, Text } from '@chakra-ui/react';
import { MdExpandLess, MdExpandMore, MdLink, MdThumbUp, MdThumbDown, MdSettings} from "react-icons/md";
import pluralize from 'pluralize';
import RequirementForm from '../../admin/requirements/RequirementForm';
import Moment from 'react-moment';
import API from '../../core/utils/API';
import AutoForm from '../../core/models/AutoForm';
import Modal from '../../core/components/modal/Modal';
import Card from '../../core/components/cards/Card';
function GroupForm(props) {

    const [show, setShow] = useState(false);

    return (

        <>

        <Button size='sm' variant='outline' className='btn-8' onClick={() => setShow(true)} colorScheme='blue'>Add Requirement</Button>


        {show &&

        <Modal
            title='Add Requirement'
            onClose={() => setShow(false)}
            footer={
                <Button onClick={(e) => {
                    e.preventDefault();
                    console.log(e.target.closest('.chakra-modal__content-container').querySelector('form'));
                    e.target.closest('.chakra-modal__content-container').querySelector('form button[type="submit"]').click();
                } } colorScheme='blue' variant='outline' className='btn-8'>Create</Button>

            }
            >
            <AutoForm
                url = 'requirements/'
                refresh={props.refresh}
                hideSubmit={true}
                inputs = {[
                    {
                        name: 'title',
                        required: true
                    },
                    {
                        name: 'description'
                    },
                    {
                        name: 'tags'
                    },
                    {
                        name: 'requirementGroupUuid',
                        value: props.uuid,
                        type: 'hidden'
                    }
                ]}
            />
        </Modal>
            }

            </>

    )
}

export class RequirementGroupDisplayRecord extends React.Component {

  constructor(props) {
    super(props);
    this.state = { currState: true }
    this.state = {
        isOpen: true
      };
    }

    

  render() {


    return (
        <Stack mt={12}>
            <Flex justifyContent='space-between'>
                <Heading size='smalltitle'>
                    {this.props.data['title']}
                </Heading>

                <HStack spacing={4}>

                <Box display='none'>
                            <Text fontSize='xs' color="subtle">Created: <Moment date={this.props.data["createdAt"]} format='hh:mm DD/MMM/YYYY' /></Text>
                            <Text fontSize='xs' color="subtle">Updated: <Moment date={this.props.data["updatedAt"]} format='hh:mm DD/MMM/YYYY' /></Text>
                        </Box>
                    
                        
                { this.props.data['active'] ? 
                                    <Tag display='none' size='sm' colorScheme='green'>Active</Tag> 
                                    : 
                                    <Tag display='none' size='sm' colorScheme='red'>Inactive</Tag> 
                                    }    
                {this.state.isOpen ?
                    <Button colorScheme='blue' variant='outline' onClick={() => this.setState({isOpen: !this.state.isOpen})} borderRadius='50%' p='0px' width='20px' height='20px' minWidth='20px'>
                    { this.state.isOpen ? <MdExpandLess />:<MdExpandMore />}
             </Button>
             :
                                    <Button variant='outline' colorScheme='blue' className='btn-8' cursor='pointer' size='xs' onClick={() => this.setState({isOpen: !this.state.isOpen})} >{this.props.data['RequirementGroupings'].length} {pluralize('requirement', this.props.data['RequirementGroupings'].length)} <MdExpandMore /></Button>
                                            }
          
                </HStack>
            </Flex>
            <Box>

        
            <Collapse in={this.state.isOpen} animateOpacity >

            <Grid templateColumns='repeat(5, 1fr)' spacing={2} p={0} pt={8} borderBottom='1px solid whitesmoke'>

<GridItem p={4} borderBottom='2px solid black'>
    <Heading size='label'>Description</Heading>
</GridItem>
<GridItem p={4} colSpan={2} borderBottom='2px solid black'>
    <Heading size='label'>Keywords for tags</Heading>
</GridItem>
<GridItem p={4} borderBottom='2px solid black'>
    <Heading size='label'>Recommended Provider</Heading>
</GridItem>
<GridItem textAlign='right' p={4} borderBottom='2px solid black'>
    <Heading size='label' pr={2}>Actions</Heading>
</GridItem>
</Grid>
                <Card mt={0} p={0}  bg='white'>
                    <Grid templateColumns='repeat(5, 1fr)' spacing={2} p={0} borderBottom='1px solid whitesmoke'  bg='white'>
     
                        {this.props.data['RequirementGroupings'].map((item) => (
                            <>
                            <GridItem p={4} borderBottom='1px solid whitesmoke'>
                                <Heading as='h6' fontSize='sm' lineHeight='1em' m={0} mb={1}>
                                    {item['Requirement']['title']}
                                </Heading>
                          
                                
                            </GridItem>
                            <GridItem p={4} colSpan={2} borderBottom='1px solid whitesmoke'>
                                <Wrap>
                                    
                                        {item['Requirement']['RequirementTaggings'].map((tag) => (
                                            <Tag size='sm' >{tag['RequirementTag']['tag']}</Tag>
                                        ))}
                                        </Wrap>
                            </GridItem>        
                            <GridItem p={4} borderBottom='1px solid whitesmoke'>
                                {!item['Requirement']['recommendedProviderName'] && !item['Requirement']['recommendedProviderName'] &&
                                    <Text color='subtle' fontSize='xs' ml={2} mb={2}>None</Text>
                                }
                                {!item['Requirement']['recommendedProviderLogoURL'] && item['Requirement']['recommendedProviderName'] &&


                                    <Button colorScheme='gray'  size='xs' variant='ghost' fontSize='xs' isExternal href={item['Requirement']['recommendedProviderURL']} onClick={()=> window.open(item['Requirement']['recommendedProviderURL'], "_blank")}>
                                        {item['Requirement']['recommendedProviderName']} <Icon as={MdLink} ml={2}/>
                                    </Button>

                                }
                                {item['Requirement']['recommendedProviderLogoURL']  &&
                                    
                                    <Link fontSize='xs' color='emphasized' isExternal onClick={()=> window.open(item['Requirement']['recommendedProviderURL'], "_blank")}>
                                        <img src={item['Requirement']['recommendedProviderLogoURL']} width='100px' />
                                    </Link>
                                }

                            </GridItem>
                            <GridItem textAlign='right' alignItem='flex-end' p={4} justifyContent='flex-end' borderBottom='1px solid whitesmoke'>
                                        <RequirementForm
                                            requirement={item['Requirement']}
                                            refresh={this.props.refresh}
                                            />
                                        <Button onClick={() => {
                                            API.deleteItems({url: 'requirementgroups/grouping/' + item.uuid + '/'})
                                            .then(res => {
                                                this.props.refresh();
                                            })
                                            .catch(err => {
                                                console.log(err);
                                            })
                                            }}
                                            color='gray'
                                            variant='ghost'
                                            _hover={{color: 'red.500', bg: 'red.50'}}
                                            size='xs'
                                            ml={2}>
                                                Delete
                                            </Button>
                                 
                            </GridItem>
                            </>
                        ))}
                                           <GridItem colSpan='5' p={4} borderBottom='1px solid whitesmoke'>
                                            <Wrap spacing={4}>

<GroupForm

        uuid={this.props.data['uuid']}
        refresh={this.props.refresh} />

        { this.props.data['RequirementGroupings'].length == 0 ?
        
        <Button onClick={() => {
            API.deleteItems({url: 'requirementgroups/' + this.props.data['uuid']})
            .then(res => {
                this.props.refresh();
            })
            .catch(err => {
                console.log(err);
            })
            }}
            colorScheme='gray'
            variant='ghost'
            _hover={{color: 'red.500', bg: 'red.50'}}
            size='sm'>
                Delete
            </Button>
            :
            <>
            <Button disabled size='sm'>
                Delete
            </Button>
                    <Text color='subtle' fontSize='12px' pt='8px'>
Delete the requirements first, or you can deactivate the requirement group.
                    </Text>
</>
            }

</Wrap>

</GridItem>

                    </Grid>
                </Card>

</Collapse>
            </Box>
        </Stack>
    )
  }
}