import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Divider, Box, Button, ButtonGroup, Text } from '@chakra-ui/react';
import API from '../../core/utils/API';

export default function Mode(props) {

    const [modes, setModes] = useState([
        'Healthcare Professional',
        'Operations Staff'
    ]);
    const [mode, setMode] = useState(props.mode);

    useEffect(() => {

        API.getItems({url: 'users/me/mode'})
        .then(response => {
          //  console.log(response);
            setMode(response.data.mode);
        })
        .catch(error => {
         //  console.log(error);
        });

    }, []);

    
    const updateMode = (newMode) => {

        API.postItems({url: 'users/me/mode', data: {mode: newMode}})
        .then(response => {
           // console.log(response);
            //setMode(newMode);
           // window.location.reload();
            
            // Refresh page
            window.location.reload();

        })
        .catch(error => {
         //   console.log(error);
        });

    }

    


    return (



        <Stack spacing={5}>
        <Divider />
            <Stack
            spacing="4"
            direction={{
                base: 'column',
                sm: 'row',
            }}
            justify="space-between"
            > 
            <Box>
                    <Text fontSize="lg" fontWeight="medium">
                    Select Role
                    </Text>
                    <Text color="muted" fontSize="sm">
                    Switch between the HR and professional roles.
                    </Text>
                </Box>
            
                <ButtonGroup variant='outline' colorScheme='blue' size='sm'>
                    {modes.map((modeOption, index) => (
                        <Button key={index} variant={(mode===modeOption) ? 'solid' : 'outline'} onClick={() => updateMode(modeOption)} className='btn-8'>{modeOption}</Button>
                    ))}
                </ButtonGroup>
            </Stack>
            <Divider />

            </Stack>




    )
}