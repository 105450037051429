import Input from './Input';
import {AutoResizeTextarea} from './TextArea';
import {Flex, Wrap, Badge, Tag, Button, Box, Heading, Text, FormControl, FormLabel, FormErrorMessage, FormHelperText, Divider } from '@chakra-ui/react';
import Select from './Select';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Numerical from './Numerical';
import Boolean from './Boolean';
import Date from './Date';
import Address from './Address';
import {InputGroup, InputLeftAddon} from '@chakra-ui/react';
import PhoneNumberInput from '../../form_elements/PhoneNumberInput';

function WrapperLeft(props) {

    //console.log(props.InputLeftAddonStyle);

    return (
        <>

            {props.InputLeftAddon !== undefined ?
                <InputGroup w='100%' flexGrow='1' className={'left-addon-'+props.InputLeftAddonStyle}>
                    <InputLeftAddon children={props.InputLeftAddon}/>
                    {props.children}
                </InputGroup>
            :
                <>
                    {props.children}
                </>
            }

        </>
    )
}

export default function InputWrapper(props) {

    //console.log("InputWrapper", props.type);

    let custom = {};
    if (props.json) {
        if (typeof props.json === 'string') {
            custom = JSON.parse(props.json);
        } else {
            custom = props.json;
        }
        //console.log(custom);
        //console.log(typeof(custom)); 
    
    }

    return(
        <Box position='relative' minH='1em'>
        {props.admin &&

            <>
                <Button className='show-on-parent-hover' position='absolute' right={0} 
                    top={ props.type === null ? 2 : 0} variant='ghost' size='xs' colorScheme='red'
                    zIndex='1'
                    onClick={() => {
                        console.log("Delete divider");
                        props.delete();
                    }}
                    >
                    Delete
                </Button>
                
                {custom && custom.type && ( custom.type.includes('Section') || custom.type.includes('section') || custom.type.includes('page') || custom.type.includes('Page') ) &&

                    <Button zIndex='1' className='show-on-parent-hover' position='absolute' right={14} top={2} variant='ghost' size='xs' colorScheme='blue' onClick={() => props.edit()}>
                        Edit
                    </Button>
                }

            </>
        }

        {(props.type === 'boolean' || props.type === 'checkbox') ?

                <Boolean
                {...props}
                />

        :

        <FormControl isInvalid={props.error} >
            
            {props.label &&
            <Flex justifyContent='space-between'>
                <FormLabel htmlFor={props.name} mb={2} fontWeight='600'
                    fontSize='lg'
                    >
                    {props.label}
                </FormLabel>
                    {props.required &&
                        <Tag h='fit-content' colorScheme='red' ml={1} size='sm' variant='outline'>
                            Required
                        </Tag>
                    }
                    </Flex>
            }
            
            {props.description &&
                <FormHelperText mb={4} mt={0} fontSize='lg'>
                    {props.description}
                </FormHelperText>
            }


            <WrapperLeft InputLeftAddon={props.inputLeftAddon} InputLeftAddonStyle={props.InputLeftAddonStyle}>

                {(props.type == null || props.type == undefined || props.type === 'input' || props.type === 'email' || props.type === 'text' || props.type === 'string' || props.type === 'password') &&
                    <Input
                        {...props}
                        />
                }

                {props.type === 'textarea' &&

                    <AutoResizeTextarea
                    {...props}

                    />
                
                }

                {(props.type === 'select' || props.type === 'select-creatable') &&
                    <Select 
                        {...props}
                        />
                }

                {(props.type === 'checkbox' &&
                    <Checkbox   
                        {...props}
                        />
                )}

                {(props.type === 'boolean') &&
                    <Boolean
                        {...props}
                        />
                }

                {props.type === 'radio' &&
                    <Radio
                        {...props}
                        />
                }

                {(props.type === 'integer' || props.type === 'decimal' || props.type === 'currency') &&
                    <Numerical
                        {...props}
                        />
                }

                {(props.type === 'date' || props.type === 'datetime-local' ) &&

                    <Date
                    {...props}
                    />

                }

                {props.type === 'address' &&

                    <Address
                    {...props}
                    />

                }

                {props.type === 'phone' &&
                
                    <PhoneNumberInput
                        //value={submitPhone}
                        placeholder="Enter phone number"
                        //country='GBR'
                        // Pass value to component but remove the country code
                        // it could be any country code so we can't assume it's 3 digits
                        // Need to split on the first space
                        value={props.defaultValue}
                        onChange={(e) => {
                            props.setValue(props.name, e);
                        }}
                    />
                
                }

                {custom && custom.type === 'divider' &&
                    <Divider />
                }

                {custom && custom.type && ( custom.type.includes('Section') || custom.type.includes('section') || custom.type.includes('page') || custom.type.includes('Page') ) &&
                
                    <>
                        {custom.type === 'Section Break' &&
                        
                            <Box minH='4px' bg='gray.200'  mt={6} ml={-6} mr={-6} mb={4} position='relative' overflow='hidden'>
                            </Box>

                        }
                        {custom.type === 'Page Break' &&
                        
                            <Box minH='4px' bg='gray.200' ml={-6} mr={-6} mb={6} position='relative' overflow='hidden'>
                                <Text size='sm' color='black'>Page Break</Text>
                            </Box>

                        }

                        {custom.title &&
                            <Heading mb={2} size='smalltitle'>
                                {custom.title}
                            </Heading>
                        }
                        
                        {custom.description &&
                            <FormHelperText mb={2} mt={0}>
                                {custom.description}
                            </FormHelperText>
                        }

                    </>

                }

                {props.info &&
                
                    <Box>
                        <Text fontSize='sm' borderBottom='1px solid black' borderColor='blue.500' mb={1} pb={0.5} mt={4}>A quick tip!</Text>
                    <Text color='subtle' fontSize='sm'>{props.info}</Text>
                    </Box>
                
                }


            </WrapperLeft>

            <FormErrorMessage>
                {props.error && props.error.message}
            </FormErrorMessage>



        </FormControl>

        }

        </Box>


    )
}