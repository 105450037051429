
import React, {useState} from 'react';
import {Heading, Text, Box, Stack, Button, Input} from '@chakra-ui/react';

import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import userPool from '../UserPool';

export default (props) => {

    const [email, setEmail] = useState(props.email);
    const [confirmationCode, setConfirmationCode] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const confirm = e => {
        e.preventDefault();

        const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });

        cognitoUser.confirmRegistration(confirmationCode, true, function(err, result) {
            if (err) {
                alert(err);
                return;
            }
            setSuccess(result);
            props.onSuccess(email);
        });

    };
    

    return (
        <>
            <Heading size='label'>Confirm your email to login</Heading>
            <form>
            <Stack>
                {error && <Text>{error.message}</Text>}
                <Input placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
                <Input placeholder="Confirmation Code" value={confirmationCode} onChange={e => setConfirmationCode(e.target.value)}/>
                <Button onClick={confirm}>Confirm</Button>
            </Stack>
            </form>
            {success && <Text>{success}</Text>}
        </>
    )

}