import {Input ,Text, Stack } from '@chakra-ui/react';
import { useState } from 'react';

export default function AddressWrapper(props) {

    console.log(props);

    // Generate uuid
    const [uuid, setUuid] = Math.random().toString(36).substring(7);

    let defaultValues = {};
    // Loop through default values
    if (props.defaultValue) {
        let tmp = props.defaultValue;
        if (typeof tmp === 'string') {
            tmp = JSON.parse(tmp);
        }
        tmp.forEach((item) => {
            defaultValues[item.name] = item.value;
        });
    }

    // Set the value of the input
    const [current, setCurrent] = useState(defaultValues);

    function gatherAddress() {

        let address = [
            {
                name: 'line1',
                value: document.getElementById(uuid + '-line1').value,
                type: 'input'
            },
            {
                name: 'line1',
                value: document.getElementById(uuid + '-line2').value,
                type: 'input'
            },
            {
                name: 'city',
                value: document.getElementById(uuid + '-city').value,
                type: 'input'
            },
            {
                name: 'country',
                value: document.getElementById(uuid + '-country').value,
                type: 'input'
            },
            {
                name: 'postcode',
                value: document.getElementById(uuid + '-postcode').value,
                type: 'input'
            }
        ]

        props.setValue(props.name, JSON.stringify(address));
        return address;

    }

    return (

        <Stack spacing={2}>

            <Input name='address-line1' placeholder='First Line' id={uuid + '-line1'} defaultValue={current.line1} 
                onChange={() => {
                    gatherAddress();
                }}
                />
            <Input name='address-line2' placeholder='Second Line' id={uuid + '-line2'} defaultValue={current.line2} 
                onChange={() => {
                    gatherAddress();
                }}
                />
            <Input placeholder='City' id={uuid + '-city'} defaultValue={current.city} 
                onChange={() => {
                    gatherAddress();
                }}
                />
            <Input placeholder='Country' id={uuid + '-country'} defaultValue={current.country} 
                onChange={() => {
                    gatherAddress();
                }}
                />
            <Input placeholder='Post Code' id={uuid + '-postcode'} defaultValue={current.postcode} 
                onChange={() => {
                    gatherAddress();
                }}
                />

        </Stack>


    )
}