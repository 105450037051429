import AutoEdit from "../../core/models/AutoEdit";
import AutoForm from "../../core/models/AutoForm";
import {Button, Box, Badge} from '@chakra-ui/react';
import Modal from '../../core/components/modal/Modal';
import React, {useState} from 'react';

export default function RequirementForm(props) {

    const [show, setShow] = useState(false);

    const [edit, setEdit] = useState(false);

    console.log(show);
    console.log(edit);
    console.log(props.requirement);

    return (

        <>

            <Button size='xs' 
                _hover={{color: 'blue.500', bg: 'blue.50'}}
                                            color='gray'
                                            variant='ghost' onClick={() => {setShow(true)}}>
                Edit {show}
            </Button>

            {show} {edit}

            {show &&
            
                <Modal  
                    title={'Editting ' + props.requirement.title}
                    onClose={() => setShow(false)}

            footer={
                <Button onClick={(e) => {
                    e.preventDefault();
                    console.log(e.target.closest('.chakra-modal__content-container').querySelector('form'));
                    e.target.closest('.chakra-modal__content-container').querySelector('form button[type="submit"]').click();
                } } colorScheme='blue' variant='outline' className='btn-8'>Save</Button>

            }
                    >
                    
                    <AutoForm
                        url={'requirements' + '/' + props.requirement.uuid}
                        item={props.requirement}
                        refresh={() => setShow(false)}
                        hideSubmit={true}
                        inputs={[
                            {
                                name: 'title'
                            },
                            {
                                name: 'description'
                            },
                            {
                                name: 'active',
                                type:'checkbox'
                            },
                            {
                                name: 'recommendedProviderName',
                                label: 'Recommended Provider Name'
                            },
                            {
                                name: 'recommendedProviderURL',
                                label: 'Recommended Provider URL'
                            },
                            {
                                name: 'recommendedProviderLogoURL',
                                label: 'Recommended Provider Logo URL'
                            },
                            {
                                name: 'defaultDuration',
                                label: 'Default Duration'
                            },
                        ]}
                        refresh={props.refresh}
                        />
                
                </Modal>
            
            }
        
        </>

    )
}



