import { HStack, Square, Image, Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton, Divider} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';
import Splash from '../../static/layout/NewSplash';
import axios from 'axios';
import { set } from 'date-fns';
import moment from 'moment';
import {MdSend} from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

import LoadingCircle from '../../static/layout/LoadingCircle';

let roles = {
    assistant: 'PyroBot',
}

export function AITask(props) {

    console.log("AITask", props);

    const [loading, setLoading] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [completed, setCompleted] = useState(props.completed ? props.completed : false);
    const [requested, setRequested] = useState(false);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    const [result, setResult] = useState(null);
    
    // generate a uuid for the task
    const [uuid, setUuid] = useState(uuidv4());
    
    const [values, setValues] = useState({});

    let Toast = useToast();

    function generate() {

        setLoading(true);


        // props.prompt is the message to the user
        // replace all instances of {field} with the field value
        // fields is an array of fields to be filled in
        // {
        //     name: 'field',
        //     label: 'Field',
        //     value: 'Value'
        // }
        
        let message = props.prompt;
        // Loop through fields and replace
        if (props.fields) {
            props.fields.forEach(field => {
                // gather value from inputs
                let value = document.querySelector('input[name="' + uuid + '-' + field.name + '"]').value;

                if (!value) {
                    value = '{missing_variable}';
                }

                // Replace all instances of {field} with value
                while (message.includes('{' + field.name + '}')) {
                    message = message.replace('{' + field.name + '}', value);
                }

            });
        }

        let tmpMessages = [];
        tmpMessages.push({
            role: 'user',
            content: message
        });

        if (props.response === 'json' || props.response === 'JSON') {

            tmpMessages.push({
                    "role": "assistant",
                    "content": "Here is the JSON requested:\n{"
                });

        }
        
        let url =  'assistant';
        let config = {
            params: {
                prompt: message,
                messages: tmpMessages
            }
        };

        console.log('URL', url, 'Config', config, 'Messages', tmpMessages);
        let resp = API.postItems({timeout: 40, url, config})
        .then (response => {
            console.log('Response', response, tmpMessages);
            // Get latest message from array returned
            let latest = response.data.response;
            let intError = false;

            // Check if valid JSON
            if (props.type === 'json') {
                try {
                    JSON.parse(latest);
                } catch (e) {
                    console.log('Error parsing JSON', e);
                    intError = e;
                }


            }


            if (intError) {
                // Remove any text from before the first { or [
                let first = latest.indexOf('{');
                let second = latest.indexOf('[');
                let start = 0;
                if (first > -1 && second > -1) {
                    start = Math.min(first, second);
                } else if (first > -1) {
                    start = first;
                } else if (second > -1) {
                    start = second;
                }
                latest = latest.slice(start);
            }

            console.log('Latest2', latest);
            // Check if valid JSON
            if (props.type === 'json') {
                let intError = false;
                try {
                    JSON.parse(latest);
                } catch (e) {
                    console.log('Error parsing JSON', e);
                    intError = e;
                }
            }



            props.onCompletion(latest);


            setResult(latest);
            setCompleted(true);
            setLoading(false);
        })
        .catch(error => {
            console.log('Error', error);
            setError(error);
            Toast({
                title: 'Error using AI',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
            setLoading(false);
        });

    }


    return (
        <Stack p={2} {...props}>

            {loading &&
                <LoadingCircle />
            }

            {completed &&
                <Box>
                    <Text fontSize='xs' color='subtle' display='none'>
                        {result}
                    </Text>
                    <Text fontSize='xs' color='subtle'>
                        {props.title + ": " } AI generation completed, <Text as='span' color='blue.200'
                        _hover={{color: 'blue.500'}}
                        cursor='pointer' onClick={() => {
                            setError(false);
                            setRequested(false);
                            setLoading(false);
                            setCompleted(false);
                            setResult(null);
                        }}>repeat</Text>.
                    </Text>
                </Box>
            }

            {error &&
                <Text fontSize='xs' color='red.500'>
                    {props.title + ": " }error generating AI content: {error.message}.
                    <Text pl={1} as='span' color='blue.500' cursor='pointer' onClick={() => {
                        setError(false);
                        setRequested(false);
                        setLoading(false);}
                    }>Reset</Text>.
                </Text>
            }

            {!requested && !declined && !loading && !completed &&

                <Box pb={2}>
                    <Heading size='label' mb={1}>
                        {props.title}
                    </Heading>

                    <Text fontSize='xs' color='subtle' mb={1}>
                        {props.description}
                    </Text>

                    <Stack spacing={4} pb={2}>
                        {props.fields && props.fields.map((field, index) => {
                            return (
                                <InputWrapper key={index}
                                    label={field.label}
                                    name={uuid + '-' + field.name}
                                    type='input'
                                    placeholder={field.placeholder}
                                    setValue={(v) => {
                                        console.log(v);
                                    }}
                                    // If hit enter then next field or click uuid-generate
                                    onKeyPress={e=> {
                                        console.log('Key pressed', e.key);
                                        if (e.key === 'Enter') {
                                            console.log('Enter pressed')
                                            if (index + 1 < props.fields.length) {
                                                let next = document.querySelector('input[name="' + uuid + '-' + props.fields[index + 1].name + '"]');
                                                if (next) {
                                                    next.focus();
                                                } else {
                                                    document.querySelector('#' + uuid + '-generate').click();
                                                }
                                            } else {
                                                document.querySelector('#' + uuid + '-generate').click();
                                            }   
                                        }
                                    }}
                                    size='sm'
                                    />
                            )
                        })}
                    </Stack>

                    <ButtonGroup variant='outline' size='xs'>
                        <Button 
                            colorScheme='red'
                            onClick={() => {    
                            setDeclined(true);
                        }}>Decline</Button>
                        <Button
                            id={uuid + '-generate'}
                            colorScheme='blue'
                            onClick={() => {
                                setRequested(true);
                                setLoading(true);
                                generate();
                            }}
                        >Generate</Button>
                    </ButtonGroup>

                </Box>

            }

            {declined &&

                <Text fontSize='xs' color='subtle'>
                    {props.title + ": " }AI generation declined, <Text as='span' color='blue.200' pl={1}
                    _hover={{color: 'blue.500'}}
                    cursor='pointer' onClick={() => {setDeclined(false);}}>undo</Text>.
                </Text>
            }

        </Stack>
    )
}


export default function Assistant(props) {

    const [messages, setMessages] = useState([
        {
            role: 'assistant',
            content: 'Hello, how can I help you today?',
        }
    ]);
    const [submitOnEnter, setSubmitOnEnter] = useState(true);

    const [loading, setLoading] = useState(false);

    let Toast = useToast();

    function submitMessage(message) {

        if (!message) {
            Toast({
                title: 'Message cannot be empty',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
            return;
        }
        console.log('Submitting message', message);
        setLoading(true);

        let tmpMessages = messages;
        tmpMessages.push({
            role: 'user',
            content: message
        });
        
        setMessages(tmpMessages);
        document.querySelector('textarea[name="message"]').value = '';

        let url =  'assistant';
        let config = {
            params: {
                prompt: message,
                messages
            }
        };

        let resp = API.postItems({timeout: 40, url, config})
        .then (response => {
            console.log('Response', response, messages);
            
            tmpMessages = messages;
            tmpMessages.push({
                role: 'assistant',
                content: response.data.response
            });
            setMessages(tmpMessages);

            setLoading(false);
        })
        .catch(error => {
            console.log('Error', error);
            // Remove last message
            tmpMessages = messages;
            tmpMessages.pop();
            setMessages(tmpMessages);
            Toast({
                title: 'Error submitting message',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
            setLoading(false);
        });

    }
        


    return (
        <Splash p={0} pb={0} maxW='800px' mb={16}>
        <Stack minW='-webkit-fill-available' w='100%' spacing={0}>
        <Stack p={2}>
            {messages.map((message, index) => {

                return (
                    <Stack key={index} p={2} >

                        <Stack direction='row' spacing={4}
                            bg={message.role === 'assistant' ? 'blue.100' : 'green.100'}
                            maxW='80%'
                            borderRadius='md'
                            p={2}
                            alignSelf={message.role === 'assistant' ? 'flex-start' : 'flex-end'}
                            boxShadow='sm'
                            >
                            <Stack>
                                {message.content.split('\n').map((line, index) => {
                                    return (
                                        <Text key={index}>
                                            {line}
                                        </Text>
                                    )

                                })}
                            </Stack>
                        </Stack>

                    </Stack>
                )
            })}

            {loading && 
                 <Stack key='awaiting-response' p={2} >

                 <Stack direction='row' spacing={4}
                     bg='blue.100'
                     maxW='80%'
                     borderRadius='md'
                     p={2}
                     alignSelf='flex-start'
                     >
                     <Stack>
                        <LoadingCircle />
                     </Stack>
                 </Stack>

             </Stack>
         
         }

         </Stack>

            <Box p={0}
                borderTop='1px solid'
                borderColor='blue.50'
                >
            <Flex p={0}
                position='relative'
                bg='white'
                justifyContent={'stretch'}
                >

                <Box 
                    flex='1'
                    >
                    <InputWrapper
                        placeholder='Respond'
                        type='textarea'
                        name='message'
                        border='none'
                        bg='transparent !important'
                        onChange={(e) => {
                            console.log('Change', e.target.value);
                            // if last character is a newline, submit message
                            if (e.target.value.slice(-1) === '\n') {
                                let newMessage = e.target.value.slice(0, -1);
                                // check if checkbox is checked
                                if (submitOnEnter) {
                                    submitMessage(newMessage);
                                }
                            }
                        }}
                        />
                </Box>
                <Box
                    alignSelf={'center'}
                    pr={2}
                    >
                    <IconButton
                        onClick={() => {
                            let newMessage = document.querySelector('textarea[name="message"]').value;
                            submitMessage(newMessage);
                        }}
                        aria-label='Send message'
                        icon={<MdSend />}
                        colorScheme='blue'
                    />
                </Box>
            </Flex>

            </Box>
        </Stack>
        </Splash>
    )
}