import React from 'react';
import Layout from '../../core/layout/Layout';
import ShowModel from '../../core/models/ShowModel';
import AutoForm from '../../core/models/AutoForm';
import {RequirementGroupDisplayRecord} from './RequirementGroupDisplayRecord';

export default function Requirements (props) {

    console.log("LOADING REQUIREMENT GROUPS");

    return (
            <ShowModel
                title="Requirement Groups"
                description="This page allows you to view and edit the requirement groups."
                secondaryButtonText=""
                primaryButtonText="Add Requirement Group"
                primaryButtonModal={true}
                modalTitle="Add Requirement Group"
                autoFormUrl= 'requirementgroups/'
                autoFormInputs={[
                    {
                        name: 'title'
                    },
                    {
                        name: 'description'
                    }
                ]}
                filterBy={[]}
                hideCount={true}
                setTitle={['title']}
                dataKey='requirementgroups'
                url='requirementgroups'
                customDisplayRecord={<RequirementGroupDisplayRecord />}
                />        


    )   
}