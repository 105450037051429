"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.3125rem',
    lg: '0.375rem',
    xl: '0.5rem',
    '2xl': '0.625rem',
    '3xl': '0.75rem',
    full: '9999px',
};
