import React, {useContext, useState, useEffect} from 'react';
import {Divider, FormControl, FormLabel, InputGroup, InputRightElement, Heading, Text, Flex, Box, Stack, Button, Input} from '@chakra-ui/react';
import { AccountContext } from '../cognito/components/Accounts';
import { CognitoUser, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Outlet } from 'react-router-dom';
import userPool from '../cognito/UserPool';
import API from '../../core/utils/API';
import Code from '../../core/components/forms/Code';

export default function PhoneMFAWrapper(props) {

    const { getSession, logout } = useContext(AccountContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneConfirmed, setPhoneConfirmed] = useState(false);
    const [mfaExpires, setMfaExpires] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [jti, setJti] = useState('');
    const [requireSession, setRequireSession] = useState(props.requireSession || false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const check = () => {

        setLoading(true);
        setError('');

        // Check phone exists and is verified
        getSession()
            .then(session => {

                console.log('CHECK: Phone MFA session', session);

                setLoggedIn(true);
                setPhone(session.phone_number);
                setPhoneConfirmed(session.phone_number_verified);
                setJti(session.idToken.payload.jti);


                // Check for phone MFA session
            
                API.getItems({url: 'mfa/session', config: {params: {type: 'phone'}}}).then(res => {

                    console.log('MFA session', res);
                    setMfaExpires(res.data.sessionExpires);
                    setLoading(false);

                }).catch(err => {

                    console.log('MFA session error', err);
                    setMfaExpires(null);
                    setError(err.response.data.message);
                    setLoading(false);

                    console.log('MFA session expires', err.response.data.sessionExpires);
                    // If no session, create one
                    if (!err.response.data.sessionExpires) {
                        sendSMSCode();
                    }

                });


            })
            .catch(err => {

                console.log('Phone MFA error', err);
                setLoggedIn(false);
                setPhone('');
                setPhoneConfirmed(false);
                setError(err.message);
                setMfaExpires(null);
                setJti('');
                setLoading(false);

            });

    
        
    }

    

    useEffect(() => {
        check();
    }, []);



    const sendSMSCode = () => {

        console.log("Sending SMS Code");

        if (phone === undefined) {
            //console.log("No phone set");
            setError('Phone number not set');
            return;
        }
        
        API.postItems({url: 'sms/send', data: {to: phone}})
        .then(response => {
            //console.log(response);
        })
        .catch(error => {
            console.log(error);
        });


    };

    const validateCode = (code) => {

        console.log("Validating code ", code);

        if (code === undefined) {
            console.log("No code set");
            setError('Code not set');
            return;
        }

        API.postItems({url: 'sms/validate', data: {code: code, phone: phone}})
        .then(response => {
            console.log("VALIDATED CODE:", response);
            check();
        })
        .catch(error => {
            console.log("ISSUE VALIDATING CODE:", error);
            setError(error.response.data.message);
        });


    };

    const clearNumber = () => {

        console.log("Clearing number");

        if (phone === undefined) {
            console.log("No phone set");
            setError('Phone number not set');
            return;
        }

        // Get email from session
        const cognitoUser = userPool.getCurrentUser();
        
        console.log("cognitoUser", cognitoUser);
        // Update phone number
        let attributeList = [];
        let attribute = new CognitoUserAttribute({
            Name: 'phone_number',
            Value: ''
        });
        attributeList.push(attribute);

        // Authenticate user
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.log(err);
                setError(err.message);
                return;
            }
            console.log('session validity: ' + session.isValid());
            console.log(session);
        });

        
        // Clear phone number in Cognito
        cognitoUser.updateAttributes(attributeList, (err, result) => {
            if (err) {
                console.log(err);
                setError(err.message);
                return;
            }
            console.log(result);
            setPhone('');
            setPhoneConfirmed(false);
            // Refresh page
            window.location.reload();
            setError('Phone number cleared');
        });

        

    };



    return (

        <>


            { loggedIn && phoneConfirmed && mfaExpires ?
                <>
                {props.children}
                </>

                :

                <>
                    <Stack spacing={4} minH='400px' maxW='500px'  m='auto'>
                        <Stack pl={8} pr={8} pt={4} pb={4} spacing={4} textAlign='center' alignContent='center'>

                            {error &&
                                <Text color='red.500'>{error}</Text>
                            }
                            <Text maxW='300px' alignSelf='center'>
                                We have sent a one-time code to your phone, we can 
                                <Text ml='3px' as='span' cursor='pointer' _hover={{color: 'blue.500'}} onClick={() => (sendSMSCode())} >
                                    send a new code
                                </Text> or you can 
                                <Text ml='3px' as='span' cursor='pointer' _hover={{color: 'blue.500'}} onClick={() => (clearNumber())} >
                                    change your phone number
                                </Text>.
                            </Text>

                            <Text fontSize='1.5em' lineHeight='2em' color='black' fontWeight='strong'>{phone}</Text>

                            <Text maxW='300px' alignSelf='center'>
                                Please enter the code below to confirm your MFA session.
                            </Text>

                            <Code 
                                inputName='code'
                                codeLength={6}
                                setMessage={setConfirmationCode}
                                return={validateCode}
                                />

                            
                        </Stack>
                        {props.onClose &&
                            <Button alignSelf='center' w='fit-content' size='sm' variant='ghost' onClick={props.onClose}>Close</Button>
                        }
                    </Stack>

                </>

            }
        </>

    );

}
