"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getColorScheme = function (props) {
    var _a = props.status, status = _a === void 0 ? 'info' : _a;
    var colorScheme = {
        info: 'blue',
        loading: 'inherit',
        success: 'green',
        warning: 'yellow',
        error: 'red',
        none: 'inherit',
        undefined: 'inherit',
        inherit: 'inherit',
        null: 'inherit',
    }[status];
    return {
        background: colorScheme === 'inherit' ? '' : "".concat(colorScheme, ".500"),
        color: colorScheme === 'yellow' ? 'black' : 'white',
    };
};
var getBaseStyle = function (props) {
    var _a = getColorScheme(props), background = _a.background, color = _a.color;
    return {
        container: {
            lineHeight: '7',
            background: background,
            color: color,
            borderRadius: 'base',
            fontSize: 'md',
            minH: '48px',
            px: 4,
            py: 2,
        },
        icon: {
            color: color,
            height: 6,
            width: 6,
        },
    };
};
exports.default = {
    baseStyle: getBaseStyle,
    variants: [],
};
