import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './core/utils/API';
import './core/utils/AxiosConfig';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ChakraProvider } from '@chakra-ui/react'
//import { theme } from '@chakra-ui/pro-theme' // when using npm
import { theme } from './theme/index';
import '@fontsource/inter/variable.css';
import "@fontsource/dm-sans";
import "@fontsource/raleway";

import { Auth0Provider } from "@auth0/auth0-react";
import { Account } from './auth/cognito/components/Accounts';

import Interceptor from './auth/Interceptor';
import {KyberEncryption} from './auth/KyberEncryption';
import { SubscriptionWrapper } from './auth/SubscriptionContext';

/*
export default extendTheme({
  // ...

  fonts: {
    header: `Satoshi-Regular`,
  },
})
*/

// From the tutorial demo download, details MUST BE CORRECT
// "domain": "dev-ojtramp.eu.auth0.com",
// "clientId": "T6zs...",  
// "audience": "https://api.pointmarkdevelopment.com" 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Account>
          <ChakraProvider theme={theme}>
            <Interceptor>
              <KyberEncryption>
                <SubscriptionWrapper>
                  <App />
                </SubscriptionWrapper>
              </KyberEncryption>
            </Interceptor>
          </ChakraProvider>
      </Account>
  </React.StrictMode>

);

// Between Accounts
/*
<Auth0Provider
domain={AUTH0_DOMAIN} // taken from the SPA application in Auth0
clientId={AUTH0_CLIENT_ID} // taken from the SPA application in Auth0
redirectUri={window.location.origin + "/checkregistration"}//{process.env.REACT_APP_AUTH0_CALLBACK_URL} //{window.location.origin + "/checkregistration"} // {process.env.REACT_APP_AUTH0_CALLBACK_URL} 
// scope="read:current_user update:current_user_metadata"
// useCookiesForTransactions={true}
audience={AUTH0_AUDIENCE} // taken from your API in Auth0
authorizationParams={{
  domain: {AUTH0_DOMAIN}, // taken from the SPA application in Auth0
  clientId: {AUTH0_CLIENT_ID}, // taken from the SPA application in Auth0
  connection: "email",
  audience: {AUTH0_AUDIENCE},
  //redirectUri: {window.location.origin + "/checkregistration"}, //{process.env.REACT_APP_AUTH0_CALLBACK_URL} //{window.location.origin + "/checkregistration"} // {process.env.REACT_APP_AUTH0_CALLBACK_URL} 
}}

>
    </Auth0Provider>
    */


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
