import React, {useState, useContext, useEffect} from 'react';
import {Image, IconButton, Alert, Flex, AlertIcon, AlertTitle, AlertDescription, CloseButton, Heading, Link, Text, Center, Box, Stack, Button, Input, InputGroup, InputRightElement, Checkbox} from '@chakra-ui/react';
import { AccountContext } from './Accounts';
import Signup from './Signup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import userPool from '../UserPool';
import Modal from '../../../core/components/modal/Modal';
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import { MdVisibilityOff, MdVisibility } from 'react-icons/md';
import Code from '../../../core/components/forms/Code';
import ChangePassword from './ChangePassword';
import { set } from 'date-fns';

export default (props) => {

    const [signUp, setSignUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(props.email);
    const [password, setPassword] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');

    const [stage, setStage] = useState(props.action ? props.action : 'Login'); // TODO - login manager, catch no action and set default to login


    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const { authenticate, forgotPassword } = useContext(AccountContext);
    
    let disabled ='disabled';

    const login = e => {

        setLoading(true);

        if (e) {
            e.preventDefault();
        }

        setError(null);

        authenticate(email, password) 
            .then(data => {
                console.log('Logged in!', data); // data is empty
               // props.onLogin();
                window.location.replace("/onboarding?forward=true");
//                window.location.reload();
            })
            .catch(err => {
                console.error('Failed to login!', err);
                setError(err.message);
                if (err.code === 'UserNotConfirmedException') {
                    setStage('Confirm your e-mail')
                    setError('You have not confirmed your email, please check your inbox for the activation code.');
                } else if (err.code === 'PasswordResetRequiredException') {
                    setStage('Reset');  
                    forgotPassword(email);
                    setError('You need to reset your password as it has expired.');
                } else if (err.code === 'UserNotFoundException') {
                    signup();
                    //setStage('Signup');
                    //setError('You have not registered before, please enter your phone number (for added security when reseting your password).');    
                } else {
                    setStage('Login');
                }
                setLoading(false);


            });

    };


    const signup = () => {

        setLoading(true);
        setError(null);

        // Check terms and conditions
        if (stage === 'Signup') {
            if (!document.getElementById('terms').checked) {
                setError('You must agree to the terms and conditions to continue.');
                setLoading(false);
                return;
            }
        }

        let params = []
        if (phone_number) {
            params.push({
                Name : 'phone_number',
                Value : phone_number
            })
        }

        userPool.signUp(email, password, params, null, (err, data) => {
            if (err) {
                console.log(err);
                setError(err.message);
                setLoading(false);
                return;
            } else {
                setStage('Confirm your e-mail');
            }
            setLoading(false);

        });
    };


    const confirm = () => {

        setLoading(true);
        setError(null);
        
        const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
        const code = confirmationCode; //inputElements.map(({value})=>value).join('')
        //console.log(code);
        cognitoUser.confirmRegistration(code, true, function(err, result) {
            if (err) {
                console.log(err);
                setError(err.message);
                setLoading(false);
                return;
            }
            if (email != null && password != null) {
                login();
            } else {
                setStage('Login');
                setLoading(false);
            }

        });

    };

    function updateCode(code) {
        setConfirmationCode(code);
    }

    useEffect(() => {
        if (confirmationCode.length === 6) {
            confirm();
        }
    }, [confirmationCode]);

    return (

        <Box minW='324px' maxW='324px' textAlign='center'>
                          <Image src='/logo.svg' h='50px' mt='12px' mb={8} mr='auto' ml='auto' alt='Logo' mt={{base: '48px', md: '0'}} 
                            onClick={() => {
                                window.location.replace("/");
                            }}
                            cursor='pointer'
                            /> 


<form >
                        {error && 
                            <Alert status="error" mb={4}>
                                <AlertDescription>{error}</AlertDescription>
                                <CloseButton position="absolute" right="4px" top="4px" onClick={() => setError('')} />
                            </Alert>
                        }
                        {stage === 'Reset' &&
                        
                            <ChangePassword 
                                email={email} 
                                setMasterEmail={setEmail}
                                setMasterPassword={setPassword}
                                setStage={setStage}
                                />
                        
                        }
                        {(stage === 'Login' || stage === 'Signup') &&
                            <Stack spacing={4} mt={4} mb={8}  ml='auto' mr='auto'>
                                <Text textAlign='center'>
                                    {stage==='Login' ?
                                    <>
                                        Login below, or if you have already then enter a <Text as='span' color='blue.500' cursor='pointer' onClick={() => {setStage('Confirm your e-mail')}}>confirmation code</Text>.
                                    </>
                                    :
                                    <>
                                        Sign-up below, or if you have already then enter a <Text as='span' color='blue.500' cursor='pointer' onClick={() => {setStage('Confirm your e-mail')}}>confirmation code</Text>.
                                    </>
                                    
                                    }

                                </Text>
                                <Text mb={4} textAlign='center' color='blue.500' cursor='pointer' 
                                    fontSize='sm'
                                    onClick={() => {
                                        if (email) {
                                            forgotPassword(email);
                                            setStage('Reset');
                                        } else {
                                            setError('Please enter your email address to reset your password.');
                                        }
                                    }}>
                                    Forgot your password?
                                </Text>
                                <Input placeholder="Email" value={email} 
                                    onChange={e => setEmail(e.target.value)}
                                    onKeyDown={
                                        e => {
                                            if (e.keyCode === 13) {
                                                // set focus to password
                                                document.getElementById('password').focus();
                                            };
                                        }
                                    }
                                    />
                                <InputGroup  size='md'>
                                    <Input
                                        id='password' 
                                        type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} 
                                        onChange={e => {
                                            setPassword(e.target.value); 
                                        }}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                if (stage === 'Login') {
                                                    login();
                                                } else {
                                                    signup();
                                                }
                                            };
                                        }}
                                        />
                                    <InputRightElement width='4.5rem'>
                                    <IconButton colorScheme='blue' mr={-2} variant='ghost' className='btn-8' size='sm' onClick={()=>setShowPassword(!showPassword)}>
                                            {showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                                    </IconButton>
                                    </InputRightElement>
                                </InputGroup>
                                {stage === 'Signup' &&

                                    <InputGroup>
                                        <Checkbox id='terms' colorScheme='blue' size='sm' variant='outline'>
                                            I agree to the <a href='https://www.pyrexia.io/terms' target='_blank'>terms and conditions</a> and <a href='https://www.pyrexia.io/privacy' target='_blank'>privacy agreement</a> 
                                        </Checkbox>
                                    </InputGroup>
                                }
                                {stage === 'SignupBLOCK' &&
                                    <PhoneInput
                                        display='none'
                                        country={'gb'}
                                        value={phone_number}
                                        onChange={phone => setPhoneNumber("+"+phone)}
                                        />
                                }
                            </Stack>
                        }
                        {stage === 'Confirm your e-mail' &&
                        
                            <Stack mb={8}>

                                <Text mb={4}>
                                    We sent a confirmation code to your email address. Please enter it below or 
                                    <Text as='span' color='blue.500' cursor='pointer' onClick={() => {setStage('Login')}}> login</Text>.
                                </Text>

                             
                                <Input placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
                                

                                <Code 
                                    codeLength={6}
                                    align='left'
                                    return={(code) => {updateCode(code)}}
                                    name='confirmationCode'
                                    align='center'
                                />


                            </Stack>
                        
                        }
                    </form>
            <Flex justifyContent='space-between'  m='auto' spacing={2}>
                {stage === 'Login' &&
                    <>  
                        <Button mr={2} onClick={() => setStage('Signup')} className='btn-8' variant='outline' w='min-content'
                            >Sign Up</Button>
                        <Button onClick={login} type='submit' className='btn-8' variant='outline' colorScheme='blue' w='min-content'
                            disabled={email && password ? '' : 'disabled'}
                            isLoading={loading}
                            >Login</Button>
                    </>
                } 
                {stage === 'Signup' &&
                    <>
                        <Button mr={2} onClick={() => setStage('Login')} className='btn-8' variant='outline' w='min-content'>Login</Button>
                        <Button onClick={signup} type='submit' className='btn-8' variant='outline' colorScheme='blue' w='min-content'
                            disabled={email && password ? '' : 'disabled'}
                            isLoading={loading}
                            >Signup</Button>
                    </>
                }
            </Flex>
        </Box>
                           

    );

};