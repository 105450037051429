import React from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    useDisclosure,
    MenuItem,
    HStack
    } from "@chakra-ui/react";


export default function WarningDialogue(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
  
    return (
      <>
        {props.type === 'Button' &&
            <Button colorScheme='red' onClick={onOpen}>
                {props.triggerText}
            </Button>
        }
        {props.type === 'MenuItem' &&
            <MenuItem onClick={onOpen}>
                {props.triggerText}
            </MenuItem>
        }
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={ 
            (props.onClose !== undefined) ?
            props.onClose :
            () => {onClose(false)}


          }
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='1.5em' fontWeight='bold'>
                {props.title}
              </AlertDialogHeader>
  
              <AlertDialogBody fontSize='1em' mb={4}>
                {props.description}
              </AlertDialogBody>
  
              <AlertDialogFooter alignContent='space-between' >
                <HStack spacing={4} w='fill-available' justifyContent='space-between'>
                    
                    <Button size='sm' ref={cancelRef} variant='solid' onClick={() => {onClose(); props.actionFunction(true)}}>
                    Cancel
                    </Button>
                    <Button size='sm' colorScheme='red' variant='outline' onClick={() => {onClose(); props.actionFunction(true)}} ml={3}>
                    {props.confirm}
                    </Button>
                </HStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }