import {Input ,Text, InputRightElement, InputGroup, Button, FormControl, FormLabel, FormHelperText, Checkbox, Radio, RadioGroup } from '@chakra-ui/react';


export default function DateWrapper(props) {

    let defaultValue = props.defaultValue ? new Date(props.defaultValue) : null;
    let d = null;
    
    if (props.type === 'datetime-local') {
        d = defaultValue ?
            defaultValue.getFullYear() + '-' + (defaultValue.getMonth() + 1).toString().padStart(2, '0') + '-' + defaultValue.getDate().toString().padStart(2, '0') + 'T' + defaultValue.getHours().toString().padStart(2, '0') + ':' + defaultValue.getMinutes().toString().padStart(2, '0')
            : null;
    } else if (props.type === 'date') {
        d = defaultValue ?
            defaultValue.getFullYear() + '-' + (defaultValue.getMonth() + 1).toString().padStart(2, '0') + '-' + defaultValue.getDate().toString().padStart(2, '0')
            : null;
    }

    // Check if we have a default value for onChange
    let onChange = props.onChange;
    if (onChange === undefined ) {
        onChange = function(e) {
            props.setValue( props.name, e.target.value);
        }
    }


    return (

        <Input type={props.type}
            {...props}
            defaultValue={d}
            onChange={onChange}
            />

    )
}