import {
    Avatar,
    AvatarGroup,
    ButtonGroup,
    Box,
    Badge, 
    Button,
    Heading,
    Input, 
    Container,
    Flex,
    Checkbox,
    List,
    Image,
    SimpleGrid,
    Grid, GridItem,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Divider,
    HStack,
    Tag, TagLabel, TagLeftIcon, TagCloseButton,
    Link,
    Icon,
    Select,
    Wrap,
    Menu, MenuItem, MenuButton, MenuList,
    InputRightElement,
    
    Tab, TabList, TabPanels, TabPanel, Tabs,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td, TableCaption,
    FormControl, FormLabel, FormHelperText, FormErrorMessage, MenuDivider, InputGroup, SkeletonText, SkeletonCircle, TagRightIcon,
  } from '@chakra-ui/react';
  import * as React from 'react';
  import { Link as ReachLink, useSearchParams } from "react-router-dom";
  import Layout from '../../core/layout/Layout';
  import { useState, useEffect } from 'react';
  import moment from 'moment';
  import {MdFilterAlt, MdSortByAlpha, MdFilter, MdOutlineExpandMore, MdOutlineVisibility, MdOutlineEdit, MdOutlineDelete, MdSearch} from 'react-icons/md';
  import Card from '../../core/components/cards/Card';
  import { Dropzone } from '../../core/components/forms/Dropzone';
  import Reminder from './Reminder';
  import Invite from '../../access/Invite';
  import Moment from 'react-moment';
  import { useAuth0 } from "@auth0/auth0-react";
  import API from '../../core/utils/API';
  import AccessSummary from './AccessSummary';
  import {MdCheck, MdClose} from 'react-icons/md';
  import { useNavigate } from 'react-router-dom';
  //import useStateWithCallback from 'use-state-with-callback';
  import pluralize from 'pluralize';
  

function GroupCardCustom(props) {

  return (
    <GridItem 
      pl={{base: '60px', md: 'none'}} 
      pb={{base: 4, md: 'inherit'}} 
      gridColumn={{base: 'span 1', md: 'span 2'}} 
      {...props}
      >
      {props.children}
    </GridItem>
  )
}

export default function SummaryRow(props) {


    let user = props.user;
    let requirementGroups = props.requirementGroups;
    const navigate = useNavigate();
    let [picture, setPicture] = useState(null);


    const getPicture = async (tokenIn) => {

      
      let config = {
        params: {
          userUuid: user.uuid
        }
      }
      
      let picture = await API.getItems({
        url: 'users/picture', 
        config
      })
      .then(res => {
        setPicture(res.data.image);
      })
      .catch(err => {
        console.log("Error getting picture: ", err);
      });

    };

    useEffect(() => {
      getPicture();
    }, []);

    const requestAccess = (requirementGroupUuid, ownerUuid, ownerEmail, duration) => {
        let url = 'access';
        let data = {
            requirementGroupUuids: [requirementGroupUuid],
            email: ownerEmail,
            userUuids: ownerUuid,
            duration: duration,
            type: 'request'
        };

        let response = API.postItems({url, data})
        .then((res) => {
            // The record was successfully approved
            props.refresh();
        })
        .catch((err) => {
            // An error occurred while approving the record
            console.log(err);
        });
    };
    
    const handleRequestManageClick = (decision, request, uuid) => {

        let url = 'access/'+ uuid + '/manage';
        let data = {
            response: decision,
            type: request,
        };
    
        let response = API.postItems({url, data})
        .then((res) => {
            // The record was successfully approved
            props.refresh();
        })
        .catch((err) => {
            // An error occurred while approving the record
            console.log(err);
        });
    };
  

    

    return (


        <SimpleGrid key={user.uuid + "-" + props.setSortCount} position='relative' pr={{base: '100px', md: 0}} borderBottom='1px solid whitesmoke' display={{base: 'block', md: 'grid'}} columns={{base: 0, md: (requirementGroups.length * 2) + 5}} p={{base: 4}} spacing={{base: 2, md: 4}}>

        <GridItem pb={{base: 4, md: 0}} gridColumn={{base: 'span 1', md: 'span 3'}}>
          <HStack>
            <Avatar name={user.displayName} src={picture ? 'data:image/jpeg;base64,' + picture : '/images/avatars/' + Math.floor(Math.random() * 4 + 1) + '.png'} size='md' mr={2} />
            <Stack spacing={0}>
              <Text p={0} m={0} color='emphasis' fontWeight='bold'>{user.displayName}</Text>
              <Text p={0} m={0} fontSize='sm' color='subtle'>{user.displayName === user.email ? 'Awaiting registration' : user.organisation}</Text>
            </Stack>
          </HStack>
        </GridItem>


        {requirementGroups.map((group) => {

          let userGroup = user.requirementGroups[group.uuid];
          let accessUuid = null;
          if (userGroup != undefined) {
            accessUuid = userGroup.accessUuid;
          }

          let expiresText = null;

          if (userGroup != undefined) {
            if (userGroup.expires === null) {
              expiresText = moment(userGroup.expiration).format('DD/MM/YYYY');
            }
          }



          return (

            <GridItem pl={{base: '60px', md: '0'}} pb={{base: 4, md: '0'}} gridColumn={{base: 'span 1', md: 'span 2'}}>

                <Heading size='label' display={{base: 'inherit', md: 'none'}} mb={2}>{group.title}</Heading>

                {accessUuid != null ?

                    <Wrap spacing={2}>
                      {userGroup.status === 'Invite pending' ?

                        <ButtonGroup isAttached size='xs' variant='outline' mb={2}>
                          <Button colorScheme='green' className='btn-8' borderColor='green.500' color='green.500' onClick={() => handleRequestManageClick(true, 'invite', userGroup.accessUuid)} leftIcon={<Icon as={MdCheck} color='green.500'/>}> Approve</Button>
                          <Button colorScheme='red' className='btn-8' pr={2} onClick={() => handleRequestManageClick(false, 'invite', userGroup.accessUuid)} leftIcon={<Icon as={MdClose} color='red.500' _hover={{color: 'invert'}} />}> Decline</Button>
                        </ButtonGroup>    
                      :

                        <>
                          {userGroup.status !== 'ACTIVE' &&
                            <Tag size='sm' color={userGroup.color + ".500"}>
                              {userGroup.status}
                            </Tag>
                          }
                        </>
                      }
                      {userGroup.status === 'ACTIVE' && 
                        <>
                          <Tag size='sm' color='green.500'>
                            {userGroup.totalCompleted} complete
                          </Tag>
                          <Tag size='sm' color='red.500'>
                            {userGroup.totalOutstanding} missing
                          </Tag>
                        </>
                      }

                      {(userGroup.status === 'Invite declined' || userGroup.status === 'Invite declined') ?

                        <Menu >
                          <MenuButton _hover={{color: 'blue.500'}} variant='ghost' size='xs' fontSize='xs' color='subtle' fontWeight='normal' as={Button} rightIcon={<MdOutlineExpandMore />}>
                            Request Access
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => requestAccess(group.uuid, user.uuid, user.email, 6)}>Request 6 months</MenuItem>
                            <MenuItem onClick={() => requestAccess(group.uuid, user.uuid, user.email, 12)}>Request 12 months</MenuItem>
                            <MenuItem onClick={() => requestAccess(group.uuid, user.uuid, user.email, -1)}>Request Indefinitely</MenuItem>
                          </MenuList>
                        </Menu>

                      :

                        <Menu>
                          <MenuButton _hover={{color: 'blue.500'}} variant='ghost' size='xs' fontSize='xs' color='subtle' fontWeight='normal' as={Button} rightIcon={<MdOutlineExpandMore />}>
                            {userGroup.expiration === null ? 'Indefinite access' : 'Access until ' + expiresText }
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => handleRequestManageClick(false, 'cancel', userGroup.accessUuid)}>Cancel access</MenuItem>
                          </MenuList>
                        </Menu>

                      } 

                    
                    </Wrap>

                  :

                    <>
                      <Menu >
                        <MenuButton _hover={{color: 'blue.500'}} variant='ghost' size='xs' fontSize='xs' color='subtle' fontWeight='normal' as={Button} rightIcon={<MdOutlineExpandMore />}>
                          Request Access
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => requestAccess(group.uuid, user.uuid, user.email, 6)}>Request 6 months</MenuItem>
                          <MenuItem onClick={() => requestAccess(group.uuid, user.uuid, user.email, 12)}>Request 12 months</MenuItem>
                          <MenuItem onClick={() => requestAccess(group.uuid, user.uuid, user.email, -1)}>Request Indefinitely</MenuItem>
                        </MenuList>
                      </Menu>
                    </>
                
                }
            
            </GridItem>


          )
        })}

          <GridItem pl={{base: '60px', md: '0'}} pb={{base: 4, md: 'inherit'}} gridColumn={{base: 'span 1', md: 'span 2'}} key={'actions-'+user.uuid}>
       
           <Heading size='label' display={{base: 'inherit', md: 'none'}} mb={2}>Actions</Heading>

            <ButtonGroup flexWrap='wrap' size='xs' variant='outline'>

              <Button mb={1} colorScheme='blue' className='btn-8' variant='outline' 
                  onClick={() => {
                    // Navigate to the user's record
                    // Check if the current url includes root path /organisations
                    if (window.location.pathname.includes('/hr/')) {
                      navigate('/hr/record', { state: { userUuid: user.uuid} });
                    } else {
                      navigate('record', { state: { userUuid: user.uuid} });
                    }
                  }}>
                    View
              </Button>
              <Reminder
                label={<Moment fromNow>{user.lastContacted}</Moment>}
                recipient={user.displayName}
                recipientEmail={user.email}
                recipientUuid={user.uuid}
                outstanding={user.outstanding}
                />
              {user.lastContacted != null &&
                <Text color='subtle' fontSize='xs'>
                  Last contacted <Moment fromNow>{user.lastContacted}</Moment>
                </Text>
              }
            </ButtonGroup>                      
          </GridItem>


      </SimpleGrid>


    )
}