import React, { useState, useEffect, useContext } from 'react';
import { Image, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPerson, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/NewSplash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import { random } from 'sjcl';

export default function CheckForm(props) {


    const [mode, setMode] = useState(null);

    return (

        <Splash maxW={(mode === null || mode === 'submitted') ? '400px' : '800px'} mb='70px'>
            <Stack spacing={4} pt={4}  p={4}>

                    <Box>
                    
                        <Heading fontSize='small' lineHeight='1.2em' mb={2} color='blue.500'>
                            Tropical Lagoon
                        </Heading>
                        <Heading fontSize='32px' lineHeight='1.2em' mb={4}>
                            Resus Trolley
                        </Heading>

                        <Text mb={2}>
                            This is the checklist for the resuscitation trolley on Tropical Lagoon. This includes life saving equipment that needs to be restocked immediately after use.
                        </Text>
                    
                    </Box>
                        <HStack spacing={4} pb={4}>
                            <Box>
                                <Heading fontSize='small' lineHeight='1.2em'>
                                    Frequency
                                </Heading>
                                <Text>
                                    Every Shift (twice a day)
                                </Text>
                            </Box>
                            <Box>
                                <Heading fontSize='small' lineHeight='1.2em'>
                                    Responsibility
                                </Heading>
                                <Text>
                                    Nursing Staff
                                </Text>
                            </Box>
                        </HStack>



            </Stack>

            <Image src='/development/qrcode.png' alt='QR Code' w='110%' h='auto' />


        </Splash>
    )
}