
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { 
    Alert,
    AlertIcon,
    AlertTitle,
    Textarea,
    AlertDescription,
    ButtonGroup, Checkbox, Wrap, Tag, TagLabel, IconButton,Table, Tbody, Tr, Th, Td, Icon, Circle, Avatar, Stack, HStack, GridItem, Flex, useToast, Box, Text, Heading, Input, Button, List, ListItem, Grid, 
    border,
    SimpleGrid} from '@chakra-ui/react';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import API from '../../core/utils/API';
import {MdCheck, MdClose, MdLock, MdList, MdAccessTime, MdExpand, MdExpandMore} from 'react-icons/md';
import Moment from 'react-moment';
import Splash from '../../static/layout/Splash';
import axios from 'axios';
import Loading from '../../static/layout/LoadingCircle';
import Layout from '../../core/layout/Layout';
import { set } from 'date-fns';
import pluralize from 'pluralize';
import { Outlet } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import Sidebar from './flow/Sidebar';
import Card from './components/Card';
import Stage from './flow/Stage';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Controls,
  MiniMap,
  Background,
  MarkerType,
  useUpdateNodeInternals
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import Node from './nodes/Node';
import EntryNode from './nodes/EntryNode';
import ExitNode from './nodes/ExitNode';
import Wayfarer from './nodes/Wayfarer';
import GroupNode from './nodes/GroupNode';
import Group from './nodes/Group';
import InternalNode from './nodes/InternalNode';
import NotificationNode from './nodes/Notification';
import { on } from 'stream';

import Tasks from './flow/Tasks';
import {adhdInitialNodes, adhdInitialEdges} from './demo/ADHD';
import {demoInitialNodes, demoInitialEdges} from './demo/Basic';
import Weighting from './flow/Weighting';

const nodeTypes = {
  default: Node,
  node: Node,
  entry: EntryNode,
  exit: ExitNode,
  wayfarer: Wayfarer,
  groupnode: GroupNode,
  group: Group,
  internal: InternalNode,
  notification: NotificationNode,
};

const people = [
  {
    name: 'Oliver Trampleasure',
    role: 'Clinical Lead',
    url: 'https://bit.ly/dan-abramov',
  },
  {
    name: 'Ali Jawad',
    role: 'Information Governance',
    url: 'https://bit.ly/dan-abramov',
  },
];

let id = 1;
const getId = () => `${id++}`;


function Flow() {

  const [show, setShow] = useState(true);

  const navigate = useNavigate();

  const [generating, setGenerating] = useState(false);
  const [showGeneratingToast, setShowGeneratingToast] = useState(true);
  const [generatingIndex, setGeneratingIndex] = useState(1);
  const [generatingInternalIndex, setGeneratingInternalIndex] = useState(0);
  let generatingItems = [
    'Attaching sources',
    'Drafting answers',
    'Assigning staff'
  ];

  const [title, setTitle] = useState(window.location.href.includes('2d5748b7-0dfd-40d7-934f-10a1dca4d397') ? 'ADHD Digital Tool' : 'Process');
  // Check if adhd in url
  let initialNodes = demoInitialNodes;
  let initialEdges = demoInitialEdges;
  console.log(window.location.href);
  if (window.location.href.includes('2d5748b7-0dfd-40d7-934f-10a1dca4d397')) {
    console.log('ADHD');
    initialNodes = adhdInitialNodes;
    initialEdges = adhdInitialEdges;
  }
  const updateNodeInternals = useUpdateNodeInternals();

  const [stage, setStage] = useState(null);
  const [node, setNode] = useState(null);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(null);
  // Get tmp nodes
  useEffect(() => {

    let tmpNodes = initialNodes;

    // Loop through each tmp node
    tmpNodes.forEach((node) => {

      // Add into data the click function
      node.data.onDoubleClick = () => {
        console.log('double click', node.id)
        setStage(node);
      }

    });

    setNodes(tmpNodes);

  }, []);


  useEffect(() => {
    // This effect will trigger every time nodes are updated.
    // Any additional logic to handle updates can be added here.
  }, [nodes]);


  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === '1') {
          // it's important that you create a new node object
          // in order to notify react flow about the change
          return {
            ...node,
            style: {
              ...node.style,
            },
          };
        }

        return node;
      }),
    );
  }, [setNodes]);

  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { screenToFlowPosition } = useReactFlow();
  const onConnect = useCallback((params) => {
    // reset the start node on connections
    connectingNodeId.current = null;
    params['type'] = 'smoothstep';
    params['animated'] = 'true';
    params['reconnectable'] = 'target';
    params['markerEnd'] = {
        type: MarkerType.Arrow,
    };
    setEdges((eds) => addEdge(params, eds));
  }, []);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          sourcePosition: 'right',
          targetPosition: 'left',
          type: 'node', 
          data: { 
            label: `New Stage`,
            status: 'notstarted',
            description: 'You can add a description here',
            totalSteps: 0,
            completedSteps: 0,
          },
          origin: [0.5, 0.0],
        };

        // Now add onDoubleClick function
        newNode.data.onDoubleClick = () => {
          console.log('double click', newNode.id)
          setStage(newNode);
        }

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({ id, source: connectingNodeId.current, target: id,

            animated: true,

           }),
        );
      }
    },
    [screenToFlowPosition],
  );

  const onLoad = reactFlowInstance => {
  
    // Start checking for nodes and edges, if not set then timeout and check again
    if (!nodes || !edges) {
      console.log('CANT fit view');
      setTimeout(() => {
        // Check again
        if (!nodes || !edges) {
          console.log('CANT fit view');
        } else {
          console.log('fitting view');
        }
      }, 1000);
    } else {
      console.log('fitting view');
      //{nodes && edges && reactFlowInstance.fitView()}
    }


  };

  // Refresh on node change
  useEffect(() => {
    console.log('nodes changed');

    // Update stage
    let tmpStage = stage;
    if (tmpStage) {
      let tmpNodes = nodes;
      tmpNodes.forEach((node) => {
        if (node.id === tmpStage.id) {
          tmpStage = node;
        }
      });
      setStage(tmpStage);
    }

    updateNodeInternals();

  }, [nodes]);


  function updateNodeData(nodeId, key, value) {

    // Find the node
    let tmpNodes = nodes;

    tmpNodes.forEach((node) => {
      if (node.id === nodeId) {
        if (key) {
          node.data[key] = value;
        }
      }
    });

    setNodes(tmpNodes);
    updateNodeInternals(nodeId);
      
      
  }

  return (
    <HStack w='100%' h='100%' p={0} m={0}>
    <Layout
      title={title} 
      layout='dense'
      titleLayout='dense'
      actionContent={
        <ButtonGroup size='md' colorScheme='blue' variant='outline'>
        <Button onClick={()=>{navigate('/orgtest/procurement')}} colorScheme='gray'>
          Dashboard
        </Button>
          <Button 
            isLoading={generating}
            loadingText='Generating'
            onClick={() => {
              setGenerating(true);
              
              
              let tmpNodes = nodes;
              let index = 1;
          
              let interval = setInterval(() => {

                // Set generating index


                  let node = tmpNodes[index];
                  let nextIndex = index + 1;

                  // Now loop through the generatingItems
                  let internalIndex = 0;
                  let internalInterval = setInterval(() => {
                    setGeneratingInternalIndex(internalIndex);
                    internalIndex++;
                    if (internalIndex >= generatingItems.length) {
                      clearInterval(internalInterval);
                    }
                  }, 700);
          
                  if (index + 1 < tmpNodes.length) {
                      if (tmpNodes[index + 1].type === 'group') {
                          nextIndex++;
                      }
                      if (nextIndex < tmpNodes.length) {
                          tmpNodes[nextIndex].data.generating = true;
                      }
                  }
          
                  node.data.generating = false;
                  node.data.assigned = people[Math.floor(Math.random() * people.length)];
          
                  if (node) {
                      let random = Math.floor(Math.random() * 4);
                      if (random === 1) {
                          node.data.completedSteps = node.data.totalSteps;
                          node.data.status = 'completed';
                      } else {
                          let min = Math.floor(node.data.totalSteps / 2);
                          let max = node.data.totalSteps - 1;
                          node.data.completedSteps = Math.floor(Math.random() * (max - min + 1) + min);
                          node.data.status = 'inprogress';
                      }
          
                      setNodes(tmpNodes);
                      updateNodeInternals(tmpNodes[index].id);
                      if (nextIndex < tmpNodes.length) {
                          updateNodeInternals(tmpNodes[nextIndex].id);
                      }
                  }
          
                  index = nextIndex;
                  setGeneratingIndex(index);
                  setGeneratingInternalIndex(0);

                  if (index >= tmpNodes.length) {
                      clearInterval(interval);
                      setGenerating(false);
                  }
          
              }, 700 * (generatingItems.length + 1));
          }}
          
            
            >
            Generate
          </Button>

        </ButtonGroup>
      }
      >
      <GridItem colSpan='12' w='100%' h='fill-available' minH='500px'
       bg='white'
        p={0}
        borderRadius='20px'
        border='2px solid #E2E8F0'
        borderColor='gray.500'
        maxH='500px'
        
        >

           



                  <>
                    {(!nodes || !edges) ? <Loading /> :

   
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      onConnectStart={onConnectStart}
                      onLoad={onLoad}
                      onConnectEnd={onConnectEnd}
                      nodeTypes={nodeTypes}
                      //fitView={{true}}
                      //fitViewOptions={{ padding: 2 }}
                      defaultViewport={
                        window.location.href.includes('2d5748b7-0dfd-40d7-934f-10a1dca4d397') ?
                        { x: 400, y: 200, zoom: 0.5 }
                        :
                        { x: 380, y: 120, zoom: 0.8 }
                      }
                      nodeOrigin={[0.5, 0]}
                      defaultZoom={0.5}
                      // Force refresh on nodes change
                      key={nodes}
                      proOptions={{ hideAttribution: true }}
                      >
                      <Controls />
                      <MiniMap />
                      <Background variant="dots" gap={12} size={1} />
                    </ReactFlow>
                    }
              </>

            </GridItem>

            <GridItem colSpan='12' key={stage && stage.id} pt={10}
             transition='0.2s all ease-in-out' 
              h='fit-content'
            >

              <Stage stage={stage} setStage={setStage} 
               nodes={nodes}
               updateNodeData={updateNodeData}
                />
            </GridItem>

            {generating && 
            
                <HStack bg='white' borderRadius='10px' position='fixed' bottom={8} right={8} border='2px solid' borderColor='gray.500'
                  minW='400px' px={8} py={6} pl='12px' shadow='md' zIndex='1000' 
                  opacity={showGeneratingToast ? 1 : 0}
                  transition='all 0.2s ease-in-out'
                  >
                    <Loading h='2em' p={0} pr={4}/>
                    <Box>
                      <Text color='black' fontWeight='600'>
                          {nodes && nodes[generatingIndex].data.label}
                      </Text>
                      <Text>
                          {generatingItems[generatingInternalIndex]}
                      </Text>
                    </Box>
                    <Icon as={MdClose} position='absolute' right='10px' top='10px' 
                  
                    onClick={() => {
                      setShowGeneratingToast(!showGeneratingToast);
                    }} cursor='pointer' _hover={{color: 'blue.500'}} />
                </HStack>

            }
            </Layout>


    </HStack> 

  )
};




export default () => {


  return (
    <ReactFlowProvider>
      <Flow />
    </ReactFlowProvider>
  )

}


/*

            <Sidebar 
              maxW={stage ? '400px' : '0px'}
              transition='all 0.2s ease-in-out'
              h='fill-available'
              stage={stage}
              display='none' 
              nodes={nodes}
              setStage={setStage}
              setNodes={setNodes}
              updateNodeInternals={updateNodeInternals}
              key={JSON.stringify(stage)}
              />

              */
