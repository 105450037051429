import {Heading, Button, Box, Text, Stack, Divider} from '@chakra-ui/react';
import API from '../../core/utils/API';
import React, {useState} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
export default function ArchiveAll() {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showInfo, setShowInfo] = useState(false);

    const archiveAll = async () => {
        const response = await API.postItems({url: 'evidences/user/archive/all'});
        console.log(response);
        if (response.status === 200) {
            setSuccess(response.data.message);
        
        } else {
            setError("An error occurred");
        }

        // TODO - notification

    }

    return (

        <Stack spacing={5}>
        <Divider />
            <Stack
            spacing="4"
            direction={{
                base: 'column',
                sm: 'row',
            }}
            justify="space-between"
            > 
            <Box>
                <Text fontSize="lg" fontWeight="medium">
                Archive Evidence
                </Text>
                <Text color="muted" fontSize="sm">
                You can archive all of your files here.
                <Icon ml={2} _hover={{color: 'blue.500'}} cursor='pointer' as={MdInfoOutline} onClick={() => setShowInfo(!showInfo)}/>
                {error && <Text color="red.500">{error}</Text>}
                {success && <Text color="green.500">{success}</Text>}
                </Text>
            </Box>
            <Button className='btn-8' colorScheme='red' variant='outline' onClick={archiveAll}>Archive All</Button>

            </Stack>
            { showInfo &&
                <Text>
                    This will remove them from your records and will not be visible to people who you provide access to from now.
                    People already with access will continue to have access to the files for regulatory reasons.
                </Text>
            }
            <Divider />

            </Stack>
    )
}