
import React, { useState, useContext } from 'react';
import { Heading, Text, Box, Stack, Button, Input } from '@chakra-ui/react';
import { AccountContext } from './Accounts';
import { CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from '../UserPool';

export default () => {



    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);

    const { getSession, authenticate } = useContext(AccountContext);

    const onSubmit = event => {

        event.preventDefault();

        getSession()
            .then(({user, email}) => {
                authenticate(email, password).then(() => {
                    
                    const attributes = [
                        new CognitoUserAttribute({
                        Name: 'email',
                        Value: newEmail
                        })
                    ];
                    
                    user.updateAttributes(attributes, (err, result) => {
                        if (err) {
                            setError(err.message);
                        } else {
                            console.log(result);
                            setError(null);
                            setNewEmail('');
                            setPassword('');
                        }
                    });
                });
            })
            .catch(err => {
                console.error(err);
                setError(err);
            }
        );

            
    };

    
    return (

        <Box>
                <Heading>Change E-mail</Heading>
                {error && <Text>{error}</Text>}
                <form onSubmit={onSubmit}>
                    <Stack spacing={3}>
                        <Input placeholder="New E-mail" value={newEmail} onChange={event => setNewEmail(event.target.value)} />
                        <Input type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} onChange={event => setPassword(event.target.value)} />
                        <Button type='submit'>Change E-mail</Button>
                    </Stack>
                </form>
        </Box>
    )
}
