"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var theme_tools_1 = require("@chakra-ui/theme-tools");
var baseStyle = function (props) {
    return {
        label: {},
        container: {
            bg: 'black',
            outline: '2px solid',
            outlineColor: (0, theme_tools_1.mode)('rgba(255, 255, 255, 0.02)', 'rgba(23, 23, 23, 0.05)')(props),
            color: 'brand-white',
            '> svg': {
                m: 2,
                color: 'brand-white',
            },
        },
    };
};
exports.default = {
    baseStyle: baseStyle,
    defaultProps: {
        colorScheme: 'blue',
    },
    sizes: {
        xs: {
            label: {
                fontSize: '2xs',
            },
        },
        sm: {
            label: {
                fontSize: '2xs',
            },
        },
        md: {
            label: {
                fontSize: 'sm',
            },
        },
        lg: {
            label: {
                fontSize: 'md',
            },
        },
        xl: {
            label: {
                fontSize: '1.625rem',
            },
        },
        '2xl': {
            container: {
                width: 20,
                height: 20,
            },
            label: {
                fontSize: '2.25rem',
            },
        },
        'massive': {
            container: {
                width: '160px',
                height: '160px',
            }, 
            label: {
                fontSize: '4rem',
            },
        }
    },
};
