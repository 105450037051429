import {Textarea, Wrap, Icon, Center, Divider, Checkbox, Link, Table, Tbody, Th, Td, Tr, Editable, TableContainer, EditableTextarea, EditablePreview, Box, Flex, Spinner, Stack, Text, Heading, ButtonGroup, Button} from '@chakra-ui/react';
import Modal from '../../core/components/modal/Modal';
import React, {useState} from 'react';
import API from '../../core/utils/API';
import pluralize from 'pluralize';
import {MdCheck} from 'react-icons/md';
import {AutoResizeTextarea} from '../../core/components/forms/AutoResizeTextarea';

export default function Reminder(props) {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        recipient: props.recipient,
        recipientUuid: props.recipientUuid,
        emotion: 'Friendly',
        style: 'Informal',
        topic: 'remind them about their outstanding HR documents and statutory mandatory training',
        outstandingCount: props.outstanding.length,
        outstanding: props.outstanding,
    });
    const [email, setEmail] = useState({
        recipient: props.recipient,
        recipientUuid: props.recipientUuid,
        body: 'Empty email',
        includeRequirements: true,
        rawRequirements: props.outstanding,
    })
    const [formMessage, setFormMessage] = useState("");
    const [apiResponse, setApiResponse] = useState("");
    const [showOutstanding, setShowOutstanding] = useState(false);
    const [sent, setSent] = useState(false);

    let emotions = ['Friendly', 'Firm', 'Encouraging'];
    let styles = ['Informal', 'Professional', 'Formal'];

    const [generatingMessageIndex, setGeneratingMessageIndex] = useState(0);
    const [generatingMessage, setGeneratingMessage] = useState([
        <Text>We recommend personalised reminders as they are more than <Text as='span' color='blue.500'>twice</Text> as effective than generic messages.</Text>,
        <Text>We add links to <Text as='span' color='blue.500'>recommended providers</Text> by default, making it easier for your recipient to complete their requirements.</Text>,
        <Text>After it's generated you can <Text as='span' color='blue.500'>edit</Text> it before sending, making sure that you can add any personal touches.</Text>
    ]);

    // Set an interval to update the message every 3 seconds
    // This is a bit hacky, but it works
    const updateMessage = (newIndex) => {

        console.log("Update triggered", generatingMessageIndex, generatingMessage.length);

        newIndex = newIndex + 1;
        console.log("New Index:", newIndex);

        if (newIndex === generatingMessage.length ) {
            newIndex = 0;
        }
        setGeneratingMessageIndex(newIndex);
    
        setTimeout(() => {
            updateMessage(newIndex);
        }
        , 4000);

     
    }



    const generateReminder = async (e) => {
        e.preventDefault();
        setLoading(true);
        clearInterval(updateMessage);

        updateMessage(0);


        const result = await API.postItems({url: "generate/reminder", data}).then(function (response) {
            console.log(response);
            setFormMessage("Generation successful");
            setApiResponse(response.data.reminder);
            updateEmailData('body', response.data.reminder);
        })
        .catch(function (error) {
            console.log(error);
            setFormMessage("Generation failed");
        });
        setLoading(false);
    }

    const updateData = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }


    const updateEmailData = async(key, value) => {
        await setEmail({
            ...email,
            [key]: value
        })

        console.log(email);
    }

    const sendReminder = async(e) => {
        if (e != undefined) {
            e.preventDefault();
        }
        setLoading(true);
        
        if (email.includeRequirements === true && email.requirements === undefined) {
            //await setEmail('requirements', document.getElementById('outstanding-summary').outerHTML);
        }

        console.log('EMAIL DATA=', email);

        const result = await API.postItems({timeout: 40, url: "generate/sendreminder", data: email})
        .then(function (response) {
            console.log(response);
            setSent(true);
            setLoading(false);

            setFormMessage("Send successful");
        })
        .catch(function (error) {
            console.log(error);
            setLoading(false);
            setFormMessage("Send failed");
        });
    }



//    updateData('oustandingCount', props.outstandingCount);
  //  updateData('outstandingTitles', props.outstanding.map((item) => item.title).join(', '));


    return (

        <>
        {data.outstandingCount > 0 ?
            <Button onClick={() => {setShow(!show)}} colorScheme='blue' variant='outline' className='btn-8'>
               Reminder
            </Button>
            :
            <Button disabled >
                Reminder
            </Button>
        }
        {show &&
            <Modal
                title={(!sent && apiResponse) ? 'Preview' : 
                 <>
                 {loading ?
                    ''
                    :
                    <>
                        {!sent ?
                    'Reminder'
                :
                    ''
                    }
                    </>
                }
                 </>}
                onClose={() => {setShow(false)}}
                maxW={(apiResponse && !sent) ? '600px' : '400px'}
                footer={
                    <>
                        {!loading && (!(apiResponse !== "" && apiResponse !== undefined && apiResponse !== null)) &&
                            <Button onClick={(e) => generateReminder(e)} size='sm' colorScheme='blue' className='btn-8'>
                                Generate
                            </Button>
                        }
                        {loading &&
                            <Button size='sm' colorScheme='grey' disabled>
                                Generating...
                                </Button>
                        }
                        {!sent && apiResponse !== "" && apiResponse !== undefined && apiResponse !== null &&
                            <>
                                <Button onClick={() => {setApiResponse(''); setFormMessage(''); setSent(false)}} size='sm' colorScheme='red' className='btn-8'>
                                    Reset
                                </Button>
                                <Button onClick={() => {sendReminder()}} size='sm' colorScheme='blue' className='btn-8'>
                                    Send
                                </Button>
                            </>
                        }
                        { !loading && sent && !(apiResponse === "" || apiResponse === null || apiResponse === undefined) &&
                            <>
                        
                                <Button onClick={() => {setApiResponse(''); setFormMessage(''); setSent(false)}} size='sm' colorScheme='blue' variant='outline' className='btn-8'>
                                    Reset
                                </Button>

                            </>
                        }
                    </>
                }
                >
                    {loading &&
                        <Flex p={8} pt={2} minH='400px' flexDirection='column' alignItems='center' justifyContent='center' alignSelf='center' justifySelf='center' textAlign='center'>
                        <Box>
                            <Box className="loading" >
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Box>
                        </Box>
                        <Heading size='label' fontSize='1.5em' mb={12}>
                            Generating reminder...
                        </Heading>
                        <Text>
                            <strong>Did you know?</strong>
                            <br/>
                            {generatingMessage[generatingMessageIndex]}
                        </Text>
                    </Flex>
                    }
                    {!loading && (apiResponse === "" || apiResponse === null || apiResponse === undefined ) &&
                            
                        <Stack spacing={4} pb={8}>

                            <Text>
                                Select the tone and style of the reminder and we will generate a reminder for you to send to the recipient.
                            </Text>
                            <Text pb={4}>
                                You can edit the reminder before sending, but people respond much better to individualised contact rather than a generic message.
                            </Text>


                            <ButtonGroup size='xs' variant='outline' colorScheme='blue' w='fill-available'>
                                <Text fontWeight='bold' mt='2px' mr={2}>Recipient:</Text>
                                <Button disabled color='black!important' borderColor='black !important' opacity='1!important'>{props.recipient}</Button>
                            </ButtonGroup>
                            <ButtonGroup display='none' size='xs' variant='outline' colorScheme='blue' w='fill-available'>
                                <Text fontWeight='bold' mt='2px' mr={2}>Recipient E-mail:</Text>
                                <Button disabled color='black!important' borderColor='black !important' opacity='1!important'>{props.recipientEmail}</Button>
                            </ButtonGroup>

                            <ButtonGroup size='xs' colorScheme='blue' variant='outline' w='fill-available'>
                                <Text fontWeight='bold' mt='2px' mr={2}>Tone:</Text>
                                {emotions.map((emotion, index) => (
                                        <>
                                            {data.emotion === emotion ?
                                                <Button variant='solid' key={index} onClick={() => updateData('emotion', emotion)}>{emotion}</Button>
                                                :
                                                <Button key={index} onClick={() => updateData('emotion', emotion)}>{emotion}</Button>
                                            }
                                        </>
                                ))}
                            </ButtonGroup>


                            <ButtonGroup size='xs' colorScheme='blue' variant='outline' w='fill-available'>
                                <Text fontWeight='bold' mt='2px' mr={2}>Style:</Text>
                                {styles.map((style, index) => (
                                    <>
                                        {data.style === style ?
                                            <Button variant='solid' key={index} onClick={() => updateData('style', style)}>{style}</Button>
                                            :
                                            <Button key={index} onClick={() => updateData('style', style)}>{style}</Button>
                                        }
                                    </>
                                ))}
                            </ButtonGroup>

                            {formMessage !== "" &&
                                <Text color='red.500' fontWeight='bold'>    
                                    {formMessage}
                                </Text>
                            }

                        </Stack>
                    }
                                
                    { !loading && !sent && !(apiResponse === "" || apiResponse === null || apiResponse === undefined) &&
                    <>
                        <Editable display='none' defaultValue={apiResponse.trim()} onChange={(newValue) => updateEmailData('body', newValue)}>
                            <EditablePreview color='black' whiteSpace='pre-line' />
                            <EditableTextarea />
                        </Editable>

                        <AutoResizeTextarea 
                            defaultValue={apiResponse.trim()}
                            id='reminderText'
                            onLoad={() => {
                                document.getElementById('reminderText').style.height = document.getElementById('reminderText').scrollHeight+'px';
                            }}
                            onChange={(e) => {
                                e.target.style.height = e.target.scrollHeight+'px';
                                updateEmailData('body', e.target.value);
                            }}
                            color='black'
                            whiteSpace='pre-line'
                            display='inherit'
                            />



                        <Checkbox defaultChecked mt={4} mb={4} onChange={(e) => {console.log(e); updateEmailData('includeRequirements', e.target.checked);}}>
                            Include {data.outstanding.length} outstanding {pluralize('requirement', data.outstanding.length)}
                            <Button variant='ghost' size='xs' onClick={(e) => {e.preventDefault(); setShowOutstanding(!showOutstanding);}}>{showOutstanding ? 'Hide Details' : 'Show Details'}</Button>
                        </Checkbox>
                        <TableContainer>

                            <Table id='outstanding-summary' variant='simple' display={showOutstanding? 'table' : 'none'}>
                                <Tbody>
                                    <Tr>    
                                        <Th bg='none' pl={4} ><Heading size='label' fontSize='sm'>Oustanding Requirements</Heading></Th>
                                        <Th bg='none' ><Heading size='label' fontSize='sm'>Recommended Provider</Heading></Th>
                                    </Tr>
                                    {data.outstanding.map((item, index) => (
                                        <Tr key={index}>
                                            <Td pl={4} >{item.title}</Td>
                                            <Td>
                                                {item.recommendedProviderName &&

                                                    <>
                                                        {(item.recipientProviderURL !== null && item.recipientProviderURL !== undefined && item.recipientProviderURL !== "" ) ?
                                                            <Link isExternal href={item.recommendedProviderURL}>{item.recommendedProviderName}</Link>
                                                        :
                                                            <Text>{item.recommendedProviderName}</Text>
                                                        }
                                                    </>
                                            
                                                }
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>

                        </TableContainer>
                        
                    </>

                    }
                    { !loading && sent && !(apiResponse === "" || apiResponse === null || apiResponse === undefined) &&
                        <Center pb={12}>
                            <Stack alignItems='center'>
                            <Icon as={MdCheck} color='green.500' fontSize='128px'/>
                            <Text fontWeight='bold'>
                                Reminder sent
                            </Text>
                            </Stack>
                            
                        </Center>
                    }

            </Modal>
        }
        </>
    )
}