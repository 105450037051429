import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text
    
  } from '@chakra-ui/react'


  export default function CustomModal(props) {

    let margin = {
      true: {
        margin: 0,
        left: 4,
        right: 4,
        top: 4,
        bottom: 4,
        width: 'unset',
      },
      false: {
        margin: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: 'unset',
      }
    }

    return (
        <>
  
        <Modal isOpen='true' className='full-page-modal' {...props}>
          <ModalOverlay  />
          <ModalContent className='full-page-modal-content' overflow='hidden' position='absolute' maxWidth='unset' 
            {...margin[props.margin ? true : false]}
            >
              {props.children}
          </ModalContent>
        </Modal>
      </>
    )
  }

  