import {Box, Checkbox, Heading, Text, FormControl, FormLabel, FormErrorMessage, FormHelperText} from '@chakra-ui/react';

export default function BooleanWrapper(props) {


    return (

        <FormControl isInvalid={props.error}>
            <Checkbox onChange={(e) => {console.log(e); props.setValue(props.name, e.target.checked);}}
             size={props.size} 
                defaultChecked={props.defaultValue}
                >
                {props.checkboxLabel || props.label}
            </Checkbox>
            {props.description &&
                <FormHelperText mb={2} mt={0} ml={7}>
                    {props.description}
                </FormHelperText>
            }

            <FormErrorMessage>
                {props.error && props.error.message}
            </FormErrorMessage>
        </FormControl>
    )


}
