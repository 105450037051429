import React, { useState, useEffect } from "react";
import API from "../../core/utils/API";
import { Box, Text, Button } from "@chakra-ui/react";
const ProductDisplay = (props) => {


    function createCheckoutSession() {
        
        API.postItems({
            url: 'stripe/create-checkout-session',
            config: {
                params: {
                    priceId: props.priceId,
                    stripeCustomerId: props.stripeCustomerId,
                    userUuid: props.userUuid,
                    organisationUuid: null,
                }
            }
        }).then((response) => {

            console.log(response);
            window.location.href = response.data.url;

        }).catch((error) => {
            console.log(error);

        })

    }
    
    return (


        
            <Button colorScheme='blue' type="submit" onClick={()=>{createCheckoutSession();}}>
                Subscribe
            </Button>
    )
};

const Message = ({ message }) => (
    <Box>
    {message === "successful" && 
      <Text color='green' pb={4}>Order successful, you will recieve an email confirmation</Text>
}
    {
        message === "cancelled" &&
        <Text color='red' pb={4}>You cancelled the order.</Text>
    }
      <Button variant='subtle' onClick={() => {window.location=window.location.href.split("?")[0];}}>Reset</Button>
    </Box>
  );

export default function Product(props) {
const [message, setMessage] = useState("");

useEffect(() => {
  // Check to see if this is a redirect back from Checkout
  const query = new URLSearchParams(window.location.search);
  
    let priceId = query.get("priceId");
    let checkout = query.get("checkout");

    
  if (priceId === props.priceId && checkout === 'success') {
    setMessage("successful");
  }

  if (priceId === props.priceId && checkout === 'cancelled') {
    setMessage(
      "cancelled"
    );
  }
}, [props]);

return message ? (
  <Message message={message} />
) : (
  <ProductDisplay userUuid={props.userUuid} stripeCustomerId={props.stripeCustomerId} priceId={props.priceId} />
);

}