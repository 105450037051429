import React, {useState, useEffect, useContext} from 'react';
import Loading from '../static/layout/Loading';
import {SubscriptionContext} from './SubscriptionContext';
import { PageBlocked } from './PageBlocked';
import {Outlet} from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import API from '../core/utils/API';
import { set } from 'date-fns';
import { Button } from '@chakra-ui/button';
function translateSubscriptionId(priceid) {

    return process.env['REACT_APP_SUBSCRIPTION_NAME_' + priceid]
}

export const SubscriptionProtectedRoute = (props) => {

    let [loading, setLoading] = useState(true);
    let [permitted, setPermitted] = useState(false);

    let [data, setData] = useState(undefined);
    let [message, setMessage] = useState("");
    let [buttonText, setButtonText] = useState("Subscribe");
    let [buttonAction, setButtonAction] = useState("/my/subscriptions");
    let [subscriptions, setSubscriptions] = useState([]);

    const [subscriptionIds, setSubscriptionIds] = useState([]);
    const [globalTrial, setGlobalTrial] = useState(false);

    let fullWindow = props.fullWindow || true;


    const subscriptionData = useContext(SubscriptionContext);
    //console.log("SubscriptionProtectedRoute", subscriptionData);
   // console.log("SubscriptionProtectedRoute Context", subscription);
    if (subscriptionData !== null && subscriptionData !== undefined && data === undefined) {
        //console.log("Subscription in wrapper", subscriptionData);
        setData(subscriptionData);
    }



    useEffect(() => {

        setLoading(true);

        if (data === undefined) {
            //console.log("SubscriptionProtectedRoute - no data");
            return;
        }

        //console.log("SubscriptionProtectedRoute", data,  process.env.REACT_APP_SUBSCRIPTION_ORDER.split(',')        );
            
        if (data.globalTrial && props.allowTrial) {

            // TODO - is this what we want anymore?!
            setPermitted(true);

        } else if (data.subscriptionIds === undefined || data.subscriptionIds.length === 0) {

            setMessage("You need to subscribe to the " + translateSubscriptionId(props.requiredSubscription) + " plan to access this page.");
            setButtonText("Subscribe");
            setButtonAction("/my/subscriptions");

        } else if (data.subscriptionIds.length > 0) {

            setMessage("You need to upgrade to the " + translateSubscriptionId(props.requiredSubscription) + " plan to access this page.");
            setButtonText("Upgrade");

            // Load order of subscriptions from .env
            // It is in ascending order
            //console.log("process.env.REACT_APP_SUBSCRIPTION_ORDER", process.env.REACT_APP_SUBSCRIPTION_ORDER);
            let subscriptionOrder = process.env.REACT_APP_SUBSCRIPTION_ORDER.split(',');

            // Cycle through the subscription order and check if it matches the user's subscription
            let passedRequiredSubscription = false;
           
            if (props.requiredSubscription === null) {
                passedRequiredSubscription = true;
            }
            
            

            for (let i = 0; i < subscriptionOrder.length; i++) {

                //console.log("Checking subscription", subscriptionOrder[i]);

                if (subscriptionOrder[i] === props.requiredSubscription) {
                    passedRequiredSubscription = true;
                }
                if (data.subscriptionIds.includes(subscriptionOrder[i])) {
                    if (passedRequiredSubscription) {
                        setPermitted(true);
                        //console.log("Subscription passed", subscriptionOrder[i]);
                    }
                    break;
                }
            }

        } else {
            // no subscriptions or trial
        }

        if (props.requiredSubscription === 'admin') {
            setMessage("You need to be an administrator to access this page.");
            setButtonText("Contact Administrators");
            setButtonAction("mailto:" + process.env.REACT_APP_SUPPORT_EMAIL + "?subject=Requesting Admin Access");

            // Check if user has 'admin' in subscriptionIds
            if (data.subscriptionIds !== undefined &&
                data.subscriptionIds.includes('admin')) {
                setPermitted(true);
            }
            
        }

        

        
        setLoading(false);

    }, [data]);


    return (


        <>

            {loading ? 
            
                <>
                {fullWindow ?
                    <Text>Checking Subscriptions</Text>
                    :
                    <Loading message='Checking your subscription status in wrapper'/> 
                }
                </>
                
                : 

                <>
                    {permitted ? 
                        <>
                            <Outlet /> 
                            {props.children}
                        </>
                        :
                        <>
                            {props.fullMessage !== undefined && props.fullMessage !== null && props.fullMessage !== '' ?

                                <>
                                    {props.fullMessage}
                                </>

                                :

                                <>
                                {(props.fullWindow === undefined || props.fullWindow === true || props.fullWindow === 'true' && props.fullWindow === null ) ?
                                    <PageBlocked
                                        message={message}
                                        button={buttonText}
                                        buttonAction={buttonAction}
                                    />
                                    :
                                    <a target='_blank' href={buttonAction}>
                                        <Button colorScheme='blue'>
                                            {props.message}
                                        </Button>
                                    </a>
                                }

                                </>

                            }
                        </>
                    }
                </>
                
            }

        </>
    )

}


export default SubscriptionProtectedRoute;