import React, {  useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Stack, Circle, Heading, Button, Flex, Box, Text, Icon,Wrap,  Tag, Avatar, TagLabel } from '@chakra-ui/react';
import { MdCheck, MdClose, MdAccessTime, MdLock } from 'react-icons/md';
import Node from './Node';
import { BsBluetooth } from 'react-icons/bs';

const handleStyle = { left: 10 };

const colors = {
  'completed': 'green.500',
  'inprogress': 'yellow.500',
  'notstarted': 'red.500',
  'locaked': 'gray.500',
}

const icons = {
  'completed': MdCheck,
  'inprogress': MdAccessTime,
  'notstarted': MdClose,
  'locked': MdLock,
}

function Group({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const [collapsed, setCollapsed] = React.useState(false);

  let defaultHeight = '42px';

  return (

    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />

    </>
    );
}

export default Group;