import { Image, Heading, Center, Container, SimpleGrid, Stack, Text } from '@chakra-ui/react'

import AWS from './AWS.png';
import NHS from './NHS.png';
import Google from './Google.png';


export default function Logos() {

  let logos = [
    '/images/home/gdpr.png',
    '/images/home/ico.png'
  ];
    
    return (
  <Container
    py={{
      base: '12',
      md: '24',
    }}
  >
    <Stack spacing="8">
      
      <SimpleGrid
        gap={{
          base: '8',
          md: '8',
        }}
        columns={{
          base: 1,
          md: 2,
          //lg: 5,
        }}
        
      >
        {logos.map((Logo, i) => (
          <Center key={Logo}>
            <Image 
              src={Logo}
              key={'logo-' + i}
              maxH={{
                base: '90px',
                md: '120px',
              }}
              maxW="230px"
              fill="emphasized"
              mb={12}
            />
          </Center>
        ))}
      </SimpleGrid>
    </Stack>
  </Container>
)

            }