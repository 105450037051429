
let people = {
    'Oliver Trampleasure': {
        name: 'Oliver Trampleasure',
        role: 'Clinical Lead',
        email: 'oliver@pyrexia.io',
        url: '/oliver.jpg',
    },
    'Ali Jawad': {
        name: 'Ali Jawad',
        role: 'Information Governance',
        email: 'ali@pyrexia.io',
        url: '/ali.jpg',
    },
    'Sarah Jane': {
        name: 'Sarah Jane',
        role: 'Clinical Lead',
        email: 'sarah@pyrexia.io',
        url: '/profile4.jpg',
    },
    'John Smith': {
        name: 'John Smith',
        role: 'Information Governance',
        email: 'john@pyrexia.io',
        url: '/profile6.jpg',
    },
    'Jane Smith': {
        name: 'Jane Smith',
        role: 'Information Governance',
        email: 'jane@pyrexia.io',
        url: '/profile5.jpg',
    },
    'Frank Turner': {
        name: 'Frank Turner',
        role: 'Clinical Lead',
        email: 'frank@pyrexia.io',
        url: '/profile1.jpg',
        conflict: 'high',
        conflicts: [
            // Director of ADHD Company called Assessments Ltd
            {
                name: 'Assessments Ltd',
                role: 'Director',
                description: 'As a director of Assessments Ltd, Frank has a conflict of interest with this bid as Assessments Ltd is a likely competitor to the bid.',
                conflict: 'high'
            }
        ]
    },
    'Germaine Greer': {
        name: 'Germaine Greer',
        role: 'Clinical Lead',
        email: 'germaine@pyrexia.io',
        url: '/profile2.jpg',
        conflict: 'low',
        conflicts: [
            // Director of ADHD Company called Assessments Ltd
            {
                name: 'NICE',
                role: 'ADHD Committee Member',
                description: 'As a member of the ADHD Committee, Germaine has a potential conflict of interest with this bid as NICE is a likely competitor to the bid.',
                conflict: 'low'
            }
        ]
    },
}


export default people;