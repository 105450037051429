"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = {
    color: 'text',
};
var sizes = {
    'statictext':  {
        fontSize: {
          base: 'lg',
          md: '28px',
        },
        ml: 'auto',
        mr: 'auto',
        mb: '1.5em',
    },
    'staticsmalltext': {
        fontSize: {
            base: 'md',
            md: '22px',
        },
        mb: '1em'
    },
    '2xl': {
        fontSize: '7xl',
        lineHeight: '5.625rem',
        letterSpacing: 'tight',
    },
    xl: {
        fontSize: '6xl',
        lineHeight: '4.5rem',
        letterSpacing: 'tight',
    },
    lg: {
        fontSize: '5xl',
        lineHeight: '3.75rem',
        letterSpacing: 'tight',
    },
    md: {
        fontSize: '4xl',
        lineHeight: '2.75rem',
        letterSpacing: 'tight',
    },
    sm: {
        fontSize: '3xl',
        lineHeight: '2.375rem',
    },
    xs: {
        fontSize: '2xl',
        lineHeight: '2rem',
    },
    'pagetitle-description': {
        mt: 7,
        fontSize: "20px",
        color: 'primary',
        mb: 12
    }
};
exports.default = {
    baseStyle: baseStyle,
    sizes: sizes,
};
