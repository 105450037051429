import React, { useState, useEffect, useContext } from 'react';
import { useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Splash from '../../static/layout/Splash';
import AutoForm from '../../core/models/AutoForm';
import Modal from '../../core/components/modal/Modal';
import Layout from '../../core/layout/Layout';
import Process from '../../core/components/process/Process';



export default function FinanceSetup(props) {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({});

    return (
        <Process

            url='organisations/'
            modal={true}
            onCancel={() => {
                console.log('Cancelled');
                // run props.onCancel
                props.onCancel();
            }}
            refresh={props.refresh}
            data={data}
            summary={true}
            finishMessage='Save'
            allowSkip={true}

            steps={[
                {
                    title: 'Welcome',
                    component: <Box>
                        <Text>
                            You can setup your financial details to start using our automated invocing products.
                        </Text>
                    </Box>
                },
                {
                    title: 'Bank Details',
                    preamble: {
                        // title: 'Test',
                        description: [
                            'These will be added to all invoices so that your customers know how they should pay.'
                        ]
                    },
                    component: [
                        {
                            name: 'bank',
                            label: 'Bank Name',
                            type: 'text',
                            description: 'Enter the name of your bank',
                            required: true
                        },
                        {
                            name: 'sortcode',
                            label: 'Sortcode',
                            type: 'integer',
                            description: 'Enter your sortcode',
                            name: 'shorthand',
                            required: true
                        },
                        {
                            name: 'account',
                            type: 'integer',
                            label: 'Account Number',
                            description: 'Enter your account number',
                            required: true,

                        },
                    ]
                },
                {
                    title: 'Accountancy Software ',
                    preamble: {
                        // title: 'Test',
                        description: [
                            'Connect your accountancy software to start using our automated invoicing products.',
                            'You must setup a custom connection first.'
                        ]
                    },
                    component: [
                        {
                            name: 'provider',
                            label: 'Provider',
                            type: 'select',
                            description: 'Select your accountancy software provider',
                            required: true,
                            options: [
                                'Xero'
                            ]
                        },
                        {
                            name: 'api_key',
                            label: 'API Key',
                            type: 'password',
                            description: 'Enter your API key',
                            required: true
                        },
                        {
                            name: 'api_secret',
                            label: 'API Secret',
                            type: 'password',
                            description: 'Enter your secret',
                            required: true
                        }
                    ]

                }

            ]}

        />
    )

}