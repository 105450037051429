import { GridItem, useColorModeValue } from "@chakra-ui/react";

export default function CustomListItem(props) {

    const shadow = useColorModeValue('sm', 'sm-dark');
    
    return (
        <>
            {props.custom === true ?

                <GridItem {...props}/>

                :
            
            <GridItem
            bg="bg-surface"
            boxShadow={shadow}
            borderRadius="lg"
            p={0}
            colSpan='2'
            mb={4}
            p={4}
            {...props}
            />

}

        </>
    )
}