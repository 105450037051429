import {Input, GridItem, Icon, Box, Stack, Tag, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import { useSearchParams } from 'react-router-dom';
import AddQuestion from './AddQuestion';
import Modal from '../../core/components/modal/Modal';
import {MdArrowRightAlt, MdAdd} from 'react-icons/md';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';
import { pl } from 'date-fns/locale';
import { set, sub } from 'date-fns';
import AssignForm from './AssignForm';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function Draggable(props) {

    // 1. Make the element draggable.
    // 2. Add an ondragstart event listener in which we specify
    // what should happen when the drag operation starts. This is
    // where we define the drag data.
    // 3. Specify what should happen
    // when the dragged data is dropped on a target.
    // 4. Clear the drag data cache (for all formats/types)

    const [dragged, setDragged] = useState(false);
    const [draggedOverTop, setDraggedOverTop] = useState(false);
    const [draggedOverBottom, setDraggedOverBottom] = useState(false);

    function dragStart(event) {
        event.dataTransfer.setData('text/plain', event.target.id);
        //event.currentTarget.style.backgroundColor = 'yellow';
    }

    function dragEnd(event) {
        //event.currentTarget.style.backgroundColor = 'white';
    }

    function dragOver(event) {
        event.preventDefault();

        const targetBounds = event.currentTarget.getBoundingClientRect();
        const targetCenter = targetBounds.y + (targetBounds.height / 2);
        const mousePosition = event.clientY;

        if (mousePosition < targetCenter) {
            setDraggedOverTop(true);
            setDraggedOverBottom(false);
        } else {
            setDraggedOverTop(false);
            setDraggedOverBottom(true);
        }

    }

    function dragLeave(event) {
        event.preventDefault();
        setDraggedOverTop(false);
        setDraggedOverBottom(false);
    }

    function drop(event) {
        event.preventDefault();
        const id = event.dataTransfer.getData('text');
        const draggableElement = document.getElementById(id);
        // Define dropzone as the element that is the target of the drop event's closest ancestor with the class 'dropzone'
        
        let dropzone = event.currentTarget;
        while (!dropzone.classList.contains('dropzone')) {
            dropzone = dropzone.parentNode;
        }
        
        // If the in the top half of the dropzone, insert before the dropzone's PARENT node
        // If in the bottom half, insert after the dropzone's PARENT node
        const targetBounds = dropzone.getBoundingClientRect();
        const targetCenter = targetBounds.y + (targetBounds.height / 2);
        const mousePosition = event.clientY;
        const parent = dropzone.parentNode;

        // convert draggableElement to a plain DOM object
        // https://stackoverflow.com/questions/36803176/why-doesnt-draggableelement-classlist-contain-draggable
        //draggableElement = draggableElement.cloneNode(true);
        if (mousePosition < targetCenter) {
            parent.insertBefore(draggableElement, dropzone);
        }
        else {
            parent.insertBefore(draggableElement, dropzone.nextSibling);
        }
        setDraggedOverTop(false);
        setDraggedOverBottom(false);

        updateOrder();


    }


    function updateOrder() {

        let order = [];

        // Get all the elements with the class 'form-item'
        let elements = document.getElementsByClassName('form-item');

        // Loop through the elements and update the order
        for (let i = 0; i < elements.length; i++) {
            let element = elements[i];
            order.push({
                uuid: element.id,
                order: i
            });
        }

        let url = 'forms/' + props.formUuid + '/parameters/order';

        let response = API.patchItems({url: url, data: {order}})
        .then((response) => {

            let data = response.data;

        })
        .catch((error) => {
            console.log("FAILED TO UPDATE ORDER", error);
            
        });


    }

    return (


        <Box className={props.admin ? 'dropzone form-item' : 'form-item'}
            draggable
            onDragStart={(event) => {
                if (props.admin) {
                    return;
                }
                dragStart(event);   
            }}
            onDragEnd={dragEnd}
            onDragOver={dragOver}
            onDragLeave={dragLeave}
            onDrop={drop}
            id={props.uuid}
            key={props.key}
            >
            {props.admin && draggedOverTop &&
                <Box width='100%'>
                    <Wrap>
                        <Icon as={MdArrowRightAlt} color='blue.500'/>
                        <Text color='blue.500' textAlign='center'>Move here</Text>
                    </Wrap>
                </Box>
            }
            <Box
                >
                {props.children}
            </Box>
            {props.admin && draggedOverBottom &&
                <Box width='100%'>
                    <Wrap>
                        <Icon as={MdArrowRightAlt} color='blue.500'/>
                        <Text color='blue.500' textAlign='center'>Move here</Text>
                    </Wrap>
                </Box>
            }
        </Box>

    )

}

function AddSection(props) {

    const [showAdd, setShowAdd] = useState(false);
    const [error, setError] = useState(null);


    // Add divider

    function addParameter(uuid, order) {

        setError(null);

        let title = document.getElementById('title').value;
        let description = document.getElementById('description').value;

        let data = {
                formUuid: props.uuid,
                type: 'section',
                title: title,
                description: description,
                order: props.order - 1
        }

        let response = API.postItems({url: 'forms/parameters', data})
        .then((response) => {

            let data = response.data;

            props.onSubmit();
            setShowAdd(false);
    
        })
        .catch((error) => {
            console.log("FAIL");
            setError(error);
            
        });

    }


    return (

        <>
            <MenuItem onClick={() => {setShowAdd(!showAdd)}}>Section</MenuItem>
        
            {showAdd &&

                <Modal
                    title='Add Divider'
                    onClose={() => {
                        setError(null);
                        setShowAdd(false);
                    }}
                    footer={
                        <Button size='sm' onClick={() => {addParameter()}} colorScheme='blue'>
                            Add Section
                        </Button>
                    }
                    >

                    <Stack spacing={8}>

                        {error &&
                            <Text color='red.500'>
                                {error}
                            </Text>
                        }

                        

                        <Input
                            label='Title'
                            type='text'
                            id='title'
                            placeholder='Title'
                            />

                        <Input
                            label='Description'
                            type='text'
                            id='description'
                            placeholder='Description'
                            />

                        <InputWrapper 
                            label='Break Type'
                            id='type'
                            name='type'
                            type='radio'
                            options='{"values":["Section","Section Break","Page Break"],"defaultValues":["Section"]}'
                            
                            />


                        <ButtonGroup>
                            <Button>Save</Button>
                            <Button>Cancel</Button>
                        </ButtonGroup>

                    </Stack>

                </Modal>
            
            
            }
        </>

    )

}


export default function Form(props) {
    
    const params = useLocation();
    console.log("Params", params);

    let navigate = useNavigate();
    const { uuid, formSubmissionUuid } = params.state;

    // Check if url includes 'admin'
    const url = window.location.href;
    const admin = url.includes('/admin/');
    
    const [form, setForm] = useState(null);
    const [error, setError] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [addIndex, setAddIndex] = useState(null);
    const [items, setItems] = useState(null);
    const [formData, setFormData] = useState(null);
    const [lastSaved, setLastSaved] = useState(null);

    const [attemptedSubmit, setAttemptedSubmit] = useState(false); // For sections

    const [showModal, setShowModal] = useState(false);

    const toast = useToast()

    const [sectionEdit, setSectionEdit] = useState(null); // For sections
     
    const [addDetails, setAddDetails] = useState({
        index: null,
        page: 1
    })


    // Get form
    function getForm() {

        let config = {
            params: {
                uuid, formSubmissionUuid
            }
        };
        let response = API.getItems({url: 'forms', config})
        .then((response) => {

            let data = response.data.form;
            data.FormParameters = data.FormParameters.sort((a, b) => a.order - b.order)

            let userValues = response.data.userValues;

            // Convert userValues to an object
            let userValuesObject = {};
            userValues.forEach((userValue) => {
                userValuesObject[userValue.userParameterUuid] = userValue;
            });

            // Create a new array of form parameters to prepare submission model
            let formData = {};
            data.FormParameters.forEach((FormParameter) => {

                let tmp = {
                    FormParameterUuid: FormParameter.uuid,
                    UserParameterUuid: FormParameter.UserParameter ? FormParameter.UserParameter.uuid : null,
                    required: FormParameter.required,
                    order: FormParameter.order,
                }
                if (FormParameter.UserParameter) {
                    tmp['question'] = FormParameter.UserParameter.question;
                    tmp['explanation'] = FormParameter.UserParameter.explanation;
                    tmp['type'] = FormParameter.UserParameter.type;

                    // Check if there is a value
                    if (userValuesObject[tmp.UserParameterUuid]) {
                        
                        // Check if type is date
                        if (FormParameter.UserParameter.type === 'date') {
                            tmp['value'] = new Date(userValuesObject[tmp.UserParameterUuid].value);
                        } else {
                            tmp['value'] = userValuesObject[tmp.UserParameterUuid].value;
                        }
                        
                    } else {
                        tmp['value'] = null;
                    }

                    // check if it is a boolean
                    if (FormParameter.UserParameter.type === 'boolean' && tmp['value'] === null) {
                        tmp['value'] = false;
                    }
                }
                if (FormParameter.json !== null && FormParameter.json !== '' && FormParameter.json !== undefined && FormParameter.json !== '"{}"') {

                    let jsonTmp = typeof FormParameter.json === 'string' ? JSON.parse(FormParameter.json) : FormParameter.json;
                    if (typeof jsonTmp === 'string') {
                        jsonTmp = JSON.parse(jsonTmp);
                    }

                    tmp['question'] = jsonTmp.title;
                    tmp['explanation'] = jsonTmp.description;
                    tmp['type'] = jsonTmp.type;


                }

                formData[FormParameter.uuid] = tmp;
            });


            // Now we have looked at user parameters lets look at the form submission json
            if (data.FormSubmissions && data.FormSubmissions.length > 0) {

                let json = JSON.parse(data.FormSubmissions[0].json);

                // Loop through the json and update the formData
                json.forEach((item) => {
                    console.log("Item", item, typeof item.value);
                    // Check if the item exists in the formData
                    // We need this in case someone deletes a required element
                    if (!formData[item.FormParameterUuid]) {
                        return;
                    }
                    // Check if needs conversion
                    if ((item.type === 'address') && typeof item.value === 'string') {
                        item.value = JSON.parse(item.value);
                    }
                    if ((item.type === 'date') && typeof item.value === 'string') {
                        item.value = new Date(item.value);
                    }
                    formData[item.FormParameterUuid].value = item.value;
                });

            }

            console.log("Form data", formData);

            setFormData(formData);

            setForm(data);
            if (data.FormParameters != null) {
                setItems(data.FormParameters.length)
            } else {
                setItems(0);
            }
    
        })
        .catch((error) => {
            setError(error);
            
        });
    }

    function updateAddDetails(index, page) {

        let current = addDetails;

        if (index !== null ) {
            current.index = index;
        }
        if (page !== null) {
            current.page = page;
        }

        setAddDetails(current);
        setShowModal(true);

    }


    function addDivider(uuid, order) {


        let data = {
                formUuid: uuid,
                type: 'divider',
                order: order
        }


        let response = API.postItems({url: 'forms/parameters', data})
        .then((response) => {

            let data = response.data;
            props.onSubmit();
            setShowAdd(false);
    
        })
        .catch((error) => {
            console.log("FAIL");
            setError(error);
            
        });

    }


    function deleteElement(uuid) {

        let response = API.deleteItems({url: 'forms/parameters/' + uuid})
        .then((response) => {

            let data = response.data;
            getForm();
    
        })
        .catch((error) => {
            console.log("FAIL");
            setError(error);
            
        });


    }




    useEffect(() => {
        getForm();
    }, []);


    function updateData(FormParameterUuid, UserParameterUuid, value, type) {

        console.log("Updating data from ", type);

        let data = formData;
        
        data[FormParameterUuid].value = value;

        setFormData(data);

        // Check if submission has been attempted
        if (attemptedSubmit) {
            // Check how many required fields are empty
            let empty = 0;
            Object.keys(formData).forEach((key) => {
                if (formData[key].required && formData[key].value === null) {
                    empty++;
                }
            });

            if (empty > 0) {
                setError('Please complete all required fields, ' + empty + ' fields are empty.');
            }

        }

        let forceSubmitTypes = [
            'boolean',
            'radio',
            'address',
            'phone'
        ];
        if (forceSubmitTypes.includes(type)) {
            submitForm(false, data);
        }
       // submitForm(false);

    }

    function submitForm(sign = false, forcedData = null) {

        console.log("Submitting form", formData === lastSaved, formData, lastSaved); 

        if (formData === lastSaved && forcedData === null) {
            console.log("No changes made");
            return;
        }

        let data = {
            json: forcedData ? forcedData : formData,
            uuid: formSubmissionUuid,
            sign
        }

        // Loop through the formData and check if any of the required fields are empty
        let empty = 0;
        Object.keys(formData).forEach((key) => {
            if (formData[key].required && formData[key].value === null) {
                empty++;
            }
        });
        
        // Convert formData from json to array and order by order
        let orderedFormData = [];
        Object.keys(formData).forEach((key) => {
            orderedFormData.push(formData[key]);
        });
        orderedFormData.sort((a, b) => a.order - b.order);

        data.json = orderedFormData;

        if (empty > 0 && sign) {

            setError('Please complete all required fields, ' + pluralize('is', empty, true) + ' empty.');

            // Show a toast
            toast({
                title: 'Required fields are empty.',
                description: 'Please complete all required fields, ' + pluralize('is', empty, true) + ' empty.',
                status: 'error',
                duration: 9000,
                isClosable: true,
              })

            setAttemptedSubmit(true);
            return;
        } 


        // Now we check for signature

        // Submit
        setError(null);

        console.log("Submitting form", data);

        let response = API.postItems({url: 'forms/submit', data})
        .then((response) => {

            let data = response.data;
            console.log("SUCCESS", data);
            setLastSaved(data.json);

            if (sign) {
                toast({
                    title: 'Submitted form',
                    description: "Your form has been submitted.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
    
        })
        .catch((error) => {
            console.log("FAIL", error);
            setError(error.message);
            
        });
        

    }

 
    return (

        <Layout
            title={form ? form.title : 'Loading form...'}
            description={form ? form.description : 'Loading form...'}
            actionContent={
                <>
                    {form &&
                        <>
                            <Button 
                                variant='outline'
                                colorScheme='blue'
                                onClick={() => {
                                    navigate(admin ? '/admin/forms' : '/my/forms');
                                }}>
                                    Return
                            </Button>
                            {admin &&
                                <AssignForm formUuid={form ? form.uuid : null} />
                            }
                        </>
                    }
                </>
            }
            >
            
            
            {(addDetails.index !== null) &&
            
                <Modal key={JSON.stringify(addDetails)}
                    onClose={() => {
                        setAddIndex(null);
                        setShowModal(false);
                    }}
                    >
                    <AddQuestion
                        order={addDetails.index}
                        page={addDetails.page}
                        uuid={form.uuid}
                        onSubmit={() => {
                            getForm();
                            setAddIndex(null);
                        }}
                        />
                </Modal>
            }

            {sectionEdit &&
            
                <Modal 
                    title='Edit Section'
                    onClose={() => {setSectionEdit(null);}}
                    footer={
                        <>
                            <Button 
                                size='sm'
                                colorScheme='red' variant='outline'
                                onClick={() => {
                                    deleteElement(sectionEdit.uuid);
                                    setSectionEdit(null);
                                }}
                                >
                                Delete
                            </Button>
                            <Button 
                                size='sm'
                                onClick={() => {
                                // Create update info
                                let data = {
                                    title: document.getElementById('title').value,
                                    description: document.getElementById('description').value,
                                }

                                // Get the type
                                // travel up to closest element with .chakra-modal__body 
                                // then get all the radios that are CHECKED
                                // then get the value of the radio that is checked
                                let type = document.querySelector('.chakra-modal__body input[type="radio"]:checked').value;
                                data.type = type;


                                let url = 'forms/parameters/' + sectionEdit.uuid;

                                let response = API.patchItems({url: url, data})
                                .then((response) => {

                                    let data = response.data;

                                    setSectionEdit(null);
                                    getForm();
                            
                                })
                                .catch((error) => {
                                    console.log("FAIL", error);
                                });

                            }} colorScheme='blue'>
                                Update
                            </Button>
                        </>
                    }
                    >

                    <Stack spacing={8}>

                        <Input
                            label='Title'
                            type='text'
                            id='title'
                            placeholder='Title'
                            defaultValue={sectionEdit.title}
                            />

                        <Input
                            label='Description'
                            type='text'
                            id='description'
                            placeholder='Description'
                            defaultValue={sectionEdit.description}
                            />

                        <InputWrapper 
                            label='Break Type'
                            id='type'
                            name='type'
                            type='radio'
                            options={'{"values":["Section","Section Break","Page Break"],"defaultValues":["' + sectionEdit.type + '"]}'}
                            />
                    </Stack>

                </Modal>

            
            }

            {form &&
            
                <GridItem colSpan='12'>
                
                    <Stack spacing={8} maxW='600px' m='auto' boxShadow='md' borderRadius='md' bg='white' p={6}>

                    {error &&
                        <Text color='red.500'>
                            {error}
                        </Text>
                    }


                    {form.FormParameters.length === 0 && admin &&
                        <Box>
                            <Menu>
                                <MenuButton>
                                    <Button colorScheme='blue' variant='solid'>
                                        Add Item
                                    </Button>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => {updateAddDetails(0, 1);}}>Question</MenuItem>
                                    <AddSection uuid={form.uuid} order={0} />
                                    <MenuItem onClick={() => {
                                        addDivider(form.uuid, 0);
                                    }}>
                                        Divider
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                    }


                    {form.FormParameters.map((FormParameter, ind) => {

                        let tmpType = FormParameter.UserParameter ? FormParameter.UserParameter.type : null;

                        console.log("FormParameter", tmpType, FormParameter);
                        
                        

                        return (

                            <Draggable admin={admin} index={ind} key={FormParameter.uuid} formUuid={form.uuid} uuid={FormParameter.uuid} position='relative'>
                                <Box position='relative'>

                                    
                                    <InputWrapper 
                                        label={
                                            <>
                                                {FormParameter.UserParameter ? FormParameter.UserParameter.question : null}                        
                                                {attemptedSubmit && FormParameter.required && formData[FormParameter.uuid].value === null &&
                                                    <Tag colorScheme='red' size='xs' ml={2}>
                                                        Required
                                                    </Tag>
                                                }
                                            </>
                                        }
                                        description={FormParameter.UserParameter ? FormParameter.UserParameter.description : null}
                                        type={FormParameter.UserParameter ? FormParameter.UserParameter.type : null}
                                        options={FormParameter.UserParameter ? FormParameter.UserParameter.options : null}
                                        ind={ind}
                                        json={typeof FormParameter.json == 'string' ? JSON.parse(FormParameter.json) : FormParameter.json}
                                        editable={true}

                                        // Muliple ways of doing the same thing!
                                        // This is bad
                                        onChange={(e) => {
                                            console.log("Updating data from input (onChange)", tmpType, e.target.value);
                                            updateData(FormParameter.uuid, FormParameter.UserParameterUuid, e.target.value, tmpType);
                                        }}
                                        setValue={(name, value) => {
                                            console.log("Updating data from input (setValue)", tmpType, value);
                                            updateData(FormParameter.uuid, FormParameter.UserParameterUuid, value, tmpType);    
                                        }}
                                        saveEntry={(value) => {
                                            console.log("Updating data from input (saveEntry)", tmpType, value);
                                            updateData(FormParameter.uuid, FormParameter.UserParameterUuid, value, tmpType);
                                        }}
                                        onBlur={(value) => {

                                            if (!admin) {
                                                submitForm(false);
                                            }
                                        }}

                                        admin={admin}
                                        edit={() => {
                                            let json = JSON.parse(FormParameter.json);
                                            if (typeof json === 'string') {
                                                json = JSON.parse(json);
                                            }
                                            let tmpSection = {
                                                uuid: FormParameter.uuid,
                                                title: json.title,
                                                description: json.description,
                                                type: json.type,
                                            };
                                            setSectionEdit(tmpSection);
                                        }}
                                        delete={() => {
                                            deleteElement(FormParameter.uuid);
                                        }}
                                        defaultValue={formData[FormParameter.uuid].value}
                                        />

                                {admin &&
                                    <Box position='absolute' 
                                        className='show-on-parent-hover'
                                        right='-37px'
                                        top='-2px'
                                        >
                                        <Menu>
                                            <MenuButton>
                                                <IconButton 
                                                    isRound={true}
                                                    variant='outline' colorScheme='blue' 
                                                    size='sm' 
                                                    aria-label='Add Item'
                                                    bg='white'
                                                    dropShadow='md'
                                                    _hover={{bg: 'blue.500', color: 'white', dropShadow: 'lg'}}
                                                    w='24px'
                                                    h='24px'
                                                    minW='unset'
                                                    fontSize='16px'
                                                    icon={<Icon as={MdAdd} />} />
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => {updateAddDetails(ind, 1);}}>Question</MenuItem>
                                                <AddSection uuid={form.uuid} order={ind + 1} />
                                                <MenuItem onClick={() => {
                                                    addDivider(form.uuid, ind + 1);
                                                }}>
                                                    Divider
                                                </MenuItem>

                                            </MenuList>
                                        </Menu>
                                    </Box>
                                }
                                </Box>
                            </Draggable>
                        )
                    })}



                    {error &&
                        <Text color='red.500'>
                            {error}
                        </Text>
                    }

                    <Box pb={8}>

                        <Box minH='4px' bg='gray.200'  mt={6} ml={-6} mr={-6} mb={4} position='relative' overflow='hidden'>
                        </Box>


                        <Heading mb={8} size='smalltitle'>
                            Signature
                        </Heading>

                        <Box border='2px solid black' borderRadius='md' w='100%'    
                            minH='100px'
                            mb='24px'
                            p='8px'
                            >
                            <Text fontSize='sm' position='abbsolute'
                                color='black'
                                fontWeight='600'
                                >
                                Signature
                            </Text>
                            <Text fontSize='sm' position='abbsolute' color='subtle'>
                                We use electronic signatures confirmed by a code send to your mobile or email. When you're ready you can sign the form before submitting. You will need to re-sign the form if you make any changes.
                            </Text>

                        </Box>
                            
                        <ButtonGroup ml='auto' size='sm' colorScheme='blue' variant='outline'>
                            <Button variant='solid' onClick={() => {
                                submitForm();
                            }}>Send Code to your email</Button>
                            <Button>Send Code to your mobile</Button>
                        </ButtonGroup>

                    </Box>


                    </Stack>


                </GridItem>
            
            
            }

        </Layout>
    )
    
}