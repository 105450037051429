import React, { useState, useEffect, useContext } from 'react';
import { useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Splash from '../../static/layout/Splash';
import AutoForm from '../../core/models/AutoForm';
import Modal from '../../core/components/modal/Modal';
import Layout from '../../core/layout/Layout';

import FinanceSetup from './FinanceSetup';


export default function Test(props) {

    const [show, setShow] = useState(false);


    return (
        <Layout
            title='Organisation Testing'
            >


            {show &&
            
                <FinanceSetup  />
            }


            <Button 
                onClick={() => setShow(true)}
                >
                Show Modal  
            </Button>
        </Layout>
    )

}