import React, { useState, useEffect, useContext } from 'react';
import { useToast, Menu, MenuButton, MenuList, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuItem } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdFlag, MdWarning, MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility, MdPhone, MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdMail, MdContentCopy } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import Process from './Process';
import { BsTags } from 'react-icons/bs';

export default function Complaint(props) {

    let toast = useToast();

    let navigate = useNavigate();

    const params = useLocation();
    console.log("Params", params.state);
    const { uuid } = params.state;
    
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [insights, setInsights] = useState(null);
    let colorOptions = {
        'APOLOGY': 'red',
        'ADVICE': 'orange',
        'INFORMATION': 'yellow',
        'EXPLANATION': 'green',
        'COMPLIMENT': 'green',
        'OTHER': 'blue',
        'LEARNING': 'purple',
        'COMPLAINT': 'red',
        'OPEN': 'orange',
        'CLOSED': 'green',
        'PENDING': 'yellow',
        'DRAFT': 'orange',
        'SENT': 'green',
        'RECEIVED': 'yellow',
        'APPROVAL': 'yellow',
        'LEGAL': 'purple',
        'PALS': 'purple',
        'INVESTIGATION': 'red',
        'CLINICAL': 'red',
        'SIGN OFF': 'green',
    }

    let insightsArray = {};

    function getItem() {

        console.log("Getting item with UUID", uuid);

        let url = 'complaints';
        let config = {
            params: {
                uuid
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            let tmpComplaint = response.data.complaint;

            let tmpInsights = {
                'IDM': {
                    'value': '7',
                    'warning': true,
                    'title': 'Index of Multiple Deprivation',
                    'explaination': 'The Index of Multiple Deprivation (IMD) is a measure of relative deprivation for neighbourhoods.'
                },
                'EFL': {
                    'value': response.data.complaint.language,
                    'warning': response.data.complaint.language !== 'English',
                    'title': 'Primary language',
                    'explaination': 'The patient has English as a foreign/second language and is more likely to experience health inequalities.'
                },
                'Ethnicity': {
                    'value': response.data.complaint.ethnicity,
                    'warning': response.data.complaint.ethnicity !== 'White British',
                    'title': 'Ethnicity',
                    'explaination': 'Health inequalities are linked to communities that are ethnically diverse.'
                },
                'Disability': {
                    'value': response.data.complaint.disability,
                    'warning': response.data.complaint.disability,
                    'title': 'Disability',
                    'explaination': 'There is an increased risk of health inequality for people with disabilities.'
                },
            }

            // Check if response.data.complaint.json exists
            if (!tmpComplaint.json) {
                tmpComplaint.json = {};
            }

            tmpComplaint['insights'] = tmpInsights;

            console.log("Insights", tmpComplaint);

            setItem(tmpComplaint);
            setLoading(false);

        })
        .catch(error => {
            console.log(error);
        });

    }

    useEffect(() => {

        if (uuid) {
            getItem();
        }

    }, [uuid]);



    let names = [
        'Oliver Trampleasure',
        'Ali Jawad',
        'Sarah Smith',
        'John Smith',
        'Jane Smith',
    ]

    // Randomly select between 1-3 names
    let randomNames = [];
    let randomNamesCount = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < randomNamesCount; i++) {
        let randomName = names[Math.floor(Math.random() * names.length)];
        // Check if name already exists
        if (randomNames.includes(randomName)) {
            // If it does don't add
        } else {
            randomNames.push(randomName);
        }
    }


    let states = [
        {
            'label': 'Pending initial response',
            'value': 'Recieved'
        },
        {   
            'label': 'Resolved',
            'value': 'Resolved'
        },
        {
            'label': 'Awaiting clinical input',
            'value': 'Clinical'
        },
        {
            'label': 'Awaiting legal input',
            'value': 'Legal'
        },
        {
            'label': 'Awaiting PALS input',
            'value': 'PALS'
        },
        {
            'label': 'Awaiting investigation',
            'value': 'Investigation'
        },
        {
            'label': 'Awaiting client response',
            'value': 'Client'
        },
        {
            'label': 'Awaiting approval',
            'value': 'Approval'
        },
        {
            'label': 'Awaiting sign off',
            'value': 'Sign off'
        },
        {
            'label': 'Response sent',
            'value': 'Sent'
        },
        {
            'label': 'Closed',
            'value': 'Closed'
        },
        {
            'label': 'Awaiting draft',
            'value': 'Draft'
        }
    ]


    function updateStatus(status) {

        let url = 'complaints/status';
        let config = {
            params: {
                id: item.id,
                status: status
            }
        };

        let resp = API.patchItems({url, 
            data: {
                id: item.id,
                status: status
            }})
        .then(response => {

            console.log("Status updated", response.data);
            getItem();

        })
        .catch(error => {
            console.log(error);
        });


    }



    return (

        <>

        <Layout
            title={loading ? 'Loading...' : item.type + ": " + item.trackingNumber.toUpperCase()}
            description=""
            actionContent={
                <>
                    <Button 
                        variant='outline'
                        colorScheme='blue'
                        onClick={() => {navigate('/organisation/feedback');}}
                        >
                            Return
                    </Button>

                    <Menu>
                        <MenuButton as={Button} variant='outline' colorScheme='red' ml={2}>
                            Actions
                            <Icon as={MdExpandMore} ml={2} mb='-3px'/>
                        </MenuButton>
                        <MenuList>
                            <MenuItem>
                                Confirm Consent Attained
                            </MenuItem>
                            <MenuItem>
                                Confirm Patient Contacted
                            </MenuItem>
                            <MenuItem>
                                Resolve Communication
                            </MenuItem>
                            <MenuItem>
                                Assign to Team
                            </MenuItem>
                        </MenuList>
                    </Menu>
                        
                </>
            }
            >

                <GridItem colSpan={{ base: 12, md: 12 }} mb={4} spacing={0}>


                    {loading ? <LoadingCircle /> : null}

                    {item &&

                        <Box pt={4}>


                            <Box>
                    
                                <Header c={9}
                                    // skip action column
                                    buttons='hide'
                                    >
                                        
                                    <Cell>
                                        Reference
                                    </Cell>
                                    <Cell>
                                        Created
                                    </Cell>
                                    <Cell>
                                        Reason for Contact
                                    </Cell>
                                    <Cell>
                                        Service
                                    </Cell>
                                    <Cell c={2}>
                                        Department
                                    </Cell>
                                    <Cell>
                                        Expected Outcome
                                    </Cell>
                                    <Cell>
                                        Status
                                    </Cell>
                                    <Cell>
                                        Assignees
                                    </Cell>
                                </Header>

                                <Row c={9}>
                                    <Cell>
                                        <Text>{item.trackingNumber.toUpperCase()}</Text>
                                    </Cell>
                                    <Cell>
                                        {moment(item.created).format('DD/MM/YYYY')}
                                    </Cell>
                                    <Cell>
                                        <Tag size='sm' colorScheme={item.type && colorOptions[item.type.toUpperCase()]}>{item.type}</Tag>
                                    </Cell>
                                    <Cell>
                                        {item.location}
                                    </Cell>
                                    <Cell c={2}>
                                        {item.team.replace(",",", ")}
                                    </Cell>
                                    <Cell>
                                        <Tag size='sm' colorScheme={item.expectedOutcome && colorOptions[item.expectedOutcome.toUpperCase()]}>{item.expectedOutcome}</Tag>
                                    </Cell>
                                    <Cell>
                                        <Menu>
                                            <MenuButton as={Tag} cursor='pointer' colorScheme={item.state && colorOptions[item.state.toUpperCase()]} size='xs'>
                                                {item.state.charAt(0).toUpperCase() + item.state.slice(1)}
                                                <Icon as={MdExpandMore} ml={2} mb='-3px'/>
                                            </MenuButton>
                                            <MenuList>
                                                {states.map((state, i) => {
                                                    return (
                                                        <MenuItem onClick={() => {updateStatus(state.value)}}>
                                                            {state.label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </MenuList>
                                        </Menu>
                                    </Cell>
                                    <Cell>
                                        {randomNames.join(", ")}
                                    </Cell>
                                </Row>

                            </Box>


                            {item.json && (item.json.safeguarding === 'TRUE' || item.json.safeguarding === 'true') &&
                                <Alert display='none' status="error" variant="left-accent">
                                    <AlertIcon />
                                    <AlertTitle mr={2}>Safeguarding Alert</AlertTitle>
                                    <AlertDescription>
                                        This complaint has been flagged as a safeguarding concern.
                                    </AlertDescription>
                                </Alert>
                            }


                                


                            <SimpleGrid columns={{base: 1, md: 3}} spacing={8} pt={8}>

                                <GridItem display='none' colSpan={3} borderRadius='md' boxShadow='md' p={4} mb={4} bg='white'>
                                    <Process />
                                </GridItem>

                                <GridItem colSpan={{base: 0, md: 2}} borderRadius='md' boxShadow='md' p={4} mb={4} bg='white'>

                                    <Heading size='sectiontitle' fontSize='24px' mb={4}>
                                        Summary
                                    </Heading>

                                    <Text mb={4}>
                                        {item.json.summary}
                                    </Text>

                                    <Wrap mb={4}>
                                        {item.json.themes && item.json.themes.split(", ").map((tag, i) => {
                                            return (
                                                <Tag size='sm' colorScheme='blue' mr={2}>
                                                    {tag}
                                                </Tag>
                                            )
                                        })}
                                    </Wrap>

                                    <Heading size='sectiontitle' fontSize='24px' mb={4} pt={4}>
                                        Full text
                                    </Heading>

                                    {item.body.split("\n").map((item, i) => {
                                        return (
                                            <Text mb={2}>
                                                {item}
                                            </Text>
                                        )
                                    })}

                                </GridItem>


                                <GridItem >

                                <Stack spacing={8}>
                                    
                                    <Stack spacing={6} borderRadius='md' boxShadow='md' p={4} bg='white'>
>

                                        <Heading size='sectiontitle' fontSize='24px'>
                                            Details
                                        </Heading>

                                        <Box>
                                            <Heading size='label' mb={2}>
                                                Time Elapsed
                                            </Heading>
                                            <Text>
                                                {moment(item.dateCreated).diff(moment(), 'days') + " days"}
                                            </Text>
                                        </Box>

                                        <Box>
                                            <Heading size='label' mb={2}>
                                                First Response Due
                                            </Heading>
                                            <Wrap spacing={2}>
                                                <Text>
                                                    { // Check if first response was completed within 24 hr 72 hr or longer
                                                    moment(item.dateCreated).add(72, 'hours').fromNow() + " (" + moment(item.dateCreated).add(72, 'hours').format('DD/MM/YYYY') + ")"
                                                    }
                                                </Text>

                                                <Button size='xs' variant='ghost' colorScheme='blue'
                                                    onClick={() => {
                                                        console.log("Send reminder");
                                                        // Open mailto link
                                                        window.open('mailto:' + item.email + '?subject=Re: ' + item.trackingNumber.toUpperCase() + '&body=Dear ' + item.pFirstname + ',%0D%0A%0D%0A' + (item.json.response && item.json.response.replace("\n", "%0D%0A")));
                                                    }}
                                                    >
                                                    Send Response
                                                </Button>
                                            </Wrap>
                                        </Box>

                                        <Box>
                                            <Heading size='label' mb={2}>
                                                Final Resolution Due
                                            </Heading>
                                            <Text>
                                                { // Check if first response was completed within 14 days
                                                moment(item.dateCreated).add(14, 'days').fromNow() + " (" + moment(item.dateCreated).add(14, 'days').format('DD/MM/YYYY') + ")"
                                                }
                                            </Text>
                                        </Box>

                                        <Box>
                                            <Heading size='label' mb={2}>
                                                Assignees
                                            </Heading>
                                            <Wrap spacing={2}>
                                                {randomNames.map((name, i) => {
                                                    return (
                                                        <Button size='xs' colorScheme='blue'
                                                            variant='solid'
                                                            onClick={() => {
                                                                console.log("Select assignee")
                                                            }}>
                                                                {name}
                                                        </Button>
                                                    )
                                                })}
                                                <Button size='xs' colorScheme='blue'
                                                    variant='ghost'
                                                    onClick={() => {
                                                        console.log("Select assignee")
                                                    }}>
                                                        Assign Staff
                                                </Button>
                                                <Menu>
                                                    <MenuButton as={Button} size='xs' colorScheme='blue'
                                                        variant='ghost'>
                                                            Suggested
                                                            <Icon as={MdExpandMore} ml={2} mb='-3px'/>
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem>
                                                            Clinical Director (Maternity)
                                                        </MenuItem>
                                                        <MenuItem>
                                                            Clinical Director (Paediatrics)
                                                        </MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </Wrap>
                                        </Box>

                                    </Stack>
                                    <Stack spacing={6} borderRadius='md' boxShadow='md' p={4} bg='white'>
>

                                        <Heading size='sectiontitle' fontSize='24px'>
                                            Service User
                                        </Heading>


                                        <Box>
                                            <Heading size='label' mb={2}>
                                                {item.pFirstname + " " + item.pLastname} <Text as='span' color='subtle'>(Service User)</Text>
                                            </Heading>
                                            <Wrap spacing={2}>
                                                {item.pConsetConfirmed ?
                                                    <Tag size='sm' colorScheme='green' order='1'>Consent Confirmed</Tag>
                                                    :
                                                    <Tag size='sm' colorScheme='red' order='0'>Consent Not Confirmed</Tag>
                                                }
                                                <Tag size='sm' order='1'>
                                                    DOB: {moment(new Date(item.pDOB)).format('DD/MM/YYYY')}
                                                </Tag>
                                                <Tag size='sm' order='1'>
                                                    Age: {moment().diff(item.pDOB, 'years')}
                                                </Tag>
                                                <Tag size='sm' order='1'
                                                    cursor='pointer'
                                                    onClick={() => {
                                                        // copy to clipboard
                                                        navigator.clipboard.writeText(item.nhsNumber);
                                                        toast({
                                                            title: "NHS Number copied",
                                                            status: "success",
                                                            duration: 3000,
                                                            isClosable: true,
                                                        });
                                                    }}
                                                    >
                                                    NHS: {item.nhs}
                                                    <Icon as={MdContentCopy} ml={2} mt='-1px'/>
                                                </Tag>
                                                <Tag size='sm' order='1'
                                                    cursor='pointer'
                                                    onClick={() => {
                                                        // copy to clipboard
                                                        navigator.clipboard.writeText(item.mrn);
                                                        toast({
                                                            title: "MRN copied",
                                                            status: "success",
                                                            duration: 3000,
                                                            isClosable: true,
                                                        });
                                                    }}
                                                    >
                                                    MRN: {item.mrn}
                                                    <Icon as={MdContentCopy} ml={2} mt='-1px'/>
                                                </Tag>
                                                <Tag size='sm' order='1'>
                                                    Ethnicity: {item.ethnicity}
                                                </Tag>
                                                {item.language === 'English' ?
                                                    <Tag size='sm' order='1'>
                                                        Language: {item.language}
                                                    </Tag>
                                                    :
                                                    <Tag size='sm' colorScheme='orange' order='0'>
                                                        Language: {item.language}
                                                    </Tag>
                                                }
                                                {item.interpreter ? 
                                                    <Tag size='sm' colorScheme='orange' order='0'>Interpretor Required</Tag> 
                                                    : 
                                                    <Tag size='sm' order='1'>No Interpretor Required</Tag>
                                                }
                                                {item.disability ?
                                                    <>
                                                        <Tag size='sm' colorScheme='orange' order='0'>Disability</Tag>
                                                        {item.disabilityDetails &&
                                                            <Text  size='sm' color='subtle' order='2'>
                                                                Disability Details: {item.disabilityDetails}
                                                            </Text>
                                                        }
                                                    </>
                                                    :
                                                    <Tag size='sm' order='1'>No Disability</Tag>
                                                }
                                            </Wrap>
                                            <Wrap spacing={2} pt={2}>
                                                <Button size='xs' variant='ghost' colorScheme='blue' 
                                                    onClick={() => {
                                                        // Open mailto link
                                                        window.open('mailto:' + item.email + '?subject=Re: ' + item.trackingNumber.toUpperCase());
                                                    }}
                                                    >
                                                    <Icon as={MdMail} mr={2} />
                                                    {item.email}
                                                </Button>
                                                <Button size='xs' variant='ghost' colorScheme='blue'
                                                    onClick={() => {
                                                        // Open mailto link
                                                        window.open('tel:' + item.phone);
                                                    }}
                                                    >
                                                    <Icon as={MdPhone} mr={2} />
                                                    {item.phone}
                                                </Button>
                                            </Wrap>


                                        </Box>


                                        <Box>
                                            <Heading size='label' mb={2}>
                                                {item.firstname + " " + item.lastname} <Text as='span' color='subtle'>(Submitter)</Text>
                                            </Heading>
                                            <Wrap spacing={2}>
                                                <Button size='xs' variant='ghost' colorScheme='blue' 
                                                    onClick={() => {
                                                        // Open mailto link
                                                        window.open('mailto:' + item.email + '?subject=Re: ' + item.trackingNumber.toUpperCase());
                                                    }}
                                                    >
                                                    <Icon as={MdMail} mr={2} />
                                                    {item.email}
                                                </Button>
                                                <Button size='xs' variant='ghost' colorScheme='blue'
                                                    onClick={() => {
                                                        // Open mailto link
                                                        window.open('tel:' + item.phone);
                                                    }}
                                                    >
                                                    <Icon as={MdPhone} mr={2} />
                                                    {item.phone}
                                                </Button>
                                            </Wrap>
                                        </Box>


                                    </Stack>

                                    <Stack spacing={6} borderRadius='md' boxShadow='md' p={4} bg='white'>
>

                                        <Heading size='sectiontitle' fontSize='24px'>
                                            Insights
                                        </Heading>



                                        <Box>
                                            <Stack>
                                                {item.insights && Object.keys(item.insights).map((key, i) => {
                                                    console.log("Insight", item.insights[key]);
                                                    return (
                                                        <Box>
                                                            <Text>
                                                                {item.insights[key].title + ": " + item.insights[key].value}
                                                                {item.insights[key].warning &&
                                                                    <Icon as={MdFlag} color='orange.500' ml={2} />
                                                                }
                                                            </Text>
                                                            <Text fontSize='xs' color='subtle'>
                                                                {item.insights[key].explaination}
                                                            </Text>
                                                        </Box>
                                                    )
                                                })}
                                            </Stack>
                                        </Box>

                                    </Stack>

                                    


                                    </Stack>

                                </GridItem>


                            </SimpleGrid>

                        </Box>
                        

                    }

                </GridItem>

            </Layout>

        </>
    )
}