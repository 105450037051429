"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = function () { return ({
    container: {},
    track: {
        bg: 'bg-subtle',
    },
    filledTrack: {
        bg: 'accent',
    },
    thumb: {
        bg: 'accent',
        boxShadow: 'none',
        _focus: {
            boxShadow: 'none',
        },
    },
}); };
exports.default = {
    baseStyle: baseStyle,
};
