import React from 'react';
import { Line, Bar, Pie, Radar} from 'react-chartjs-2';
import { GridItem, Wrap, Text , Box, HStack} from "@chakra-ui/react";
import Chart from 'chart.js/auto';
import Layout from '../core/layout/Layout';

const Graphs = () => {
  const data = {
    labels: ['Oliver', 'James'],
    datasets: [
      {
        label: 'Health Record',
        data: [4, 6],
        backgroundColor: '#4895EF',
      },
      {
        label: 'Training Record',
        data: [3, 2],
        backgroundColor: '#3F37C9',
      },
      {
        label: 'Personal Record',
        data: [2, 1],
        backgroundColor: '#7209B7',
      },
    ],
  };

  const piedata = {
    labels: ['Complete', 'Expired', 'Outstanding', 'Pending Review', 'Reviewed'],
    datasets: [
      {
        label: '# of Requirements',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(72, 149, 239, 0.7)',
          'rgba(63, 55, 201, 0.7)',
          'rgba(114, 9, 183, 0.7)',
          'rgba(181, 23, 158, 0.7)',
          'rgba(247, 37, 133, 0.7)'
        ],
        borderColor: [
          '#4895EF',
          '#3F37C9',
          '#7209B7',
          '#B5179E',
          '#F72585',
        ],
        borderWidth: 1,
      },
    ],
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const linedata = {
  labels,
  datasets: [
    {
      label: 'Paediatrics',
      data: [4,4,8,12,8,16,12],
      borderColor: '#4895EF',
      backgroundColor: 'rgba(72,149,239, 0.7)',
      yAxisID: 'y',
    },
    {
      label: 'General Practice',
      data: [2,5,10,15,10,24,18],
      borderColor: '#3F37C9',
      backgroundColor: 'rgba(63,55,201, 0.7)',
      yAxisID: 'y1',
    },
  ],
};

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Individual Compliance',
      },
    },
  };

  const lineoptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Departmental Compliance',
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const radardata = {
    labels: ['Health Record', 'Training Record', 'Personal Record', 'Other'],
    datasets: [
      {
        label: 'Medical Staff',
        data: [3, 9, 3, 5],
        backgroundColor: 'rgba(181,24,159, 0.5)',
        borderColor: '#B5179E',
        borderWidth: 1,
      },
      {
        label: 'Nursing Staff',
        data: [5, 2, 3, 6],
        backgroundColor: 'rgba(247,38,133, 0.3)',
        borderColor: '#F72585',
        borderWidth: 1,
      },
    ],
  };

  console.log("DATA GRPAH", data);

  return (
    <Layout title='Graphical Dashboard'>
        
      <GridItem pt={12} colSpan='6' rowSpan='2' alignItems='flex-start' width='fill-available'>

        <Wrap spacing={12} >

          <Box w='800px' p={8}>
            <Bar data={data} options={options} />
          </Box>

          <Box maxW='400px' p={8} pt={12}>
            <Pie data={piedata} 
              options={
                {
                  plugins: {title: {text: 'Requirement Status'}},
                }
              }
            />
          </Box>

          <Box w='800px' p={8}>
            <Line options={lineoptions} data={linedata} />
          </Box>

          <Box w='600px' h='600px' p={8} pt={12}>
            <Radar data={radardata} 
              options={
                {
                  plugins: {title: {text: 'Recruitment Compliance'}},
                }
              } />
          </Box>

        </Wrap>
        
      </GridItem>

    </Layout>
    )
};

export default Graphs;