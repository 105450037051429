import React, { useState, useEffect } from 'react';
import {Box, ButtonGroup, GridItem, Heading, CircularProgressLabel, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, ring, SimpleGrid, Divider } from '@chakra-ui/react';
import API from "../../core/utils/API";
import {MdHelpOutline, MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import WorkSchedule from '../prospectiveleave/WorkSchedule';
import Layout from '../../core/layout/Layout';
import Modal from '../../core/components/modal/Modal';

import { FaCheckCircle, FaTimesCircle, FaDivide } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import PayCalculations from './PayCalculations';
import ScheduleCalculations from './schedule/ScheduleCalculations';
import ProspectiveCheck from './schedule/ProspectiveCheck';
import LeaveHourCheck from './schedule/LeaveHourCheck';

import Questions from './manage/Questions';

import Moment from 'react-moment';
import Group from './schedule/Group';
import Figure from './schedule/Figure';
import Mathematical from './schedule/Mathematical';
import EditSchedule from './manage/Edit';
import OriginalEdit from './manage/OriginalEdit';
import OriginalEditPay from './manage/OriginalEditPay';
import FTE from './schedule/FTE';
import moment from 'moment';

function roundtodp(number, dp = 0) {
    return Math.round(100 * number) / 100;
}

function currency(number) {

    number = roundtodp(number, 2);

    // Add commas to thousands
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Check if NaN
    if (number === 'NaN') return '0.00';

    // Check if only one figure after decimal point
    let numberSplit = number.split(".");
    
    if (numberSplit.length === 1) return number;

    if (numberSplit[1].length === 2) return number;

    return numberSplit[0] + '.' + numberSplit[1] + '0';

}


function convertToHours(time) {
    let hours = Math.floor(time);
    let minutes = Math.round((time - hours) * 60);

    // Add a leading zero if minutes is less than 10
    if (minutes < 10) minutes = '0' + minutes;

    // Add a leading zero if hours is less than 10
    if (hours < 10) hours = '0' + hours;

    return hours + ':' + minutes;

}

export default function Schedule(props) {

    const { state } = useLocation();
    // Get uuid passed as a state prop
   //console.log("props", state);

    const navigate = useNavigate();
    const uuid = state.uuid;
    const [rota, setRota] = useState(null);
    const [rotaLoading, setRotaLoading] = useState(true);
    const [duties, setDuties] = useState(null);
    const [schedule, setSchedule] = useState(null);
    const [originalSchedule, setOriginalSchedule] = useState(null);
    const [showCalculations, setShowCalculations] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showOriginal, setShowOriginal] = useState(true);
    const [showChecks, setShowChecks] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showAllErrors, setShowAllErrors] = useState(false);
    const [unknownShifts, setUnknownShifts] = useState(null)

    const [showQuestions, setShowQuestions] = useState(state.showQuestions ? state.showQuestions : false);

    //console.log("SHOW QUESTIONS", showQuestions);

    function switchShow(section, show) {

        if (!show) {
            setShowCalculations(false);
            setShowSchedule(false);
            setShowChecks(false);
        } else {
            if (section === 'calculations') {
                setShowCalculations(true);
                setShowSchedule(false);
                setShowChecks(false);
            }
            if (section === 'schedule') {
                setShowCalculations(false);
                setShowSchedule(true);
                setShowChecks(false);
            }
            if (section === 'checks') {
                setShowCalculations(false);
                setShowSchedule(false);
                setShowChecks(true);
            }
        }

    }


    function refreshSchedule(runAnalysis = false) {

        setRotaLoading(false);
        let ran = false;
        if (runAnalysis) {
            ran = repeatAnalysis();
        }

        let url = 'schedules';
        let config = {
            params: {
                uuid: uuid
            }
        };
        let response = API.getItems({url, config})
        .then(response => {
            //console.log(response);
            if (response.data.rotas === null) {
                // Start timer to check again in 5 seconds
                setTimeout(() => {
                    setRotaLoading(true);
                }, 5000);
                // Exit function
                return
            } else {
                console.log("Original Response Data", response.data);
                //console.log(JSON.parse(response.data.schedule));
                //console.log(JSON.parse(response.data.rota));
                // Add ? and annual leave to schedule


                let shifts = response.data.duties;

                // Check if shifts is json object
                if (typeof shifts === 'string') {
                    shifts = JSON.parse(shifts);
                }


                shifts['OFF'] = {
                    'shorthand': 'OFF',
                    'start': 'N/A',
                    'end': 'N/A',
                    'hours': 0,
                    'enhanced': 0,
                    'enhancedText': 'N/A',
                    'name': 'OFF',
                    'colour': '#ECFFDC'
                }
                shifts['?'] = {
                    'shorthand': '?',
                    'start': 'N/A',
                    'end': 'N/A',
                    'hours': 0,
                    'enhanced': 0,
                    'enhancedText': 'N/A',
                    'name': 'Unknown',
                    'colour': '#D00101'
                }
                //console.log("OUTPUTS", 
                //JSON.parse(response.data.jsonForms),
                //JSON.parse(response.data.jsonQueries),
                //JSON.parse(response.data.schedule));

                let tmpSchedule = response.data.scheduleDB;
                // If userParameters is null then set to empty object
                if (tmpSchedule.userParameters === null) tmpSchedule.userParameters = {};
                if (tmpSchedule.jsonErrors === null) tmpSchedule.jsonErrors = JSON.stringify({});
                if (tmpSchedule.jsonTesting === null) tmpSchedule.jsonTesting = JSON.stringify({});

                console.log("TMP SCHEDULE", tmpSchedule);

                // Loop through the rota (an array of weeks, each with an array of shifts)
                let unknownShifts = 0;
                let tmpRota = JSON.parse(response.data.rota);
                tmpRota.map((week, weekIndex) => {
                    week.map((shift, shiftIndex) => {
                        //console.log(shift);
                        // If the shift is not in the shifts object then add it
                        if (shift === '?') {
                            unknownShifts++;
                        }
                    });
                });
                
                setDuties(shifts);
                setSchedule(tmpSchedule);
                setRota(tmpRota);
                setUnknownShifts(unknownShifts);
                setOriginalSchedule(JSON.parse(response.data.schedule));
                setSchedule(response.data.scheduleDB);

            }
        }).catch(error => {
            console.log(error);
            // Start timer to check again in 5 seconds
            setTimeout(() => {
                setRotaLoading(true);
            }, 5000);
        });


    }

    useEffect(() => {

        if (rotaLoading) {
            refreshSchedule(false);
        };

    }, [rotaLoading]);

    function repeatAnalysis() {

        let url = 'schedules/repeat';
        let config = {
            params: {
                uuid: uuid
            }
        };
        let response = API.getItems({url, config})
        .then(response => {
            //console.log(response);
            
            if (response.data.rotas === null) {
                // Start timer to check again in 5 seconds
                setTimeout(() => {
                    setRotaLoading(true);
                }, 5000);
                // Exit function
                return
            } else {
                // console.log(JSON.parse(response.data.schedule));
                // Add ? and annual leave to schedule
            }
        }).catch(error => {
            console.log(error);
        });

        return true;

    }

    function saveRota(duties, rota) {

        //console.log("saving rota", rota);

        let url = 'schedules/rota';
        let config = {
            params: {
                uuid: uuid,
                duties: JSON.stringify(duties),
                rota: JSON.stringify(rota)
            }
        };

        let response = API.patchItems({url, config})
        .then(response => {
            //console.log(response);
            // Update everything
            
            refreshSchedule(false);
//            navigate('/pay/schedule', {state: {uuid: uuid}});

            return true;
        }).catch(error => {
            console.log(error);
            return false;
        });

    }


    return (

        <>


            {showQuestions &&

                <Modal
                    title='Schedule Questions'
                    onClose={() => {
                        setShowQuestions(false);
                        refreshSchedule(false);
                    }}
                    hideClose={true}
                    hideFooter={true}
                    >
                    <Text>Please answer these questions so that we can accurately calculate your appropriate salary, we are already analysing the PDF in the background.</Text>
                    <Questions
                        uuid={uuid}
                        onClose={() => {setShowQuestions(false);}}
                        />
                </Modal>

            }
            {(rota === null || duties === null) ?
                <Splash>
                    <Loading
                        show={true}
                        message='Awaiting AI analysis' />
                </Splash>
            :
            
                    
                <Layout 
                    title='Work Schedule'
                    actionContent={
                        <>
                        <Button display='none' variant='outline' onClick={() => {navigate('/pay/uploadschedule', { state: { uuid: uuid, stage: 2 }});}}>
                            Edit
                        </Button>
                        <Button display='none' colorScheme='blue' variant='solid' onClick={() => repeatAnalysis()}>
                            Personalise
                        </Button>
                        <Button variant='outline' colorScheme='blue' onClick={() => {navigate('/pay/schedules/')}}>
                            Your Schedules
                        </Button>
                        </>
                    }
                    >


                    <GridItem colSpan='12'>



                        {showEdit &&

                            <Modal 
                                title='Edit Schedule'
                                description='Edit your schedule'
                                onClose={() => {setShowEdit(false);}}
                                >
                                <EditSchedule
                                    uuid={uuid}
                                    attributes={schedule}
                                    />
                            </Modal>

                        }


                        {schedule.fteCalculated < 1 && (schedule.fte === 0 || schedule.fte === null) &&
                            <FTE
                                uuid={uuid}
                                fteCalculated={schedule.fteCalculated}
                                fte={schedule.fte}
                                refreshSchedule={() => {refreshSchedule(true);}}
                                />
                        }



                    {showOriginal &&

                     
                        <Box w='fit-content' pt={8}>

                            <Heading fontSize='1.5em' fontWeight="bold" pb={0} lineHeight='2em'>
                                Original Information
                            </Heading>
                                <Text color='subtle' fontSize='md' maxW='700px' pb={2}>
                                    This is the original data that you uploaded, we have extracted this using our AI engine but you can correct it if anything is wrong.
                                </Text>

                            <Box w='fit-content' bg='white' p={props.p ? props.p : 4} pt={0} borderRadius='md' boxShadow='md' mb={12} mt={4}>
                                
                            <Stack direction={{ base: 'column', md: 'row' }} spacing={4} pt={0}>

                                <Wrap spacing={4} pt={0}>


                                    <Figure
                                        heading='Basic salary'
                                        value={"£" + currency(originalSchedule.basicPay)}
                                        />

                                    <Mathematical
                                        icon={MdAdd}
                                        />

                                    <Figure
                                        heading='Weekend pay'
                                        value={"£" + currency(originalSchedule.weekendPay)}
                                        explaination={
                                            <>
                                                {'1:' + originalSchedule.weekendFrequency ? originalSchedule.weekendFrequency + ' weekends, ' : ''}
                                                {originalSchedule.weekendPercentage ? originalSchedule.weekendPercentage + '% of basic pay,' : ''}
                                            </>
                                        }
                                        />
                                    
                                    <Mathematical
                                        icon={MdAdd}
                                        />

                                    <Figure
                                        heading='Additional pay'
                                        value={"£" + currency(schedule.userParameters.additionalPay ? schedule.userParameters.additionalPay : originalSchedule.additionalPay)}
                                        explaination={schedule.userParameters.additional ? schedule.userParameters.additional : originalSchedule.additional ? originalSchedule.additional + ' hours / week' : '' }
                                        />

                                    <Mathematical
                                        icon={MdAdd}
                                        />
                                    
                                    <Figure
                                        heading='Enhanced pay'
                                        value={"£" + currency(schedule.userParameters.enhancedPay ? schedule.userParameters.enhancedPay : originalSchedule.enhancedPay)}
                                        explaination={schedule.userParameters.enhanced ? schedule.userParameters.enhanced : originalSchedule.enhanced ? originalSchedule.enhanced + ' hours / week' : ''}
                                        />

                                    {schedule.londonInclude === true &&
                                        <>
                                            <Mathematical
                                                icon={MdAdd}
                                                />
                                            
                                            <Figure
                                                heading='London weighting'
                                                value={"£" + currency(schedule.londonWeighting)}
                                                explaination='Likely included given totals'
                                                />                                   
                                        </>
                                    }

                                    {schedule.payPremiaInclude === true &&
                                        <>
                                            <Mathematical
                                                icon={MdAdd}
                                                />
                                            
                                            <Figure
                                                heading='Pay Premia'
                                                value={"£" + currency(schedule.payPremiaPay)}
                                                explaination='Likely included given totals'
                                                />                                   
                                        </>
                                    }


                                    <Mathematical
                                        value='='
                                        />

                                    <Figure
                                        heading='Total pay'
                                        value={"£" + currency(originalSchedule.totalPay)}
                                        />

                                    </Wrap>

<Flex flexGrow={1} justifyContent={{ base: 'flex-start', md: 'flex-end' }} pt={4} >
<OriginalEditPay 
    uuid={uuid}
                                                        originalSchedule={originalSchedule} 
                                                        schedule={schedule}
                                                        refresh={refreshSchedule}
                                                        refreshSchedule={() => {refreshSchedule(false);}}
                                                        />
</Flex>

                                    </Stack>
                                    

                                <Divider mt='24px' mb='0px' />

                                <Stack direction={{ base: 'column', md: 'row' }} spacing={4} pt={0}>

                                    <SimpleGrid columns={{base:1, md: 7}} spacing={4} pt={4}>

                                        <GridItem colSpan={{base:1, md: 3}}>
                                            <List fontSize='sm'>
                                                <ListItem>
                                                    <b>Specialty:</b> {schedule.specialty}, 
                                                    <b> Site:</b> {schedule.site},
                                                    <b> Organisation:</b> {schedule.organisation}
                                                </ListItem>
                                                <ListItem display='none'>
                                                    <b>Host:</b> {schedule.host}
                                                </ListItem>
                                            </List>
                                        </GridItem>
                                        <GridItem colSpan={{base:1, md: 2}}>
                                            <List fontSize='sm'>
                                                <ListItem>
                                                    <b>Grade:</b> {schedule.grade} at {roundtodp(schedule.fteCalculated * 100, 0)}% FTE
                                                </ListItem>
                                                <ListItem>
                                                    <b>Dates:</b> {moment(schedule.start).format('DD/MM/YY')}-{moment(schedule.end).format('DD/MM/YY')}
                                                </ListItem>
                                            </List>
                                        </GridItem>
                                        <GridItem colSpan={{base:1, md: 2}}>
                                            <List fontSize='sm'>
                                                <ListItem>
                                                    <b>Annual Leave:</b> {schedule.leave}
                                                </ListItem>
                                                <ListItem>
                                                    <b>Study Leave:</b> {schedule.study}
                                                </ListItem>
                                                <ListItem display='none'>
                                                    <b>Bank Holidays:</b> 8
                                                </ListItem>
                                            </List>
                                        </GridItem>

                                    </SimpleGrid>


                                    <Flex flexGrow={1} pt={4} justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
                                    <OriginalEdit 
    uuid={uuid} 
                                                        originalSchedule={originalSchedule} 
                                                        schedule={schedule}
                                                        refreshSchedule={() => {refreshSchedule(false);}}
                                                        />
                                    </Flex>
                                </Stack>

                            </Box>
                             
                            


                            <Wrap spacing={4} pt={4} display='none'>

                                {schedule.londonWeighting !== null &&
                                    <Tag size='sm' colorScheme={schedule.londonInclude ? 'blue' : 'gray'}>
                                        N.B. London weighting {schedule.londonInclude ? 'likely to' : 'may'} be included at £{currency(schedule.londonWeighting)}.
                                    </Tag>
                                }
                                {schedule.payPremiaPay !== null &&
                                    <Tag size='sm' colorScheme={schedule.payPremiaInclude ? 'blue' : 'gray'}>
                                        N.B. A pay premia {schedule.payPremiaInclude ? 'likely to' : 'may'} be included at £{currency(schedule.payPremiaPay)}.
                                    </Tag>
                                } 

                            </Wrap>

                        </Box>

                    }



                        <PayCalculations
                            annualSalary={currency(schedule.annualSalary)}
                            baseSalary={currency(schedule.aiParameters.baseSalary)}
                            grade={schedule.grade}
                            fte={schedule.fte}
                            fteCalculated={schedule.fteCalculated}
                            
                            enhanced={roundtodp(schedule.enhanced,2)}
                            enhancedRate={currency(schedule.enhancedRate)}
                            enhancedPay={currency(schedule.enhancedPay)}

                            additional={roundtodp(schedule.additional,2)}
                            rate={currency(schedule.rate)}
                            additionalPay={currency(schedule.additionalPay)}

                            payPremiaInclude={schedule.payPremiaInclude}
                            payPremiaPay={currency(schedule.payPremiaPay)}
                            londonInclude={schedule.londonInclude}
                            londonWeighting={currency(schedule.londonWeighting)}


                            weekendFrequency={roundtodp(schedule.weekendFrequency,1)}
                            weekendPercentage={schedule.weekendPercentage}
                            weekendPay={currency(schedule.weekendPay)}

                            totalPay={schedule.totalPay}

                            scheduleTotalSalary={originalSchedule.totalPay}

                            difference={schedule.difference}

                            />

                        <Text fontSize='sm' color='subtle' pt={4} mb={4}>
                            These figures were on the basis of {schedule.leave} days annual leave, {schedule.study} days study leave and 8 bank holidays per year.
                            Please note that some trainees will have additional payments not in the work schedule, such as pay premia, London weighting, or additional hours (i.e. GP trainees with out of hour payments not in their work schedule rota).
                        </Text>


                        {schedule.fteCalculated < 1 && !(schedule.fteCalculated === 0 || schedule.fteCalculated === null) &&
                            <Box mb={4}>
                                <Heading fontSize='sm'color='red.500' pb={0} lineHeight='1.2em'>
                                    Weekend Payment Inaccurate
                                </Heading>

                                <Text fontSize='sm'color='subtle'>
                                    The weekend payment is calculated from your rota, but for payment should be calculated as a percentage of the weekends you work compared to full-time employees. As we do not know the number of weekends worked on a 100% FTE rota we are unable to calculate this accurately.
                                </Text>

                            </Box>
                        }

                        {unknownShifts > 0 &&
                            <Box mb={4}>
                                <Heading fontSize='sm' color='red.500'  pb={0} lineHeight='1.2em'>
                                    Missing Shifts
                                </Heading>

                                <Text fontSize='sm' color='subtle'>
                                    We have identified {unknownShifts} shifts that we do not recognise, please check these and update to the appropriate shift type. You can do this by clicking 'Show Rota' below.
                                </Text>
                            </Box>
                        }

                        {schedule.aiParameters.margin !== null &&

                            <Alert status='info' mb={4} display='none'>
                                <AlertIcon />
                                <AlertTitle mr={2}>Margin</AlertTitle>
                                <AlertDescription>
                                    There is an additional payment of £{roundtodp(schedule.aiParameters.margin, 2)} due to the typical practice of rounding your hours up to the nearest 15 minutes.
                                </AlertDescription>
                            </Alert>

                        }

                        <Box mb={4}>
                            <Heading fontSize='sm' pb={0} lineHeight='1.2em'>
                                What should I do now?
                            </Heading>
                            <Text fontSize='sm' color='subtle'>
                                If you are happy with the figures then you can do nothing, if you would like to contest your pay from an employee we would recommend that you contact your union and the relevant HR team at the employing Trust.
                            </Text>
                        </Box>

                        <ButtonGroup size='xs' variant='outline' mb={12} pt={8} flexWrap='wrap'>

                            <Text mb={2}>
                                Show:
                            </Text>
                            <Button mb={2} onClick={() => setShowOriginal(!showOriginal)} colorScheme={showOriginal ? 'blue' : 'gray'}>
                                {showOriginal ? 'Hide' : 'Show'} Original Figures
                            </Button>
                            <Button  mb={2} onClick={() => switchShow('schedule', !showSchedule)} colorScheme={showSchedule ? 'blue' : 'gray'}>
                                {showSchedule ? 'Hide' : 'Show'} Rota
                            </Button>
                            <Button mb={2} onClick={() => switchShow('calculations', !showCalculations)} colorScheme={showCalculations ? 'blue' : 'gray'}>
                                {showCalculations ? 'Hide' : 'Show'} Calculations
                            </Button>
                            <Button mb={2} onClick={() => switchShow('checks', !showChecks)} colorScheme={showChecks ? 'blue' : 'gray'}>
                                {showChecks ? 'Hide' : 'Show'} Error Screening
                            </Button>
                            <Button display='none' onClick={() => {
                                setShowQuestions(true);
                                //navigate('/pay/schedules/questions', { state: { uuid: uuid }});
                                }}>
                                Repeat Questions
                            </Button>

                        </ButtonGroup>


                        {showChecks &&
                            
                            <Group 
                                title='Error Screening'
                                explaination='We have screened this schedule for common errors, and combinations of these. This information is for reference and to suggest areas for further investigation.'
                                p={0}
                                >

                                <Stack spacing={4} minW='100%'>
                                        {JSON.parse(schedule.jsonTesting).map(test => {

                                            if (test.autoMatch === 0) return (<></>);

                                            if (showAllErrors === false && test.highlight !== true ) return (<></>);

                                            let cellBG = 'none';
                                            let rowBG = 'none';
                                            let rowColor = 'black';
                                            let cellColor = 'black';
                                            let ringColor = 'gray'
                                            let opacity = 0.5;
                                            if (test.autoMatch > 0) {
                                                opacity = 1;
                                            }
                                            if (test.autoMatch === 1) {
                                                ringColor = 'gray.500';
                                                cellBG = 'gray.50';
                                                rowBG = 'gray.50';
                                            } else if ( test.autoMatch === 2) {
                                                ringColor = 'yellow.500';
                                                cellBG = 'yellow.50';
                                                rowBG = 'yellow.50';
                                            } else if (test.autoMatch === 3) {
                                                ringColor = 'orange.500';
                                                cellBG = 'orange.50';
                                                rowBG = 'orange.50';
                                            } else if (test.autoMatch > 3) {
                                                ringColor = 'red.500';
                                                cellBG = 'red.50';
                                                rowBG = 'red.50';
                                                //cellColor = 'white';
                                                //rowColor = 'white';
                                            }

                                            return (

                                                <Box order={10-test.autoMatch} p={2} mb={2} pb={6} opacity={opacity}>
                                                    <HStack >
                                                        <Box mr={4}>
                                                            <CircularProgress value={100*test.autoMatch/5} color={ringColor} bg={rowBG} borderRadius='50%'>
                                                                <CircularProgressLabel color={ringColor}>{test.autoMatch} of 5</CircularProgressLabel>
                                                            </CircularProgress>
                                                        </Box>
                                                        <Stack>
                                                            <Heading fontSize='sm' lineHeight='1em' color={ringColor}>
                                                                {test.name}
                                                            </Heading>
                                                            <Text fontSize='xs' color='subtle' as='span'>{test.description}</Text>
                                                            <Wrap>
                                                                <Tag size='sm' display='none'>
                                                                    {test.hours} hrs
                                                                </Tag>
                                                                <Tag size='sm' variant='outline' colorScheme={test.additionalTest ? 'red' : 'gray'} order={test.additionalTest ? 1 : 2}>
                                                                    Additional Hours: {convertToHours(test.additional)} hrs {test.additionalTest ? <Icon as={MdCheck} color='red.500' ml={2}/> :  <Icon as={MdClose} color='green.500' ml={2}/>}
                                                                </Tag>
                                                                <Tag size='sm' variant='outline' colorScheme={test.additionalPayTest ? 'red' : 'gray'} order={test.additionalPayTest ? 1 : 2}>
                                                                    Additional Hours Payment: £{test.additionalPay} {test.additionalPayTest ? <Icon as={MdCheck} color='red.500' ml={2}/> :  <Icon as={MdClose} color='green.500' ml={2}/>}
                                                                </Tag>
                                                                <Tag size='sm' variant='outline' colorScheme={test.enhancedTest ? 'red' : 'gray'} order={test.enhancedTest ? 1 : 2}>
                                                                    Enhanced Hours: {convertToHours(test.enhanced)} hrs {test.enhancedTest ? <Icon as={MdCheck} color='red.500' ml={2}/>:  <Icon as={MdClose} color='green.500' ml={2}/>}
                                                                </Tag>
                                                                <Tag size='sm' variant='outline' colorScheme={test.enhancedPayTest ? 'red' : 'gray'} order={test.enhancedPayTest ? 1 : 2}>
                                                                    Enhanced Hours Payment: £{test.enhancedPay} {test.enhancedPayTest ? <Icon as={MdCheck} color='red.500' ml={2}/>:  <Icon as={MdClose} color='green.500' ml={2}/>}
                                                                </Tag>
                                                                <Tag size='sm' variant='outline' colorScheme={test.nonPensionableTest ? 'red' : 'gray'} order={test.nonPensionableTest ? 1 : 2}>
                                                                    Non-Pensionable Payment: £{test.nonPensionable} {test.nonPensionableTest ? <Icon as={MdCheck} color='red.500' ml={2}/>:  <Icon as={MdClose} color='green.500' ml={2}/>}
                                                                </Tag>
                                                            </Wrap>
                                                        </Stack>
                                                    </HStack>
                                                </Box>

                                            )
                                            
                                        })}

                                                <Box order={10} p={2} mb={2} pb={6}>
                                                    <HStack >
                                                        <Box mr={4}>
                                                            <CircularProgress opacity='0' value={0} color='gray' borderRadius='50%'>
                                                                <CircularProgressLabel color='gray'><Icon as={MdHelpOutline} /></CircularProgressLabel>
                                                            </CircularProgress>
                                                        </Box>
                                                        <Stack>
                                                        <Heading fontSize='sm' lineHeight='1em'>
                                                            View Full Report
                                                        </Heading>
                                                        <Text fontSize='xs' color='subtle' as='span'>
                                                            We automatically hide the less likely errors that we have screen for, if you would like to see the full report then you can do so by clicking below.
                                                        </Text>
                                                        <Wrap>
                                                            <Button order='10' w='min-content' mb={4} variant='outline' size='xs' onClick={() => setShowAllErrors(!showAllErrors)} colorScheme={showAllErrors ? 'blue' : 'gray'}>
                                                                {showAllErrors ? 'Hide' : 'Show'} Full Report
                                                            </Button>
                                                        </Wrap>
                                                        </Stack>
                                                    </HStack>
                                                </Box>

                                </Stack>

                            </Group>

                        }

                        {showCalculations &&
                            <ScheduleCalculations schedule={schedule} duties={duties}/>
                        }

                        {showSchedule &&

                            <WorkSchedule
                                rota={rota}
                                shifts={duties}
                                leave={{
                                    'annual': schedule.leave,
                                    'study': schedule.study,
                                    'bank': 8,
                                }}
                                grade={schedule.grade}
                                leaveHours={9}
                                schedule={schedule}
                                saveRota={saveRota}
                                />
                        }








                    </GridItem>
                    

                

                </Layout>

            }

        </>

    );

}
    
