
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { 
    Alert,
    AlertIcon,
    AlertTitle,
    Textarea,
    AlertDescription,
    CircularProgress, CircularProgressLabel,
    Slider, SliderTrack, SliderFilledTrack, SliderThumb,
    ButtonGroup,Image, Checkbox, Wrap, Tag, TagLabel, IconButton,Table, Tbody, Tr, Th, Td, Icon, Circle, Avatar, Stack, HStack, GridItem, Flex, useToast, Box, Text, Heading, Input, Button, List, ListItem, Grid, 
    border} from '@chakra-ui/react';
import API from '../../../core/utils/API';
import {MdLockOutline, MdAdd, MdCheck, MdClose, MdLock, MdList, MdAccessTime, MdExpand, MdExpandMore} from 'react-icons/md';
import Moment from 'react-moment';
import Loading from '../../../static/layout/LoadingCircle';
import pluralize from 'pluralize';
import { useNavigate } from 'react-router-dom';
import { max } from 'date-fns';


export default function Weighting(props) {

    const [weightings, setWeightings] = useState(
        {
            "Quality and Innovation": {
              "description": "Assessment of clinical effectiveness, user experience, and technological innovation in ADHD diagnosis and management.",
              "weighting": 20,
              "locked": false
            },
            "Value": {
              "description": "Evaluation of cost-effectiveness, return on investment, and budgetary compliance of the tool.",
              "weighting": 15,
              "locked": false
            },
            "Integration": {
              "description": "Assessment of system interoperability, data sharing compliance, and scalability across NHS trusts.",
              "weighting": 15,
              "locked": false
            },
            "Collaboration": {
              "description": "Evaluation of partnership potential, support and training, and governance structures for collaborative working.",
              "weighting": 10,
              "locked": false
            },
            "Service Sustainability": {
              "description": "Assessment of long-term viability, environmental impact, and commitment to maintenance and upgrades.",
              "weighting": 10,
              "locked": false
            },
            "Improving Access": {
              "description": "Evaluation of accessibility features, geographical coverage, and user inclusivity for diverse populations.",
              "weighting": 10,
              "locked": false
            },
            "Reducing Health Inequalities": {
              "description": "Assessment of equity of access, data-driven insights, and outreach efforts to reduce health disparities.",
              "weighting": 10,
              "locked": false
            },
            "Facilitating Choice": {
              "description": "Evaluation of the tool’s ability to enhance patient choice and provide tailored care pathways.",
              "weighting": 5,
              "locked": false
            },
            "Social Value": {
              "description": "Assessment of community impact, ethical considerations, and engagement with the voluntary sector.",
              "weighting": 5,
              "locked": false
            }
          });

    useEffect(() => {
        console.log(weightings);
    }, [weightings]);


    const toast = useToast();
    function updateWeights(targetKey, figure) {
        console.log(targetKey, figure);

        // check for maximum available
        let maxAvailable = 0;
        let maxLosable = 0;
        Object.keys(weightings).map((key, index) => {
            if (!weightings[key].locked && key !== targetKey) {
                maxAvailable += weightings[key].weighting;
                maxLosable += (100 - weightings[key].weighting);
            }
        });

        // Check if difference if within bounds
        let difference = figure - weightings[targetKey].weighting;
        if (maxAvailable - difference < 0) {
            console.log('Exceeds maximum available');
            difference = maxAvailable;
        } else if (maxLosable + difference < 0) {
            console.log('Exceeds maximum losable');
            difference = -maxLosable;
        } 

        if (difference === 0) {

            /*
            toast({
                title: 'No change',
                description: 'No change in weighting',
                status: 'info',
                duration: 2000,
                isClosable: true
            });
            */


            newWeightings[targetKey].changed = new Date();
            setWeightings({...newWeightings});
            return;
        }

        // Update target
        let newWeightings = weightings;
        newWeightings[targetKey].weighting += difference;
        newWeightings[targetKey].changed = new Date();

        // Update others
        // Loop thourgh until the difference is 0
        console.log('Difference:', difference);
        // need to see if positive or negative difference
        let changeUnit = difference > 0 ? 1 : -1;
        while (difference !== 0) {

        console.log("Cycled", difference);
            Object.keys(weightings).filter(key => key !== targetKey).map((key, index) => {
                if (!weightings[key].locked) {
                    if (difference !== 0) {

                        newWeightings[key].weighting -= changeUnit;
                        newWeightings[key].changed = new Date();
                        if (newWeightings[key].weighting < 0) {
                            newWeightings[key].weighting = 0;
                        } else {
                            console.log("Changed", key, difference, newWeightings[key].weighting);
                            difference -= changeUnit;
                        }

                    }                    
                }
            });
        }

        setWeightings({...newWeightings});



    }

    return (

        <Box>

            {Object.keys(weightings).map((key, index) => {
                
                let faintColor = 'gray.100';
                let color = 'blue.500';

                // Check if locked
                if (weightings[key].locked) {
                    faintColor = 'green.100';
                    color = 'green.500';
                } else if (weightings[key].weighting < 5) {
                    faintColor = 'red.100';
                    color = 'red.500';
                } else if (weightings[key].weighting > 90) {
                    faintColor = 'red.100';
                    color = 'red.500';
                } else if (weightings[key].weighting === 0) {
                    faintColor = 'red.100';
                    color = 'red.500';
                } else if (weightings[key].weighting < 10) {
                    faintColor = 'yellow.100';
                    color = 'yellow.500';
                }
            
                return (
                    <Box key={index} pb={8} w='100%'>

                        <HStack>
                            <CircularProgress value={weightings[key].weighting}  size='64px' 
                                thickness='5px'
                                mr={4}
                                transition='all 0.5s ease-in-out'
                                bg={weightings[key].locked ? 'green.100' : 'none'}
                                borderRadius='full'
                                color={color}
                            >
                                <CircularProgressLabel

                                    color={color}
                                    >{weightings[key].weighting}</CircularProgressLabel>
                            </CircularProgress>
                            <Stack spacing={0} position='relative' w='fill-available'>
                                <Text color='black' fontWeight='600'>{key}</Text>
                                <Text pb={0}>{weightings[key].description}</Text>
                                
                                <Box pr={8}>
                                    <Slider aria-label="slider-ex-1"  size="sm"
                                    disabled={weightings[key].locked}
                                    onChangeEnd={(value) => {
                                        updateWeights(key, value);
                                    }}
                                    pr={8}
                                    defaultValue={weightings[key].weighting}
                                    key={JSON.stringify(weightings[key])}
                                    opacity={weightings[key].locked ? 0.1 : 1}
                                    colorScheme={faintColor}
                                    >
                                    <SliderTrack
                                        >
                                        <SliderFilledTrack
                                            bg={color}
                                            transition='all 0.5s linear'
                                        />
                                    </SliderTrack>
                                    <SliderThumb 
                                        bg={color}
                                        transition='all 0.5s linear'
                                        _focus={{
                                            boxShadow: 'none'
                                        }}
                                    >
                                    </SliderThumb>
                                </Slider>
                                </Box>
                                    <IconButton icon={<MdLockOutline />} 
                                    position='absolute'
                                    right='0'
                                    bottom='0'
                                    colorScheme='blue'
                                    transition='all 0.5s ease-in-out'
                                    variant={weightings[key].locked ? 'outline' : 'ghost'}
                                    size='xs' onClick={() => {
                                        let newWeightings = weightings;
                                        newWeightings[key].locked = !newWeightings[key].locked;
                                        setWeightings({...newWeightings});
                                    }} />
                            </Stack>
                        </HStack>


                    </Box>
                )
            
            })}


        </Box>

    )

}