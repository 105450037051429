"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var theme_tools_1 = require("@chakra-ui/theme-tools");
var utils_1 = require("@chakra-ui/utils");
var _1 = require(".");
var getOutlineStyle = function (props) {
    var theme = props.theme;
    var inputOutlineStyle = _1.Input.variants.outline.field;
    return (0, utils_1.mergeWith)({ field: inputOutlineStyle }, {
        field: {
            _active: __assign(__assign({}, inputOutlineStyle._focus), { background: (0, theme_tools_1.mode)((0, theme_tools_1.transparentize)('gray.500', 0.3)(theme), (0, theme_tools_1.transparentize)('gray.300', 0.3)(theme))(props) }),
            _disabled: {
                _hover: {},
                _active: {
                    borderColor: 'input-border-disabled',
                    bg: 'unset',
                },
            },
        },
        icon: {
            color: 'input-placeholder',
            '>svg': {
                fontSize: '1.5rem',
            },
        },
    });
};
var variants = {
    outline: getOutlineStyle,
};
exports.default = {
    variants: variants,
};
