"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = function () { return ({
    container: {
        color: 'default',
        mr: 2,
    },
    track: {
        bg: 'accent-subtle',
        p: 1,
        _checked: {
            bg: 'accent',
            _disabled: {
                // * prop thumb.disabled not forwared
                '>span': {
                    bg: 'bg-subtle',
                },
            },
        },
        _focus: {
            boxShadow: 'none',
        },
        _focusVisible: {
            boxShadow: 'none',
        },
        _disabled: {
            // * prop thumb.disabled not forwared
            '>span': {
                bg: 'disabled',
            },
            bg: 'accent-subtle',
            _checked: {
                bg: 'accent-disabled',
            },
        },
    },
    thumb: {
        bg: 'accent',
        _checked: {
            bg: 'bg-surface',
        },
    },
}); };
var sizes = {
    sm: {
        track: { w: 9, h: 4 },
        thumb: {
            w: 4,
            h: 4,
            _checked: {
                transform: 'translateX(1.25rem)',
            },
        },
    },
};
exports.default = {
    baseStyle: baseStyle,
    sizes: sizes,
    defaultProps: {
        size: 'sm',
    },
};
