import {   useColorModeValue as mode,
  Box, Tag, List, HStack, Circle, Icon, Button, Flex, Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'

export default function Costings(props) {

    let HRFeatures = [
      'Unlimited record requests',
      'Automated reminders',
      'Personalised AI generated reminders',
      'Advanced reporting tools',
      'Rapid onboarding'
    ]

    let StaffFeatures = [
      'Unlimited page analysis',
      'Unlimited requests',
      'Artificial intelligence text detection', 
      'Natural language processing to extract key information', 
      'Automatic tagging and sorting', 
      'Unlimited document sharing',
    ]

    return (
        <Box as="section">
        <Container
          py={{
            base: '16',
            md: '24',
          }}
        >
          <Flex
            spacing={{
              base: '12',
              md: '0',
            }}
            direction={{
              base: 'column',
              lg: 'row',
            }}
          >
            
            <Box
              flex="0"
            >
              <Heading size='pagetitle' mr='120px'
                >
                Pricing
              </Heading>
              <Heading size='smalltitle' fontSize='64px' mt={4} color='#B400FF'>
                Organisations
              </Heading>
              <Text mt="7" fontSize="20px" color='primary' mb={12} >
                Rapidly onboard your new staff
              </Text>

              </Box>

       
            <SimpleGrid
              columns={{
                base: 1
              }}
              pt={{
                base: '0px',
                md: '78px',
              }}
              columnGap="8"
              rowGap="6"
              flex="1"
            >

                <Box
                  bg="bg-surface"
                  borderRadius="2xl"
                  boxShadow="lg"
                  px={{
                    base: '6',
                    md: '8',
                  }}
                  py="8"
                  position="relative"
                  overflow="hidden"
                  borderColor='blue.500'
                  borderWidth='2px'
                  border='solid black 2px'
                >
                  <Stack spacing="8" textAlign="center">
                    <Stack spacing="5" align="center">
                      <Stack spacing="4">
                        <Heading
                          size={{
                            base: 'md',
                            md: 'lg',
                          }}
                        >
                          Enterprise
                        </Heading>
                        <Stack spacing="1" alignItems='center'>
                          <Text fontSize="xl" fontWeight="semibold">
                          £8<Text as='span' fontSize='18px' mb='-10px'>/user</Text> <Text as="span" fontSize="sm" color="muted"> pa</Text>
                          </Text>
                          <Text color="muted" pb={4}>For organisations</Text>

                          <Tag mt={4} colorScheme='blue'>No minimum users or term</Tag>
                          
                        </Stack>
                      </Stack>
                    </Stack>


            <SimpleGrid
              columns={{
                base: 1,
                md: 2,
              }}
              pt={{
                base: '0px',
                md: '52px',
              }}
              columnGap="8"
              rowGap="6"
              flex="1"
            >
                <Box>
                  <Heading fontSize="xl" fontWeight="semibold" mb={8} size='label'>HR Benefits</Heading>
                    <List as="ul" spacing="4">
                      {HRFeatures.map((feature) => {

                        return (

                          <HStack key='feature' as="li" spacing="3">
                            <Circle size="6" bg={mode('whitesmoke', 'whiteAlpha.50')}>
                              <Icon as={FiCheck} color="black" />
                            </Circle>
                            <Text color="muted" align='left'>{feature}</Text>
                          </HStack>

                        )
                      })}

                    </List>
                    </Box>

                      <Box>
                        <Heading fontSize="xl" fontWeight="semibold" mb={8} size='label'>Staff Benefits</Heading>
                    <List as="ul" spacing="4">
                      {StaffFeatures.map((feature) => {

                        return (

                          <HStack key='feature' as="li" spacing="3">
                            <Circle size="6" bg={mode('whitesmoke', 'whiteAlpha.50')}>
                              <Icon as={FiCheck} color="black" />
                            </Circle>
                            <Text color="muted" align='left'>{feature}</Text>
                          </HStack>

                        )
                      })}

                    </List>
                      </Box>
                    </SimpleGrid>
            <Text fontSize='xs' color='subtle' pt={8}>
              Fair usage terms and conditions apply.
            </Text>

                    <Button variant="primary" size="lg">
                      Get started
                    </Button>
                  </Stack>
                </Box>
            </SimpleGrid>

          </Flex>
        </Container>
      </Box>
    
    
    )
}