"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = {
    icon: {
        color: 'on-accent',
    },
    control: {
        bg: 'unset',
        borderColor: 'bg-muted',
        '>div>svg': {
            color: 'invert',
            _hover: {
                color: 'ui',
            },
        },
        _hover: { // This is for hovering the checkbox itself, not the label
            borderColor: 'ui',
            '>svg': {
                color: 'ui',
            },
        },
        _active: {
            bg: 'bg-muted',
        },
        _checked: {
            bg: 'ui',
            color: 'text',
            borderColor: 'ui',
            background: 'ui',
            '>div>svg': {
                color: 'invert',
            },
            _hover: {
                color: 'accent-emphasis',
                borderColor: 'ui',
                '>div>svg': {
                    color: 'invert',
                },
            },
            _active: {
                bg: 'bg-muted',
            },
            _disabled: {
                bg: 'unset',
                borderColor: 'disabled',
                _hover: {
                    borderColor: 'disabled',
                },
                _active: {
                    bg: 'unset',
                },
            },
        },
        _indeterminate: {
            borderColor: 'bg-muted',
            _hover: {
                borderColor: 'bg-muted',
            },
            bg: 'unset',
            '>div>svg': {
                color: 'ui',
                _hover: {
                    color: 'ui',
                },
            },
            _disabled: {
                '>div>svg': {
                    color: 'disabled',
                },
            },
        },
        _focus: {
            boxShadow: 'none',
        },
        _disabled: {
            bg: 'unset',
            borderColor: 'disabled',
            _hover: {
                borderColor: 'disabled',
            },
            _checked: {
                '>div>svg': {
                    color: 'disabled',
                },
            },
            '>div>svg': {
                color: 'disabled',
            },
        },
    },
    label: {
        color: 'text',
        _disabled: {
            opacity: 'unset',
            color: 'disabled',
        },
    },
};
var sizes = {
    md: {
        control: {
            width: 5,
            height: 5,
            _checked: {
                _before: {
                    w: 2.5,
                    h: 2.5,
                },
            },
        },
        label: { fontSize: 'sm' },
    },
    lg: {
        control: {
            width: 6,
            height: 6,
            _checked: {
                _before: {
                    w: 3.5,
                    h: 3.5,
                },
            },
        },
        Label: { fontSize: 'md' },
    },
};
exports.default = {
    baseStyle: baseStyle,
    sizes: sizes,
};
