import React, {useEffect, useState} from 'react';
import { Tooltip, Badge,Tag, WrapItem, Wrap, HStack, Flex, GridItem, Box, Text, Heading, Button, Stack, Center, Image, useColorModeValue, Table, Th, Thead, Tr, Td, Tbody, ButtonGroup, TableCaption, textDecoration } from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import Check from './Check';
import WorkSchedule from './WorkSchedule';

export default function Overview(props) {

    let [display, setDisplay] = useState('shorthand');
    let [rotaDisplay, setRotaDisplay] = useState(['original', 'withAL', 'withSL']);

    let hourlyRate = 58398/40/52; // ST8
    let enhancedRate = hourlyRate * 0.37;

    let underpaymentHours = 0;
    let underpaymentAmount = 0;
    let underpaymentEnhancedHours = 0;
    let underpaymentEnhancedAmount = 0;

    let offColour = '#ECFFDC';
    let onColour = '#C5D5E4';
    let nightColour = '#DB5375';
    let longColour = '#F17F29';

    let shifts = {
        'LD': {
            shorthand: 'LD',
            name: 'Long Day',
            colour: longColour,
            hours: 13,
            enhancedhours: 0.5,
            start: '08:30',
            end: '21:30',
        },
        'SD': {
            shorthand: 'SD',
            name: 'Standard Day',
            colour: onColour,
            hours: 9,
            enhancedhours: 0,
            start: '08:30',
            end: '17:30',
        },
        'N': {
            shorthand: 'N',
            name: 'Night',
            colour: nightColour,
            hours: 13,
            enhancedhours: 13,
            start: '20:30',
            end: '09:30',
        },
        'C': {
            shorthand: 'C',
            name: 'Clinic',
            colour: '#EDD892',
            hours: 8,
            enhancedhours: 0,
            start: '09:00',
            end: '17:00',
        },
        'HALF': {
            shorthand: 'HALF',
            name: 'Half Day',
            colour: '#FCB97D',
            hours: 4.5,
            enhancedhours: 0,
            start: '08:30',
            end: '13:00',
        },
        'AL': { 
            shorthand: 'AL',
            name: 'Annual Leave',
            colour: offColour,
            hours: 0,
            enhancedhours: 0,
            start: '-',
            end: '-',
        },
        'SL': {
            shorthand: 'SL',
            name: 'Study Leave',
            colour: offColour,
            hours: 0,
            enhancedhours: 0,
            start: '-',
            end: '-',
        },
        'OFF': {
            shorthand: 'OFF',
            name: 'Off',
            colour: offColour,
            hours: 0,
            enhancedhours: 0,
            start: '-',
            end: '-',
        },
    };
        
    let rotas = {
        original:[
            ['LD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'LD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'LD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'LD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'SD', 'LD', 'LD', 'LD'],
            ['OFF', 'OFF', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['N', 'N', 'N', 'N', 'OFF', 'OFF', 'OFF'],
            ['OFF', 'OFF', 'OFF', 'OFF', 'N', 'N', 'N'],
        ],
        withAL: [
            ['LD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'LD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'LD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'LD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'SD', 'LD', 'LD', 'LD'],
            ['OFF', 'OFF', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['AL', 'AL', 'AL', 'AL', 'AL', 'OFF', 'OFF'],
            ['N', 'N', 'N', 'N', 'OFF', 'OFF', 'OFF'],
            ['OFF', 'OFF', 'OFF', 'OFF', 'N', 'N', 'N'],
        ],
        withSL: [
            ['LD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'LD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'LD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'LD', 'SD', 'OFF', 'OFF'],
            ['SD', 'SD', 'SD', 'SD', 'LD', 'LD', 'LD'],
            ['OFF', 'OFF', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
            ['SL', 'SL', 'SL', 'SL', 'SL', 'OFF', 'OFF'],
            ['AL', 'AL', 'AL', 'AL', 'AL', 'OFF', 'OFF'],
            ['N', 'N', 'N', 'N', 'OFF', 'OFF', 'OFF'],
            ['OFF', 'OFF', 'OFF', 'OFF', 'N', 'N', 'N'],
        ]
    }


    let paeds = [
        ['N', 'N', 'N', 'N', 'OFF', 'OFF', 'OFF'],
        ['LD', 'C', 'C', 'C', 'SD', 'OFF', 'OFF'],
        ['SD', 'SD', 'SD', 'OFF', 'LD', 'LD', 'LD'],
        ['HALF', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
        ['SD', 'LD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
        ['SD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
        ['N', 'N', 'N', 'N', 'OFF', 'OFF', 'OFF'],
        ['OFF', 'SD', 'OFF', 'SD', 'N', 'N', 'N'],
        ['OFF', 'OFF', 'OFF', 'OFF', 'OFF', 'OFF', 'OFF'],
        ['OFF', 'SD', 'SD', 'SD', 'OFF', 'OFF', 'OFF'],
        ['LD', 'SD', 'SD', 'LD', 'SD', 'OFF', 'OFF'],
        ['LD', 'C', 'SD', 'LD', 'SD', 'OFF', 'OFF'],
        ['SD', 'SD', 'SD', 'OFF', 'LD', 'LD', 'LD'],
        ['OFF', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
        ['SD', 'LD', 'LD', 'SD', 'SD', 'OFF', 'OFF'],
        ['SD', 'SD', 'SD', 'LD', 'SD', 'OFF', 'OFF'],
        ['N', 'N', 'N', 'N', 'OFF', 'OFF', 'OFF'],
        ['SD', 'SD', 'OFF', 'SD', 'N', 'N', 'N'],
        ['OFF', 'OFF', 'OFF', 'OFF', 'OFF', 'OFF', 'OFF'],
        ['SD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
        ['SD', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
        ['SD', 'SD', 'SD', 'LD', 'SD', 'OFF', 'OFF'],
        ['SD', 'SD', 'OFF', 'SD', 'LD', 'LD', 'LD'],
        ['HALF', 'SD', 'SD', 'SD', 'SD', 'OFF', 'OFF'],
        ['N', 'N', 'OFF', 'OFF', 'SD', 'OFF', 'OFF'],
        ['SD', 'LD', 'LD', 'SD', 'SD', 'OFF', 'OFF'],
    ]


    
    let totalHours = {
        'original': {
            'index': 1,
            'title': 'Original Rota',
            'description': 'The original rota with no leave accounted for, this is typically what you are sent and then leave is accomodated for later.',
            'totalhours': 0,
            'enhancedhours': 0,
            'weeks': 0,
            'average': 0,
            'averageEnhanced': 0,
            'show': true
        },
        'withAL': {
            'index': 2,
            'title': '...with Annual Leave',
            'description': 'The original rota with annual leave, commonly but incorrectly used to pay trainees.',
            'totalhours': 0,
            'enhancedhours': 0,
            'weeks': 0,
            'average': 0,
            'averageEnhanced': 0,
            'show': true
        },
        'withSL': {
            'index': 3,
            'title': '...with Annual and Study Leave',
            'description': 'The original rota with annual and study leave, this is what should have been used to pay trainees.',
            'totalhours': 0,
            'enhancedhours': 0,
            'weeks': 0,
            'average': 0,
            'averageEnhanced': 0,
            'show': true
        }
    }

    // Loop through each week
    for (let i = 0; i < rotas.original.length; i++) {

        let weekHours = {};
        let enhancedhours = {};

        let rotaKeys = Object.keys(rotas);
        // Loop through each rota
        for (let j = 0; j < rotaKeys.length; j++) {
            weekHours[rotaKeys[j]] = 0;
            enhancedhours[rotaKeys[j]] = 0;
        }

        // Loop through each day
        for (let j = 0; j < rotas.original[i].length; j++) {
            
            // Get hours for each shift and add them to weekHours for each rota
            for (let k = 0; k < rotaKeys.length; k++) {
                weekHours[rotaKeys[k]] += shifts[rotas[rotaKeys[k]][i][j]]['hours'];
                enhancedhours[rotaKeys[k]] += shifts[rotas[rotaKeys[k]][i][j]]['enhancedhours'];
            }

        }

        // Loop through each rota
        for (let j = 0; j < rotaKeys.length; j++) {

            totalHours[rotaKeys[j]]['totalhours'] += weekHours[rotaKeys[j]];
            totalHours[rotaKeys[j]]['enhancedhours'] += enhancedhours[rotaKeys[j]];
            if (weekHours[rotaKeys[j]] > 0) {
                totalHours[rotaKeys[j]]['weeks'] += 1;
            }
        }
        

    }

    // Loop through each rota
    let rotaKeys = Object.keys(rotas);
    for (let i = 0; i < rotaKeys.length; i++) {
        totalHours[rotaKeys[i]]['average'] = Math.round(10 * totalHours[rotaKeys[i]]['totalhours'] / totalHours[rotaKeys[i]]['weeks'])/10;
        totalHours[rotaKeys[i]]['averageEnhanced'] = Math.round(10 * totalHours[rotaKeys[i]]['enhancedhours'] / totalHours[rotaKeys[i]]['weeks'])/10;
    }

    // Calculate the difference between the AL and SL rotas
    underpaymentHours = totalHours['withSL']['average'] - totalHours['withAL']['average'];
    underpaymentHours = Math.round(100 * underpaymentHours) / 100;

    underpaymentEnhancedHours = totalHours['withSL']['averageEnhanced'] - totalHours['withAL']['averageEnhanced'];
    underpaymentEnhancedHours = Math.round(100 * underpaymentEnhancedHours) / 100;

    underpaymentAmount = underpaymentHours * hourlyRate * 52;
    underpaymentAmount = Math.round(underpaymentAmount); 

    underpaymentEnhancedAmount = underpaymentEnhancedHours * enhancedRate * 52;
    underpaymentEnhancedAmount = Math.round(underpaymentEnhancedAmount);



    function toggleDisplay(rota) {

        let newDisplay = rotaDisplay;

        totalHours[rota]['show'] = !totalHours[rota]['show'];

    }


    return (
        <Layout
            title='Prospective Leave'
            description={
                <>
                <Text pb={2}>It was recently highlighted that a large number of medical trainees have been underpaid since 2020, you may be owed a large amount of money from previous or current jobs.
                    The calculations can be complex but if you upload work schedules we will automatically analyse them for you using our AI tools.</Text>
                </>
            }
            >

        <GridItem colSpan={12}>

            <Stack spacing={6}>



            <Stack spacing={6}>
                <Heading size="smalltitle" >
                    What is the problem?
                </Heading>

                <Text fontSize="lg" color={'black'}>
                    A large number of NHS Trusts have inappropriately calculated your average hours per week, resulting in an underpayment. 
                    This happened because they inappropriately assumed in their calcualtions that study leave could be taken on any shift but in most cases you can only take leave on the shorter 'normal day' shifts. 
                </Text>

                </Stack>

                <Stack spacing={6}>


                <Heading size="smalltitle"  pt={8}>
                    Was I affected?
                </Heading>
                <Text fontSize="lg" color={'black'}>
                    There is a reasonable chance you were effected depending on the questions below, answer them for each job you have worked from 2020 onwards. You can read the policy document <Text 
                    onClick={() => {
                        window.open('https://www.nhsemployers.org/system/files/2022-08/Presepective-cover-guidance-for-study-leave.pdf', "_blank");}}
                    color='blue.500'
                    _hover={{textDecoration:'underline'}}
                    cursor='pointer'
                    as='span'>
                    here</Text>.
                </Text>

                <Box>
                    <Check/>
                </Box>

                </Stack>

                <Stack spacing={6}>

                <Heading size="smalltitle"  pt={8}>
                    A quick example
                </Heading>
                <Text>
                    In the example below we have used a classic 10-week rota pattern.
                    The tables show the rota pattern with the shifts and total hours for that week, and below that the average hours per week for each rota. <b>Work Schedule 1</b> is the original rota, <b>Work Schedule 2</b> accomodates the doctor's annual leave (often mistakenly used by Trusts) and <b>Work Schedule 3</b> accomodates the doctor's annual and study leave (the correct schedule).
                    You need to discount any weeks where you are on leave when calculating the average hours per week.
                </Text>
                <Text>
                    In this example many trusts would have under estimated your weekly hours by {underpaymentHours} hours per week, and your enhanced hours by {underpaymentEnhancedHours} hours per week.
                    The total underpayment for a ST8 trainee working this rota is £{Math.round(underpaymentAmount + underpaymentEnhancedAmount)} per year, or over £{Math.round(3* (underpaymentAmount + underpaymentEnhancedAmount))} since 2020.
                </Text>


                <ButtonGroup size="md" display='none' isAttached variant="outline">
                    {Object.keys(rotas).map((rota) => {
                        return (
                            <Button colorScheme="blue" onClick={() => {toggleDisplay(rota);}}>{rota}</Button>
                        )
                    })}
                </ButtonGroup>

            <Flex pt={8} pl={8} pr={8} justifyContent='space-between' alignItems='center' flexWrap='wrap'>
                {rotaDisplay.map((rota) => {

                    if (!totalHours[rota]['show']) {
                        return null;
                    }


                    return (

                        <Stack maxW='300px' p={4}>
                            <Tag size='sm' w='max-content' mb={2} colorScheme='blue'>
                                Work Schedule {totalHours[rota].index}
                            </Tag>
                            <Heading size="label" fontWeight="bold">
                                {totalHours[rota]['title']}
                            </Heading>
                            <Heading size="label" fontSize='2xl' fontWeight="bold">
                                {totalHours[rota]['average']} <Text as='span' fontSize='md'>hrs/week</Text>
                            </Heading>
                            <Heading size="label" fontSize='2xl' fontWeight="bold">
                                {totalHours[rota]['averageEnhanced']} <Text as='span' fontSize='md'> enhanced hrs/week</Text>
                            </Heading>
                            <Text minH='55px' fontSize='small' color='subtle'>
                                {totalHours[rota]['description']}
                            </Text>
                            
                        <Table className='mini-table'>
                            <Thead>
                            <Tr>
                                <Th>Week</Th>
                                <Th>M</Th>
                                <Th>T</Th>
                                <Th>W</Th>
                                <Th>T</Th>
                                <Th>F</Th>
                                <Th>S</Th>
                                <Th>S</Th>
                                <Th>Hours</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            {rotas[rota].map((week, i) => {
                                let totalHours = 0;
                                week.forEach((day) => {
                                    totalHours += shifts[day].hours;
                                });
                                return(
                            
                                <Tr>

                                    <Td>{i+1}</Td>
                                    {week.map((day) => {
                                        
                                        let shift = shifts[day];
                                        let message = shift.name;
                                        if (shift.hours === 0) {
                                            message += ' - no hours';
                                        } else {
                                            message += ' - ' + shift.hours + ' hours';
                                            if (shift.enhancedhours !== 0) {
                                                message += ' (' + shift.enhancedhours + ' enhanced)';
                                            }
                                        }
                                        return (
                                            <Tooltip label={message} aria-label="A tooltip">
                                                <Td _hover={{fontWeight: '900'}} cursor='pointer' bg={shifts[day].colour}>{shifts[day][display]}</Td>
                                            </Tooltip>
                                        )
                                    })}
                                    <Td>{totalHours}</Td>
                                </Tr>
                            )})}

                            <Tr>
                                <Td colSpan='8' textAlign='right !important'>Total Hours</Td>
                                <Td>{totalHours[rota].totalhours}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan='8' textAlign='right !important'>Total Enhanced Hours</Td>
                                <Td>{totalHours[rota].enhancedhours}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan='8' textAlign='right !important'>Number of weeks</Td>
                                <Td>{totalHours[rota].weeks}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan='8' textAlign='right !important'>Average Weekly Hours</Td>
                                <Td>{totalHours[rota].average}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan='8' textAlign='right !important'>Average Weekly Enhanced Hours</Td>
                                <Td>{totalHours[rota].averageEnhanced}</Td>
                            </Tr>
                            </Tbody>
                        </Table>
                        </Stack>

                    )

                })}
                </Flex>

                <Stack alignItems='center' w='100%'>
                    

                                <Box borderRadius='16px' h='34px' fontSize='12px' border='1px solid' borderColor='#d3d3d3' pr={3}>
                                    <HStack>
                                    <Box w='34px' h='32px' bg='#d3d3d3' borderRadius='50%' >
                                        <Text pt='8px' w='32px' textAlign='center'>
                                        Key
                                        </Text>
                                    </Box>
                                    <b>Shift Title</b>
                                    <Text>Total Hours (Enhanced Hours)</Text>
                                    </HStack>
                                </Box>
                <Flex textAlign='center' flexWrap='wrap' spacing={4} pt={2} maxW='600px' alignItems='center' justifyContent='center' textAlign='center'>  


                    {Object.keys(shifts).map((shiftKey) => {

                        let shift = shifts[shiftKey];
                        let message = shift.name + ' - ' + shift.hours + ' hours (' + shift.enhancedhours + ' enhanced hours)';

                        return (

                                <Box borderRadius='16px' h='34px' fontSize='12px' border='1px solid' mr={4} mb={4} borderColor='#d3d3d3' pr={3}>
                                    <Tooltip label={message} aria-label={message}>
                                        <HStack>
                                            <Box w='34px' h='32px' bg={shift.colour} borderRadius='50%' >
                                                <Text pt='8px' w='32px' textAlign='center'>
                                                    {shiftKey}
                                                </Text>
                                            </Box>
                                            <b>{shift.name}</b>
                                            <Text>{shift.hours} hrs ({shift.enhancedhours})</Text>
                                        </HStack>
                                    </Tooltip>
                                </Box>
                        )

                    })}

                </Flex>
                </Stack>



                </Stack>
                
            </Stack>


        </GridItem>


        </Layout>
    )
}