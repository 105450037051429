import React from 'react';

import {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import {Image, Alert, ListIcon, Stack, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, List, ListItem, HStack, Text, Link, Flex, Tag, TagLabel, TagRightIcon, Box,Heading, Container, Input, Button, ButtonGroup } from '@chakra-ui/react';
import Modal from '../../core/components/modal/Modal';
import MFAWrapper from '../../auth/cognito/components/MFAWrapper';
import {Dropzone} from '../../core/components/forms/Dropzone';
import { Link as ReachLink } from 'react-router-dom';

import BasicBanner from '../../core/components/banners/BasicBanner';

import { useNavigate } from "react-router-dom";

import Pluralize from 'pluralize';
import { useAuth0 } from "@auth0/auth0-react";
import API from '../../core/utils/API';

import Resizer from "react-image-file-resizer";
import jsPDF from 'jspdf';
import { Buffer } from "buffer";
import { MdCheck, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";


export default function UploadSchedule(props) {

    const [confirmed, setConfirmed] = useState(false);


    const [formMessage, setFormMessage] = useState("");
    const [formError, setFormError] = useState("");
    const {
      register,
      handleSubmit,
      reset,
      formState,
      formState: { errors },
    } = useForm();
    const onError = (errors, e) => console.log(errors, e);
    const [loading, setLoading] = useState(false)
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [show, setShow] = useState(false);

    const [file, setFile] = useState(null);

    const navigate = useNavigate();

    function uploadFile(e) {
        console.log(e.target.files, e.target.files.length);

        
        let data = new FormData();
        Object.values(e.target.files).forEach((file) => {
            data.append('file', e.target.files[file]);
        });

        // Loop through each file 
        for (let i = 0; i < e.target.files.length; i++) {
            // Add the file to the request.
            data.append('file', e.target.files[i]);
        }
        
        data.append('upload_preset', 'ml_default');

        let response = API.postItems({url: 'schedules/upload', data}).then(function (response) {

            // check if multiple files
            if (e.target.files.length > 1) {
                navigate('/pay/schedules');
            } else {
                props.setUUID(response.data.uuid);
                props.progressStage();
            }


        }).catch(function (error) {
            console.log(error)
        });
    }


    return (

        <Box>

            {!confirmed ?
                <>
                    <Heading as='h4' fontSize='xl'>Is your work schedule ready?</Heading>

                    <Text color='subtle' fontSize='sm' mb={4}>
                        You will have been sent many documents from your employer, but we only need your work schedule. This is usually a PDF that looks similar to the images below.
                    </Text>
                    
                    <HStack spacing={4} mb={4} direction={{base: 'column', md: 'row'}}>
                        <Image boxShadow='md' src='/images/schedules/TitlePage.png' w='160px' h={{base: 'auto', md: '100%'}} />
                        <Image boxShadow='md' src='/images/schedules/RotaPage.png' w='160px' h={{base: 'auto', md: '100%'}} />
                    </HStack>

                    <ButtonGroup mb={4} spacing={4}>
                        <Button colorScheme='blue' onClick={() => {setConfirmed(true)}}>Yes, my file looks like that</Button>
                        <Button
                        variant='outline' onClick={() => {
                            setConfirmed(false)
                            navigate('/pay/schedules');
                        }}>No</Button>
                    </ButtonGroup>
                    <Text color='subtle' fontSize='sm'>
                        Non-Resident On Call (NROC) not supported, feel free to upload your schedule and our team can use it to test our analysis for NROC.
                    </Text>
                </>

            :
                <>
                    <Heading as='h4' fontSize='xl'>Upload your work schedule</Heading>
                    <Dropzone cursor='pointer' 
                        _hover={{border: '1px solid #4DA9F2'}} mb={4} 
                        onSubmit={(e) => e.preventDefault()} filesize='2mb' filetypes='Any images or PDFs'>
                        <Input
                            type="file"
                            name="evidence"
                            /*multiple*/
                            onDrop={function() {console.log("dropped")}}
                            onChange={uploadFile}
                            h="100%"
                            w="100%"
                            position="absolute"
                            top="0"
                            left="0" 
                            opacity="0"
                            accept="image/*,application/PDF"
                            cursor='pointer'
                            /> 
                    </Dropzone>

                    <Text color='subtle' fontSize='sm'>
                        Non-Resident On Call (NROC) not supported, feel free to upload your schedule and our team can use it to test our analysis for NROC.
                    </Text>
                </>
            }

        </Box>


    )
}