import {
    Button,
    ButtonGroup,
    Container,
    Divider,
    IconButton,
    Box,
    HStack,
    Input,
    Stack,
    Image,
    Text,
  } from '@chakra-ui/react';
  import * as React from 'react';
  import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
  import {Link as ReachLink} from 'react-router-dom';
  import Logo from './logo.svg';
import { FiPlus } from 'react-icons/fi';
import PyrexiaDivider from './PyrexiaDivider';

  export default function Footer() {
    return (

      <>

      <PyrexiaDivider/>

    <Container as="footer" role="contentinfo" mt={24}>
      <Stack
        spacing="8"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        py={{ base: '12', md: '16' }}
      >
        <Stack spacing={{ base: '2', md: '4' }} align="start">
          <Image src='/logo.png' maxH={{base: '30px', md: '50px'}}/>
          <Text color="muted">Automated HR tools for Healthcare</Text>
        </Stack>
        <Stack
          direction={{ base: 'column-reverse', md: 'column', lg: 'row' }}
          spacing={{ base: '12', md: '8' }}
        >
          <Stack direction="row" spacing="8">

            <Stack spacing="4" minW="36" flex="1">
              <Text fontSize="sm" fontWeight="semibold" color="subtle">
                
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                <Button variant="link" display='none'>Privacy</Button>
                <Button variant="link" display='none'>Terms</Button>
              </Stack>
            </Stack>
            <Stack spacing="4" minW="36" flex="1">
              <Text fontSize="sm" fontWeight="semibold" color="subtle">
                Product
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                <Button variant='link' as={ReachLink} to='/about'>About</Button>
                <Button variant='link' as={ReachLink} to='/features'>Features</Button>
                <Button variant='link' as={ReachLink} to='/workflowpro'>For Professionals</Button>
                <Button variant='link' as={ReachLink} to='/workflow'>For Organisations</Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing="4" display='none'>
            <Text fontSize="sm" fontWeight="semibold" color="subtle">
              Stay up to date
            </Text>
            <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} maxW={{ lg: '360px' }}>
              <Input placeholder="Enter your email" type="email" required />
              <Button variant="primary" type="submit" flexShrink={0}>
                Subscribe
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        pt="8"
        pb="12"
        justify="space-between"
        direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
      >
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} Pyrexia Ltd. All rights reserved.
        </Text>
        <ButtonGroup variant="ghost" display='none'>
          <IconButton
            as="a"
            href="#"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
          <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
        </ButtonGroup>
      </Stack>
    </Container>
    </>
  )
    }