import React from 'react';
import Layout from '../../core/layout/Layout';
import ShowModel from '../../core/models/ShowModel';
import AutoForm from '../../core/models/AutoForm'; 
import API from '../../core/utils/API';

import { useState, useEffect } from 'react';

import { Center, Spacer, Icon, IconButton, Circle, Box, Heading, Text, Flex, Button, Stack, Avatar, AvatarGroup, Divider, HStack, VStack, Wrap, WrapItem, Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton, useColorModeValue } from '@chakra-ui/react';
import { MdWarningAmber, MdInbox, MdEdit, MdOutlineExpandMore, MdCheck, MdClose, MdLabelOutline } from 'react-icons/md';
import Modal from '../../core/components/modal/Modal';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import Conversation from './Conversation';

export default function Inbox (props) {


    const navigate = useNavigate();

    let [messages, setMessages] = useState([]);
    let [users, setUsers] = useState([]);
    let [loading, setLoading] = useState(true);
    let [error, setError] = useState(null);
    let [show, setShow] = useState(false);
    let [lastChecked, setLastChecked] = useState(null);

    const getMessages = async () => {

        setLoading(true);
        setError(null);

        let url = 'messages/';
        let response = await API.getItems({url})
        .then((response) => {

            let data = response.data;

            console.log("DATA = ", data);
            //setMessages(data['messages']);

            let tmpUsers = [];

            data['users'].map((user) => {

                let tmpUser = user;
                user.Messages = [];

                // Move 
                if (user.senderMessage) {
                    console.log("SENDER MESSAGE = ", user.senderMessage);
                    user.senderMessage.map((message) => {
                        tmpUser.Messages.push(message);
                    });
                    tmpUser.Messages.concat(user.senderMessage);
                } else if (user.recipientMessage) {
                    console.log("RECIPIENT MESSAGE = ", user.recipientMessage);
                    tmpUser.Messages.concat(user.recipientMessage);
                    user.recipientMessage.map((message) => {
                        tmpUser.Messages.push(message);
                    });
                }
                
                // Sort messages
                tmpUser.Messages.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });

                tmpUsers.push(tmpUser);

            });


//            setUsers(data['users']);
            setUsers(tmpUsers);

            setLastChecked(moment().format('YYYY-MM-DD HH:mm:ss'));
    
            setLoading(false);
        })
        .catch((error) => {
            setError(error);
            setLoading(false);
            
        });

    }

    useEffect(() => {
        getMessages();
    }, []);

    function onSubmitNewMessage() {
        console.log("SUBMIT NEW MESSAGE");
        setShow(false);
        getMessages();
    }




    return (
        

        <Box mt='70px'>
 
            <HStack 
                zIndex='3' h='70px' position='fixed' top='0' right='0' w='400px' 
                bg='blue.500' color='white' pl={2} pr={4}>


                <Circle border='0px solid white' mr={2} cursor='pointer' size='48px' bg='white'>
                    <Icon as={MdInbox} color='blue.500' boxSize='28px' />
                </Circle>
                <Stack spacing={0}>
                    <Text color='white' fontSize='md' fontWeight='bold'>Inbox</Text>
                    <Text color='white' fontSize='xs'>Last <Text as='span' onClick={() => getMessages()} textDecoration='underline' color='white' cursor='pointer'>refreshed</Text> <Moment fromNow>{lastChecked}</Moment></Text>
                </Stack>
                <Spacer />
                <IconButton size='xs' onClick={() => props.toggleSidebar()} icon={<MdClose />} colorScheme='blue' className='btn-8' />

            </HStack>

            {show && 
                <Modal onClose={() => setShow(false)}
                    title='New message'
                    submitButton={{
                        text: 'Send',
                    }}
                    >
                    
                    <Box pt={4}>
                        <AutoForm

                            model='message'
                            url='messages'
                            method='post'
                            submitText='Send'
                            hideSubmit={true}
                            refresh={onSubmitNewMessage}
                            inputs = {[
                                {
                                    name: 'tempEmail',
                                    label: 'Email',
                                },
                                {
                                    name: 'subject'
                                },
                                {
                                    name: 'body',
                                    label: 'Message',
                                    type: 'textarea',
                                }, 
                                {
                                    name: 'flag',
                                    type: 'checkbox',
                                },
                            ]}
                        />
                    </Box>
                </Modal>
            }


            <Stack spacing="0" justify="center" mt={5} bg='white' boxShadow='md' border='0px solid gray' borderRadius='md'>

         
                {loading &&
                
                    <HStack minW='350px' justifyContent='space-between' w='100%' p={2} 
                        cursor='pointer'
                        borderBottom='1px solid whitesmoke'
                        > 
                        <Center w='100%'>
                            <Stack spacing={0} pb={8}>
                                <Box className="loading" w='100%'pb={0} pt={2}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </Box>
                                <Text fontSize='sm'>Loading messages</Text>
                            </Stack>
                        </Center>
                    </HStack>
                }
                {error && 
                    <HStack minW='350px' justifyContent='space-between' w='100%' p={2} 
                        cursor='pointer'
                        _hover={{bg: 'red.50'}}
                        borderBottom='1px solid whitesmoke'
                        >
                        
                        <HStack>
                            <Circle mr={2} cursor='pointer' size='48px' bg='red.500' >
                                <MdWarningAmber color='white' size='24px' />
                            </Circle>
                            <Stack spacing={0}>
                                <Text fontWeight='strong'>Error {error.code}</Text>
                                <Text>{error.message}</Text>
                            </Stack>
                        </HStack>
                    </HStack>
                }

                <HStack minW='350px' justifyContent='space-between' w='100%' p={2} 
                    cursor='pointer'
                    _hover={{bg: 'blue.50'}}
                    borderBottom='1px solid whitesmoke'
                    onClick={() => setShow(!show)}
                    >
                    
                    <HStack>
                        <Circle mr={2} cursor='pointer' size='48px' bg='blue.500' >
                            <MdEdit color='white' size='24px' />
                        </Circle>
                        <Stack spacing={0}>
                            <Text fontWeight='strong'>Start a new conversation</Text>
                        </Stack>
                    </HStack>
                    <Button 
                        display='none'
                        size='xs'
                        variant='outline'
                        colorScheme='blue' className='btn-8'  
                        onClick={() => setShow(!show)}
                        >
                        Compose
                    </Button>
                </HStack>


                {!loading && users.map((user) => {

                    return (

                        <HStack onClick={() => {
                            // Navigate to the user's record
                            console.log("CONVERSATION = ", user);
                            props.conversation(user.uuid);
                            //navigate('/my/conversation', { state: { recipientUuid: user.uuid} });
                            }}

                            _hover={{bg: 'blue.50'}}
                            cursor='pointer' justifyContent='space-between' w='100%' p={2} borderBottom='1px solid whitesmoke'>
                            <HStack>
                                <Avatar mr={2} name={user.firstName + " " + user.lastName} src={user.picture} />
                                <Stack spacing={0}>
                                    <Text fontWeight='strong'>{user.firstName + " " + user.lastName}</Text>
                                    <Text color='subtle' fontSize='xs'>{user.Messages.length} messages, last message <Moment fromNow>{user.Messages[0].createdAt}</Moment></Text>
                                </Stack>
                            </HStack>

                            <Button 
                                size='xs'
                                variant='outline'
                                colorScheme='blue' className='btn-8'  
                                onClick={() => {
                                // Navigate to the user's record
                                navigate('/my/conversation', { state: { recipientUuid: user.uuid} });
                                }}>
                                View
                            </Button>
                        </HStack>
                    )

                })}

            </Stack>

        </Box>

    )
    
}
    