import React, { useState, useEffect } from 'react';
import {Box, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table } from '@chakra-ui/react';
import { MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";

export default function Mathematical(props) {

    return (

        <Box pr={2}>
            {props.icon ?
                <Icon as={props.icon} boxSize='32px' ml='-2' mt={1} mb={-1} color={props.color}/>
            :
                <Text fontSize='32px' color={props.color} >
                    {props.value}
                </Text>
            }
        </Box>

    )
}