import React, { useEffect, useState } from "react";
import { Input, Divider, Icon, Menu, MenuItem, Tooltip, Badge,Tag, WrapItem, Wrap, HStack, Flex, GridItem, Box, Text, Heading, Button, Stack, Center, Image, useColorModeValue, Table, Th, Thead, Tr, Td, Tbody, ButtonGroup, TableCaption, MenuList, MenuButton, InputGroup } from '@chakra-ui/react';
import {MdAdd, MdCheck, MdArrowForward, MdEdit} from 'react-icons/md';
import { ro } from "date-fns/locale";
import Modal from '../../core/components/modal/Modal';


function makeTimeSafe(time) {

    // Need to make sure time is in the format HH:MM
    // If not, add a zero to the start
    if (time.length === 4) {
        time = '0' + time;
    }


    return time;
}


let colours = [

    '#ECFFDC',
    '#C5D5E4',
    '#DB5375',
    '#F17F29',
    '#FCB97D',
]



export default function WorkSchedule(props) {


    const [showDetails, setShowDetails] = useState(false);
    
    // Rota will be an array of weeks
    // Each week will be an array of days
    // Each day will have a shift 'key'
    const [rota, setRota] = useState(props.rota);


    // Shifts will be an array of shift objects
    // Each shift object will have a key, start, end, hours, enhanced hours, title, description
    const [shifts, setShifts] = useState(props.shifts);

    const [editDuty, setEditDuty] = useState(null);

    const [loading, setLoading] = useState(true);




    function translateToTime(duration)  {

        let hours = Math.floor(duration);
        let minutes = Math.round((duration - hours) * 60);

        // Round minutes up to nearest 15
        if (minutes > 0) {
            minutes = Math.ceil(minutes / 15) * 15;
        }

        let message = hours;
        if (minutes >0 ) {
            message += ":" + minutes + "";
        }


        return message;

    }

    function roundtodp(number, dp = 2) {
        return Math.round(100 * number) / 100;
    }
    

    function updateRota(w, d, key) {

        let tmpRota = [...rota];
        tmpRota[w][d] = key;
        console.log("ORIGINAL ROTA", props.rota);
        console.log("TMP ROTA", tmpRota);
        if (setRota(tmpRota)) {
            props.rota = tmpRota;
            props.shifts = shifts;
        }


    }


    return (

        <Box w='100%'>

            {((props.rota !== rota && rota !== null) || 
            (props.shifts !== shifts && shifts !== null)) &&

                <Flex p={4} justifyContent='space-between' pb={4} 
                    flexDirection={{base: 'column', md: 'row'}} alignItems='start' 
                    border='2px solid blue' borderColor='blue.500' borderRadius='md'
                    >

                    <Box mb={{base: 4, md: 0}}>

                        <Heading fontSize='1em' lineHeight='1.2em' color='blue.500'>
                            Unsaved Changes
                        </Heading>
                        <Text>Your changes to the shifts and/or rota are not saved yet.</Text>

                    </Box>

                    <HStack direction={{base: 'column', md: 'row'}} gap={4}>

                        <Button w='min-content' colorScheme='red' variant='ghost'                   
                            size={{base: 'sm', md: 'md'}}
                            order={{base: 1, md: 0}} 
                            m={0}
                            onClick={() => {
                                setRota(props.rota);
                                setShifts(props.shifts);
                            }}>
                            Discard
                        </Button>

                        <Button w='min-content' variant='solid' colorScheme='blue' 
                            size={{base: 'sm', md: 'md'}}
                            order={{base: 0, md: 1}}
                            m={0}
                            onClick={() => {
                                props.saveRota(shifts, rota);
                            }}>
                            Save
                        </Button>

                    </HStack>

                </Flex>
            }

            <Stack ml='auto' mr='auto' p={4} direction={{base: 'column', md: 'row'}} spacing={12} alignItems='flex-start'>
                    



            <Box pt={8}>

                <Heading pb={1} size="label" fontSize='xl' textAlign='center' mb={2} fontWeight="bold" pt={2}>
                    Shifts
                </Heading>

                <Divider />

                <Text color='subtle' p={2} textAlign='center' fontSize='xs' pb={4}>
                    This is an overview of the shifts you entered,<br/>you can edit the shifts by clicking on the table below.
                </Text>

                <Table className='mini-table' maxW='300px' m='auto'>
                    <Thead>
                    <Tr>
                        <Th>Key</Th>
                        <Th>Shift</Th>
                        <Th>Start</Th>
                        <Th>End</Th>
                        <Th>Hours</Th>
                        <Th>Enhanced</Th>
                        <Th>Edit</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {Object.keys(shifts).map((shiftKey, index) => {
                        let shift = shifts[shiftKey];
                        
                        return (
                            <Tr key={index}>
                                <Td bg={shift.colour}>{shift.shorthand}</Td>
                                <Td>{shift.name}</Td>
                                <Td>{shift.start}</Td>
                                <Td>{shift.end}</Td>
                                <Td>{shift.hoursText}</Td>
                                <Td>{shift.enhancedText}</Td>
                                <Td><Icon as={MdEdit} onClick={() => {setEditDuty(shiftKey);}}/></Td>
                            </Tr>
                        )
                    })}

                    </Tbody>
                </Table>

                <Flex justifyContent='center' pb={4}>

                    <Button mt={2} variant='variant' colorScheme='primary' size='xs' onClick={() => {
                                let keyOriginal = 'user-shift-';
                                let key = keyOriginal;
                                let keyIndex = 1;
                                while (shifts[key+keyIndex] !== undefined) {
                                    key += keyIndex;
                                    keyIndex++;
                                }
                                let tmpShifts = {...shifts};
                                tmpShifts[key+keyIndex] = {
                                    'key': key+keyIndex,
                                    'start': '00:00',
                                    'end': '00:00',
                                    'hours': 0,
                                    'enhanced': 0,
                                    'name': 'New Shift',
                                    'shorthand': 'NS',
                                    'colour': '#C5D4E3',
                                    'hoursText': '0',
                                    'enhancedText': '0',
                                }
                                setShifts(tmpShifts);
                            }}>
                                Add Shift
                    </Button>

                </Flex>



            </Box>

            {editDuty !== null &&
            
                <Modal title='Edit Shift' onClose={() => {setEditDuty(null);}}>
                    <Stack spacing={4}>
                        
                            <Heading size="label">
                                Shift Name
                            </Heading>
                            <Input placeholder='Shift Name' value={shifts[editDuty].name} onChange={(e) => {
                                let tmpShifts = {...shifts};
                                tmpShifts[editDuty].name = e.target.value;
                                setShifts(tmpShifts);
                            }}/>
                            <Heading size="label">
                                Shorthand
                            </Heading>
                            <Input placeholder='Shift Shorthand' value={shifts[editDuty].shorthand} onChange={(e) => {
                                let tmpShifts = {...shifts};
                                tmpShifts[editDuty].shorthand = e.target.value;
                                setShifts(tmpShifts);
                            }}/>
                            <Heading size="label">
                                Colour
                            </Heading>
                            <Wrap spacing={2}>
                                {colours.map((colour, index) => {
                                    return (
                                        <Button w='36px' bg={colour} onClick={() => {
                                            let tmpShifts = {...shifts};
                                            tmpShifts[editDuty].colour = colour;
                                            setShifts(tmpShifts);
                                        }}>
                                            {shifts[editDuty].colour === colour &&
                                                <Icon as={MdCheck} />
                                            }
                                        </Button>
                                    )
                                })}
                            </Wrap>
                            <Heading size="label">
                                Start
                            </Heading>
                        <Input placeholder='Shift Start' value={makeTimeSafe(shifts[editDuty].start)} type='time' onChange={(e) => {
                            let tmpShifts = {...shifts};
                            tmpShifts[editDuty].start = e.target.value;
                            setShifts(tmpShifts);
                        }}/>
                            <Heading size="label">
                                End
                            </Heading>
                            <Input placeholder='Shift End' value={makeTimeSafe(shifts[editDuty].end)} type='time' onChange={(e) => {
                                let tmpShifts = {...shifts};
                                tmpShifts[editDuty].end = e.target.value;
                                setShifts(tmpShifts);
                            }}/>

                        <Button variant='solid' colorScheme='red.500' onClick={() => {
                            setEditDuty(null);
                        }}>
                            Close
                        </Button>

                    </Stack>
                </Modal>
            
            }


                <Box pt={8}>

                    <Heading pb={1} size="label" fontSize='xl' textAlign='center' mb={2} fontWeight="bold" pt={2}>
                        Rota
                    </Heading>

                    <Divider />

                    <Text color='subtle' p={2} textAlign='center' fontSize='xs' pb={4}>
                        This is an overview of the rota you entered,<br/>you can edit the rota by clicking on the table below.
                    </Text>

                    <Table className='mini-table' maxW='300px' m='auto' key={rota.join('')}>
                        <Thead>
                        <Tr>
                            <Th>Week</Th>
                            <Th>M</Th>
                            <Th>T</Th>
                            <Th>W</Th>
                            <Th>T</Th>
                            <Th>F</Th>
                            <Th>S</Th>
                            <Th>S</Th>
                            {showDetails &&
                                <Th display={showDetails}>Hours (Enhanced)</Th>
                            }
                        </Tr>
                        </Thead>
                        <Tbody>

                        {rota.map((week, weekIndex) => {


                            return (
                                <Tr>
                                    <Td>{weekIndex+1}</Td>
                                    {week.map((day, dayIndex) => {
                                        let shift = shifts[day];

                                        if (dayIndex === 7 && showDetails) {
                                            return <Td>{day} ({week(8)})</Td>
                                        }
                                        if (dayIndex > 6) {
                                            return;
                                        }

                                        if (day === '') {
                                            shift = shifts['?'];
                                        }
                                            
                                        if (shift === undefined) {
                                            return <Td bg='red.500'></Td>
                                        }
                                        let message = shift.name;
                                        if (shift.hours === 0) {
                                            message += ' - no hours';
                                        } else {
                                            message += ' - ' + shift.hoursText + ' hours';
                                            if (shift.enhanced !== 0) {
                                                message += ' (' + shift.enhancedText + ' enhanced)';
                                            }
                                        }
                                        return (

                                                <Td _hover={{fontWeight: '900'}} cursor='pointer' bg={shift.colour}>
                                                    <Menu width='fill-available'>
                                                        <MenuButton width='fill-available'>
                                                            {shift.shorthand}
                                                        </MenuButton>
                                                        <MenuList>
                                                            {Object.keys(shifts).map((key, index) => {

                                                                let menuShift = shifts[key];
                                                                if (shift.shorthand === menuShift.shorthand) {
                                                                    return;
                                                                }


                                                                return (
                                                                    <MenuItem onClick={() => {
                                                                        // Update rota
                                                                        console.log("UPDATE DUTIES", weekIndex, dayIndex, menuShift.key);
                                                                        updateRota(weekIndex, dayIndex, key);
                                                                    }}>
                                                                        {menuShift.shorthand}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                        )
                                    }
                                    )}
                                    <Td display={showDetails}></Td>
                                </Tr>
                            )
                        })}

                        </Tbody>
                    </Table>
            

 
                </Box>
                    

            </Stack>



            {((props.rota !== rota && rota !== null) || 
            (props.shifts !== shifts && shifts !== null)) &&

                <Flex mt={8} p={4} justifyContent='space-between' pb={4} 
                    flexDirection={{base: 'column', md: 'row'}} alignItems='start' 
                    border='2px solid blue' borderColor='blue.500' borderRadius='md'
                    >

                    <Box mb={{base: 4, md: 0}}>

                        <Heading fontSize='1em' lineHeight='1.2em' color='blue.500'>
                            Unsaved Changes
                        </Heading>
                        <Text>Your changes to the shifts and/or rota are not saved yet.</Text>

                    </Box>

                    <HStack direction={{base: 'column', md: 'row'}} spacing={0}>

                        <Button w='min-content' variant='ghost' colorScheme='red'                    
                            size={{base: 'sm', md: 'md'}}
                            order={{base: 1, md: 0}}
                            ml={0}
                            mr={0}
                            onClick={() => {
                                setRota(props.rota);
                                setShifts(props.shifts);
                            }}>
                            Discard
                        </Button>

                        <Button w='min-content' variant='solid' colorScheme='blue' 
                            size={{base: 'sm', md: 'md'}}
                            order={{base: 0, md: 1}}
                            ml={0}
                            mr={0}
                            onClick={() => {
                                props.saveRota(shifts, rota);
                            }}>
                            Save
                        </Button>

                    </HStack>

                </Flex>
            }

            
        </Box>

    )


}