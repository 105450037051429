
import React from 'react';
import { useDisclosure } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom';
import { Flex, Tag, TagLabel, Divider, ButtonGroup, Stack, Wrap, Heading, Box, Input, Button, Text } from '@chakra-ui/react';
import axios from 'axios';
import API from '../../core/utils/API';


export default function Registration(props) {

    let [registration, setRegistration] = React.useState('');
    const [details, setDetails] = React.useState(null);
    const [trying, setTrying] = React.useState(false);

    const [error, setError] = React.useState('');

    const [searchParams] = useSearchParams();

    const { getDisclosureProps, getButtonProps } = useDisclosure()

    const buttonProps = getButtonProps()

    const disclosureProps = getDisclosureProps()

    const nextStep = () => {

        let config = {
            params: {
                table: 'GMC',
                idcol: 'GMC_Ref_No',
                id: registration,
            }
        }

        console.log("CONFIG:", config);

        let responseAPI = API.getItems({url: 'my/professionalRegistration', config})
        .then(function (response2) {
            console.log("Response from API in: ", response2);
          //  window.location.href = '/registration-success';
        });

        let response = API.postItems({url: 'users/registration/assign', config})
        .then(function (response) {
            console.log("Response from API in: ", response);
            props.setStep('Photo');
          //  window.location.href = '/registration-success';
        });

    }

    const reset = () => {
        setDetails(null);
    }


    const checkRegistration = () => {


        setError('');
        setTrying(true);


        if (document.getElementById('registration').value === '') {

            setError('Please enter a registration code');
            setTrying(false);

            return;

        }

        let data = {
            table: 'GMC',
            idcol: 'GMC_Ref_No',
            id: document.getElementById('registration').value,
        }
        let config = {
            params: {
                table: 'GMC',
                idcol: 'GMC_Ref_No',
                id: document.getElementById('registration').value,
            }
        }

        let responseAPI = API.getItems({url: 'users/me/professionalRegistration', config})
        .then(function (response2) {
            setRegistration(document.getElementById('registration').value);
            setDetails(JSON.parse(response2.data));
        });




        setTrying(false);

    }


    return (

        <Box>
            <Stack spacing={4}>
            <Heading size='smalltitle' mb={4}>GMC Registration</Heading>

                {details === null &&
                    <>
                    <Text>Entering all your details is dull!</Text><Text>We can create a profile automatically from your official information.</Text>
                    <Input placeholder='GMC Number' id='registration'/>

                    {error != '' &&
                        <Text color='red'>{error}</Text>
                    }

                        <Flex justifyContent='space-between' pt={4}>
                            <Button onClick={() => props.setStep('Welcome')} variant='outline' colorScheme='red' className='btn-8'>Back</Button>

                            {trying ?
                                <Button justifySelf='flex-end' w='fit-content' variant='outline' disabled>Checking</Button>
                                :
                                <Button justifySelf='flex-end' w='fit-content' variant='outline' colorScheme='blue' className='btn-8' onClick={checkRegistration}>Find Details</Button>
                            }                        
                        </Flex>
                    </>
                }
                    {details != null &&
                        <>
                            {Object.keys(details).length != 0 && 

                                <Box>
                                    <Text mb={4}>We think we've found you!</Text>
                                    <Text mb={8}>If this isn't you then you an go back and check your number, if it is then we will update your profile with the publically available information.</Text>

                                    <Box p={4} mt={4} mb={6} boxShadow='md' border='1px solid black' borderRadius='md'>
                                        <Text as='span' color='blue.500'>{[details['Given_Name'], details['Other_Names'], details['Surname']].join(" ")}</Text>
                                        <Text>{details['Qualification']} ({details['Year_Of_Qualification']}) from {details['Place_of_Qualification']}</Text>
                                        <Text>{details['Registration_Status']}</Text>
                                    </Box>
                                    <Wrap spacing={2} display='none'>
                                    
                                        {Object.keys(details).map((innerAttr, index) => {
                                            return (
                                                <>
                                                    {details[innerAttr] != "" && details[innerAttr] != '0000-00-00' &&
                                                        <Tag size='xs' isAttached variant='outline'>
                                                            <TagLabel>{innerAttr.replaceAll("_", " ")}</TagLabel>
                                                            <Divider bg='black' ml={1} mr={1} orientation='vertical' />
                                                            <TagLabel>{details[innerAttr]}</TagLabel>
                                                        </Tag>
                                                    }
                                                </>
                                            )})
                                        }
                                        
                                    </Wrap>
                                </Box>
                            }
                            <Flex justifyContent='space-between'>
                                <Button onClick={() => reset()} variant='outline' colorScheme='red' className='btn-8'>Back</Button>
                                <Button onClick={() => nextStep()} variant='outline' colorScheme='blue' className='btn-8'>This is me!</Button>
                            </Flex>
                        </>
                    }
            </Stack>
        </Box>

    )

}