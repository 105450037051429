import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Img,
    Center,
    SimpleGrid,
    Link,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react';
  import * as React from 'react';
  import { MdArrowForward } from 'react-icons/md';
  import { useAuth0 } from "@auth0/auth0-react";
  import { redirect } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
import TryNowButton from '../shared/TryNowButton';

  export default function Hero() {
  
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();

    return (
      <Box as="section" pt="4" pb="12" overflow="hidden">
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <Flex
            align="flex-start"
            direction={{
              base: 'column',
              lg: 'row',
            }}
            justify="space-between"
            mb="30"
            mt="10"
          >
            <Box
              
              flex="1"
              maxW={{
                lg: 'xl',
              }}
              pt="6"
            >
              <Heading as="h1" size='pagetitle'  mt={4} mb={6}>
                Automated<br/>HR tools for<br/>Healthcare
              </Heading>
              <Text size='pagetitle-description' mt={12} mb={6} >
                AI for recruitment, onboarding and compliance.
              </Text>
              <TryNowButton />
            </Box>
            <Center flex='1' position={{base: 'absolute', lg: 'inherit'}} top={{base: '50px', lg: 'inherit'}}
                height='600px'
                margin-top='-120px'
                width='100%'
                overflow='hidden'
                left='0'
                >
              <Box mt={16} className='gooey'
                zIndex='-1'
                right={{base: '-120px', lg: '20px'}}
                top={{base: '40px', lg: '-60px'}}
                position={{base: 'absolute', lg: 'relative'}}
                />
            </Center>
          </Flex>
        </Box>

        <Box h='200px' mb={24} ml={8} display='none!important;'>
          <svg className='masked' height='200px' width='700px'>
            <defs>
            <mask id="mask" x="0" y="0" width="100%" height="100%" >
              <rect id="alpha" x="0" y="0" width="100%" height="100%"/>
              <text id="title" dy="1.58em">Pyrexia Passport</text>
            </mask>
            </defs>
            <rect id="base" x="0" y="0" width="100%"  height="100%" className='gradient-background'/>
          </svg>
        </Box>
        
      </Box>
    )
  }