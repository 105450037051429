
import Footer from '../layout/Footer';
import CallToAction from '../shared/CallToAction';
import PyrexiaDivider from '../layout/PyrexiaDivider';
import { Box, Link, Heading, Text, Image, UnorderedList, ListItem, AspectRatio } from '@chakra-ui/react';
export default function WorkflowProfessionals() {


    return (
        <Box>
          <Box as="section" py="7.5rem" pb='5em'>
            <Box
              maxW={{
                base: 'xl',
                md: '7xl',
              }}
              mx="auto"
              px={{
                base: '6',
                md: '8',
              }}
            >
                <Heading size="pagetitle">
                  How it works<Text as='span' color='#0166ff'>.<br/>
                  For pay
                  </Text>
                </Heading>
                <Heading size='statictagline'>
                  Not sure how much you should be paid?  <sup>Ø</sup>Pyrexia can help.
                </Heading>
                <Text size='staticsmalltext'>

                </Text>
                <Text size='staticsmalltext' pt={4}>
                  Our intelligent HR automation platform makes checking your pay simple. Just upload your work schedule and we'll do the rest.
                </Text>
                <UnorderedList>
                  <ListItem>
                    <Text size='staticsmalltext'>
                      AI instantly extracts your shifts and rota from the Work Schedule
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                      Pyrexia will automatically calculate your hours of work, including enhanced antisocial hours
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                      Compares the correct pay figures against the original work schedule and informs you of any differences
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                      Easily edit details and all analysis is re-run instantly
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                      Automatic checks against 90 common error combinations - including <Text color='blue.500' as='span'><a href='/prospectiveleave'>Prospective Leave</a></Text>, incorrect number of hours used for a day of leave, rounding down leave and more. Full details below.
                    </Text>
                  </ListItem>

                </UnorderedList>
            </Box>
            </Box>


              <Box as='section' bg='#000' color='white' py='7.5rem'>
              <Box
                maxW={{base: 'xl',md: '7xl',}}
                mx="auto"
                px={{base: '6',md: '8',}}
                >
                <Heading size="smalltitle" color='inverted'>
                  We want to make life easier for everyone.
                </Heading>
                <Heading size='statictagline' fontSize='22px' mb={12} mt={8} color='inverted'>
                <sup>Ø</sup>Pyrexia is working with healthcare professionals around the UK to make HR simpler.
                </Heading>
                <AspectRatio maxW='100%' ratio={1/0.6498194945848375} mb={12}>
                  <iframe src="https://www.loom.com/embed/e22ac0147f8f4c0eaf7600bcd27e2ca1?sid=add8039a-7fc3-4916-ad75-bdd01c53ffaf" 
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  
                    ></iframe>
                </AspectRatio>
              </Box>
            </Box>



            <Box as='section' py='7.5rem' mb={4} pt='5em'>
              <Box
                maxW={{base: 'xl',md: '7xl',}}
                mx="auto"
                px={{base: '6',md: '8',}}
                >

              <Heading size="smalltitle">
                Errors              
              </Heading>
              <Heading size='statictagline' fontSize='22px' mb={12} mt={8}>
                We check for a number of common errors and their combinations, for a total of 90.
              </Heading>

              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Prospective Leave</Text><br/>
                Doctors are paid for intensity of work - i.e. how many additional hours above 40, and enhanced hours per week. There is a common error where 
                all leave (most commonly study leave) is not accounted for. This can lead to significant underpayments up to £3000pa. 
                <Text color='blue.500' as='span'><a href='/prospectiveleave'> Read more.</a></Text>
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Incorrect hours of leave</Text><br/>
                To accurately calculate your average hours of work per week HR must deduct the hours you spend on leave, this should be the length of your shortest shift. 
                A common mistake is to use a standard 7.5 hour day, which can lead to significant underpayments.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Rounding leave</Text><br/>
                There are a number of steps to calculating your average hours of work using your leave entitlement, depending on when you round figures it is possible to 
                make significant errors, especially in shorter rotas. We do not round your leave entitlement.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Rounding times</Text><br/>
                It is a contractual requirement that hours are rounded up to the nearest 15 minutes. If this is inappropriately applied in the middle of calculations
                it can lead to incorrect payments. We only round the times at the very end of the calculations.
              </Text>
              <Text size='staticsmalltext'>
                <Text as='span' fontWeight='bold'>Out-of-hours for GP Trainees</Text><br/>
                GP trainees have additional out-of-hours work that can be paid in addition to their contracted GP hours, this is 30 minutes per week. 
                It is not an error, but is not attributable to the rota in the work schedule so we make sure we keep an eye out for it.
              </Text>


                </Box>
              </Box>
              
              <PyrexiaDivider />


              <CallToAction/>
              <Footer/>
  
          </Box>
    )

}