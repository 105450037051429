import React, { useState } from 'react';
import { Flex, Box, Text, Tag, Wrap, Button, Stack } from '@chakra-ui/react';
import InputWrapper from '../forms/inputs/InputWrapper';

export default function Summary(props) {


    console.log(props.questions);
    console.log(props.questions[0]);

    // Loop through the questions and check that they are in the data object
    let missingDataKeys = []
    Object.keys(props.questions).map((key, index) => {

        //  Get key from questions
        let name = props.questions[key].name;

        // Check if name exists in data
        if (!props.data[name]) {

            let prep = {
                value: undefined,
                error: props.questions[key].error,
                required: props.questions[key].required,
            }
            // Add to missing data keys
            
            missingDataKeys[name] = prep;    
        }

    })

    console.log("Updating panel data", missingDataKeys);

    // Update the data object with the missing data keys
    props.updateData(missingDataKeys);


    return (
        <Stack spacing={4}>

            {props.preamble !== undefined &&  
            
                <>
                    {props.preamble.title && <Text fontSize="xl" fontWeight="bold">{props.preamble.title}</Text>}
                    {props.preamble.description && 
                        <>
                            {props.preamble.description.map((item, index) => {
                                return <Text>{item}</Text>
                            })}
                        </>
                    }
                </>
            }

            {props.questions != undefined && 
                // props.questions is an array of objects
                // loop through this
                Object.keys(props.questions).map((key, index) => {

                    console.log(key, props.questions[key]);
                    let name = props.questions[key].name;
                    console.log(props.data[name].value);

                    return (
                        <InputWrapper
                            {...props.questions[key]}   
                            defaultValue={props.data[name].value}
                            setValue={(name, value) => {props.updateData({[name]: value})}}
                            />
                    )
                })
            }

        </Stack>

    )

}