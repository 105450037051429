import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import WorkSchedule from '../prospectiveleave/WorkSchedule';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';


export default function Schedule(props) {

    const [schedules, setSchedules] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAdmin , setShowAdmin] = useState(false);
    const navigate = useNavigate();
    const [refreshing, setRefreshing] = useState(false);
    

    const subscriptionData = useContext(SubscriptionContext);
    //console.log("SubscriptionProtectedRoute", subscriptionData);
   // console.log("SubscriptionProtectedRoute Context", subscription);
    if (subscriptionData !== null && subscriptionData !== undefined && showAdmin === false) {
        //console.log("SubscriptionContext in Schedules", subscriptionData);
        // Loop through subscription data
        subscriptionData.subscriptionIds.forEach((subscription, index) => {

            if (subscription === 'admin') {
                //console.log("Admin subscription found");
                setShowAdmin(true);
            }

        });
    }


    function updateList() {


        let url = 'schedules/all';
        let config = {
            params: {
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            // Sort schedules by .lastAnalysed
            let sorted = response.data.schedules.sort(function(a, b) {
                return (a.lastAnalysed < b.lastAnalysed) ? 1 : ((b.lastAnalysed < a.lastAnalysed) ? -1 : 0);
            });

            // Loop through each and check if more than 3 minutes old and no ai analysis available
            let analysing = false;
            sorted.forEach((schedule, index) => {
                if (schedule.lastAnalysed === null && moment(schedule.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isAfter(moment().subtract(3, 'minutes'))) {
                    analysing = true;
                }
            });

            if (analysing) {
                // Set timeout to update list
                setRefreshing(true);
                setTimeout(() => {
                    updateList();
                }, 10000);
            } else {
                setRefreshing(false);
            }


            setSchedules(sorted);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
        }

        );

    }
    useEffect(() => {

        updateList();

    }, []);

    function viewSchedule(uuid) {
        //console.log("View Schedule", uuid);
        navigate('/pay/schedule', {state: {uuid: uuid}});
    }


    function repeatAnalysis(uuid) {

        let url = 'schedules/repeat';
        let config = {
            params: {
                uuid
            }
        };
        let response = API.getItems({url, config})
        .then(response => {
            updateList();
        }).catch(error => {
            console.log(error);
        });

    }

    function moveMinus(value, prefix) {

        let pre = '';
        if (value < 0) {
            pre = '-';
        }

        // Check if decimal, if so, round to 2dp
        if (value % 1 !== 0) {
            value = Math.round(value * 100) / 100;
        }

        // Make sure we have two decimal places
        value = value.toFixed(2);

        value = Math.abs(value);

        // Add commas to thousands
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Add extra 0 if only one decimal place
        let valueSplit = value.split(".");
        if (valueSplit[1] && valueSplit[1].length === 1) {
            value = value + '0';
        }


        return pre + prefix + value;

    }

    function setPrivacy(uuid, privacy) {

        //console.log("Setting Privacy");
        let url = 'schedules/privacy';
        let config = {
            params: {
                uuid,
                private: privacy
            }
        };
        let response = API.getItems({url, config})
        .then(response => {
            updateList();
        }).catch(error => {
            console.log(error);
        });

    }

    return (

        <>

        <Layout
            title="Your Schedules"
            description="This is a list of your work schedules, you can add more by searching our database or uploading a PDF."
            actionContent={
                <>
                    <SubscriptionProtectedRoute
                        message="Subscribe to Upload"
                        requiredSubscription="prod_OYd6qBn5Qe5ZDg"  // Pay plan, the lowest
                        allowTrial={false}
                        fullWindow={false}
                        fullMessage={
                            <>
                                {schedules && schedules.length <= 2 && // zero indexed
                                    <Button w='min-content' variant='outline' colorScheme='blue' onClick={() => navigate('/pay/uploadschedule')}>Upload Schedule</Button>
                                }
                                {schedules && schedules.length > 2 && // zero indexed
                                    <Button w='min-content' variant='solid' colorScheme='red' onClick={() => navigate('/my/subscriptions/')}>Subscribe</Button>
                                }
                            </>
                        }
                        >
                        {schedules && schedules.length <= 7 ?
                            <Button variant='outline' colorScheme='blue'  onClick={() => navigate('/pay/uploadschedule')}>Upload Schedule</Button>
                            :
                            <a href='mailto:oliver@pyrexia.io' target='_blank'>
                                <Button  w='min-content' variant='solid' colorScheme='red'>Contact Us</Button>
                            </a>
                        }
                    </SubscriptionProtectedRoute>
                </>
            }
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>

                    {refreshing && <Tag colorScheme='blue' mb={4}>Refreshing for analysis updates</Tag>}

                    {loading ? <LoadingCircle /> : null}

                    {schedules ?

                        <Box>

                                <Header c={6}>

                                    <Cell c={2}>
                                        Organisation
                                    </Cell>
                                    <Cell c={2}>
                                        Specialty
                                    </Cell>
                                    <Cell c={1}>
                                        Status
                                    </Cell>

                                </Header>

                                {schedules &&
                                    <SubscriptionProtectedRoute
                                        message="Subscribe to Upload"
                                        requiredSubscription="prod_OYd6qBn5Qe5ZDg"  // Pay plan, the lowest
                                        allowTrial={false}
                                        fullWindow={false}
                                        fullMessage={

                                            <Row c={6}                                    
                                                buttons='hide'
                                                >
                                                <Cell c={6}>
                                                    <Alert status='loading' w='auto' m={-4} borderRadius={0} className='gradient-background'>
                                                        {schedules.length <= 2 ? 
                                                            <Box bg='white' color='black' p='6px 14px' m='-6px -14px' w='-webkit-fill-available'>
                                                                You can upload a further {pluralize('schedule', 3 - (schedules.length), true)} on the free plan. 
                                                            </Box>
                                                            : 
                                                            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent='space-between' w='-webkit-fill-available'>
                                                                <Text color='white'>You have reached the limit of 3 schedules on your plan, subscribe to add more.</Text>
                                                                <Button w='min-content' variant='outline' colorScheme='white' onClick={() => navigate('/my/subscriptions/')} size='xs'>Subscribe</Button>
                                                            </Flex>
                                                        }
                                                    </Alert>
                                                </Cell>
                                            </Row>
                                        }
                                    >
                                        
                                        <Row c={6}                                    
                                                buttons='hide'
                                                >
                                                <Cell c={6}>
                                                    <Alert status='loading' w='auto' m={-4} borderRadius={0}  className='gradient-background'>
                                                        {schedules.length <= 7 ? 
                                                            <Box bg='white' color='black' p='6px 14px' m='-6px -14px' w='-webkit-fill-available'>
                                                                You can upload a further {pluralize('schedule', 8 - (schedules.length), true)} on the premium plan. 
                                                            </Box>
                                                            : 
                                                            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent='space-between' w='-webkit-fill-available'>
                                                                <Text color='white'>You have reached the limit of schedules on your plan, contact us to discuss other options.</Text>
                                                                <a href='mailto:oliver@pyrexia.io' target='_blank' line-height='1.4em'>
                                                                    <Button variant='outline' colorScheme='white' size='xs'>Contact Us</Button>
                                                                </a>
                                                            </Flex>
                                                        }
                                                    </Alert>
                                                </Cell>
                                            </Row>
                                    </SubscriptionProtectedRoute>
                                }


                                {schedules.map((schedule, index) => (
                                    <Row c={6}
                                    
                                        buttons='hide'
                                    
                                        >

                                        {schedule.lastAnalysed !== null ?
                                            <>

                                                <Cell c={2}>
                                                    <Text fontWeight='600'>{schedule.site}</Text>
                                                    {schedule.organisation !== schedule.site &&
                                                        <Text color='subtle'>{schedule.organisation}</Text>
                                                    }
                                                    {schedule.host !== null && schedule.host !== schedule.site && schedule.host !== schedule.organisation &&
                                                        <Text color='subtle'>{schedule.host}</Text>
                                                    }

                                                </Cell>

                                                <Cell c={2}>

                                                    <Text as='span' fontWeight='600'>{schedule.specialty}</Text><br/>
                                                    <Text as='span' color='subtle'>{schedule.grade} at {Math.round(schedule.fteCalculated*100)}% FTE, <Moment format="MMM YYYY">{schedule.start}</Moment></Text><br/>
                                                    <Text as='span' color='subtle' display='none'>
                                                        <Moment format="MMM YYYY">
                                                        {schedule.start}
                                                        </Moment>
                                                    </Text>
                                                </Cell>

                                                <Cell>

                                                {schedule.noCover === true && schedule.anyShift === false ?
                                                        <Tag variant='outline'  colorScheme='green' size='xs' display='none' pl={2} pr={4}>
                                                            <TagLeftIcon as={MdCheck} />
                                                            <TagLabel>
                                                                Prospective Leave
                                                            </TagLabel>
                                                        </Tag>
                                                        : 
                                                        <Tag variant='outline'  size='xs' display='none' pl={2} pr={4}>
                                                            <TagLeftIcon as={MdClose} />
                                                            <TagLabel>
                                                                No Prospective Leave
                                                            </TagLabel>
                                                        </Tag>
                                                    }

                                                    {schedule.difference === 0 ?
                                                        <Tag variant='subtle' colorScheme='green' size='xs' pl={2} pr={4}>
                                                            <TagLeftIcon as={MdCheck} />
                                                            <TagLabel>
                                                                Correct
                                                            </TagLabel>
                                                        </Tag>
                                                    :
                                                        <Tag variant='subtle' colorScheme={schedule.difference > 0 ? 'orange' : 'red'} size='xs' pl={2} pr={4}>
                                                            <Icon as={schedule.difference > 0 ? MdExpandLess : MdExpandMore} mr={1}/>
                                                            {moveMinus(schedule.difference, "£")}
                                                        </Tag>
                                                    }
                                                    
                                                </Cell>

                                                <Cell>
                                                    <ButtonGroup size="xs" variant="outline">
                                                        {schedule.JSONrota !== null &&
                                                            <Button onClick={() => viewSchedule(schedule.uuid)} colorScheme="blue">View</Button>
                                                        }
                                                        {showAdmin &&
                                                            <>
                                                                <IconButton size='xs' onClick={() => repeatAnalysis(schedule.uuid)} variant='outline' colorScheme='blue' icon={<MdOutlineModelTraining />} />
                                                                {schedule.private === true ?
                                                                    <IconButton size='xs' onClick={() => setPrivacy(schedule.uuid, false)} variant='outline' colorScheme='red' icon={<MdLock />} />
                                                                    :
                                                                    <IconButton size='xs' onClick={() => setPrivacy(schedule.uuid, true)} variant='outline' colorScheme='blue' icon={<MdLockOpen />} />
                                                                }
                                                            </>
                                                        }
                                                        {schedule.private &&
                                                            <IconButton size='xs' disabled variant='outline' colorScheme='blue' icon={<MdLock />} />
                                                        }
                                                    </ButtonGroup>
                                                    
                                                </Cell>
                                                
                                            </>
                                        :
                                                        
                                            <>
                                                {moment(schedule.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isAfter(moment().subtract(3, 'minutes')) ?


                                                    <>
                                                    <Cell c={2}>
                                                        <Text fontWeight='600'>{schedule.uuid}</Text>
                                                        <Text color='blue.500'>Analysing file</Text>
                                                    </Cell>

                                                    <Cell c={3}>
                                                        <Box pt={2}>
                                                            <div class='progress-thin-line'></div>
                                                        </Box>
                                                    </Cell>

                                                    <Cell>
                                                        <Wrap spacing='0.5em'>
                                                            <Tag colorScheme='blue' size='sm' >
                                                                Analysing
                                                            </Tag>
                                                            {showAdmin &&
                                                                <>
                                                                    <IconButton size='xs' onClick={() => repeatAnalysis(schedule.uuid)} variant='outline' colorScheme='blue' icon={<MdOutlineModelTraining />} />
                                                                    <IconButton size='xs' onClick={() => viewSchedule(schedule.uuid)} variant='outline' colorScheme='blue' icon={<MdOutlineVisibility />} />
                                                                    {schedule.private === true ?
                                                                        <IconButton size='xs' onClick={() => setPrivacy(schedule.uuid, false)} variant='outline' colorScheme='red' icon={<MdLock />} />
                                                                        :
                                                                        <IconButton size='xs' onClick={() => setPrivacy(schedule.uuid, true)} variant='outline' colorScheme='blue' icon={<MdLockOpen />} />
                                                                    }
                                                                </>
                                                            }
                                                            
                                                        </Wrap>
                                                    </Cell>
                                                    
                                                    </>

                                                :

                                                    <>
                                                    <Cell c={2}>
                                                        <Text fontWeight='600'>{schedule.uuid}</Text>
                                                        <Text color='subtle'>Analysing file failed</Text>
    
                                                    </Cell>
                                                    <Cell c={2}>
                                                        <Text color='subtle'>Issue with analysis, flagged for review</Text>
                                                        {schedule.jsonForms === null &&
                                                            <Text color='red.500'>No AI analysis available.</Text>
                                                        }
    
                                                    </Cell>
                                                    <Cell>
                                                        <Tag variant='outline' size='xs' pl={4} pr={4}>
                                                            <TagLeftIcon as={MdHelpOutline} />
                                                            <TagLabel>
                                                                Unknown
                                                            </TagLabel>
                                                        </Tag>
                                                        
                                                    </Cell>
                                                    <Cell>
                                                        <Wrap spacing='0.5em'>
                                                            <Tag size='sm' >
                                                                Under Review
                                                            </Tag>
                                                            {showAdmin &&
                                                                <>
                                                                    <IconButton size='xs' onClick={() => repeatAnalysis(schedule.uuid)} variant='outline' colorScheme='blue' icon={<MdOutlineModelTraining />} />
                                                                    <IconButton size='xs' onClick={() => viewSchedule(schedule.uuid)} variant='outline' colorScheme='blue' icon={<MdOutlineVisibility />} />
                                                                    {schedule.private === true ?
                                                                        <IconButton size='xs' onClick={() => setPrivacy(schedule.uuid, false)} variant='outline' colorScheme='red' icon={<MdLock />} />
                                                                        :
                                                                        <IconButton size='xs' onClick={() => setPrivacy(schedule.uuid, true)} variant='outline' colorScheme='blue' icon={<MdLockOpen />} />
                                                                    }
                                                                </>
                                                            }
                                                            {schedule.private &&
                                                                <IconButton size='xs' disabled variant='outline' colorScheme='blue' icon={<MdLock />} />
                                                            }
                                                        </Wrap>
                                                    </Cell>
                                                    
                                                    </>

                                                }
                                            </>
                                        }
                                    </Row>
                                ))}
                                
                        </Box>


                        : null}

                        <Button display='none' ml={4} mt={8} size='xs' onClick={() => setShowAdmin(!showAdmin)} variant='outline' colorScheme='gray'>
                            {showAdmin ? 'Hide Admin Tools' : 'Show Admin Tools'}
                        </Button>


                </GridItem>

        </Layout>

        </>

    )

}



