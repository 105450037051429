import React from 'react';
import { useState} from 'react';
import { useEffect } from "react";
import { 
    Wrap,
    ButtonGroup,
    Select,
    Divider,
    Link,
    FormControl,
    FormLabel,
    Tooltip,
    FormErrorMessage,
    FormHelperText,
    Radio,
    RadioGroup,
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    ListItem,
    OrderedList,
    UnorderedList,
    SliderMark,
    Editable,
    EditableInput,
    EditableTextarea,
    Tag, 
    EditablePreview, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Spinner, chakra, useCheckboxGroup, useCheckbox, GridItem, Checkbox, Box, Heading, Text, Button, Input, Stack, useColorModeValue, Center, Image, Flex, Spacer, useToast, CheckboxGroup, TagCloseButton, InputGroup, TagLeftIcon, Grid, SimpleGrid, IconButton} from '@chakra-ui/react';
import API from '../../core/utils/API';
import { useForm, useController, Controller } from "react-hook-form";
import Splash from '../../static/layout/Splash';
import CreatableSelect from 'react-select/creatable';
import Specialties from './GeneralPractice';
import Loading from '../../static/layout/Loading';
import { MdCheck, MdClose, MdContentCopy, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import Layout from '../../core/layout/Layout';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingCircle from '../../static/layout/LoadingCircle';
import Modal from '../../core/components/modal/Modal';

function CheckBoxButtons(props) {

    return (
        <Box>
            {props.data.values &&
                <Wrap spacing={2}>
                    {props.data.values.map((option, index) => {

                        return (
                            <Button colorScheme='blue' variant='outline' className='btn-8' onClick={() => {console.log("clicked", props.data.label, option.valueLabel); props.setCheckboxButtonValue(props.data.valueLabel, option.label, index);}}>{option.label}</Button>
                        )
                    })}
                </Wrap>
            }
        </Box>
    )
}


function convertMinutesToString(minutes) {

    let hours = Math.floor(minutes / 60);
    let mins = minutes % 60;

    let hoursString = hours > 0 ? hours + "h" : "";
    let minsString = mins > 0 ? mins + "m" : "";

    return hoursString + minsString;

}

function CopyeableText(props) {

    const toast = useToast();

    const generateToast = () => {
        toast({
            title: "Copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
        })
    }

    return (
        <Box cursor='pointer' _hover={{color: 'blue.500'}}
            onClick={() => {
                navigator.clipboard.writeText(props.text); 
                generateToast();
            }}>
            <Heading size='label' mb='0.5em' {...props.titleProps}>
                {props.title}
                <Icon ml='0.5em' 
                    boxSize='12px' 
                    opacity='0.5' 
                    as={MdContentCopy} color='black' w={4} h={4} />
            </Heading>
            <Text>
                {props.text} 
            </Text>
        </Box>
    )
}

function Slides(props) {

    const [slides, setSlides] = useState(props.slides);
    const [index, setIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [showList, setShowList] = useState(true);
    const toast = useToast();


    return (
        <>
            <Wrap spacing={4} display='none'>
                <Button size='xs' colorScheme='blue' variant='outline' onClick={() => {setShow(true);}}>View {slides && slides.length} Slides</Button>
                <Button size='xs' colorScheme='blue' variant='outline' onClick={() => {setShowList(!showList);}}>View Slides</Button>
                <IconButton size='xs' colorScheme={showList ? 'blue' : 'gray'} variant='outline' onClick={() => {setShowList(!showList);}} icon={<Icon as={showList ? MdVisibilityOff : MdVisibility} boxSize="12px" />} />
            </Wrap>
            {showList &&
                <Box style={{maxHeight: '130px', overflowX: 'scroll'}} overflowY='hidden' mb='12px' pt='8px' pb='18px' pr='48px'>
                    <Flex pr='96px'>
                        
                        <Box bg='linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)' w='24px' h='130px' position='absolute' right='12px' zIndex='1' />
                    
                        
                        {slides && slides.map((slide, index) => {

                            let copyText = slide.title + '\n\n' + slide.content;

                            return (
                                <Box p='4px' borderRadius='4px' minW='160px' maxW='160px' minH='96px' maxH='96px' bg='whitesmoke' mr='8px' boxShadow='sm' overflow='hidden'
                                    border='1px solid whitesmoke'
                                    _hover={{bg: 'gray.100', cursor: 'pointer', borderColor: 'blue.500'}}
                                    //onClick={() => {setIndex(index); setShow(true);}} cursor='pointer' _hover={{bg: 'gray.100'}}
                                    // onClick copy to clipboard
                                    onClick={() => {
                                        navigator.clipboard.writeText(copyText);
                                        toast({
                                            title: "Copied to clipboard",
                                            status: "success",
                                            duration: 3000,
                                            isClosable: true,
                                        })
                                    }}
                                    >
                                    <Text fontWeight='bold' fontSize='0.75vw' mb='2px'>{slide.title}</Text>
                                    <List fontSize='0.5vw'>
                                        {slide.content.split('\n').map((el) => {
                                            return (
                                                <ListItem>
                                                    {el}
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Box>
                            )
                        })}

                        {!slides &&
                        
                            [0,1,2,3,4].map((el) => {
                                return (

                                    <Box p='4px' borderRadius='4px' minW='160px' maxW='160px' minH='96px' maxH='96px' bg='whitesmoke' mr='8px' boxShadow='sm' overflow='hidden'
                                        border='1px solid whitesmoke'
                                        >
                                        <Text fontWeight='bold' fontSize='0.75vw' mb='2px'>Generating</Text>
                                        <Box mt='-6px'>
                                            <LoadingCircle />
                                        </Box>
                                    </Box>
                                )
                            })
                        
                        }
                    </Flex>
                </Box>
            } 
            {show &&
                <Modal
                    title={slides[index].title}
                    onClose={() => {setShow(false); setIndex(0);}}
                    footer={
                        <ButtonGroup size='sm'>
                            <Button colorScheme='blue' variant='outline' onClick={() => {setIndex(index - 1);}} disabled={index === 0 ? true : false}>Previous</Button>
                            <Button colorScheme='blue' variant='outline' onClick={() => {setIndex(index + 1);}} disabled={index === slides.length - 1 ? true : false}>Next</Button>
                        </ButtonGroup>
                    }
                    >
                        {slides[index].content.split('\n').map((el) => {
                            return (
                                <Text>
                                    {el}
                                </Text>
                            )
                        })}
                </Modal>
            }
        
        </>


    )
}

function DesignBox(props) {

    return (

        <Box className='hover-only' p='2px' borderRadius="lg" overflow="hidden" {...props}>
            <Box position='relative' p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" bg='white'>

                {props.children}

            </Box>
        </Box>


    )
}

function ListWithReflection(props) {

    //console.log(props.title, props.array);

    return (

        <Stack spacing={2}>
            {props.array.map((el) => {
                return (

                    <CopyeableText 
                        title={el.title}
                        titleProps={{size: 'label', mb: '4px', color: 'blue.500'}}
                        text={el.reflection}
                        />

                    )
                }
            )}    
        </Stack>

    )
}

export default function TeachingView(props) {



    //console.log("Viewing teaching", props);

    let required = [
        'Topic',
        'LearningObjectives',
        'LessonContent',
        'Feedback'
    ]

    const navigate = useNavigate();

    // Get uuid from state
    const { state } = useLocation();
    //console.log("props", state);
    const uuid = state.uuid;

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [message, setMessage] = useState('');
    const [tries, setTries] = useState(0);

    function update() {

        //console.log("Updating teaching session");

        setLoading(true);
        
        let url = 'ai';
        let config = {
            params: {
                uuid: uuid
            }
        };

        let response = API.getItems({url, config})
        .then((response) => {

            //console.log(response.data);
            setData(response.data.json);;


            let refresh = false;
            // Loop through requirements and check if object exists in json
            required.forEach((requirement) => {

                if (!response.data.json[requirement]) {
                    console.log("Could not find:", requirement);
                    refresh = true;
                }

            });

            if (response.data.json.LessonContent) {
                response.data.json.LessonContent.forEach((lesson) => {
                    if (!lesson.slides) {
                        console.log("Could not find slides for:", lesson.title);
                        refresh = true;
                    }
                });
            }

            // Check creation date is less than 5 mins ago
            let creationDate = new Date(response.data.createdAt);
            let now = new Date();
            let diff = now - creationDate;
            //console.log("diff", diff);
            if (diff > 300000) {
                //console.log("Teaching session is older than 5 mins");
                if (refresh) {
                    setMessage("Teaching session is older than 5 mins and is missing some data. Please restart.");
                }
                refresh = false;

            }

            if (refresh) {

                // Trigger update in 5 secs
                setTimeout(() => {
                    update();
                }, 5000);

            };

            setLoading(false);
            setLoadedOnce(true);

        })
        .catch((error) => {
            console.log(error);
            if (tries < 3) {
                setTries(tries + 1);

                // Trigger update in 5 secs
                setTimeout(() => {
                    update();
                }, 3000);

            }
            setLoading(false);
        })
    }

    // Use API to get AI data
    useEffect(() => {
        update();
    }, []);

    return (

        <>

        {loading && !loadedOnce &&
            
            <Loading 
                message='Generating a teaching session'
                />
        
        }

        {data &&
        
            <Layout
                title='Teaching Session'
                actionContent={
                    <>
                        <Button variant='outline' colorScheme='blue' onClick={() => {navigate('/my/ai/teaching')}}>Return</Button>
                        <Button display='none' variant='outline' colorScheme='red' onClick={() => {update();}}>Refresh</Button>
                    </>
                }
                >

                <GridItem colSpan={6} h='0' pb='72px'>

                    <Stack>

                        <Heading size='smalltitle' mb='16px'>
                            {data.Topic}
                        </Heading>
                        {message &&
                            <Text color='red.500' mb='16px'>
                                {message}
                            </Text>
                        }
                        <Wrap spacing={2} pb='24px'>
                                    {data.tags && data.tags.map((tag, index) => {
                                        return (
                                            <Tag size='sm' variant='outline' colorScheme='blue' key={tag.key+tag.value}>
                                                <Text mr='4px' as='span' color='blue.500' fontWeight='bold'>{tag.key + ": "}</Text>{" " + tag.value}
                                            </Tag>
                                        )
                                    })}
                                    
                                    {data.keywords && data.keywords.map((keyword, index) => {
                                        let found = false;
                                        if (JSON.stringify(data).includes(keyword)) {
                                            found = true;
                                        }
                                        return (
                                            <Tag size='sm' variant='outline' colorScheme={found ? 'green' : 'red'} key={keyword}>
                                                <TagLeftIcon boxSize="12px" as={found ? MdCheck : MdClose} />
                                                {keyword}
                                            </Tag>
                                        )
                                    })}
                            </Wrap>
                    
                    </Stack>


                    <SimpleGrid columns={{base: 1, md: 6}} spacing={4} pb='72px'>
                        <GridItem colSpan={{base: 1, md: 2}} >
                            <DesignBox>
                                <Stack spacing={4}>

                                    <Heading size='label' mb='0.5em'>
                                        Learning Objectives
                                    </Heading>

                                    {!data.LearningObjectives &&    
                                        <LoadingCircle />
                                    }

                                    <OrderedList pl='1em'>
                                        {data.LearningObjectives && data.LearningObjectives.map((el) => {
                                            return (
                                                <ListItem>
                                                    {el}
                                                </ListItem>
                                            )
                                        })}
                                    </OrderedList>
                                    

                                </Stack>
                            </DesignBox>
                        </GridItem>

                        <GridItem colSpan={{base: 1, md: 4}}  pb='72px'>

                            <Stack spacing={4}>

                                <DesignBox>
                                    <Stack spacing={4}>
                                        <Heading size='label' mb='0.5em'>
                                            Lesson Plan
                                        </Heading>

                                        {!data.LessonContent &&
                                            <LoadingCircle />
                                        }

                                        {data.LessonContent && data.LessonContent.map((el, index) => {

                                            let slideCount = 0;
                                            if (el.slides) {
                                                slideCount = el.slides.length;
                                            }

                                            return (
                                                <Box key={el.title + "-" + slideCount} borderTop={index != 0 ? 'whitesmoke 1px solid' : 'none'}>
                                                    <Heading size='label' mb='0.5em'>
                                                        {el.title} {el.duration && <Tag size='sm' ml={2} color='gray.500'>{convertMinutesToString(el.duration)}</Tag>}
                                                    </Heading>
                                                    <Text>
                                                        {el.description}
                                                    </Text>
                                                    <Wrap spacing={2} mt={4}>
                                                    {el.teachingMethod && el.teachingMethod.map((method) => {
                                                        return (
                                                            <Tag size='sm' variant='outline' colorScheme='blue' key={method}>
                                                                {method}
                                                            </Tag>
                                                        )}
                                                    )}
                                                    </Wrap>

                                                    {data.Feedback && <Slides slides={el.slides} />}

                                                </Box>
                                            )

                                        })}

                                    </Stack>
                                </DesignBox>

                                <DesignBox>
                                    <Stack spacing={4}>
                                        <Heading size='label' mb='0.5em'>
                                            Assessment Methods
                                        </Heading>

                                        {!data.AssessmentMethods &&
                                            <LoadingCircle />
                                        }

                                        <OrderedList pl='1em'>
                                            {data.AssessmentMethods && data.AssessmentMethods.map((el) => {
                                                return (
                                                    <ListItem>
                                                        <Text as='span' fontWeight='bold'>{el.title + ": "}</Text>
                                                        {el.description}
                                                    </ListItem>
                                                )
                                            })}
                                        </OrderedList>
                                    </Stack>
                                </DesignBox>

                            <Box display='none'>
                                <DesignBox>
                                    <Stack spacing={4}>
                                        <Heading size='label' mb='0.5em'>
                                            Quiz
                                        </Heading>

                                        {!data.Quiz &&
                                            <LoadingCircle />
                                        }

                                        {data.Quiz && data.Quiz.map((el) => {
                                                
                                                return (
                                                    <Box>
                                                        <Heading size='label' mb='0.5em'>
                                                            {el.question}
                                                        </Heading>
                                                        
                                                        <Wrap spacing={2} mt={4}>
                                                            {el.answers && el.answers.map((answer) => {
                                                                return (
                                                                    <Tag size='sm' variant='outline' colorScheme='blue' key={answer}>
                                                                        {answer.correct && <Icon as={MdCheck} boxSize="12px" />}
                                                                        {answer.text}
                                                                    </Tag>
                                                                )}
                                                            )}
                                                        </Wrap>
                                                    </Box>
                                                )
                                        })}

                                    </Stack>

                                </DesignBox>  
                                </Box>
                                <DesignBox>
                                <Stack spacing={4}>
                                        <Heading size='label' mb='0.5em'>
                                            Feedback
                                        </Heading>

                                        {!data.Feedback &&
                                            <LoadingCircle />
                                        }
                                        {data.Feedback && data.Feedback.map((el) => {
                                                
                                                return (
                                                    <Box pb={4}>
                                                        <Heading size='label' pb={4}>
                                                            {el.question}
                                                        </Heading>
                                                        
                                                        {el.rating ?

                                                            <RadioGroup >
                                                                <Stack direction='row'>
                                                                {['Very Poor', 'Poor', 'Average', 'Good', 'Very Good'].map((rating, index) => {
                                                                    return (
                                                                        <Radio value={index}>{rating}</Radio>
                                                                    )}
                                                                )}
                                                            </Stack>
                                                            </RadioGroup>
                                                        :
                                                            <Input />
                                                        }
                                                    </Box>
                                                )
                                        })}

                                    </Stack>
                                </DesignBox>
                            
                            </Stack>

                        </GridItem>

                    </SimpleGrid>

                </GridItem>


            </Layout>                            

        
        
        
        
        }

            

        </>

    )
}
