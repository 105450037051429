
import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Card from '../../core/components/cards/Card';
import { H } from 'highlight.run';
import Modal from '../../core/components/modal/Modal';


function ReportModal(props) {

    let report = props.report;

    // Loop through report.data array and get all keys
    let keys = [];
    if (report.data && report.data.length > 0) {
        // Loop through all items in the data array
        report.data.map((item) => {
            // Loop through all keys in the item
            for (let key in item) {
                keys.push(key);
            }
        });
    }
    // Remove duplicates
    keys = [...new Set(keys)];

    return (

        <Modal
            title={report.title}
            onClose={props.onClose}
            maxW='800px'
            >
            <Table>
                <Thead>
                    <Tr>
                        {keys.map((key) => {
                            if (key === 'accountUuid') {
                                return null;
                            }
                            return (
                                <Th>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                </Th>
                            )
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {report.data.map((item) => {


                        return (
                            <Tr>
                                {keys.map((key) => {

                                    // return early if accountUuid
                                    if (key === 'accountUuid') {
                                        return null;
                                    }

                                    if (key === 'message'
                                        // And check if there appears to be JSON in the message
                                        && item[key].includes('{')  && item[key].includes('}')
                                    ) {

                                        // The message may have a pre and post amble
                                        // So we need to remove that
                                        let message = item[key];
                                        let pre = message.indexOf('{');
                                        let post = message.lastIndexOf('}');
                                        message = message.slice(pre, post+1);

                                        // Parse the JSON
                                        let json = JSON.parse(message);

                                        return (
                                            <Td>
                                                <Text color='strong'>
                                                    {item[key].substring(0, pre)} 
                                                </Text>
                                                <Box maxW='350px' overflow='scroll'>
                                                    <pre>
                                                        {JSON.stringify(json, null, 2)}
                                                    </pre>
                                                </Box>
                                                <Text color='strong'>
                                                    {item[key].substring(post+1)}
                                                </Text>
                                            </Td>
                                        )


                                    }



                                    return (
                                        <Td>
                                            <Text 
                                                color={key === 'status' ? (item[key] === 'Success' ? 'green.500' : 
                                                item[key] === 'Error' ? 'red.500' : 'blue.500') : 'strong'}
                                                >
                                                {item[key]}
                                            </Text>
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

        </Modal>

    )
}



export default function Job(props) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [reports, setReports] = useState([]);
    const [showReport, setShowReport] = useState(null);

    useEffect(() => {
        setLoading(true);
        API.getItems({url: 'organisations/reports'}).then((response) => {
            console.log(response.data);
            // Set the reports
            // But order by createdAt, with recent first
            let tmpReports = response.data.reports;
            tmpReports.sort((a, b) => {
                return moment(b.createdAt) - moment(a.createdAt);
            });
            setReports(tmpReports);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setError(error);
        })
    }, []);


    return (
        <Layout
            title='Reports'
            layout='dense'
            subtitle='This is a list of all reports'
            actionContent={<></>
            }
            >


            <GridItem colSpan={12}>
                
                {loading && <Loading />}

                {showReport !== null &&
                
                    <ReportModal maxW='unset' report={showReport} onClose={() => setShowReport(null)} />
                }

                {error && <Alert status='error'>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }


                <Header c={6}
                    buttons="hide"
                    >
                    <Cell c={2}>
                        Title
                    </Cell>
                    <Cell>
                        Model
                    </Cell>
                    <Cell>
                        Create
                    </Cell>
                    <Cell>
                        Outcomes
                    </Cell>
                    <Cell>
                        Actions
                    </Cell>
                </Header>

                {!loading && reports && reports.map((report) => {

                    // Count data statuses
                    let dataStatuses = {
                        'Success': 0,
                    };
                    // Remove 'Success' from the array if 0
                    if (dataStatuses['Success'] === 0) {
                        delete dataStatuses['Success'];
                    }

                    if (report.data && report.data.length > 0) {
                        report.data.map((item) => {
                            if (dataStatuses[item.status]) {
                                dataStatuses[item.status] += 1;
                            } else {
                                dataStatuses[item.status] = 1;
                            }
                        });
                    }


                    return (
                        <Row key={report.uuid} c={6} buttons="hide">
                            <Cell c={2}>
                                <Text color='strong'>{report.title}</Text>
                                <Text color='subtle'>{report.description}</Text>
                            </Cell>
                            <Cell>
                                {report.model}
                            </Cell>
                            <Cell>
                                <Moment format="HH:mm:ss DD/MM/YY">{report.createdAt}</Moment>
                            </Cell>
                            <Cell>
                                <Wrap>
                                    {report.data && report.data.length > 0 && Object.keys(dataStatuses).map((status) => {
                                        return (
                                            <Tag
                                                size='sm'
                                                variant='outline'
                                                colorScheme={status === 'Success' ? 'green' : 
                                                    status === 'Error' ? 'red' : 'blue'}
                                                >
                                                <TagLabel>{status} ({dataStatuses[status]})</TagLabel>
                                            </Tag>
                                        )
                                    })}
                                </Wrap>
                            </Cell>
                            <Cell>
                                <Button size='xs' colorScheme='blue' variant='outline'
                                    onClick={() => setShowReport(report)}
                                    >
                                    View
                                </Button>
                            </Cell>
                        </Row>
                    )
                })}

            </GridItem>

        </Layout>
    )
}