
import React, { useState, useEffect, useContext } from 'react';
import { UnorderedList, SimpleGrid, Grid, Box, MenuList, MenuItem, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, Menu } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import Navigation from './components/Navigation';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Card from '../../core/components/cards/Card';
import { set } from 'date-fns';
import Sidebar from './components/Sidebar';

import { AITask } from '../../mixed/assistant/Assistant';

      
export default function Job(props) {
    
    // Get state

    // Using useLocation to access location state
    const location = useLocation();
    console.log(location.state);
    // Extracting jobSpecification from location state
    const stateJob = location.state.job;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [job, setJob] = useState(stateJob ? {...stateJob} : {});
    const [scores, setScores] = useState(stateJob ? stateJob.details.jobScoring : []);
    const [jobSpec, setJobSpec] = useState(stateJob ? stateJob.details.jobSpecification : []);
    const [applicants, setApplicants] = useState(stateJob ? stateJob.details.applicants : []);

    return (
        <Layout
            title={job.title ? job.title : 'New posting'}
            layout='dense'
            description={job.location ? job.location : 'Start generating a new job posting below...'}
            actionContent={
                <Navigation job={job} />
            }
            >


            <GridItem colSpan={12}>

                {loading && <Loading />}

                {error && <Alert status='error'>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }

            </GridItem>



            <GridItem colSpan={6}>

                <Card p={0}>

                        <Heading fontSize='xl' mb={1} p={4} lineHeight='1'>Job Specification</Heading>

                <AITask
                    title='Generate Job Description'
                    description='Generate a job description for the job title you enter.'
                    p={4}
                    completed={job.details && job.details.jobSpecification.length > 0 ? true : false}
                    prompt={`
                        <template>
                            [
                                {
                                    "title": "Job Title",
                                    "description": "{role}",
                                },
                                {
                                    "title": "Job Overview",
                                    "description": "...",
                                },
                                {
                                    "title": "Key Responsibilities",
                                    "description": ["...", "..."],
                                },
                                {
                                    "title": "Qualifications and Skills",
                                    "description": ["...", "..."],
                                },
                                {
                                    "title": "Experience",
                                    "description": ["...", "..."],
                                },
                                {
                                    "title": "Working Conditions",
                                    "description": "...",
                                }
                            ]
                        </template>

                        I have provided you a template to fill in with the job description.
                        In your responses do not provide any post or pre-amble, just the JSON array.
                        The job title is "{role}" and it is within the NHS.
                        Each array should have at least 5 options.
                        Return a completed template, without the template tags.`
                    }
                    fields={[
                        {name: 'role', type: 'text', placeholder: 'Job title'},
                    ]}
                    onCompletion={(data) => {

                        let jobSpec = JSON.parse(data);
                        let tmpJob = {
                            "title": jobSpec[0].description,
                            "location": "City General Hospital",
                            "status": "Open",
                            "applicants": 0,
                            "opens": "2024-05-01",
                            "closes": "2024-05-14",
                            "interviewsStart": "2024-05-15",
                            "interviewsEnd": "2024-05-30",
                            "appointee": "",
                            "details": {
                                "requirements": {
                                    "dbs": "None",
                                    "roles": [],
                                    "requirementGroups": []
                                },
                                "jobScoring": [],
                                "applicants": [],
                                "jobSpecification": jobSpec,
                                "applicationScoring": [],
                                "interview": {
                                    "questions": [],
                                    "scoreMatrix": {}
                                }
                            }
                        };
                        console.log(tmpJob);
                        setJob(tmpJob);
                    }}
                    />


                        {job.details && job.details.jobSpecification.map((spec, index) => {
                            
                            return (

                                <Row c={4} buttons='hide'>
                                    <Cell c={4}>
                                        <Text fontWeight='bold'>{spec.title}</Text>
                                        <Stack spacing={2}>
                                            {Array.isArray(spec.description) ?
                                                <UnorderedList pt={2}>
                                                {spec.description.map((item, index) => {
                                                    return (
                                                        <ListItem>{item}</ListItem>
                                                    )
                                                })}
                                                </UnorderedList>
                                                :
                                                <Text>{spec.description}</Text>
                                            }
                                        </Stack>
                                    </Cell>
                                </Row>
                            )
                        })}


                </Card>

            </GridItem>

            <GridItem colSpan={6}>

                <Sidebar job={job} />
                
            </GridItem>



            {job && job.details &&
            <GridItem colSpan={6}>

                <Stack spacing={4}>

                <Card title='Application' p={0}>

                        <Heading fontSize='xl' mb={1} p={4} lineHeight='1'>Scoring Matrix</Heading>

                        <AITask
                            title='Generate Matrix'
                            description='We can automatically generate a scoring matrix for the job.'
                            prompt={`
                                <template>
                                    [
                                        {
                                            "title": "...",
                                            "description": "...",
                                            "score": "1-5|1-10",
                                            "finalScore": "mean|mode|median|sum",
                                            "source": "Application|CV|Interview"
                                        }
                                    ]
                                </template>

                                In your response do not provide any post or pre-amble, just the JSON array.
                                I have provided you a template to fill in a scoring matrix for a job application.
                                The job title is ` + job.details.jobSpecification[0].description + ` and it is within the NHS.
                                There should be at least 5 scoring criteria in the array.
                                Return a completed template, without the template tags.`
                            }
                            onCompletion={(data) => {
                                let tmpJob = job;
                                tmpJob.details.jobScoring = JSON.parse(data);
                                console.log("SCORES", tmpJob);
                                setJob(tmpJob);
                                setScores(JSON.parse(data));
                            }}
                            px={4}
                            type='json'
                            completed={job.details.jobScoring.length > 0 ? true : false}
                            borderTop='1px solid whitesmoke'
                            />

                        {scores.map((score, index) => {

                            return (

                                <Row c={5} buttons='hide'>
                                    <Cell c={4}>
                                        <Text fontWeight='bold'>{score.title}</Text>
                                        <Text>{score.description}</Text>
                                        <Text><Text as='span' fontWeight='600'>Score: </Text>{score.score + " (" + score.finalScore + ")"}</Text>

                                    </Cell>
                                    <Cell>
                                        <Button size='xs' variant='outline' colorScheme='blue'>{score.source}</Button>
                                    </Cell>
                                </Row>
                            )
                        })}


                </Card>



                </Stack>

            </GridItem>
            }



        </Layout>
    )
}