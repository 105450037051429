import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

import { Heading, VStack, Checkbox, WrapItem, useDisclosure, Collapse,  Divider, SkeletonText, Grid, GridItem, Flex, Button, List, ListItem, Badge, Wrap, Box, Stack, Text } from '@chakra-ui/react';
import { MdExpandLess, MdExpandMore} from "react-icons/md";
import CustomListItem from './CustomListItem';
import Layout from '../../core/layout/Layout';

import pluralize from 'pluralize';

import {RequirementTagDisplayRecord} from '../../requirements/pages/RequirementTagDisplayRecord';
import API from '../../core/utils/API';
import Error from '../utils/Error';
import AutoForm from './AutoForm';

function ToggleElement(props) {

    const { isOpen, onToggle } = useDisclosure();

    const entries = Object.entries(props.data);
    const listItems = entries.map(([key, value]) => (
      <ListItem key={props.title + "." + key}>
        {key}: {JSON.stringify(value)}
      </ListItem>
    ));

    return (
        
        <>
            <GridItem p={1} whiteSpace='nowrap' pr={2}>{props.title}</GridItem>
            <GridItem>
                <List mt={2} ml={0}>
                    <Button size='xs' onClick={onToggle}>
                        {!isOpen ? 'Show' : 'Hide'} {props.title}
                    </Button>
                    <Collapse in={isOpen} animateOpacity>
                        <List ml={5}>{listItems}</List>
                    </Collapse>
                </List>
            </GridItem>
        </>
    );
  }


function DisplayRecord(props) {



    const { isOpen, onToggle } = useDisclosure()
  

    const entries = Object.entries(props.data);



    const listItems = entries.map(([key, value]) => (
        <>
            { ((typeof(props.data[key])) === 'object' && props.data[key] !== null )  ? 
                                
                <ToggleElement title={key} data={props.data[key]}/>
                :

                <>    
                    <GridItem p={1} pl={0} whiteSpace='nowrap' color='emphasized' pr={2}>{key}</GridItem>
                    <GridItem p={1} width='max-content' whiteSpace='pre-line' >
                        <Text noOfLines={1} _hover={{ noOfLines: 'unset' }}>
                            {props.data[key]}
                        </Text>
                    </GridItem>
                </> 
                     
                     
            }
        </>
    ));

    const deleteMe = async () => {

        console.log("Deleting", props.data.uuid);

        const response = await API.deleteItems({url: props.model + '/delete/' + props.data.uuid});

        if (response.status === 200) {
            props.refresh();
        } else {
            alert('Error'); // TODO - add error toast
        }
        
    }


    return (
        <Stack>
            <Flex>
                <Box w='40px'>
                    <Button onClick={onToggle} borderRadius='50%' p='4px' width='20px' height='20px' minWidth='20px'>
                        {isOpen ? <MdExpandLess /> : < MdExpandMore/>}
                    </Button>
                </Box>
                <Box w='-webkit-fill-available'>
                    <Flex justifyContent='space-between'>
                        <Box>
                            <Text color='emphasized' fontSize='md' data-filter-by={props.data[props.filterBy]}>
                                {props.setTitle.map((item) => (
                                    props.data[item] + " "
                                ))}
                            </Text>
                            {props.data[props.filterBy] !== null &&
                            <Badge size='xs' mr={2} >
                                {props.data[props.filterBy]}
                            </Badge>
                            }
                        </Box>
                        <Box>
                            <Text fontSize='xs' color="subtle">Created: <Moment date={props.data["createdAt"]} format='hh:mm DD/MMM/YYYY' /></Text>
                            <Text fontSize='xs' color="subtle">Updated: <Moment date={props.data["updatedAt"]} format='hh:mm DD/MMM/YYYY' /></Text>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            <Box>
                <Collapse in={isOpen} animateOpacity >
                    <Grid templateColumns='repeat(2, 1fr)' fontSize='sm' pl='40px' pt={2} width='min-content' overflow='hidden'> 
                        <GridItem p={1} pl={0} color='emphasized'>Key</GridItem>
                        <GridItem p={1} width='maxContent' >Value</GridItem>
                        {listItems}
                    </Grid>
                </Collapse>
                <Button colorScheme='red' variant='outline' className='btn-8' onClick={()=>{deleteMe();}}>
                    Delete
                </Button>
            </Box>
        </Stack>
    )

}



export default function ModelList (props) {

    //const [filters, setFilters] = React.useState('');

    const [originalData, setOriginalData] = React.useState([]);
    const [records, setRecords] = React.useState('');  
    const [savedFilters, setSavedFilters] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);


    let filters = {};

    props.filterBy.forEach((item) => {

        filters[item] = {};
        
    });

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [loadedOnce, setLoadedOnce] = React.useState(false);

    let TempDisplayRecord = DisplayRecord;
    if (props.customDisplayRecord != undefined) {
        console.log("Using custom display record");
        TempDisplayRecord = props.customDisplayRecord;
    }

    const getData = async () => {

        try {

            setLoading(true);

            const response = await API.getItems({url: props.url});
            //console.log(response);
            
            const a = (response.data[props.dataKey]);
            const d = Array.from(a);
            console.log("ShowModel Data", d);
            console.log(d);

            // Get filters from records
            d.forEach((record) => {
                props.filterBy.forEach((filterColumn) => {
                    if (record[filterColumn] !== null ) {
                        filters[filterColumn][record[filterColumn]] = false;
                    }
                });
            });
            setLoadedOnce(true);

            setOriginalData(d);
            setRecords(d);
            setSavedFilters(filters);
            setLoading(false);

            console.log("Dinished loading");

            return;


        } catch (error) {
            console.log(error);
            console.log(records);
            setError(error);
            setLoading(false);
            return;
        }
    }

    useEffect(() => {
        getData();
    }, []);


    function filterRecord(record) {

        let showRecord = false;
        let filterCount = 0;

        props.filterBy.forEach((filterColumn) => {

            Object.keys(savedFilters[filterColumn]).forEach((filterItem) => {
        
                if (savedFilters[filterColumn][filterItem] === true) {
                    filterCount += 1;
                    if (record[filterColumn] === filterItem) {
                        showRecord = true;
                    }
                }
            });
        });

        if (showRecord === true || filterCount === 0) {
            return record;
        }

    }

    return (
        <>
            {!loading && loadedOnce &&
                <Layout
                title={props.title}
                description={props.description}
                primaryButtonText={props.primaryButtonText}
                primaryButtonModal={props.primaryButtonModal}
                modalTitle={props.modalTitle}
                modalContent={<AutoForm
                    url={props.autoFormUrl}
                    inputs={props.autoFormInputs}
                    refresh={getData}
                />}
                >

                    


            
                
                    {error && 
                        <Error p={4} message={JSON.stringify(error)}/>
                    }

                    {!loading && loadedOnce &&
                        <GridItem colSpan='12'>



            <VStack spacing={2} alignItems='flex-start' pt={4} pb={4}>
                {props.filterBy.length == 0 ?
                <>
                        {!props.hideCount && 
                            <Badge ml={2} className=''>
                            {loading ? 
                        <SkeletonText noOfLines={1} p={1.5} startColor='white.500' endColor='grey.500'/>
                        :    
                        <>
                            {records.length}
                            {" " + pluralize('record') /* props.dataKey, originalData.length */}
                        </>
                    }                    </Badge>

                }
                </>
                    :
                    <Heading fontSize='sm' lineHeight='1em' as='h6' mb='0' display=''>
                        Filters
                        <Button onClick={() => setIsOpen(!isOpen)} borderRadius='50%' ml={2} p='4px' width='20px' height='20px' minWidth='20px'>{isOpen ? <MdExpandLess /> : <MdExpandMore />}</Button>
                        <Badge ml={2}>
                            {loading ? 
                                <SkeletonText noOfLines={1} p={1.5} startColor='white.500' endColor='grey.500'/>
                                :    
                                <>
                                    {records.length}
                                    {(records.length != originalData.length) &&
                                        <> of {originalData.length}</>
                                    }
                                    {" " + pluralize('record') /* props.dataKey, originalData.length */}
                                </>
                            }
                        </Badge>
                    </Heading> 
                }
                <Collapse in={isOpen} animateOpacity >

                    {savedFilters &&    
                        <> 

                            {Object.keys(savedFilters).map((filter) => (
                                <Wrap>
                                    <WrapItem key={filter} mr={2}>
                                    {filter}:
                                    </WrapItem>
                                    {Object.keys(savedFilters[filter]).map((filterItem) => (
                                        <WrapItem key={filterItem}>
                                            <Checkbox 
                                                isChecked={savedFilters[filter][filterItem]} 
                                                onChange={(e) => {
                                                    const newFilters = { ...savedFilters };
                                                    newFilters[filter][filterItem] = e.target.checked;
                                                    setSavedFilters(newFilters);

                                                    setRecords(originalData.filter(filterRecord));
                                                
                                                }}
                                                >
                                                {filterItem}
                                            </Checkbox>
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            ))}

                        </>
                    }
                </Collapse>

            </VStack>
            
            
                            
                            <CustomListItem
                                custom={props.customDisplayRecord !== undefined}
                                >

                            
                                {!loading && records && records.map((item) => (
                                    
                                        <Box id={item.uuid} >

                                            { props.customDisplayRecord !== undefined ? 

                                                /*React.cloneElement(this.props.customDisplayRecord, {data: "hello"})*/
                                                /*<RequirementTagDisplayRecord />*/
                                                React.cloneElement(props.customDisplayRecord, {
                                                    data: item, 
                                                    refresh: getData
                                                }) 

                                                :
                                                <TempDisplayRecord 
                                                    data={item} 
                                                    filterBy={props.filterBy} 
                                                    setTitle={props.setTitle}
                                                    refresh={getData}
                                                    model={props.dataKey}
                                                    />
                                            }
                                        </Box>
                                    ))}

                        </CustomListItem>

                        </GridItem>
                    }

                </Layout>
            }
        </>
        
    )
    
}
    