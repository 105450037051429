import React, {useState, useEffect, useContext, createContext} from 'react';
import { Outlet } from 'react-router-dom';
import {
  Flex,
  Wrap,
  Button,
  Box,
  useColorModeValue,
  useColorMode,
  Image,
  Spinner,
  Text,
  Stack,
  Center,
  Progress
} from '@chakra-ui/react';
import Splash from '../static/layout/Splash';
import API from '../core/utils/API';
import Loading from "../static/layout/Loading";

import { KyberEncryptionContext } from './KyberEncryption';
import { AccountContext } from '../auth/cognito/components/Accounts';
import { sub } from 'date-fns';

export const SubscriptionContext = createContext();




function translateSubscriptionId(priceid) {

  return process.env['REACT_APP_SUBSCRIPTION_NAME_' + priceid] ? process.env['REACT_APP_SUBSCRIPTION_NAME_' + priceid] : priceid;
  
}


function listSubscriptions(subs) {

  let translatedSubscriptions = [];
    subs.forEach(function (sub) {
      //console.log('sub', sub);
      if (sub === 'admin') {
        translatedSubscriptions.push('admin');
      } else {
        translatedSubscriptions.push(translateSubscriptionId(sub));
      }
    }
  )

  return translatedSubscriptions.join(', ');

}

export const SubscriptionWrapper = (props) => { 

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionIds, setSubscriptionIds] = useState(null);
  const [globalTrial, setGlobalTrial] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(null);


  const { getSession } = useContext(AccountContext);

  let session = getSession()
  .then((session) => {
    //console.log('SubscriptionWrapper session', session);
    let email = null;
    if (session && session.idToken && session.idToken.payload) {
      email = session.idToken.payload.email;
      setEmail(email);
    }
    return email;
  }).catch((error) => {
    console.log('SubscriptionContext error', error);
    return null;
  });

  useEffect(() => {

    //console.log('Subscription Context');
    API.getItems({
      url: 'users/getSubscriptions',
    }).then((response) => {

      //console.log('Subscription Context response', response);

      let tmpData = {};
      let tmpSubscriptionIds = [];

      if (!response.data) {
        console.log("SubscriptionContext: No response data");
        return;
      }

      if (response.data.subscriptions.length > 0) {

        // get just priceId from subscriptions
        response.data.subscriptions.map((subscription) => {
          //console.log('subscription', subscription);
          tmpSubscriptionIds.push(subscription.product_id);
        })

      }

      if (response.data.globalTrial) {
        tmpData.globalTrial = response.data.globalTrial;
      } else {
        tmpData.globalTrial = false;
      }

      tmpData.subscriptionIds = tmpSubscriptionIds;

      setSubscriptionIds(tmpSubscriptionIds);
      setGlobalTrial(tmpData.globalTrial);

      //console.log('Subscription Context tmpData', tmpData);

    }).catch((error) => {
      setData({});
      console.log('error', error);
    })

  }, []);

  useEffect(() => {
    if (subscriptionIds && email) {
      let tmpData = {};
      let tmpSubscriptionIds = subscriptionIds;
      //console.log('Subscription Context subscriptionIds', subscriptionIds);

      // check if email ends with @pyrexia.io
      let domain = "@pyrexia.io";
      if (email.substr(email.length - domain.length) === '@pyrexia.io') {
        tmpSubscriptionIds.push('admin');
      }
      tmpData.subscriptionIds = tmpSubscriptionIds;
      tmpData.globalTrial = globalTrial;
      tmpData.email = email;
      //console.log('Subscription Context tmpData', tmpData);
      setData({subscriptionIds: tmpSubscriptionIds, globalTrial: globalTrial, email: email});
    }
    setLoading(false);
  }, [subscriptionIds, email, globalTrial]);

  return (
    <>
      {!loading ?
          <>
            <SubscriptionContext.Provider value={{...data}}>
              <Box display='none'>
                <Text textAlign='right' pr={8} maxW='7xl' m='auto'>
                  {globalTrial ? 'Your free trial is active. ' : ''}
                  {subscriptionIds && subscriptionIds.length > 0 ? 'You are currently subscribed to ' + listSubscriptions(subscriptionIds) + '.' : ''}
                </Text>         
              </Box>
              {props.children}

            </SubscriptionContext.Provider>
          </>
        :
          <Splash>     
            <Loading 
              message='Loading your subscriptions...'
              />
          </Splash>
      }
    </>
  )
/*

  return (
    <SubscriptionContext.Provider value={data} globalTrial={globalTrial} subscriptions={subscriptionIds}>
      <Outlet />
    </SubscriptionContext.Provider>
  )
  */
};