import {Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';

export default function Demographics(props) {

    const [disability, setDisability] = useState(null);
    const [language, setLanguage] = useState('English');


    let ethnicities = [
        "Asian British",
        "Indian",
        "Pakistani",
        "Bangladeshi",
        "Chinese",
        "Any other Asian background",
        "Black",
        "Black British",
        "Black Caribbean",
        "Black African",
        "Any other Black, Black British, or Caribbean background",
        "White and Black Caribbean",
        "White and Black African",
        "White and Asian",
        "Any other Mixed or multiple ethnic background",
        "White English",
        "White Welsh",
        "White Scottish",
        "White Northern Irish",
        "White British",
        "White Irish",
        "White Gypsy or Irish Traveller",
        "White Roma",
        "Any other White background",
        "Arab",
        "Any other"
    ];


    return (

        <Stack spacing={4}>

            <InputWrapper
                name='disability'
                type='radio'
                label={props.translate('Do you have a disability?', props.lang)}
                required={true}
                options={{
                    'values': [
                        props.translate('Yes', props.lang),
                        props.translate('No', props.lang)
                    ]
                }}
                setValue={props.setValue}
                onChange={(e) => {
                    props.setValue('disability', e.target.value);
                }}
                />
            

            <InputWrapper
                name='ethnicity'
                type='select'
                label={props.translate('What is your ethnicity?', props.lang)}
                required={true}
                // Use UK options for ethnicity
                /*
                We need the following options:
Asian British
Indian
Pakistani
Bangladeshi
Chinese
Any other Asian background
Black
Black British
Black Caribbean
Black African
Any other Black, Black British, or Caribbean background
White and Black Caribbean
White and Black African
White and Asian
Any other Mixed or multiple ethnic background
White English
White Welsh
White Scottish
White Northern Irish 
White British
White Irish
White Gypsy or Irish Traveller
White Roma
Any other White background
Arab
Any other ethnic group
*/
                options={ethnicities.map((ethnicity) => {
                    return {
                        value: ethnicity,
                        label: props.translate(ethnicity, props.lang)
                    }
                })
                }
                    
                setValue={props.setValue}
                />

            
                <InputWrapper
                    name='interpreter'
                    type='radio'
                    label={props.translate('Do you need an interpreter?', props.lang)}
                    required={true}
                    options={
                        [
                            {
                                value: 'Yes',
                                label: props.translate('Yes', props.lang)
                            },
                            {
                                value: 'No',
                                label: props.translate('No', props.lang)
                            }
                        ]
                    }
                    setValue={props.setValue}
                    />

        </Stack>

    )

}