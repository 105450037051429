
import DocumentRow from './DocumentRow';
import Card from '../components/cards/Card';
import {
    AvatarGroup,
    Avatar,
    Box,
    Button,
    Divider,
    Image,
    Heading,
    HStack,
    Icon,
    IconButton,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
    useColorModeValue,
    ButtonGroup,
    Wrap,
    Badge,
    Table, 
    Tr, 
    Td,
    Th,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Grid, GridItem,
  } from '@chakra-ui/react'
  import PDFIcon from './pdf.jpg';


export default function DocumentList(props) {

    let documents = props.documents;


    return (
        <Stack w='100%' spacing={0} key={'document-stack-' + props.requirementGroupUuid}>

          {documents.map((document) => (
            <DocumentRow mode={props.mode} refresh={props.refresh} document={document} requirementGroupUuid={props.requirementGroupUuid} staleTime={props.staleTime}/>
          ))}

          {documents.length == 0 &&
            <Box p={4} colSpan={4} key={'no-documents-' + props.requirementGroupUuid}>
              <Wrap>
                <Image src={PDFIcon} alt='PDF Icon' w='18px' display='none' key='no-document-icon'/>
                <Text fontSize='sm' lineHeight='18px' m={0} key='no-document-text'>
                  No documents attached
                </Text>
              </Wrap>
            </Box>
          }


          {props.footer}

        </Stack>


    )
}