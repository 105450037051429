"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require("./");
var baseStyle = function () {
    return {
        control: {
            bg: 'unset',
            borderColor: 'accent',
            _hover: {
                bg: 'unset',
                borderColor: 'ui',
                _disabled: {
                    borderColor: 'disabled',
                },
            },
            _checked: {
                bg: 'white',
                color: 'accent',
                borderColor: 'accent',
                _hover: {
                    bg: 'unset',
                    color: 'accent-emphasis',
                    borderColor: 'accent-emphasis',
                },
                _disabled: {
                    bg: 'unset',
                    color: 'disabled',
                    borderColor: 'disabled',
                    _hover: {
                        borderColor: 'disabled',
                    },
                },
            },
            _focus: {
                boxShadow: 'none',
            },
            _disabled: {
                bg: 'unset',
                cursor: 'not-allowed',
                borderColor: 'disabled',
                _hover: {
                    borderColor: 'disabled',
                },
            },
            label: _1.Checkbox.baseStyle.label,
        },
    };
};
var sizes = {
    md: {
        control: {
            width: 5,
            height: 5,
            _checked: {
                _before: {
                    w: 2.5,
                    h: 2.5,
                },
            },
        },
        label: { fontSize: 'sm' },
    },
    lg: {
        control: {
            width: 6,
            height: 6,
            _checked: {
                _before: {
                    w: 3.5,
                    h: 3.5,
                },
            },
        },
        Label: { fontSize: 'md' },
    },
};
exports.default = {
    baseStyle: baseStyle,
    sizes: sizes,
};
