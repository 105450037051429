
import React, { useState, useEffect, useRef} from 'react';
import { useColorModeValue, GridItem, HStack, Tag, IconButton, CloseButton, Box, Alert, AlertIcon, AlertTitle, AlertDescription, Stack, Heading, Text, Button, Flex, Wrap, useDisclosure, useToast } from '@chakra-ui/react';
import API from '../../core/utils/API';
import moment from 'moment';
import Modal from '../components/modal/Modal';
import {MdRefresh} from 'react-icons/md';
import { max } from 'date-fns';

export default function DocumentProfess(props) {

    
    let [analysing, setAnalysing] = useState(props.analysing);
    let [maxAnalysing, setMaxAnalysing] = useState(props.maxAnalysing);
    let [loading, setLoading] = useState(true);
    
    const toast = useToast();

    const checkAnalysis = async () => {

        let analysisURL = 'evidences/analysis';
        let analysisResponse = await API.getItems({url: analysisURL});
    
        let newAnalysingArray = [];
        let newStaleArray = [];
        analysisResponse.data['evidences'].map((evidence) => {
          if (evidence.lastTagCheck === null && moment(evidence.createdAt).isAfter(moment().subtract(props.staleTime, 'seconds'))) {
            newAnalysingArray.push(evidence.title);
          } else if (evidence.lastTagCheck === null ) {
            newStaleArray.push(evidence.title);
          }
        });
    
        if (newAnalysingArray.length != analysing.length) {
            //clearInterval(updateProgress);
            props.refreshFunction();
            setAnalysing(analysing);
        }

        if (newAnalysingArray.length === 0) {
            console.log("Cancel");
            //setShowModal(true);
          clearInterval(updateProgress);


            toast({
            title: 'Analysis Complete',
            description: "We've finished analysing your files, please refresh the page to see the latest analysis.",
            status: 'success',
            //duration: 9000,
            isClosable: true,
            render: () => (

                <Alert status='success' onClick={() => refreshPage()}>
                    <AlertIcon />
                    <Box pr={1}>
                        <AlertTitle>Analysis Completed</AlertTitle>
                        <AlertDescription>
                            We've refreshed the page to load the latest information.
                        </AlertDescription>
                    </Box> 
                    <IconButton
                        alignSelf='flex-start'
                        ml={1}
                        mt={1}
                        display='none'
                        position='relative'
                        icon={<MdRefresh />}
                        vartiant='outline'
                        onClick={()=>refreshPage()}
                    />

                </Alert>


            ),
            });
            
            props.refreshFunction();

        }
    
      };

      let updateProgress = useRef(null)
      useEffect(() => {

        if (props.analysing.length === 0) {
            return;
        }
        updateProgress = setInterval(() => {
            checkAnalysis(); 
        }, 3000);
        setLoading(false);
        return () => clearInterval(updateProgress);
        }, []);
    
  function refreshPage(){ 
    //window.location.reload(); 
  }

    return (

        <>
                <>
                    <Card border='8px solid black' ml='-46px' mr='-46px'>
                        <Stack spacing={4}>    
                            <Box>
                                <HStack justifyContent='space-between'>
                                    <Stack marginTop='-10px' pl='24px' key={maxAnalysing - analysing.length} >
                                        <Heading size='smalltitle'>Analysing</Heading>
                                        {analysing.length === 0 ?
                                            <Text>We have finished analysing your files, please refresh the page to see the latest information.</Text>
                                            :
                                            <Text>We are currently analysing {analysing.length} files for you. 
                                            {maxAnalysing != analysing.length &&
                                                <>We've finished analysing {maxAnalysing - analysing.length} files.
                                                </>
                                            }
                                            </Text>
                                        }
                                        {analysing.map((docName) => {
                                            <Tag>{docName}</Tag>
                                        })}
                                    </Stack>
                                    <Box pr='24px'>
                                        { (analysing.length > 0) ?
                                            <>
                                                <div class="loadingio-spinner-pulse-z24frotj9ul" ><div class="ldio-sne5c7spk">
                                                <div></div><div></div><div></div>
                                                </div></div>
                                            </>
                                        :
                                            <Button variant='outline' colorScheme='blue' className='btn-8'  onClick={() => refreshPage()}>
                                            Refresh
                                            </Button>
                                        }
                                    </Box>
                                    <Button onClick={checkAnalysis} display='none'  >Check Progress</Button>
                                </HStack>
                            </Box>
                        </Stack>
                    </Card>
                </>

        </>
    )
}


const Card = (props) => (
    <GridItem
      bg="bg-surface"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      borderRadius="lg"
      rowSpan={1}
      colSpan={2}
      p={4}
      {...props}
    />
  )