import React from 'react';
import {useState, useEffect} from 'react';
import { FormProvider, useForm } from "react-hook-form";

import {Box, Text, Divider, HStack, Heading, FormErrorMessage, Wrap, Flex, Textarea, Stack, Input ,InputRightElement, InputGroup, Button, FormControl, FormLabel, FormHelperText, Checkbox, Radio, RadioGroup } from '@chakra-ui/react';

import Association from '../../core/components/form_elements/Association';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Select from 'react-select';
import API from '../../core/utils/API';
import CreatableSelect from 'react-select/creatable';

import InputWrapper from '../../core/components/forms/inputs/InputWrapper';

export default function AttributesForm(props) {


      const methods = useForm();
      const { 
        handleSubmit,
        register,
        watch,
        setValue,
        getValues,
        control,
        formState: { errors, isSubmitting }, } = methods;
  

      const [attribute, setAttribute] = useState(props.attribute);
      if (attribute !== undefined && getValues("type") === undefined ){
        console.log('Defining type');
        setValue('type', attribute.type);
        setValue('options', JSON.parse(attribute.options));
      }
      const [showAdvanced, setShowAdvanced] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formMessage, setFormMessage] = useState(null);
    const [error, setError] = useState(null);
      let tmpOptions = [];
      const type = watch('type');
      const options = watch('options');
      const question = watch('question');

      if (question === undefined && props.attribute !== undefined) {
        setValue('question', props.attribute.question);
      }
      if (type === undefined && props.type !== undefined) {
        setValue('type', props.attribute.type);
      }
      if (type === undefined) {
        setValue('type', 'input');
      }
    useEffect(() => {
        setLoading(false);
    }, [attribute]);


    const reset = () => {

    }


    const updateOptions = (name, value) => {

        let current = getValues('options');


        if (current === undefined) {
            current = {};
        }

        current[name] = value;


        setValue("options", current);

    }

    const onSubmit = () => {

        let data = getValues();
        console.log("DATA", data);

        let url = 'usersparameters'

        if (attribute != undefined) {

            url = url + '/' +attribute.uuid;
            let response = API.patchItems({url, data})
            .then(function (response) {
                console.log(response);
                setFormMessage("Record updated successfully");
                // Reset inputs
                reset(); // This will reset the form
                props.onSubmit(response.data);
            })
            .catch(function (error) {
                console.log(error);
                setFormMessage("Error updating record");
            });
        } else {
            let response = API.postItems({url, data})
            .then(function (response) {
                console.log(response);
            // window.location.reload(false);
                setFormMessage("Record created successfully");
                // Reset inputs
                reset(); // This will reset the form
                //window.location.reload(false);
                props.onSubmit(response.data);

            })
            .catch(function (error) {
                console.log(error);
                //window.location.reload(false);
                setFormMessage("Error creating record");


            });
        }
    }

 
      function optionsCheck() {

        let tmpOptions = getValues('options');
        console.log(tmpOptions);
        if (tmpOptions === undefined || tmpOptions === null) {
            console.log('null3');

            return
        }
        if (tmpOptions.values === undefined || tmpOptions.values === null) {
            console.log('null4');

            return
        }

        console.log("passed");

        
            if (Array.isArray(tmpOptions.values)) {

                let retOptions = [];
                tmpOptions.values.forEach((val, ind) => {

                    retOptions.push({
                        value: val,
                        label: val
                    });
                })
                console.log('RET',retOptions);
                return retOptions;

            } else {

                if (tmpOptions === undefined) {
                    return ;
                }
                let retOptions = [];
                let splitOptions = [...new Set(tmpOptions.values.split(","))];

                splitOptions.forEach((val, ind) => {
                    retOptions.push({
                        value: val,
                        label: val
                    });
                })

                // Remove duplicates                

                return retOptions;

            }
        
      }

      function defaultsCheck() {

        let tmpOptions = getValues('options');
        console.log(tmpOptions);
        if (tmpOptions === undefined || tmpOptions === null) {
            console.log("null");
            return
        }
        if (tmpOptions.defaultValues === undefined || tmpOptions.defaultValues === null) {
            console.log("null2")
            return
        }

        console.log('dv', tmpOptions.defaultValues);

        return tmpOptions.defaultValues;

      }

    function extraOptions(variable) {

        let tmpOptions = getValues('options');
        console.log(tmpOptions);
        if (tmpOptions === undefined || tmpOptions === null) {
            console.log("null");
            return
        }
        if (tmpOptions[variable] === undefined || tmpOptions[variable] === null) {
            console.log("null2")
            return
        }

        console.log('Custom ' + variable, tmpOptions[variable]);

        return tmpOptions[variable]



    }

      console.log(props.attribute);
    return (

        <FormProvider {...methods} > 

        <form onSubmit={handleSubmit(onSubmit)}>

            <Stack spacing={6} mt={4}>
        <Box>
                <FormControl isInvalid={errors.name} display=''>
                    <FormLabel htmlFor='key' mb={0}>Key</FormLabel>
                    <FormHelperText mt={0} mb={2}>This is the unique name for this attribute.</FormHelperText>
                    <Input
                        id='key'
                        placeholder='Key'
                        {...register('key', {
                        required: 'This is required',
                        })}
                        defaultValue={attribute && attribute.key}
                    />
                    <FormErrorMessage>
                        {errors.name && errors.name.message}
                    </FormErrorMessage>
                </FormControl>

            

            <InputWrapper 
                type='textarea'
                name='question'
                required={true}
                error={errors.question}
                onChange={(e) => {
                    console.log("Question udpated");
                    setValue('question', e.target.value);
                    if (document.getElementById('key').value === '') {
                        if (!props.full) {
                            setValue('key', e.target.value.replaceAll(" ", "_"));
                        }
                    }
                }}
                setValue={setValue}
                defaultValue={attribute && attribute.question}
                variant='unstyled'
                color='black'
                fontWeight='600'
                opacity={1}
                placeholder='Add you question here'
                fontSize='lg'
                mt={0}
                p={0}
                mt={2}
                border='none'
                />

            <InputWrapper
                type='textarea'
                name='description'
                placeholder='Add an optional description or explaination here'
                error={errors.description}
                setValue={setValue}
                defaultValue={attribute && attribute.description}
                variant='unstyled'
                border='none'
                color='black'
                mt={0}
                p={0}
                mt={2}
                fontSize='lg'
                />
            
            </Box>

{question !== undefined && question !== null && question !== '' &&

<>

            <InputWrapper
                inputLeftAddon='Answer Type'
                InputLeftAddonStyle='unstyled'
                type='select'
                name='type'
                error={errors.type}
                setValue={setValue}
                required={true}
                placeholder='Answer Format'
                defaultValue={attribute ? attribute.type : 'input'}
                options={[
                        {
                            label: 'Text',
                            value: 'input'
                        },
                        {
                            label: 'Paragraph',
                            value: 'textarea'
                        },
                        {
                            label: 'Radio Button',
                            value: 'radio'
                        },
                        {
                            label: 'Checkboxes',
                            value: 'checkbox'
                        },
                        {
                            label: 'True or False',
                            value: 'boolean'
                        },
                        {
                            label: 'Date',
                            value: 'date'
                        },
                        {
                            label: 'Whole Number (1, 2, 3)',
                            value: 'integer'
                        },
                        {
                            label: 'Decimal (1.23)',
                            value: 'decimal'
                        },
                        {
                            label: 'Slider',
                            value: 'slider'
                        },
                        {
                            label: 'Phone',
                            value: 'phone'
                        },
                        {
                            label: 'E-mail',
                            value: 'email'
                        },
                        {
                            label: 'Address',
                            value: 'address'
                        },
                        {
                            label: 'Date',
                            value: 'date'
                        }
                ]}
            />

            {(type === 'radio' || type === 'checkbox') &&
                
                
                    <InputWrapper
                        type='select-creatable'
                        name='values'
                        label='Options'
                        isMulti
                        description='Create the various options the user can choose from.'
                        placeholder='Options for answer'
                        defaultValue={optionsCheck()}
                        setValue={updateOptions}
                        components={{ Menu:()=> null, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        />


            }

            {type === 'checkboxBLOCK' &&
            
                <InputWrapper   
                    type='input'
                    id='singleoption'
                    info="We recommend using a 'Radio Button' instead as this makes the user answer the question, rather than relying on a default value the user may forget to change."
                    placeholder='Checkbox text'
                    {...register('options', {
                    required: 'This is required',
                    })}
                    onChange={(e) => {
                        let tmpOptions = [];
                        e.target.value.split(",").forEach((val, ind) => {
                            return {key: ind, value: val }
                        });
                        setValue('options', e.target.value.split(",")); 
                        e.preventDefault();
                    }}



                    />
            }

            {showAdvanced &&
                <Box className='advanced-options'>

                    {type === 'radio' &&

                        <>
                            <InputWrapper 
                                type='select'
                                name='defaultValues'
                                placeholder='Default Options'
                                options={optionsCheck()}
                                setValue={updateOptions}
                                defaultValue={defaultsCheck()}
                                />

                            <InputWrapper
                                type='boolean'
                                name='options.other'
                                checkboxLabel='Allow other'
                                setValue={setValue}
                                />

                        </>

                    }

                    {type === 'checkbox' &&

                        <InputWrapper 
                            type='select'
                            name='defaultValues'
                            placeholder='Default Options'
                            options={optionsCheck()}
                            setValue={updateOptions}
                            register={register}
                            control={control}
                            isMulti
                            defaultValue={defaultsCheck}
                            />

                    }

                    {(type === 'integer' || type === 'decimal' || type === 'slider') &&
                    <Stack spacing={4}>
                        <HStack spacing={2}>
                            <InputWrapper   
                            inputLeftAddon='Min'
                            InputLeftAddonStyle='unstyled'
                                type={type}
                                name='min'
                                setValue={updateOptions}
                                defaultValue={extraOptions('min')}
                                />
                            <InputWrapper   
                                type={type}
                                inputLeftAddon='Max'
                                InputLeftAddonStyle='unstyled'
                                name='max'
                                setValue={updateOptions}                        
                                defaultValue={extraOptions('max')}
                                />

                        </HStack>
                        
                        {type !== 'integer' &&
                        <InputWrapper   
                            type='integer'
                            inputLeftAddon='Decimal Places'
                            InputLeftAddonStyle='unstyled'
                            name='precision'
                            setValue={updateOptions}
                            defaultValue={extraOptions('precision')}
                            />
                        }

                        </Stack>
                    }

                    {type === 'email' &&
                    
                        <InputWrapper
                            type='select-creatable'
                            name='values'
                            label='Permitted domains'
                            isMulti
                            description='Only allow e-mails with the following domains'
                            placeholder='All permitted'
                            components={{DropdownIndicator: null,}}
                            defaultValue={optionsCheck()}
                            setValue={setValue}
                            />
                    
                    }
                
                    {type === 'date' &&
                        
                        <HStack>

                            <InputWrapper   
                                type='boolean'
                                name='options.past'
                                checkboxLabel='Allow past dates'
                                setValue={setValue}
                                />
                            <InputWrapper   
                                type='boolean'
                                name='options.future'
                                checkboxLabel='Allow future dates'
                                setValue={setValue}
                                />

                        </HStack>
                    
                    }
                </Box>
            }

            {type !== 'input' && type !== 'textarea' && 
                <Text _hover={{color: 'blue.500'}} cursor='pointer' fontSize='sm' color='subtle' onClick={() => setShowAdvanced(!showAdvanced)}>{showAdvanced ? 'Hide' : 'Show'} advanced options</Text>
            }

            <Button onClick={() => {onSubmit();}} alignSelf='flex-end' colorScheme='blue' variant='outline' mt={4}>
                Submit
            </Button>
            </>
}

            </Stack>

      </form>

</FormProvider>
    )
}