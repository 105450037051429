import { useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Circle, Heading, Flex, Box, Text, Icon,Wrap,  Tag, Avatar, TagLabel, Spinner } from '@chakra-ui/react';
import { MdCheck, MdClose, MdAccessTime, MdLock } from 'react-icons/md';

const handleStyle = { left: 10 };

function Node({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  let colors = {
    'completed': 'green.500',
    'inprogress': 'yellow.500',
    'notstarted': 'red.500',
    'locaked': 'gray.500',
  }

  let icons = {
    'completed': MdCheck,
    'inprogress': MdAccessTime,
    'notstarted': MdClose,
    'locked': MdLock,
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <Box
        border='1px solid #e2e8f0'
        borderBottom='4px solid'
        borderBottomColor={colors[data.status]}
        borderRadius='md' 
        boxShadow='md'
        px={4}
        py={2}
        bg='white'
        pl={8}
        minW='230px'
        // On double click, open the modal
        onDoubleClick={data.onDoubleClick}
        
        >
          {data.status === 'notstarted' ?
          <>
            {data.generating ?
            <Spinner boxSize='14px' position='absolute' left={3} top='14px' />
            :
            <Circle borderRadius='full' size='14px' bg='white' border='2px solid' borderColor={colors[data.status]} position='absolute' left={3} top='14px' />
            
            }
          </>


          :
          <Icon as={icons[data.status]} color={colors[data.status]} mr={2}
            position='absolute'
            left={3}
            top={3}
            />
          }

            <Text>
              {data.label}
            </Text>
            <Flex spacing={2} pt={1}
              flexDirection='row-reverse'
              >  
              {data.completedSteps !== undefined && data.totalSteps !== undefined &&
                <Tag size='xs'
                  px={2} py={1}
                  fontSize='xs'
                  >{data.completedSteps}/{data.totalSteps} completed</Tag>
              }
            </Flex>
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />

    </>
  );
}

export default Node;
