import React, { useState, useEffect, useContext } from 'react';
import { Image, Menu, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import {MdInfo, MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import Modal from '../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import ClientLink from './ClientLink';

import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import e from 'cors';

import FileView from '../../core/components/files/View';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { set } from 'date-fns';

import AccountsUpload from './AccountsUpload';

let statuses = {
    'default': {
        color: 'gray',
        style: 'outline'
    },
    'sent': {
        color: 'green',
        style: 'solid'
    },
    'preparing': {
        color: 'gray',
        style: 'outline'
    },
    'draft': {
        color: 'blue',
        style: 'outline'
    },
    'attached': {
        color: 'green',
        style: 'outline'
    },
}



function Logs(props) {

    const [show, setShow] = useState(false);
    const [logs, setLogs] = useState(
        props.logs.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
        })
    );

    return (

        <>
            
            <MenuItem onClick={() => {setShow(true)}}>
                Logs
            </MenuItem>
        
            {show &&
                <Modal title='Logs' onClose={() => {setShow(false)}}>
                    <Stack spacing={2}>
                        {logs.map((log) => {
                            return (
                                <Box>
                                    <Text>{log.topic}</Text>
                                    <Text color='subtle'>{log.message}</Text>
                                    <Text fontSize='xs' color='subtle'>{moment(log.time).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                </Box>
                            )
                        })}
                    </Stack>
                </Modal>
            }
        </>


    )
}


function InvoiceList(props) {

    let address = [];
    let addressKeys = ['address_buildingnumber', 'address_street', 'address_city', 'address_count', 'address_zip', 'address_country'];
    // Loop through props.account.Client and add to address
    if (props.account.Client) {
        addressKeys.map((key) => {
            if (props.account.Client[key]) {
                address.push(props.account.Client[key]);
            }
        });
    }

    return (

        <Modal 
            title={props.title}
            onClose={props.onClose}
            bodyPadding={0}
            maxW='800px'
            >

            <Flex justify='space-between' p={4} px={6}>

                <Stack spacing={2}>

                    <Stack spacing={0}>
                        <Text color='subtle'>Invoice Number:</Text>
                        <Text>{props.account.sourceInvoiceName}</Text>
                    </Stack>

                    <Stack spacing={2}>
                        <Box>
                            <Text color='subtle'>Issued:</Text>
                            <Text>{moment(props.account.issued).format('DD/MM/YYYY')}</Text>
                        </Box>
                        <Box>
                            <Text color='subtle'>Due:</Text>
                            <Text>{moment(props.account.due).format('DD/MM/YYYY')}</Text>
                        </Box>
                    </Stack>    
                </Stack>

                <Stack spacing={0}>
                    <Text color='subtle'>Customer:</Text>
                    {props.account.Client ?
                        <>
                            <Text>{props.account.company_name}</Text>
                            {address.map((line) => {
                                return (
                                    <Text>{line}</Text>
                                )
                            })}
                            {address.length === 0 && <Text>No Address</Text>}
                            <Text color='subtle' display='none'>Imported from Xero as {props.data.xero.Name}</Text> 
                        </>
                        :
                        <Text>Awaiting details</Text>
                    }
                </Stack>

            </Flex>

            <Table>
                <Tbody>
                    <Tr borderBottom='1px solid black'>
                        <Td color='strong'>Item</Td>
                        <Td color='strong'>Quantity</Td>
                        <Td color='strong'>Total<br/>Cost</Td>
                    </Tr>
                    {props.items.map((item) => {
                        return (
                            <Tr>
                                <Td><Text maxW='400px'
                                    lineBreak='anywhere'
                                    textWrap='wrap'
                                    >{item.Item_Description}</Text></Td>
                                <Td>{item.InvoicedQuantity}</Td>
                                <Td>£{item.LineExtensionAmount}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>


        </Modal>

    )

}

export default function Account(props) {


    const [account, setAccount] = useState(props.account);
    const [clientLinkDetails, setClientLinkDetails] = useState(null);
    const [showFile, setShowFile] = useState(null);
    const [invoiceModal, setInvoiceModal] = useState(null);
    const [actioning, setActioning] = useState(false);

    let client = account.Client;

    const toast = useToast();

    let clientName = 'Awaiting details';
    let clientEmail = 'No Email';
    let clientPhone = 'No Phone';
    if (client) {
        clientEmail = client['contact_email'] ? client['contact_email'] : clientEmail;
        clientName = client['company_name'] ? client['company_name'] : clientName;
        clientPhone = client['contact_phone'] ? client['contact_phone'] : clientPhone;
    }

    // Change account.status to lowercase
    let status = account.status.toLowerCase();

    let statusStyle = statuses[status] ? statuses[status] : statuses['default'];
/*
    function showInvoiceList() {

        let lineItems = account.lines.map((line) => {
            return {
                name: line.Item.Name,
                quantity: line.InvoicedQuantity,
                unitPrice: line.Price.PriceAmount,
                total: line.LineExtensionAmount
            }
        });

        console.log("Line Items", lineItems);
        setInvoiceModal({
            title: sourceTarget.name,
            items: lineItems
        });
    }
*/

    function refresh() {

        console.log("refresh single")
    }

    function currency(amount, currencySymbol = '£') {

        if (amount === undefined || amount === null) {
            return currencySymbol + '0.00 (undefined)';
        }

        // Make sure there are 2 decimal places
        amount = amount.toFixed(2);

        // Add commas to the amount
        amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return currencySymbol + amount;

    }

    // Get the latest log via timestamp
    let latestLog = account.logs.sort((a, b) => {
        return new Date(b.time) - new Date(a.time);
    })[0];



    return (

            <Row c={9}
                buttons='hide'
                key={props.key}
                >

                {invoiceModal && <InvoiceList {...invoiceModal} onClose={() => {setInvoiceModal(null)}} />}

                {clientLinkDetails && <ClientLink {...clientLinkDetails} onClose={() => {setClientLinkDetails(null)}} onSuccess={() => {props.refresh()}} />}

                {showFile !== null && <FileView filename={showFile} onClose={() => {setShowFile(null)}} margin='true'/>}


                <Cell c={2} 

                    >
                    <HStack spacing={2} >
                        {props.multipleSelect ?
                            <Circle size='42px' className='gradient-background'
                                onClick={() => {
                                    props.toggleSelected(account.uuid);
                                }}
                                cursor='pointer'
                                >
                                {props.selected.includes(account.uuid) ?
                                    <Circle size='38px' bg='transparent'>
                                        <Icon as={MdCheck} color='white' boxSize='28px'/>
                                    </Circle>
                                    :
                                    <Circle size='38px' bg='white'>
                                    </Circle>
                                }
                            </Circle>
                            :
                            <Avatar boxSize='42px' name='Xero' />
                        }

                        <Stack spacing={0} pl={1} 
                            cursor='pointer'
                            _hover={{color:'blue.500'}}
                            onClick={() => {
                                if (!account.lines) {
                                    return;
                                }
                                setInvoiceModal({
                                    title: account.sourceInvoiceName,
                                    items: account.lines,
                                    data: account.data,
                                    account: account
                                })
                            }}>
                            <Text
                                // On click open up email with mailto
                                color='strong'
                                mb={0}
                                >Xero</Text>
                            <Text 
                                mt={0}
                                color='subtle'
                                >
                                    {account.lines ? account.lines[0].Item_Description : 'Awaiting details'}
                            </Text>
                            

                        </Stack>
                    </HStack>
                </Cell>

                <Cell>
                    {account.issued &&
                        <Text color='strong'>{moment(account.issued).format('DD/MM/YYYY')}</Text>
                    }
                    {!account.issued &&
                        <Text color='subtle'>Awaiting details</Text>
                    }
                    {account.due &&
                        <Text fontSize='xs'
                            color='subtle' //{moment(account.due).isBefore(moment()) ? 'red.500' : 'subtle'}
                            >
                            Due {moment(account.due).format('DD/MM/YYYY')}
                        </Text>
                    }
                </Cell>


                <Cell c={2}>
                    {!client && (!account.lines || !account.data['xero_contact']) &&
                        <Text color='strong' mb={0} {...(client ? {} : {color:'subtle'})}>
                            {client ? clientName : 'Awaiting linked client'}
                        </Text>
                    }
                        {!client && account.lines && account.data && account.data['xero_contact'] &&
                                <Text color='red.500'
                                    cursor='pointer'
                                    onClick={() => {
                                        setClientLinkDetails({
                                            source: 'xero',
                                            sourceClient: account.data['xero_contact'],
                                            sourceAccount: account,
                                            search: account.data['xero_contact']['Name']
                                        });
                                    }}
                                    >
                                    {account.data['xero_contact']['Name']}
                                </Text>
                            
                        }
                        {client &&
                            <Text color='strong' mb={0}>{clientName}</Text>
                        }
                    <Wrap spacing={1}>
                        {!client && !account.lines &&
                            <></>
                        }
                        {client && account.lines &&
                            <>
                                <Text color='subtle' fontSize='xs' as={clientPhone === 'No Phone' ? 'span' : 'a'} 
                                    href={'tel:' + clientPhone}
                                    isDisabled={!client}
                                    _hover={{color: clientPhone === 'No Phone' ? 'inherit' : 'blue.500'}}
                                    disabled={clientPhone === 'No Phone'}
                                    order={clientPhone === 'No Phone' ? 1 : 0}
                                    >
                                    {client ? clientPhone : 'No Phone'}
                                </Text>
                                <Text color='subtle' fontSize='xs' as={clientEmail === 'No Email' ? 'span' : 'a'}
                                    href={'mailto:' + clientEmail }
                                    isDisabled={!client}
                                    _hover={{color: clientEmail === 'No Email' ? 'inherit' : 'blue.500'}}
                                    disabled={clientEmail === 'No Email'}
                                    order={clientEmail === 'No Email' ? 1 : 0}
                                    >
                                    {client ? clientEmail : 'No Email'}
                                </Text>
                            </>
                        }
                    </Wrap>

                    <Wrap spacing={1}>
                        {account.data && account.data['xero-contact'] && 
                            Object.keys(account.data['xero-contact']).map((key) => {
                                return (
                                    <Text key={key} fontSize='xs' color='subtle'>{key}: {account.data['xero-contact'][key]}</Text>
                                )
                            }
                        )}
                    </Wrap>

                </Cell>

                <Cell>
                    <Wrap spacing={1}>
                        <Button size='xs' colorScheme={!account.fileLocation ? 'red' : 'blue'}
                            variant='outline'
                            onClick={() => {
                                
                                if (!account.fileLocation) {
                                    // display warning dialogue
                                    props.upload();
                                    return;
                                }

                                //setShowFile(account.fileLocation);
                        
                            }}
                            >
                            {account.sourceInvoiceName ? 'SI_' + account.sourceInvoiceName : 'Awaiting details'}
                        </Button>
                        

                        {(account.po || account.xx ) ?
                            <Tag size='sm' variant='outline' colorScheme='blue' ml={2}>
                                {account.po ? account.po : account.xx}
                            </Tag>
                            :
                            <Tag size='sm' colorScheme='yellow' variant='outline' ml={2}>
                                Missing PO/XX
                            </Tag>
                        }

                        {!account.fileLocation && 
                            <Text color='subtle' display='none' fontSize='xs'>Please <Text as='span' onClick={()=>{console.log("UPLOAD")}} color='black' _hover={{color:'blue.500'}}>upload</Text> the invoice</Text>
                        }
                    
                    </Wrap>
                </Cell>

                <Cell>
                    <Text color='strong'>{currency(account.total)}</Text>
                    <Text color='subtle' fontSize='xs'>inc {currency(account.tax)} tax</Text>
                </Cell>

                <Cell>
                    {!account.blocked && !props.multipleSelect && actioning &&
                        <LoadingCircle p={0} h='48px'/>
                    }

                    {!actioning &&
                        <Stack spacing={1}>
                            
                            {!account.blocked && !props.multipleSelect && !actioning &&

                            <Menu>
                                <MenuButton as={Button} size='xs' colorScheme='blue' variant='outline'
                                    rightIcon={<MdExpandMore />}
                                    w='min-content'
                                    >
                                    Actions
                                </MenuButton>
                                <MenuList>

                                    <MenuItem onClick={() => {
                                            setActioning(true);
                                            // Open up the ubl file
                                            let url = 'organisations/accounts/compile-ubl';
                                            let config = {
                                                params: {
                                                    uuid: account.uuid
                                                }
                                            }
                                            let resp = API.getItems({url, config})
                                            .then(response => {

                                                /*
                                                console.log(response);

                                                let fileContents = response.data.ubl;
                                                let fileName = 'Pyrexia_Invoice_SI_' + account.sourceInvoiceName + '.ubl';

                                                // Download filecontents
                                                var element = document.createElement('a');
                                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContents));
                                                element.setAttribute('download', fileName);
                                                element.style.display = 'none';
                                                document.body.appendChild(element);
                                                element.click();
                                                document.body.removeChild(element);
                                                */
                                                //getAccounts();
                                                setActioning(false);
                                                toast({
                                                    title: 'Invoice compiled',
                                                    description: 'The invoice has been compiled',
                                                    status: 'success',
                                                    duration: 3000,
                                                    isClosable: true,
                                                });
                                                props.refresh();
                                            })
                                            .catch(error => {
                                                console.log(error);
                                                setActioning(false);
                                                toast({
                                                    title: 'Error compiling invoice',
                                                    description: 'There was an error compiling the invoice',
                                                    status: 'error',
                                                    duration: 3000,
                                                    isClosable: true,
                                                }); 
                                                props.refresh();
                                            });
                                        }}
                                        >
                                        Compile
                                    </MenuItem>

                                    <MenuItem onClick={() => {
                                        console.log("Send Invoice", account);    
                                        // On click create file from ubl and send to client
                                        console.log("Send Invoice", account);

                                        let fileContents = account.ubl;
                                        let fileName = 'Pyrexia_Invoice_SI_' + account.sourceInvoiceName + '.ubl';

                                        // Download filecontents
                                        var element = document.createElement('a');
                                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContents));
                                        element.setAttribute('download', fileName);
                                        element.style.display = 'none';
                                        document.body.appendChild(element);
                                        element.click();
                                        document.body.removeChild(element);
                                    }}
                                    >Download</MenuItem>



                                    <MenuItem onClick={() => {
                                        setActioning(true);
                                        let url = 'organisations/accounts/draft';
                                        let config = {
                                            params: {
                                                uuid: account.uuid,
                                                draft: true
                                            }
                                        }
                                        let resp = API.postItems({timeout: 30, url, config})
                                        .then(response => {
                                            console.log(response);
                                            setActioning(false);
                                            toast({
                                                title: 'Invoice drafted',
                                                description: 'The invoice has been drafted on Tradeshift',
                                                status: 'success',
                                                duration: 3000,
                                                isClosable: true,
                                            });

                                            props.refresh();

                                        })
                                        .catch(error => {
                                            console.log(error);
                                            setActioning(false);
                                            toast({
                                                title: 'Error drafting invoice',
                                                description: 'Check the logs for more information',
                                                status: 'error',
                                                duration: 3000,
                                                isClosable: true,
                                            });
                                            props.refresh();
                                        });
                                    }}
                                    >Draft</MenuItem>

                                    <MenuItem onClick={() => {
                                        setActioning(true);
                                        let url = 'organisations/accounts/send';
                                        let config = {
                                            params: {
                                                uuid: account.uuid,
                                                draft: false
                                            }
                                        }


                                        // Open a dialogue to check if the user wants to send the invoice
                                        if (window.confirm('Are you sure you want to send the invoice?', 'This will send the invoice to the client via Tradeshift')) {

                                            let resp = API.postItems({timeout: 30, url, config})
                                            .then(response => {
                                                console.log(response);
                                                setActioning(false);
                                                toast({
                                                    title: 'Invoice sent',
                                                    description: 'The invoice has been sent to the client',
                                                    status: 'success',
                                                    duration: 3000,
                                                    isClosable: true,
                                                });
                                                props.refresh();
                                            })
                                            .catch(error => {
                                                console.log(error);
                                                setActioning(false);
                                                toast({
                                                    title: 'Error sending invoice',
                                                    description: 'Check the logs for more information',
                                                    status: 'error',
                                                    duration: 3000,
                                                    isClosable: true,
                                                });
                                                props.refresh();
                                            });

                                        }

                                    }}
                                    >Send</MenuItem>

                                    <Logs logs={account.logs} />

                                </MenuList>
                            </Menu>

                            }

                            <Wrap spacing={1}>
                                <Tag size='sm' 
                                    colorScheme={statusStyle.color}
                                    variant={statusStyle.style}
                                    >
                                    {account.status}
                                </Tag>

                                {account.blocked && 
                                    <Tag size='sm' colorScheme='red' variant='outline'>
                                        Blocked Client
                                    </Tag>
                                }

                                {account.locked &&
                                    <Tag size='sm' colorScheme='red' variant='outline'>
                                        Locked
                                    </Tag>
                                }
                            </Wrap>
                        
                        </Stack>

                        
                    }

                </Cell>

                <Cell>

                    {latestLog &&
                        <>
                            <Text fontSize='xs'>{latestLog.topic}</Text>
                            <Text color='subtle' fontSize='xs'>{latestLog.message}</Text>
                            <Text color='subtle' fontSize='xs'>
                                <Moment fromNow>{latestLog.time}</Moment>
                            </Text>
                        </>
                    }


                </Cell>


            </Row>


    )
}