import React, { useState, useEffect } from 'react';
import {SimpleGrid, Box, Grid, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import WorkSchedule from '../prospectiveleave/WorkSchedule';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {Header, Row, Cell} from '../../core/components/lists/List';

export default function Reflections(props) {

    const [reflections, setReflections] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAdmin , setShowAdmin] = useState(false);
    const navigate = useNavigate();
    const [refreshing, setRefreshing] = useState(false);

    function updateList() {

        let url = 'ai';
        let config = {
            params: {
                model: 'reflection'
            }
        };

        let response = API.getItems({url, config})
        .then(response => {

            console.log("response", response);
            setReflections(response.data);
            setLoading(false);



        })
        .catch(error => {

            console.log("error", error);
            setLoading(false);

        }

        );

    }

    useEffect(() => {

        updateList();

    }, []);

    function viewReflection(uuid) {
        console.log("View Reflection", uuid);
        navigate('/my/ai/reflection/view', {state: {uuid: uuid}});
    }



    return (

        <Layout
            title="Your Reflections"
            description="This is a list of your draft reflections."
            actionContent={<Button variant='outline' colorScheme='blue'  onClick={() => navigate('/my/ai/reflection/generate')}>Generate Reflection</Button>}
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>

                    {loading ? <LoadingCircle /> : null}

                    {reflections &&

                        <Box>

                            <Header c={5}>

                                <Cell c={2}>
                                    Reflection Title
                                </Cell>
                                <Cell c={2}>
                                    Created
                                </Cell>

                            </Header>

                                {Object.keys(reflections).map((key, index) => {
                                    
                                    let reflection = reflections[key];
                                    let json = reflection.json;

                                    if (!json) {
                                        return null;
                                    }

                                    return (


                                        <Row columns={5}
                                            buttons={
                                                <Button onClick={() => viewReflection(reflection.uuid)}>View</Button>
                                            }
                                            >

                                            <Cell c={2}>
                                                <Text color='black' fontWeight='bold'>{json.topic}</Text>
                                            </Cell>
                                            <Cell c={2}>
                                                {moment(reflection.createdAt).format('MM/DD/YYYY')}
                                            </Cell>

                                        </Row>

                                    )

                                })}
                                
                        </Box>

                    }

                </GridItem>

        </Layout>

    )

}



