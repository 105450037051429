import React from 'react';
import { 
    Container, Divider,
    Menu,
    MenuButton,
    Tag, ButtonGroup,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider, Wrap, HStack, Icon, Avatar, Button, Flex, Box, Text, Badge, Stack, Collapse, Grid, GridItem,useColorModeValue, useColorMode, Heading } from "@chakra-ui/react";
  

export default function Design(props) {


    return (
        <Box id='design' className='design'>

            <Heading>Heading</Heading>
            <Text>Text</Text>
            <Button>Button</Button>
            <Avatar>Avatar</Avatar>
            <Badge>Badge</Badge>
            <Tag>Tag</Tag>
            <ButtonGroup isAttached>
                <Button>Button</Button>
                <Button>Button</Button>
            </ButtonGroup>
            <Menu>
                <MenuButton>MenuButton</MenuButton>
                <MenuList>
                    <MenuItem>MenuItem</MenuItem>
                    <MenuItem>MenuItem</MenuItem>
                    <MenuItem>MenuItem</MenuItem>
                </MenuList>

            </Menu>

            <Divider />

            </Box>


    )
}