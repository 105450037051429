import React from 'react';
import { 
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    ButtonGroup, Checkbox, Wrap, Tag, Table, Tbody, Tr, Th, Td, Icon, Circle, Avatar, Stack, HStack, GridItem, Flex, useToast, Box, Text, Heading, Input, Button, List, ListItem, Grid } from '@chakra-ui/react';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import { useState, useEffect } from 'react';
import API from '../../core/utils/API';
import {MdCheck, MdClose, MdList, MdAccessTime} from 'react-icons/md';
import Moment from 'react-moment';
import Splash from '../../static/layout/Splash';
import axios from 'axios';
import Loading from '../../static/layout/LoadingCircle';
import Layout from '../../core/layout/Layout';
import { set } from 'date-fns';
import pluralize from 'pluralize';

import BasicBanner from '../../core/components/banners/BasicBanner';
import Product from './Product';
import secureLocalStorage from 'react-secure-storage';


function Card(props) {
    return (
        <GridItem colSpan={{base: 0, md: 1}} borderRadius='md' boxShadow='md' p={4} mb={4} bg='white'>
            {props.children}
        </GridItem>
    )
}


let productsTemplate = [
    {
        name: 'Invoice Automation',
        description: 'Automate the processing of invoices from your accountancy software to SBS Tradeshift',
        price: 1000,
        price_addendum: 'pcm',
        product_id: 'prod_QJFrJdHK23vjcH',
        price_id: 'price_1PYa9DCpM5K88EcGqBArol8t',
        benefits: 'Save two days of work a week, reduce errors by to one thousandth and have a £30,000 return on investment.',
        deal: '',
        active: null,
        features: [
            'Automated job posting',
            'AI supported candidate selection',
            'Automated interview scheduling',
            'Personalised candidate feedback',
        ],
    },
    {
        name: 'Applicant Tracking System',
        description: 'Rapidly generate job specifications, push them to job boards, generate assessment criteria and screen applications.',
        price: 1000,
        price_addendum: 'pcm',
        product_id: 'prod_QJFrJdHK23vjck',
        price_id: 'price_1PYa9DCpM5K88EcGqBArol8t',
        deal: '',
        benefits: 'Generate a job description, post an advert and create an assessment criteria in less than 5 minutes.',
        active: null,
        features: [
            'Automated job posting',
            'AI supported candidate selection',
            'Automated interview scheduling',
            'Personalised candidate feedback',
        ],
    },
    {
        name: 'Automated Onboarding',
        description: 'Easily ensure that your future employees are ready to go!',
        price: 1000,
        price_addendum: 'pcm',
        product_id: 'prod_QJFrJdHK23vjca',
        price_id: 'price_1PYa9DCpM5K88EcGqBArol8t',
        deal: '',
        benefits: 'Reduce time to onbaord a new employee from six weeks to three days, supported with an 80% efficiency saving in document processing.',
        active: null,
        features: [
            'Automated document collection',
            'Granular control of onboarding process',
            'Automated and personalised alerts',
            'Roles and permissions templates'
        ],
    },
    {
        name: 'Digital ID Checks',
        description: 'Perform ID checks remotely within minutes, check your employees identities and avoid delayes in registration.',
        price: 500,
        price_addendum: '',
        product_id: 'prod_QJFrJdHK23vjcJ',
        price_id: 'price_1PYa9DCpM5K88EcGqBArol8t',
        deal: '',
        warning: 'Additional charges per ID check, discounts for bulk purchases.',
        benefits: 'Save £2000 per registration in locum bridging and improve staff satisfaction.',
        active: null,
        features: [
            'Automated ID checks',
            'International ID checks',
            'Granular control of ID checks',
            'Automated and personalised alerts'
        ],
    },
    {
        name: 'Compliance Monitoring',
        description: "Easily monitor your employee's compliance, automatically send personalised reminders and generate one-click reports.",
        price: '1000',
        price_addendum: 'pcm',
        product_id: 'prod_QJFrJdHK23vjcJ',
        price_id: 'price_1PYa9DCpM5K88EcGqBArol8t',
        deal: '',
        benefits: 'Increase CQC compliance rates and have live monitoring of your staff base at all times',
        active: null,
        features: [
            'Automatically monitor compliance',
            'Automated and personalised alerts',
            'Alerts and notifications',
            'Granular control of compliance monitoring',
        ],
    },
    {
        name: 'Feedback System',
        description: '',
        price: '1000',
        price_addendum: 'pcm',
        product_id: 'prod_QJFrJdHK23vjcJ',
        price_id: 'price_1PYa9DCpM5K88EcGqBArol8t',
        deal: '',
        benefits: 'Increase access by supporting any language, automatically highlighting health inequalities, AI powered thematic and sentiment analysis and personalised responses.',
        active: null,
        features: [
            'Automated feedback collection',
            'Automated and personalised alerts',
            'Granular control of feedback process',
            'Feedback analysis'
        ],
    }

]


export const SubscriptionManagement = (props) => {

    const [loading, setLoading] = useState(true);
    const [costNew, setCostNew] = useState(1000);
    const [costCancel, setCancel] = useState(300);
    const [multi, setMulti] = useState(false);

    const [subscriptions, setSubscriptions] = useState(null);

    const [discount, setDiscount] = useState(null);

    const [scale, setScale] = useState(null);

    const toast = useToast();

    let discounts = {
        1: 5,
        2: 7.5,
        3: 10,
        4: 12.5,
        5: 15
    };

    let scales = {
        5: 1,
        10: 2,
        50: 4,
        100: 8,
        500: 16,
        1000: 20,
        10000: 30,
        100000: 30,
    }



    const [products, setProducts] = useState(null);

    function updateSubscriptions(staff = 1, refresh = false) {


        // check for url params checkout
        console.log('URL', window.location.href, refresh);

        if (window.location.href.includes('checkout') && refresh === false) {


            let params = new URLSearchParams(window.location.href);
            console.log('Checkout', params);
            let checkout = params.get('checkout');

            if (checkout) {
                console.log('Checkout', params.get('checkout'));

                // if success then force refresh
                if (checkout === 'success') {
                    refresh = true;
                    toast({
                        title: 'Subscription successful',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                } else if (checkout === 'cancel') {
                    toast({
                        title: 'Subscription cancelled',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }


            }

        }



        if (refresh === true) {

            // Load subcsriptions
            console.log('Loading subscriptions');

            let url = 'roles/mine';
            let config = {
                params: {
                }
            };

            let resp = API.getItems({url, config})
            .then(response => {

                // Get the active subscriptions for this org
                let organisationUuid = secureLocalStorage.getItem('organisationUuid');

                // Loop through response.data.organisations
                secureLocalStorage.setItem('organisationSubscriptions', response.data.organisations[organisationUuid].subscriptions);
                secureLocalStorage.setItem('organisationProductIds', response.data.organisations[organisationUuid].product_ids);

                setSubscriptions(response.data.organisations[organisationUuid].product_ids);

            })
            .catch(error => {
                console.log(error);
            });

        } else {
            console.log('Subscriptions', secureLocalStorage.getItem('organisationProductIds'));
            setSubscriptions(secureLocalStorage.getItem('organisationProductIds'));
        }
            

        // Get the subscriptions
        // this will be an API call in the future

        // Update scales based on numbers in organisation
        let tmpScale = 0;
        // Loop through scales, once key is above number of employees save that as scale
        Object.keys(scales).forEach(key => {
            
            if (staff < key && tmpScale === 0) {
                tmpScale = scales[key];
            }
        });

        setScale(tmpScale);

    }



    useEffect(() => {

        updateSubscriptions(1, false);

    }, []);

    function updateProductsState() {

        // Use the subscriptions array of product numbers
        // to update the products array

        // Loop through the products array
        let template = productsTemplate;


        // get details of subscriptions
        let subscriptionDetails = secureLocalStorage.getItem('organisationSubscriptions');

        let subscriptionCount = 0;

        for (let i = 0; i < template.length; i++) {
                
            // Check if the product number is in the subscriptions array
            if (Object.keys(subscriptions).includes(template[i].product_id)) {
                template[i].active = true;
                // Search subscriptionDetails for the product_id
                template[i].subscriptionId = subscriptions[template[i].product_id].stripeSubscriptionId;
                subscriptionCount++;
            } else {
                template[i].subscriptionId = null;
                template[i].active = false;
            }
    
        }

        // Find discount based on subscriptionCount
        if (discounts[subscriptionCount]) {
            setDiscount(discounts[subscriptionCount]);
        } else {
            setDiscount(discounts[discounts.length]);
        }

        setProducts(template);

        setLoading(false);

    }

    useEffect(() => {
        if (subscriptions !== null) {
            updateProductsState();
        }
    }, [subscriptions]);

    return (

        <Layout
            title='Subscriptions'
            actionContent={
                <ButtonGroup
                    colorScheme='blue'
                    variant='outline'
                    isDisabled={true}
                    >
                    <Button
                        borderColor='blue.500!important'  
                        color='blue.500!important'
                        opacity='1!important'
                        isLoading={!subscriptions}
                        >
                        {subscriptions ? Object.keys(subscriptions).length + ' ' + pluralize('Subscriptions', Object.keys(subscriptions).length) : 'Loading'}
                    </Button>
                </ButtonGroup>
            }
            description='Easily manaage your product subscriptions here, Pyrexia operates a modular design where you only pay for what you need, while benefiting from discounts when buying multiple tools.'
            >
            <GridItem colSpan='12'> 

            <ButtonGroup display='none'>
                {[1,4,6,14,45,80,120,510,1024,10242].map(staff => {

                    return (
                        <Button onClick={() => {updateSubscriptions(staff, false)}}>
                            {staff}
                        </Button>
                    )
                })}
            </ButtonGroup>


            <Text display='none'>
                {scale && scale}
            </Text>

            {subscriptions && Object.keys(subscriptions).length > 0 &&
                <Alert>
                    <AlertTitle>Congratulations!</AlertTitle>
                    <AlertDescription>You have subscribed to {Object.keys(subscriptions).length} {pluralize('products', Object.keys(subscriptions).length)}, so will recieve a {discount}% discount on your next subscription.</AlertDescription>
                </Alert>
            }


            <Stack spacing={4} py={4} mt={4}>

                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    borderBottom='1px solid'
                    borderColor='text'
                    justifyContent='space-between'
                    pb={2}
                    >

                    <Box>
                        <Heading size='label'>
                            Products
                        </Heading>
                    </Box>

                    <Flex minW='100px' alignItems='center'
                        justifyContent='center'
                        >
                        <Button 
                            display='none'
                            size='sm' colorScheme='blue' variant='ghost' onClick={() => setMulti(!multi)}>
                            {multi ? 'Single' : 'Multi'}
                        </Button>
                    </Flex>

                </Stack>

                {products && products.map((product, index) => {
                        
                        return (
                            <Product
                                {...product}
                                discount={discount}
                                scale={scale}
                                loading={loading}
                                setLoading={setLoading}
                                key={index+loading}
                                refresh={() => {updateSubscriptions(1, true)}}
                            />
                        )
                })}

            </Stack>

            </GridItem>
        </Layout>
    )
}

export default SubscriptionManagement;