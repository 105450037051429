"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseStyle = __assign(__assign({}, _1.Checkbox.baseStyle.label), { lineHeight: 7, _disabled: {
        opacity: 1,
        color: 'disabled',
    } });
var sizes = {
    sm: {
        _peerPlaceholderShown: {
            fontSize: 'sm',
            top: '0.5',
            left: '4',
        },
    },
    md: {
        _peerPlaceholderShown: {
            fontSize: 'md',
            top: '1.5',
            left: '4',
        },
    },
    lg: {
        _peerPlaceholderShown: {
            fontSize: 'lg',
            top: '2.5',
            left: '4',
        },
    },
};
exports.default = {
    baseStyle: baseStyle,
    sizes: sizes,
};
