import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import auth0 from 'auth0-js';
import {
    Flex,
    Box,
    useColorModeValue,
    useColorMode,
    Image,
    Spinner,
    HStack,
    Stack,
    Text,
    Progress,
    Center
  } from '@chakra-ui/react';
import axios from 'axios';
import API from '../core/utils/API';

//import {addAccessTokenInterceptor} from '../core/utils/AxiosConfig';

const MYAPI= process.env.REACT_APP_API_SERVER_URL;


    /*
const setAxiosTokenInterceptor = async (accessToken: string): Promise<void> => {
    axios.interceptors.request.use(async config => {
      const requestConfig = config
      if (accessToken) {
        requestConfig.headers.common.Authorization = `Bearer ${accessToken}`
      } 
      return requestConfig
    })
  }
  
*/

export default function CheckRegistration() {

    const { user, isAuthenticated, getUser, getAccessTokenSilently, handleRedirectCallback, onRedirectCallback, loginWithPopup } = useAuth0();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { colorMode, toggleColorMode } = useColorMode();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [userMetadata, setUserMetadata] = useState(null);
  
  
/*
    var webAuth = new auth0.WebAuth({
        clientID: 'T6zs0CqBoQrxsN8BBX7oP05BaQdj4HIO',
        domain: 'dev-ojtramp.eu.auth0.com',
        redirectUri: 'http://localhost:3000/checkregistration',
        responseType: 'token id_token'
      });
    webAuth.parseHash({ hash: window.location.hash }, function(err, authResult) {
        if (err) {
          return console.log(err);
        }
      
        webAuth.client.userInfo(authResult.accessToken, function(err, user) {
          // Now you have the user's information
        });
      });
    var options = {
    method: 'POST',
    url: 'https://dev-ojtramp.eu.auth0.com/oauth/token',
    headers: {'content-type': 'application/json'},
    data: {
        grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
        client_id: 'T6zs0CqBoQrxsN8BBX7oP05BaQdj4HIO',
        client_secret: 'C2enKmWfp0EgkqvPK7WUj6p7852qNmalKv5-KC_50HdSr_OTdCIw9O1NSJDEYESA',
        username: '{userPhoneNumber}',
        otp: 'code',
        realm: 'email',
        audience: 'your-api-audience',
        scope: 'openid profile email'
    }
    };

    axios.request(options).then(function (response) {
    console.log(response.data);
    }).catch(function (error) {
    console.error(error);
    });
*/
//if(!isAuthenticated) {                      
    //loginWithPopup().then(token => {
        //getUser().then(user => {
          //  console.log(token);
        //});
    //})
 //}
/*
    useEffect(() => {
        const initAuth0 = async () => {
            
            if (window.location.search.includes('code=')) {
                const { appState } = await handleRedirectCallback()
                onRedirectCallback(appState)
            }

            isAuthenticated.then(
                async authenticated => {
                        // get token and register interceptor
                        const token = await getAccessTokenSilently();
                        setAxiosTokenInterceptor(token).then(
                            () => {navigate("/dashboard");}
                        )
                    
                }
            )


        }
        initAuth0().catch()
    }, [])
*/

    useEffect(() => {
        const getToken = async () => {

            try {
                const response = await API.getItems({url: 'requirementgroups/'});
                console.log(response);    
                navigate("/my/dashboard");

            } catch (error) {
                console.log(error);
                navigate("/my/dashboard");

            }
           /* addAccessTokenInterceptor(accessToken).then(() => {
                console.log("Initiated interceptor"); 
                navigate("/dashboard");
            })*/
            
        }
        getToken();
    }, [])

    return (

        <Center justifyContent='center' alignItems='center' direction='column' flex='1' height='100%' >
            <Stack justifyContent='center' spacing={6} mt={24}>
                <Image src='./logo.svg' h='20px' alt='Logo'/> 
                
                {!isAuthenticated ?
                    <>    
                        <Progress colorScheme='blue' size='xs' isIndeterminate minW='200px' />
                        <Text align='center' color='subtle' fontSize='sm' mt={3}>checking registration</Text>
                    </>
                    :
                    <>
                        <Progress colorScheme='blue' size='xs' minW='300px' value='100'/>
                        <Text align='center' color='subtle' fontSize='sm' mt={3}>registration completed</Text>
                    </>
                }
            </Stack>
        </Center>

    )
}
