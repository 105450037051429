import Layout from '../core/layout/Layout';
import {Flex, Stack, Link, Box, Image, Text, Heading, Wrap, Button, GridItem, Icon} from '@chakra-ui/react';
import React, {useState, useEffect} from 'react';
import {AiOutlineApple, AiOutlineAndroid} from 'react-icons/ai';

export default function Authenticator(props) {

    const [show, setShow] = useState(false);
    const [devicePlatform, setDevicePlatform] = useState(undefined);

    const getMobileOS = () => {
        const ua = navigator.userAgent
        let message = 'We have detected you are using '
        if (/android/i.test(ua)) {
            setDevicePlatform('Android');
            return message + "Android"
        }
        else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ){
            setDevicePlatform('iOS');
            return message + "iOS"
        }
        setShow(true);
        return "We were unable to detect your device type, if you are using a computer please return to this page on your phone."
    }

    
    if (devicePlatform === undefined) {
        setDevicePlatform(getMobileOS);
    }

    const platforms = [
        {
            name: 'Android',
            icon: 'Android',
            url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
        },
        {
            name: 'iOS',
            icon: 'Apple',
            url: 'https://apps.apple.com/us/app/google-authenticator/id388497605'
        },
    ]

    



    return (
        <Layout 
            title='Authenticators'
            description={<>
                <Text>You need to download an authenticator app on a mobile device to access our high security pages, we recommend Google's Authenticator App which can be downloaded on any platform.</Text>
                <Text pt={4}>{devicePlatform}</Text>
                </>
            }   
            >

            <GridItem colSpan={{base: 12, md: 8}}>
                <Stack spacing={4} >
                            <Text></Text>
            <Wrap pb={4}>

            {platforms.map(platform => {

                if (!show && platform.name != devicePlatform) {
                    return
                }

                return (
                    <a key={platform.name} href={platform.url} target='_blank'>
                        <Stack justifyContent='center' p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                            {platform.name === 'Android' && <Icon as={AiOutlineAndroid} boxSize='128px' />}
                            {platform.name === 'iOS' && <Icon as={AiOutlineApple} boxSize='128px' />}
                            <Text pt={2} textAlign='center'>{platform.name}</Text>  
                            <Button colorScheme='blue'>
                                Download
                            </Button>
                        </Stack>
                    </a>

                )

            })}

            </Wrap>
            
            <Button mt={4} w='min-content' size='sm' variant='outline' onClick={() => {setShow(!show);}}>
                {show ? 'Hide' : 'Show'} Other Platforms
            </Button>
            </Stack>
            </GridItem>


        </Layout>
    )
}