import {
    Box,
    Button,
    Circle,
    Heading,
    Img,
    LightMode,
    SimpleGrid,
    Stack,
    Text,
    Image,
    Icon,
    useColorModeValue as mode,
    VisuallyHidden,
    Link,
  } from '@chakra-ui/react'
  import { FaPlay } from 'react-icons/fa'

  import HeroImage from './Hero.png';
  import { MdArrowForward } from 'react-icons/md';
  import { useNavigate } from "react-router-dom";

  import CallToAction from '../shared/CallToAction';
import PyrexiaDivider from '../layout/PyrexiaDivider';

  export default function Overview() {
    const navigate = useNavigate();

    let sections = [  
      {
        title: "Bringing AI to life in practical ways",
        description: "Our advanced natural language processing and intelligent systems effortlessly automate repetitive tasks for you.",
        link: '/features',
        image: '/images/home/ai.png'
      },
      {
        title: "Security is foundational to everything we do.",
        description: "Our advanced systems and robust algorithms provide unparalleled protection against threats and breaches.",
        link: '/about#security',
        image: '/images/home/security.png'
      },
      {
        title: "Clinician-centric design.",
        description: "Created by doctors to transform experiences for healthcare professionals. Ultimate focus on user experience.",
        link: '/about',
        image: '/images/home/design.png'
      },
    ];

      

    return (
      
        <Box as="section" py="7.5rem">
          <Box
            maxW={{
              base: 'xl',
              md: '7xl',
            }}
            mx="auto"
            px={{
              base: '6',
              md: '8',
            }}
          >

            {sections.map((section, index) => (
              <Stack key={index} mb="2" direction={{ base: 'column', lg: 'row' }} spacing={12}>
                <Image ml='auto' mr='auto' w='400px' src={section.image} alt={section.title} mb="5" order={{base: 0, md: index % 2 === 0 ? 0 : 1}} />
                <Stack spacing="5" order={{base: 0, md: index % 2 === 0 ? 1 : 0}} justifyContent={{base: 'left', md: 'center'}}>
                  <Box textAlign={{ base: 'left', md: index % 2 === 0 ? 'left' : 'right'}}>
                    <Text mt={0} mb={4} fontSize='32px' fontWeight='600' color='black'>
                      {section.title}
                    </Text>
                    <Text mt={0} mb={4} size='pagetitle-description'>
                      {section.description} <Text as='span' color='blue.500' cursor='pointer'  onClick={()=>{navigate(section.link)}}>Read more.</Text>
                    </Text>
                  </Box>
                </Stack>
              </Stack>
            ))}
            

              </Box>

        </Box>
  
    )
  }