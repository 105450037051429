
import React from 'react';
import { Link as ReachLink } from "react-router-dom";
import Layout from '../../core/layout/Layout';
import { SimpleGrid, Select, GridItem,  Box, Text, Wrap, Tag } from '@chakra-ui/react';
import AutoForm from '../../core/models/AutoForm';
import API from '../../core/utils/API';
import { useState } from 'react';
import { useEffect } from 'react';
import ShowTemplate from './ShowTemplate';
export default function EmailTemplates() {

    let [loading, setLoading] = useState(true);
    let [templates, setTemplates] = useState([]);
    let [templateName, setTemplateName] = useState('');
    
    useEffect(() => {
        const getTemplates = async () => {

        API.getItems({url: 'utils/emails/templates'})
        .then(response => {
            console.log(response.data);
            setTemplates(response.data.templates);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }
    getTemplates();
    }, []);




    return (

        <Layout 
            title="Email Templates"
            primaryButtonText="Add Template"
            primaryButtonModal={true}
            modalTitle="Add Template"
            modalContent={<AutoForm 
                url = 'utils/emails/templates'
                inputs = {[
                    {
                        name: 'TemplateName'
                    },
                    {
                        name: 'SubjectPart'
                    },
                    {
                        name: 'HtmlPart' 
                    },
                    {
                        name: 'TextPart'
                    },
                ]}
                />}   
            >

            <GridItem colSpan={12} key={templateName}>

                <Box mb={12}>

                    <Text size='smalltitle' mb={2}>Select an e-mail template:</Text>
                    <Select placeholder='Select option' onChange={(e) => setTemplateName(e.target.value)}>
                        {templates.map((template, index) => {
                            return (                                        
                                <option value={template.Name}>{template.Name}</option>
                            )
                        })}
                    </Select>


                </Box>

                {templateName ?
            
                    <ShowTemplate templateName={templateName} />

                    :

                    <Box>
                        <Text>
                            Click on a template to view it
                        </Text>
                    </Box>
                }
            </GridItem>
        </Layout>

    )
}