import React from 'react';

import Select, {InputProps,OptionType,OptionsType,} from 'react-select';
import makeAnimated from 'react-select/animated';
import {useState} from 'react';

import API from '../../utils/API';

const animatedComponents = makeAnimated();


  
  
export default function Association(props) {
    const [options, setOptions] = React.useState('');  
    const url = props.url;

    console.log(url);
    

    const CustomInput = (inputProps: InputProps) => {
        const getValue: () => OptionsType<OptionType> = inputProps.getValue;
        const options: OptionsType = inputProps.options;
              
        console.log('value', getValue()); // <==== value
        console.log(options); // <==== options
              
        return <input name="userUuid" {...inputProps} {...props.register} />; //
      };

    React.useEffect(()=>{
        async function getOptions(){
            const response = await API.getItems(`${url}`);
            const a = (response.data[props.model]);

            let varID = 'uuid';
            if (props.id !== undefined) {
                varID = props.id;
            }


            let selectList = [];
            Array.from(a).map((record) => {
                
                let tempLabel = '';
                props.label.map((opt) => {

                    if (opt.prefix !== undefined) {
                        tempLabel = tempLabel + opt.prefix
                    }
                    tempLabel = tempLabel + record[opt.column]
                    if (opt.suffix !== undefined) {
                        tempLabel = tempLabel + opt.suffix
                    }
                })

                tempLabel = tempLabel.trim();

                selectList.push({
                    value: record[varID],
                    label: tempLabel
                });
                              
            });

            console.log(selectList);
            setOptions(selectList);
        }
        getOptions()
    }, [])
    

    return (

        <>

            {props.isMulti === 'true' ? 
                
                <Select
                    name={props.name}
                    options={options}
                    isMulti
                    components={{ Input: CustomInput }}

                    />

                :

                <Select
                    name={props.name}
                    options={options}
                    components={{ Input: CustomInput}}


                    />            
            
            }
        
        </>





    )

}