import { Stack, Heading, Text, Box, Container, HStack } from '@chakra-ui/react'
import { Divider } from '@chakra-ui/react'
import { Circle, Icon } from '@chakra-ui/react'
import { HiCheck } from 'react-icons/hi'

import { useCallback, useMemo, useState } from 'react'

const useStep = (props) => {
  const { maxStep, initialStep = 0 } = props
  const [currentStep, setCurrentStep] = useState(initialStep)
  const canGoToNextStep = useMemo(() => currentStep + 1 <= maxStep, [currentStep, maxStep])
  const canGoToPrevStep = useMemo(() => currentStep - 1 >= 0, [currentStep])
  const setStep = useCallback(
    (step) => {
      const newStep = step instanceof Function ? step(currentStep) : step
      if (newStep >= 0 && newStep <= maxStep) {
        setCurrentStep(newStep)
        return
      }
      throw new Error('Step not valid')
    },
    [maxStep, currentStep],
  )
  const goToNextStep = useCallback(() => {
    if (canGoToNextStep) {
      setCurrentStep((step) => step + 1)
    }
  }, [canGoToNextStep])
  const goToPrevStep = useCallback(() => {
    if (canGoToPrevStep) {
      setCurrentStep((step) => step - 1)
    }
  }, [canGoToPrevStep])
  const reset = useCallback(() => {
    setCurrentStep(0)
  }, [])
  return [
    currentStep,
    {
      goToNextStep,
      goToPrevStep,
      canGoToNextStep,
      canGoToPrevStep,
      setStep,
      reset,
    },
  ]
}


 const StepCircle = (props) => {
  const { isCompleted, isActive } = props
  return (
    <Circle
      size="8"
      bg={isCompleted ? 'accent' : 'inherit'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'accent' : 'inherit'}
      {...props}
    >
      {isCompleted ? (
        <Icon as={HiCheck} color="fg.inverted" boxSize="5" />
      ) : (
        <Circle bg={isActive ? 'accent' : 'border'} size="3" />
      )}
    </Circle>
  )
}
const Step = (props) => {
  const { isActive, isCompleted, isLastStep, ...stackProps } = props
  return (
    <Box w='fill-available'>
        <HStack flex={isLastStep ? '0' : '1'} spacing="0" {...stackProps} w='fill-avaiable'>
            <StepCircle isActive={isActive} isCompleted={isCompleted} />
            {!isLastStep && (
                <Divider
                orientation="horizontal"
                borderWidth="1px"
                borderColor={isCompleted ? 'accent' : 'inherit'}
                />
            )}
        </HStack>
        <Stack pr={2} pt={2} spacing={0}>
            <Text fontSize='xs'>{props.title}</Text>
            <Text display='none' fontSize='xs' color='subtle' mt={0}>{props.description}</Text>
        </Stack>
    </Box>
  )
}

export default function Process(props) {

  let steps = [
    {
        title: 'Submitted',
        description: 'Awaiting initial response'
    },
    {
        title: 'Team',
        description: 'Awaiting the relevant team to respond'
    },
    {
        title: 'Investigation',
        description: 'Awaiting the investigation team to respond'
    },
    {
        title: 'PALS',
        description: 'Awaiting the PALS team to compile'
    },
    {
        title: 'Legal',
        description: 'Awaiting the legal team to respond'
    },
    {  
        title: 'Drafted',
        description: 'Response has been drafted and is awaiting approval'

    },
    {
        title: 'Approved',
        description: 'Response has been approved and is awaiting sign off'
    },
    {
        title: 'Sent',
        description: 'Response has been sent'
    },
    {
        title: 'Client',
        description: 'Awaiting client to respond'
    },
    {
        title: 'Closed',
        description: 'Complaint has been closed'
    }
  ]


  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: Math.random() * steps.length,
  })

  return (
    <Box bg="bg.surface">
      <Container display="flex" justifyContent="center" alignItems="center" minH="20" pl='0 !important' pr='0 !important'>
        <HStack spacing="0" justify="space-evenly" flex="1" pl='0 !important' pr='0 !important' alignItems='flex-start'>
          {[...Array(steps.length)].map((_, id) => (
            <Step
              key={id}
              cursor="pointer"
              onClick={() => setStep(id)}
              isActive={currentStep === id}
              isCompleted={currentStep > id}
              isLastStep={steps.length === id + 1}
              title={steps[id].title}
                description={steps[id].description}
            />
          ))}
        </HStack>
      </Container>


    </Box>
  )
}