
import { Box, Divider, HStack } from "@chakra-ui/react"

export default function PyrexiaDivider(props) {

    return (
        
      <HStack mt={8}>
        <Divider />
          <Box>
            Ø
          </Box>
        <Divider />
      </HStack>
    )
}