import React, { useState, useEffect } from 'react';
import {Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table } from '@chakra-ui/react';
import { MdEdit, MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";
import API from '../../../core/utils/API';

let params = {

}


export default function EditSchedule(props) {

    console.log('ATTRIBUTES:', props.attributes);

    let updatedAttributes = {};
    

    function updateAttribute(index, value) {

        console.log("Update Attribute", index, value);

        updatedAttributes[index] = value;

        console.log("Updated Attributes", updatedAttributes);

    }




    return (

        <Box>

            {Object.keys(props.attributes).map((key, index) => {   

                let value = props.attributes[key];

                // Check if attribute value is an object
                if (typeof value === 'object') {
                    return;
                }

                return (

                    <Box key={index}>
                        <Text>{key}</Text>
                        <Input value={value} onChange={(e) => {updatedAttributes[index] = e.target.value}} />
                    </Box>

                )

            })}

        </Box>

    )
}