import { Box, Flex, Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { products } from './data'
import { PricingCard } from './PricingCard'

export default function Costings(props) {

  // Get user data like uuid, email, etc
  // Get user's organisation data
  


    return (
        <Box as="section">
        <Container
          py={{
            base: '16',
            md: '24',
          }}
        >
          <Flex
            spacing={{
              base: '12',
              md: '0',
            }}
            direction={{
              base: 'column',
              lg: 'row',
            }}
          >
            
            <Box
              flex="0"
            >
              <Heading size='pagetitle' mr='120px'
                >
                Pricing
              </Heading>
              <Text mt="7" fontSize="20px" color='primary' mb={12} >
                We know you'll love it, use it free for a year *
              </Text>

              </Box>

       
            <SimpleGrid
              columns={{
                base: 1,
                md: 2,
              }}
              pt={{
                base: '0px',
                md: '78px',
              }}
              columnGap="8"
              rowGap="6"
              flex="1"
            >
              {products.map((product, id) => (
                <PricingCard key={id} product={product} />
              ))}
            </SimpleGrid>
          </Flex>
        </Container>
      </Box>
    
    
    )
}