import React from 'react';

import {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import {Image,InputRightAddon, Alert, ListIcon, Stack, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, List, ListItem, HStack, Text, Link, Flex, Tag, TagLabel, TagRightIcon, Box,Heading, Container, Input, Button, ButtonGroup } from '@chakra-ui/react';
import Modal from '../../core/components/modal/Modal';
import MFAWrapper from '../../auth/cognito/components/MFAWrapper';
import {Dropzone} from '../../core/components/forms/Dropzone';
import { Link as ReachLink, unstable_usePrompt  } from 'react-router-dom';

import BasicBanner from '../../core/components/banners/BasicBanner';

import { useNavigate  } from "react-router-dom";

import Pluralize from 'pluralize';
import { useAuth0 } from "@auth0/auth0-react";
import API from '../../core/utils/API';

import Resizer from "react-image-file-resizer";
import jsPDF from 'jspdf';
import { Buffer } from "buffer";
import { MdCheck, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import secureLocalStorage from 'react-secure-storage';

export default function UploadInvoice(props) {

  unstable_usePrompt({
    message: "Are you sure you want to cancel your uploads?",
    when: ({ currentLocation, nextLocation }) =>
      submitting && !finished &&
      currentLocation.pathname !== nextLocation.pathname,
  });


  const [confirmed, setConfirmed] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [formError, setFormError] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
  } = useForm();
  const onError = (errors, e) => console.log(errors, e);
  const [loading, setLoading] = useState(false)
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [failedFilenames, setFailedFilenames] = useState([]);
  const [successFilenames, setSuccessFilenames] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [finished, setFinished] = useState(false);
  const [minimised, setMinimised] = useState(false);
  const [startLocation, setStartLocation] = useState(null);

  const navigate = useNavigate();

  const batchSize = 2;

  let organisationUuid = secureLocalStorage.getItem('organisationUuid');

  async function uploadFile(e) {
    console.log(e.target.files, e.target.files.length);

    let startingURL = window.location.href;

    setTotalFiles(e.target.files.length);
    setSubmitting(true);
    setProgress(0);
    setStartLocation(window.location.href);


    const files = Array.from(e.target.files);
    const totalFiles = files.length;
    let data = new FormData();
    setMinimised(true);

    for (let i = 0; i < totalFiles; i++) {

      console.log("Location check", window.location.href, startingURL, startLocation);

      if (window.location.href !== startingURL) {
        console.log("Location changed");
        return;
      }


        if (i % batchSize === 0 && i !== 0) {
            // Upload the current batch
            try {
                data.append('upload_preset', 'ml_default');
                let response = await API.postItems({url: 'organisations/accounts/uploads', data,
                  config: {
                    params: {
                      organisationUuid
                    }
                  }
              });
                console.log(response);
                setProgress((i / totalFiles) * 100);
                let tmpSuccessFilenames = successFilenames;
                tmpSuccessFilenames.push(...data.getAll('file').map(file => file.name));
                setSuccessFilenames(tmpSuccessFilenames);
            } catch (error) {
                console.log(error);
                let tmpFailedFilenames = failedFilenames;
                tmpFailedFilenames.push(...data.getAll('file').map(file => file.name));
                setFailedFilenames(tmpFailedFilenames);
            }

            // Prepare a new FormData for the next batch
            data = new FormData();
        }

        // Append file to FormData
        data.append('file', files[i]);

        // If it's the last file and the batch isn't full, upload it
        if (i === totalFiles - 1) {
            try {
                data.append('upload_preset', 'ml_default');
                let response = await API.postItems({url: 'organisations/accounts/uploads', data,
                  config: {
                    params: {
                      organisationUuid
                    }
                  }
                });
                console.log(response);
                setProgress(100); // Set progress to 100% on the last batch
                let tmpSuccessFilenames = successFilenames;
                tmpSuccessFilenames.push(...data.getAll('file').map(file => file.name));
                setSuccessFilenames(tmpSuccessFilenames);
                setFinished(true);
                setStartLocation(null);
                props.refresh();
            } catch (error) {
                console.log(error);
                let tmpFailedFilenames = failedFilenames;
                tmpFailedFilenames.push(...data.getAll('file').map(file => file.name));
                setFailedFilenames(tmpFailedFilenames);
                setFinished(true);
                setStartLocation(null);
                props.refresh();
            }

            

        }
    }
  }


  


    return (

      <>



        {minimised ?

          <Box bg='white' borderRadius='sm' boxShadow='sm' p={4} mb={8}>

            <HStack justifyContent='space-between'>

                <Stack>
                        <Heading as='h4' fontSize='xl' lineHeight='1em'>
                          {!finished && submitting && 'Uploading ' + totalFiles + ' invoices'}
                          {finished && 'Upload complete'}
                        </Heading>
                        <Wrap pb={4}>
                          <Text fontSize='sm' color='subtle'>Uploaded: {successFilenames.length}</Text> 
                          <Text fontSize='sm' color='subtle'
                            onClick={() => setShowFailed(!showFailed)}
                            cursor='pointer'
                            _hover={{color: 'red.500'}}
                            >Failed: {failedFilenames.length}</Text>
                        </Wrap>
                      {showFailed &&
                        <Text fontSize='sm' color='red.500'>Failed: {failedFilenames.length > 0 ? failedFilenames.join(", ") : "None"}</Text>
                      }

              </Stack>



              <ButtonGroup size='sm' variant='outline' pb={4}>
                {finished && failedFilenames.length > 0 &&
                  <Button colorSceheme='red'
                    variant='outline'
                    onClick={() => setShowFailed(!showFailed)}>
                      {showFailed ? 'Hide Failed' : 'Show Failed'}
                  </Button>
                }
                <Button colorScheme='blue' onClick={() => setMinimised(false)}>Expand</Button>
                <Button colorScheme='red' onClick={() => props.onClose()}>
                  {finished ? 'Close' : 'Cancel'}
                </Button>
              </ButtonGroup>

            </HStack>


            <Box h='8px' w='100%' bg='gray.200' borderRadius='full'>
              <Box h='8px' w={progress + '%'} borderRadius='full' className='gradient-background'></Box>
            </Box>

          </Box>


        :

          <Modal 
                hideClose={formMessage ? true : false}
                onClose={() => props.onClose()}
                //MFA={true}
                footer={
                  <ButtonGroup size='sm'>

                    {finished && failedFilenames.length > 0 &&
                      <Button colorSceheme='red'
                        variant='outline'
                        onClick={() => setShowFailed(!showFailed)}>
                          {showFailed ? 'Hide Failed' : 'Show Failed'}
                      </Button>
                    }
                    {submitting &&
                      <Button  variant='outline' colorScheme='blue' onClick={() => setMinimised(true)}>Minimise</Button>
                    }
                    {finished &&
                      <Button colorScheme='blue' onClick={() => props.onClose()}>Return</Button>
                    }
                  </ButtonGroup>
                }
              
              >
            
            <Box textAlign='center' p='0'>
              <Box mt={4} p='0'>
                

                {!submitting && !finished &&
                    <>
                    <Heading as='h4' fontSize='xl'>Select your invoices</Heading>
                    <Text mb={6}>The files will be automatically attached to the correct using your organisation's naming conventions.</Text>
                      <Dropzone cursor='pointer' 
                        _hover={{border: '1px solid #4DA9F2'}} mb={4} 
                        onSubmit={(e) => e.preventDefault()} filesize='2mb' filetypes='Any PDFs'>
                        
                          <Input
                                    type="file"
                                    name="evidence"
                                    /*multiple*/
                                    onDrop={function() {console.log("dropped")}}
                                    onChange={uploadFile}
                                    h="100%"
                                    w="100%"
                                    position="absolute"
                                    top="0"
                                    left="0" 
                                    opacity="0"
                                    accept="image/*,application/PDF"
                                    cursor='pointer'
                                    multiple
                                    /> 
                      </Dropzone>
                    </>
                  }
                {!finished && submitting && // !finished &&
                    <Stack pb={4}>
                      <Heading as='h4' fontSize='xl'>Uploading {totalFiles} invoices</Heading>
                      <Box mt={4} mb={4}>
                        <LoadingCircle />
                      </Box>
                      <Box h='8px' w='100%' bg='gray.200' borderRadius='full'>
                        <Box h='8px' w={progress + '%'} borderRadius='full' className='gradient-background'></Box>
                      </Box>
                      <Text fontSize='sm' color='subtle'>Uploading {successFilenames.length} of {totalFiles} invoices</Text>
                      <Text fontSize='sm' color='subtle'>Uploaded: {successFilenames.length}</Text> 
                      <Text fontSize='sm' color='subtle'
                        onClick={() => setShowFailed(!showFailed)}
                        cursor='pointer'
                        _hover={{color: 'red.500'}}
                        >Failed: {failedFilenames.length}</Text>
                      {showFailed &&
                        <Text>{failedFilenames.length > 0 ? failedFilenames.join(", ") : "None"}</Text>
                      }
                    </Stack>

                }

                {finished &&

                    <>

                      <Heading as='h4' fontSize='xl'>Upload complete</Heading>
                      <Box mt={4} mb={4}>
                        <Icon as={MdCheck} boxSize='24' color='green.500' />
                      </Box>
                      <Text fontSize='sm' color='subtle'>Uploaded {successFilenames.length} invoices</Text>
                      {failedFilenames.length > 0 &&
                        <Text fontSize='sm' color='subtle'
                          onClick={() => setShowFailed(!showFailed)}
                          cursor='pointer'
                          _hover={{color: 'red.500'}}
                          >Failed: {failedFilenames.length}</Text>
                      }
                      {showFailed &&
                        <Text>{failedFilenames.length > 0 ? failedFilenames.join(", ") : "None"}</Text>
                      }

                    </>
                }
                  
              </Box>
            </Box>
          </Modal>
        }
     </>

    )
}
