import React, { useState, useEffect } from 'react';
import API from '../../core/utils/API';
import { FormLabel, Box, Stack, Text, Button } from '@chakra-ui/react';


export default function Parameters(props) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    useEffect(() => {
        API.getItems({url: '/users/values'}).then((response) => {
            console.log(response.data);
            setData(response.data.userValues);
        })
    }, []);

    useEffect(() => {
        setLoading(false);
    }, [data]);


    return (

        <Box>

            {!loading && !show &&

                <Box borderBottom='2px solid whitesmoke' 
                pt={2}
                pb={2}>
                    <Button onClick={() => setShow(true)} variant='outline' colorScheme='blue'>
                        Show my parameters
                    </Button>
                </Box>
            }

            {!loading && show &&
            data.map((item, index) => (
                <Box key={index} borderBottom='2px solid whitesmoke' 
                pt={2}
                pb={2}>
                <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
                >
                <FormLabel variant="inline" minW={{md: '18em'}} maxW={{md:'24em'}}>
                    <Text fontSize="lg" fontWeight="medium">
                    {item.UserParameter.question && item.UserParameter.question.replaceAll('_', ' ')}
                    </Text>
                    <Text color="muted" fontSize="sm" lineHeight='1.2em' mb={2}>
                    {item.UserParameter.description && item.UserParameter.description.replaceAll('_', ' ')}
                    </Text>
                </FormLabel>

                <Stack direction={{base: 'column', md: 'row'}} w='100%'
                    maxW={{md: '3xl'}}
                    pl='5px !important'
                    >

                    <Text>{item.value}</Text>                
                </Stack>
                </Stack>
                </Box>
            ))}

        </Box>

    )

}