
import Footer from '../layout/Footer';
import CallToAction from '../shared/CallToAction';
import PyrexiaDivider from '../layout/PyrexiaDivider';
import { Box, Link, Heading, Text, Image, UnorderedList, ListItem } from '@chakra-ui/react';
export default function WorkflowProfessionals() {


    return (
        <Box>
          <Box as="section" py="7.5rem" pb='5em'>
            <Box
              maxW={{
                base: 'xl',
                md: '7xl',
              }}
              mx="auto"
              px={{
                base: '6',
                md: '8',
              }}
            >
                <Heading size="pagetitle">
                  How it works<Text as='span' color='#B400FF'>.<br/>
                  For organisations
                  </Text>
                </Heading>
                <Heading size='statictagline'>
                  Is your HR team drowning in paperwork and chasing endless documents? <sup>Ø</sup>Pyrexia can help.
                </Heading>
                <Text size='staticsmalltext'>
                  If your staff spend more time pushing and chasing paper than focusing on their priorities, it's time for a change. <sup>Ø</sup>Pyrexia is the lifeline your healthcare organisation needs.
                </Text>
                <Text size='staticsmalltext' pt={4}>
                  Our intelligent HR automation platform eliminates the endless admin weighing down your people processes:
                </Text>
                <UnorderedList>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    AI instantly extracts credentials data from documents - avoiding major data re-entry across your systems.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Compliance tracking is automated with proactive, personalised alerts - no more last minute credential crises.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Our clinician passport enables frictionless staff mobility between NHS organisations - made for your dynamic workforce.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Customisable workflows and permissions provide structure while allowing flexibility.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    AI tools - such as personalised AI-generated reminders - to speed up workflows.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    100% compliance with Cybersecurity and Data Protection regulations.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size='staticsmalltext'>
                    Robust analytics provide data-driven insights on bottlenecks, turnover, and more to inform your strategy.
                    </Text>
                  </ListItem>

                </UnorderedList>
            </Box>
            </Box>


              <PyrexiaDivider />

            <Box as='section' py='7.5rem' mb={4} pt='5em'>
              <Box
                maxW={{base: 'xl',md: '7xl',}}
                mx="auto"
                px={{base: '6',md: '8',}}
                >

<Heading size='statictagline'>
                Early trials already show <sup>Ø</sup>Pyrexia delivering a 70% reduction in HR workloads. Your team could recover hours of capacity to focus on more strategic initiatives that move the needle.
                </Heading>
                <Text size='staticsmalltext'>
                Streamlined onboarding means clinicians start seeing patients faster. Automated compliance avoids potentially dangerous lapses. And tapping our UK-wide network accelerates recruitment.  
                </Text>
                <Text size='staticsmalltext'>
                Whether you're a clinic manager, HR director, or whole health system, <sup>Ø</sup>Pyrexia makes life easier - reducing costs, risks, and burnout.
                </Text>
                <Text size='staticsmalltext'>
                <Link href='mailto:ali@pyrexa.io' color='blue.500'>
                Schedule a demo now</Link> to experience the paperwork-free future of healthcare HR. Your team will feel the difference.
                </Text>

                </Box>
              </Box>


            <Box as='section' bg='#000' color='white' py='7.5rem'>
              <Box
                maxW={{base: 'xl',md: '7xl',}}
                mx="auto"
                px={{base: '6',md: '8',}}
                >
                <Heading size="smalltitle" color='inverted'>
                  We’re bringing the power of automation to everyone.                
                </Heading>
                <Heading size='statictagline' fontSize='22px' mb={12} mt={8} color='inverted'>
                <sup>Ø</sup>Pyrexia is working with organisations around the UK to make HR simpler.
                </Heading>
                <Image src='/images/WeDoThisOrganisations.png' />
              </Box>
            </Box>
  
  
              <CallToAction/>
              <Footer/>
  
          </Box>
    )

}