import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdFlag, MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import { Line, Bar, Pie, Radar} from 'react-chartjs-2';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';


const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
      },
    },
    plugins: {
      title: {
        display: true,
      },
    },
  };

const piedata = {
    labels: [], //  ['Complete', 'Expired', 'Outstanding', 'Pending Review', 'Reviewed']
    datasets: [
      {
        label: '', // String
        data: [], // [12, 19, 3, 5, 2, 3]
        backgroundColor: [
          'rgba(72, 149, 239, 0.7)',
          'rgba(63, 55, 201, 0.7)',
          'rgba(114, 9, 183, 0.7)',
          'rgba(181, 23, 158, 0.7)',
          'rgba(247, 37, 133, 0.7)'
        ],
        borderColor: [
          '#4895EF',
          '#3F37C9',
          '#7209B7',
          '#B5179E',
          '#F72585',
        ],
        borderWidth: 1,
      },
    ],
};
const bardata = {
    labels: [], //'Oliver', 'James'
    datasets: [
      {
        label: '', // string
        data: [], // 4, 6
        backgroundColor: '#4895EF',
      },
      /*{
        label: 'Training Record',
        data: [3, 2],
        backgroundColor: '#3F37C9',
      },
      {
        label: 'Personal Record',
        data: [2, 1],
        backgroundColor: '#7209B7',
      },*/
    ],
  };


function Card(props) {

    return (
        <Box borderRadius='md' boxShadow='md' p={4} bg='white' w='fit-content'>
            <Heading size='md' mb={4}>{props.title}</Heading>
            {props.children}
        </Box>
    )
}

export default function Insights(props) {

    const [analysis, setAnalysis] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [teams, setTeams] = useState(null);
    const [locations, setLocations] = useState(null);


    function getData() {

        console.log("Loading data");
        API.getItems({url: 'complaints/insights', config: {}})
        .then(response => {
            console.log("Insights", response.data);

            let tmpStatuses = response.data.analysis.statuses;
            let tmpPieData = piedata;
            tmpPieData.datasets[0].label = 'Status of Communications';
            Object.keys(tmpStatuses).map((key) => {
                tmpPieData.labels.push(key);
                tmpPieData.datasets[0].data.push(tmpStatuses[key]);
            });
            setStatuses(tmpPieData);

            let tmpTeams = bardata;
            let tmpTeamsData = response.data.analysis.teams;
            Object.keys(tmpTeamsData).map((key) => {
                tmpTeams.labels.push(key);
                tmpTeams.datasets[0].data.push(tmpTeamsData[key]);
            });
            setTeams(tmpTeams);

            let tmpLocations = piedata;
            let tmpLocationsData = response.data.analysis.locations;
            Object.keys(tmpLocationsData).map((key) => {
                tmpLocations.labels.push(key);
                tmpLocations.datasets[0].data.push(tmpLocationsData[key]);
            });
            console.log("Locations", tmpLocations);
            setLocations(tmpLocations);
            


            setAnalysis(response.data.analysis);

        })
        .catch(error => {
            console.log(error);
        });

    }




    useEffect(() => {

        getData();

    }, []);

    return (
        <Layout
            title='PALS Insights'>

                <GridItem pt={12} colSpan='6' rowSpan='2' alignItems='flex-start' width='fill-available'>

                    <Wrap spacing={8}>

                {statuses &&
                    <Card title='Status of Communications'>
                        <Box maxW='400px' p={8} pt={12}>
                            <Pie data={statuses} 
                            options={
                                {
                                plugins: {title: {text: 'Status of Communications'}},
                                }
                            }
                            />
                        </Box>
                    </Card>
                }

                {locations &&
                    <Card title='Breakdown by Service/Location'>
                        <Box maxW='400px' p={8} pt={12}>
                            <Pie data={locations} 
                            options={
                                {
                                plugins: {title: {text: 'Breakdown by Service/Location'}},
                                }
                            }
                            />
                        </Box>
                    </Card>
                }

                {teams &&
                    <Card title='Breakdown by Team'>
                        <Box w='800px' p={8}>
                            <Bar data={teams} options={options} />
                        </Box>
                    </Card>
                }
</Wrap>
                </GridItem>

        </Layout>
    )

}

