import {
    Avatar,
    Box,
    ButtonGroup,
    Button,
    HStack,
    Circle,
    Badge,
    Icon,
    Stack,
    Tag,
    Heading,
    Text,
    useColorModeValue,
    Wrap,
    Link,
    VStack,
    Flex,
    List,
    ListItem, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,

  } from '@chakra-ui/react';
  import * as React from 'react';
  import { HiCash, HiLocationMarker, HiShieldCheck } from 'react-icons/hi';
  import { MdMail, MdOutlineMessage, MdOutlineQuestionAnswer, MdCheck, MdClose, MdOutlineAvTimer, MdHelp, MdExpandMore, MdExpand } from 'react-icons/md';
  import Moment from 'react-moment';
  import CustomListItem from '../core/models/CustomListItem';
  import {useEffect, useState} from 'react';
  import API from '../core/utils/API';


  export default function AccessCard(props) {
    
    let [show, setShow] = React.useState(false);
    let [access, setAccess] = React.useState(props.access);
    let [picture, setPicture] = React.useState(null);
  
    const getPicture = async (tokenIn) => {

      
      let config = {
        params: {
          userUuid: access.accessorUuid
        }
      }
      
      let picture = await API.getItems({
        url: 'users/picture', 
        config
      })
      .then(res => {
        setPicture(res.data.image);
      })
      .catch(err => {
        console.log("Error getting picture: ", err);
      });

    };

    useEffect(() => {
      getPicture();
    }, []);



    let accessorName = 'Error';
    let accessorEmail = 'Error';
    let userName = 'Error';

    let status = 'Error';
    if (access == null) {
      
    } else {

      if (access['accessor'] == null) {
        accessorName = access['tempEmail'];
        accessorEmail = access['tempEmail'];
      } else {
        if (access['accessor']['firstName'] == null) {
          accessorName = access['accessor']['email'];
        } else {
          accessorName = access['accessor']['firstName'] + ' ' + access['accessor']['lastName'];
        }
        accessorEmail = access['accessor']['email'];
      }

      if (access['user'] == null) {
        userName = access['tempEmail'];
      } else {
        if (access['user']['firstName'] == null) {
          userName = access['user']['email'];
        } else {
          userName = access['user']['firstName'] + ' ' + access['user']['lastName'];
        }
      }
    
    }

    
    let now = new Date();
    let expires = new Date(access['expiration']);
    let color = 'gray';
    let active = true;

    if (access['cancelled']) {
      status = 'Cancelled';
      active = false;
    } else if (new Date(access['expiration']) < Date.now() && access['expiration'] != undefined) {
      status = 'Expired';
      active = false;
    } else if (access['approved'] && access['accepted']) {
      status = 'Active';
      color = 'green';
    } else if (access['approved'] === false && access['accepted']) {
      status = 'Request Declined';
      active = false;
    } else if (access['approved'] && access['accepted'] === false) {
      status = 'Share Declined';
      active = false;
    } else if (access['approved'] && access['accepted'] === null) {
      status = 'Share Pending';
      color='yellow'
    } else if (access['approved'] === null && access['accepted']) {
      status = 'Request Pending';
      color='red'
    }


    const handleRequestManageClick = (decision, request) => {
      console.log(decision);
      let url = 'access/'+ access['uuid'] + '/manage';
      let data = {
        response: decision,
        type: request
      };
      console.log(data);
      let response = API.postItems({url, data})
        .then((res) => {
          // The record was successfully approved
          setAccess(res.data.access);
          console.log(res);
        })
        .catch((err) => {
          // An error occurred while approving the record
          console.log(err);
        });
    };


    const extendAccessRequest = (uuid, ownerUuid, accessorUuid, requirementGroupUuid, expiration, extension) => {

      let url = 'access/';
      let data = {
        uuid,
        ownerUuid,
        accessorUuid,
        requirementGroupUuid,
        expiration,
        extension
      };
      console.log(data, url);
      let response = API.patchItems({url, data})
      .then((res) => {
        setAccess(res.data.access);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
    }

    let showCard = true;
    if (props.activeOnly && !active) {
      showCard = false;
    }
    if (props.search != null && props.search != '') {
      if (accessorName.toLowerCase().includes(props.search.toLowerCase()) === false) {
        showCard = false;
      }
    }



    return (

      <>
      { access != null && showCard &&

        <Flex p={4} pl={6} pr={6} borderBottom='1px solid whitesmoke' justifyContent='space-between'>
          <Avatar w='57px' h='57px' name={accessorName} src={picture ? 'data:image/jpeg;base64,' + picture : '/images/avatars/' + Math.floor(Math.random() * 4 + 1) + '.png'}  mr='3'/>
          <Stack direction={{base: 'column', md: 'row'}} w='fill-available'>

          <Box  mr='auto'>
            <Text fontWeight='500'>
              {accessorName} {showCard}
            </Text>
            <Text fontSize='xs' color='gray.500' >
              Access from <Moment format="DD/MM/YYYY">{access['createdAt']}</Moment>
              {access['expiration'] == null &&
                '  with no expiration' }
              {access['expiration'] != null &&
                <>
                  {' until '}<Moment format="DD/MM/YYYY">{access['expiration']}</Moment> 
                </>
              }
            </Text>
            <Text fontSize='xs' color='gray.500' >
              {access.accessor && access['accessor']['organisation']}
            </Text>

            {show &&
              <>
                <Text fontSize='sm'>{accessorEmail}</Text>
                <Text fontSize='sm'>{access['accessorMessage']}</Text>
              </>
            }
          </Box>


          <Box justifySelf='flex-end'>

            <ButtonGroup isAttached size='xs' variant='outline' colorScheme={color}>

              
              <Menu>
                <MenuButton>
                  <Button>
                    {status}
                    <Icon as={MdExpandMore} ml={1}/>
                  </Button>
                </MenuButton>

                <MenuList>
                  {status==='Request Pending' &&
                  <>
                    <MenuItem onClick={() => handleRequestManageClick(true, 'request')} >Approve</MenuItem>
                    <MenuItem onClick={() => handleRequestManageClick(false, 'request')} >Decline</MenuItem>
                  </>
                  }
                  {status==='Share Pending' &&
                    <>
                      <MenuItem>Send Reminder</MenuItem>
                      <MenuItem onClick={() => handleRequestManageClick(true, 'cancel')} >Cancel</MenuItem>
                    </>
                  }
                  {status==='Active' &&
                    <MenuItem onClick={() => handleRequestManageClick(true, 'cancel')} >Cancel</MenuItem>
                  }
                  <MenuItem display='none' onClick={() => extendAccessRequest(access.uuid, access.userUuid, access.accessorUuid, access.requirementGroupUuid, null, 18)}>
                    Extend 6 months
                  </MenuItem>
                  <MenuItem display='none!important' onClick={() => handleRequestManageClick(false, 'cancel')} icon={<MdMail/>}>Message</MenuItem>
                </MenuList>
              </Menu>
            </ButtonGroup>


          </Box>
          </Stack>

          <Box ml={4} display='none'>
            <Button size='xs' onClick={() => setShow(!show)}><MdExpandMore /></Button>
          </Box>


        </Flex>

}    
        </>
  )

}