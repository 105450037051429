import React from 'react';
import Layout from '../../core/layout/Layout';
import { Flex, Button, Heading, Stack, HStack, Checkbox, Radio, RadioGroup ,SimpleGrid, Select, GridItem,  Box, Text, Wrap, Tag, Input } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react'
import API from '../../core/utils/API';
import axios from 'axios';


const ROOTAPI = process.env.REACT_APP_ROOT_SERVER_URL;

export default function Unsubscribe(props) {

    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState('');
    const [updated, setUpdated] = React.useState(false);

    const [searchParams] = useSearchParams();
    

    const { getDisclosureProps, getButtonProps } = useDisclosure()

    const buttonProps = getButtonProps()
    const disclosureProps = getDisclosureProps()

    const submitForm = () => {

        setError('');
        if (!document.getElementById('email').value) {
            setError('Please enter an email address');
            return;
        }
        if (!document.getElementById('UnsubscribeFromPromotionalEmails').checked && !document.getElementById('UnsubscribeFromLoginEmails').checked && !document.getElementById('UnsubscribeFromNotificationEmails').checked) {
            setError('Please select at least one type of email to unsubscribe from');
            return;
        }
        if (!value) {
            setError('Please select a reason for unsubscribing');
            return;
        }
        let data = {
            email: document.getElementById('email').value,
            reason: value,
            UnsubscribeFromPromotionalEmails: document.getElementById('UnsubscribeFromPromotionalEmails').checked,
            UnsubscribeFromLoginEmails: document.getElementById('UnsubscribeFromLoginEmails').checked,
            UnsubscribeFromNotificationEmails: document.getElementById('UnsubscribeFromNotificationEmails').checked,
        }

        console.log(data);
        let response = axios.post(ROOTAPI + 'unsubscribe', data).then(response => {
            console.log(response);
            if (response.status === 200) {
                //window.location.href = '/unsubscribe-success';
                setUpdated(true);
            }
        }).catch(error => {
            console.log(error);
            if (error.response.status === 400) {
                
                setError(error.response.data.message);
            } else {
                setError(error.message);
            }
        });


    }



    return (
        <Layout title="">

            <GridItem colSpan={12} mt={-16}>
                <Stack spacing={4} maxW='700px' m='auto'>

                    <Heading size='smalltitle' pb={8}>Unsubscribe</Heading>

                    {updated && <Text color='green'>Your preferences have been updated</Text>}

                    {error &&    <Text color='red'>{error}</Text> }

                    {!updated &&
                    <>
                    <Stack spacing={4} pb={4}>
                        <Heading size='label' fontSize='md' >Email to unsubscribe</Heading>
                        {searchParams.get('email') ?
                            <Input id='email' placeholder="Email" disabled value={searchParams.get('email')} />
                            :
                            <Input id='email' placeholder="Email" />
                        }
                    </Stack>

                    <Stack spacing={4} pb={4}>
                        <Flex justifyContent='space-between'>
                            <Heading size='label' fontSize='md'>Select the types of e-mails to unsubscribe from</Heading>
                            <Button display={{base: 'none', md: 'inherit'}} {...buttonProps} w='fit-content' colorScheme='blue' size='xs' variant='outline' className='btn-8'>Toggle Advanced Options</Button>
                        </Flex>
                        <Box>
                            <HStack>
                                <Checkbox id='UnsubscribeFromPromotionalEmails'>Promotional Emails</Checkbox>
                                <Tag colorScheme='blue' size='sm'>Helpful</Tag>
                            </HStack>
                            <Text fontSize='sm' color='subtle' pl={7}>These are critical to being able to log in, we strongly recommend that you do not block the sending of these e-mails.</Text>
                        </Box>
                        <Box {...disclosureProps}>
                            <HStack>
                                <Checkbox id='UnsubscribeFromLoginEmails' colorScheme='red'>Login Emails</Checkbox>
                                <Tag colorScheme='red' size='sm'>Critical</Tag>
                            </HStack>
                            <Text fontSize='sm' color='subtle' pl={7}>These are critical to being able to log in, we strongly recommend that you do not block the sending of these e-mails or you will not be able to recieve your login codes.</Text>
                        </Box>

                        <Box {...disclosureProps}>
                            <HStack>
                                <Checkbox id='UnsubscribeFromNotificationEmails' colorScheme='red'>Notification Emails</Checkbox>
                                <Tag colorScheme='red' size='sm'>Important</Tag>
                            </HStack>
                            <Text fontSize='sm' color='subtle' pl={7}>You recieve these when someone requests access to your records or there are important updates to inform you of.</Text>
                        </Box>
                        <Button display={{base: 'inherit', md: 'base'}} {...buttonProps} w='fit-content' colorScheme='blue' size='xs' variant='outline' className='btn-8'>Toggle Advanced Options</Button>

                    </Stack>

                    <Stack spacing={4} pb={4}>
                        <Heading size='label' fontSize='md'>Select the types of e-mails to unsubscribe from</Heading>

                        <RadioGroup onChange={setValue} value={value}>
                            <Stack stack={4}>
                                <Radio value='notme'>I never signed up</Radio>
                                <Radio value='toomany'>Too many emails</Radio>
                                <Radio value='irrelevant'>They aren't relevant to me</Radio>
                                <Radio value='changedmind'>I am no longer interested</Radio>
                            </Stack>
                        </RadioGroup>

                    </Stack>
                    
                    <Box>
                    <Button onClick={()=>submitForm()} colorScheme='red' variant='outline' className='btn-8'>Unsubscribe</Button>
                    </Box>
                    </>
}
                </Stack>
            </GridItem>
        </Layout>
    )
}
