import React, { useState } from 'react';
import Moment from 'react-moment';
import { Icon, Link, Tag, TagLabel, Heading, IconButton, Box, Stack, Button, Text, Flex, Spacer, HStack, Collapse, useDisclosure } from '@chakra-ui/react';
import {MdReply, MdCheck, MdClose, MdFlag} from 'react-icons/md';
import { Link as ReachLink } from "react-router-dom";

export default function Message(props) {

    let [message, setMessage] = useState(props.message);
    let [expand, setExpand] = useState(false);

    let myMessage = props.recipientUuid === message.userUuid;

    function formatText(rawText) {

      let formattedText = rawText.split("*").map((text, i) => {
        if (i % 2 === 0) {
          let italicText = text.split("_").map((text, i) => {
            if (i % 2 === 0) {
              return text;
            } else {
              return <Text as='span' fontStyle='italic'>{text}</Text>;
            }
          });

          return italicText;
        } else {

          let italicText = text.split("_").map((text, i) => {
            if (i % 2 === 0) {
              return text;
            } else {
              return <Text as='span' fontStyle='italic'>{text}</Text>;
            }
          });
          return <Text as='span' fontWeight='bold'>{italicText}</Text>;
        }
      });

      return formattedText;

    }

    return (

        <Box id={'message-' + message.uuid} className='message' borderRadius='md' boxShadow='sm' p={4} pb={1} maxW='80%' 
          alignSelf={myMessage ? 'flex-start' : 'flex-end'}
          bg={myMessage ? '#EEECED' : '#DEF6D7'}
          ml={myMessage ? '0' : '40px'}
          mr={myMessage ? '40px' : '0'}
          position='relative'
          >
          

          <IconButton 
            className={props.messageUuid === message.uuid ? '' : 'show_on_parent_hover'}
            justifySelf='center'
            position='absolute'
            left={myMessage ? 'auto' : '-40px'}
            right={myMessage ? '-40px' : 'auto'}
            transform={myMessage ? 'scaleX(1) translateY(-50%)' : 'scaleX(-1) translateY(-50%)'}
            top='50%'
            icon={<MdReply />} 
            variant={props.messageUuid === message.uuid ? 'solid' : 'ghost'}
            colorScheme={props.messageUuid === message.uuid ? 'blue' : 'gray'}
            size='sm'
            onClick={() => {props.reply(message.uuid);}}
            />

          {message.replyUuid &&
            <Box ml={-2} mr={-2} mt={-2} cursor='pointer' onClick={() => props.showMessage('message-'+message.replyUuid)} mb={4} borderRadius='md' p={2} bg='gray.50'>
              <Text fontWeight='bold' fontSize='xs' color='black'>
                {message.replyTo.subject}
              </Text>
              <Text noOfLines={1} fontSize='xs' color='black'>{formatText(message.replyTo.body)}</Text>
            </Box>
          
          }

          <Text fontWeight='bold'>
            {message.subject}
          </Text>
          
          {message.body.split('<br/>').map((rawText, i) => {

            return <Text key={i} fontSize='sm' mb={2}>{formatText(rawText)}</Text>;

          })}

          <HStack pt={2} w='fill-available' justifyContent='space-between'>
            <Text fontSize='xs' color='subtle'>
              <Moment format="HH:mm DD/MM/YY">
              {message.createdAt}</Moment>

            {(message.flag === true || message.flag ==='true') &&
              <Tag colorScheme='red' variant='subtle' fontSize='8px' padding='4px 6px !important' minH='fit-content' borderRadius='full' ml={2}>
                <TagLabel>Important</TagLabel>
              </Tag>
            }
              
            </Text>


            <HStack mr='-0.5em'>
            
              <Box w='24px' pt='4px' color={message.viewed === null ? 'subtle' : 'green'}>
                <Icon as={MdCheck} />
                <Icon ml='-12px' as={MdCheck} />
              </Box>
              </HStack>
          </HStack>

        </Box>

    )
}
