

import React from 'react';
import {RouterProvider, Route, createBrowserRouter, createRoutesFromElements, Navigate} from 'react-router-dom';
import './App.scss';
import { Outlet } from 'react-router-dom';

// Core layout pages
import Blank from './static/layout/Blank';
import RootLayout from './static/layout/RootLayout';
import ErrorPage from './static/layout/ErrorPage';
import Index from './static/layout/Index';
import Loading from './static/layout/Loading';

// Authentication - Cognito
import Cognito from './auth/cognito/Cognito';
import Login from './auth/cognito/Login';
import Authenticator from './auth/Authenticator';

// Security Pages
import {ProtectedRoute} from "./auth/ProtectedRoute";
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedRouteMFA from './auth/ProtectedRouteMFA';
import CheckRegistration from './auth/CheckRegistration'; 
import CheckRegistration2 from './auth/CheckRegistration2';
import FailedCookies from './auth/FailedCookies';
import MFAWrapper from './auth/cognito/components/MFAWrapper';
import { KyberEncryption } from './auth/KyberEncryption';
import SubscriptionProtectedRoute from './auth/SubscriptionWrapper';

// Static Pages 
import Home from './static/home/Home';
import About from './static/about/About';
import Features from './static/features/Features';
import Pricing from './static/pricing/Pricing';
import WorkflowOrganisations from './static/workflow/WorkflowOrganisations';
import WorkflowProfessionals from './static/workflow/WorkflowProfessionals';
import WorkflowPay from './static/workflow/WorkflowPay';

// User Pages
import Passport from './core/dashboard/Dashboard'; // needs to be moved
import Dashboard from './my/Dashboard';
import AccessList from './access/AccessList';
import Unsubscribe from './users/pages/Unsubscribe';
import Onboarding from './users/onboarding/Onboarding';
import Inbox from './my/messages/Inbox';
import Conversation from './my/messages/Conversation';
import NotificationsAll from './my/notifications/NotificationsFullList';
import Subscriptions from './my/subscriptions/Subscriptions';
import MyForms from './my/forms/MyForms';
import RoleSelect from './my/RoleSelect';

// DBS
import DBSOverview from './my/yoti/Overview';
import DBSApply from './my/yoti/Apply';
import DBSList from './my/dbs/DBSList';
import Success from './my/dbs/Success';
import Error from './my/dbs/Error';

// AI
import AIReflection from './my/ai/Reflection';
import AIReflections from './my/ai/Reflections';
import AIReflectionView from './my/ai/ReflectionView';
import AITeaching from './my/ai/Teaching';
import AITeachingView from './my/ai/TeachingView';
import AITeachings from './my/ai/Teachings';
import Calendar from './my/ical/Schedules';

// Organisations
import Organisations from './admin/organisations/Organisations';
import Organisation from './admin/organisations/Organisation';
import OrganisationsInterceptor from './admin/organisations/Interceptor';
import OrganisationLanding from './organisations/Landing';
import OrganisationDashboard from './organisations/landing/Dashboard';
import OrganisationRoles from './organisations/roles/RoleUsers';
import OrganisationDetails from './organisations/details/Details';
import OrganisationAccounts from './organisations/accounts/Accounts';
import OrganisationAccountsDevelopment from './organisations/accounts/AccountsDevelopment';
import OrganisationAccountsSettings from './organisations/accounts/settings/AccountsSettings';
import OrganisationClients from './organisations/accounts/Clients';
import OrganisationReports from './organisations/reports/Reports';
import OrganisationSubscriptions from './organisations/subscriptions/Subscriptions';
import OrganisationOnboarding from './organisations/onboarding/Onboarding';
import OrganisationTest from './organisations/testing/Test';
import OrganisationManageSubscriptions from './organisations/subscriptions/ManageSubscriptions';
import OrganisationSubscriptionsWrapper from './organisations/subscriptions/SubscriptionsWrapper';

import Flow from './organisations/flows/Flow';
import FlowDashbaord from './organisations/flows/Dashboard';
import FlowBids from './organisations/flows/flow/Bids';
import FlowNew from './organisations/flows/New';

import Jobs from './organisations/jobs/Jobs';
import Job from './organisations/jobs/Job';
import Apply from './organisations/jobs/Apply';
import Interview from './organisations/jobs/Interview';
import Decision from './organisations/jobs/Decision';

// Prospective Leave
import ProspectiveLeaveOverview from './my/prospectiveleave/Overview';
import ScheduleProcess from './my/pay/ScheduleProcess';
import Schedule from './my/pay/Schedule';
import Schedules from './my/pay/Schedules';
import ScheduleSelect from './my/pay/manage/Select';
import ScheduleQuestions from './my/pay/manage/Questions';


// MIXED
import Assistant from './mixed/assistant/Assistant';


// HR
import HRRecords from './core/dashboard/Records'; // needs to be moved
import HRSummary from './hr/dashboard/Summary';
import Form from './hr/forms/Form';
import Forms from './hr/forms/Forms';
import UserImport from './hr/users/Import';

// HR - Complaints
import SubmitComplaint from './hr/complaints/Submit';
import Complaints from './hr/complaints/Complaints';
import Complaint from './hr/complaints/Complaint';
import ComplaintsInsights from './hr/complaints/Insights';

// Shared pages
import Document from './documents/Document';
import ProfilePage from './users/pages/ProfilePage';

// Management pages
import Admin from './admin/Admin';
import Users from './users/pages/Users';
import Requirements from './requirements/pages/Requirements';
import RequirementTags from './requirements/pages/RequirementTags';
import RequirementGroup from './requirements/pages/RequirementGroup';
import EmailTemplates from './admin/email/EmailTemplates';
import Usage from './admin/monitoring/Usage';
import Attributes from './admin/attributes/Attributes';
import Encryption from './admin/encryption/Encryption';
import Permissions from './admin/permissions/Permissions';

// Development pages
import WebSocket from './development/WebSocket';
import Design from './development/Design';
import Reflection from './development/Reflection';
import Graphs from './development/Graphs';
import EncryptionTest from './development/Encryption';
import DevelopmentBlank from './development/Blank';
import CheckForm from './development/Checks/CheckForm';
import Poster from './development/Checks/Poster';
import AgentChat from './development/agents/Chat';
import RegistrationAPI from './development/registration/RegistrationAPI';

import { ScrollRestoration } from "react-router-dom";

import { hotjar } from 'react-hotjar';

class RootRouteComponent extends React.Component {
  
  componentDidMount() {
    //console.log("RootRouteComponent Mounted");
    hotjar.initialize('3647265', 6);
    hotjar.event('button-click');
  }

  render() {

    return (
      <div>
        <Outlet />
        <ScrollRestoration />
      </div>
    );

  }
}

const router = createBrowserRouter(
    createRoutesFromElements(
      <>

      <Route path='/' element={<RootRouteComponent/>} >

        <Route path='accounts' element={<OrganisationAccounts/>} />

        <Route path='pals' element={<Blank />}>
          <Route path='feedback' element={<SubmitComplaint />} />
          <Route path='feedback/:uuid' element={<SubmitComplaint />} />
          <Route path='echeck' element={<CheckForm />} />
          <Route path='accounts' element={<OrganisationAccounts />} />
        </Route>

        <Route path='echecks' element={<Blank />}>
          <Route path='form' element={<CheckForm />} />
          <Route path='poster' element={<Poster />} />
        </Route>

        <Route path='assistant' element={<Assistant />} />
        
          
        <Route path='orgtest' element={<RootLayout hideNav={true}/>}  errorElement={<ErrorPage/>}>

          <Route path='procurement' element={<OrganisationsInterceptor test='true' hideNav={true}/>}>
            <Route index element={<FlowDashbaord/>} />
            <Route path='flow' element={<Flow/>} />
            <Route path='bids' element={<FlowBids />} />
            <Route path='create' element={<FlowNew />} />
          </Route>

          <Route path='flow' element={<Flow />} />

            <Route path='check' element={<OrganisationSubscriptionsWrapper product='Invoice Automation' />} >
            </Route>  

            <Route path='check2' element={<OrganisationSubscriptionsWrapper product='block' />} >
            </Route>  

          <Route path='test' element={<OrganisationTest/>} />

          <Route path='manage' element={<OrganisationManageSubscriptions />} />

          <Route path='accounts-development' element={<OrganisationAccountsDevelopment />} /> 
          <Route path='accounts-settings' element={<OrganisationAccountsSettings />} />
          <Route path='onboarding' element={<OrganisationOnboarding />} />

          <Route path='agents' element={<AgentChat />} />
          <Route path='registration' element={<RegistrationAPI />} />
          <Route path='dashboard' element={<OrganisationDashboard />} />
          <Route path='subscriptions' element={<OrganisationSubscriptions />} />
          
          <Route path='jobs' element={<Blank />} >

          <Route index element={<Jobs />} />
            <Route path='job' element={<Job />} />
            <Route path='apply' element={<Apply />} />
            <Route path='review' element={<Apply marking={true} />} />
            <Route path='interview' element={<Interview />} />
            <Route path='applicant' element={<Decision />} />
          </Route>
        </Route>

        <Route path='user' element={<Blank />}>
          <Route path='import' element={<UserImport/>}/>
        </Route> 

        <Route index element={<Navigate to='/login' />} />

        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Login action='Signup' />} />


        <Route path='/'  element={<RootLayout />} errorElement={<ErrorPage/>}>



          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='loading' element={<Loading />} />
          <Route path='features' element={<Features />} />
          <Route path='pricing' element={<Pricing />} />
          <Route path='workflow' element={<WorkflowOrganisations />} />
          <Route path='workflowpro' element={<WorkflowProfessionals />} />
          <Route path='workflowpay' element={<WorkflowPay />} />
          <Route path='prospectiveleave' element={<ProspectiveLeaveOverview />} />

  

          <Route path='failedcookies' element={<FailedCookies />} />

          <Route path='authenticator' element={<Authenticator />} />

          <Route path='assistant' element={<Assistant />} />

            
          <Route exact path='/' element={<ProtectedRoute/>}>


          
            <Route path="checkregistration2" element={<CheckRegistration2/>}/>
            <Route path="callback" exact />
            <Route path='loading' element={<Loading />} />

            <Route path="pay" element={<Blank />} >

              <Route path='uploadschedule' element={<ScheduleProcess />} />
              <Route path='schedule' element={<Schedule />} />
              <Route path='schedules' element={<Schedules />} />
              <Route path='schedules' element={<Blank />} >
                <Route path='select' element={<ScheduleSelect />} />
                <Route path='questions' element={<ScheduleQuestions />} />
              </Route>
              <Route path='select' element={<ScheduleSelect />} />

            </Route>


            <Route path='onboarding' element={<Onboarding />} />

            <Route path='my' element={<Blank />}>

              <Route path='assistant' element={<Assistant />} />

              <Route path='organisations' element={<RoleSelect />} />

              <Route path='dbs' element={<Blank />}>
                <Route index element={<DBSOverview />} />
                <Route path='idcheck' element={<DBSApply />} />
                <Route path='list' element={<DBSList />} />
                <Route path='success' element={<Success />} />
                <Route path='error' element={<Error />} />
              </Route>

              <Route path='forms' element={<Blank />}>
                <Route index element={<MyForms />} />
                <Route path="" element={<MFAWrapper />} >
                  <Route path='view' element={<Form />} />
                </Route>
              </Route>

              <Route path='dashboard' element={<Dashboard />} />
              <Route path='subscriptions' element={<Subscriptions />} />

              <Route path='calendar' element={<Calendar />} />

              <Route path="ai" element={<SubscriptionProtectedRoute requiredSubscription='prod_O0voJs06DaJcY7' />} >
                <Route path='reflection' element={<Blank />}>
                  <Route path='generate' element={<AIReflection />} />
                  <Route path='view' element={<AIReflectionView />} />
                  <Route path='' element={<AIReflections />} />
                </Route>
                <Route path='teaching' element={<Blank />}>
                  <Route path='generate' element={<AITeaching />} />
                  <Route path='view' element={<AITeachingView />} />
                  <Route path='' element={<AITeachings />} />
                </Route>
                <Route path='calendar' element={<Blank />} >
                  <Route path='list' element={<Calendar />} />
                </Route>
              </Route>

              <Route path="" element={<SubscriptionProtectedRoute requiredSubscription="prod_O3gaseKK6VJS04" allowTrial={true} />} >
                <Route path='passport' element={<Passport />} />
              </Route>

              <Route path='notifications' element={<NotificationsAll/>} />

              <Route element={<MFAWrapper />} >
                <Route path='access' element={<AccessList />} />
                <Route path='inbox' element={<Inbox />} />
                <Route path='conversation' element={<Conversation />} />
                <Route path='document' element={<Document />} />
              </Route>

              <Route element = {<MFAWrapper />} >
                <Route path='profile' element={<ProfilePage />} />
                <Route path='auth' element={<Cognito />} />
                <Route path='unsubscribe' element={<Unsubscribe />} />
              </Route>

            </Route>

            
            <Route path='hr' element={<Blank />}>
              <Route path='summary' element={<HRSummary />} />
              <Route element={<MFAWrapper />} >
                <Route path='record' element={<HRRecords />} />
              </Route>
              <Route path='pals' element={<Blank />} >
                <Route index element={<Complaints />} />
                <Route path='view' element={<Complaint />} />
                <Route path='insights' element={<ComplaintsInsights />} />
              </Route>
            </Route>


            <Route path="" element={<SubscriptionProtectedRoute requiredSubscription='admin' />} >
            
              <Route exact path='/' element={<MFAWrapper />} >
            
                <Route path='admin' element={<Admin />}>
                  <Route index element={<Index />} />
                  <Route path='group' element={<RequirementGroup />} />
                  <Route path='requirements' element={<Requirements />} />
                  <Route path='requirementtags' element={<RequirementTags />} />
                  <Route path='users' element={<Users />} />
                  <Route path='emailtemplates' element={<EmailTemplates />} />
                  <Route path='usage' element={<Usage />} />
                  <Route path='attributes' element={<Attributes />} />
                  <Route path='forms' element={<Blank/>}>
                    <Route index element={<Forms/>}/>
                    <Route path='view' element={<Form/>}/>
                  </Route>
                  <Route path='encryption' element={<Encryption />} />
                  <Route path='organisations' element={<Blank />}>
                    <Route index element={<Organisations />} />
                    <Route path='view' element={<Organisation />} />
                  </Route>
                  <Route path='permissions' element={<Permissions />} />
                </Route>
            
              </Route>

            </Route>


            
            <Route path='organisation' element={<OrganisationsInterceptor />} 
              // Interceptor has MFA Wrapper in it
              >

                <Route index element={<OrganisationDashboard />} />

                <Route path='jobs' element={<Blank />} >

                  <Route index element={<Jobs />} />
                  <Route path='job' element={<Job />} />
                  <Route path='apply' element={<Apply />} />
                  <Route path='review' element={<Apply marking={true} />} />
                  <Route path='interview' element={<Interview />} />
                  <Route path='applicant' element={<Decision />} />

                </Route>
         
                <Route path='health' >
                  <Route index element={<HRSummary />} />
                  <Route path='record' element={<HRRecords />} />
                </Route>
                <Route path='compliance' >
                  <Route index element={<HRSummary />} />
                  <Route path='record' element={<HRRecords />} />
                </Route>
                <Route path='recruitment'>
                  <Route index element={<HRSummary />} />
                  <Route path='record' element={<HRRecords />} />
                </Route>
                <Route path='staff'>
                  <Route index element={<HRSummary />} />
                  <Route path='record' element={<HRRecords />} />
                </Route>

                <Route path='reports'>
                  <Route index element={<OrganisationReports />} />
                </Route>
                
                <Route path='manage' element={<OrganisationManageSubscriptions />} />
                <Route path='subscriptions' element={<OrganisationSubscriptions />} />



                <Route path='check' element={<OrganisationSubscriptionsWrapper product='Invoice Automation'  />} >
                  <Route path='accounts' element={<OrganisationAccounts />} />
                </Route>  

                <Route path='check2' element={<OrganisationSubscriptionsWrapper product='Applicant Tracking System' />} >
                  <Route path='accounts' element={<OrganisationAccounts />} />
                </Route>  


                <Route path='check3' element={<OrganisationSubscriptionsWrapper product='block' />} >
                  <Route path='accounts' element={<OrganisationAccounts />} />
                </Route>  


                <Route path='accounts' element={<OrganisationAccounts />} />
                <Route path='accounts-dev' element={<OrganisationAccountsDevelopment />} />

                <Route path='clients' element={<OrganisationClients />} />

                <Route path='feedback' element={<Blank />} >
                  <Route index element={<Complaints />} />
                  <Route path='view' element={<Complaint />} />
                  <Route path='insights' element={<ComplaintsInsights />} />
                </Route>

                <Route path='roles' element={<Blank />} >
                  <Route index element={<OrganisationRoles />} />
                  <Route path='import' element={<UserImport/>}/>
                  <Route path='users' element={<Users />} />
                </Route>

                <Route path='details' element={<OrganisationDetails />} />

            </Route>

            <Route path="development" element={<SubscriptionProtectedRoute requiredSubscription='admin' />} >
                <Route index element={<Index />} />
                <Route path='websocket' element={<WebSocket />} />
                <Route path='graphs' element={<Graphs />} />
                <Route path='design' element={<Design />} />
            </Route>
            
            <Route path="*" element={<ErrorPage message='Route not found' />}/>


            <Route path='development' element={<SubscriptionProtectedRoute requiredSubscription='admin' />} >
              <Route index element={<Index />} />
              <Route path='encryption' element={<EncryptionTest />} />
            </Route>

            <Route exact path='/' element={<KyberEncryption/>}>
              <Route path='development' element={<SubscriptionProtectedRoute requiredSubscription='admin' />} >
                <Route index element={<Index />} />
                <Route path='blank' element={<DevelopmentBlank />} />
              </Route>
            </Route>


          
          </Route>

        </Route>

        </Route>

      </>
      
  )
) 
  
function App() {

  /*const { isLoading, error, handleRedirectCallback } = useAuth0();

  if (isLoading) {
    return (
      <Loading message='Just loading a few bits' pt='90px'/>
    );
  }
  if (error) {
    return (
      <ErrorPage />
    );
  }
*/

  return (
      <RouterProvider router={router} />
  );
}


export default App;
export {router};
