let companies = [
    {
        "company_name": "Pearson Assessments",
        "logo": "https://www.pearsonclinical.co.uk/content/dam/global-store/global/images/ui/logos/pearson-logo.svg",
        "website": "https://www.pearsonassessments.com",
        "contact_email": "contactus@pearson.com",
        "description": "Pearson offers a comprehensive suite of ADHD assessment tools including rating scales, performance-based tests, and digital solutions for telepractice. Their tools are widely used in educational and clinical settings for diagnosing and managing ADHD.",
        "estimated_cost_per_assessment": "$150 - $300"
    },
    {
        "company_name": "Qbtech",
        "logo": "https://static.wixstatic.com/media/9236c2_1de5693c7af24d93b6ace7fc7d24676d~mv2.png/v1/fill/w_424,h_182,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9236c2_1de5693c7af24d93b6ace7fc7d24676d~mv2.png",
        "website": "https://www.qbtech.com",
        "contact_email": "info@qbtech.com",
        "description": "Qbtech specializes in ADHD assessments using objective measures such as the QbTest, which evaluates attention, impulsivity, and activity levels. This tool is often used in clinical settings and is suitable for integration into public health services.",
        "estimated_cost_per_assessment": "$200 - $400"
    },
    {
        "company_name": "ADHD Online",
        "logo": "https://adhdonline.com/wp-content/uploads/2022/09/ADHD-Online-Full-Color-Logo-November-2023.png",
        "website": "https://www.adhdonline.com",
        "contact_email": "support@adhdonline.com",
        "description": "ADHD Online offers a platform for comprehensive ADHD assessments through telehealth services. Their assessments are reviewed by licensed psychologists and provide a scalable solution for large populations.",
        "estimated_cost_per_assessment": "$150 - $250"
    },
    {
        "company_name": "Cognoa",
        "logo": "https://cognoa.com/wp-content/uploads/2023/05/cognoa-green.svg",
        "website": "https://cognoa.com",
        "contact_email": "info@cognoa.com",
        "description": "Cognoa develops digital health solutions for pediatric behavioral health. Their mobile app for ADHD assessment is designed for early diagnosis and intervention, making it a strong candidate for health service integration.",
        "estimated_cost_per_assessment": "$100 - $200"
    }
]



export default companies;