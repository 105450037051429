import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Image, Menu, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, MenuOptionGroup } from '@chakra-ui/react';
import API from "../../core/utils/API";
import {MdOutlineErrorOutline, MdWarning, MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos, MdList, MdInfoOutline, MdRefresh } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import Modal from '../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import ClientLink from './ClientLink';

import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import e from 'cors';

import FileView from '../../core/components/files/View';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { set } from 'date-fns';

import AccountsUpload from './AccountsUpload';

import Account from './Account';
import { load } from 'webfontloader';
import { bg } from 'date-fns/locale';


function SyncSettings(props) {

    const toast = useToast();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [secrets, setSecrets] = useState({});

    function updateSecrets(key, v) {

 

    }

    function submit() {

        setError(null);

        let options = ['tradeshift-token', 'tradeshift-secret', 'xero-client-id', 'xero-client-secret', 'etz-username', 'etz-password'];


        if (secrets === null) {
            setError(new Error('No secrets have been set.'));
            return;
        }

        setLoading(true);
        // Submit the form

        let url = 'organisations/secrets';
        let config = {
            params: {
                'secrets': [],
            }
        };

        options.forEach((option) => {

            let inp = document.getElementById(option);
            if (!inp) {
                return;
            }

            let v = inp.value;

            if (v) {
                config.params.secrets.push({
                    key: option,
                    value: v
                });
            }

        });

        let resp = API.postItems({url, data: config.params, config})
        .then(response => {
            setLoading(false);
            toast({
                title: 'Success',
                description: 'Settings have been saved.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            props.onClose();
        })
        .catch(error => {

            setError(error);
            setLoading(false);

            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

        });

    }

    return (
        <Modal 
            title='Sync Settings'
            onClose={props.onClose}
            actionContent={
                <Button colorScheme='blue' variant='solid' size='sm' onClick={submit} isLoading={loading}>
                    Save
                </Button>
            }
            >
                <Stack>

                    <Text mb={4}>
                        These are the settings for syncing invoices and expenses from various sources. Values cannot be viewed for security reasons, but can be updated from here.
                    </Text>

                    {error && 
                        <Alert status='error'>
                            <AlertTitle mr={2}>Error</AlertTitle>
                            <AlertDescription>{error.message}</AlertDescription>
                            <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                        </Alert>
                    }

                    <Stack>
                        <Heading size='label'>Tradeshift</Heading>
                        <InputWrapper 
                            id='tradeshift-token'
                            label='Token'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('tradeshift-token', value)}}
                            />

                        <InputWrapper
                            id='tradeshift-secret'
                            label='Token Secret'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('tradeshift-secret', value)}}
                            />
                    </Stack>


                    <Stack>
                        <Heading size='label'>Xero</Heading>
                        <InputWrapper 
                            id='xero-client-id'
                            label='Client ID'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('xero-client-id', value)}}
                            />

                        <InputWrapper
                            id='xero-client-secret'
                            label='Client Secret'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('xero-client-secret', value)}}
                            />
                    </Stack>

                    <Stack>
                        <Heading size='label'>ETZ</Heading>
                        <InputWrapper 
                            id='etz-username'
                            label='Username'
                            type='password'
                            autocomplete='off'
                            setValue={(value) => {updateSecrets('etz-username', value)}}
                            />
                        <InputWrapper
                            id='etz-password'
                            label='Password'
                            type='password'
                            autocomplete='off'
                            setValue={(value) => {updateSecrets('etz-password', value)}}
                            />
                    </Stack>

                </Stack>
        </Modal>
    )
}

function OverviewCard(props) {  

    let highlight = 'blue.500';
    let icon = {MdInfoOutline};
    let loading = false;
    if ((props.count === 0 && props.zeroPositive) || (!props.zeroPositive && props.count > 0)) {
        highlight = 'green.500';
        icon = {MdCheck};
    }
    if ((props.count === 0 && !props.zeroPositive) || (props.zeroPositive && props.count > 0)) {
        highlight = 'red.500';
        icon = {MdWarning};
    }
    if (props.count === null && !props.skipCount) {
        loading = true;
    }

    return (
        <GridItem 
            >
            <HStack
                p={4}
                borderRadius='md'
                boxShadow='md'
                bg='white'
                _hover={{   
                    boxShadow: 'md',
                    cursor: 'pointer',
                    bg: 'blue.50',
                }}
                onClick={() => {
                    props.updateFilters(props.filters);
                    props.setTemplate(props.cardKey);
                    props.setSubtitle(props.title);
                }}
                borderBottom='2px solid white'
                borderColor={props.active ? 'blue.500' : 'transparent'}
                >
                
                <HStack>
                    <Box mr={2}>
                        {loading && <CircularProgress isIndeterminate size='32px' color={highlight} />}
                        {!loading && highlight === 'blue.500' && <Icon as={MdInfoOutline} color={highlight} boxSize='32px'/>}
                        {!loading && highlight === 'green.500' && <Icon as={MdCheck} color={highlight} boxSize='32px' />}
                        {!loading && highlight === 'red.500' && <Icon as={MdOutlineErrorOutline} color={highlight} boxSize='32px' />}
                    </Box>
                    <Stack w='fill-available'>
                        <Box>

                        <Heading size='label'>{props.title}</Heading>
                        <Text>
                            {props.skipCount !== true &&
                                <>
                                    {props.count !== null && props.count + ' ' + props.message}
                                    {props.count === null && 'Checking ' + props.message}
                                </>
                            }
                            {props.skipCount === true && props.message}
                        </Text>
                        </Box>
                        <Flex alignItems='space-between' display='none'>
                            <></>
                            <Button size='sm' colorScheme='blue' variant='outline'
                                onClick={props.onClick}
                                >
                                View
                            </Button>

                        </Flex>
                    </Stack>
                </HStack>
            </HStack>
        </GridItem>


    )

}

export default function Accounts(props) {

    let templateCards = {
        'sent': {
            title: 'Completed Invoices',
            message: 'sent to clients',
            count: null,
            zeroPositive: false,
            filters: {invoicePDF: 'Linked', tradeshift: 'Sent', client: 'Linked', hide: false, showFilters: false},
        },
        'outstanding': {
            title: 'Outstanding Invoices',
            message: 'invoices not sent',
            count: null,
            zeroPositive: true,
            filters: {invoicePDF: 'Linked', tradeshift: 'Not Sent', client: 'Linked', hide: false, showFilters: false},
        },
        'incomplete': {
            title: 'Incomplete Invoices',
            message: 'missing timesheets',
            count: null,
            zeroPositive: true,
            filters: {invoicePDF: 'Missing', tradeshift: 'None', client: 'Linked', hide: false, showFilters: false},
        },
        'orphaned': {
            title: 'Outstanding Timesheets',
            message: 'unattached to invoices',
            count: null,
            zeroPositive: true,
            filters: {invoicePDF: 'None', tradeshift: 'None', client: 'None', hide: true, showFilters: false},
        },
        'error': {
            title: 'Errors',
            message: 'invoices with errors',
            count: null,
            zeroPositive: true,
            filters: {invoicePDF: 'Linked', tradeshift: 'Error', client: 'Linked', hide: false, showFilters: false},
        },
        'skipping': {
            title: 'Skipped Invoices',
            message: 'missing clients',
            count: null,
            zeroPositive: true,
            filters: {invoicePDF: 'None', tradeshift: 'None', client: 'Missing', hide: false, showFilters: false},
        },
        'custom': {
            title: 'Custom Filters',
            message: 'Filter by client, date, or status',
            count: null,
            skipCount: true,  
            filters: {invoicePDF: 'None', tradeshift: 'None', client: 'None', hide: false, showFilters: true},
        }
    }


    const [cards, setCards] = useState(templateCards);

                
    const [showPDFs, setShowPDFs] = useState(false);
    const [pdfs, setPDFs] = useState({});

    const [timeframe, setTimeframe] = useState({name: 'This Week', from: moment().startOf('isoWeek'), to: moment().endOf('isoWeek')});
    
    const [accounts, setAccounts] = useState(null);
    const [clients, setClients] = useState([]);
    const [summaryLoading, setSummaryLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSettings, setShowSettings] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [upload, setUpload] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [subtitle, setSubtitle] = useState("");
    const [template, setTemplate] = useState(null);

    let templateFilters = {
        lines: 'None', // None, Complete, Missing - this is whether invoice details have been loaded - this is legacy
        client: 'None', // None, Linked, Missing
        invoicePDF: 'None', // None, Linked, Missing
        tradeshift: 'None', // None, Preparing, Incomplete, Attached, Draft, Sent, Error
        // Now we want to filter from most recent Monday to Sunday
        order: 'updatedAt', // due, updatedAt, createdAt
        orderBy: 'DESC', // DESC, ASC
        refresh: true,
        //showFilters: false,
    };
    const [filters, setFilters] = useState({
        ...templateFilters,
        from: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        to: moment().endOf('isoWeek').format('YYYY-MM-DD'),
        hide: true,
        showFilters: false,
        clients: [], // Array of client uuids,
    });

    const [multipleSelect, setMultipleSelect] = useState(false);
    const [selected, setSelected] = useState([]);
    const [bulkActioning, setBulkActioning] = useState(false);

    const [updateFilterDate, setUpdateFilterDate] = useState(null);
    const [updateFilterClients, setUpdateFilterClients] = useState(null);
    const [filterClients, setFilterClients] = useState([]);

    let toast = useToast();
    let navigate = useNavigate();


    function updateFilters(updates) {

        
        let tmpFilters = {...filters};

        // Loop through template filters
        Object.keys(templateFilters).map((key) => {
            tmpFilters[key] = templateFilters[key];
        })

        for (let key in updates) {
            tmpFilters[key] = updates[key];
        }

        if (filters.refresh === true && filters.hide === false) {

            setLoading(true);
        
        }

        console.log("Filters", tmpFilters);

        // If there is no showPDFs then we want to hide the PDFs
        if (tmpFilters.showPDFs === false || tmpFilters.showPDFs === null) {
            setShowPDFs(false);
        } else {
            setShowPDFs(true);
            setLoading(false);
        }

        setFilters(tmpFilters);

    }

    // Refresh accounts if filters change

    useEffect(() => {
        if (filters.refresh === true && filters.hide === false) {
            getAccounts(false);
        }
    }, [filters, page, limit, timeframe]);

    useEffect(() => {
        getAccountsSummary();
    }, [timeframe]);

    function getAccountsSummary(notify) {

        setCards(templateCards);
        setTemplate(null);
        setError(null);
        setLoading(true);
        setAccounts(null);
        setPDFs({});

        let uuid = secureLocalStorage.getItem('organisationUuid');
        let url = 'organisations/accounts/view/summary';
        let config = {
            params: {
                from: timeframe.from.format('YYYY-MM-DD'),
                to: timeframe.to.format('YYYY-MM-DD')
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log(response.data);

            let tmpCards = {...cards};
            // Loop through response.data.totals
            for (let key in response.data.totals) {
                if (tmpCards[key]) {
                    tmpCards[key].count = response.data.totals[key];
                }
            }
            setCards(tmpCards);

            setClients(response.data.clients);
            setPDFs(response.data.orphanedPDFs);

            if (notify) {
                toast({
                    title: 'Accounts Summary',
                    description: 'Accounts summary has been successfully refreshed.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setError(error);
            setLoading(false);
        });



    }

    function getAccounts(notify) {

        //setLoading(true);
        setError(null);
        setLoading(true);
        setAccounts(null);


        let uuid = secureLocalStorage.getItem('organisationUuid');
        let url = 'organisations/accounts/view';
        let config = {
            params: {
                page,
                limit,
                filter: {
                    ...filters
                }
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log(response.data);
            let toastNotification = true;
            if (accounts && accounts.length === 0) {
                toastNotification = false;
            }

            /* LEGACY
            // If any accounts with lines null or {} then show warning
            let awaitingAnalysis = response.data.accounts.find((account) => {
                return account.lines === null || account.lines === {};
            });
            if (awaitingAnalysis) {
                setShowSyncWarning(true);
            }
            */


            console.log("Accounts", response.data.accounts);
            setAccounts([...response.data.accounts]);

            if (toastNotification && notify) {
                toast({
                    title: 'Accounts Updated',
                    description: 'Accounts have been successfully refreshed.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setError(error);
            setLoading(false);
        });
        

    }

    function syncInvoices(source = 'xero') {

        setSubmitting(true);
        
        let uuid = secureLocalStorage.getItem('organisationUuid');

        let url = 'organisations/accounts/sync';

        // Convert YYYY-MM-DD to ISO string
        let from = new Date(filters['from']);
        let to = new Date(filters['to']);

        let params = {
            source: source,
            from: from.toISOString(),
            to: to.toISOString()
        }

        
        let config = {
            params
        };

        console.log(config);

        let resp = API.getItems({timeout: 60, url, config})
        .then(response => {

            let { message} = response.data;
            
            toast({
                title: 'Success',
                description: message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });


            setSubmitting(false);
            getAccounts();

        })
        .catch(error => {
            console.log(error);
            setError(error);
            setSubmitting(false);
        });

    }



    return (

        <Layout 
            layout='dense'
            title={<>Accounts<Tag variant='solid' ml={2} colorScheme='blue' size='lg'>Development</Tag></>}
            description={'This is a summary of the various invoices and timesheets for your organisation, from ' + timeframe.from.format('dddd, MMMM Do YYYY') + ' to ' + timeframe.to.format('dddd, MMMM Do YYYY') + '.'}
            actionContent={ 
                <ButtonGroup>
                    <ButtonGroup 
                        colorScheme='blue'
                        variant='outline'
                        >

                        <Menu>
                            <MenuButton as={Button}
                                rightIcon={<MdExpandMore />}
                                isLoading={submitting}
                                >
                                    Actions
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => {syncInvoices('xero')}}>Sync Xero Invoices</MenuItem>
                                <MenuItem onClick={() => {setUpload(true)}}>Upload Invoices</MenuItem>
                                <MenuItem display='none' onClick={() => {setShowSettings(true)}}>Settings</MenuItem>
                                <MenuItem onClick={() => {getAccountsSummary(true)}}>Refresh Summary</MenuItem>
                                <MenuItem onClick={() => {getAccounts(true)}}
                                    isDisabled={filters['hide'] === true}
                                    >Refresh Accounts</MenuItem>
                                <MenuItem onClick={() => {setMultipleSelect(true)}}
                                    isDisabled={accounts && accounts.length === 0}
                                    >Select Multiple</MenuItem>
                            </MenuList>
                        </Menu>

                        <Menu>
                            <MenuButton as={Button}
                            disabled={submitting}
                            rightIcon={<MdExpandMore />}
                            >
                                Issued {timeframe.name}
                            </MenuButton>
                            <MenuList>
                                {[
                                    {name: 'This Week', from: moment().startOf('isoWeek'), to: moment().endOf('isoWeek')},
                                    {name: 'Last Week', from: moment().subtract(1, 'week').startOf('isoWeek'), to: moment().subtract(1, 'week').endOf('isoWeek')},
                                    {name: 'This Month', from: moment().startOf('month'), to: moment().endOf('month')},
                                    {name: 'Last Month', from: moment().subtract(1, 'month').startOf('month'), to: moment().subtract(1, 'month').endOf('month')},
                                    {name: 'This Quarter', from: moment().startOf('quarter'), to: moment().endOf('quarter')},
                                    {name: 'Last Quarter', from: moment().subtract(1, 'quarter').startOf('quarter'), to: moment().subtract(1, 'quarter').endOf('quarter')},
                                    {name: 'This Year', from: moment().startOf('year'), to: moment().endOf('year')},
                                    {name: 'Last Year', from: moment().subtract(1, 'year').startOf('year'), to: moment().subtract(1, 'year').endOf('year')}
                                ].map((timeframe) => {
                                    return (
                                        <MenuItem onClick={() => {
                                            setTimeframe(timeframe);
                                            updateFilters({from: timeframe.from.format('YYYY-MM-DD'), to: timeframe.to.format('YYYY-MM-DD')});
                                        }}>{timeframe.name}</MenuItem>
                                    )
                                })}
                            </MenuList>

                        </Menu>
                    </ButtonGroup>
                </ButtonGroup>
            }
            >

{showSettings && <SyncSettings onClose={() => {setShowSettings(false)}} />}

<>
            <GridItem colSpan='12'>


                {error && <Alert status='error'mb={4}>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }

                <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing={4} pb={12}>

                    {Object.keys(cards).map((key) => {
                        return (
                            <OverviewCard 
                                cardKey={key}
                                title={cards[key].title}
                                message={cards[key].message}
                                count={cards[key].count}
                                skipCount={cards[key].skipCount}
                                zeroPositive={cards[key].zeroPositive}
                                filters={cards[key].filters}
                                updateFilters={updateFilters}
                                setTemplate={setTemplate}
                                setSubtitle={setSubtitle}
                                active={key === template}
                                />
                        )
                    })}

                </SimpleGrid>
            </GridItem>

            <GridItem colSpan={12} pb={48}>

                {updateFilterDate && 
                    <Modal title='Filter by Issued Date' onClose={() => {setUpdateFilterDate(null)}}
                        >
                        <Stack>
                            <Text>From:</Text>
                            <Input id='from' label='From' type='date' 
                                onChange={(event) => {
                                    updateFilters({from: event.target.value});
                                }}
                                />
                            <Text>To:</Text>
                            <Input id='to' label='To' type='date' 
                                onChange={(event) => {
                                    updateFilters({to: event.target.value});
                                }}
                                />
                        </Stack>
                    </Modal>
                }

                {updateFilterClients &&
                
                    <Modal title='Filter Clients' onClose={() => {setUpdateFilterClients(null)}}
                        key={'update-filter-clients-' + updateFilterClients}
                        >

                        <Text pb={4}>
                            Select clients to filter by.
                        </Text>

                        <Stack spacing={1}>
                            
                            {clients && Object.keys(clients).map((uuid) => {

                                return (
                                    <Checkbox key={uuid} value={uuid} 
                                        isChecked={filters['clients'].includes(uuid)}
                                        onChange={(event) => {
                                        let tmpFilterClients = [...filters['clients']];
                                        let index = tmpFilterClients.indexOf(event.target.value);
                                        if (index === -1) {
                                            tmpFilterClients.push(event.target.value);
                                        } else {
                                            tmpFilterClients.splice(index, 1);
                                        }
                                        updateFilters({clients: tmpFilterClients, hide: false});
                                    }}>
                                        {clients[uuid]}
                                    </Checkbox>
                                )
                            })}
                        </Stack>

                    </Modal>

                
                }

                {upload && <AccountsUpload onClose={() => {
                    setUpload(false);
                    }} 
                    refresh={() => {
                        getAccountsSummary();
                        if (filters.hide === false) {
                            getAccounts();
                        }
                    }} />}

                
                {loading && (filters.hide === false || showPDFs === true ) && <Loading blankTimeout='0' />}



                <Flex justifyContent='space-between' alignItems='center' w='fill-available' mb={8}>
                            <Heading size='smallertitle'>
                                {subtitle}
                            </Heading>
                            <ButtonGroup variant='outline'
                                colorScheme='blue'
                                >

                                <Button icon={<MdList />} colorScheme='blue' 
                                    variant={multipleSelect ? 'solid' : 'outline'}
                                    display='none'
                                    onClick={() => {setMultipleSelect(!multipleSelect)}}>
                                    Select Multiple
                                </Button>                            

                                
                            </ButtonGroup>
                        </Flex>

                {!loading && showPDFs === true && filters.hide === true &&

                    <Box>

                        <Header c={2}
                            buttons="hide"
                            >
                                <Cell>
                                    <Text>Filename</Text>
                                </Cell>
                                <Cell>
                                    <Text>Updated At</Text>
                                </Cell>
                        </Header>

                        {pdfs && Object.keys(pdfs).map((key) => {
                            return (
                                <Row
                                    c={2}
                                    buttons="hide"
                                    >
                                    <Cell pl='48px'>
                                        <Text>{key}</Text>
                                    </Cell>
                                    <Cell>
                                        <Text>{moment(pdfs[key].updatedAt).format('DD/MM/YYYY HH:mm')}</Text>
                                    </Cell>
                                </Row>
                            )
                        })}

                    </Box>


                }

                {!loading && filters.hide === false &&
              
                    <Box>


                        <Header c={9}
                            buttons="hide"
                            >
                            <Cell c={2} pl='48px'>
                                <Stack>
                                    <Text>Source</Text>


                                    <Wrap>
                                        <ButtonGroup isAttached size='xs' variant='ghost'>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<MdExpandMore />} variant='ghost' size='xs'>
                                                    {limit} per page
                                                </MenuButton>
                                                <MenuList>
                                                    {[10, 25, 50, 100, 500, 1000].map((varLimit) => {
                                                        <MenuItem onClick={() => {setLimit(varLimit)}}>{varLimit}</MenuItem>
                                                    })}
                                                </MenuList>
                                            </Menu>
                                            <IconButton icon={<MdArrowBackIos />} onClick={() => {setPage(page - 1)}} disabled={page === 1} />
                                            <Button>
                                                Page {page}
                                            </Button>
                                            <IconButton icon={<MdArrowForwardIos />} onClick={() => {setPage(page + 1)}} disabled={accounts && accounts.length < limit} />
                                        </ButtonGroup>
                                        <Menu display='none'>
                                            <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                                colorScheme={filters['lines'] === 'None' ? 'default' : 'blue'}
                                                variant={filters['lines'] === 'None' ? 'ghost' : 'outline'}
                                                display='none'
                                                >
                                                {filters['lines'] === 'None' ? '' : filters['lines']}
                                            </MenuButton>
                                            <MenuList>
                                                {['None', 'Complete', 'Missing'].map((status) => {
                                                    return (
                                                    <MenuItem onClick={() => {updateFilters({lines: status})}}>{status}</MenuItem>
                                                    )
                                                })}
                                            </MenuList>
                                        </Menu>
                                    </Wrap>

                                </Stack>
                            </Cell>
                            <Cell>
                                <Text>Dates</Text>
                                {filters['showFilters'] &&
                                    <>
                                        <Menu>
                                            <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                                colorScheme={filters['from'] === null ? 'default' : 'blue'}
                                                variant={filters['from'] === null ? 'ghost' : 'outline'}
                                                >
                                                    {filters['order'] === 'due' ? 'Sorting' : ''}
                                            </MenuButton>
                                            <MenuList>
                                                <MenuOptionGroup defaultValue='asc' title='Order' type='radio'>
                                                    <MenuItem onClick={() => {updateFilters({order: 'asc', orderBy: 'due'})}}>Ascending</MenuItem>
                                                    <MenuItem onClick={() => {updateFilters({order: 'desc', orderBy: 'due'})}}>Descending</MenuItem>
                                                </MenuOptionGroup>
                                                <MenuItem
                                                    onClick={() => {
                                                        setUpdateFilterDate(true);
                                                    }}
                                                    >
                                                    Filter Issue Dates
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                        <Stack spacing={0} pt={2}>
                                            <Text key={'from-date-' + filters['from']}
                                                fontSize='xs' color='subtle'
                                                >{filters['from'] === null ? '' : 'From ' + moment(filters['from']).format('DD/MM/YYYY')}</Text>
                                            <Text key={'to-date-' + filters['to']}
                                                fontSize='xs' color='subtle'
                                                >{filters['to'] === null ? '' : 'To ' + moment(filters['to']).format('DD/MM/YYYY')}</Text>
                                        </Stack>
                                    </>
                                }
                            </Cell>
                            <Cell c={2}>
                                <Stack>
                                    {!filters['showFilters'] &&

                                        <>

                                            {filters['client'] === 'Linked' ? 
                                            
                                                <Wrap>
                                                <Text>Client</Text>

                                                    <Button size='xs' 
                                                        colorScheme={filters['clients'].length === 0 ? 'default' : 'blue'}
                                                        opacity={filters['clients'].length === 0 ? 0.5 : 1}
                                                        variant={filters['clients'].length === 0 ? 'ghost' : 'outline'}
                                                        onClick={() => {setUpdateFilterClients(true)}}>
                                                        {filters['clients'].length} selected
                                                    </Button>

                                                </Wrap>
                                            :
                                                <Text>Client</Text>

                                            }
                                        
                                        
                                        
                                        </>

                                    }
                                    {filters['showFilters'] &&
                                    <Wrap>
                                        <Text>Client</Text>
                                        <Menu>
                                            <MenuButton w='fit-content'  as={Button} rightIcon={<MdFilterList />} size='xs'

                                                colorScheme={filters['client'] === 'None' ? 'default' : 'blue'}
                                                variant={filters['client'] === 'None'  ? 'ghost' : 'outline'} 
                                                >
                                                {filters['client'] === 'None' ? '' : filters['client']}
                                            </MenuButton>
                                            <MenuList>
                                                {['None', 'Linked', 'Missing'].map((status) => {
                                                    return (
                                                    <MenuItem onClick={() => {updateFilters({client: status})}}>{status}</MenuItem>
                                                    )
                                                })}
                                                <MenuItem onClick={() => {setUpdateFilterClients(true)}}>Filter by Client</MenuItem>
                                            </MenuList>
                                        </Menu>


                                        {filters['clients'] != [] && 
                                            <Button size='xs'
                                                w='auto'
                                                colorScheme={filters['clients'].length === 0 ? 'default' : 'blue'}
                                                variant={filters['clients'].length === 0 ? 'ghost' : 'outline'}
                                                color={filters['clients'].length === 0 ? 'subtle' : 'blue'}
                                                onClick={() => {setUpdateFilterClients(true)}}
                                                >
                                                {filters['clients'].length} selected
                                            </Button>
                                        }
                                    </Wrap>
                                }
                                </Stack>
                            </Cell>
                            <Cell>
                                <Stack>
                                    <Text>Invoice</Text>

                                {filters['showFilters'] &&
                                    <>
                                    <Menu>
                                        <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                            colorScheme={filters['updatePDF'] === 'None' ? 'default' : 'blue'}
                                            variant={filters['updatePDF'] === 'None' ? 'ghost' : 'outline'}
                                            >
                                            {filters['updatePDF'] === 'None' ? '' : filters['updatePDF']}
                                        </MenuButton>
                                        <MenuList>
                                            {['None', 'Linked', 'Missing'].map((status) => {
                                                return (
                                                    <MenuItem onClick={() => {updateFilters({invoicePDF: status})}}>{status}</MenuItem>
                                                )
                                            })}
                                        </MenuList>
                                    </Menu>
                                    </>
                                }
                                </Stack>
                            </Cell>
                            <Cell>
                                Amount
                            </Cell>
                            <Cell>
                                <Stack>
                                    <Text>Status</Text>

                                {filters['showFilters'] &&
                                    <>
                                    <Menu>
                                        <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                            colorScheme={filters['tradeshift'] === 'None' ? 'default' : 'blue'}
                                            variant={filters['tradeshift'] === 'None' ? 'ghost' : 'outline'}>
                                            {filters['tradeshift'] === 'None' ? '' : filters['tradeshift']}
                                        </MenuButton>
                                        <MenuList>
                                            {['Preparing', 'Incomplete', 'Attached', 'Draft', 'Sent', 'Error'].map((status) => {
                                                return (
                                                    <MenuItem onClick={() => {updateFilters({tradeshift: status})}}>{status}</MenuItem>
                                                )
                                            })}
                                        </MenuList>
                                    </Menu>
                                    </>
                                }
                                </Stack>
                            </Cell>

                            <Cell>
                                <Stack>
                                    <Text>Latest Update</Text>

                                {filters['showFilters'] &&
                                    <>
                                    <Menu>
                                        <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                            colorScheme={filters['order'] === 'updatedAt' ? 'default' : 'blue'}
                                            variant='ghost'
                                            >
                                            {filters['order'] === 'updatedAt' ? '' : filters['order']}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuOptionGroup defaultValue='asc' title='Order' type='radio'>
                                                <MenuItem onClick={() => {updateFilters({order: 'asc', orderBy: 'updatedAt'})}}>Ascending</MenuItem>
                                                <MenuItem onClick={() => {updateFilters({order: 'desc', orderBy: 'updatedAt'})}}>Descending</MenuItem>
                                            </MenuOptionGroup>
                                        </MenuList>
                                    </Menu>
                                    </>
                                }
                                </Stack>
                            </Cell>
                        </Header>

                        {loading && <LoadingCircle />}

                        {!loading && accounts && accounts.map((account) => {

                            // Create timestamp string
                            let time = new Date();
                            let timestamp = time.getTime();
                            let timestampstring = timestamp.toString(); 

                            return (
                                <Account account={account} 
                                    // Need this to re-render when we update accounts
                                    key={timestampstring + account.uuid + multipleSelect.toString() + selected.includes(account.uuid).toString()+ filters.toString()}
                                    // pass account as a state
                                    refresh={() => {getAccounts()}}
                                    upload={() => {setUpload(true)}}
                                    multipleSelect={multipleSelect}
                                    selected={selected}
                                    toggleSelected={(uuid) => {
                                        let index = selected.indexOf(uuid);
                                        let tmpSelected = [...selected];
                                        if (index === -1) {
                                            tmpSelected.push(uuid);
                                        } else {
                                            tmpSelected.splice(index, 1);
                                        }
                                        setSelected([...tmpSelected]);
                                    }}
                                 />
                            )
                            
                            })
                        }

                {!loading && accounts && accounts.length === 0 &&
                    <Box p={4}>
                        <Text>
                            No accounts found.
                        </Text>
                    </Box>
                }

                {!loading && accounts === null &&
                    <Box p={4}>
                        <Text color='red.500'>
                            Issue loading accounts
                        </Text>
                    </Box>
                }


                    </Box>
                
                }


            </GridItem>

            {multipleSelect &&

                <Box position='fixed' bottom='0' left='0' right='0' p={4} boxShadow='md' zIndex='2147483002' display='flex' justifyContent='space-between'
                    alignItems='center'  h='68px' className='gradient-background'
                    >
                    <HStack spacing={2}>
                        <ButtonGroup size='sm' variant='outline' colorScheme='white' spacing={2}>
                            <Button onClick={() => {setSelected(accounts.map((account) => account.uuid))}}>Select All</Button>
                            <Button onClick={() => {setSelected([]);}}>Clear</Button>
                        </ButtonGroup>
                        <Text color='white'>
                            {selected.length} selected
                        </Text>
                    </HStack>

                    <ButtonGroup size='sm' variant='outline' colorScheme='white'>
                        <Button onClick={() => {setMultipleSelect(false); setSelected([]);}}>Cancel</Button>
                        <Button onClick={() => {
                            setBulkActioning(true);
                            console.log(selected);
                            let url = 'organisations/accounts/bulk/draft';
                            let config = {
                                params: {
                                    action: 'draft'
                                },
                                data: {
                                    uuids: selected
                                }
                            }

                            // Send selected variable in the body of the request
                            let resp = API.postItems({timeout: 30 * selected.length, url, config})
                            //let resp = API.postItems({timeout: 30 * selected.length, url, config})
                            .then(response => {
                                console.log(response.data);
                                getAccounts();
                                toast({
                                    title: 'Success',
                                    description: response.data.message,
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                });
                                setSelected([]);
                                setMultipleSelect(false);
                                setBulkActioning(false);
                            })
                            .catch(error => {
                                console.log(error);
                                setError(error);
                                setMultipleSelect(false);
                                setBulkActioning(false);
                            });
                            setMultipleSelect(false);}}>Draft</Button>

                        <Button variant='solid' onClick={() => {
                            if (window.confirm('Are you sure you want to send the invoice?', 'This will send the invoice to the client via Tradeshift')) {

                                let url = 'organisations/accounts/bulk/send';
                                let config = {
                                    params: {
                                        action: 'send'
                                    },
                                    data: {
                                        uuids: selected
                                    }
                                }
                                let resp = API.postItems({timeout: 30 * selected.length, url, config})
                                .then(response => {
                                    console.log(response.data);
                                    getAccounts();
                                    toast({
                                        title: 'Success',
                                        description: response.data.message,
                                        status: 'success',
                                        duration: 5000,
                                        isClosable: true,
                                    });
                                    setSelected([]);
                                    setMultipleSelect(false);
                                    setBulkActioning(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                    setError(error);
                                    setMultipleSelect(false);
                                    setBulkActioning(false);
                                });
                                setMultipleSelect(false);
                            }
                            }}>Send</Button>


                    </ButtonGroup>
                </Box>

            }
</>
        </Layout>

    )

}