import React from 'react';
import {useState} from 'react';
import { useForm } from "react-hook-form";

import { Text, Wrap, Flex, Textarea, Stack, Input ,InputRightElement, InputGroup, Button, FormControl, FormLabel, FormHelperText, Checkbox, Radio, RadioGroup, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper} from '@chakra-ui/react';

import Association from '../../core/components/form_elements/Association';
import BasicBanner from '../../core/components/banners/BasicBanner';
import Select from 'react-select';
import API from '../../core/utils/API';

function capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

function Password(props) {

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
  
    return (
      <InputGroup size='md'>
        <Input
          pr='4.5rem'
          type={show ? 'text' : 'password'}
          {...props.register}
        />
        <InputRightElement width='4.5rem'>
          <Button h='1.75rem' size='sm' onClick={handleClick}>
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
    )
  
}


function RadioCreator(props) {
    const [value, setValue] = React.useState(props.value)
    return (
      <RadioGroup onChange={setValue} defaultValue={value} spacing={2}>
          <Wrap spacing={4} direction='row'>

            {props.options.map((option, index) => {
                return (
                   <Radio value={option.value}>{option.label}</Radio>
                   
                )
            })}
            </Wrap>
      </RadioGroup>
    )
  }


  <RadioGroup>
  
</RadioGroup>


export default function AutoForm(props) {
    
    const [data, setData] = useState("");
    const [formMessage, setFormMessage] = useState("");
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const onError = (errors, e) => console.log(errors, e);
    const [loading, setLoading] = useState(false);

    const onSubmit = (data, e) => {
        setLoading(true);
        console.log(data);

        if (props.item != undefined) {

            let response = API.patchItems({url: props.url, data})
            .then(function (response) {
                console.log(response);
                setFormMessage("Record updated successfully");
                // Reset inputs
                reset(); // This will reset the form
                setLoading(false);
                props.refresh();
            })
            .catch(function (error) {
                console.log(error);
                setFormMessage("Error updating record");
                setLoading(false);
            });
        } else {
            let response = API.postItems({url: props.url, data})
            .then(function (response) {
                console.log(response);
            // window.location.reload(false);
                setFormMessage("Record created successfully");
                // Reset inputs
                reset(); // This will reset the form
                setLoading(false);
                props.refresh();

                //window.location.reload(false);

            })
            .catch(function (error) {
                console.log(error);
                //window.location.reload(false);
                setFormMessage("Error creating record");
                setLoading(false);
                props.refresh();
                //props.refresh();
                //window.location.reload(false);

            });
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            
            <Stack spacing="10">

            {formMessage !== "" &&
                <Text>{formMessage}</Text>
            }
            
            {props.inputs.map((item, index) => {

                console.log(item);
                if (item.value === undefined) {
                    item.value = '';
                    if (props.item != undefined) {
                        if (props.item[item.name] != undefined) {
                            item.value = props.item[item.name]
                        }
                    } 
                }

                return (

                    <FormControl className={item.type}>


                        {(item.type === null || item.type === undefined || item.type === 'disabled' || item.type === 'hidden' || item.type === 'text') && 
                            <Input id={item.name} name={item.name}
                                {...register(item.name, {required: item.required })}
                                variant='flushed'
                                placeholder=' '
                                defaultValue={item.value}
                                data-peer
                                disabled={item.type === 'disabled' ? true : false}
                                />
                        }

                        {(item.type === 'number') &&
                            <NumberInput id={item.name} name={item.name}
                                    {...register(item.name, {required: item.required })}
                                    variant='flushed'
                                    placeholder=' '
                                    defaultValue={item.value}
                                    min={item.min ? item.min : 0}
                                    max={item.max ? item.max : null}
                                    data-peer
                                    >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                            </NumberInput>
                        }

                        {(item.type === 'password') && 
                            <Password 
                                register={register(item.name, {required: item.required })}
                                variant='flushed'
                                />
                        }

                        {(item.type === 'checkbox') && 
                            <>
                            { (item.value === true) ?
                                <Checkbox 
                                    name={item.name} 
                                    {...register(item.name, {required: item.required })}
                                    type={item.type} 
                                    defaultChecked
                                    />
                                :
                                <Checkbox 
                                    name={item.name} 
                                    {...register(item.name, {required: item.required })}
                                    type={item.type} 
                                    />
                            }
                            </>
                        }
                        {(item.type === 'radio') && 
                            <RadioCreator options={item.options} value={item.value} />
                        }

                        {(item.type === 'association') &&
                            <Association
                                url = {item.assocation.url}
                                delim = {item.assocation.delim}
                                isMulti= {item.assocation.isMulti}
                                model= {item.assocation.model}
                                label = {item.assocation.label}
                                />
                        }

                        {(item.type === 'select') &&
                            
                            <Select 
                                name={item.name} 
                                {...register(item.name, {required: item.required })}
                                options={item.options}
                                defaultValue={item.value}
                            />
                        }

                        {(item.type === 'textarea') &&
                            <Textarea
                                name={item.name}
                                {...register(item.name, {required: item.required })}
                                variant='flushed'
                                placeholder=' '
                                defaultValue={item.value}
                                data-peer
                                />
                        }


                        <FormLabel variant='floating' htmlFor={item.name}>
                            {(item.label === null || item.label === undefined) ?
                                capitalizeFirstLetter(item.name)
                                :
                                item.label
                            }
                        </FormLabel>
                        
                        {item.helperText !== null && item.type !== undefined &&
                            <FormHelperText>{item.helperText}</FormHelperText>
                        }


                    </FormControl>


                );
            })}

            </Stack>

            <FormControl mt={4} display={props.hideSubmit ? 'none' : 'inherit'}>
                <Button type="submit" colorScheme='blue'>Save</Button>
            </FormControl>

        </form>

    )
}
