import React from 'react';
import { Button, ButtonGroup, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { MdExpandMore } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';



export default function Navigation(props) {

    const navigate = useNavigate();
    // check if orgtest is in the URL
    // if so, we are in a test environment and we should not show the return button
    let domain = 'organisation';
    let url = window.location.href;
    if (url.includes('orgtest')) {
        domain = 'orgtest';
    }


    return (
        <ButtonGroup variant='outline'>

            <Button colorScheme='gray' variant='outline'
                onClick={() => {navigate('/' + domain + '/jobs/')}}
                >Return</Button>

            {props.job.title &&
                <Menu>
                    <MenuButton as={Button} rightIcon={<MdExpandMore />} colorScheme='blue' variant='outline'>
                        Actions
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => {navigate('/' + domain + '/jobs/job', { state: { job: props.job } })}}>Overview</MenuItem>
                        <MenuItem onClick={() => {navigate('/' + domain + '/jobs/apply', { state: { job: props.job } })}}>Apply</MenuItem>
                        <MenuItem onClick={() => {navigate('/' + domain + '/jobs/review', { state: { job: props.job } })}}>Review</MenuItem>
                        <MenuItem onClick={() => {navigate('/' + domain + '/jobs/interview', { state: { job: props.job } })}}>Interview</MenuItem>
                        <MenuItem onClick={() => {navigate('/' + domain + '/jobs/applicant', { state: { job: props.job } })}}>Applicant</MenuItem>
                    </MenuList>
                </Menu>
            }

        </ButtonGroup>
    )
}