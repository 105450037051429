import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Controls,
  MiniMap,
  Background,
  MarkerType
} from '@xyflow/react';


const initialNodes = [
{
  id: 'entry',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'entry',
  position: { x: -600, y: 100 },
  data: { 
    label: 'Children\'s ADHD Assessment Tool',
    status: 'notstarted',
    totalSteps: 6, 
    completedSteps: 0,
    value: 'Initiation of the procurement process for the digital ADHD assessment tool. This node represents the entry point where the process begins.'
  },
},
{
  id: 'conflictsOfInterest',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: -300, y: 100 },
  data: { 
    label: 'Conflicts of Interest',
    status: 'notstarted',
    totalSteps: 4, 
    completedSteps: 0,
    value: 'Identification and management of any conflicts of interest within the procurement team to ensure impartiality in the process.'
  },
},
{
  id: 'settingScope',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: 0, y: 100 },
  data: { 
    label: 'Setting Scope',
    status: 'notstarted',
    totalSteps: 9, 
    completedSteps: 0,
    value: 'Defining the scope and requirements of the ADHD assessment tool, including features, user needs, and integration with existing NHS systems.'
  },
},
{
  id: 'clinical-group',
  data: { 
    label: 'Clinical Specification', 
    value: 'Clinical evaluation and input into the requirements for the ADHD assessment tool, ensuring it meets clinical standards and patient needs.'
  },
  position: { x: 350, y: -350 },
  className: 'light',
  style: { backgroundColor: 'rgba(0, 0, 0, 0.06)', width: 260, height: 390, border: 'none' },
  type: 'group',
},
{
  id: 'psychology',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'internal',
  position: { x: 130, y: 20 },
  data: { 
    label: 'Psychology',
    status: 'notstarted',
    totalSteps: 7, 
    completedSteps: 0,
    description: 'Assessment of the tool’s psychological validity and its effectiveness in assessing ADHD symptoms in children.',
    steps: [
      {
          "label": "Symptom Assessment - Behavioral Context",
          "value": "Assess the child's behavior across multiple settings to ensure context-sensitive evaluation.",
          "status": "notstarted",
          "assigned": "Ali Jawad",
          "completed": "2024-09-21",
          "outcome": "text",
          "sources": ["NICE Guidelines", "Behavioral Psychology Texts", "DSM-5"],
          "description": "Review and analyze the child's behavioral patterns in different environments to understand their ADHD symptoms within various contexts."
      },
      {
          "label": "Multi-source Input - Teacher/School Input",
          "value": "Include teacher rating scales and questionnaires to assess the child's behavior in a school setting.",
          "status": "notstarted",
          "assigned": "Jane Smith",
          "completed": "2024-09-21",
          "outcome": "text",
          "sources": ["Teacher Rating Scales", "School Behavioral Assessments", "Educational Psychology Resources"],
          "description": "Gather feedback from teachers regarding the child's behavior in the school environment, providing a broader perspective on ADHD symptoms."
      },
      {
          "label": "Human-in-the-Loop Decision Support - Clinical Review",
          "value": "Allow clinicians to review and adjust the diagnosis based on professional judgment.",
          "status": "notstarted",
          "assigned": "Sarah Jane",
          "completed": "2024-09-21",
          "outcome": "text",
          "sources": ["NICE Guidelines", "Clinical Psychology Manuals", "Diagnostic Review Processes"],
          "description": "Incorporate clinical expertise into the decision-making process, enabling adjustments to the diagnosis based on clinician insights."
      },
      {
          "label": "Parental/Guardian Support - Guided Intervention Plans",
          "value": "Offer personalized intervention plans for parents to implement at home.",
          "status": "notstarted",
          "assigned": "Oliver Trampleasure",
          "completed": "2024-09-21",
          "outcome": "text",
          "sources": ["NICE Guidelines", "Parental Intervention Guides", "ADHD Behavioral Interventions"],
          "description": "Provide customized strategies and support for parents to help manage their child's ADHD symptoms at home."
      },
      {
          "label": "Clinical Analytics and Evaluation - Outcome Tracking",
          "value": "Track clinical outcomes over time to evaluate the effectiveness of interventions and treatment plans.",
          "status": "notstarted",
          "assigned": "Ali Jawad",
          "completed": "2024-09-21",
          "outcome": "text",
          "sources": ["NICE Guidelines", "Clinical Psychology Tools", "Outcome Tracking Systems"],
          "description": "Monitor the progress and effectiveness of ADHD interventions by tracking clinical outcomes and patient improvements."
      },
      {
          "label": "Clinical Decision Support - Case Review and Collaboration",
          "value": "Facilitate collaborative review among the clinical team, allowing multidisciplinary input in complex cases.",
          "status": "notstarted",
          "assigned": "Sarah Jane",
          "completed": "2024-09-21",
          "outcome": "boolean",
          "sources": ["NICE Guidelines", "Clinical Collaboration Tools", "Multidisciplinary Review Protocols"],
          "description": "Enable teamwork and cross-disciplinary consultations to refine diagnoses and treatment plans in challenging cases."
      },
      {
          "label": "Data Protection and GDPR Compliance - Secure Data Handling",
          "value": "Ensure secure data handling, with encryption and access controls per NHS Digital and GDPR guidelines.",
          "status": "notstarted",
          "assigned": "Jane Smith",
          "completed": "2024-09-21",
          "outcome": "boolean",
          "sources": ["NHS Digital Guidelines", "GDPR Compliance Resources", "Data Security Protocols"],
          "description": "Protect sensitive data by adhering to data security protocols, ensuring compliance with GDPR and NHS guidelines."
      }
  ]
  
  },
  parentId: 'clinical-group',
  extent: 'parent',
},
{
  id: 'paediatric',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'internal',
  position: { x: 130, y: 110 },
  data: {
      label: 'Paediatric',
      status: 'notstarted',
      totalSteps: 5, 
      completedSteps: 0,
      description: 'Review of the tool from a paediatric perspective, ensuring it is suitable for use with children and adolescents.',
      steps: [
              {
                  "label": "Symptom Assessment - Alignment with NICE Guidelines (NG87)",
                  "value": "Ensure the tool assesses inattention, hyperactivity, and impulsivity symptoms per DSM-5, ICD-10, and NICE guidelines.",
                  "status": "notstarted",
                  "assigned": "Jane Smith",
                  "completed": "2024-09-21",
                  "outcome": "text",
                  "sources": ["NICE Guidelines", "DSM-5", "ICD-10", "Pediatric Psychiatry Textbooks"],
                  "description": "Validate the tool's criteria against established clinical guidelines to ensure comprehensive assessment of ADHD symptoms in children."
              },
              {
                  "label": "Multi-source Input - Parental/Guardian Input",
                  "value": "Implement structured questionnaires for parents/guardians to report the child's behavior.",
                  "status": "notstarted",
                  "assigned": "Oliver Trampleasure",
                  "completed": "2024-09-21",
                  "outcome": "text",
                  "sources": ["Conners' Parent Rating Scale", "Strengths and Difficulties Questionnaire", "Pediatric Assessment Guidelines"],
                  "description": "Collect input from parents or guardians to capture their observations and concerns about their child's behavior."
              },
              {
                  "label": "Parental/Guardian Support - Educational Resources",
                  "value": "Provide parents with resources about ADHD, management strategies, and tailored content based on the child's symptoms.",
                  "status": "notstarted",
                  "assigned": "Sarah Jane",
                  "completed": "2024-09-21",
                  "outcome": "text",
                  "sources": ["NICE Guidelines", "Parental Support Literature", "ADHD Management Resources"],
                  "description": "Equip parents with information and strategies to support their child's ADHD management."
              },
              {
                  "label": "Population Health Management - Risk Stratification",
                  "value": "Implement risk stratification features to identify children at high risk for severe ADHD or related complications.",
                  "status": "notstarted",
                  "assigned": "Ali Jawad",
                  "completed": "2024-09-21",
                  "outcome": "text",
                  "sources": ["NICE Guidelines", "Risk Stratification Models", "Pediatric ADHD Management"],
                  "description": "Use data to categorize children based on risk, guiding targeted interventions for those with severe symptoms or comorbidities."
              },
              {
                  "label": "Clinical Decision Support - Integrated Decision Aids",
                  "value": "Provide evidence-based decision support tools that align with NICE guidelines for ADHD assessment and treatment.",
                  "status": "notstarted",
                  "assigned": "Jane Smith",
                  "completed": "2024-09-21",
                  "outcome": "boolean",
                  "sources": ["NICE Guidelines", "Clinical Decision Support Systems", "Pediatric ADHD Guidelines"],
                  "description": "Incorporate decision support tools to assist clinicians in making informed, guideline-compliant decisions during assessment and treatment."
              }
          ]            
  },
  parentId: 'clinical-group',
  extent: 'parent',
},
{
  id: 'psychiatry',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'internal',
  position: { x: 130, y: 200 },
  data: {
      label: 'Psychiatry',
      status: 'notstarted',
      totalSteps: 8, 
      completedSteps: 0,
      description: 'Evaluation of the tool’s capability to accurately assess and diagnose ADHD from a psychiatric standpoint.',
      steps: [
          {
              "label": "Symptom Assessment - Symptom Frequency and Severity",
              "value": "Capture symptom frequency, severity, and duration, providing a granular view of the child's behavioral patterns.",
              "status": "notstarted",
              "assigned": "Ali Jawad",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["DSM-5", "NICE Guidelines", "Psychiatric Assessment Texts"],
              "description": "Assess the frequency, intensity, and duration of ADHD symptoms to provide a detailed understanding of the child's condition."
          },
          {
              "label": "Comorbidity Screening - Common Comorbidities",
              "value": "Screen for associated conditions like anxiety, depression, and conduct disorder, ensuring a holistic assessment.",
              "status": "notstarted",
              "assigned": "Sarah Jane",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["DSM-5", "NICE Guidelines", "Psychiatric Comorbidity Studies"],
              "description": "Evaluate for additional psychiatric conditions that often co-occur with ADHD to ensure a comprehensive diagnosis."
          },
          {
              "label": "Multi-source Input - Clinical Observations",
              "value": "Use clinician-administered observational tools to assess the child's behavior during clinical visits.",
              "status": "notstarted",
              "assigned": "Oliver Trampleasure",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["NICE Guidelines", "DSM-5", "Clinical Observation Tools"],
              "description": "Gather clinical observations to complement other sources of data, offering a direct assessment of the child's behavior in a clinical setting."
          },
          {
              "label": "Human-in-the-Loop Decision Support - Initial Diagnostic Output",
              "value": "Generate an initial diagnostic report based on collected data, indicating the likelihood of ADHD.",
              "status": "notstarted",
              "assigned": "Jane Smith",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["NICE Guidelines", "Diagnostic Tools", "DSM-5"],
              "description": "Produce a preliminary diagnosis based on symptom data, providing an early indication of ADHD likelihood."
          },
          {
              "label": "Population Health Management - Population Analytics",
              "value": "Enable clinicians to view aggregated data on symptoms, comorbidities, and intervention outcomes across the population.",
              "status": "notstarted",
              "assigned": "Ali Jawad",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["NICE Guidelines", "Population Health Data", "Clinical Analytics Systems"],
              "description": "Use population-level data to identify trends and outcomes, supporting public health decisions and resource allocation."
          },
          {
              "label": "Clinical Analytics and Evaluation - Quality Improvement",
              "value": "Enable ongoing evaluation and quality improvement based on data insights, supporting service evaluation and audit.",
              "status": "notstarted",
              "assigned": "Oliver Trampleasure",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["NICE Guidelines", "Quality Improvement Frameworks", "Clinical Evaluation Tools"],
              "description": "Utilize data to continuously improve clinical practices, ensuring the tool evolves with new insights and evidence."
          },
          {
              "label": "Clinical Psychology and Psychiatry Integration - Referral Pathways",
              "value": "Facilitate referrals to psychology or psychiatry services for children requiring more intensive assessment or treatment.",
              "status": "notstarted",
              "assigned": "Jane Smith",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["NICE Guidelines", "Referral Systems", "Clinical Pathways"],
              "description": "Streamline the referral process to ensure children with complex needs receive appropriate psychological or psychiatric care."
          },
          {
              "label": "Clinical Psychology and Psychiatry Integration - Psychiatric Assessment Modules",
              "value": "Include specialized modules for psychiatrists to explore complex cases where ADHD symptoms overlap with other psychiatric conditions.",
              "status": "notstarted",
              "assigned": "Sarah Jane",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["DSM-5", "NICE Guidelines", "Psychiatric Assessment Tools"],
              "description": "Provide tools for psychiatrists to delve deeper into cases where ADHD may intersect with other psychiatric diagnoses."
          }
      ]        
  },
  parentId: 'clinical-group',
  extent: 'parent',
},
{
  id: 'safeguarding',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'internal',
  position: { x: 130, y: 290 },
  data: {
      label: 'Safeguarding',
      status: 'notstarted',
      totalSteps: 2,
      completedSteps: 0,
      description: 'Ensuring that the tool adheres to safeguarding standards to protect children’s welfare during the assessment process.',
      steps: [
          {
              "label": "Safeguarding - Risk Alerts",
              "value": "Include mechanisms for flagging safeguarding concerns during the assessment process and triggering alerts to safeguarding teams.",
              "status": "notstarted",
              "assigned": "Oliver Trampleasure",
              "completed": "2024-09-21",
              "outcome": "boolean",
              "sources": ["NHS Safeguarding Policies", "Safeguarding Tools", "Risk Alert Protocols"],
              "description": "Integrate features that identify and raise alarms for any safeguarding issues, ensuring the child's safety is prioritized."
          },
          {
              "label": "Safeguarding - Child Protection Protocols",
              "value": "Embed child protection protocols to follow NHS and local authority procedures if safeguarding issues are identified.",
              "status": "notstarted",
              "assigned": "Jane Smith",
              "completed": "2024-09-21",
              "outcome": "text",
              "sources": ["NHS Safeguarding Policies", "Local Authority Guidelines", "Child Protection Resources"],
              "description": "Ensure the tool is equipped to comply with legal and procedural requirements when safeguarding concerns arise."
          }
      ]
      
  },
  parentId: 'clinical-group',
  extent: 'parent',
},
{
  id: 'it',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: 350, y: 100 },
  data: { 
    label: 'IT',
    status: 'notstarted',
    totalSteps: 8, 
    completedSteps: 0,
    value: 'Technical evaluation of the tool’s integration capabilities with NHS IT systems, data handling, and security measures.'
  },
},
{
  id: 'finance',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: 350, y: 220 },
  data: { 
    label: 'Finance',
    status: 'notstarted',
    totalSteps: 3, 
    completedSteps: 0,
    value: 'Financial analysis and budgeting for the procurement and implementation of the ADHD assessment tool.'
  },
},
{
  id: 'dataSecurity',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: 850, y: -190 },
  data: { 
    label: 'Data Security',
    status: 'notstarted',
    totalSteps: 7, 
    completedSteps: 0,
    value: 'Evaluation of the tool’s compliance with NHS data security policies, focusing on patient data protection and GDPR adherence.'
  },
},
{
  id: 'informationGovernance',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: 1200, y: -190 },
  data: { 
    label: 'Information Governance',
    status: 'notstarted',
    totalSteps: 16, 
    completedSteps: 0,
    start: '2024-02-01',
    due: '2024-09-01',
    owner: 'Oliver Trampleasure',
    procurementDescription: 'This is the procurement of an ADHD digital tool for use in the paediatric department.',
    description: 'Ensuring that the tool complies with NHS information governance policies, including data sharing agreements and audit trails.',
    steps: [
      {
          "label": "GDPR Compliance - Data Subject Rights",
          "value": "Ensure the tool complies with GDPR, allowing data subjects to exercise their rights (access, rectification, erasure, and data portability). Implement informed consent mechanisms.",
          "status": "notstarted",
          "assigned": "Ali Jawad",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Legal Team", "Compliance Department"],
          "description": "Ensure the tool supports the rights of individuals under GDPR, providing options for data management and informed consent."
      },
      {
          "label": "GDPR Compliance - Data Minimization",
          "value": "Ensure data collection is limited to what is necessary for ADHD management and review regularly to purge unnecessary data.",
          "status": "notstarted",
          "assigned": "Jane Smith",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Legal Team", "Compliance Department"],
          "description": "Limit data collection to essential information, with regular reviews to remove unnecessary data, ensuring compliance with GDPR."
      },
      {
          "label": "NHS DSPT Compliance - Toolkit Compliance",
          "value": "Ensure the tool meets NHS DSPT standards and is regularly updated to reflect changes in data handling or security.",
          "status": "notstarted",
          "assigned": "Sarah Jane",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["NHS Compliance Team", "IT Security Team"],
          "description": "Adhere to NHS Data Security and Protection Toolkit (DSPT) requirements, ensuring ongoing compliance through regular updates."
      },
      {
          "label": "NHS DSPT Compliance - Data Sharing Agreements",
          "value": "Ensure DSAs are in place with third-party processors handling NHS data in compliance with DSPT guidelines.",
          "status": "notstarted",
          "assigned": "Oliver Trampleasure",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Legal Team", "NHS Compliance Team"],
          "description": "Establish and maintain Data Sharing Agreements (DSAs) with external partners, ensuring compliance with NHS data protection standards."
      },
      {
          "label": "Data Security - Encryption Standards",
          "value": "Implement AES-256 encryption for data at rest and in transit, with secure key management and TLS 1.2+ for communication.",
          "status": "notstarted",
          "assigned": "Jane Smith",
          "completed": "2024-10-01",
          "outcome": "boolean",
          "sources": ["IT Security Team", "NHS Digital"],
          "description": "Use strong encryption methods to protect sensitive data, both at rest and during transmission, ensuring secure communications."
      },
      {
          "label": "Data Security - Access Control",
          "value": "Implement RBAC and MFA, with audit logging to track data access and modifications securely.",
          "status": "notstarted",
          "assigned": "Sarah Jane",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["IT Security Team", "NHS Digital"],
          "description": "Control access to data using Role-Based Access Control (RBAC) and Multi-Factor Authentication (MFA), with logging for accountability."
      },
      {
          "label": "Interoperability - FHIR Compliance",
          "value": "Ensure the tool complies with FHIR standards for seamless integration with NHS EHR systems.",
          "status": "notstarted",
          "assigned": "Oliver Trampleasure",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Technical Team", "NHS Digital"],
          "description": "Ensure the tool meets Fast Healthcare Interoperability Resources (FHIR) standards for compatibility with existing NHS electronic health record systems."
      },
      {
          "label": "Interoperability - Data Portability",
          "value": "Implement secure export/import capabilities in standardized formats (e.g., HL7, FHIR) in compliance with GDPR.",
          "status": "notstarted",
          "assigned": "Ali Jawad",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Technical Team", "Compliance Department"],
          "description": "Support data portability by allowing secure data transfers in standard formats, ensuring compliance with GDPR."
      },
      {
          "label": "Clinical Safety - Clinical Risk Management",
          "value": "Provide a Clinical Safety Case Report in compliance with DCB0129 and ensure ongoing risk management.",
          "status": "notstarted",
          "assigned": "Jane Smith",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Clinical Team", "Risk Management Department"],
          "description": "Manage clinical risks by preparing a safety case report, adhering to standards, and ensuring continuous risk monitoring."
      },
      {
          "label": "Clinical Safety - Medical Device Regulations",
          "value": "Ensure the tool is CE/UKCA marked if applicable and complies with MDR for clinical evaluation and post-market surveillance.",
          "status": "notstarted",
          "assigned": "Sarah Jane",
          "completed": "2024-10-01",
          "outcome": "boolean",
          "sources": ["Clinical Team", "Regulatory Affairs"],
          "description": "Ensure compliance with medical device regulations, including appropriate markings and adherence to ongoing safety monitoring requirements."
      },
      {
          "label": "Safeguarding - Child Protection Protocols",
          "value": "Ensure the tool complies with NHS safeguarding policies, with automated alerts for safeguarding issues.",
          "status": "notstarted",
          "assigned": "Oliver Trampleasure",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Clinical Team", "Safeguarding Leads"],
          "description": "Incorporate child protection protocols to comply with NHS policies, ensuring prompt responses to safeguarding concerns."
      },
      {
          "label": "Safeguarding - Age-Appropriate Consent",
          "value": "Implement consent protocols that comply with UK legal requirements for minors, including parental/guardian consent and Gillick competency assessments.",
          "status": "notstarted",
          "assigned": "Ali Jawad",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Legal Team", "Clinical Leads"],
          "description": "Ensure that consent processes are suitable for minors, incorporating legal requirements for both parental consent and assessments of Gillick competence."
      },
      {
          "label": "Governance - Incident Reporting",
          "value": "Establish procedures for reporting data breaches or compliance issues, with detailed incident reports that meet NHS standards.",
          "status": "notstarted",
          "assigned": "Jane Smith",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Compliance Team", "NHS Digital"],
          "description": "Set up protocols for the timely reporting of data breaches or compliance failures, ensuring transparency and adherence to NHS standards."
      },
      {
          "label": "Governance - Transparency and Accountability",
          "value": "Provide comprehensive documentation on the tool’s data handling processes and ensure third-party processors adhere to the same standards.",
          "status": "notstarted",
          "assigned": "Sarah Jane",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Legal Team", "Compliance Department"],
          "description": "Maintain detailed records of data handling procedures, ensuring all parties involved adhere to NHS governance standards."
      },
      {
          "label": "Clinical Analytics - Population Health Analytics",
          "value": "Ensure the tool supports data aggregation and anonymization for population health analytics and clinical outcomes reporting.",
          "status": "notstarted",
          "assigned": "Oliver Trampleasure",
          "completed": "2024-10-01",
          "outcome": "boolean",
          "sources": ["Clinical Leads", "Technical Team"],
          "description": "Support large-scale data analysis by enabling the aggregation and anonymization of data for population health studies."
      },
      {
          "label": "Clinical Analytics - Research and Evaluation",
          "value": "Implement secure mechanisms for sharing de-identified data with NHS-approved research teams, supporting continuous evaluation.",
          "status": "notstarted",
          "assigned": "Ali Jawad",
          "completed": "2024-10-01",
          "outcome": "text",
          "sources": ["Clinical Team", "Research Department"],
          "description": "Facilitate research by securely sharing anonymized data with authorized research teams, promoting ongoing evaluation of clinical practices."
      }
  ]    
  },
},
{
  id: 'legal',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: 1700, y: 100 },
  data: { 
    label: 'Legal',
    status: 'notstarted',
    totalSteps: 4, 
    completedSteps: 0,
    value: 'Legal review of the procurement contract and ensuring compliance with NHS procurement regulations and standards.'
  },
},
{
  id: 'weighting',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'node',
  position: { x: 2100, y: 100 },
  data: { 
    label: 'Weighting',
    status: 'notstarted',
    totalSteps: 6, 
    completedSteps: 0,
    value: 'Final assessment and weighting of the tool against the defined criteria to determine its suitability for procurement.'
  },
},



{ 
  id: 'signoff', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'notification', 
  position: { x: 2500, y: 100 }, 
  data: { 
    label: 'Sign off', 
    status: 'notstarted',
    totalSteps: 1,  // Randomized total steps
    completedSteps: 0,
    value: 'Final sign off before procurement initiation.',
    type: 'decision'
  } 
},
{ 
  id: 'procurement', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 2900, y: 100 }, 
  data: { 
    label: 'Procurement Initiation', 
    status: 'notstarted',
    totalSteps: 14,  // Randomized total steps
    completedSteps: 0,
    value: 'Initiate the procurement process.' 
  } 
},
{ 
  id: 'bids', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 3300, y: 0 }, 
  data: { 
    label: 'Bids', 
    status: 'notstarted',
    totalSteps: 8,  // Randomized total steps
    completedSteps: 0,
    value: 'Collection of bids from potential suppliers.' 
  } 
},
{ 
  id: 'bidderComms', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 3300, y: 200 }, 
  data: { 
    label: 'Bidder Communication', 
    status: 'notstarted',
    totalSteps: 12,  // Randomized total steps
    completedSteps: 0,
    value: 'Communication with bidders.' 
  } 
},
{ 
  id: 'eval2', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 3700, y: 100 }, 
  data: { 
    label: 'Evaluation', 
    status: 'notstarted',
    totalSteps: 16,  // Randomized total steps
    completedSteps: 0,
    value: 'Evaluation of submitted bids.' 
  } 
},
{ 
  id: 'award', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'notification', 
  position: { x: 4050, y: 100 }, 
  data: { 
    label: 'Award', 
    status: 'notstarted',
    totalSteps: 11,  // Randomized total steps
    completedSteps: 0,
    value: 'Award the contract.',
    type: 'decision'
  } 
},
{ 
  id: 'notice', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 4450, y: 100 }, 
  data: { 
    label: 'Notice', 
    status: 'notstarted',
    totalSteps: 9,  // Randomized total steps
    completedSteps: 0,
    value: 'Notice of award.' 
  } 
},
{ 
  id: 'feedback', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 4450, y: 300 }, 
  data: { 
    label: 'Feedback review', 
    status: 'notstarted',
    totalSteps: 13,  // Randomized total steps
    completedSteps: 0,
    value: 'Review feedback if not awarded.' 
  } 
},
{ 
  id: 'standstill', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'notification', 
  position: { x: 4850, y: 100 }, 
  data: { 
    label: 'Standstill Period', 
    status: 'notstarted',
    totalSteps: 1,  // Randomized total steps
    completedSteps: 0,
    value: 'Prior to final contract.',
    type: 'wait'
  } 
},
{ 
  id: 'psr', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 5200, y: 100 }, 
  data: { 
    label: 'PSR Panel Review', 
    status: 'notstarted',
    totalSteps: 15,  // Randomized total steps
    completedSteps: 0,
    value: 'Panel review process.' 
  } 
},
{ 
  id: 'contract', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 5500, y: 100 }, 
  data: { 
    label: 'Award Contract', 
    status: 'notstarted',
    totalSteps: 18,  // Randomized total steps
    completedSteps: 0,
    value: 'Award the contract to the selected vendor.' 
  } 
},
{ 
  id: 'awardNotice', 
  sourcePosition: 'right', 
  targetPosition: 'left', 
  type: 'node', 
  position: { x: 5800, y: 100 }, 
  data: { 
    label: 'Award Notice', 
    status: 'notstarted',
    totalSteps: 14,  // Randomized total steps
    completedSteps: 0,
    value: 'Final award notice issued.' 
  } 
},
{
  id: 'exit',
  sourcePosition: 'right',
  targetPosition: 'left',
  type: 'exit',
  position: { x: 6150, y: 100 },
  data: { 
    label: 'Exit', 
    status: 'notstarted',
    totalSteps: 1, 
    completedSteps: 0,
    value: 'End of the procurement process.'
  },
}
];

const initialEdges = [
{
  id: 'e1-2',
  source: 'entry',
  target: 'conflictsOfInterest',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e2-3',
  source: 'conflictsOfInterest',
  target: 'settingScope',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e3-4',
  source: 'settingScope',
  target: 'clinical-group',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e9-12',
  source: 'legal',
  target: 'weighting',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e-clinical-dataSecurity',
  source: 'clinical-group',
  target: 'dataSecurity',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e-dataSecurity-infoGovernance',
  source: 'dataSecurity',
  target: 'informationGovernance',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e-scope-IT',
  source: 'settingScope',
  target: 'it',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e-scope-finance',
  source: 'settingScope',
  target: 'finance',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e-IT-dataSecurity',
  source: 'it',
  target: 'dataSecurity',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e-finance-legal',
  source: 'finance',
  target: 'legal',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
{
  id: 'e-ig-legal',
  source: 'informationGovernance',
  target: 'legal',
  
  animated: true,
  markerEnd: {
    type: MarkerType.Arrow,
  },
},
 // Image 1 edges
 { id: 'e-award-notice', source: 'award', target: 'notice', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-award-feedback', source: 'award', target: 'feedback', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-notice-standstill', source: 'notice', target: 'standstill', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-standstill-psr', source: 'standstill', target: 'psr', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-psr-contract', source: 'psr', target: 'contract', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-contract-awardNotice', source: 'contract', target: 'awardNotice', animated: true, markerEnd: { type: MarkerType.Arrow } },

 // Image 2 edges
 { id: 'e-evaluation-paediatrics', source: 'evaluation', target: 'paediatrics', animated: true, markerEnd: { type: MarkerType.Arrow } },
 // Add other related edges as shown in the second image.

 // Image 3 edges
 { id: 'e-signoff-procurement', source: 'signoff', target: 'procurement', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-procurement-bids', source: 'procurement', target: 'bids', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-procurement-bidderComms', source: 'procurement', target: 'bidderComms', animated: true, markerEnd: { type: MarkerType.Arrow } },
 { id: 'e-bids-eval2', source: 'bids', target: 'eval2', animated: true, markerEnd: { type: MarkerType.Arrow } },

 { id: 'e-bidderComms-eval2', source: 'bidderComms', target: 'eval2', animated: true, markerEnd: { type: MarkerType.Arrow } },

 { id: 'e-weighting-signoff', source: 'weighting', target: 'signoff', animated: true, markerEnd: { type: MarkerType.Arrow } },

 { id: 'e-eval2-award', source: 'eval2', target: 'award', animated: true, markerEnd: { type: MarkerType.Arrow } },

 { id: 'e-notice-exit', source: 'notice', target: 'exit', animated: true, markerEnd: { type: MarkerType.Arrow } },

];

export { initialNodes as adhdInitialNodes, initialEdges as adhdInitialEdges };
