
import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ReachLink } from "react-router-dom";
import { Box, Flex, Spacer, HStack, Collapse, useDisclosure, Text, Button, IconButton, Stack, Heading, Tag, TagLabel, Link, Icon } from '@chakra-ui/react';
import Inbox from './Inbox';
import Conversation from './Conversation';


export default function MessagingWindow(props) {

    const [show, setShow] = React.useState(true);
    const [conversation, setConversation] = React.useState(null);

    return (

        <Box m='auto' maxW='400px' minW='400px'>

            {conversation === null && <Inbox conversation={setConversation} toggleSidebar={props.toggleSidebar} />}

            {conversation !== null && <Conversation recipientUuid={conversation} conversation={setConversation}/>}

        </Box>

    )

}