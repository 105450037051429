import React, { useEffect, useState, useContext } from 'react';
import { AccountContext } from './Accounts';
import { SkeletonCircle, Menu, MenuButton, MenuList, MenuItem, Button, Avatar, Box, Text, MenuDivider } from '@chakra-ui/react';
import LoginManager from './LoginManager';

export default (props) => {

    const [status, setStatus] = useState(null);
    const { getSession, logout } = useContext(AccountContext);

    const logoutUser = () => {
        logout();
        checkSession();
    }

    const checkSession = async () => {

        getSession().then(session => {
        
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "z94pfyki",
                email: session.email,
                //name: '',
                user_id: session.sub,
            });
    
            setStatus(true);
        })
        .catch(err => {
            setStatus(false);
    
        });
    }

    useEffect(() => {
        checkSession();
    }, []);

    
    window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "z94pfyki",
    });

    return (
        
        <Menu>
            <MenuButton as={Avatar} src={props.picture ? 'data:image/jpeg;base64,' + props.picture : '/images/avatars/1.png'} cursor='pointer' border='2px solid white' _hover={{border: '2px solid', borderColor: 'blue.500'}}>
                
            </MenuButton>
            <MenuList>

                {status !== null && status ? 
                    <>
                        {props.menu}
                        <MenuDivider/>
                        <MenuItem onClick={logoutUser}>Logout</MenuItem>
                    </>
                    : 
                    <>
                    </>
                }

            </MenuList>
        </Menu>
    );
};


//                 <LoginManager onLogin={checkSession} />
