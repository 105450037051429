import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { AsYouType } from "libphonenumber-js";
import { MdPhone, MdExpandMore } from "react-icons/md";
import sc from "states-cities-db";
import { set } from "date-fns";

const COUNTRIES = sc.getCountries();

const getCountryTelCode = country =>
  country && COUNTRIES.find(({ iso }) => iso === country).prefix;

  const countryOptions = COUNTRIES.map(({ name, iso, prefix }) => ({
    label: name,
    value: iso,
    prefix
  }));

export default function PhoneNumberInput({
  size,
  value,
  country,
  options,
  onChange,
  placeholder,
  ...rest
}) {
    


  let [number, setNumber] = useState(null);
  let [selectedCountry, setSelectedCountry] = useState(null);
  let [countryCode, setCountryCode] = useState(null);
  let [loading, setLoading] = useState(true);

  let tmpCountry = "";
  let tmpCountryCode = "";
  useEffect(() => {
      
    if (value === null || value === undefined ){
      
      tmpCountry = 'GBR';
      tmpCountryCode = '+44';
    
    } else if (value.split(" ").length > 1) {
      tmpCountryCode = value.split(" ")[0];
      console.log("tmpCountry", tmpCountryCode);
      // Loop through country options
      // If the country code matches, set it
      // If not, set it to GBR
      console.log("countryOptions", countryOptions);
      countryOptions.forEach((option) => {
          if (option.prefix === tmpCountryCode) {
              tmpCountry = option.value;
          }
      });
      console.log("tmpCountry", tmpCountry);
      // look up country code
    }
    let tmpValue = value ? value.replace(tmpCountryCode, "").trim() : "";
    console.log("Setting country", tmpCountry, tmpCountryCode, tmpValue);
    setSelectedCountry(tmpCountry);
    setCountryCode(tmpCountryCode);
    setNumber(tmpValue);
    setLoading(false);
  }, [country]);



  const onCountryChange = e => {
    let value = e.target.value;
    let code = getCountryTelCode(value);
    let parsedNumber = new AsYouType().input(`${code}${number}`);

    setCountryCode(code);
    setSelectedCountry(value);
    onChange(parsedNumber);
  };

  const onPhoneNumberChange = e => {
    let value = e.target.value;
    let parsedNumber = new AsYouType().input(`${countryCode}${value}`);

    setNumber(value);
    onChange(parsedNumber);
  };

  return (
    <>
      {!loading &&
      <InputGroup size={size} {...rest}>
      <InputLeftElement width="4rem">
        <Select
          top="0"
          left="0"
          zIndex={1}
          bottom={0}
          opacity='0!important'
          height="100%"
          position="absolute"
          value={selectedCountry}
          onChange={onCountryChange}
          className='country-select-phone'
        >
          <option value="" />
          {countryOptions.map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Select>
        <Flex pl={2} width="100%" alignItems="center">
          {selectedCountry ? (
            <Box mr="4px" width="50%" flex={1}>
              <Flag height="1rem" code={selectedCountry || ""} />
            </Box>
          ) : (
            <MdPhone />
          )}
          <MdExpandMore/>
        </Flex>
      </InputLeftElement>
      <Input
        pl="4rem"
        type="tel"
        value={number}
        pattern="[0-9]"
        placeholder={placeholder}
        onChange={onPhoneNumberChange}
      />
      
    </InputGroup>
}
    </>
    
  );
}

PhoneNumberInput.defaultProps = {
  options: [],
  size: "md"
};
