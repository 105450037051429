import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Image, Menu, MenuList, MenuItem, CircularProgress, CircularProgressLabel, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, MenuOptionGroup, border } from '@chakra-ui/react';
import API from "../../../core/utils/API";
import {MdAttachFile, MdOutlineErrorOutline, MdWarning, MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos, MdList, MdInfoOutline, MdRefresh } from "react-icons/md";
import LoadingCircle from '../../../static/layout/LoadingCircle';
import Loading from '../../../static/layout/Loading';
import Layout from '../../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import Modal from '../../../core/components/modal/Modal';
import pluralize from 'pluralize';
import InputWrapper from '../../../core/components/forms/inputs/InputWrapper';

import demoBids from '../demo/Bids';
import Card from '../components/Card';

function Currency({value}) {

    // check if value is a number
    if (isNaN(value)) {
        return
    }
    return (
        <Text>
            £{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
    )
}

function OverviewCard(props) {  

    let navigate = useNavigate();
    let colours = {
      'error': 'red.500',
      'inprogress': 'yellow.500',
      'completed': 'green.500',
      'locked': 'gray.500',
      'top-recommendation': 'green',
      'recommended': 'green',
      //'not-recommended': 'red',
    }

    let highlightColours = {
      'error': 'red.50',
      'inprogress': 'yellow.50',
      'completed': 'green.50',
      'locked': 'gray.50',
      'top-recommendation': 'green.50',
        'recommended': 'green.50',
    }

    let styles = {
        'top-recommendation': 'solid',
        'recommended': 'outline',
        'not-recommended': 'subtle'
    }

    let labels = {
        'top-recommendation': 'Top Recommendation',
        'recommended': 'Recommended',
        'not-recommended': 'Not recommended'
    }

    return (
        <GridItem 

            >
            <Card
                p={4}
                _hover={{   
                    cursor: 'pointer',
                    bg: highlightColours[props.status] ? highlightColours[props.status] : props.score > 90 ? 'green.50' : props.score > 80 ? 'yellow.50' : 'red.50',
                }}
                onClick={props.onClick}
                status={props.status}
                //borderBottomColor={colours[props.status]}
                role='group'

                // Check if props.selected is the same as the current company
                bg={props.current === props.company ? highlightColours[props.status] : 'white'}
                >
                
                <HStack spacing={4} w='100%' justifyContent='space-between'>
                <Stack w='fit-content'>
                    <Heading size='label'>
                        {props.company}
                    </Heading>

                    {(props.status === 'recommended' || props.status === 'top-recommendation') ?
                        <Tag size='sm' px={2}
                            _groupHover={{
                            /*
                            color: colours[props.currentStatus],
                            bg: 'none',
                            borderColor: colours[props.currentStatus],
                            */
                            }} 
                            variant={styles[props.status]}
                            colorScheme={colours[props.status]}
                            >
                            {labels[props.status]}
                        </Tag>
                      :
                        <Text fontSize='xs' color='gray.500'>
                          {labels[props.status]}
                        </Text>
                    }

                </Stack>

                <Flex  w='fit-content' mr={4}>
                      <Text fontSize='2xl'
                        fontWeight='bold'
                        color={props.score > 90 ? 'green.500' : props.score > 80 ? 'yellow.500' : 'red.500'}
                        >
                        {props.score}
                      </Text>
                    </Flex>
                    </HStack>
            </Card>
        </GridItem>


    )

}

export default function Dashbaord(props) {

  console.log('demoBids', demoBids);


    const [bids, setBids] = useState(demoBids);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [selected, setSelected] = useState({});

    let toast = useToast();
    let navigate = useNavigate();


 
    return (

        <SimpleGrid columns={{base: 1, md: 3}} spacing={10} pt={0}>

    <GridItem colSpan={2}
    h='fill-available'>

            <GridItem colSpan='12' mr={10}>

            <Heading size='sm' pb={10} color='blue.500'>
                Recommended Bids
            </Heading>

                {error && <Alert status='error'mb={4}>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }


                <SimpleGrid
                    minChildWidth='230px'
                    spacing={4} pb={12}>

                    {bids.map((bid) => {

                        if (bid.status !== 'recommended' && bid.status !== 'top-recommendation') {
                            return null;

                        }
                   
                          console.log('bid', bid);
                            return (
                                <OverviewCard 
                                    {...bid}
                                    current={selected.company}
                                    onClick={() => {
                                        setSelected(bid);
                                    }}
                                    />
                            )
                        }
                    )}

                </SimpleGrid>


                            <Heading size='sm' pb={10}>
                                All Bids
                            </Heading>


                        <SimpleGrid minChildWidth='230px' spacing={4} pb={12}>

                            {bids.map((bid) => {

                                if (bid.status === 'recommended' || bid.status === 'top-recommendation') {
                                    return null;

                                }

                                console.log('bid', bid);
                                    return (
                                        <OverviewCard 
                                            {...bid}
                                            current={selected.company}
                                            onClick={() => {
                                                setSelected(bid);
                                            }}
                                            />
                                    )
                                }
                            )}

                        </SimpleGrid>


            </GridItem>



</GridItem>

<GridItem colSpan='1'
    h='100%'

    >
    
    <Card
    >

        <Box >

            <Heading size='sm'>
                {selected.company ?
                    selected.company
                    :
                    'Select a bid'
                }
            </Heading>
            <Text pt={10}>
                {selected.company ?
                    selected.summary
                    :
                    'No bid selected'
                }
            </Text>
        </Box>  


        {selected.company &&
            <Stack spacing={0} 
                pt={8}
                alignItems='flex-start'
                w='fill-available'  
                >

                <HStack pb={8}spacing={0}
                    alignItems='flex-start'            
                    w='100%'  
                    >
                        
                        <CircularProgress value={selected.score} size='60px' color={selected.score > 90 ? 'green.500' : selected.score > 80 ? 'yellow.500' : 'red.500'}
                                mr={4}
                                thickness='4px'
                                >
                                <CircularProgressLabel>
                                    {selected.score}
                                </CircularProgressLabel>
                        </CircularProgress>

                            <Stack spacing={0}>
                                <Text fontColor='black'>
                                    Average Score
                                </Text>
                                <Text color='subtle' fontSize='sm'>
                                    The average score of this bid compared to other bids.
                                </Text>
                            </Stack>
                </HStack>

                <Heading fontSize='md' pb={8} pt={2} m={0} lineHeight='1em'>
                    Automated Scoring
                </Heading>
                
                {selected.details.map((detail) => {

                    let v = detail.data.bid - detail.data.lowest;
                    let flip = detail.data.flip ? true : false;
                    let range = detail.data.highest - detail.data.lowest;

                    if (['Price', 'Delivery'].includes(detail.criteria)) {
                        flip = true;
                    }
                    if (flip) {
                        v = range - v;
                    }

                    let percentage = (v/range)*100;

                    return (
                        <HStack pb={8} borderBottom='1px solid whitesmoke' spacing={0}
                            w='fill-available'
                            >

                            <CircularProgress value={detail.score} size='60px' color={detail.score > 90 ? 'green.500' : detail.score > 80 ? 'yellow.500' : 'red.500'}
                                mr={4}
                                thickness='4px'
                                >
                                <CircularProgressLabel>
                                    {detail.score}
                                </CircularProgressLabel>
                            </CircularProgress>

                            <Stack spacing={0}
                                w='fill-available'
                                >
                                <Text fontColor='black' fontWeight='600'>
                                    {detail.criteria}

                                    <Text as='span' color='subtle'  ml={1}>
                                        ({detail.data.unit})
                                    </Text>
                                </Text>
                                <Text>
                                    {detail.description}
                                </Text>
                                {// Create a small graph using detail.data
                                    // We have max/min/average/bid 
                                    // Create a small graph using detail.data
                                <Box pt='4px'>
                                    <Box w='100%' position='relative' h='24px' mt={2}>
                                        <Box w='100%' h='4px' bg='gray.200' position='absolute' top='0'>
                                            <Box w={percentage+'%'} h='100%' 
                                                bg={detail.score > 90 ? 'green.500' : detail.score > 80 ? 'yellow.500' : 'red.500'}
                                                transition='0.5s'
                                                >
                                            </Box>  
                                        </Box>
                                        <Text fontSize='xs' position='absolute'
                                            bottom='0'
                                            left='0'
                                            color={flip ?
                                                detail.data.bid === detail.data.highest ?
                                                'red.500' : 'gray.500'
                                                : 'gray.500'}
                                            >
                                            {!flip ? detail.data.lowest : detail.data.highest}
                                        </Text>
                                        <Text fontSize='xs' position='absolute'
                                            bottom='0'
                                            right='0'
                                            color={flip ?
                                                detail.data.bid === detail.data.lowest ?
                                                'green.500' : 'gray.500'
                                                : 'gray.500'}
                                            >
                                            {!flip ? detail.data.highest : detail.data.lowest}
                                        </Text>
                                        <Text fontSize='xs' position='absolute'
                                            bottom='0'
                                            left={percentage+'%'}
                                            ml='-8px'
                                            color={detail.score > 90 ? 'green.500' : detail.score > 80 ? 'yellow.500' : 'red.500'}
                                            transition='0.5s'
                                            opacity={(detail.data.lowest === detail.data.bid || detail.data.highest === detail.data.bid) ? 0 : 1}
                                            >
                                            {detail.data.bid}
                                        </Text>
                                    </Box>
                                    </Box>
                                }
                            </Stack>
                        </HStack>
                    )
                })}

                <Heading fontSize='md' pb={8} pt={2} m={0} lineHeight='1em'>
                    Documents
                </Heading>

                {[{
                    name: 'Financial Documents',
                    type: 'PDF'
                }, {
                    name: 'Company Documents',
                    type: 'PDF'
                },
                {
                    name: 'Bid Documents',
                    type: 'PDF'
                }].map((doc) => {

                    return (
                        <HStack pb={8} spacing={0}
                            alignItems='flex-start'
                            cursor='pointer'
                            role='group'
                            color='blue.500'
                            _hover={{
                                //bg: 'gray.50'
                            }}
                            w='fill-available'
                            >
                                <Box minW='80px' maxW='80px' pr={4}
                                    textAlign='center'
                                    >
                                    <Text py='2px' px='4px' fontSize='10px' m='auto'
                                        w='fit-content' borderRadius='md'
                                        color='#8B0000'
                                        border='1px solid #8B0000'
                                        borderColor='#8B0000'
                                        bg="white"
                                        _groupHover={{
                                            color: 'white',
                                            bg: '#8B0000'
                                        }}
                                        >
                                        {doc.type}
                                    </Text>
                                </Box>
                                <Stack spacing={0} 
                                w='fill-available'>
                                    <Text fontColor='black'
                                        fontWeight='600'
                                        _groupHover={{
                                            color: 'blue.500'
                                        }}
                                        >
                                        {doc.name}
                                    </Text>
                                </Stack>
                        </HStack>
                    )
                })}


                <Heading fontSize='md' pt={2} pb={8} m={0} lineHeight='1em'>
                    Outcome
                </Heading>
                <ButtonGroup pb={0}>
                    <Button colorScheme='green' variant='outline'>
                        Accept
                    </Button>
                    <Button colorScheme='red' variant='outline'>
                        Decline
                    </Button>

                </ButtonGroup>

            </Stack>

        }

        
    </Card>

    </GridItem>
</SimpleGrid>   
)}
