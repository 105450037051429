import {
  Box,
  Button,
  Circle,
  Heading,
  HStack,
  Icon,
  Tag,
  List,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'
import { PricingCardBadge } from './PricingCardBadge'
import { Link } from 'react-router-dom'

export const PricingCard = (props) => {
  const { product } = props
  return (
    <Box
      bg="bg-surface"
      borderRadius="2xl"
      boxShadow="lg"
      px={{
        base: '6',
        md: '8',
      }}
      py="8"
      position="relative"
      overflow="hidden"
      borderColor='blue.500'
      borderWidth='2px'
      border='solid black 2px'
    >
      {product.isPopular && (
        <PricingCardBadge bg='blue.500'>
          <Text fontWeight="medium" color='invert'>Popular</Text>
        </PricingCardBadge>
      )}
      <Stack spacing="8" textAlign="center">
        <Stack spacing="5" align="center">
          <Stack spacing="4">
            <Heading
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              {product.name}
            </Heading>
            <Stack spacing="1" alignItems='center'>
              <Text fontSize="xl" fontWeight="semibold">
              {product.price}<Text as='span' fontSize='18px' mb='-10px'>{product.price_suffix}</Text> <Text as="span" fontSize="sm" color="muted">{product.price_addendum}</Text>
              </Text>
              <Text color="muted">{product.description}</Text>
              {product.deal != undefined &&
                <Tag colorScheme='blue' w='fit-content' m='auto'>
                  {product.deal}
                </Tag>
              }
            </Stack>
          </Stack>
        </Stack>
        <List as="ul" spacing="4">
          {product.features.map((feature) => (
            <HStack key={feature} as="li" spacing="3">
              <Circle size="6" bg={mode('whitesmoke', 'whiteAlpha.50')}>
                <Icon as={FiCheck} color="black" />
              </Circle>
              <Text color="muted" align='left'>{feature}</Text>
            </HStack>
          ))}
        </List>
        <a href={product.buttonUrl}>
          <Button>
            Get started
          </Button>
        </a>
      </Stack>
    </Box>
  )
}

export default PricingCard