import { theme as proTheme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'
import * as components from './components/index'
import * as foundations from './foundations'
import { styles } from './styles'

const baseTheme = { ...proTheme }
baseTheme.colors = {} // only provide blueberry colors
baseTheme.components.Button.sizes = {} // blueberry does not have a "xs" button size

export const theme: Record<string, any> = extendTheme(
  {
    styles,
    ...foundations,
    colors: { ...foundations.colors, brand: foundations.colors['purple'] },
    components: { ...components },
    fonts: {
      heading: `'Satoshi-Regular', sans-serif`, // `'Raleway', sans-serif`,
      body: `'DM Sans', sans-serif`,
    },
    
  },
  baseTheme,
)
