import {Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';

export default function Contact(props) {

    return (


        <Stack spacing={4}>

            <InputWrapper
                name='firstname'
                type='input'
                label={props.translate('Firstname', props.lang)}
                required={true}
                setValue={props.setValue}
                />
            <InputWrapper
                name='lastname'
                type='input'
                label={props.translate('Lastname', props.lang)}
                required={true}
                setValue={props.setValue}
                />
            <InputWrapper
                name='email'
                type='input'
                label={props.translate('Email', props.lang)}
                required={true}
                setValue={props.setValue}
                />
            <InputWrapper
                name='phone'
                type='phone'
                label={props.translate('Phone', props.lang)}
                required={true}
                setValue={props.setValue}
                />
            <InputWrapper
                name='address'
                type='address'
                label={props.translate('Address', props.lang)}
                required={true}
                setValue={props.setValue}
                />
    
        </Stack>


    )

}