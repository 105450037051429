import { Box, CloseButton, Container, Link, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import {useState} from 'react';


export default function BasicBanner(props) {


  const [show, setShow] = useState(true);
  const [message, setMessage] = useState(props.message);

  setShow(true);


  return (
    <>
      {show &&
        <Box mb={4} bg={(props.background == undefined) ? 'brand-light' : props.background} color="brand-white" w='100%' p={{ base: '4', md: '3',}} py={{base: '3',md: '5',}} position="relative" >
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            justify="center"
            spacing={{
              base: '0.5',
              md: '1.5',
            }}
            pe={{
              base: '4',
              sm: '0',
            }}
          >
            <Text color='brand-white'>{props.message}</Text>
          </Stack>
          {props.closable != 'false' &&
            <CloseButton
              onClick={() => setShow(!show)}
              position="absolute"
              right="2"
              top={{
                base: '2',
                md: '4',
              }}
            />
          }
        </Box>
    }
  </>
)
    }