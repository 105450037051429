import { useAuth0 } from "@auth0/auth0-react";
import { PageBlocked } from "./PageBlocked";
import React, {useState} from 'react';
import { Outlet } from 'react-router-dom';
import { useEffect } from "react";
import { useContext } from "react";
import {
  Flex,
  Wrap,
  Button,
  Box,
  useColorModeValue,
  useColorMode,
  Image,
  Spinner,
  Text,
  Stack,
  Center,
  Progress
} from '@chakra-ui/react';
//import { addAccessTokenInterceptor } from "../core/utils/AxiosConfig";
import Interceptor from "./Interceptor";
import { InterceptorContext } from './Interceptor';
import { AccountContext } from './cognito/components/Accounts';
import CallToActionButton from '../static/shared/CallToActionButton';
import Splash from '../static/layout/Splash';
import { useNavigate } from "react-router-dom";
import API from '../core/utils/API';
import {SubscriptionWrapper} from './SubscriptionContext';
import Loading from "../static/layout/Loading";

export const ProtectedRoute = () => { 

  // Print to console the current url
  //console.log("ProtectedRoute.js - window.location.href", window.location.href);

  const [ loggedIn, setLoggedIn ] = useState(false);
  const { isTokenSet } = useContext(InterceptorContext); 
  const [subscriptions, setSubscriptions] = useState([]);



  const Interceptor = useContext(InterceptorContext);
  const InterceptorSetup = Interceptor.setup;
  //console.log("LOGGED IN", InterceptorSetup);
    
  const navigate = useNavigate();


  // auth0
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  //Cognito
  const { getSession } = useContext(AccountContext);


  const [loading, setLoading] = useState();

  if (loading === undefined) {
    setLoading(true);
  }

  let color = 'grey.500';
  let colorScheme = 'grey';


    

 // console.log('ProtectedRoute.js - isTokenSet', isTokenSet);
 

        useEffect(() => {
          if (process.env.REACT_APP_AUTH_SERVICE === 'AUTH0' && isAuthenticated) {
          
            
            setLoggedIn(true);
          } else if (process.env.REACT_APP_AUTH_SERVICE === 'COGNITO') {
            getSession()
                .then(session => {
            
                  setLoggedIn(true);
                    setLoading(false);
                })
                .catch(err => {
                  color='red.500';
                  colorScheme='red';
                  setLoggedIn(false);
                  setLoading(false);

                  // Redirect to login page
                  navigate('/login');
                });
            
          }


        
        }, []);
    

    useEffect(() => {



    }, [loggedIn])
        

        return loggedIn && InterceptorSetup  ? 
        
        <Outlet />
        
        :
        <Splash>        
          {loading ?
                
            <Loading message='Protected Route: Checking your login details'/>

            :
            
            <Loading 
              message='Protected Route: You must have an account and be logged in to view this page'
              loading={false}
              show={true}
              addendum={<>
                <Center>
                  <Wrap spacing={2} w='fit-content' ml='auto' mr='auto'>
                      <Box className='new-login-btn-wrapper'>
                          <Box id='loginButton' className='new-login-btn' variant='outline' 
                          onClick={() => {navigate('/login')}}
                            >
                              Login
                          </Box>
                      </Box>
                      <CallToActionButton
                          id='signupButton'
                          text='Signup'
                          className='navbar'
                          onClick={() => {navigate('/signup')}}
                        />
                    </Wrap>
                    </Center>
              </>}
              />
              
          }
        </Splash>

      ;
};