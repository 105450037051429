
// This is going to export Products which is a JSON object with keys being specific products


export default {
    'Invoice Automation': {
        name: 'Invoice Automation',
        description: 'Automate your invoicing process with our AI tools',
        price: '£1000',
        price_addendum: 'pcm',
        product_ids: ['prod_QJFrJdHK23vjcH'],
        price_id: 'price_1PXPu8CpM5K88EcGicIiTyGw',
        deal: 'Integrates with Xero and SBS',
        features: [
            'Integration with existing systems',
            'Granular control of invoice submission',
            'Beautiful intuitive interface',
            'Analytics'
        ],

    },
    'Applicant Tracking System': {
        name: 'Applicant Tracking System',
        description: 'Automate your recruitment process',
        price: '£1000',
        price_addendum: 'pcm',
        product_ids: ['block'],
        product_id: 'null',
        price_id: 'null',
        deal: '',
        features: [
            'Automated job posting',
            'AI supported candidate selection',
            'Automated interview scheduling',
            'Personalised candidate feedback',
        ],
    },
    'Automated Onboarding': {
        name: 'Automated Onboarding',
        description: 'Automate your onboarding process',
        price: '£1000',
        price_addendum: 'pcm',
        product_ids: ['block1'],
        price_id: 'null',
        deal: '',
        features: [
            'Automated document collection',
            'Granular control of onboarding process',
            'Automated and personalised alerts',
            'Roles and permissions templates'
        ],
    },
    'Digital ID Checks': {
        name: 'Digital ID Checks',
        description: 'Remote digital ID checks for your employees',
        price: 'On request',
        price_addendum: '',
        product_ids: ['block2'],
        price_id: 'null',
        deal: '',
        features: [
            'Automated ID checks',
            'International ID checks',
            'Granular control of ID checks',
            'Automated and personalised alerts'
        ],
    },
    'Compliance Monitoring': {
        name: 'Compliance Monitoring',
        description: 'Automate compliance monitoring for your employees',
        price: '£1000',
        price_addendum: 'pcm',
        product_ids: ['block3'],
        price_id: 'null',
        deal: '',
        features: [
            'Automatically monitor compliance',
            'Automated and personalised alerts',
            'Alerts and notifications',
            'Granular control of compliance monitoring',
        ],
    },
    'Feedback System': {
        name: 'Feedback System',
        description: 'Automate your feedback process',
        price: '£1000',
        price_addendum: 'pcm',
        product_ids: ['block4'],
        price_id: 'null',
        deal: '',
        features: [
            'Automated feedback collection',
            'Automated and personalised alerts',
            'Granular control of feedback process',
            'Feedback analysis'
        ],
    }
}