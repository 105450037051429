import React, { useState, useEffect } from 'react';
import { HStack, ButtonGroup, Wrap, Stack, Heading, Box, Input, Button, Text } from '@chakra-ui/react';
import API from '../../core/utils/API';
import Layout from '../../core/layout/Layout';

export default function Encryption(props) {

    const [count, setCount] = useState(0);

    const getCount = () => {

        API.getItems({url: 'admin/encryptioncount'}).then((response) => {
            console.log("Got count: ", response.data.count);
            setCount(response.data.count);
        }).catch((error) => {
            console.log("Error from API: ", error)
        })

    }

    useEffect(() => {
        getCount();
    }, []);


    return (

        <Layout 
            title="Encryption"
            description="AES-256 encryption for all data at rest ensures that the data is secure even if there security settings on a single services are compromised. It has a 256-bit key length, and AES-256 encryption is used by governments, it is the highest level of encryption currently available. It is practically unbreakable by brute force based on current computing power, making it the strongest encryption standard.
            We use AES-GCM-256, which is a mode of operation for symmetric key cryptographic block ciphers that uses the Advanced Encryption Standard (AES) algorithm. It is an authenticated encryption algorithm designed to provide both data authenticity (integrity) and confidentiality.
            You can use this methodlogy for up to 4,294,967,296 records before you need to change the key."
            >
                <Box key={count}>
                    {count ? count : 'Loading'} of 4,294,967,296
                </Box>

                <Box>
                    {count && (count / 4294967296 * 100).toFixed(2) + '%'}
                </Box>
            
        </Layout>

    )
}
