
import {
    Flex,
    Box,
    useColorModeValue,
    useColorMode,
    Image,
    Spinner,
    Text,
    Progress,
    Button,
    Stack,
    Center
  } from '@chakra-ui/react';
  import Splash from './Splash';
import { useEffect, useState } from 'react';
  import Logo from './logo.svg';
import { set } from 'date-fns';
export default function Loading(props) {

    const { colorMode, toggleColorMode } = useColorMode();

    // Set loading variable to true by default, or to the value passed in props
    //const loading = props.loading === undefined ? true : props.loading;

    // Create a timeout to stop the loading animation after 10 seconds
    // This is to prevent the loading animation from continuing indefinitely
    // in the event of an error
    const [loading, setLoading] = useState(props.loading || true);
    const [blankTimeout, setBlankTimeout] = useState(props.show || false);
    const [totalTimeout, setTotalTimeout] = useState(false);

    // Set timeout to true after 10 seconds
    function stopLoading(time) {
        if (time === 10000) {
            setTotalTimeout(true);
            setLoading(false);
        }
        if (time === 2000) {
            setBlankTimeout(true);
        } 
    }

    // Create a timeout to stop the loading animation after 10 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setTotalTimeout(true);
            setLoading(false);
        }, props.timeout * 1000 || 10000);
        return () => clearTimeout(timer);
    }, []);

    // Create a timeout to show the loading animation after 2 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setBlankTimeout(true)
        }, props.blankTimeout * 1000 || 2000);
        return () => clearTimeout(timer);
    }, []);


    //console.log("Loading", loading, timeout, blankTimeout, props.show);

    return (


        <Splash>  

            <Center display='none' justifyContent='center' alignItems='center' direction='column' flex='1' height='100%' pt={props.pt} >
                <Image src='/logo.png' h='30px' minW='112px' mt='12px' mr={2} alt='Logo' />               
            </Center>

            <Center display={(props.show || blankTimeout) ? 'inherit' :  'none' } justifyContent='center' alignItems='center' direction='column' flex='1' height='100%' pt={props.pt} >
            <Stack justifyContent='center' spacing={6} mt='-80px' pt='80px' minW='400px'>

                {loading ?
                    <Box className="loading active" w='100%' m='auto'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Box>
                :
                    <Box className="loading inactive" w='100%' m='auto'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Box>
                }
                    <Center display='none'>
                        <Image src='./logo.png' h='40px' alt='Logo'/>
                    </Center> 
              {props.message ?
                <>
                    <Text textAlign='center'>
                        {props.message}
                    </Text>
                    {props.addendum}
                    {!loading &&
                        <Text textAlign='center' color='red.500'>Sorry that didn't work, it took too long</Text>
                    }
                </>
                :
                    <>
                    {props.children}
                    </>
                }

                </Stack>
            </Center>
        </Splash>

    )
}
      