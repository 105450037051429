import Splash from '../../static/layout/Splash';
import { Text, Button, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {MdCheckCircleOutline} from 'react-icons/md';
export default function Success(props) {

    const navigate = useNavigate();

    return (
        
        <Splash title='Error' subtitle='Something went wrong' 
            icon={MdCheckCircleOutline}
            iconColor='green.500'
            {...props}>

            <Stack spacing={4} textAlign='center'>
                <Text color='emphasis' fontWeight='600'>ID Check Completed</Text>
                <Text pb={4}>We are automatically checking your submitted documents, you can check progress from the main list.</Text>
                <Button colorScheme='blue' ml='auto !important' mr='auto !important' w='fit-content' variant='outline'  onClick={() => navigate('/my/dbs/list')}>Return</Button>    
            </Stack>

        </Splash>


    )
}