import React, { useState, useEffect } from 'react';
import {SimpleGrid, GridItem, Center, Box, HStack, Stack, Button, Heading, Input, Text } from '@chakra-ui/react';
import Kyber1024 from '../auth/Kyber1024';
import {Buffer} from 'buffer';
import Layout from '../core/layout/Layout';

export const EncryptionTest = (props) => {

    const [iv, setIv] = useState(''); // This is the key that is used to encrypt the data
    const [localKey, setLocalKey] = useState(''); // This is the key that is used to encrypt the data
    const [serverKey, setServerKey] = useState('');
    const [testKey, setTestKey] = useState('');
    const autoTestMessage = "This is a local test message";
    const [encryptedAutoTestMessage, setEncryptedAutoTestMessage] = useState(''); // This is the key that is used to encrypt the data
    const [decryptedAutoTestMessage, setDecryptedAutoTestMessage] = useState(''); // This is the key that is used to encrypt the data
    
    const [textSS1, setTextSS1] = useState('');
    const [textSS2, setTextSS2] = useState('');
    const [encLocalMessage, setEncLocalMessage] = useState('');
    const [encServerMessage, setEncServerMessage] = useState('');
    const [decLocalMessage, setDecLocalMessage] = useState('');
    const [decServerMessage, setDecServerMessage] = useState('');
    const [decLocalUsingServerMessage, setDecLocalUsingServerMessage] = useState('');
    const [decServerUsingLocalMessage, setDecServerUsingLocalMessage] = useState('');

    useEffect(() => {
        AES256_GCM_GENERATEKEY();
        createKey();

    }, []);

    async function AES256_GCM_GENERATEKEY() {

        let tmpIv = window.crypto.getRandomValues(new Uint8Array(12));
        console.log("IV", tmpIv);
        setIv(tmpIv);
    
        window.crypto.subtle.generateKey({
                name: "AES-GCM",
                length: 256, //can be  128, 192, or 256
            },
            false, //whether the key is extractable (i.e. can be used in exportKey)
            ["encrypt", "decrypt"] //can "encrypt", "decrypt" or  "wrapKey", or "unwrapKey"
        ).then(function(key) {
            setTestKey(key);
        }).catch(function(err) {
            console.error(err);
        });

    }
    



    function AES256_GCM_ENCRYPT(secretKey, plainText) {

        if (secretKey == null) {
            alert("Failed to Generate AES Keys Check The browser Comptabilty ")
            return;
        } else {
            console.log(secretKey);
        }
    
    
        return crypto.subtle.encrypt({
            name: "aes-gcm",
            iv: iv,
            tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
        }, secretKey, asciiToUint8Array(plainText)).then(function(cipherText) {

            let tmpIv = typedArrayToBuffer(iv);
            let final = appendBuffer(cipherText, iv);
            final = bytesToHexString(final);
            console.log(final);
            return final;
            document.getElementById("cipherTextGCM").value = bytesToHexString(final);
        }, failAndLog);
    
    }

    var appendBuffer = function(buffer1, buffer2) {
        var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
        tmp.set(new Uint8Array(buffer1), 0);
        tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
        return tmp.buffer;
      };

    function typedArrayToBuffer(array: Uint8Array): ArrayBuffer {
        return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
    }
    
    function AES256_GCM_DECRYPT(secretKey, cipherText) {
    
        if (secretKey == null) {
            alert("Failed to Generate AES Keys Check The browser Comptabilty ")
            return;
        }

        let cipherArray = hexStringToUint8Array(cipherText);
        console.log("Cipher Array", cipherArray);

        let cipherIV = cipherArray.slice(cipherArray.length - 12);
        console.log('Cipher IV', cipherIV);

        let cipherData = cipherArray.slice(0, cipherArray.length - 12);

        console.log("Cipher Data", cipherData);

       

        return crypto.subtle.decrypt({
                name: "aes-gcm",
                iv: cipherIV,
                tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
            },
            secretKey,
            cipherData
        ).then(function(decryptedText) {

            let final = bytesToASCIIString(decryptedText);
            console.log("DECRYPTED", final);
            return final;
        }, failAndLog);
    }
    
    function bytesToHexString(bytes) {
        if (!bytes)
            return null;
    
        bytes = new Uint8Array(bytes);
        var hexBytes = [];
    
        for (var i = 0; i < bytes.length; ++i) {
            var byteString = bytes[i].toString(16);
            if (byteString.length < 2)
                byteString = "0" + byteString;
            hexBytes.push(byteString);
        }
    
        return hexBytes.join("");
    }
    
    
    function bytesToASCIIString(bytes) {
        return String.fromCharCode.apply(null, new Uint8Array(bytes));
    }
    
    function hexStringToUint8Array(hexString) {
        if (hexString.length % 2 != 0)
            throw "Invalid hexString";
        var arrayBuffer = new Uint8Array(hexString.length / 2);
    
        for (var i = 0; i < hexString.length; i += 2) {
            var byteValue = parseInt(hexString.substr(i, 2), 16);
            if (byteValue == NaN)
                throw "Invalid hexString";
            arrayBuffer[i / 2] = byteValue;
        }
    
        return arrayBuffer;
    }
    
    function failAndLog(error) {
        console.log(error);
    }
    
    function asciiToUint8Array(str) {
        var chars = [];
        for (var i = 0; i < str.length; ++i)
            chars.push(str.charCodeAt(i));
        return new Uint8Array(chars);
    }
    


    // Post Quantum Key Generation
    async function createKey() {

        let pk_sk = Kyber1024.KeyGen1024();
        let pk = pk_sk[0]; // Public key
        let sk = pk_sk[1]; // Private key

        let c_ss = Kyber1024.Encrypt1024(pk); // Using the public key sent by the server to encrypt the data
        let c = c_ss[0]; // c is the encapsulation of the symmetric key, this is sent to the server to decrypt data after transfer
        let ss1 = c_ss[1]; // ss is the symmetric key, this is used to encrypt the data

        console.log("Encapsulated symmetric key: ", c);
        console.log("Private key: ", sk);

        // Convert the keys to base64 strings
        let ss1Base64 = Buffer.from(ss1).toString('base64');

        setTextSS1(ss1Base64);

        window.crypto.subtle.importKey(
            'raw', 
            Buffer.from(ss1), 
            {
                name: 'AES-GCM', 
                length: 256
            }, 
            false, 
            ['encrypt', 'decrypt']
        ).then(function(key) {
            console.log("Local Key:", key);
            setLocalKey(key);
        }).catch(function(err) {
            console.error(err);
        });

        let ss2 = Kyber1024.Decrypt1024(c, sk); // Using the private key to decrypt the encapsulated symmetric key
        let ss2Base64 = Buffer.from(ss2).toString('base64');

        setTextSS2(ss2Base64);

        window.crypto.subtle.importKey(
            'raw', 
            Buffer.from(ss2), 
            {
                name: 'AES-GCM', 
                length: 256
            }, 
            false, 
            ['encrypt', 'decrypt']
        ).then(function(key) {
            console.log("Server Key:", key);
            setServerKey(key);
        }).catch(function(err) {
            console.error(err);
        });
        
        return true;

    }

    async function runTest() {

        let message = document.getElementById("user-message").value;

        console.log("User asking us to test ", message);

        let quantumSetup = await createKey();

        console.log("Quantum key setup", quantumSetup);

        let encryptedLocal = await AES256_GCM_ENCRYPT(localKey, message);
        setEncLocalMessage(encryptedLocal);

        let encryptedServer = await AES256_GCM_ENCRYPT(serverKey, message);
        setEncServerMessage(encryptedServer);

        let decryptedLocal = await AES256_GCM_DECRYPT(localKey, encryptedLocal);
        setDecLocalMessage(decryptedLocal);

        let decryptedServer = await AES256_GCM_DECRYPT(serverKey, encryptedServer);
        setDecServerMessage(decryptedServer);

        let decryptedLocalUsingServer = await AES256_GCM_DECRYPT(localKey, encryptedLocal);
        setDecLocalUsingServerMessage(decryptedLocalUsingServer);

        let decryptedServerUsingLocal = await AES256_GCM_DECRYPT(localKey, encryptedServer);
        setDecServerUsingLocalMessage(decryptedServerUsingLocal);




    }

    return (    
        <Layout
            title='Encryption Testing'
            description='This is a live demonstration of the Kyber1024 encryption algorithm, a post-quantum encryption algorithm'
            >

        <GridItem colSpan='6'>

            <Stack spacing={8}>

            <HStack>
                <Input id='user-message' defaultValue='A test message, you can change this'/>
                <Button variant='outline' colorScheme='blue' onClick={() => {runTest();}}>
                    Encrypt
                </Button>
            </HStack>
            
            <Center>
                <Stack textAlign='center'>
                    <Heading size='label'>Shared Data</Heading>
                    <Text><b>IV:</b> {bytesToHexString(iv)}</Text>
                </Stack>
            </Center>

            <SimpleGrid spacing={4} columns={2}>

                <GridItem>
                    <Stack maxW='350px' spacing={4}>
                        <Heading size='label'>Local Key</Heading>
                        <Text><b>Type:</b> {localKey && localKey.algorithm.name + '-' + localKey.algorithm.length}</Text>
                        <Text><b>Base64:</b> {textSS1}</Text>
                        <Text><b>Encrypted:</b> {encLocalMessage}</Text>
                        <Text><b>Decrypted using Local Key:</b> {decLocalMessage}</Text>
                        <Text><b>Decrypted using Server Key:</b> {decLocalUsingServerMessage}</Text>
                    </Stack>
                </GridItem>

                <GridItem>
                    <Stack maxW='350px' spacing={4}>
                        <Heading size='label'>Server Key</Heading>
                        <Text><b>Type:</b> {serverKey && serverKey.algorithm.name + '-' + serverKey.algorithm.length}</Text>
                        <Text><b>Base64:</b> {textSS2}</Text>
                        <Text><b>Encrypted:</b> {encServerMessage}</Text>
                        <Text><b>Decrypted using Server Key:</b> {decServerMessage}</Text>
                        <Text><b>Decrypted using Local Key:</b> {decServerUsingLocalMessage}</Text>
                    </Stack>
                </GridItem>

            </SimpleGrid>
            </Stack>
            </GridItem>
            
        </Layout>
    )
}

export default EncryptionTest;