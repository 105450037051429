import React, { useState, useEffect } from 'react';
import {Box, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, ButtonGroup, InputGroup, InputRightAddon } from '@chakra-ui/react';
import { MdAdd, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdArrowForward } from "react-icons/md";
import API from "../../../core/utils/API";
import Moment from 'react-moment';
import Splash from '../../../static/layout/Splash';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../static/layout/LoadingCircle';

function roundtodp(number, dp = 0) {
    return Math.round(100 * number) / 100;
}


export default function ScheduleSelect(props) {

    const [schedules, setSchedules] = useState([]);
    const [originalSchedules, setOriginalSchedules] = useState(null);
    const [organisation, setOrganisation] = useState(null);
    const [specialty, setSpecialty] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showUpload, setShowUpload] = useState(false);
    const [searchCount, setSearchCount] = useState(0);

    const [search, setSearch] = useState(null);

    const navigate = useNavigate();

    function updateList() {

        console.log("Updating Schedules");

        let url = 'schedules/list';
        let config = {
            params: {
            }
        };
        console.log("Loading Schedules");
        let resp = API.getItems({url, config})
        .then(response => {
            console.log(response);
            // Loop through each and combine everything into a single line for searching
            response.data.schedules.forEach((schedule, index) => {
                let search = schedule.location + " " + schedule.specialty + " " + schedule.year + " " + schedule.fte + " " + schedule.start + " " + schedule.end;
                response.data.schedules[index].search = search;
                response.data.schedules[index].searchFound = 0;
            });

            setOriginalSchedules(response.data.schedules);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
        }

        );

    }


    useEffect(() => {

        if (search) {

            console.log("Searching Schedules");

            let searchTerms = search.split(" ");

            // Loop through each schedule and search for each term
            let newSchedules = originalSchedules;
            let foundSchedules = [];

            newSchedules.forEach((schedule, index) => {

                schedule.searchFound = 0;

                searchTerms.forEach((searchTerm, index) => {

                    if (schedule.search.toLowerCase().includes(searchTerm.toLowerCase())) {
                        schedule.searchFound++;
                    }

                });

                if (schedule.searchFound > 0) {
                    foundSchedules.push(schedule);
                }

            });

            // Order by number of search terms found
            foundSchedules.sort((a, b) => (a.searchFound < b.searchFound) ? 1 : -1);

            setSchedules(foundSchedules);
            setSearchCount(searchCount+1);

        } else {

            setSchedules(null);

        }

    }, [search]);

    useEffect(() => {

        updateList();

    }, []);


    function adoptSchedule(uuid) {

        console.log("Adopting Schedule");

        let url = 'schedules/adopt';

        let config = {
            params: {
                uuid: uuid
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {
            console.log(response);
            if (response.data.adopted === true) {
                console.log("Adopted", response.data.uuid);
                navigate('/pay/schedule', {state: {uuid: response.data.uuid}});
                //navigate('/pay/schedules');
            } else {
                console.log("Not Adopted");
                console.log(response);
            }
        })
        .catch(error => {
            console.log(error);
        });

    }


    return (

        <Splash 
            maxW='600px'
            footer={<Text display='none' fontSize='xs' color='subtle'>Pyrexia staff can view schedules to improve accuracy, by default core details (shift pattern, shift details, hospital location, etc) will be made available to your colleagues but cannot view the original document.</Text>}
            >

            <Box maxH='fill-available'>

                <Heading as="h1" size="sm" lineHeight='1em' mb={4}>Find a work schedule</Heading>

                <Text mb={4}>Search for a placement by hospital name, specialty and year:</Text>
                <InputGroup mb={4}>
                    <Input id='search-schedule' placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                    <InputRightAddon _hover={{color: 'red.500'}} onClick={() => {document.getElementById('search-schedule').value = ''; setSearch(null);}} cursor='pointer' children={<Icon as={MdClose} />} />
                </InputGroup>


                {loading ? <Loading/> : 

                            <Stack key={searchCount} mt={8} mb={8} bg='white' borderRadius='md' boxShadow='md' p={4} spacing={4} overflowY='scroll' maxH='40vh'>

                                {schedules && schedules.length > 0 ? 
                                    <>
                                        {schedules.map((schedule, index) => {

                                            return (
                                                <Flex key={schedule.uuid + "/" + schedules.length + "/" + searchCount} justifyContent='space-between'>
                                                    <Stack spcaing={0}>
                                                        <Text mt={0} m={0} fontWeight='600'>{schedule.specialty}</Text>
                                                        <Text mt='0 !important' m={0} fontSize='xs' color='subtle'>{schedule.site}, {schedule.grade} <Moment format="MMM YYYY">{schedule.start}</Moment></Text>
                                                        <Text display='none'><Moment>{schedule.start}</Moment>-<Moment>{schedule.end}</Moment></Text>
                                                    </Stack>
                                                    <Box>
                                                        <Button size='xs' variant='outline' onClick={() => {adoptSchedule(schedule.uuid)}} colorScheme="blue">Add</Button>
                                                    </Box>
                                                </Flex>
                                            )
                                        })}
                                    </>
                                :
                                    <Text>No schedules found.</Text>
                                }
                            </Stack>
                    
                    }


                <Flex justifyContent='space-between' alignContent='space-between'>
                    <Button variant='outline' onClick={() => {navigate('/pay/schedules')}}>Return</Button>
                    {schedules && !showUpload && search !== null && schedules.length !== 0 &&
                        <Text mt={2} color='blue.500' mb={2} cursor='pointer' fontSize='sm' _hover={{color: 'blue.500'}} onClick={() => {setShowUpload(true);}}>I can't find my work schedule</Text>
                    }
                    {((search !== null && schedules && schedules.length === 0 ) || showUpload ) &&
                        <Button variant='outline' onClick={() => {navigate('/pay/uploadschedule')}} colorScheme='blue'>Upload</Button>
                    }
                </Flex>
            
            </Box>

        </Splash>


    )
}