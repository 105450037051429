const current = {
    // uuid as key
    '1': {
        uuid: '3e6729a8-1bde-41f7-93be-23b1e3c8e123',
        label: '10ml Syringes',
        title: '10ml Syringes',
        deadline: '2025-01-01',
        currentStatus: 'error',
        stages: {
            'Clinical': {
                status: 'notstarted',
                completedSteps: 0,
                totalSteps: 5,
            },
            'IT': {
                status: 'locked',
                completedSteps: 0,
                totalSteps: 5,
            },
            'IG': {
                status: 'locked',
                completedSteps: 0,
                totalSteps: 5,
            },
            'Procurement': {
                status: 'locked',
                completedSteps: 0,
                totalSteps: 7,
            },
        },
        completedSteps: 0,
        totalSteps: 22,
        status: 'incomplete',
        workflow: 'closed-competition',
        summary: 'The current stage is Information Governance. 13 of 22 steps have been completed.',
    },
    '2': {
        uuid: '4b7d83f1-56ed-489f-b8d9-a12f1e2a4567',
        label: 'Template',
        title: 'Template',
        deadline: '2024-11-13',
        currentStatus: 'inprogress',
        stages: {
            'Clinical': {
                status: 'complete',
                completedSteps: 5,
                totalSteps: 5,
            },
            'IT': {
                status: 'complete',
                completedSteps: 5,
                totalSteps: 5,
            },
            'IG': {
                status: 'inprogress',
                completedSteps: 3,
                totalSteps: 5,
            },
            'Procurement': {
                status: 'locked',
                completedSteps: 0,
                totalSteps: 7,
            },
        },
        completedSteps: 13,
        totalSteps: 22,
        status: 'incomplete',
        workflow: 'closed-competition',
        summary: 'The current stage is Information Governance. 13 of 22 steps have been completed.',
    },
    'bids': {
        uuid: '5c8f29c1-99ef-42d9-b7f1-87c0b2a6d890',
        label: 'ECG Machines',
        title: 'ECG Machines',
        deadline: '2024-09-01',
        currentStatus: 'inprogress',
        status: 'incomplete',
        stages: {
            'Clinical': {
                status: 'complete',
                completedSteps: 5,
                totalSteps: 5,
            },
            'IT': {
                status: 'complete',
                completedSteps: 5,
                totalSteps: 5,
            },
            'IG': {
                status: 'complete',
                completedSteps: 5,
                totalSteps: 5,
            },
            'Procurement': {
                status: 'inprogress',
                completedSteps: 3,
                totalSteps: 7,
            },
        },
        currentSteps: [
            'Bids received',
        ],
        completedSteps: 15,
        totalSteps: 17,
        amount: 1000000,
        workflow: 'open-competition',

    },

    '3': {
        uuid: '6e923a2d-b5fd-4f87-a5a4-c132f3d45678',
        label: '30ml Syringes',
        title: '30ml Syringes',
        status: 'complete',
        workflow: 'open-competition',
        contractor: 'ABC Company',
        amount: 1000000,
        awarded: '2021-01-01',
        end: '2022-01-01',
    },
    '4': {
        uuid: '7f1b2d93-3bdf-4b38-90f9-45e2b7f4e789',
        label: '50ml Syringes',
        title: '50ml Syringes',
        status: 'complete',
        workflow: 'open-competition',
        contractor: 'XYZ Company',
        amount: 1000000,
        awarded: '2021-01-01',
        end: '2022-01-01',
    },
    '5': {
        uuid: '8a2c3e45-6bde-4f98-81c3-d5f3e4d6890a',
        label: '100ml Syringes',
        title: '100ml Syringes',
        status: 'complete',
        workflow: 'open-competition',
        contractor: '123 Company',
        amount: 1000000,
        awarded: '2021-01-01',
        end: '2022-01-01',
    },
    '6': {
        uuid: '9b3d4f57-8dbe-4f29-b2d1-96f5e6f7891b',
        label: '150ml Syringes',
        title: '150ml Syringes',
        status: 'complete',
        workflow: 'open-competition',
        contractor: '456 Company',
        amount: 1000000,
        awarded: '2021-01-01',
        end: '2022-01-01',
    },
    '7': {
        uuid: 'ab4e5f69-9eef-4f39-c3e1-07f6f8g89a1c',
        label: 'Electronic Health Records',
        title: 'Electronic Health Records',
        status: 'complete',
        workflow: 'open-competition',
        contractor: '789 Company',
        amount: 1000000,
        awarded: '2021-01-01',
        end: '2022-01-01',
    },
    '8': {
        uuid: 'bc5f607a-a0ef-503a-d4f2-18f7f9h90b2d',
        label: 'Patient Transport',
        title: 'Patient Transport',
        status: 'inprogress',
        workflow: 'open-competition',
        contractor: 'ABC Company',
        amount: 120000,
        awarded: null,
        end: '2025-01-01',
    },
}

export default current;
