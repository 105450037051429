"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var theme_tools_1 = require("@chakra-ui/theme-tools");
var baseStyleList = function (props) {
    return {
        bg: 'bg-surface',
        overflow: 'hidden',
        boxShadow: (0, theme_tools_1.mode)('md', 'md-dark')(props),
        color: 'default',
        minW: '3xs',
        py: 'unset',
        zIndex: 1,
        borderRadius: 'md',
        borderWidth: '0',
        _focus: {
            boxShadow: "".concat((0, theme_tools_1.mode)("0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)", "0px 0px 1px #0D0D0D, 0px 4px 8px rgba(13, 13, 13, 0.9)")(props), " !important"),
        },
    };
};
var baseStyleItem = function (props) {
    return {
        py: 2,
        px: 4,
        border: 'none',
        _focus: {
            bg: 'blue.500',
            color: 'on-accent',
        },
        _active: {
            bg: 'blue.500',
            color: 'on-accent',
            _disabled: {
                color: 'default',
                bg: 'initial',
                cursor: 'not-allowed',
            },
        },
        _expanded: {
            bg: (0, theme_tools_1.mode)('gray.100', 'whiteAlpha.100')(props),
        },
        _disabled: {
            opacity: 0.4,
            cursor: 'not-allowed',
        },
    };
};
var baseStyleGroupTitle = {
    mx: 4,
    my: 2,
    fontWeight: 'semibold',
    fontSize: 'sm',
};
var baseStyleCommand = {
    color: 'muted',
};
var baseStyleDivider = {
    border: 0,
    borderBottom: '1px solid',
    borderBottomColor: 'bg-muted',
    my: 0,
    opacity: 0.6,
};
var baseStyle = function (props) { return ({
    list: baseStyleList(props),
    item: baseStyleItem(props),
    groupTitle: baseStyleGroupTitle,
    command: baseStyleCommand,
    divider: baseStyleDivider,
}); };
exports.default = {
    baseStyle: baseStyle,
};
