
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import {InputGroup, InputRightAddon, Box, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, ButtonGroup } from '@chakra-ui/react';
import { MdOutlineArrowForward, MdCheck, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";
import Loading from '../../../static/layout/Loading';
import LoadingCircle from '../../../static/layout/LoadingCircle';
import API from "../../../core/utils/API";
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

export default function ScheduleQuestions(props) {

    const [formMessage, setFormMessage] = useState("");
    const [fte, setFTE] = useState("");
    const [leave, setLeave] = useState("");
    const [cover, setCover] = useState("");
    const [start, setStart] = useState("");
    const [year, setYear] = useState("");
    const [country, setCountry] = useState("");
    const [london, setLondon] = useState("");
    const [end, setEnd] = useState("");
    const [page, setPage] = useState(0);
    const [data, setData] = useState({});
    const { state } = useLocation();

    let uuid = props.uuid;
    // Get from state if not passed as props
    if (!uuid) {
        // Get uuid passed as a state prop
        uuid = state.uuid;
    }

    console.log("Questions for uuid", uuid);

    const navigate = useNavigate();

    function nextPage() {
        setPage(page + 1);
    }

    function previousPage() {
        setPage(page - 1);
    }


    function updateData(key, value) {

        console.log("Update data", key, value);
        let newData = {...data};
        newData[key] = value;
        setData(newData);
        console.log("New data", data);
        //nextPage();

    }

    let pages = [
        [
            {
                key: "fte",
                question: "Where you full time on this schedule?",
                type: "button",
                answers: [
                    {
                        label: "Yes 100% FTE",
                        value: 1,
                        highlight: props.fte ? props.fte === 1 ? true : false : true,
                    },
                    {
                        label: "90%",
                        value: 0.9,
                        highlight: props.fte ? props.fte === 0.9 ? true : false : false,
                    },
                    {
                        label: "80%",
                        value: 0.8,
                        highlight: props.fte ? props.fte === 0.8 ? true : false : false,
                    },
                    {
                        label: "70%",
                        value: 0.7,
                        highlight: props.fte ? props.fte === 0.7 ? true : false : false,
                    },
                    {
                        label: "60%",
                        value: 0.6,
                        highlight: props.fte ? props.fte === 0.6 ? true : false : false,
                    },
                    {
                        label: "50%",
                        value: 0.5,
                        highlight: props.fte ? props.fte === 0.5 ? true : false : false,
                    }
                ],
            },
            {
                key: "grade",
                question: "What grade were you during this job?",
                type: "button",
                answers: [
                    {
                        label: 'FY1',
                        value: 'FY1',
                    },
                    {
                        label: 'FY2',
                        value: 'FY2',
                    },
                    {
                        label: 'CT1/2',
                        value: 'CT1/2',
                    },
                    {
                        label: 'ST3/4/5',
                        value: 'ST3/4/5',
                    },
                    {
                        label: 'ST6/7/8',
                        value: 'ST6/7/8',
                    },
                ]
            },
            {
                key: "londonWeighting",
                question: "Did you recieve a London weighting?",
                type: "button",
                answers: [
                    {
                        label: 'No',
                        highlight: props.londonWeighting ? props.londonWeighting === 0 ? true : false : true,
                        value: 0,
                    },
                    {
                        label: '£2,162 (Inner)',
                        value: 2162,
                        highlight: props.londonWeighting ? props.londonWeighting === 2162 ? true : false : false,
                    },
                    {
                        label: '£527 (Outer)',
                        value: 527,
                        highlight: props.londonWeighting ? props.londonWeighting === 527 ? true : false : false,
                    },
                    {
                        label: '£149 (Fringe)',
                        value: 149,
                        highlight: props.londonWeighting ? props.londonWeighting === 149 ? true : false : false,
                    }
                ],
            },
        ],
        [
            {
                key: "leave",
                question: "How many days annual leave did you have (for the entire year)?",
                type: "button",
                answers: [
                    {
                        label: '27 (under 5 years service)',
                        value: 27,
                        highlight: props.leave ? props.leave === 27 ? true : false : false,
                    },
                    {
                        label: '32 (over 5 years service)',
                        value: 32,
                        highlight: props.leave ? props.leave === 32 ? true : false : false,
                    },
                ]
            },
            {
                key: "study",
                question: "How many days study leave did you have (for the entire year)?",
                type: "number",
                defaultValue: 30,
            },
            {
                key: "anyShift",
                question: "Could you take leave on any shift?",
                type: "button",
                answers: [
                    {
                        label: "No",
                        value: false,
                        highlight: props.anyShift ? props.anyShift === false ? true : false : true,
                    },
                    {
                        label: "Yes",
                        value: true,
                        highlight: props.anyShift ? props.anyShift === true ? true : false : false,
                    }
                ],
            },
            {
                key: "noCover",
                question: "Did you have to organise cover to take leave on some shifts?",
                type: "button",
                answers: [
                    {
                        label: 'Yes',
                        value: true,
                        highlight: props.cover ? props.cover === true ? true : false : true,
                    },
                    {
                        label: 'No',
                        value: false,
                        highlight: props.cover ? props.cover === false ? false : true : false,
                    }
                ],
            },
        ],
        [
            {
                key: "start",
                question: "What was the start date of the schedule?",
                type: "date",
            },
            {
                key: "duration",
                question: "How long was the placement?",
                type: "button",
                answers: [
                    {
                        label: '4 months',
                        value: '4',
                        highlight: true,
                    },
                    {
                        label: '6 months',
                        value: '6',
                        highlight: true,
                    },
                    {
                        label: '12 months',
                        value: '12',
                        highlight: true,
                    },
                    {
                        label: 'Indefinite',
                        value: '0',
                        highlight: true,
                    },
                ],
            },
            /*
            {
                key: "startDay",
                question: "What day of the week did the schedule start on?",
                type: "button",
                answers: [
                    {
                        label: 'Monday',
                        value: 'Monday',
                        highlight: true,
                    },
                    {
                        label: 'Tuesday',
                        value: 'Tuesday',
                    },
                    {
                        label: 'Wednesday',
                        value: 'Wednesday',
                        highlight: true,
                    },
                    {
                        label: 'Thursday',
                        value: 'Thursday',
                    },
                    {
                        label: 'Friday',
                        value: 'Friday',
                    },
                    {
                        label: 'Saturday',
                        value: 'Saturday',
                    },
                    {
                        label: 'Sunday',
                        value: 'Sunday',
                    },
                ],
            },
            */
        ]
    ];


    console.log("Schedule questions", pages);





    function submitQuestions() {

        console.log("Submit Questions", props.uuid);

        let url = 'schedules';
        let config = {
            params: {
                ...data,
                uuid: props.uuid
            }
        };

        let resp = API.patchItems({url, config})
        .then(response => {
            console.log(response);
            setFormMessage("Details saved successfully");
            
            // redirect to the root page for the schedule
            navigate('/pay/schedule', {state: {uuid: uuid}});
            console.log("Close modal");
            props.onClose();

        })
        .catch(error => {
            console.log(error);
        });

                
    }


    return (

        <>

            {page < pages.length &&

                <>

                {pages[page].map((question, index) => {

                   
                    return (

                    <Box>
                        <Heading fontSize='label' lineHeight='1.4em' mb={2} mt={4}>
                            {question.question}
                        </Heading>
                        {question.type === "button" &&
                            <Wrap pb={4}>
                                {question.answers.map((answer, index) => (
                                    <Button key={index} 
                                    order={data[question.key] === answer.value ? 0 : 1}
                                    variant={data[question.key] === answer.value ? 'solid' : 'outline'} 
                                    colorScheme={(answer.highlight || data[question.key] === answer.value) ? 'blue' : 'gray'} 
                                    size='xs'
                                    onClick={() => {
                                        updateData(question.key, answer.value);

                                    }}
                                    >
                                        {answer.label}
                                    </Button>
                                ))}
                            </Wrap>
                        }
                        {question.type === "date" &&
                            <InputGroup>
                                <Input id={question.key+"-input"} mb={2} type='date' defaultValue={question.defaultValue} 
                                    onChange={() => {
                                        let date = document.getElementById(question.key+"-input").value;
                                        updateData(question.key, date);
                                    }}
                                    />
                            </InputGroup>
                        }
                        {question.type === "number" &&
                            <InputGroup>
                                <Input id={question.key+"-input"} mb={2} type='number' defaultValue={question.defaultValue} />
                            </InputGroup>
                        }


                    </Box>

                )})}

                {page === (pages.length-1) ?
                   <Button mb={2} onClick={() => {submitQuestions();}} colorScheme='blue'>Submit</Button>
                    :
                    <Button mt={4} mb={4} onClick={() => {nextPage();}} colorScheme='blue'>Next</Button>
                }

                </>

            }



            <Text fontSize='xs' color='subtle' mt={4} pb={4}>
                {page !== 6 &&
                    <Text as='span' mr={2}>
                        Page {page+1} of {pages.length}.
                    </Text>
                }
                {page !== 0 &&
                    <Text as='span' cursor='pointer' _hover={{color: 'blue.500'}} color='subtle' onClick={() => {previousPage();}}>
                        Previous Page
                    </Text>
                }
            </Text>

        </>

            

    )
}