import { Icon, Button, Box, Container, Heading, Image, Stack, Text, useColorMode } from '@chakra-ui/react'
import Hero from './Hero.png';
import { MdArrowForward } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import TryNowButton from '../shared/TryNowButton';

export default function Features() {
  const { colorMode } = useColorMode()
  const navigate = useNavigate();
  return (
    <Box as="section">
      <Box pb={12}>
        <Container
          pt={{
            base: '16',
            md: '36',
          }}
          pb={{
            base: '18',
            md: '28',
          }}
          mb={{
            base: '16',
            md: '10',
          }}
        >
          <Stack
            spacing={{
              base: '8',
              md: '10',
            }}
            align="center"
          >
            <Stack
              spacing={{
                base: '4',
                md: '5',
              }}
              textAlign="center"
            >
              <Stack spacing="3" alignItems='center'>
               
                <Heading size='pagetitle' mb="12"> 
                  Making your life easier
                </Heading>
              <Text
                size='pagetitle-description'
              >
                Stop digging out your HR documents.</Text>
                <Text 
                size='pagetitle-description'>Save them once and share them from a secure place.
              </Text>
              <Box mt='2em'>
                <TryNowButton />
              </Box>
              </Stack>
            </Stack>
          </Stack>
        </Container>

      </Box>
    </Box>
  )
}