import React, { useState } from 'react';
import { Flex, Box, Text, Button } from '@chakra-ui/react';

export default function Welcome(props) {

    // Get state
    const [loading, setLoading] = useState(false);

    return (
        <>
        <Flex h='fill-available'>
            <Box
                w='-webkit-fill-available'
                >
                <Text>Welcome to Pyrexia.io</Text>
                <Text>Let's get started by setting up your organisation</Text>
            </Box>
        </Flex>
        </>
    )
}