"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    primary: '#171717',
    secondary: '#DC564C',
    ui: 'blue.500',
    heading: '#171717',
    text: '#3d3d3d',
    black: '#171717',
    white: '#ffffff',
    invert: '#ffffff',
    inverted: '#ffffff',
    undefined: 'inherit',
    inherit: {
        50: 'inherit',
        100: 'inherit',
        200: 'inherit',
        300: 'inherit',
        400: 'inherit',
        500: 'inherit',
        600: 'inherit',
        700: 'inherit',
        800: 'inherit',
        900: 'inherit',
    },
    blackAlpha: {
        50: 'rgba(0, 0, 0, 0.04)',
        100: 'rgba(0, 0, 0, 0.06)',
        200: 'rgba(0, 0, 0, 0.08)',
        300: 'rgba(0, 0, 0, 0.16)',
        400: 'rgba(0, 0, 0, 0.24)',
        500: 'rgba(0, 0, 0, 0.36)',
        600: 'rgba(0, 0, 0, 0.48)',
        700: 'rgba(0, 0, 0, 0.64)',
        800: 'rgba(0, 0, 0, 0.80)',
        900: 'rgba(0, 0, 0, 0.92)',
    },
    whiteAlpha: {
        50: 'rgba(255, 255, 255, 0.04)',
        100: 'rgba(255, 255, 255, 0.06)',
        200: 'rgba(255, 255, 255, 0.08)',
        300: 'rgba(255, 255, 255, 0.16)',
        400: 'rgba(255, 255, 255, 0.24)',
        500: 'rgba(255, 255, 255, 0.36)',
        600: 'rgba(255, 255, 255, 0.48)',
        700: 'rgba(255, 255, 255, 0.64)',
        800: 'rgba(255, 255, 255, 0.80)',
        900: 'rgba(255, 255, 255, 0.92)',
    },
    gray: { // OLD
        50: '#FCFCFC',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#323232',
        950: '#1F1F1F',
    },
    blue: {
        50: '#F2F1FB',
        100: '#DCDAF5',
        200: '#C2C0EF',
        300: '#A19EE9',
        400: '#7F7CE3',
        500: '#3105C2',
        600: '#2A039E',
        700: '#23027A',
        800: '#1B0158',
        900: '#120F35',
        950: '#0B081F',
    },
    mint: { // OLD
        50: '#F9FDFE',
        100: '#F4FAFD',
        200: '#E9F6FA',
        300: '#DDF1F8',
        400: '#D2EDF5',
        500: '#C7E8F3',
        600: '#9FBAC2',
        700: '#778B92',
        800: '#505D61',
        900: '#282E31',
        950: '#171A1C',
    },
    purple: { // blue copied
        50: '#EBF2FA', 
        100: '#D5E5F5', 
        200: '#ADC8E8', 
        300: '#7AAFD1', 
        400: '#3F8EBA', 
        500: '#3066BE', 
        600: '#2A47A2', 
        700: '#203380', 
        800: '#192661', 
        900: '#101941', 
        950: '#0B102B',
    },
    orange: { // OLD ORANGE
        50: '#FFF4E5',
        100: '#FFE4B8',
        200: '#FFD18B',
        300: '#FFBD5D',
        400: '#FFAE3B',
        500: '#FF8C00',
        600: '#FF6F00',
        700: '#FF5200',
        800: '#FF3500',
        900: '#FF1600',
        950: '#E50E00',
    },
    yellow: { // updated
        50: '#FEF7E0',
        100: '#FDE8A8',
        200: '#FCD971',
        300: '#FACD38',
        400: '#F9C703',
        500: '#F7B500',
        600: '#F5A800',
        700: '#F39700',
        800: '#F18600',
        900: '#EF6C00',
        950: '#EE6200',
    },
    red: {// Updated
        50: '#FEEAEA',
        100: '#FDC5C5',
        200: '#FB8F8F',
        300: '#F74A4A',
        400: '#E12C2C',
        500: '#D00000',
        600: '#B70000',
        700: '#8F0000',
        800: '#660000',
        900: '#3D0000',
        950: '#260000',
    },
    green: { // Updated
        50: '#E3F9F1',
        100: '#C5F2E8',
        200: '#9DE6D9',
        300: '#6FCDBE',
        400: '#49BFA5',
        500: '#007F5F',
        600: '#00614B',
        700: '#004D3D',
        800: '#003A2F',
        900: '#00251C',
        950: '#001A12',
    },
};
