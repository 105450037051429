"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = function () {
    return {
        overlay: {
            bg: 'rgba(0, 0, 0, 0.5)',
            p: 1,
            py: 2,
        },
        dialog: {
            color: 'default',
            _focus: {
                boxShadow: '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1) !important',
            },
        },
        dialogContainer: {
            color: 'default',
        },
        header: {
            pb: 4,
            pt: 8,
            lineHeight: '8',
            fontWeight: 'bold',
            fontSize: '4xl',
        },
        body: {
            color: 'subtle',
            fontSize: 'sm',
            lineHeight: '1.5',
            pt: 2,
            pb: 0,
        },
        footer: {
            py: 6,
        },
        closeButton: {
            color: 'accent',
        },
    };
};
exports.default = {
    baseStyle: baseStyle,
};
