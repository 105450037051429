import React, { useState } from 'react';

import API from '../../core/utils/API';

import Moment from 'react-moment';
import 'moment-timezone';

import { Tabs, Tab, TabPanel, TabPanels, TabList, ButtonGroup, IconButton, HStack, Tooltip, Spinner, Table, Icon, Thead, Th, Tr, Td, Tbody, Heading, VStack, Checkbox, WrapItem, useDisclosure, Collapse,  Divider, SkeletonText, Grid, GridItem, Flex, Button, List, ListItem, Badge, Wrap, Box, Stack, Text } from '@chakra-ui/react';
import { MdLabel, MdOutlineLabel, MdCheck, MdClose, MdAdd, MdAttachment, MdOutlineWarning, MdExpandLess, MdExpandMore, MdCheckCircle, MdHelp} from "react-icons/md";
import axios from 'axios';
import FullPageModal from '../../core/components/modal/FullPageModal';
import { Document, Page, pdfjs } from 'react-pdf';

//import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";  
// TODO - change over to local file 
//pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export default function ShowEvidence(props) {



    const [pdfBlob, setPdfBlob] = useState(null);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [errorPDF, setErrorPDF] = useState(null);
    const [numPages, setNumPages] = useState(null);

    React.useEffect(()=>{
        async function getPDF(){
            try {

                setLoadingPDF(true);

                console.log("filePath", props.filePath);
                //console.log('Fetching PDF data from endpoint: ', url);

                // Call the endpoint to retrieve the pre-signed S3 URL
                const url = 'uploads/pdf/' + props.evidenceUuid;
                let responseURL = await API.getItems({url});
                responseURL = responseURL.data.url;
                console.log("PDF URL Response:", responseURL);

                // Call the endpoint to retrieve the PDF data
                //let configPDF = {
                //    responseType: 'stream'//'blob'
                //}
                //console.log("PDF config", configPDF);
                
                let axiosConfig = {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/pdf',
                        'Access-Control-Allow-Origin': '*',
                    }
                }
                console.log("axiosConfig", axiosConfig);
                let response = await axios.get(responseURL, axiosConfig).catch( e => {
                    console.log('file download ERROR:', e)
                    console.log(' e.response', e.response)
                  });
                // TODO - chang over to API
          /*      let response = await axios({
                    method: 'get',
                    url: responseURL,
                    responseType: 'blob'
                    });*/
 //              const response = await API.getItemsRaw({url: responseURL.data.url , config: configPDF});
                console.log("PDF Response:", response);
                console.log("PDF Response Data:", response.data);
                /*
                const url = MYAPI + 'uploads/pdf/' + props.evidenceUuid;
                //console.log('Fetching PDF data from endpoint: ', url);

                // Call the endpoint to retrieve the PDF data
                const response = await axios.get(url, {
                    responseType: 'blob'
                });
*/
     //           console.log("PDF Response:", response);
                
                // Display the PDF in the browser
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                //console.log('pdfBlob: ', pdfBlob);

                setPdfBlob(pdfBlob);
                
                setLoadingPDF(false);



            } catch (error) {
                console.log("Error:", error);
                setErrorPDF(error);
                setLoadingPDF(false);
            }
        }
        getPDF()
    }, [])

    const onLoadSuccess = ({ numPages }) => {
        //console.log(`Loaded ${numPages} pages of the PDF`);
        setNumPages(numPages);
        for (let i = 1; i <= numPages; i++) {
            setPages(pages => [...pages, i]);
        }
        console.log("Pages", pages);
      };
    
      const onLoadError = (error) => {
        console.error('Error loading the PDF:', error);
      };

    //const [filters, setFilters] = React.useState('');
    const [records, setRecords] = React.useState([]);
    const [evidence, setEvidence] = React.useState([]);
    const [requirements, setRequirements] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState([]);
    const [analysis, setAnalysis] = React.useState();

    const url = 'evidences/show';


    async function getData(){
        try {

            setLoading(true);
            
            let configData = {
                params: {
                    requirementGroupUuid: props.requirementGroupUuid,
                    evidenceUuid: props.evidenceUuid
                }
            };

            const response = await API.getItems({url, config: configData});

            //console.log(response);
            const a = (response.data);
            const ev = a.evidence;
            console.log("Evidence", ev);
            const rec = Array.from(ev.Records);
            const req = Array.from(a.requirement);

            console.log("Just JSON", JSON.parse(ev.rawTextJSON));
            console.log("Double JSON", JSON.parse(JSON.parse(ev.rawTextJSON)));

            setEvidence(ev);
            setRequirements(req);
            setRecords(rec);
            //setAnalysis(JSON.parse(JSON.parse(ev.rawTextJSON)));

            setLoading(false);


        } catch (error) {
            console.log("Tried and failed to set data");
            setError(error);
            setLoading(false);
        }
    }

    React.useEffect(()=>{
        
        getData()
    }, [])


    function closeMe() {
        setShow(false); 
        props.onClose();
    }

    function updatePage(e, change) {

        console.log("PAGE CHANGE", page, e, change);
        let tempPage = page + e;

        console.log(tempPage);
        if (tempPage < 1 ) {
            tempPage = 1;
        }
        if (tempPage > numPages) {
            tempPage = numPages;
        }

        setPage(tempPage);

    }

    function addRecord(requirementUuid, evidenceUuid) {

        const data = {
            requirementUuid: requirementUuid,
            creatorUuid: 'this_user',
            evidenceUuid: evidenceUuid,
            page: page
        };

        let response = API.postItems({url: 'records/add', data})
        .then(response => {
            // Do something with the response
            console.log("success");
            getData();

        })
        .catch(error => {
            // Handle the error if something went wrong
            console.log("failed");
        });


    }


    function approveRecord(uuid) {

        const data = {
            recordUuid: uuid,
        };

        let response = API.postItems({url: 'records/approve', data})
        .then(response => {
            // Do something with the response
            console.log("success");
            getData();

        })
        .catch(error => {
            // Handle the error if something went wrong
            console.log("failed");
        });

    }

    function rejectRecord(uuid) {

        console.log("Rejecting record");
        const data = {
            recordUuid: uuid,
        };

        API.postItems({url: 'records/reject', data})
        .then(response => {
            // Do something with the response
            console.log("success");
            getData();
        })
        .catch(error => {
            // Handle the error if something went wrong
            console.log("failed");
        });

    }

    

    return (

        <>
        <Button variant='ghost' size='sm' onClick={() => {setShow(!show)}}>
            {props.buttonText ?
                props.buttonText
                :
                'View'
            }
        </Button>

        {show &&

                <FullPageModal >

                    <Flex>

                        {loadingPDF && 
                            <Box w='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'
                                    />
                            </Box>
                        }
                        {errorPDF && 
                            <Box w='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
                                <Box>
                                    <Heading size='md'>Error</Heading>
                                    <Text>{errorPDF.message}</Text>
                                </Box>
                            </Box>
                        }
                        {pdfBlob && 

                            <Box>
                                <Document file={pdfBlob}
                                    onLoadSuccess={onLoadSuccess}
                                    onLoadError={onLoadError}
                                    className='pdf'
                                    >
                                    <Page pageNumber={page} />

                                    
                                        {(numPages > 1) &&
                                            <Flex position='fixed' bottom='20px' border='1px solid grey' overflow='hidden' bg="white">
                                                {page != 1 ?
                                                    <Button size='xs' onClick={() => updatePage(-1)}>Previous</Button>
                                                    :
                                                    <Button size='xs' disabled>Previous</Button>
                                                }
                                                <Text fontSize='xs' p={1} pl={2} pr={2}>Page {page} of {numPages}</Text>
                                                {page != numPages ?
                                                    <Button size='xs' onClick={() => updatePage(1)}>Next</Button>
                                                    :
                                                    <Button size='xs' disabled>Next</Button>
                                                }
                                            </Flex>
                                        }
                                </Document>
                            </Box>
                        }

                        <Box overflowY='scroll' width='-webkit-fill-available'>

                            <Button size='sm' colorScheme='red' position='absolute' right={4} top={4} onClick={() => {closeMe()}}><MdClose /></Button>
    
                            <Box pl={8} pr={8} pt={6} pb={6}>
                                <Heading as='h6' fontSize='xl' lineHeight='1em' mb={2}>{evidence.title}</Heading>
                                <Text mb={2} color='muted'>Uploaded by user on <Moment format='DD/MM/YYYY'>{evidence.createdAt}</Moment></Text>
                                <Text mb={2} color='muted'>{evidence.description}</Text>
                            </Box>

                            <Tabs>
                                <TabList>
                                    <Tab>Tags</Tab>
                                    <Tab>Page Text</Tab>
                                    <Tab>All Text</Tab>
                                    <Tab>All JSON</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel p={0}>

                                        <Table borderTop='1px solid #E2E8F0' pt={1}>

                                            {requirements.map((requirement) => (

                                                <Tr className='opacity-hover' order={requirement.Records.length}>

                                                <Th fontSize='sm'  h='auto' pt={1}>{requirement.title}</Th>


                                                <Td pt={1} >

                                                    <Wrap is-hover="hover">

                                                        {requirement.Records.map((record) => (
                                                            <>

                                                            {(record.evidenceUuid == props.evidenceUuid) &&
                                                                <ButtonGroup size='xs' isAttached variant='outline'>
                                                                    <Button onClick={() => setPage(record['page'])}><MdLabel mr={2}/><Text fontWeight='600' pl={1}>{"  Page " + record['page']}</Text></Button>
                                                                    {(record['approved'] != true) &&
                                                                        <IconButton aria-label='Approve' onClick={() => approveRecord(record.uuid)} icon={<MdCheck />} />
                                                                    }
                                                                    <IconButton aria-label='Approve' onClick={() => rejectRecord(record.uuid)} icon={<MdClose />} />
                                                                </ButtonGroup>
                                                            }


                                                            {(record.evidenceUuid != props.evidenceUuid) &&
                                                                
                                                                <ButtonGroup variant='outline' size='xs' className='tagged-elsewhere' order='5'>
                                                                    <Button><MdOutlineLabel/><Text pl={1}>Other file</Text></Button>
                                                                </ButtonGroup>
                                                            }       

                                                            </>

                                                        ))}

                                                        {requirement.Records.length == 0 &&
                                                            <Badge size='xs' colorScheme='red'>
                                                                Not Evidenced
                                                            </Badge>
                                                        }
                                                        
                                                        <ButtonGroup variant='outline' size='xs' order='6'>
                                                            <IconButton onClick={() => addRecord(requirement.uuid, evidence.uuid)} className='opacity-on-wrapper-hover' aria-label='Approve'  icon={<MdAdd />} />
                                                        </ButtonGroup>


                                                    </Wrap>

                                                </Td>

                                                </Tr>

                                            ))}

                                        </Table>

                                    </TabPanel>
                                    <TabPanel>
                                        <Text>
                                            This is the automatically found text used for tagging. If you think this is incorrect, please contact the administrator.
                                        </Text>
                                        <Divider mt={2} mb={2}/>
                                        {analysis != undefined &&
                                            <>
                                                {analysis[page-1] != undefined &&
                                                    <>
                                                    {analysis[page-1]['img']}
                                                    </>
                                                }
                                            </>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        <Text>
                                            This is the automatically found text used for tagging. If you think this is incorrect, please contact the administrator.
                                        </Text>
                                        <Divider mt={2} mb={2}/>
                                        {evidence.rawText}
                                    </TabPanel>
                                    <TabPanel>
                                        <Text>
                                            This is the JSON data used for tagging. If you think this is incorrect, please contact the administrator.
                                        </Text>
                                        <Divider mt={2} mb={2}/>
                                        <Box>
                                            {evidence.rawTextJSON}
                                        </Box>

                                    </TabPanel>

                                </TabPanels>
                            </Tabs>
                            
                            


                        </Box>

                    </Flex>

                </FullPageModal>

        }
        </>
           
    )
}
