
import React, { useState } from 'react';

import Moment from 'react-moment';
import CustomListItem from '../core/models/CustomListItem';
import AccessCard  from './AccessCard';
import { InputGroup, InputRightElement, IconButton, Input, Tag, TagLabel, Center, HStack, ButtonGroup, Tab, TabList, Tabs, TabPanel, TabPanels, Table, Icon, Thead, Th, Tr, Td, Tbody, Heading, VStack, Checkbox, WrapItem, useDisclosure, Collapse,  Divider, SkeletonText, Grid, GridItem, Flex, Button, List, ListItem, Badge, Wrap, Box, Stack, Text } from '@chakra-ui/react';
import { MdCheck, MdClose, MdAdd, MdAttachment, MdOutlineWarning, MdExpandLess, MdExpandMore, MdCheckCircle, MdHelp, MdSearch} from "react-icons/md";
import FullPageModal from '../core/components/modal/FullPageModal';
import { useLocation } from 'react-router-dom';
import {Link as ReachLink} from "react-router-dom";
import API from '../core/utils/API';
import Modal from '../core/components/modal/Modal';
import { useAuth0 } from "@auth0/auth0-react";

export default function AccessList(props) {

    
    const location = useLocation();

    let requirementGroupUuid = props.requirementGroupUuid;
    let requirementGroupTitle = props.requirementGroupTitle;
    let userUuid = props.userUuid;
    
    let addendum = '';
    if (requirementGroupTitle != null) {
        addendum = requirementGroupTitle;
    }

    const [showModal, setShowModal] = React.useState(false);
    const [access, setAccess] = React.useState([]);  
    const [search, setSearch] = React.useState('');
    const [showSearch, setShowSearch] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [activeOnly, setActiveOnly] = React.useState(true);

    React.useEffect(()=>{
        async function getData(){
            try {
 
                setLoading(true);
                
                let url = 'access/list/';
                let config = {
                    params: {
                        requirementGroupUuid: requirementGroupUuid,
                        userUuid: userUuid,
                    }
                }
                
                const response = await API.getItems({url, config});
              
                const data = (response.data);
                //console.log(data['accesses']);
                const accessPrep = Array.from(data['accesses']);
                setAccess(accessPrep);

                setLoading(false);


            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }
        getData()
    }, [showModal])


    return (

        <>

            <Box cursor='pointer' onClick={() => setShowModal(true)}
                  pt={{base: 0, md: '24px'}}
                  pb={{base: 2, md: 0}}
                  >

                    {props.accessRequests.length != 0 &&
                        <Tag size='sm' borderRadius='full' pr={2} colorScheme='red'>
                          <TagLabel>
                            {props.accessRequests.length + " requests for access"}
                          </TagLabel>
                        </Tag>
                      }
                      {props.accessCurrents != 0 && props.accessRequests.length == 0 &&
                        <Tag size='sm' mr={2} variant='subtle' borderRadius='full' >
                          <TagLabel>{props.accessCurrents.length} viewers</TagLabel>
                        </Tag>
                      }
                      {props.accessInvites.length != 0 && props.accessCurrents.length == 0 && props.accessRequests.length == 0 &&
                        <Tag size='sm' mr={2} variant='subtle' borderRadius='full' >
                          <TagLabel>{props.accessInvites.length} invites</TagLabel>
                        </Tag>
                      }

                      {props.accessCurrents.length == 0 && props.accessRequests.length == 0 && props.accessInvites.length == 0 &&
                        <Tag size='sm' mr={2} variant='subtle' borderRadius='full' >
                          <TagLabel>No-one has access</TagLabel>
                        </Tag>
                      }
            </Box>


                      {showModal &&
            <Modal
                MFA={true} 
                maxW='600px'
                title={ 'Access Requests for:'}
                titleSecondLine={addendum}
                bodyPadding={0}
                onClose={() => {
                    props.refresh();

                    setShowModal(false);
                }}
                footer={
                  <>
                    { showSearch ?
                      <InputGroup size='sm' maxW='200px' >
                        <Input id='search-input' placeholder='Search' border='2px gray solid !important' borderRadius='24px' onChange={(e) => setSearch(e.target.value)}/>
                        <InputRightElement>
                            <Icon as={MdClose} cursor='pointer' _hover={{color: 'red.500'}} onClick={(e) => {setSearch(''); setShowSearch(false);}}/>
                        </InputRightElement>
                      </InputGroup>
                      :
                      <IconButton size='sm' variant='ghost' onClick={() =>{setShowSearch(true)}} icon={<MdSearch/>} />
                    }
                    <Button size='sm' variant='ghost' onClick={() =>{setActiveOnly(!activeOnly)}}>{activeOnly ? 'Show all' : 'Show active only'}</Button>
                  </>
                }
                >
                <Box p={0} mt={0} m='auto'>

                    {loading && <SkeletonText mt="4" noOfLines={4} spacing="4" />}

                    {error && <Text color='red'>Error loading access records. {JSON.stringify(error)}</Text>}

                    {!loading && !error && access.length === 0 && <Text color='muted' p={4} pl={8} pr={8}>No access records found.</Text>}

                    {!loading && !error && access.length > 0 &&

                        <Box borderTop='1px solid whitesmoke'>
                            {access.map((access, index) => (
                                <AccessCard access={access} activeOnly={activeOnly} search={search} border='true'/>
                            ))}

                            {access.length === 0 && <Text color='muted' p={4}>You have not shared this record yet, and no one has requested access.</Text>}
                        </Box>
                    }

                </Box>
            </Modal>
            }
        </>
    )
}
