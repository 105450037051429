import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Image, Menu, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, MenuOptionGroup } from '@chakra-ui/react';
import API from "../../core/utils/API";
import {MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos, MdList } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import Modal from '../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import ClientLink from './ClientLink';

import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import e from 'cors';

import FileView from '../../core/components/files/View';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { set } from 'date-fns';

import AccountsUpload from './AccountsUpload';

import Account from './Account';
import { load } from 'webfontloader';

function InvoiceList(props) {


    return (

        <Modal 
            title={props.title}
            onClose={props.onClose}
            bodyPadding={0}
            maxW='unset'
            >

            <Table>
                <Tbody>
                    <Tr borderBottom='1px solid black'>
                        <Td color='strong'>Item</Td>
                        <Td color='strong'>Quantity</Td>
                        <Td color='strong'>Total<br/>Cost</Td>
                    </Tr>
                    {props.items.map((item) => {
                        return (
                            <Tr>
                                <Td>{item.Item_Description}</Td>
                                <Td>{item.InvoicedQuantity}</Td>
                                <Td>£{item.LineExtensionAmount}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

            {props.data && 
                <Text>
                    {JSON.stringify(props.data)}
                </Text>
            }

        </Modal>

    )

}



function Card(props) {
    return (
        <GridItem colSpan={{base: 0, md: 2}} borderRadius='md' boxShadow='md' p={4} mb={4} bg='white'>
            {props.children}
        </GridItem>
    )
}

function SyncSettings(props) {

    const toast = useToast();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [secrets, setSecrets] = useState({});

    function updateSecrets(key, v) {

 

    }

    function submit() {

        setError(null);

        let options = ['tradeshift-token', 'tradeshift-secret', 'xero-client-id', 'xero-client-secret', 'etz-username', 'etz-password'];


        if (secrets === null) {
            setError(new Error('No secrets have been set.'));
            return;
        }

        setLoading(true);
        // Submit the form

        let url = 'organisations/secrets';
        let config = {
            params: {
                'secrets': [],
            }
        };

        options.forEach((option) => {

            let inp = document.getElementById(option);
            if (!inp) {
                return;
            }

            let v = inp.value;

            if (v) {
                config.params.secrets.push({
                    key: option,
                    value: v
                });
            }

        });

        let resp = API.postItems({url, data: config.params, config})
        .then(response => {
            setLoading(false);
            toast({
                title: 'Success',
                description: 'Settings have been saved.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            props.onClose();
        })
        .catch(error => {

            setError(error);
            setLoading(false);

            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

        });

    }

    return (
        <Modal 
            title='Sync Settings'
            onClose={props.onClose}
            actionContent={
                <Button colorScheme='blue' variant='solid' size='sm' onClick={submit} isLoading={loading}>
                    Save
                </Button>
            }
            >
                <Stack>

                    <Text mb={4}>
                        These are the settings for syncing invoices and expenses from various sources. Values cannot be viewed for security reasons, but can be updated from here.
                    </Text>

                    {error && 
                        <Alert status='error'>
                            <AlertTitle mr={2}>Error</AlertTitle>
                            <AlertDescription>{error.message}</AlertDescription>
                            <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                        </Alert>
                    }

                    <Stack>
                        <Heading size='label'>Tradeshift</Heading>
                        <InputWrapper 
                            id='tradeshift-token'
                            label='Token'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('tradeshift-token', value)}}
                            />

                        <InputWrapper
                            id='tradeshift-secret'
                            label='Token Secret'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('tradeshift-secret', value)}}
                            />
                    </Stack>


                    <Stack>
                        <Heading size='label'>Xero</Heading>
                        <InputWrapper 
                            id='xero-client-id'
                            label='Client ID'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('xero-client-id', value)}}
                            />

                        <InputWrapper
                            id='xero-client-secret'
                            label='Client Secret'
                            type='password'
                            autocomplete='off'
                            onChange={(value) => {updateSecrets('xero-client-secret', value)}}
                            />
                    </Stack>

                    <Stack>
                        <Heading size='label'>ETZ</Heading>
                        <InputWrapper 
                            id='etz-username'
                            label='Username'
                            type='password'
                            autocomplete='off'
                            setValue={(value) => {updateSecrets('etz-username', value)}}
                            />
                        <InputWrapper
                            id='etz-password'
                            label='Password'
                            type='password'
                            autocomplete='off'
                            setValue={(value) => {updateSecrets('etz-password', value)}}
                            />
                    </Stack>

                </Stack>
        </Modal>
    )
}

export default function Accounts(props) {


    const [employee, setEmployee] = useState({
        '1234': {
            name: 'Oliver Trampleasure',
            email: 'oliver@pyrexia.io'
        },
        '1235': {
            name: 'Ali Jawad',
            email: 'ali@pyrexia.io'
        },
        '1236': {
            name: 'Jordan Lipscombe',
            email: 'jlipscombe@clivehenrygroup.com'
        }
    });

    const [sources, setSources] = useState({
        'd5f055ee-6dbc-4a72-a2af-c49b7824a808': {
            name: 'Paddington NHS Trust',
            logo: '/images/partners/02b827c1-b12d-468f-b8ef-d180c6d1109c.png'
        },
        '123457': {
            name: 'Clive Henry Group',
            logo: '/images/partners/9fdc5918-1a20-499c-a66a-a70931362e07.png'
        },
        '123458': {
            name: 'Pyrexia Ltd',
            logo: '/images/partners/9fdc5918-1a20-499c-a66a-a70931362e07.png'
        }

    });

    let logos = [
        '41a4804f-cd90-470f-bc6f-f631c9f304f6',
        '02b827c1-b12d-468f-b8ef-d180c6d1109c',
        '9fdc5918-1a20-499c-a66a-a70931362e07',
    ]


    const [accounts, setAccounts] = useState([]);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSettings, setShowSettings] = useState(false);
    const [showFile, setShowFile] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [invoiceModal, setInvoiceModal] = useState(null);
    const [upload, setUpload] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(50);
    const [clientLinkDetails, setClientLinkDetails] = useState(null);
    const [filterLines, setFilterLines] = useState('None');
    const [filterInvoiceLinked, setFilterInvoiceLinked] = useState('None');
    const [filterClientLinked, setFilterClientLinked] = useState('None');
    const [filterTradeshiftLinked, setFilterTradeshiftLinked] = useState('None');
    const [filterFrom, setFilterFrom] = useState(null);
    const [filterTo, setFilterTo] = useState(null);
    const [filterOrder, setFilterOrder] = useState('DESC');
    const [filterOrderBy, setFilterOrderBy] = useState('updatedAt');
    const [subtitle, setSubtitle] = useState('');
    const [showSyncWarning, setShowSyncWarning] = useState(false);
    const [multipleSelect, setMultipleSelect] = useState(false);
    const [selected, setSelected] = useState([]);
    const [bulkActioning, setBulkActioning] = useState(false);
    const [updateFilterDate, setUpdateFilterDate] = useState(null);
    const [updateFilterClients, setUpdateFilterClients] = useState(null);
    const [filterClients, setFilterClients] = useState([]);

    let toast = useToast();
    let navigate = useNavigate();


    function currency(amount, currencySymbol = '£') {

        if (amount === undefined || amount === null) {
            return currencySymbol + '0.00 (undefined)';
        }

        // Make sure there are 2 decimal places
        amount = amount.toFixed(2);

        // Add commas to the amount
        amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return currencySymbol + amount;

    }

    function getAccounts(notify) {

        //setLoading(true);
        setError(null);

        let uuid = secureLocalStorage.getItem('organisationUuid');
        let url = 'organisations/accounts';
        let config = {
            params: {
                page,
                limit,
                filter: {
                    lines: filterLines,
                    client: filterClientLinked,
                    invoicePDF: filterInvoiceLinked,
                    tradeshift: filterTradeshiftLinked,
                    from: filterFrom,
                    to: filterTo,
                    order: filterOrder,
                    orderBy: filterOrderBy,
                    clients: filterClients
                }
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            let toastNotification = true;
            if (accounts && accounts.length === 0) {
                toastNotification = false;
            }
            // If any accounts with lines null or {} then show warning
            let awaitingAnalysis = response.data.accounts.find((account) => {
                return account.lines === null || account.lines === {};
            });
            if (awaitingAnalysis) {
                setShowSyncWarning(true);
            }

            // Loop through all accounts and get clients
            // we want uuid: name

            setClients(response.data.clients);

            console.log("Accounts", response.data.accounts);
            setAccounts([...response.data.accounts]);

            if (toastNotification && notify) {
                toast({
                    title: 'Accounts Updated',
                    description: 'Accounts have been successfully refreshed.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setError(error);
            setLoading(false);
        });
        

    }

    function syncInvoices(source = 'xero', from = null, to = null) {

        setSubmitting(true);
        
        let uuid = secureLocalStorage.getItem('organisationUuid');

        let url = 'organisations/accounts/sync';
        let params = {
            source: source,
        }

        // set param.from to 8 days ago
        if (from === null) {
            from = new Date();
            from.setDate(from.getDate() - 8);
        }
            
        params.from = from.toISOString();

        if (to === null) {
            to = new Date();
        }
        
        params.to = to.toISOString();

        let config = {
            params
        };

        let resp = API.getItems({timeout: 60, url, config})
        .then(response => {

            let { message} = response.data;
            
            toast({
                title: 'Success',
                description: message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });


            setSubmitting(false);
            getAccounts();

        })
        .catch(error => {
            console.log(error);
            setError(error);
            setSubmitting(false);
        });

    }

    useEffect(() => {
       getAccounts(false);
    }, []);

    useEffect(() => {
        getAccounts(false);
    }, [page, limit, filterLines, filterClientLinked, filterInvoiceLinked, filterTradeshiftLinked, filterFrom, filterTo, filterOrder, filterOrderBy, filterClients]);



    return (

        <Layout 
            layout='dense'
            title='Accounts'
            description='This is a summary of the various invoices and expenses for an organisation.'
            actionContent={ 
                <ButtonGroup>
                    <IconButton icon={<MdList />} colorScheme='blue' 
                        variant={multipleSelect ? 'solid' : 'outline'}
                        onClick={() => {setMultipleSelect(!multipleSelect)}} />
                    <IconButton icon={<MdUpload />} colorScheme='blue' variant='outline' onClick={() => {setUpload(!upload)}} />
                
{//                    <IconButton icon={<MdSettings />} colorScheme='blue' variant='outline' onClick={() => {setShowSettings(!showSettings)}} />
}
                    <IconButton icon={<MdOutlineRefresh />} colorScheme='blue' variant='outline' onClick={() => {getAccounts(true)}} />
                    <Button display='none' colorScheme='red' variant='solid' onClick={() => {syncInvoices('xero')}}
                        >
                        Xero Sync
                    </Button>
                    <ButtonGroup isAttached
                        colorScheme='blue'
                        variant='outline'
                        >
                        <Button
                            onClick={() => {
                                syncInvoices('xero', moment().subtract(1, 'week'), new Date());
                            }}
                            isLoading={submitting}
                            >
                            Sync Invoices
                        </Button>
                        <Menu>
                            <MenuButton as={Button}
                            disabled={submitting}
                            pt='3px'
                            pl='0px' 
                            pr='5px'
                            >
                                <Icon as={MdExpandMore} />
                            </MenuButton>
                            <MenuList>
                                <MenuItem
                                    onClick={() => {
                                        syncInvoices('xero', moment().subtract(1, 'hour'), new Date());
                                    }}
                                    >
                                    Last 1 Hour
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        syncInvoices('xero', moment().subtract(24, 'hour'), new Date());
                                    }}
                                    >
                                    Last 24 Hours
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        syncInvoices('xero', moment().subtract(2, 'day'), new Date());
                                    }}
                                    >
                                    Last 2 Days
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        syncInvoices('xero', moment().subtract(1, 'week'), new Date());
                                    }}
                                    >
                                    Last Week
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        syncInvoices('xero', moment().subtract(2, 'week'), new Date());
                                    }}
                                    >
                                    Last Fortnight
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>{
                                        syncInvoices('xero', moment().subtract(100, 'year').startOf('month'), new Date());
                                    }}
                                    >
                                    All Invoices
                                </MenuItem>
                            </MenuList>

                        </Menu>
                    </ButtonGroup>
                </ButtonGroup>
            }
            >




            <GridItem colSpan={12}>

                {updateFilterDate && 
                    <Modal title='Filter by Issued Date' onClose={() => {setUpdateFilterDate(null)}}
                        >
                        <Stack>
                            <Text>From:</Text>
                            <Input id='from' label='From' type='date' 
                                onChange={(event) => {
                                    setFilterFrom(event.target.value);
                                }}
                                />
                            <Text>To:</Text>
                            <Input id='to' label='To' type='date' 
                                onChange={(event) => {
                                    setFilterTo(event.target.value);
                                }}
                                />
                        </Stack>
                    </Modal>
                }

                {updateFilterClients &&
                
                    <Modal title='Filter Clients' onClose={() => {setUpdateFilterClients(null)}}
                        key={'update-filter-clients-' + updateFilterClients}
                        >

                        <Text pb={4}>
                            Select clients to filter by.
                        </Text>

                        <Stack spacing={1}>
                            
                            {clients && Object.keys(clients).map((uuid) => {

                                return (
                                    <Checkbox key={uuid} value={uuid} 
                                        isChecked={filterClients.includes(uuid)}
                                        onChange={(event) => {
                                        let tmpFilterClients = [...filterClients];
                                        let index = tmpFilterClients.indexOf(event.target.value);
                                        if (index === -1) {
                                            tmpFilterClients.push(event.target.value);
                                        } else {
                                            tmpFilterClients.splice(index, 1);
                                        }
                                        setFilterClients(tmpFilterClients);
                                    }}>
                                        {clients[uuid]}
                                    </Checkbox>
                                )
                            })}
                        </Stack>

                    </Modal>

                
                }

                {upload && <AccountsUpload onClose={() => {
                    setUpload(false);
                    }} 
                    refresh={() => {getAccounts()}} />}

                {showSettings && <SyncSettings onClose={() => {setShowSettings(false)}} />}
                
                {loading && <Loading />}

                {error && <Alert status='error'>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }


                {// Check if any accounts lack a output value
                    showSyncWarning &&
                    <Alert status='info' marginBottom='24px' id='awaiting-analysis-accounts'>
                        <AlertIcon />
                        <AlertTitle mr={2}>Awaiting Details</AlertTitle>
                        <AlertDescription>We are gathering extra details for some invoices, please refresh the page soon to check on progress.</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => {
                            // Destory the alert
                            setShowSyncWarning(false);
                        }} />
                    </Alert>
                }

                {!loading && accounts &&
              
                    <Box>

                        <Header c={9}
                            buttons="hide"
                            >
                            <Cell c={2} pl='48px'>
                                <Stack>
                                    <Text>Source</Text>
                                    <Wrap>
                                        <ButtonGroup isAttached size='xs' variant='ghost'>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<MdExpandMore />} variant='ghost' size='xs'>
                                                    {limit} per page
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem onClick={() => {setLimit(10); setPage(1);}}>10 per page</MenuItem>
                                                    <MenuItem onClick={() => {setLimit(25); setPage(1);}}>25 per page</MenuItem>
                                                    <MenuItem onClick={() => {setLimit(50); setPage(1);}}>50 per page</MenuItem>
                                                    <MenuItem onClick={() => {setLimit(100); setPage(1);}}>100 per page</MenuItem>
                                                    <MenuItem onClick={() => {setLimit(250); setPage(1);}}>250 per page</MenuItem>
                                                    <MenuItem onClick={() => {setLimit(500); setPage(1);}}>500 per page</MenuItem>
                                                </MenuList>
                                            </Menu>
                                            <IconButton icon={<MdArrowBackIos />} onClick={() => {setPage(page - 1)}} disabled={page === 1} />
                                            <Button>
                                                Page {page}
                                            </Button>
                                            <IconButton icon={<MdArrowForwardIos />} onClick={() => {setPage(page + 1)}} disabled={accounts.length < limit} />
                                        </ButtonGroup>
                                        <Menu>
                                            <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                                colorScheme={filterLines === 'None' ? 'default' : 'blue'}
                                                variant={filterLines === 'None' ? 'ghost' : 'outline'}
                                                >
                                                {filterLines === 'None' ? '' : filterLines}
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => {setFilterLines('None')}}>None</MenuItem>
                                                <MenuItem onClick={() => {setFilterLines('Complete')}}>Complete</MenuItem>
                                                <MenuItem onClick={() => {setFilterLines('Missing')}}>Missing</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Wrap>
                                </Stack>
                            </Cell>
                            <Cell>
                                <Text>Dates</Text>
                                <Menu>
                                    <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                        colorScheme={filterFrom === null ? 'default' : 'blue'}
                                        variant={filterFrom === null ? 'ghost' : 'outline'}
                                        >
                                            {filterOrderBy === 'due' ? 'Sorting' : ''}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuOptionGroup defaultValue='asc' title='Order' type='radio'>
                                            <MenuItem onClick={() => {setFilterOrder('asc'); setFilterOrderBy('due');}}>Ascending</MenuItem>
                                            <MenuItem onClick={() => {setFilterOrder('desc'); setFilterOrderBy('due');}}>Descending</MenuItem>
                                        </MenuOptionGroup>
                                        <MenuItem
                                            onClick={() => {
                                                setUpdateFilterDate(true);
                                            }}
                                            >
                                            Filter Issue Dates
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                                <Stack spacing={0} pt={2}>
                                    <Text key={'from-date-' + filterFrom}
                                        fontSize='xs' color='subtle'
                                        >{filterFrom === null ? '' : 'From ' + moment(filterFrom).format('DD/MM/YYYY')}</Text>
                                    <Text key={'to-date-' + filterTo}
                                        fontSize='xs' color='subtle'
                                        >{filterTo === null ? '' : 'To ' + moment(filterTo).format('DD/MM/YYYY')}</Text>
                                </Stack>
                            </Cell>
                            <Cell c={2}>
                                <Stack>
                                    <Text>Client</Text>
                                    <Menu>
                                        <MenuButton w='fit-content'  as={Button} leftIcon={<MdFilterList />} size='xs'
                                            colorScheme={filterClientLinked === 'None' ? 'default' : 'blue'}
                                            variant={filterClientLinked === 'None' ? 'ghost' : 'outline'} 
                                            >
                                            {filterClientLinked === 'None' ? '' : filterClientLinked}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => {setFilterClientLinked('None')}}>None</MenuItem>
                                            <MenuItem onClick={() => {setFilterClientLinked('Linked')}}>Linked</MenuItem>
                                            <MenuItem onClick={() => {setFilterClientLinked('Missing')}}>Missing</MenuItem>
                                            <MenuItem onClick={() => {setUpdateFilterClients(true)}}>Filter by Client</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Stack>
                            </Cell>
                            <Cell>
                                <Stack>
                                    <Text>Invoice</Text>
                                    <Menu>
                                        <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                            colorScheme={filterInvoiceLinked === 'None' ? 'default' : 'blue'}
                                            variant={filterInvoiceLinked === 'None' ? 'ghost' : 'outline'}
                                            >
                                            {filterInvoiceLinked === 'None' ? '' : filterInvoiceLinked}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => {setFilterInvoiceLinked('None')}}>None</MenuItem>
                                            <MenuItem onClick={() => {setFilterInvoiceLinked('Linked')}}>Linked</MenuItem>
                                            <MenuItem onClick={() => {setFilterInvoiceLinked('Missing')}}>Missing</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Stack>
                            </Cell>
                            <Cell>
                                Amount
                            </Cell>
                            <Cell>
                                <Stack>
                                    <Text>Status</Text>
                                    <Menu>
                                        <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                            colorScheme={filterTradeshiftLinked === 'None' ? 'default' : 'blue'}
                                            variant={filterTradeshiftLinked === 'None' ? 'ghost' : 'outline'} >
                                            {filterTradeshiftLinked === 'None' ? '' : filterTradeshiftLinked}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => {setFilterTradeshiftLinked('None')}}>None</MenuItem>
                                            <MenuItem onClick={() => {setFilterTradeshiftLinked('Preparing')}}>Preparing</MenuItem>
                                            <MenuItem onClick={() => {setFilterTradeshiftLinked('Incomplete')}}>Incomplete</MenuItem>
                                            <MenuItem onClick={() => {setFilterTradeshiftLinked('Attached')}}>Attached</MenuItem>
                                            <MenuItem onClick={() => {setFilterTradeshiftLinked('Draft')}}>Draft</MenuItem>
                                            <MenuItem onClick={() => {setFilterTradeshiftLinked('Sent')}}>Sent</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Stack>
                            </Cell>

                            <Cell>
                                <Stack>
                                    <Text>Latest Update</Text>
                                    <Menu>
                                        <MenuButton w='fit-content' as={Button} leftIcon={<MdFilterList />} size='xs'
                                            colorScheme={filterOrderBy === 'updated' ? 'default' : 'blue'}
                                            variant='ghost'
                                            >
                                            {filterOrderBy === 'updatedAt' ? 'Sorting' : ''}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuOptionGroup defaultValue='asc' title='Order' type='radio'>
                                                <MenuItem onClick={() => {setFilterOrder('asc'); setFilterOrderBy('updatedAt');}}>Ascending</MenuItem>
                                                <MenuItem onClick={() => {setFilterOrder('desc'); setFilterOrderBy('updatedAt');}}>Descending</MenuItem>
                                            </MenuOptionGroup>
                                        </MenuList>
                                    </Menu>
                                </Stack>
                            </Cell>
                        </Header>

                        {loading && <LoadingCircle />}

                        {!loading && accounts && accounts.map((account) => {

                            // Create timestamp string
                            let time = new Date();
                            let timestamp = time.getTime();
                            let timestampstring = timestamp.toString(); 

                            return (
                                <Account account={account} 
                                    // Need this to re-render when we update accounts
                                    key={timestampstring + account.uuid + multipleSelect.toString() + selected.includes(account.uuid).toString()+ filterLines + filterClientLinked + filterInvoiceLinked + filterTradeshiftLinked + filterFrom + filterTo + filterOrder + filterOrderBy}
                                    // pass account as a state
                                    refresh={() => {getAccounts()}}
                                    upload={() => {setUpload(true)}}
                                    multipleSelect={multipleSelect}
                                    selected={selected}
                                    toggleSelected={(uuid) => {
                                        let index = selected.indexOf(uuid);
                                        let tmpSelected = [...selected];
                                        if (index === -1) {
                                            tmpSelected.push(uuid);
                                        } else {
                                            tmpSelected.splice(index, 1);
                                        }
                                        setSelected([...tmpSelected]);
                                    }}
                                 />
                            )
                            
                            })
                        }

                    </Box>
                
                }


            </GridItem>

            {multipleSelect &&

                <Box position='fixed' bottom='0' left='0' right='0' p={4} boxShadow='md' zIndex='2147483002' display='flex' justifyContent='space-between'
                    alignItems='center'  h='68px' className='gradient-background'
                    >
                    <HStack spacing={2}>
                        <ButtonGroup size='sm' variant='outline' colorScheme='white' spacing={2}>
                            <Button onClick={() => {setSelected(accounts.map((account) => account.uuid))}}>Select All</Button>
                            <Button onClick={() => {setSelected([]);}}>Clear</Button>
                        </ButtonGroup>
                        <Text color='white'>
                            {selected.length} selected
                        </Text>
                    </HStack>

                    <ButtonGroup size='sm' variant='outline' colorScheme='white'>
                        <Button onClick={() => {setMultipleSelect(false); setSelected([]);}}>Cancel</Button>
                        <Button onClick={() => {
                            setBulkActioning(true);
                            console.log(selected);
                            let url = 'organisations/accounts/bulk/draft';
                            let config = {
                                params: {
                                    action: 'draft'
                                },
                                data: {
                                    uuids: selected
                                }
                            }

                            // Send selected variable in the body of the request
                            let resp = API.postItems({timeout: 30 * selected.length, url, config})
                            //let resp = API.postItems({timeout: 30 * selected.length, url, config})
                            .then(response => {
                                console.log(response.data);
                                getAccounts();
                                toast({
                                    title: 'Success',
                                    description: response.data.message,
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                });
                                setSelected([]);
                                setMultipleSelect(false);
                                setBulkActioning(false);
                            })
                            .catch(error => {
                                console.log(error);
                                setError(error);
                                setMultipleSelect(false);
                                setBulkActioning(false);
                            });
                            setMultipleSelect(false);}}>Draft</Button>

                        <Button variant='solid' onClick={() => {
                            if (window.confirm('Are you sure you want to send the invoice?', 'This will send the invoice to the client via Tradeshift')) {

                                let url = 'organisations/accounts/bulk/send';
                                let config = {
                                    params: {
                                        action: 'send'
                                    },
                                    data: {
                                        uuids: selected
                                    }
                                }
                                let resp = API.postItems({timeout: 30 * selected.length, url, config})
                                .then(response => {
                                    console.log(response.data);
                                    getAccounts();
                                    toast({
                                        title: 'Success',
                                        description: response.data.message,
                                        status: 'success',
                                        duration: 5000,
                                        isClosable: true,
                                    });
                                    setSelected([]);
                                    setMultipleSelect(false);
                                    setBulkActioning(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                    setError(error);
                                    setMultipleSelect(false);
                                    setBulkActioning(false);
                                });
                                setMultipleSelect(false);
                            }
                            }}>Send</Button>


                    </ButtonGroup>
                </Box>

            }

        </Layout>

    )

}