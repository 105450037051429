import React from 'react';
import Layout from '../../core/layout/Layout';
import ShowModel from '../../core/models/ShowModel';
import AutoForm from '../../core/models/AutoForm';

export default function Requirements () {

    return (

        <Layout
          title="Requirements"
          description="View and manage your requirements."
          secondaryButtonText=""
          primaryButtonText="Add Requirement"
          primaryButtonModal={true}
          modalTitle="Add Requirement"
          modalContent={<AutoForm
            url = 'requirements/'
            inputs = {[
                {
                    name: 'title'
                },
                {
                    name: 'description'
                }, 
                {
                    name: 'type',
                    type: 'select',
                    options: [
                        {
                            label: "Occupational Health",
                            value: "Occupational Health"
                        },
                        {
                            label: "Environmental Health",
                            value: "Environmental Health"
                        }
                    ]
                },
                {
                    name: 'active',
                    type: 'checkbox',
                    value: true
                }
            ]}
            />}
          >
            
            <ShowModel
                filterBy={['type']}
                setTitle={['title']}
                dataKey='requirements'
                url='requirements'
                />        

        </Layout>

    )   
}