import {Box, Text, Icon, keyframes} from '@chakra-ui/react';
import {MdAdsClick} from 'react-icons/md';

const colors = keyframes`
  from {color: 'gray.300';}
  to {color: #E63F3E;}
`;

export default function Index() {
  const highlightLoop = `${colors} alternate infinite 2s linear`;
  
  return (
    <>
        <Box maxWidth='800px' m='auto' p={4} textAlign='center' >
        </Box>
    </>
  );
}