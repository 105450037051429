import React, { useState, useContext} from 'react';
import axios from 'axios';
import {Heading, Text, Box, Stack, Button, Input} from '@chakra-ui/react';
import { AccountContext } from './Accounts';

export default () => {

    const [number, setNumber] = useState(0);

    const fetchRegistration = async () => {
        const response = await axios.post('', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                number: number
            })
        });
        console.log(response);

    };



    return (
        <div>
            <h1>{number}</h1>
            <Button onClick={fetchRegistration}>Fetch Registration</Button>
        </div>
    );
};