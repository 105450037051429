"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var theme_tools_1 = require("@chakra-ui/theme-tools");
var tokens_1 = require("../foundations/tokens");
var filledStyle = function (props) {
    var theme = props.theme, isIndeterminate = props.isIndeterminate, hasStripe = props.hasStripe, onAccent = props.onAccent;
    var stripeStyle = (0, theme_tools_1.mode)((0, theme_tools_1.generateStripe)(), (0, theme_tools_1.generateStripe)('1rem', 'rgba(0,0,0,0.1)'))(props);
   //var background = onAccent ? 'on-accent-emphasis' : (0, tokens_1.getColorDefault)(props);
    var gradient = "linear-gradient(\n    to right,\n    transparent 0%,\n    ".concat((0, theme_tools_1.getColor)(theme, 'inherit'), " 50%,\n    transparent 100%\n  )");
    var addStripe = !isIndeterminate && hasStripe;
    return __assign(__assign({}, (addStripe && stripeStyle)), (isIndeterminate ? { bgImage: gradient } : { background: 'inherit' }));
};
var getSolidStyle = function (props) {
    var _a;
    return {
        filledTrack: __assign({ 
            transition: 'all 0.3s',
            className: 'gradient-backgound'
    }, filledStyle(props)),
        track: {
            background: 'bg-muted',
            borderRadius: (_a = props.borderRadius) !== null && _a !== void 0 ? _a : 'full',
        },
    };
};
var getOnAccentStyle = function (props) {
    return {
        filledTrack: __assign({}, filledStyle(__assign(__assign({}, props), { onAccent: true }))),
        track: {
            bg: 'bg-muted',
        },
    };
};
exports.default = {
    variants: {
        solid: getSolidStyle,
        'on-accent': getOnAccentStyle,
    },
    defaultProps: {
        size: 'lg',
        variant: 'solid',
    },
};
