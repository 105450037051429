import React from 'react'
import { Box, Text, Icon, Flex } from '@chakra-ui/react'
import {MdCheckCircle, MdErrorOutline, MdInfoOutline, MdWarning} from 'react-icons/md'
import Card from '../../core/components/cards/Card';

export default function Splash(props) {

    return (
        
        <Flex justifyContent='center' alignContent='center'
            py={props.externalPy ? props.externalPy : 0}
            px={props.externalPx ? props.externalPx : 0}
            >
            <Card pb={8} mt='70px' mx={4} {...props}>
                {props.children}
            </Card>
        </Flex>
    )
}