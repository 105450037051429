import {
    Avatar,
    Box,
    Flex,
    HStack,
    Image,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    StackDivider,
    Text,
    Tag,
    Heading,
    Button,
    Badge,
  } from '@chakra-ui/react'
  import {
    FiBookmark,
    FiClock,
    FiGrid,
    FiHelpCircle,
    FiMoreVertical,
    FiPieChart,
    FiSearch,
    FiSettings,
  } from 'react-icons/fi';
import { MdOutlineShoppingBag, MdInfoOutline, MdDashboard, MdEmail, MdGroup, MdGroups, MdOutlineSchool, MdFeedback, MdOutlineHealthAndSafety, MdChevronLeft, MdChevronRight, MdExpandLess, MdExpandMore, MdMaximize, MdMinimize, MdPieChart } from 'react-icons/md';
import {IoMdCash, IoMdClipboard} from 'react-icons/io';
import secureLocalStorage from 'react-secure-storage';

import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { set } from 'date-fns';
import { te } from 'date-fns/locale';
let components = [
    {
        category: 'Recruitment',
        permission: 'View Jobs Summary',
        title: 'Jobs',
        link: 'jobs',
        icon: <FiBookmark />,
        iconOnly: FiBookmark
    },
    {   
        category: 'Finance',
        permission: 'View Clients',
        title: 'Clients',
        link: 'clients',
        icon: <MdOutlineShoppingBag />,
        iconOnly: MdOutlineShoppingBag
    },
    {
        category: 'Governance',
        permission: 'View Compliance Summary',
        title: 'Compliance',
        link: 'compliance',
        icon: <MdOutlineSchool />,
        iconOnly: MdOutlineSchool,
        params: {
            uuids: ['c91a41f0-e3a9-42ed-89b9-91229768f418']
        }
    },
    {   
        category: 'PALS',
        permission: 'View Feedback Summary',
        title: 'Feedback',
        link: 'feedback',
        icon: <MdFeedback />,
        iconOnly: MdFeedback
    },
    {   
        category: 'Recruitment',
        permission: 'View Recruitment Summary',
        title: 'Recruitment',
        link: 'recruitment',
        icon: <FiGrid />,
        iconOnly: FiGrid,
        params: {
            uuids: ['57156688-a247-4ed4-ac24-693c7cfed938']
        }
    },
    {
        category: 'Governance',
        permission: 'View Occupational Health Summary',
        title: 'Health',
        link: 'health',
        icon: <MdOutlineHealthAndSafety />,
        iconOnly: MdOutlineHealthAndSafety,
        params: {
            uuids: ['2b68e94c-3506-4cba-b908-3dc42f6db1e3']
        }
    },
    {
        category: 'Administration',
        permission: 'View Roles',
        title: 'Roles',
        link: 'roles',
        icon: <MdGroup />,
        iconOnly: MdGroup
    },
    {
        category: 'Governance',
        permission: 'View Combined Staff Record',
        title: 'Staff',
        link: 'staff',
        icon: <MdGroup />,
        iconOnly: MdGroup,
        params: {
            uuids: [
                '2b68e94c-3506-4cba-b908-3dc42f6db1e3',
                'c91a41f0-e3a9-42ed-89b9-91229768f418',
                '57156688-a247-4ed4-ac24-693c7cfed938'
            ]
        }
    },
    {
        category: 'Finance',
        permission: 'View Accounts Summary',
        title: 'Accounts',
        link: 'accounts',
        icon: <IoMdCash />,
        iconOnly: IoMdCash
    },
    {
        category: 'Finance',
        permission: 'View Accounts Summary',
        title: <>Accounts <Tag size='xs' colorScheme='blue' varian='solid'
                    fontSize='xs' py={1} px={2}
                    ml={1}
                    >DEV</Tag></>,
        link: 'accounts-dev',
        icon: <IoMdCash />,
        iconOnly: IoMdCash
    },
    {
        category: 'Administration',
        permission: 'View Reports Summary',
        title: 'Reports',
        link: 'reports',
        icon: <IoMdClipboard />,
        iconOnly: IoMdClipboard
    },
    {
        category: 'Administration',
        permission: 'View Subscriptions',
        title: 'Subscriptions',
        link: 'manage',
        icon: <IoMdClipboard />,
        iconOnly: IoMdClipboard
    },
    {
        category: 'Administration',
        permission: 'View Subscriptions',
        title: 'Check 1',
        link: 'check/accounts',
        icon: <IoMdClipboard />,
        iconOnly: IoMdClipboard
    },
    {
        category: 'Administration',
        permission: 'View Subscriptions',
        title: 'Check 2',
        link: 'check2/accounts',
        icon: <IoMdClipboard />,
        iconOnly: IoMdClipboard
    }
];

const Group = (props) => {
    
    const [open, setOpen] = useState(props.open);
    const [items, setItems] = useState(props.items);

    
    return (
        <Box>
            
            <SidebarButton 
                icon={open ? <MdExpandLess /> : <MdExpandMore />}
                open={props.show}
                onClick={() => {
                    console.log("Toggling", props.category);
                    setOpen(!open);
                }}
                text={props.category}
                categoryOpen={open}
                item={false}
                />
            
            {open &&
                items.map(component => {

                    return (
                        <SidebarButton 
                            icon={component.icon}
                            iconOnly={component.iconOnly}
                            categoryOpen={open}
                            open={props.show}
                            onClick={() => {
                                //console.log("Open " + permission);
                                props.switchPanel(component.link, component.params);
                            }}
                            text={component.title}
                            key={component.title}
                            item={true}
                            />
                    )
                })
            }

        </Box>
    )
}



const SidebarButton = (props) => {

    let borderCol = 'transparent';
    let textCol = 'text';
   
    if (props.categoryOpen) {
        console.log("Open");
        textCol = 'blue.500';
        borderCol = 'blue.500';
    }
    if (props.item) {
        borderCol = 'blue.300';
        textCol = 'text';
        console.log("Item Open");
    }
    
    return (
        <>
            {props.open ?

                <Box iconSpacing="3" 
                    color='blue.500'
                    cursor='pointer'
                    _hover={{bg: 'blue.50', color: 'blue.500'}}
                    paddingX={4}
                    paddingY={2}
                    display='flex'
                    justifyContent='flex-start'
                    alignItems='center'
                    flexDirection='row'
                    {...props}
                    key={props.key}
                    fontSize={props.item ? 'sm' : 'md'}
                    borderLeftWidth='4px' 
                    borderLeftStyle='solid'
                    borderLeftColor={borderCol}
                    >

                    <Icon
                        variant='ghost'
                        colorScheme='blue'
                        as={props.iconOnly ? props.iconOnly : props.categoryOpen ? MdExpandLess : MdExpandMore}
                        mr={4}
                        />
                    <Text color={textCol}>{props.item && "- "}{props.text}</Text>
                    {props.categoryOpen !== undefined &&
                        <Icon display='none' ml={1} as={props.categoryOpen ? MdExpandLess : MdExpandMore} />
                    }
                </Box>
        
                :

                <IconButton
                    variant='ghost'
                    colorScheme='blue'
                    icon={props.icon}
                    aria-label={props.children}
                    {...props}
                    key={props.key}
                    />
        
            } 
        </>
    )
}

export default function Sidebar(props) {

    const params = useLocation();
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState([]);
    const [uuid, setUuid] = useState('pyrexia');
    const [items, setItems] = useState({});

    function getOrganisation() {
    
        let tmpUuid = secureLocalStorage.getItem('organisationUuid');
        setUuid(tmpUuid);
        let tmpPermissions = secureLocalStorage.getItem('organisationPermissions');
        setPermissions(tmpPermissions);


        let categories = {};
        let viewableComponents = [];
        let finalItems = {};

        if ((tmpPermissions === undefined || tmpPermissions == null) && props.test !== 'true') {
            return;
        }

        // Loop through components
        components.map(component => {

            if (props.test === 'true' || tmpPermissions.includes(component.permission)) {
                
                if (categories[component.category]) {
                    categories[component.category] = categories[component.category] + 1;
                    finalItems[component.category]['items'].push(component);
                } else {
                    categories[component.category] = 1;
                    finalItems[component.category] = {
                        open: false,
                        items: [component]
                    };
                }

                viewableComponents.push(component);

            }

        })
        
        // Make a maximum (i.e. so it is easier to know if things are expanded or not)
        let max = 5;
        if (viewableComponents.length <= max) {
            
            // Loop through each of the finalItems and change open to true
            Object.keys(finalItems).map(key => {
                finalItems[key]['open'] = true;
            });

        }

        console.log(finalItems);

        setItems(finalItems);
            

    }

    if (uuid === 'pyrexia') {
        getOrganisation();
    }
    
    function switchPanel(url, urlState) {
        //console.log("Switching to " + url, urlState);
        navigate(url, {state: urlState});
    }

    // Loop through components and add to the categories if permissions match

    function toggleCategory(category, open) {

        let tmpItems = items;

        
        tmpItems[category]['open'] = !open;

        console.log("Toggled", category, open, !open, tmpItems);
        setItems(tmpItems);

    }

    useEffect(() => {
        console.log("Items", items);
    }
    ,[items]);


    return (

    
    <Flex as="section" minH='fill-available'>
      <Stack
        flex="1"
        maxW={{
          base: props.show ? 'full' : '64',
          sm: props.show ? 'xs' : '64',
        }}
        pb={2}
        px={{
          base: '0',
        }}
        borderRightWidth="0px"
        justifyContent="space-between"
        w={props.show ? '200px' : '45px'}
        transition='all 0.2s ease-in-out'
        pt={props.show ? '4' : '0'}
        position='fixed'
        zIndex='100'
        top={props.hideNav ? '0' : '70px'}
        bottom='0'
        left='0'
        boxShadow='md'
        overflowY='scroll'
        borderRight='2px solid'
        borderRightColor='gray.500'
        bg='white'
        borderTopRightRadius='20px'
        borderBottomRightRadius='20px'
      >
        {props.show &&
            <IconButton boxSize='40px' p='4px' variant='ghost' position='absolute' right='0px' top='0px' icon={props.show ? <MdChevronLeft /> : <MdChevronRight />} aria-label="Open Menu" onClick={() => props.setShow(!props.show)} />
        }
        
        <Stack spacing="0" >



                    <Flex transition='all 0.2s ease-in-out' justifyContent='center' alignItems='center' flexDirection='column' 
                    w={props.show ? '200px' : '32px'} 
                    h={props.show ? '120px' : '32px'}
                    p={props.show ? 4 : 0}
                    ml={props.show ? 0 : '6px'}
                    >
                        <Image mt='24px' 
                            src={uuid ? '/images/partners/' + uuid + '.png' : '/images/partners/pyrexia.png'}
                            w='100px' float='right' mb={2}
                        />
                    </Flex>
                    <Stack spacing="0" pt='24px'
                        key={JSON.stringify(items)}
                        >

                        {!props.show &&
                            <IconButton
                                variant='ghost'
                                colorScheme='blue'
                                icon={props.show ? <MdChevronLeft /> : <MdChevronRight />}
                                aria-label='Expand sidebar'
                                onClick={() => props.setShow(!props.show)}
                                opacity={!props.show ? 1 : 0}
                                transition='all 0.2s ease-in-out'
                                />
                        }

                        <SidebarButton 
                            icon={<MdDashboard />}
                            open={props.show}
                            onClick={() => {
                                //console.log("Open " + permission);
                                switchPanel("", "");
                            }}
                            text="Overview"
                            key='overview-panel'
                            />

                        
                        {Object.keys(items).map(category => {

                            console.log("Category", category, items[category]);

                            return (
                                <Group
                                    category={category}
                                    open={items[category].open}
                                    items={items[category].items}
                                    show={props.show}
                                    key={category}
                                    switchPanel={switchPanel}
                                    />
                            )

                        })}
                        
                        <SidebarButton 
                            icon={<MdInfoOutline />}
                            open={props.show}
                            text="Details"
                            onClick={() => {
                                //console.log("Open details");
                                navigate('details');
                            }}/>
                    </Stack>
                </Stack>
                <Stack spacing="2" divider={<StackDivider />}>

                    <Box />
                    {props.show ?
                        <Text color='subtle' fontSize='xs' cursor='pointer'
                            _hover={{color: 'blue.500'}}
                            p={2}
                            pl={4}
                            onClick={() => {
                                window.open('mailto:oliver@pyrexia.io');
                            }}
                            >
                            Contact an administrator
                        </Text>
                        :
                        <IconButton variant='ghost' coloScheme='blue' icon={<MdEmail />} aria-label="Contact an administrator" 
                            onClick={() => {
                                // Open new tab
                                window.open('mailto:oliver@pyrexia.io');
                            }}
                            />
                    }
                
                </Stack>
      </Stack>
    </Flex>
  )
}