import React from 'react';
import { useState} from 'react';
import { useEffect } from "react";
import { 
    Wrap,
    ButtonGroup,
    Select,
    Divider,
    Link,
    FormControl,
    FormLabel,
    Tooltip,
    FormErrorMessage,
    FormHelperText,
    Radio,
    RadioGroup,
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    ListItem,
    OrderedList,
    UnorderedList,
    SliderMark,
    Editable,
    EditableInput,
    EditableTextarea,
    Tag, 
    EditablePreview, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Spinner, chakra, useCheckboxGroup, useCheckbox, GridItem, Checkbox, Box, Heading, Text, Button, Input, Stack, useColorModeValue, Center, Image, Flex, Spacer, useToast, CheckboxGroup, TagCloseButton, InputGroup, TagLeftIcon, Grid, SimpleGrid} from '@chakra-ui/react';
import API from '../../core/utils/API';
import { useForm, useController, Controller } from "react-hook-form";
import Splash from '../../static/layout/Splash';
import CreatableSelect from 'react-select/creatable';
import Specialties from './GeneralPractice';
import Loading from '../../static/layout/Loading';
import { MdCheck, MdClose, MdContentCopy } from 'react-icons/md';
import Layout from '../../core/layout/Layout';
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import e from 'cors';

function CheckBoxButtons(props) {

    return (
        <Box>
            {props.data.values &&
                <Wrap spacing={2}>
                    {props.data.values.map((option, index) => {

                        return (
                            <Button colorScheme='blue' variant='outline' className='btn-8' onClick={() => {console.log("clicked", props.data.label, option.valueLabel); props.setCheckboxButtonValue(props.data.valueLabel, option.label, index);}}>{option.label}</Button>
                        )
                    })}
                </Wrap>
            }
        </Box>
    )
}


function CopyeableText(props) {

    const toast = useToast();

    const [copied, setCopied] = useState(false);

    const generateToast = () => {
        toast({
            title: "Copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
        })
    }

    return (
        <Box cursor='pointer' _hover={{color: 'blue.500'}}
            onClick={() => {
                navigator.clipboard.writeText(props.text); 
                generateToast();
                setCopied(true);
            }}>
            <Heading size='label' mb='0.5em' {...props.titleProps}>
                {props.title}
                <Icon ml='0.5em' 
                    boxSize='12px' 
                    opacity='0.5' 
                    as={copied ? MdCheck : MdContentCopy} color={copied ? 'green.500' : 'blue.500'} w={4} h={4} />
            </Heading>
            <Text>
                {props.text} 
            </Text>
        </Box>
    )
}


function DesignBox(props) {

    return (

        <Box className='hover-only' p='2px' borderRadius="lg" overflow="hidden" {...props}>
            <Box position='relative' p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" bg='white'>

                {props.children}

            </Box>
        </Box>


    )
}

function ListWithReflection(props) {

    console.log(props.title, props.array);

    return (

        <Stack spacing={2}>
            {props.array.map((el) => {
                return (

                    <CopyeableText 
                        title={el.title}
                        titleProps={{size: 'label', mb: '4px', color: 'blue.500'}}
                        text={el.reflection}
                        />

                    )
                }
            )}    
        </Stack>

    )
}

export default function Reflection(props) {


    // Import JSON data from ./GeneralPractice.js
    const [tags, setTags] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [apiResponse, setApiResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [json, setJson] = useState({});

    const navigate = useNavigate();
    const { state } = useLocation();

    function update() {
    
        setLoading(true);

        data['tree'] = tags;
        data['keywords'] = keywords;

        console.log(data);

        let url = 'ai';
        let config = {
            params: {
                uuid: state.uuid
            }
        };

        let response = API.getItems({url, config})
        .then(function (response) {
            console.log(response);
            let tmpJson = response.data.json;

            setJson(tmpJson);
            setApiResponse(response.data);
            setLoading(false);

        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });

    }

    useEffect(() => {
        update();
    }, []);

    return (

        <>

        {loading &&
            
            <Loading 
                message='Loading reflection'
                />
        
        }

        {apiResponse &&
        
            <Layout
                title='Reflection'
                actionContent={
                    <>
                        <Button variant='outline' colorScheme='blue' onClick={() => {navigate("/my/ai/reflection")}}>Return</Button>
                    </>
                }
                >

                <GridItem colSpan={6} h='0' pb='72px'>

                    <Stack>

                        <Heading size='smalltitle' mb='16px'>
                            {json.topic}
                        </Heading>
                        <Wrap spacing={2} pb='24px'>
                                    {tags.map((tag, index) => {
                                        return (
                                            <Tag size='sm' variant='outline' colorScheme='blue' key={tag.key+tag.value}>
                                                <Text mr='4px' as='span' color='blue.500' fontWeight='bold'>{tag.key + ": "}</Text>{" " + tag.value}
                                            </Tag>
                                        )
                                    })}
                                    
                                    {keywords.map((keyword, index) => {
                                        let found = false;
                                        if (JSON.stringify(json).includes(keyword)) {
                                            found = true;
                                        }
                                        return (
                                            <Tag size='sm' variant='outline' colorScheme={found ? 'green' : 'red'} key={keyword}>
                                                <TagLeftIcon boxSize="12px" as={found ? MdCheck : MdClose} />
                                                {keyword}
                                            </Tag>
                                        )
                                    })}
                            </Wrap>
                    
                    </Stack>


                    <SimpleGrid columns={{base: 1, md: 6}} spacing={4} pb='72px'>
                        <GridItem colSpan={{base: 1, md: 2}} >
                            <DesignBox>
                                <Stack spacing={4}>

                                    <CopyeableText 
                                        title='Description'
                                        text={json.description}
                                        />

                                    <CopyeableText 
                                        title='Reflection'
                                        text={json.reflection}
                                        />

                                </Stack>
                            </DesignBox>
                        </GridItem>

                        <GridItem colSpan={{base: 1, md: 4}}  pb='72px'>
                            <Stack spacing={4}>

                                <DesignBox>
                                    {json.themes !== undefined &&
                                            <Box>
                                                <Heading size='label' mb='1em'>Themes</Heading>
                                                {json.themes[0].reflection !== undefined ?
                                                    <ListWithReflection title='Themes' array={json.themes} />
                                                :
                                                    <UnorderedList>
                                                        {json.themes.map((theme, index) => {
                                                            return (
                                                                <ListItem>
                                                                    {theme.title}
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </UnorderedList>
                                                }
                                            </Box>
                                    }
                                </DesignBox>
                                <DesignBox>
                                    {json.capabilities !== undefined &&
                                        <Box>
                                            <Heading size='label' mb='1em'>Capabilities</Heading>
                                            {json.capabilities[0].reflection !== undefined ?
                                                <ListWithReflection title='Capabilities' array={json.capabilities} />
                                            :
                                                <UnorderedList>
                                                    {json.capabilities.map((capability, index) => {
                                                        return (
                                                            <ListItem>
                                                                {capability.title}
                                                            </ListItem>
                                                        )
                                                    })}
                                                </UnorderedList>
                                            }
                                        </Box>
                                    }
                                </DesignBox>
                                <DesignBox>
                                    {json.learningNeeds !== undefined &&
                                        <Box>
                                            <Heading size='label' mb='1em'>Learning Needs</Heading>
                                            {json.learningNeeds[0].reflection !== undefined ?
                                                <ListWithReflection title='Learning Needs' array={json.learningNeeds} />
                                            :
                                                <OrderedList>
                                                    {json.learningNeeds.map((learningNeed, index) => {
                                                        return (
                                                            <ListItem>
                                                                {learningNeed.title}
                                                            </ListItem>
                                                        )
                                                    })}
                                                </OrderedList>
                                            }
                                        </Box>
                                    }
                                </DesignBox>

                                </Stack>

                        </GridItem>

                    </SimpleGrid>

                </GridItem>


            </Layout>                            

        
        
        
        
        }

            
        
        

        </>

    )
}
