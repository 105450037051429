import { useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Circle, Heading, Flex, Box, Text, Icon,Wrap,  Tag, Avatar, TagLabel } from '@chakra-ui/react';
import { MdCheck, MdClose, MdAccessTime, MdLock } from 'react-icons/md';

const handleStyle = { left: 10 };

function Wayfarer({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  let colors = {
    'completed': 'green.500',
    'inprogress': 'yellow.500',
    'notstarted': 'red.500',
    'locaked': 'gray.500',
  }

  let icons = {
    'completed': MdCheck,
    'inprogress': MdAccessTime,
    'notstarted': MdClose,
    'locked': MdLock,
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />

      <Flex
      alignItems='center'
        h='72px'
        // On double click, open the modal
        
        >
          <Box

        border='1px solid #e2e8f0'
        borderRadius='full' 
        bg='white'
        boxShadow='md'
        px={4}
        py={2}
        onDoubleClick={data.onDoubleClick}
    minWidth='230px'
        >
        <Text>
          {data.label}
        </Text>
          </Box>
          

      </Flex>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />

    </>
  );
}

export default Wayfarer;
