import React, { useState, useEffect, useContext } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import Modal from '../../core/components/modal/Modal';


import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import FileImport from './FileImport';
import PyrexiaUserList from './PyrexiaUserList.csv';


export default function Import(props) {

    const [csv, setCsv] = useState([]);
    const [showNewUser, setShowNewUser] = useState(false);
    const [defaultRoles, setDefaultRoles] = useState([
        'Healthcare Professional',
    ]);
    const [roles, setRoles] = useState([
        'Healthcare Professional',
        'Administrator',
    ]);


    const toast = useToast();

    const [users, setUsers] = useState([
        {
            firstName: 'Oliver',
            lastName: 'Trampleasure',
            email: 'ojtramp@gmail.com',
            roles: [
                'Healthcare Professional',
                'Administrator'
            ],
            start: '01/01/2024',
            end: '01/09/2024'
        }
    ]);

    function importData(input) {

        let colIndexes = {
            firstName: 'Firstname',
            lastName: 'Lastname',
            email: 'E-mail',
            roles: 'Roles',
            start: 'Start Date (DD/MM/YYYY)',
            end: 'End Date (DD/MM/YYYY)' 
        };

        
        let tmpArray = [];

        // Loop through input

        for (let i = 0; i < input.length; i++) {
                
            let row = input[i];
    
            let user = {
                firstName: row[colIndexes.firstName],
                lastName: row[colIndexes.lastName],
                email: row[colIndexes.email],
                roles: row[colIndexes.roles] ? row[colIndexes.roles].split(",") : defaultRoles,
                start: row[colIndexes.start],
                end: row[colIndexes.end]
            }
    
            tmpArray.push(user);
    
        }

        setUsers(tmpArray);

    }


    useEffect(() => {
            
            console.log("CSV", csv);
    
            if (csv.length > 0) {
                importData(csv);
            }
    
    }, [csv]);





    return (
        <Layout
            title='Import Users'
            description={
                <>
                <Text as='span'>You can import users from a CSV file. 
                Download the <Text as='span' cursor='pointer' color='blue.500'
                    onClick={() => {
                            // Download file PyrexiaUserList from public folder /files/PyrexiaUserList.csv
                            window.open(PyrexiaUserList);
                        }}>
                    example CSV file</Text> to get started.</Text>

                </>
            }
            actionContent={
                <ButtonGroup>
                    <FileImport setCsv={setCsv} />
                    <Button colorScheme='blue'
                        variant='outline'
                        isDisabled={users.length === 0}
                        onClick={() =>{
                            console.log("Save users", users);

                            let data = {
                                users: users,
                            };

                            let response = API.postItems({url: 'users/upload', data})
                            .then((response) => {

                                toast({
                                    title: "Users saved",
                                    description: "All users have been saved",
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                                })

                            })
                            .catch((error) => {
                                console.log("FAIL");
                                toast({
                                    title: "Error",
                                    description: "There was an error saving users",
                                    status: "error",
                                    duration: 9000,
                                    isClosable: true,
                                })
                                
                            });

                        }}
                        >
                        Save
                    </Button>
                    

                </ButtonGroup>
            }
            
            >
        

            <GridItem colSpan='6' alignItems='flex-start' width='fill-available'
                key={users.length}
                >


                {showNewUser &&
                    <Modal
                        title='Add a user'
                        onClose={() => {
                            setShowNewUser(false);
                        }}
                        actionContent={
                            <Button colorScheme='blue'
                                variant='outline'
                                onClick={() =>{

                                    // Check for email at least
                                    if (!document.getElementsByName('add-email')[0].value) {
                                        toast({
                                            title: "Email required",
                                            description: "Please enter an email address",
                                            status: "error",
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                        return;
                                    }

                                    // Check for a role
                                    if (!document.getElementsByName('add-roles')[0].value) {
                                        toast({
                                            title: "Role required",
                                            description: "Please enter a role",
                                            status: "error",
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                        return;
                                    }

                                    console.log("Save new user");
                                    let user = {
                                        firstName: document.getElementsByName('add-firstName')[0].value,
                                        lastName: document.getElementsByName('add-lastName')[0].value,
                                        email: document.getElementsByName('add-email')[0].value,
                                        roles: document.getElementsByName('add-roles')[0].value.split(","),
                                        // Convert start and end from 2023-01-01 to 01/01/2023
                                        start: moment(document.getElementsByName('add-start')[0].value).format('DD/MM/YYYY'),
                                        end: moment(document.getElementsByName('add-end')[0].value).format('DD/MM/YYYY')
                                    }
                                    let tmpUsers = users;
                                    tmpUsers.push(user);
                                    setUsers(tmpUsers);
                                    setShowNewUser(false);
                                    toast({
                                        title: "User added",
                                        description: "The user has been added",
                                        status: "success",
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                }}
                                >
                                Save
                            </Button>
                        }
                        >
                        <InputWrapper
                            label='Firstname'
                            type='input'
                            name='add-firstName'
                            />  
                        <InputWrapper
                            label='Last name'
                            type='input'
                            name='add-lastName'
                            />
                        <InputWrapper
                            label='E-mail'
                            type='input'
                            name='add-email'
                            />
                        <InputWrapper
                            label='Roles'
                            type='select'
                            name='add-roles'
                            options={roles}
                            defaultValue={defaultRoles}
                            isMulti={true}
                            />
                        <InputWrapper
                            label='Start'
                            type='date'
                            name='add-start'
                            />
                        <InputWrapper
                            label='End'
                            type='date'
                            name='add-end'
                            />

                    </Modal>

                }


                
                <Header c={9}>
                    <Cell c={2}>
                        Name
                    </Cell>
                    <Cell c={2}>
                        E-mail
                    </Cell>
                    <Cell c={2}>
                        Roles
                    </Cell>
                    <Cell>
                        Start
                    </Cell>
                    <Cell>
                        End
                    </Cell>

                </Header> 

                <Row
                    bg='blue.50'
                            c={9}
                            buttons={
                                <ButtonGroup size='xs'>
                                    <Button variant='outline'
                                        colorScheme='red'
                                        onClick={() => {
                                            // Toast to say example
                                            toast({
                                                title: "Example",
                                                description: "This is an example user and cannot be deleted",
                                                status: "info",
                                                duration: 9000,
                                                isClosable: true,
                                            })
                                        }}
                                        isDisabled={true}
                                        >
                                        Delete
                                    </Button>
                                </ButtonGroup>

                            }
                            >
                            <Cell c={2}>
                                <Text>
                                Oliver Trampleasure <Text as='span' color='blue.500'>(Example)</Text>
                                </Text>
                            </Cell>
                            <Cell c={2}>
                                oliver@pyrexia.io
                            </Cell>
                            <Cell c={2}>
                                <Wrap spacing={2}>
                                    {['CTO'].map((role) => {
                                        return (
                                            <Tag colorScheme='blue' size='sm' variant='outline'>
                                                {role}
                                                <TagRightIcon as={MdClose} isDiabled={true} />

                                            </Tag>
                                        )
                                    })}
                                    <Menu>
                                        <MenuButton isDisabled={true} as={Button} rightIcon={<MdExpandMore />} size='xs' variant='ghost' colorScheme='blue'>
                                            Add
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem>Example Role</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Wrap>
                            </Cell>
                            <Cell>
                                01/01/2024
                            </Cell>
                            <Cell>
                                01/09/2030
                            </Cell>
                        </Row>   
                

                {users && users.map((user, index) => {


                    function deleteThisUser() {

                        console.log("Delete " + index);
                        let tmpUsers = users;

                        console.log(tmpUsers);

                        // Remove just the one user
                        tmpUsers.splice(index, 1);


                        console.log(tmpUsers);

                        // Force a re-render
                        setUsers([...tmpUsers]);

                        toast({
                            title: "User deleted",
                            description: "The user has been deleted",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                        })
                        
                    }

                    function deleteRole(role) {

                        console.log("Delete role " + role);
                        let tmpRoles = user.roles;

                        console.log(tmpRoles);

                        // Remove just the one role
                        tmpRoles.splice(role, 1);

                        // Save the new roles
                        user.roles = tmpRoles;

                        // Force a re-render
                        setUsers([...users]);

                        toast({
                            title: "Role deleted",
                            description: "The role has been deleted",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                        })

                    }

                    // Check available roles for this user
                    let availableRoles = roles.filter((role) => {
                        return !user.roles.includes(role);
                    });
                    

                    return (

                        <Row
                            c={9}
                            buttons={
                                <ButtonGroup size='xs'>
                                    <Button variant='outline'
                                        colorScheme='red'
                                        onClick={() => {
                                            console.log("Delete user clicked 2");
                                            deleteThisUser(user, index);
                                        }}
                                        >
                                        Delete
                                    </Button>
                                </ButtonGroup>

                            }
                            >
                            <Cell c={2}>
                                {[user.firstName, user.lastName].join(" ")}
                            </Cell>
                            <Cell c={2}>
                                {user.email}
                            </Cell>
                            <Cell c={2}>
                                <Wrap spacing={2}>
                                    {user.roles.map((role) => {
                                        return (
                                            <Tag colorScheme='blue' variant='outline' size='sm'>
                                                {role}
                                                <TagRightIcon cursor='pointer' as={MdClose} onClick={() => {
                                                    deleteRole(role);
                                                }} />
                                            </Tag>
                                        )
                                    })}
                                    {user.roles.length === 0 &&
                                        <Tag colorScheme='red' size='sm'>
                                            No roles
                                        </Tag>
                                    }
                                    {availableRoles.length > 0 &&
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<MdExpandMore />} size='xs' variant='ghost' colorScheme='blue'>
                                                Add
                                            </MenuButton>
                                            <MenuList>
                                                {availableRoles.map((role) => {


                                                    return (
                                                        <MenuItem onClick={() => {
                                                            let tmpRoles = user.roles;
                                                            tmpRoles.push(role);
                                                            user.roles = tmpRoles;
                                                            setUsers([...users]);
                                                            toast({
                                                                title: "Role added",
                                                                description: "The role has been added",
                                                                status: "success",
                                                                duration: 9000,
                                                                isClosable: true,
                                                            })
                                                        }}>{role}</MenuItem>
                                                    )
                                                })}
                                            </MenuList>
                                        </Menu>
                                    }
                                </Wrap>
                            </Cell>
                            <Cell>
                                {user.start}
                            </Cell>
                            <Cell>
                                {user.end}
                            </Cell>
                        </Row>   
                    )
                })}

                <Row 
                    c={9}
                    buttons={

                        <Button colorScheme='blue'
                            variant='outline'
                            onClick={() =>{
                                setShowNewUser(!showNewUser);
                            }
                            }
                            >
                            Add User
                        </Button>
                    }
                    >
                    <Cell c={8}>

                    <Button colorScheme='red'
                        variant='outline'
                        size='xs'
                        onClick={() =>{
                            
                            // Check for confirmation
                            if (!window.confirm("Are you sure you want to remove all users?")) {
                                return;
                            }

                            setUsers([]);

                            toast({
                                title: "Users removed",
                                description: "All users have been removed",
                                status: "info",
                                duration: 9000,
                                isClosable: true,
                            })
                        }}
                        >
                        Remove All Users
                    </Button>
                    </Cell>
                </Row>


            </GridItem>
        
        </Layout>
    )
}