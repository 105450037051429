import React from 'react';
import Layout from '../../core/layout/Layout';
import ShowModel from '../../core/models/ShowModel';
import AutoForm from '../../core/models/AutoForm'; 
import API from '../../core/utils/API';

import { useState, useEffect } from 'react';

import { Spacer, Icon, IconButton, Circle, Box, Heading, Text, Flex, Button, Stack, Avatar, AvatarGroup, Divider, HStack, VStack, Wrap, WrapItem, Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton, useColorModeValue } from '@chakra-ui/react';
import {MdFileUpload, MdOutlineFileCopy, MdEditNote, MdOutlineSchool, MdUploadFile, MdEditDocument, MdFileCopy, MdLink, MdOutlineAccountCircle, MdOutlineVisibility, MdLogin, MdNotifications,  MdPhoneIphone, MdPhone, MdLockOutline, MdLockClock, MdLock, MdDeleteSweep, MdDelete, MdOutlineArchive, MdVisibility, MdAutoFixHigh, MdNotificationsNone, MdWarningAmber, MdInbox, MdEdit, MdOutlineExpandMore, MdCheck, MdClose, MdLabelOutline } from 'react-icons/md';
import Modal from '../../core/components/modal/Modal';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { AccountContext } from '../../auth/cognito/components/Accounts';
import { forwardRef, useRef, useImperativeHandle } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, MenuGroup, MenuDivider } from "@chakra-ui/react"

export function NotificationMenu(props) {

    return (
        <>
            {props.menuWrapper ?
                <Menu>
                    <MenuButton onClick={props.refresh}>
                        <MdNotifications />
                    </MenuButton>
                    <MenuList minW='400px'>
                        {props.children}
                    </MenuList>
                </Menu>
            :
                <>
                    {props.children}
                </>
            }
      </>
    )
}
export default function Notifications (props, ref) {


    const navigate = useNavigate();

    let [messages, setMessages] = useState([]);
    let [notifications, setNotifications] = useState([]);
    let [loading, setLoading] = useState(true);
    let [error, setError] = useState(null);
    let [show, setShow] = useState(false);
    let [lastChecked, setLastChecked] = useState(null);


    let icons = {
        'message': <MdInbox />,
        'notification': <MdNotificationsNone />,
        'warning': <MdWarningAmber />,
        'eye': <MdOutlineVisibility />,
        'ai-file': <MdAutoFixHigh />,
        'delete-file': <MdDelete />,
        'archive-file': <MdDelete />,
        'archive-files': <MdDeleteSweep />,
        'lock': <MdLockOutline />,
        'lock-open': <MdLockOutline />,
        'lock-reset': <MdLockClock />,
        'phone': <MdPhoneIphone/>,
        'login': <MdOutlineAccountCircle />,
        'upload-file': <MdFileUpload />, // MdUploadFile
        'upload-file-fail': <MdFileUpload />,
        'updated-file': <MdEditNote />,
        'add-file': <MdOutlineFileCopy />,
        'teaching': <MdOutlineSchool />
    }

    const getNotifications = async () => {

        //console.log("Loading notifications");

        setLoading(true);
        setError(null);

        let url = 'notifications/';
        let config = {
            params: {
                all: props.all
            }
        };
        let response = await API.getItems({url, config})
        .then((response) => {

            let data = response.data;

            //console.log("DATA = ", data);
            setNotifications(data['notifications']);
            //setMessages(data['messages']);
    
            setLastChecked(moment().format('YYYY-MM-DD HH:mm:ss'));
    
            setLoading(false);
        })
        .catch((error) => {
            setError(error);
            setLoading(false);
            
        });

    }

    useEffect(() => {

        if (props.all) {
            getNotifications();
        }

    }, []);
   



    return (
        
        <NotificationMenu 
            refresh={getNotifications}
            menuWrapper={props.menuWrapper}
            >
        <Box position='relative'  maxW={props.all ? '100%' : '400px'} mt={props.all ? '0px' : '52px'} >
            
            {props.all ?

                <>
                </>
                :

            <HStack 
            zIndex='3' h='52px' position='fixed' top='0' right='0'
            w='400px'
            borderBottomColor='blue.500' color='blue.500' pl={2} pr={4}
            pt={1} pb={1}
            borderBottom='2px solid' >


            <Circle border='0px solid white' mr={0} cursor='pointer' size='24px' bg='white' mr={1}>
                <Icon as={MdNotifications} color='blue.500' boxSize='18px' />
            </Circle>
            <Stack spacing={0}>
                <Text lineHeight='1.2em' fontSize='sm' fontWeight='bold'>Notifications</Text>
                <Text color='subtle' lineHeight='1.2em' fontSize='sm'>Last <Text as='span' onClick={() => getNotifications()} textDecoration='underline' color='blue.500' cursor='pointer'>refreshed</Text> <Moment fromNow>{lastChecked}</Moment></Text>
            </Stack>
            <Spacer />
            <IconButton display='none' size='xs' onClick={() => props.toggleSidebar()} icon={<MdClose />} colorScheme='blue' className='btn-8' />

        </HStack>

            }

            {show && 
                <Modal onClose={() => setShow(false)}>
                    <Heading>Hello, showing notification here</Heading>
                </Modal>
            }



            <Stack spacing="0" justify="center" mt={5} bg='white' boxShadow='md' border='0px solid gray' borderRadius='md'>

                {loading && 
                    <HStack minW='350px' justifyContent='space-between' w='100%' p={2} 
                        cursor='pointer'
                        _hover={{bg: 'blue.50'}}
                        borderBottom='1px solid whitesmoke'
                        >
                        
                        <HStack>
                            <Circle mr={2} cursor='pointer' size='24px' bg='white'>
                                <MdWarningAmber size='18px' color='blue.500' />
                            </Circle>
                            <Stack spacing={0}>
                                <Box className="loading" w='100%' borderBottom='1px solid whitesmoke' pb={4}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>

                                </Box>
                            </Stack>
                        </HStack>
                    </HStack>
                }

                {error && 
                    <HStack minW='350px' justifyContent='space-between' w='100%' p={2} 
                        cursor='pointer'
                        _hover={{bg: 'red.50'}}
                        borderBottom='1px solid whitesmoke'
                        >
                        
                        <HStack>
                            <Circle mr={1} cursor='pointer' size='24px' bg='white' >
                                <Icon as={MdWarningAmber} color='red.500' size='18px' />
                            </Circle>
                            <Stack spacing={0}>
                                <Text fontWeight='strong' fontSize='sm'>Error {error.code}</Text>
                                <Text fontSize='sm'>{error.message}</Text>
                            </Stack>
                        </HStack>
                    </HStack>
                }



                {!loading && notifications.map((notification) => {

                    return (

                        <HStack key={notification.uuid} onClick={() => {
                            // Navigate to the user's record
                            //props.conversation(user.uuid);
                            //navigate('/my/conversation', { state: { recipientUuid: user.uuid} });
                            }}

                            _hover={{bg: 'blue.50'}}
                            cursor='pointer' justifyContent='space-between' w='100%' p={2} borderBottom='1px solid whitesmoke'>
                
                            <HStack>
                                <Circle mr={1} cursor='pointer' size='24px' bg='white' color='blue.500'>
                                    {notification.icon ?
                                        icons[notification.icon]
                                        :
                                        <MdNotificationsNone size='218px4px' />
                                    }
                                </Circle>
                                <Stack spacing={0}>
                                    <Text fontWeight='strong' fontSize='xs'>{notification.subject}</Text>
                                    <Text fontSize='xs' color='subtle'>{notification.body}. <Text as='span' fontSize='xs' color='subtle'><Moment fromNow>{notification.createdAt}</Moment></Text></Text>
                                </Stack>
                            </HStack>

                            <Button 
                                display='none'
                                size='xs'
                                variant='outline'
                                colorScheme='blue' className='btn-8'  
                                onClick={() => {
                                // Navigate to the user's record
                                //navigate('/my/conversation', { state: { recipientUuid: user.uuid} });
                                }}>
                                View
                            </Button>
                        </HStack>
                    )

                })}

                {props.all != true && 
                
                
                    <HStack key='link-to-my-notifications' onClick={() => {
                        navigate('/my/notifications');
                        }}

                        _hover={{bg: 'blue.50'}}
                        cursor='pointer' justifyContent='space-between' w='100%' p={2} borderBottom='1px solid whitesmoke'>
            
                        <HStack>
                            <Circle mr={1} cursor='pointer' size='24px' bg='white' color='blue.500'>
                                <MdLink/>
                            </Circle>
                            <Stack spacing={0}>
                                <Text fontWeight='strong' fontSize='xs'>View all</Text>
                                <Text fontSize='xs' color='subtle'>Click here to see all your notifications.</Text>
                            </Stack>
                        </HStack>

                        <Button 
                            display='none'
                            size='xs'
                            variant='outline'
                            colorScheme='blue' className='btn-8'  
                            onClick={() => {
                            // Navigate to the user's record
                            //navigate('/my/conversation', { state: { recipientUuid: user.uuid} });
                            }}>
                            View
                        </Button>
                    </HStack>
                }

            </Stack>

        </Box>
        </NotificationMenu>

    )
    
}
    