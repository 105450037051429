import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import AutoForm from '../../core/models/AutoForm';
export default function Organisations(props) {


    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    

    function updateList() {


        let url = 'permissions/';
        let config = {
            params: {
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log("List", response.data);
            let sorted = response.data.permissions.sort((a, b) => {
                return moment(a.title).diff(moment(b.title));
            });

            console.log(sorted);
            setList(sorted);
            setLoading(false);

        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        }

        );

    }
    useEffect(() => {

        updateList();

    }, []);

    function viewItem(uuid) {
        //console.log("View Schedule", uuid);
        navigate('view', {state: {uuid}});
    }

    



    return (

        <>

        <Layout
            title="Permissions"
            description="This is a list of permissions on Pyrexia."
            primaryButtonText="Add Permission"
            primaryButtonModal={true}
            modalTitle="Add Permission"
            modalContent={<AutoForm
            url = 'permissions/'
            inputs = {[
                {
                    name: 'title'
                },
                {
                    name: 'description'
                },
                {
                    name: 'active',
                    type: 'checkbox',
                    value: true
                }
            ]}
            />}
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>


                    {loading ? <LoadingCircle /> : null}

                    {list ?

                        
                    <Box>
            
                        <Header c={5}>
                            <Cell c={4}>
                                Permission
                            </Cell>
                        </Header>


                    {list.map((item) => {

                        return (
                            <Row c={8}
                                buttons={
                                        <Button 
                                            colorScheme='blue'
                                            variant='outline'
                                            onClick={() => {
                                                viewItem(item.uuid);
                                            }}
                                            >
                                            View
                                        </Button>
                                }
                                >
                                <Cell c={5}>
                                    <Heading size='label'>{item.title}</Heading>
                                    <Text color='subtle'>
                                        {item.description}
                                    </Text>
                                </Cell>
                            </Row>
                        )
                    })}

                </Box>

                        :

                        <Box>
                            <Text>
                                You have no permissions.
                            </Text>
                        </Box>

                    }

                </GridItem>

            </Layout>

        </>
    )
}