import React from 'react';
import Layout from '../../core/layout/Layout';
import ShowModel from '../../core/models/ShowModel';
import AutoForm from '../../core/models/AutoForm';

export default function Users () {
    
    return (
        


          < ShowModel
            title="Users"
            description="View and manage all users."
            secondaryButtonText=""
            primaryButtonText="Add User"
            primaryButtonModal={false}
            setTitle={['firstName', 'lastName']}
            filterBy={['firstName', 'lastName']}
            url='users'
            dataKey='users'
            />


    )
    
}
    