import React from 'react';

import {useState} from 'react';
import { useForm } from "react-hook-form";
import {Alert, ListIcon, Stack, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, List, ListItem, HStack, Text, Link, Flex, Tag, TagLabel, TagRightIcon, Box,Heading, Container, Input, Button } from '@chakra-ui/react';
import Modal from '../../core/components/modal/Modal';
import MFAWrapper from '../../auth/cognito/components/MFAWrapper';
import {Dropzone} from '../../core/components/forms/Dropzone';
import { Link as ReachLink } from 'react-router-dom';

import BasicBanner from '../../core/components/banners/BasicBanner';

import { useNavigate } from "react-router-dom";

import Pluralize from 'pluralize';
import { useAuth0 } from "@auth0/auth0-react";
import API from '../../core/utils/API';

import Resizer from "react-image-file-resizer";
import jsPDF from 'jspdf';
import { Buffer } from "buffer";
import { MdCheck, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";
//import CreateEvidencePDF from './CreateEvidencePDF';

export default function UploadEvidence(props) {
  const navigate = useNavigate();

    const [data, setData] = useState("");
    const [formMessage, setFormMessage] = useState("");
    const [formError, setFormError] = useState("");
    const {
      register,
      handleSubmit,
      reset,
      formState,
      formState: { errors },
    } = useForm();
    const onError = (errors, e) => console.log(errors, e);
    const [loading, setLoading] = useState(false)
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [show, setShow] = useState(false);


    const [endMessageIndex, setEndMessageIndex] = useState(0);
    let endMessage = [{
      icon: MdOutlineCloudDone,
      title: 'Uploading',
      message: 'We just uploaded and encrypted your documents as PDFs'
    },
    {
      icon: MdModelTraining,
      title: 'Text extraction',
      message: '... and have started using AI to extract the text from them'
    },
    {
      icon: MdKeyboardVoice,
      title: 'Language extraction',
      message: '... and natural language processing to find key information'
    },
    {
      icon: MdLabelOutline,
      title: 'Tagging',
      message: '... before using that to automatically tag your documents.'
    },
    {
      icon: MdFolderOpen,
      title: 'Organising',
      message: '... which let us sort your documents into separate records'
    },
    {
      icon: MdOutlineNewLabel,
      title: 'Labelling',
      message: '... but you can add your own tags, or remove ours'
    },
    {
      icon: MdIosShare,
      title: 'Sharing',
      message: '... before sharing your records, or keeping them private.'
    }]

    
    const [sizeMessage, setSizeMessage] = useState([]);


    const resizeFile = (file, width, height) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 1240, 1704, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64' );
    });

    function getImageDimensions(imageSrc) {
      const img = new Image();
      //console.log("getImageDimensions", imageSrc);
      img.src = imageSrc;
      return new Promise((resolve, reject) => {
        img.onload = function() {
          resolve({ width: img.width, height: img.height });
        }
        img.onerror = function() {
          reject(new Error('Failed to load image'));
        }
      });
    }

    const generatePDF = (image, w, h, filename) => new Promise((resolve, reject) => {

      //console.log("generatePDF");
      //console.log("w", w);
      //console.log("h", h);
      //console.log("filename", filename);
      //const DataUrl = `data:image/jpg;base64,${image}`;
      
      let orientation = "portrait";
      let maxH = 297;
      let maxW = 210;
      if (w > h) {
        console.log("landscape");
        orientation = "landscape";
        maxW = 297;
        maxH = 210;
      }
      var doc = new jsPDF({
        orientation: orientation,
        unit: "mm",
        format: 'a4'//[4, 2]' // size, can be 'a4' 297 210
      });
      /*{
        orientation: 'p', or 'l'
        unit: 'mm', 'p'
        format: 'a4', 
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
        }
      */
      //console.log("doc", doc);
        
      let wRation = w / maxW;
      let hRatio = h / maxH;
      if (wRation > hRatio) {
        w = maxW;
        h = h / wRation;
      } else {
        h = maxH;
        w = w / hRatio;
      }

      //console.log("pass dimensions");

      let xOffset = 0;
      let yOffset = 0;

      if (orientation == "landscape") {
        xOffset = (maxW - w) / 2;
        yOffset = (maxH - h) / 2;
      } else {
        xOffset = (maxW - w) / 2;
        yOffset = (maxH - h) / 2;
      }

      // addImage(imageData, format, x, y, width, height, alias, compression, rotation)  
      doc.addImage(image, 'JPEG', xOffset, yOffset, w, h);
      //console.log('added image', image);

//      doc.save('demo.pdf')

      resolve(doc.output('blob', filename));

    
    });


    const onSubmit = async (data, e) => {

      setLoading(true);
      const formData = new FormData();

      for (const key of Object.keys(multipleFiles)) {

        const file = multipleFiles[key];
        //console.log("File", file);

        // Check if file is an image and over 2mb
        if (file.type.match('image.*')) {

          //console.log("File is an image", file);

          let image = file;
          if (file.size > 2097152) {
            image = await resizeFile(file);
          } else {
            image = await new Promise((resolve, reject) => {
              var fr = new FileReader();  
              fr.readAsDataURL(file);
              fr.onload = () => {
                resolve(fr.result )
              };
              fr.onerror = reject;         
            });

          }
          let resizedImageFile = new File([
            new Blob([image])
          ], file.name, { type: file.type });

          //console.log(file.name + " size " + parseFloat(file.size/1000000) + " to " + parseFloat(resizedImageFile.size/1000000) + "mb");        
          
          let dims = await getImageDimensions(image);
          //console.log(dims);
          let PDFtemp = await generatePDF(image, dims.width, dims.height, file.name);

          //console.log("PDF", PDFtemp);

          formData.append('evidence', new File([PDFtemp], file.name.replace(/\.[^/.]+$/, "") + ".pdf", { type: 'application/pdf' }));
        
        } else {
        
          //console.log(file);
          formData.append('evidence', file);
        
        }
        
        
      }

      //console.log("End Form Data", formData);
      setFormError('');
      let response = API.postItems({timeout: 60, url: 'uploads', data: formData}).then(function (response) {
        setEndMessageIndex(0);
        setFormMessage(response.data.message);
        setMultipleFiles([]);
        setSizeMessage([]);
        setLoading(false);
      })
      .catch(function (error) {
        setFormError(error.message);
        if (error.response.status === 400) {
          setFormError(error.message + "\n" + "Session expired, please refresh the page and try again.");
        }
        setMultipleFiles([]);
        setSizeMessage([]);
        setLoading(false);
      });

    }

    // Functions to preview multiple images
    // TODO - bug when cancelling upload
    const changeMultipleFiles = (e) => {
      if (e.target.files) {

        const fileArray =  [];
        setSizeMessage([]);
        Array.from(e.target.files).forEach(function(file) 
          {
            if(file.size > 2097152){
              fileArray.push(file);

//              setSizeMessage((existingMessages) => existingMessages.concat(file.name));
            } else {
              fileArray.push(file);
            }
          }
        );

        // Combine file array with multipleFiles array
        let files = multipleFiles;
        files = files.concat(fileArray);
        setMultipleFiles(files);
      }
    };

    const removeFile = (filename) => {

        let files = multipleFiles;

        //console.log(files);

        files = files.filter(function( obj ) {
          //console.log(obj);
          return obj.name !== filename;
        });

          //console.log(files);
          setMultipleFiles(files);


    }

    const fileSize = (size) => {

      let mb = size / 1000000;
      let kb = size / 1000;

      let out = size + " b";
      if (mb > 1) {
        out = Math.round(mb*10)/10 + "mb";
      } else {
        out = Math.round(kb) + "kb"
      }

      return out
    }

    const render = (data) => {
      return  (
        <List spacing={4} pl={0} pb={4}>
            {data.map((file) => {
              return (
                
                <ListItem bg="bg-surface" p="4" boxShadow={mode('sm', 'sm-dark')} position="relative" borderRadius="lg" cursor="grab" >
                  <Flex justifyContent='space-between'>
                    <HStack>
                      <Avatar display='none' name={file.name} mr={1}/>
                      <Box>
                        <Text fontWeight="bold" textAlign='left'>{file.name.replace(/\.[^/.]+$/, "")}</Text>
                        <Text fontSize="sm" textAlign='left'>
                          <Badge display='none' pl={2} pr={2} mr={2} size='xs'>{file.name.split('.').pop()}</Badge>
                          <Badge display='none' pl={2} pr={2} size='xs'>{fileSize(file.size)}</Badge>
                        </Text>
                      </Box>
                    </HStack>
                    <Button variant="ghost" colorScheme='red' size="sm" onClick={() => removeFile(file.name)}>Remove</Button>
                  </Flex>
                </ListItem>
                
              )
            })}
          </List>
      )
    };




      // drag state
  const [dragActive, setDragActive] = React.useState(false);
  
  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  function endProcess() {
    setEndMessageIndex(0);
    setFormMessage(null);
    props.refresh();
    setShow(false);
  }

  function resetMessage() {
    setFormMessage(null);
  }

    return (
      <>
      <Button variant='outline' colorScheme='red' className='btn-8' onClick={() => setShow(true)}>
        Upload Documents
      </Button>
        { show  &&
          <Modal 
            hideClose={formMessage ? true : false}
            onClose={() => setShow(false)} 
            maxW='500px'
            MFA={true}
            footer={
              <>
                {formMessage &&
                  <Button colorScheme='blue' className='btn-8' variant='outline' onClick={() => endProcess()}>Return to Dashboard</Button>
                }

                {!loading && !formMessage &&
                  <>
                   
                   {multipleFiles.length !== 0 &&
                      <Wrap spacing={4}>
                        <Button variant='ghost' onClick={() => {setMultipleFiles([])}}>Clear All</Button>
                        <Button onClick={() => document.getElementById('hidden-submit').click()}  colorScheme='blue' variant='outline' type="submit">Upload {multipleFiles.length} {Pluralize( 'File', multipleFiles )}</Button>
                      </Wrap>
                  }
                  </>
                }
              
              </>
            }
          
          >
        <Box textAlign='center' p='0'>
          <Box mt={4} p='0'>
            <form onSubmit={handleSubmit(onSubmit, onError)}>

              {formError &&

                <Alert status="error" mb={4}>
                  <AlertIcon />
                  <AlertTitle mr={2}>Error!</AlertTitle>
                  <AlertDescription>{formError}</AlertDescription>
                  <CloseButton position="absolute" right="8px" top="8px" onClick={() => setFormError('')} />
                </Alert>
              }


              {(loading || formMessage) && 
                <>

                  <Box pb={4} >
                    <Stack spacing={4} ml={-4} mr={-4}>
                      
                      <Heading size='smalltitle' fontSize='32px' mb={0} >Process</Heading>
                      <Text  >This is a quick explaination of what we're doing now!</Text>

                      <Box
                        borderBottom='1px solid whitesmoke'
                        >
                          {loading ?
                            <Box className="loading" w='100%' borderBottom='1px solid whitesmoke' pb={4}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                            </Box>
                            :
                            <>
                              <Icon as={MdCheck} boxSize='64px' color="blue.500" />
                              <Text pb={8}>All files uploaded<br/>You can <Text as='span' color='blue.500' cursor='pointer' onClick={() => endProcess()}>close this window</Text>.</Text>
                              
                            </>
                          }
</Box>
  

                      {endMessage.map((message, index) => (
                        <HStack pl={4} pr={4} borderBottom='1px solid whitesmoke' pb={4}>
                          <Icon boxSize='32px' as={message.icon} color="blue.500" />
                          <Stack pl={3}>
                            <Text mb='0' align='left' fontWeight='bold'>{message.title}</Text>
                            <Text mt='0 !important' align='left' >{message.message}</Text>
                          </Stack>
                        </HStack>
                      ))}
                    </Stack>
                  </Box>
                  
                </>
              }


              { !loading && !formMessage &&
                <>

                  <Heading as='h4' fontSize='xl'>Choose your documents</Heading>
                  <Dropzone cursor='pointer' 
                      _hover={{border: '1px solid #4DA9F2'}} mb={4} onDragEnter={handleDrag} 
                      onSubmit={(e) => e.preventDefault()} filesize='2mb' filetypes='Any images or PDFs'>
                    <Input
                      type="file"
                      name="evidence"
                      multiple
                      onDrop={function() {console.log("dropped")}}
                      onChange={changeMultipleFiles}
                      h="100%"
                      w="100%"
                      position="absolute"
                      top="0"
                      left="0"
                      opacity="0"
                      accept="image/*,application/PDF"
                      cursor='pointer'
                    /> 
                  </Dropzone>

                  {(sizeMessage.length !== 0) &&
                    <>
                      <Text size='sm' mb={4}>
                        These files were too large:
                        <br/>{
                        sizeMessage.join(", ")}</Text>
                    </>
                  }

                  {errors.files && <p className="error">Please select at least one file</p>}
                  
                  {!loading && !formMessage &&
                  <>
                   
                   {multipleFiles.length > 4 &&
                    <Flex w='fill-avialable' justifyContent='space-between' mb={12} mt={14}>
                      <Button color='black' variant='ghost' onClick={() => setShow(false)}>Close</Button>
                      <Wrap spacing={4}>
                        <Button variant='ghost' onClick={() => {setMultipleFiles([])}}>Clear All</Button>
                        <Button onClick={() => document.getElementById('hidden-submit').click()}  colorScheme='blue' type="submit">Upload {multipleFiles.length} {Pluralize( 'File', multipleFiles )}</Button>
                      </Wrap>
                    </Flex>
                  }
                  </>
                  }
                  {render(multipleFiles)}
                  <Button display='none' id='hidden-submit' type="submit">Upload Hidden</Button>


                </>
              }

            </form>
          </Box>
        </Box>
        </Modal>
        }
      </>

    )
}
