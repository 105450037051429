import React, { useState, useEffect, useContext } from 'react';
import { Image, useToast, Menu, MenuButton, MenuList, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuItem, color } from '@chakra-ui/react';
import API from "../../../core/utils/API";
import {MdAttachFile, MdAdd, MdAutoFixHigh,  MdFlag, MdInsertLink, MdWarning, MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility, MdPhone, MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdMail, MdContentCopy } from "react-icons/md";
import LoadingCircle from '../../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../../static/layout/Loading';
import Splash from '../../../static/layout/Splash';
import Layout from '../../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../../auth/SubscriptionContext';
import Modal from '../../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../../core/components/lists/List';
import pluralize from 'pluralize';
import { BsTags } from 'react-icons/bs';
import InputWrapper from '../../../core/components/forms/inputs/InputWrapper';
import { random } from 'sjcl';

import { set } from 'date-fns';

import DirectAwardCompanies from './../demo/DirectAward';

function Companies(props) {

    return (

        <Box p={10} pb={2}>

            {DirectAwardCompanies.map((company, index) => {
                    
                    return (
    
                        <HStack key={index} pb={8}
                            cursor='pointer'
                            _hover={{bg: 'gray.50'}}
                            role='group'
                            >
                            
                            <Image src={company.logo}  boxSize='60px' objectFit='contain' />
                            <Stack spacing={0} pl={4}>
                                <Text fontWeight='600'>{company.company_name}</Text>
                                <Text  color='subtle'>{company.description}</Text>
                                <Wrap pt={2}>
                                    <Tag >
                                        {company.website}
                                    </Tag>
                                    <Tag >
                                        {company.contact_email}
                                    </Tag>
                                </Wrap>
                            </Stack>
    
                        </HStack>
    
                    )
            })}

        </Box>

    )

}

export default function DirectAward(props) {

    // Set timeout to simulate loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
            
            setTimeout(() => {
    
                setLoading(false);
    
            }, 3000)
    
        }
    , []);


    return (

        <Box>

            {loading ? <Box pt={8} textAlign='center'>
                <LoadingCircle message='Checking for relevant providers'/>
                <Text maxW='200px' fontSize='sm' m='auto'>Searching for relevant providers</Text>
            </Box> : null}
            {!loading ? <Companies /> : null}
        </Box>

    )

}