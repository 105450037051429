import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Image, Menu, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, MenuOptionGroup } from '@chakra-ui/react';
import API from "../../../core/utils/API";
import {MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos, MdList } from "react-icons/md";
import LoadingCircle from '../../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../../static/layout/Loading';
import Splash from '../../../static/layout/Splash';
import Layout from '../../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../../auth/SubscriptionWrapper';
import Modal from '../../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../../core/components/lists/List';
import pluralize from 'pluralize';
import InputWrapper from '../../../core/components/forms/inputs/InputWrapper';
import ClientLink from '../ClientLink';

import AutoForm from '../../../core/models/AutoForm';

import GoogleMap from '../../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import e from 'cors';

import FileView from '../../../core/components/files/View';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { set } from 'date-fns';
import Card from '../../../core/components/cards/Card';

import AccountsBankDetails from './AccountsBankDetails';
import AccountsDueOptions from './AccountsDueOptions';
import AccountsPDFOptions from './AccountsPDFOptions';
import AccountsTradeshiftKeys from './AccountsTradeshiftKeys';
import AccountsPlatformKeys from './AccountsPlatformKeys';

export default function AccountSettings(props) {


    return (

        <Layout 
            layout='dense'
            title='Account Settings'
            description='You can update your account settings here.'
            >
            

            <GridItem colSpan='2'>

                <Card>
                    <Heading size='sm'>Bank details</Heading>
                    <Text mb={4} pt={2}>This information will be displayed in all Tradeshift invoices as the accepted payment method.</Text>
                    <AccountsBankDetails />
                </Card>

            </GridItem>
            

            <GridItem colSpan='2'>

                <Card>
                    <Heading size='sm'>Due dates</Heading>
                    <Text mb={4} pt={2}>It is possible to override the default due date provided by your accountany platform.</Text>
                    <AccountsDueOptions />
                </Card>

            </GridItem>

            <GridItem colSpan='2'>
                    
                    <Card>
                        <Heading size='sm'>PDF options</Heading>
                        <Text mb={4} pt={2}>You can require a PDF invoice for all transactions.</Text>
                        <AccountsPDFOptions />
                    </Card> 

            </GridItem>

            <GridItem colSpan='2'>

                <Card>
                    <Heading size='sm'>API keys</Heading>
                    <Text mb={4} pt={2}>You can update your API keys here.</Text>
                    <AccountsTradeshiftKeys />
                </Card>

            </GridItem>

            <GridItem colSpan='2'>
                    
                    <Card>
                        <Heading size='sm'>Platform keys</Heading>
                        <Text mb={4} pt={2}>You can update your platform keys here.</Text>
                        <AccountsPlatformKeys />
                    </Card>

            </GridItem>


            
        </Layout>

    )
}