
/* 

Websocket chat agent


*/
import {Icon,Image,  HStack, Circle, IconButton, Flex, Heading, Tag, Stack, Box, Text, Button, Input, color, Avatar} from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from "react";
//const socket = new WebSocket('wss://0q8ebkr7lf.execute-api.eu-west-2.amazonaws.com/production/');
import useWebSocket, { ReadyState } from "react-use-websocket"
// need to send messages as {"action": "sendmessage", "message": "hello, everyone!"}
import axios from "axios";
import { MdSend } from 'react-icons/md';
import { motion } from 'framer-motion';

import LoadingCircle from '../../static/layout/LoadingCircle';
// Use this approach for development/testing only

export function PyrexiaAgent() {

    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState([])
    const [sending, setSending] = useState(false)
    const [data, setData] = useState("")
    
    async function sendMessage(message) {

        message = message.trim();

        // Check if message is empty
        if (message === "") {
            return;
        }

        setSending(true);
    
        // get messages in tmp
        let tmpMessages = [...messages];
        tmpMessages.push({
            "role": "user",
            "content": [
                {
                    "type": "text",
                    "text": message
                }
            ]
        });
        setMessages(tmpMessages);
        document.getElementById('message-input').value = "";
    
        let data = {
            "action": "sendmessage",
            "encryption": false,
            "messages": tmpMessages
        }
    
        console.log("Sending message", JSON.stringify(data));
        try {
            const response = await fetch('https://hhjqfu5edcxh72x44ygk7cjoqi0ukgau.lambda-url.eu-west-2.on.aws/', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
    

            
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            } else {
                console.log("Response OK", response);
            }
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let receivedText = '';

            console.log("about to process stream");
            console.log("Response", response);
    
            const processStream = async () => {
                console.log("Processing stream");
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        console.log('Stream complete');
                        break;
                    }
                    const chunk = decoder.decode(value, { stream: true });
                    receivedText += chunk;
                    setData(receivedText);
    
                    // Add new chunk to assistant's message
                    tmpMessages = [...tmpMessages]; // Create a new array for state update
                    // Check if the last message is from the assistant
                    if (tmpMessages.length > 0 && tmpMessages[tmpMessages.length-1].role === "assistant") {
                        // Add to the last message
                        tmpMessages[tmpMessages.length-1].content[0].text += chunk;
                    }
                    else {
                        // Add a new message
                        tmpMessages.push({
                            "role": "assistant",
                            "content": [
                                {
                                    "type": "text",
                                    "text": chunk
                                }
                            ]
                        });
                    }
                    setMessages(tmpMessages);
                }
            };
    
            await processStream();
    
            setSending(false);
    
        } catch (error) {
            console.error('Error:', error);
            setData('');
            setSending(false);
        }
    }
    async function sendMessageWORKING(message) {

        setSending(true);

        // get messages in tmp
        let tmpMessages = messages;
        tmpMessages.push({
            "role": "user",
            "content": [
                {
                    "type": "text",
                    "text": message
                }
            ]
        });
        setMessages(tmpMessages);
        document.getElementById('message-input').value = "";


        
        let url = "https://hhjqfu5edcxh72x44ygk7cjoqi0ukgau.lambda-url.eu-west-2.amazonaws.com";
        let data = {
            "action": "sendmessage",
            "encryption": false,
            "messages": tmpMessages
        }
//        postDataAndStream(url, data);

        console.log("Sending message", JSON.stringify(data));
        try {
            /*
            const response = await fetch('https://hhjqfu5edcxh72x44ygk7cjoqi0ukgau.lambda-url.eu-west-2.on.aws/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                mode: 'no-cors',
                body: JSON.stringify(data) // replace with your data
            });
            */
            
            const response = await fetch('https://hhjqfu5edcxh72x44ygk7cjoqi0ukgau.lambda-url.eu-west-2.on.aws/', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => {
                console.log("Response", response);
                return response.text();
            }).then(data => {
                console.log("Data", data);
                setData(data);
                return data
            });


            tmpMessages.push({
                "role": "assistant",
                "content": [
                    {
                        "type": "text",
                        "text": response
                    }
                ]
            });
            setMessages(tmpMessages);
            setData('');

            console.log("Response", response, tmpMessages);
           
            
            setSending(false);
            
        } catch (error) {
            console.error('Error:', error);
            setData('');

            setSending(false);
        }

    }


    return (
        <Flex 
            justifyContent='space-between'
            flexDirection='column'
            w='100%'
            h='100%'
            // create a texture background
            >

            <HStack spacing={2} flexDirection='row' justifySelf='flex-start' alignItems='center' justifyContent='flex-start' 
                p={2} bg='black' borderColor='gray.200'
                boxShadow='lg'
                >    
                <Circle boxSize='32px' className='gradient-background' 
                    bg={sending ? 'white' : 'white'} color='black' p={2} boxShadow='md'
                    transition='background-color 0.5s'
                    >
                        <Box as={motion.div}
                            // Make this spin when sending, continuously
                            key={sending}
                            /*
                            animate={{
                                // pause animatino when not sending
                                rotate: sending ? 360 : 0,
                                //scale: sending ? 1.1 : 1,
                                transition: {
                                    duration: 2,
                                    repeat: Infinity
                                }
                            }}
                            */
                            >
                            <Image src='/favicon/LogoOnly.png' w='24px'

                                // Invert colouring when sending
                                //filter={sending ? 'invert(1)' : ''}
                            />
                        </Box>

                </Circle>
                <Text fontSize='sm' color='white'>Swift</Text>
            </HStack>

            <Stack p={2} 
                bg='gray.50'
                spacing={4}
                overflowY='auto'
                justifySelf='flex-end'
                // Default load scrolled to the top of the last child
                // This has the id message-0, message-1, etc.
                // This is the last message, i.e. message-{messages.length-1}
                // The id of the last message is message-{messages.length-1}
                // Scroll to the top of the last message


                // Start by scrolling to bottom
                ref={el => el && el.scrollTo(0, el.scrollHeight)}




                >
                {messages.map((message, index) => {

                    // Get message
                    // Loop through content until we get type text
                    let messageContent = "";
                    for (let i = 0; i < message.content.length; i++) {
                        if (message.content[i].type === "text") {
                            messageContent = message.content[i].text;
                            break;
                        }
                    }

                    // split message into lines
                    let lines = messageContent.split("\n");

                    return (
                        <Box key={"message-"+index}
                            id={"message-"+index}
                            alignSelf={message.role === "user" ? 'flex-end' : 'flex-start'} 
                            bg={message.role === "user" ? 'blue.100' : 'gray.100'}
                            maxW='80%'
                            borderRadius='md'
                            p={2}
                            boxShadow='md'
                            >
                            <Text color='subtle' fontSize='xs'
                                pb={1}
                                lineHeight='1'  
                                >
                                {message.role === "user" ? "You" : "Swift"}
                            </Text>
                            <Stack spacing={1}>
                                {lines.map((line, index) => {
                                    return (
                                        <Text key={line+"-"+index}
                                            fontSize='sm'
                                        >
                                            {line}
                                            </Text>
                                    )
                                })}

                            </Stack>
                        </Box>
                    )
                })}

                {sending ? 
                    <Box
                        alignSelf='flex-start'
                        >
                        <LoadingCircle />
                    </Box>
                    :
                    <Box alignSelf='flex-end' p={2} pb={0}>
                        {messages.length !== 0 &&
                            <Text color='subtle' fontSize='xs'
                                onClick={() => {
                                    setMessages([])
                                }}
                                _hover={{color: 'blue.500'}}
                                cursor='pointer'
                                >
                                Clear chat
                            </Text>
                        }
                    </Box>
                }

                <Box key={data.length} display='none'>
                    <Text>
                        {data}
                    </Text>
                </Box>
            </Stack>


            <Flex p={2} bg='white' borderTop='1px solid black' borderColor='gray.200'
                boxShadow='lg'
                >
                <Input id='message-input' placeholder="Type your message here" 
                    type='textarea'
                    border='none'
                    size='sm'
                    _hover={{border: 'none', bg: 'none'}}
                    onKeyPress={(e) => {
                        // if key is enter then click send-button
                        if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
                            document.getElementById("send-button").click();
                        }
                    }}
                    isDisabled={sending}
                    defaultValue='Tell me a long story'
                    />
                <IconButton 
                    id='send-button'
                    onClick={() => {
                        sendMessage(document.getElementById('message-input').value);
                    }}
                    colorScheme='blue'
                    isLoading={sending}
                    size='sm'
                    variant='ghost'
                    icon={<MdSend   />}
                    />
            </Flex>
        </Flex>
    )
}


export default function TestPage() {
    return (
        <Flex
            position='absolute'
            top='0'
            bottom='0'
            left='0'
            right='0'
            justifyContent='center'
            alignItems='center'
            >

            <Box w='300px' h='500px'
                position='relative'
                maxW='300px'
                boxShadow='xl'
                borderRadius='md'
                borderWidth='1px'
                >
                <PyrexiaAgent />
            </Box>



        </Flex>
    )
}