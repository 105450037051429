import {Button, Icon} from '@chakra-ui/react';
import { MdArrowForward } from 'react-icons/md';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from "react-router-dom";

export default function TryNowButton(props) {

  const navigate = useNavigate;
  const [loggedIn, setLoggedIn] = useState(null);

    let bg = 'linear-gradient(to left, #fb594b, #5321bf)';
    if (props.variant === 'transparent') {
      bg = 'transparent'
    }

    
    function destination () {

      if (document.getElementById("signupButton") !== null) {
        document.getElementById("signupButton").click();
      } else if (document.querySelector("[href='/signup']") !== null) {
        document.querySelector("[href='/signup']").click();
      } else {
        document.getElementById("defaultNavLink").click();
      }
    }


    return (

        <Button
        className='call-to-action-btn'
        borderRadius='24px'
        pl={6}
        pr={6}
        mt={6} mb={4}
        zIndex='200'
        cursor='pointer'
        background={bg+"!important"}
        onClick={() => {destination();}}
      >
        Try now - for free!
        <Icon as={MdArrowForward} ml="2" />
        {loggedIn !== null &&
          <>
            {loggedIn ?
              <Navigate to="/onboarding?forward=true" replace={true} />
              :
              <Navigate to="/signup" replace={true} />
            }
          </>
        }
      </Button>
    )
}