import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Image, Menu, MenuList, MenuItem, CircularProgress, CircularProgressLabel, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, MenuOptionGroup, border } from '@chakra-ui/react';
import API from "../../../core/utils/API";
import {MdAttachFile, MdOutlineErrorOutline, MdWarning, MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos, MdList, MdInfoOutline, MdRefresh } from "react-icons/md";
import LoadingCircle from '../../../static/layout/LoadingCircle';
import Loading from '../../../static/layout/Loading';
import Layout from '../../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import Modal from '../../../core/components/modal/Modal';
import pluralize from 'pluralize';
import InputWrapper from '../../../core/components/forms/inputs/InputWrapper';

import demoBids from '../demo/Bids';
import Card from '../components/Card';

import People from '../demo/People';

let colours = {
    'error': 'red.500',
    'inprogress': 'yellow.500',
    'completed': 'green.500',
    'locked': 'gray.500',
  }

  let colourSchemes = {
    'error': 'red',
    'inprogress': 'yellow',
    'completed': 'green',
    'locked': 'gray',
  }

  let highlightColours = {
    'error': 'red.50',
    'inprogress': 'yellow.50',
    'completed': 'green.50',
    'locked': 'gray.50',
  }


export default function Conflicts() {

    const [selected, setSelected] = useState({});


    return (

        <SimpleGrid columns={{base: 1, md: 3}} spacing={10} pt={0}>

        <GridItem colSpan={2}
        pb={20}
        h='fill-available'>
            <Heading size='sm' pb={10} color='blue.500'>
                Conflicts
            </Heading>

            <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing={4} pb={12}>

                {Object.keys(People).map((person, index) => {

                    let conflictKey = People[person].conflict ? 'inprogress' : 'completed';
                    let conflictOrder = People[person].conflict ? 1 : 2;
                    if (People[person].conflict === 'high') {
                        conflictKey = 'error';
                        conflictOrder = 0;
                    }

                    return (

                        
                        <Card
                            p={4}
                            borderRadius='md'
                            _hover={{   
                                cursor: 'pointer',
                                bg: highlightColours[conflictKey]
                            }}
                            borderBottom='3px solid white'
                            borderBottomColor={colours[conflictKey]}
                            bg={People[person] === selected ? highlightColours[conflictKey] : 'white'}
                            role='group'
                            order={conflictOrder}
                            w='100%'
                            display='flex'
                            onClick={() => {
                                setSelected(People[person]);
                            }}
                            >
                            
                            <Avatar size='md' src={People[person].url} mr={4} />
                            <Stack w='100%'>
                                <Heading size='label'>
                                    {People[person].name}
                                </Heading>
                                <Flex justifyContent='space-between' w='100%'>
                                
                                    <Tag size='sm' px={2}
                                    variant='outline'
                                    colorScheme={colourSchemes[conflictKey]}
                                    >
                                    {People[person].conflict ? 'Conflict' : 'No Conflict'}
                                </Tag>
                                
                                </Flex>
                            </Stack>
                        </Card>
                    )
                })}
            </SimpleGrid>
            </GridItem>

            <GridItem colSpan={1}>
                <Card>
                    <Heading size='sm' pb={10}>
                        Details
                    </Heading>

                    {selected.name &&
                    
                        <Box>

                            <Flex pb={10}>
                                <Avatar size='lg' src={selected.url} mr={4} />
                                <Stack  spacing={0}>
                                    <Text color='black' fontWeight='600'>
                                        {selected.name}
                                    </Text>
                                    <Text>
                                        {selected.role}
                                    </Text>
                                </Stack>
                            </Flex>

                            {!selected.conflict &&


                                    <Flex>

                                    <Circle boxSize='64px' bg={'green.500'} color='white' mr={4}>
                                        <Text fontSize='md' color='white' lineHeight='1em'>
                                            NONE
                                        </Text>
                                    </Circle>

                                    <Stack spacing={0}>
                                        <Text color='black' fontWeight='600'>
                                           No conflicts
                                        </Text>

                                        <Text mt={0}>
                                            Our searches revealed no conflicts of interest.
                                        </Text>

                                    </Stack>
                                    </Flex>

                            }

                            {selected.conflicts && Object.keys(selected.conflicts).map((conflict, index) => {

                                let conflictKey = selected.conflicts[conflict].conflict ? 'inprogress' : 'completed';

                                if (selected.conflicts[conflict].conflict === 'high') {

                                    conflictKey = 'error';

                                }

                                return (
                                    <Flex>

                                        <Circle boxSize='64px' bg={selected.conflicts[conflict].conflict === 'high' ? 'red.500' : 'yellow.500'} color='white' mr={4}>
                                            <Text fontSize='md' color='white' lineHeight='1em'>
                                                {selected.conflicts[conflict].conflict.toUpperCase()}
                                            </Text>
                                        </Circle>

                                        <Stack spacing={0}>
                                            <Text color='black' fontWeight='600'>
                                                {selected.conflicts[conflict].name}
                                            </Text>

                                            <Text>
                                                Role: {selected.conflicts[conflict].role}
                                            </Text>

                                            <Text>
                                                {selected.conflicts[conflict].description}
                                            </Text>

                                        </Stack>
                                    </Flex>
                                )

                            }
                            )}
                                

                        </Box>
                    }

                </Card>
            </GridItem>

        </SimpleGrid>


    )

}