/*

    General page for displaying files - PDF, images.
    Option to include an area for editing associated models etc.

*/

import React, { useState, useEffect } from 'react';
import { Stack, Box, Button, Flex, Text, Grid, GridItem, Image, Link } from '@chakra-ui/react';
import Loading from '../../../static/layout/LoadingCircle';
import FullPageModal from '../modal/FullPageModal';

export default function View(props) {

    let [file, setFile] = useState(null);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        fetchFile();
    }
    , []);

    function fetchFile() {

        // Load test PDF from ./public/files
        fetch('/files/' + props.filename + '.pdf')
            .then(response => response.blob())
            .then(blob => {
                let url = URL.createObjectURL(blob);
                setFile({
                    name: 'Test PDF',
                    description: 'This is a test PDF file',
                    url: url
                });
                setLoading(false);
            });
/*
        fetch(`/api/files/${props.match.params.id}`)
            .then(response => response.json())
            .then(data => {
                setFile(data);
                setLoading(false);
            });
            */
    }

    if (loading) {
        return (
            <FullPageModal margin={true}>
                <Loading />
            </FullPageModal>
        )
    }

    return (
        <FullPageModal margin={true}>
            <Stack w='100%' h='100%' spacing={0}>
                <iframe src={file.url} width='100%' height='100%' />
                <Flex justify='space-between' align='center' p={4}>
                    <Button colorScheme='blue'>Approve</Button>
                    <Button colorScheme='' onClick={() => {props.onClose();}}>Close</Button>
                </Flex>
            </Stack>
        </FullPageModal>
    );

}
