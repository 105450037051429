import {
    Avatar,
    AvatarGroup,
    Box,
    Badge, 
    Button,
    Heading,
    Input, 
    Container,
    Flex,
    List,
    Image,
    SimpleGrid,
     
    Grid, GridItem,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Divider,
    HStack,
    Link,
    Icon,
    Checkbox, 
    CheckboxGroup,
    RadioGroup,
    Radio,
    Wrap,
    Tab, TabList, TabPanels, TabPanel, Tabs,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td, TableCaption,
    FormControl, FormLabel, FormHelperText, FormErrorMessage, ModalFooter,
  } from '@chakra-ui/react';
  import { RadioButton, RadioButtonGroup } from '../core/components/forms/RadioButtonGroup'
  import * as React from 'react';
import Error from '../core/utils/Error';
  import Modal from '../core/components/modal/Modal';
  import Select from 'react-select';
  import makeAnimated from 'react-select/animated';
  import { useState, useEffect } from 'react';
  import { useForm, Controller } from 'react-hook-form';
import BasicBanner from '../core/components/banners/BasicBanner';
  
  import {Toggle} from '../core/components/forms/RadioButtonGroupRHF';
import secureLocalStorage from 'react-secure-storage';

import API from '../core/utils/API';
  import { Dropzone } from '../core/components/forms/Dropzone';
import { set } from 'date-fns';
  const animatedComponents = makeAnimated();

  export default function Invite(props) {

    let [showModal, setShowModal] = React.useState(false);

    const { handleSubmit, errors, register, formState, control, reset } = useForm();

    const [formMessage, setFormMessage] = useState("");
    let [requirementGroups, setRequirementGroups] = useState([]);
    let [loading, setLoading] = useState(true);
    let [organisationUuid, setOrganisationUuid] = useState("");


    React.useEffect(()=>{
      async function getRequirementGroups(){

        // Check if requirement groups passed as props
        if (props.requirementGroups) {
          console.log("Requirement groups passed as props", props.requirementGroups);
          setRequirementGroups(props.requirementGroups);
          setLoading(false);
          return;

        }
          try {


              const url = 'requirementgroups/list';
              let response = await API.getItems({url});
              setRequirementGroups(response.data.requirementGroups);

              setLoading(false);
          } catch (error) {

              setLoading(false);
          }
      }

      // Get organisationUuid
      setOrganisationUuid(secureLocalStorage.getItem('organisationUuid'));

      getRequirementGroups();
    },[]);


    
  const handleClose = () => {
      setShowModal(false);
  }
  const handleShow = () => {
      setShowModal(true);
  };
    const onSubmit = (data, e) => {

      data.type = props.type;
      data.organisationUuid = organisationUuid;
      console.log("Invite organisationUuid", organisationUuid);

      const response = API.postItems({url: 'access', data})
      .then(function (response) {

        //setFormMessage("Access request created successfully");
          // Reset inputs
  //  window.location.reload(false);
          reset(); // This will reset the form
        props.refresh();
        setShowModal(false);
      })
      .catch(function (error) {

        //setFormMessage("Error creating access request");
      });
    }

    // Set text variables
    let title = "Share your records";
    let emailExplaination = "Enter the e-mails of the professional you would like to share your records with.";
    let recordsExplaination = "Select which records you would like to share";
    let durationExplaination = "How long should the professional have access";
    if (props.type == 'request') {
      title = "Request access to records";
      emailExplaination = "Enter the e-mails of the professional you would like to request access to their records from.";
      recordsExplaination = "Select which records you would like to request access to";
      durationExplaination = "How long do you need access for";
    }


    return (

        <>
          <Button variant='outline' colorScheme='blue' className='btn-8' onClick={() => {handleShow()}}>
            {props.type == 'invite' && 'Share Records'}
            {props.type == 'request' && 'Request Access'}
          </Button>

          {showModal &&
                          <form onSubmit={handleSubmit(onSubmit)}>

            <Modal 
              MFA={true} 
              title={title} 
              onClose={handleClose}
              footer={
                <Button size='sm' variant='outline' colorScheme='blue' className='btn-8' type="submit" onClick={handleSubmit(onSubmit)}>
                {props.type == 'invite' && 'Share'}
                {props.type == 'request' && 'Request'}
                </Button>
              }
              >

            <Box>   


              {formMessage && 
                <BasicBanner status="success" variant="solid" mb={4} message={formMessage} />
              }             


                    
                <Stack spacing={8} pt={4} pb={8}>
                    <FormControl >
                        <Input placeholder='Professional e-mail' {...register("email")} required />
                        <FormHelperText>{emailExplaination}</FormHelperText>
                    </FormControl>
  

                        <FormControl>
                          <Text mb={2}>Records</Text>
                          <FormHelperText mb={4}>{recordsExplaination}</FormHelperText>
                          <Controller
                            name="requirementGroupUuids"
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                              <CheckboxGroup required {...rest} 
                                defaultValue={requirementGroups.length === 1 ? 
                                  requirementGroups.map((group) => group.uuid)
                                  : []}
                                >
                                <Stack direction="column" spacing={4}>
                                  {requirementGroups.length === 0 ?
                                    <Error message='No options found'/>
                                    :
                                    <>
                                      {requirementGroups.map((option) => (
                                        <Checkbox key={option.uuid} value={option.uuid}
                                          // if requirementGroups length is 1, check the box by default
                                          isChecked={requirementGroups.length === 1}

                                          >
                                          {option.title}
                                        </Checkbox>
                                      ))}
                                    </>
                                  }
                                </Stack>
                              </CheckboxGroup>
                            )}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel mb={2}>Duration</FormLabel>
                          <FormHelperText mb={4}>{durationExplaination}</FormHelperText>
                          <Controller
                            name="duration"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <RadioButtonGroup required size='sm' onChange={onChange}>
                                  <RadioButton value="6">6 months</RadioButton>
                                  <RadioButton value="12">12 months</RadioButton>
                                  <RadioButton value="-1">Indefinite</RadioButton>
                                </RadioButtonGroup>
                              )}
                          />
                        </FormControl>

                        </Stack>



            </Box>

            </Modal>
            </form>

          }
        </>
    )
  }