"use strict";
/* eslint-disable react-hooks/rules-of-hooks */
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@chakra-ui/react");
var theme_tools_1 = require("@chakra-ui/theme-tools");
var baseStyle = {
    table: {
        fontVariantNumeric: 'lining-nums tabular-nums',
        borderCollapse: 'collapse',
        width: 'full',
    },
    caption: {
        color: 'default',
        mt: 4,
        fontFamily: 'heading',
        textAlign: 'center',
        fontWeight: 'medium',
    }
};
var variantSimple = {
    table: {
        bg: 'none !important'
    },
    th: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '24px',
        height: 14,
        border: 'none',
        borderBottom: '1px solid #000 !important',
    },
    td: {
        color: 'default',
        borderBottom: '1px solid whitesmoke !important',
        background: 'none !important',
    },
    tr: {
        background: 'none !important',
    }
};
var variantStripe = function (props) { return ({
    table: {
        bg: 'none !important'
    },
    tr: {
        borderBottom: '1px solid black'
    },
    th: {
        bg: 'bg-subtle',
        color: 'default',
        fontWeight: 'bold',
        fontSize: 'md',
        height: 14,
    },
    td: {
        color: 'default',
        borderBottom: '1px solid black',
    },
    tbody: {
        td: {
            bg: 'unset',
        },
        tr: {
            background: (0, theme_tools_1.mode)('red.500', 'blue.500')(props),
            '&:nth-of-type(odd)': {
                'th, td': {
                    bg: "".concat((0, react_1.useColorModeValue)((0, react_1.useToken)('colors', 'white'), (0, react_1.useToken)('colors', 'gray.950')), " !important"), // TODO
                },
            },
            '&:nth-of-type(even)': {
                'th, td': {
                    bg: 'bg-subtle',
                    borderBottomWidth: 'unset',
                },
                td: {},
            },
        },
    },
}); };
var variants = {
    simple: variantSimple,
    striped: variantStripe,
};
exports.default = {
    baseStyle: baseStyle,
    variants: variants,
};
