import React, {useState, useEffect, useContext} from 'react';
import {Box, Badge, GridItem, Button, Stack, HStack, Flex, Heading, Input, Text, useToast} from '@chakra-ui/react';
import API from '../../core/utils/API';
import Layout from '../../core/layout/Layout';
import PricingCard from './PricingCard';
import Product from './Product';
import moment from 'moment';
import { SubscriptionContext } from '../../auth/SubscriptionContext';
import { set } from 'date-fns';
export default function Subscribe() {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState('');
    const [coupon, setCoupon] = useState('');
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [stripeSubscriptions, setStripeSubscriptions] = useState([]);
    const [error, setError] = useState(null);
    const [highestSubscription, setHighestSubscription] = useState({});
    const [allSubscriptions, setAllSubscriptions] = useState([]);
    const [showMore, setShowMore] = useState(true);

    const [localSubscriptions, setLocalSubscriptions] = useState([]); 
    const [payUrlOptions, setPayUrlOptions] = useState('');


    const liveSubscriptions = React.useContext(SubscriptionContext);
    console.log('liveSubscriptions', liveSubscriptions);  

    function switchShowMore() {
        setShowMore(!showMore);
    }


    useEffect(() => {
        if (user.email) {
            setPayUrlOptions('?prefilled_promo_code=NONE&prefilled_email=' + user.email + 'client_reference_id=' + user.uuid);
        }
    }, [user]);
    
    useEffect(()=>{
        async function getUser(){


            try {


                const response = await API.getItems({url: 'users/me'});
                const a = (response.data['me']);
                setUser(a);
                console.log(a);

                
                if (a.stripeCustomerId != null) {
                    console.log("user already has stripe id");
                    setStripeCustomerId(a.stripeCustomerId);

                    const response2 = await API.getItems({
                        url: 'users/getStripeSubscriptions',
                        config: {
                            params: {
                                userUuid: a.uuid
                            }
                        }
                    });
                    const b = (response2.data);
                    console.log(b);

                    setStripeSubscriptions(b.stripeSubscriptions);
                    setSubscriptions(b.localSubscriptions);

                    // Cycle through each subscription
                    // Check if it is active
                    // If it is active, check if it is cancelled
                    // If it is cancelled, check if it is past the end date
                    // If it is past the end date, check if it is past the end date + 30 days
                    let activeCount = 0;
                    let endingCount = 0;
                    let highestsubscription = null;
                    let tmpAllSubscriptions = [];
                    b.localSubscriptions.forEach((subscription) => {

                        let currentRunning = false;
                        if (subscription.status === 'active' || subscription.status === 'trialing') {
                            activeCount++;
                            currentRunning = true;
                            tmpAllSubscriptions.push(subscription);
                        } else {
                            if (subscription.cancel_at_period_end) {
                                endingCount++;
                                currentRunning = true;
                            }
                        }

                        if (currentRunning) {
                            if (highestsubscription === null) {
                                highestsubscription = subscription;
                            } else {
                                if (subscription.plan_amount > highestsubscription.plan_amount) {
                                    highestsubscription = subscription;
                                }
                            }
                        }
                    });

                    console.log("tmpAllSubscriptions", tmpAllSubscriptions);

                    setAllSubscriptions(tmpAllSubscriptions);

                    console.log("activeCount", activeCount);
                    console.log("endingCount", endingCount);
                    console.log("highestsubscription", highestsubscription);
                    if (highestsubscription !== null) {
                        setHighestSubscription(highestsubscription);
                    }
                    setLoading(false);


                    return;
                }

                let ans = await checkStripeCustomerId(a.uuid);



                console.log("answer", ans);
                return;

            } catch (error) {
                setError(error);
                return;
            }
        }
        getUser()
    }, [])

    async function checkStripeCustomerId(uuid) {
            try {
                console.log('checkStripeCustomerId');
                const response = await API.getItems({
                    url: 'users/getStripeCustomerId',
                    config: {
                        params: {
                            uuid: uuid
                        }
                    }
                });
                const a = (response.data);
                
                console.log(a);
                setStripeCustomerId(a.stripeCustomerId);
                setLoading(false);
                return a;
            } catch (error) {
                setError(error);
                setLoading(false);

                return;
            }

        }
    


    return (
        <Layout
            title="Subscriptions"
            >

            <GridItem colSpan='6'>


            <Box display='none'>
                <Text>
                    E-mail: {user.email}
                </Text>
                <Text>
                    UUID: {user.uuid}
                </Text>
                <Text>
                    Stripe: {stripeCustomerId}
                </Text>
            </Box>


            <HStack spacing={{base: 0, md: 4}} alignItems='stretch' pt={4} flexDirection={{base: 'column-reverse', md: 'row'}}>

            {!loading &&

            <>
                    <PricingCard
                        showMore={showMore}
                        switchShowMore={() => {switchShowMore()}}
                        highestSubscription={highestSubscription}
                        allSubscriptions={allSubscriptions}
                        product={{
                            name: 'Free Trial',
                            description: 'All access pass for everyone after they register',
                            price: 'FREE',
                            price_addendum: '',
                            deal: 'Free trial',
                            features: [
                                'Analyse 3 Work Schedules',
                                'Generate 5 Teaching Sessions',
                                'Complete access to Digital Staff Passport',
                                'Unlimited documents', 
                                'Unlimited sharing', 
                                'Artificial Intelligence Text Detection', 
                                'Natural Language Processing to extract key information', 
                                'Automatic tagging and sorting', 
                            ],
                        }}
                    />

                <Box display='none'>
	
                <PricingCard
                    userUuid={user.uuid} 
                    stripeCustomerId={stripeCustomerId} 
                    priceId='price_1NlVqHCpM5K88EcG5S42yUSU'
                    highestSubscription={highestSubscription}
                    allSubscriptions={allSubscriptions}
                    showMore={showMore}
                    switchShowMore={() => {switchShowMore()}}
                    product={{
                        name: 'Pay',
                        description: 'Our pay package, allowing AI analysis of work schedules',
                        price: '£0.83',
                        price_addendum: ' / per month',
                        deal: 'Free trial for 6 months',
                        features: [
                            'Up to 8 work schedules per year', 
                            'AI rota extraction', 
                            'AI pay analysis', 
                            'Prospective pay underpayment screening', 
                            'Full calculations and reports', 
                            'LTFT, Flexible Pay Premia compatible'
                        ],
                        buttonText: 'Subscribe',
                        buttonVariant: 'outline',
                        buttonUrl: 'https://buy.stripe.com/4gw3dkgFw9U6gda8wz' + payUrlOptions
                    }}
                />

                    <PricingCard
                        userUuid={user.uuid} 
                        stripeCustomerId={stripeCustomerId} 
                        priceId='price_1Nm0eOCpM5K88EcGAsGuInu3'
                        highestSubscription={highestSubscription}
                        allSubscriptions={allSubscriptions}
                        showMore={showMore}
                        switchShowMore={() => {switchShowMore()}}
                        product={{
                            name: 'Essentials',
                            description: 'Our Essentials personal plan, all for less than one coffee a month.',
                            price: '£1.50',
                            price_addendum: ' / per month',
                            deal: 'Free trial for 6 months',
                            features: [
                                'Up to 15 pages', 
                                '5 shares', 
                                'Artificial Intelligence Text Detection', 
                                'Natural Language Processing to extract key information', 
                                'Automatic tagging and sorting', 
                                'Complete Digital Staff Passport',
                                'Access to all pay package features',
                            ],
                            buttonText: 'Subscribe',
                            buttonVariant: 'outline',
                            buttonUrl: 'https://buy.stripe.com/9AQg06dtkeamaSQ4gm' + payUrlOptions
                        }}
                    />
                </Box>


                <PricingCard
                    userUuid={user.uuid} 
                    stripeCustomerId={stripeCustomerId} 
                    priceId='price_1OQqIUCpM5K88EcGPNFwMGVk' //price_1NlwypCpM5K88EcG4kmXEMU9
                    highestSubscription={highestSubscription}
                    allSubscriptions={allSubscriptions}
                    showMore={showMore}
                    switchShowMore={() => {switchShowMore()}}
                    bannerColor='gradient-background'
                    product={{
                        name: 'Premium',
                        description: 'Unlimited staff passport features and access to our amazing AI tools.',
                        price: '£20',
                        isPopular: true,
                        price_addendum: ' annually',
                        deal: 'Free trial for 30 days',
                        features: [
                            'Analyse 8 Work Schedules', 
                            'Generate 50 Teaching sessions',
                            'AI rota extraction', 
                            'AI pay analysis', 
                            'Prospective pay underpayment screening', 
                            'Full calculations and reports', 
                            'Complete Digital Staff Passport',
                            'Access to upcoming AI tools',
                        ],
                        buttonText: 'Subscribe',
                        buttonVariant: 'outline',
                        buttonUrl: 'https://buy.stripe.com/3cs8xE2OG9U6d0Y148' + payUrlOptions
                    }}
                />

</>}


            </HStack >



            <Stack pt={8} display='none'>

                {subscriptions.map((subscription, index) => (
                    <>
                        {subscription.status != 'cancelled' &&
                    
                            <Box key={index}>
                                <Stack>
                                    <Heading size='label'>
                                        {subscription.stripeSubscriptionId}
                                        <Badge>
                                            {subscription.status}
                                        </Badge>
                                    </Heading>
                                </Stack>
                                <Text>
                                    £{subscription.plan_amount/100}
                                </Text>
                                <Text>
                                    {subscription.cancel_at_period_end &&
                                        <>
                                            {'Cancels on ' + moment(subscription.current_period_end).format('LLL')}
                                        </>
                                    }
                                    {!subscription.cancel_at_period_end && subscription.status === 'trialing' &&
                                        <>
                                            {'First payment on ' + moment(subscription.current_period_end).format('LLL')}
                                        </>
                                    }
                                    {!subscription.cancel_at_period_end && subscription.status !== 'trialing' &&
                                        <>
                                            {'Renews ' + moment(subscription.current_period_end).format('LLL')}
                                        </>
                                    }
                                </Text>
                            </Box>
                        }
                    </>
                ))}



            </Stack>
            </GridItem>

        </Layout>
    )
}