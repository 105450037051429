import { Image, Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton, Divider} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';
import Splash from '../../static/layout/Splash';
import axios from 'axios';
import Language from './Language';
import Contact from './Contact';
import Demographics from './Demographics';
import Details from './Details';
import Overview from './Overview';
import Behalf from './Behalf';
import { set } from 'date-fns';


// Import translationsAll json from ./Translate.js
import translationsAll from './Translate';
import moment from 'moment';

export default function SubmitComplaint(props) {

    const [stageIndex, setStageIndex] = useState(0);
    const [data, setData] = useState({
        'language': 'English'
    });
    const [review, setReview] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [lang, setLang] = useState('English');
    const [visibleFilled, setVisibleFilled] = useState(true);
    
    // Get the uuid from the url
    let url = window.location.href;
    let uuid = url.split('/').pop();
    // check if uuid is a valid uuid
    if (uuid.length !== 36) {
        uuid = '02b827c1-b12d-468f-b8ef-d180c6d1109c';
    }
    
    let toast = useToast();


    function setValue(key, value) {
        let newData = data;
        newData[key] = value;
        setData(newData);

        // check if all visible inputs are filled in
        let inputs = document.querySelectorAll('input, textarea, select');
        let filled = true;
        inputs.forEach(input => {
            if (input.offsetParent !== null && input.value === '') {
                filled = false;
            }
        })
        //setVisibleFilled(!filled);

    }

    let stages = [

        {
            name: 'Language',
            component: Language,
            title: 'Language',
            description: 'Please select your preferred language.'
        },
        {
            name: 'Contact',
            component: Contact,
            title: 'Contact Details',
            description: 'Please enter your contact details so we can get back to you.'
        },
        {
            name: 'Behalf',
            component: Behalf,
            title: 'Patient Details',
            description: 'Please enter the details of the patient, if you are not the patient.'
        },
        {
            name: 'Overview',
            component: Overview,
            title: 'Overview',
            description: 'Please enter the overview of your complaint.'
        },
        {
            name: 'Details',
            component: Details,
            title: 'Comments and Details',
            description: 'Please enter as much information as possible so we can help you.'
        },
        {
            name: 'Demographics',
            component: Demographics,
            title: 'Your Demographics',
            description: 'This information is important so we can ensure we are serving all of our community.'
        },
    ]

    function SubmitComplaint() {

        let complaint = data;
        complaint.status = 'New';
        complaint.organisationUuid = uuid;

        let url = 'complaints';
        // let url = 'public/pals/submit';

        // Create an axios request
        axios.post('https://api.pyrexia.io/public/pals/submit', complaint)
        .then(response => {
            console.log("Complaint AXIOS", response.data);
            setTrackingNumber(response.data.trackingNumber);

        })
        .catch(error => {
            console.log("AXIOS", error);
        });
/*
        let resp = API.postItems({url, data: complaint})
        .then(response => {
            console.log("Complaint", response.data);
            //setTrackingNumber(response.data.trackingNumber);
        })
        .catch(error => {
            console.log(error);
        });
*/


    }

    function changeLanguage(language) { 
        setValue('language', language);
        setLang(language);
        //setVisibleFilled(false);
        setStageIndex(stageIndex+1);

    }

    function translate(phrase, language) {

        let translations = translationsAll[phrase];

        if (translations) {
            let translation = translations[language];
            if (translation) {
                return translation;
            }
        }

        return phrase;
    }


    let keyTranslations = {
        'Pdob': 'Date of Birth',
        'Behalf': 'Are you filing this complaint on behalf of someone else?',
        'Pfirstname': 'Service User Firstname',
        'Plastname': 'Service User Lastname',
        'Pemail': 'Service User Email',
        'Pphone': 'Service User Phone',
        'Paware': 'Service User is Aware',
        'Type': 'Type of Feedback',
        'Expectedoutcome': 'Expected Outcome',
        'Interpreter': 'Interpreter Required',
        'NHS': 'NHS Number',
        'MRN': 'Hospital Number',   
    }

    function translateKey(key) {
        if (keyTranslations[key]) {
            return keyTranslations[key];
        }
        return key;
    }

    function nextPage() {

        // Check if all visible inputs have been filled in
        let inputs = document.querySelectorAll('input, textarea, select');
        let filled = true;
        let names = [];
        inputs.forEach(input => {

            let p = input.closest('div');

            if (input.offsetParent !== null && input.value === '' && input.type !== 'hidden' && 
            // id starts with
            !input.id.startsWith('react-select') ){
                filled = false;
                names.push(input.name);

                // Change border colour
                input.style.borderColor = 'red';

                // Check if parent div has a class called 'select'
                if (p && (p.classList.contains('select') || p.querySelector('[class$="control"]')) ) {
                    let closestControl = input.closest('div').querySelector('[class$="control"]');
                    if (closestControl) {
                        closestControl.style.borderColor = 'red';
                    }
                } 

            } else {
                input.style.borderColor = '#eee';

               // Check if parent div has a class called 'select'
               if (p && (p.classList.contains('select') || p.querySelector('[class$="control"]')) ) {
                // Find the child of the parent div with a class that ends with 'control'
                    let closestControl = input.closest('div').querySelector('[class$="control"]');
                    if (closestControl) {
                        closestControl.style.borderColor = '#eee';
                    }
                }
            }
        })



        if (!filled) {
            
            toast({
                title: "Please fill in all fields",
                description: "There are " + pluralize('field', names.length, true) + " missing fields.",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        
        }

        window.scrollTo(0, 0);

        return filled;

    }

    return (
        
        <Box w='100%' p='70px 24px' >

            <Stack spacing={4} maxW='600px' mt='40px' pb='70px' m='auto'>

                <Box>
                    <Image src={'/images/partners/'+uuid+'.png'} w='240px' float='right' mb={6}/>
                </Box>

                {stages.map((stage, i) => {

                    return (
                        <Box display={(!review && stageIndex === i) ? 'block' : 'none'}>
                            <Heading size='sectiontitle' mb={4}>
                                {translate(stage.title, lang)}
                            </Heading>
                            <Text mb={4}>
                                {translate(stage.description, lang)}
                            </Text>
                            <Divider mb={8} />
                            {stage.component({setValue: setValue, data: data, lang: lang, translate, changeLanguage})}
                        </Box>
                    )
                })}

                {review && !trackingNumber &&

                    <Box>
                        <Heading size='sectiontitle' mb={4}>
                            {translate('Review', lang)}
                        </Heading>
                        <Text mb={4}>
                            {translate('Please review your answers before submitting.', lang)}
                        </Text>

                        <Divider mb={8} />
                        <Stack spacing={4}>

                            {Object.keys(data).map((key, i) => {

                                let content = data[key];


                                // Is the object a JSON string?
                                if (typeof content === 'string' && (content[0] === '{' || content[0] === '[') ) {
                                    content = JSON.parse(content);

                                    // Check if content is an array of objects
                                    if (Array.isArray(content) && typeof content[0] === 'object') {
                                        content = content.map((item, i) => {
                                            return (
                                                <Text>
                                                    {item.value}
                                                </Text>
                                            )
                                        })
                                    }

                                    
                                }

                                // Check if data is an array of strings
                                if (Array.isArray(content) && typeof content[0] === 'string') {
                                    content = content.join(', ');
                                }

                                // Replace _ with spaces
                                key = key.replace(/_/g, ' ');
                                // Capitalise each word in the key
                                key = key.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                                // Uppercase certain words
                                let upper = ['Nhs', 'Mrn', 'Id', 'Dob', 'Pc', 'Gp', 'Cc'];
                                upper.forEach(word => {
                                    key = key.replace(word, word.toUpperCase());
                                })

                                return (
                                    <Box>
                                        <Text fontWeight='bold'>
                                            {translate(translateKey(key), lang)}
                                        </Text>
                                        <Text>
                                            {key === 'Pdob' ?
                                                <>
                                                    {moment(content).format('DD/MM/YYYY')}
                                                </>
                                                :
                                                <>
                                                    {translate(content, lang)}
                                                </>
                                            }
                                        </Text>
                                    </Box>
                                )
                            })}
                        </Stack>
                    </Box>
                }

                {trackingNumber ?

                    <Box>

                        <Heading size='sectiontitle' mb={4}>
                            {translate('Thank you', lang)}
                        </Heading>

                        <Text mb={4}>
                            {translate('Your comments have been submitted. Your tracking number is below. To track the status of your comments you will also need your email.', lang)}

                        </Text>

                        <Box mb={4}>
                            <Text fontSize='xx-large'>
                                {trackingNumber}
                            </Text>
                        </Box>

                        <Text mb={4}>
                            {translate('We will be in touch with you shortly.', lang)}
                        </Text>

                    </Box>
                
                
                :

                    <>
                        <Box h={2}/>
                        <Box display={review ? 'none' : 'block'} className='gradient-background' minH='3px' mt={2} borderRadius='sm' >
                            <Box bg='gray.300' w={`${100 - ((stageIndex + 1) / stages.length * 100)}%`} h='3px' float='right' borderRight='sm' transition='0.2s all' />
                        </Box>
                        <ButtonGroup pt={2} size='sm' colorScheme='blue' mt={4} w='100%' justifyContent='space-between'>
                            {!review ?
                                <Button colorScheme='red' variant='outline' onClick={() => {setStageIndex(stageIndex - 1)}} isDisabled={stageIndex === 0}>
                                    {translate('Back', lang)}
                                </Button>
                                :
                                <Button colorScheme='red' variant='outline' onClick={() => {setReview(false)}}>
                                    {translate('Edit',lang)}
                                </Button>
                            }
                            <Text color='subtle' fontSize='sm' mt={1}>
                                {translate('Step ' + (parseInt(stageIndex) + 1) + ' of', lang)}{" " + stages.length}
                            </Text>
                            {stageIndex < stages.length - 1 &&
                                <Button disabled={!visibleFilled} variant='outline' onClick={() => {
                                    nextPage() && setStageIndex(stageIndex + 1)
                                    }} isDisabled={stageIndex === stages.length - 1}>
                                    {translate('Next', lang)}
                                </Button>
                            }
                            {stageIndex === stages.length - 1 && !review &&
                                <Button colorScheme='blue' onClick={() => {
                                    nextPage() && setReview(true)}}>
                                    {translate('Review', lang)}
                                </Button>
                            }
                            {review &&
                                <Button colorScheme='blue' onClick={() => {SubmitComplaint()}}>
                                    {translate('Submit', lang)}
                                </Button>
                            }
                        </ButtonGroup>
                    </>

                }


            </Stack>

        </Box>
    )
}