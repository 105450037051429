const current = [
    
    {
        "company": "Digital Productions Ltd",
        "score": "97",
        "status": "top-recommendation",
        "summary": "Recommended for award as the highest scoring bidder.",
        "details": [
            {
                "criteria": "Price",
                "score": "100",
                "description": "The price is the lowest of all bidders.",
                "data": {
                    "unit": "£000",
                    "bid": 90,
                    "lowest": 90,
                    "highest": 105,
                    "average": 95
                }
            },
            {
                "criteria": "Quality",
                "score": "95",
                "description": "The quality is high and meets the requirements.",
                "data": {
                    "unit": "points",
                    "bid": 95,
                    "lowest": 90,
                    "highest": 100,
                    "average": 95
                }
            },
            {
                "criteria": "Delivery",
                "score": "95",
                "description": "The delivery time is acceptable.",
                "data": {
                    "unit": "days",
                    "bid": 28,
                    "lowest": 14,
                    "highest": 42,
                    "average": 28
                }
            }
        ]
    },
    {
        "company": "Tech Innovators Inc",
        "score": "92",
        "status": "recommendation",
        "summary": "Good overall performance, recommended for award.",
        "details": [
            {
                "criteria": "Price",
                "score": "95",
                "description": "Competitive pricing, slightly above the lowest bid.",
                "data": {
                    "unit": "£000",
                    "bid": 95,
                    "lowest": 90,
                    "highest": 110,
                    "average": 97
                }
            },
            {
                "criteria": "Quality",
                "score": "90",
                "description": "Quality meets the standard but lacks some innovation.",
                "data": {
                    "unit": "points",
                    "bid": 90,
                    "lowest": 85,
                    "highest": 98,
                    "average": 92
                }
            },
            {
                "criteria": "Delivery",
                "score": "92",
                "description": "Delivery time is within an acceptable range.",
                "data": {
                    "unit": "days",
                    "bid": 30,
                    "lowest": 15,
                    "highest": 45,
                    "average": 29
                }
            }
        ]
    },
    {
        "company": "Future Systems Ltd",
        "score": "89",
        "status": "recommendation",
        "summary": "Recommended, but some areas for improvement.",
        "details": [
            {
                "criteria": "Price",
                "score": "90",
                "description": "Slightly above the average price.",
                "data": {
                    "unit": "£000",
                    "bid": 100,
                    "lowest": 88,
                    "highest": 112,
                    "average": 96
                }
            },
            {
                "criteria": "Quality",
                "score": "85",
                "description": "Quality is acceptable but lacks certain key features.",
                "data": {
                    "unit": "points",
                    "bid": 88,
                    "lowest": 83,
                    "highest": 95,
                    "average": 89
                }
            },
            {
                "criteria": "Delivery",
                "score": "88",
                "description": "Delivery is on the slower end but within range.",
                "data": {
                    "unit": "days",
                    "bid": 35,
                    "lowest": 17,
                    "highest": 50,
                    "average": 31
                }
            }
        ]
    },
    {
        "company": "NextGen Solutions",
        "score": "85",
        "status": "recommendation",
        "summary": "Consider for award, some concerns noted.",
        "details": [
            {
                "criteria": "Price",
                "score": "88",
                "description": "Higher than average pricing.",
                "data": {
                    "unit": "£000",
                    "bid": 103,
                    "lowest": 92,
                    "highest": 115,
                    "average": 100
                }
            },
            {
                "criteria": "Quality",
                "score": "82",
                "description": "Meets minimum quality requirements.",
                "data": {
                    "unit": "points",
                    "bid": 87,
                    "lowest": 80,
                    "highest": 94,
                    "average": 88
                }
            },
            {
                "criteria": "Delivery",
                "score": "85",
                "description": "Longer delivery time.",
                "data": {
                    "unit": "days",
                    "bid": 40,
                    "lowest": 18,
                    "highest": 55,
                    "average": 34
                }
            }
        ]
    },
    {
        "company": "PrimeTech Group",
        "score": "95",
        "status": "recommended",
        "summary": "Recommended for award, excellent overall performance.",
        "details": [
            {
                "criteria": "Price",
                "score": "98",
                "description": "Competitive price, close to the lowest.",
                "data": {
                    "unit": "£000",
                    "bid": 92,
                    "lowest": 90,
                    "highest": 108,
                    "average": 97
                }
            },
            {
                "criteria": "Quality",
                "score": "96",
                "description": "High quality with innovative features.",
                "data": {
                    "unit": "points",
                    "bid": 97,
                    "lowest": 91,
                    "highest": 100,
                    "average": 95
                }
            },
            {
                "criteria": "Delivery",
                "score": "94",
                "description": "Fast delivery time.",
                "data": {
                    "unit": "days",
                    "bid": 25,
                    "lowest": 13,
                    "highest": 40,
                    "average": 27
                }
            }
        ]
    },
    {
        "company": "Innovatech Solutions",
        "score": "91",
        "status": "recommendation",
        "summary": "Strong performance, recommended for award.",
        "details": [
            {
                "criteria": "Price",
                "score": "94",
                "description": "Below average price, competitive offer.",
                "data": {
                    "unit": "£000",
                    "bid": 93,
                    "lowest": 89,
                    "highest": 109,
                    "average": 96
                }
            },
            {
                "criteria": "Quality",
                "score": "91",
                "description": "Quality is good, with some innovative elements.",
                "data": {
                    "unit": "points",
                    "bid": 92,
                    "lowest": 88,
                    "highest": 98,
                    "average": 93
                }
            },
            {
                "criteria": "Delivery",
                "score": "88",
                "description": "Slightly longer delivery time.",
                "data": {
                    "unit": "days",
                    "bid": 33,
                    "lowest": 16,
                    "highest": 48,
                    "average": 30
                }
            }
        ]
    },
    {
        "company": "Pioneer Tech Corp",
        "score": "87",
        "status": "recommendation",
        "summary": "Consider for award, but note some weaknesses.",
        "details": [
            {
                "criteria": "Price",
                "score": "89",
                "description": "Above average pricing.",
                "data": {
                    "unit": "£000",
                    "bid": 98,
                    "lowest": 90,
                    "highest": 111,
                    "average": 99
                }
            },
            {
                "criteria": "Quality",
                "score": "84",
                "description": "Quality meets basic requirements, but lacks innovation.",
                "data": {
                    "unit": "points",
                    "bid": 86,
                    "lowest": 81,
                    "highest": 92,
                    "average": 88
                }
            },
            {
                "criteria": "Delivery",
                "score": "86",
                "description": "Moderate delivery time.",
                "data": {
                    "unit": "days",
                    "bid": 36,
                    "lowest": 20,
                    "highest": 52,
                    "average": 33
                }
            }
        ]
    },
    {
        "company": "GlobalTech Solutions",
        "score": "78",
        "status": "consideration",
        "summary": "Consider for award, but note some concerns.",
        "details": [
            {
                "criteria": "Price",
                "score": "80",
                "description": "Above average pricing.",
                "data": {
                    "unit": "£000",
                    "bid": 105,
                    "lowest": 92,
                    "highest": 105,
                    "average": 100
                }
            },
            {
                "criteria": "Quality",
                "score": "75",
                "description": "Quality meets minimum requirements.",
                "data": {
                    "unit": "points",
                    "bid": 80,
                    "lowest": 75,
                    "highest": 85,
                    "average": 81
                }
            },
            {
                "criteria": "Delivery",
                "score": "75",
                "description": "Longer delivery time.",
                "data": {
                    "unit": "days",
                    "bid": 52,
                    "lowest": 25,
                    "highest": 60,
                    "average": 40
                }
            }
        ]
    }
]

export default current;
