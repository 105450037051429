import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Image,
    Img,
    SimpleGrid,
    Link,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react';
  import * as React from 'react';
  import { MdArrowForward } from 'react-icons/md';
  import { useAuth0 } from "@auth0/auth0-react";

  export default function Hero() {
  
    const { loginWithRedirect } = useAuth0();

    return (

      <Box as="section" py="7.5rem">
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: '6',
          md: '8',
        }}
      >
          <Flex
            align="flex-start"
            direction={{
              base: 'column',
              lg: 'row',
            }}
            justify="space-between"
            mb="24"
          >
            <Box
              flex="1"
            >
              <Heading size='pagetitle' mb={4}>
                About.
              </Heading>
              <Heading size='statictagline'>
                We’re on a mission to solve healthcare’s HR problems.
              </Heading>
              <Text size='staticsmalltext'>
                Born from the vision of two dedicated doctors, Pyrexia aims to redefine healthcare HR. Our expertise spans identity management, data security, AI, and the seamless handling of mandatory and compliance documentation.
              </Text>
              <Text size='staticsmalltext'>
                Our core mission? Simplify and streamline the HR process for clinical staff and HR teams alike. At Pyrexia, we uphold the highest standards of transparency and accountability. This ensures that you always understand who we are, what we offer, and how Pyrexia operates.
              </Text>
              <Text size='staticsmalltext'>
                Your feedback is invaluable. A hiccup to share? An idea we should be working? We're all ears! Because if something isn’t clicking for you, we're on it!
              </Text>
              <Button
                borderRadius='24px'
                pl={6}
                pr={6}
                display='none'
                backgroundImage='linear-gradient(to left, #fb594b, #5321bf)'
                onClick={() => loginWithRedirect()}
              >
                Try now - for free!
                <Icon as={MdArrowForward} ml="2" />
              </Button>
            </Box>
            <Box
              boxSize={{
                base: '20',
                lg: '8',
              }}
            />
            <Box>

            </Box>
          </Flex>
        </Box>
      </Box>
    )
  }