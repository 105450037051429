/*

    A generic onboarding page for registering new organisations.

    We will use the Process component to manage the onboarding process.

    The process will be as follows:
    - welcome
    - organisation details
    - organisation logo
    - organisation users
    - organisation settings
    - organisation complete


*/

import React, { useState } from 'react';
import Process from '../../core/components/process/Process';
import Welcome from './Welcome';
import OrganisationDetails from './OrganisationDetails';
import OrganisationLogo from './OrganisationLogo';
import OrganisationAddress from './OrganisationAddress';

export const OrganisationOnboarding = (props) => {

    const [organisation, setOrganisation] = useState({});

    return (
        <Process
            url='organisations/'
            modal={true}
            onCancel={() => {
                console.log('Cancelled');
                // run props.onCancel
                props.onCancel();
            }}
            refresh={props.refresh}
            data={organisation}
            summary={true}
            finishMessage='Finish'
            allowSkip={false}
            steps={[
                {
                    title: 'Welcome',
                    component: <Welcome />
                },
                {
                    title: 'Details',
                    preamble: {
                        // title: 'Test',
                        description: [
                            'Please provide the details of your organisation.'
                        ]
                    },
                    component: [
                        {
                            name: 'title',
                            label: 'Organisation Name',
                            type: 'text',
                            description: 'Enter the name of your organisation',
                            required: true
                        },
                        {
                            name: 'shorthand',
                            label: 'Shorthand / Acronym',
                            type: 'text',
                            description: 'Please enter a shorthand or acronym for your organisation',
                            name: 'shorthand',
                            required: false
                        },
                        {
                            name: 'category',
                            type: 'select',
                            label: 'Organisation Type',
                            description: 'Please select the most relevant type for your organisation.',
                            required: true,
                            options: [
                                {
                                    label: "Secondary Care Trust (NHS)",
                                    value: "Secondary Care Trust (NHS)"
                                },
                                {
                                    label: "Integrated Care Board (NHS)",
                                    value: "Integrated Care Board (NHS)"
                                },
                                {
                                    label: 'General Practice (NHS)',
                                    value: 'General Practice (NHS)'
                                },
                                {
                                    label: "Private Clinic",
                                    value: "Private Clinic"
                                },
                                {
                                    label: "Private Hospital",
                                    value: "Private Hospital"
                                },
                                {
                                    label: "Private Practice",
                                    value: "Private Practice"
                                },
                                {
                                    label: "Private Company",
                                    value: "Private Company"
                                },
                                {
                                    label: "Charity",
                                    value: "Charity"
                                },
                                {
                                    label: "Other",
                                    value: "Other"
                                }
                            ]

                        },
                        {
                            name: 'description',
                            label: 'Description',
                            type: 'textarea',
                            description: 'Please enter a short description of your organisation',
                            required: false
                        }
                    ]
                },
                {
                    title: 'Address',
                    preamble: {
                        description: [
                            'Please provide the business address of your organisation.'
                        ]
                    },
                    component: [
                        {
                            name: 'address1',
                            placeholder: 'Street Address',
                            type: 'text',
                            required: true
                        },
                        {
                            name: 'address2',
                            placeholder: 'Street Address Second Line',
                            type: 'text',
                            required: false
                        },
                        {
                            name: 'address3',
                            placeholder: 'City',
                            type: 'text',
                            required: true
                        },
                        {
                            name: 'address4',
                            placeholder: 'Country',
                            type: 'text',
                            required: true
                        },
                        {
                            name: 'postcode',
                            placeholder: 'Postcode',
                            type: 'text',
                            required: true
                        }
                    ]
                },
                {
                    title: 'Contact Details',
                    preamble: {
                        description: [
                            'Please provide the contact details for your organisation.'
                        ]
                    },
                    component: [
                        {
                            name: 'phone',
                            label: 'Phone Number',
                            type: 'text',
                            required: true
                        },
                        {
                            name: 'email',
                            label: 'Email Address',
                            type: 'email',
                            required: true
                        },
                        {
                            name: 'website',
                            label: 'Website',
                            type: 'text',
                            required: false
                        }
                    ]
                }
            ]}

            />
    );

}

export default OrganisationOnboarding;