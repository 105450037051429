import { Flex, GridItem } from '@chakra-ui/react';

export default function Card(props) {


    return (
      <GridItem
      className='card'
      position="relative"
      direction="column"
      align={{ sm: 'center' }}
      w="full"
      mb={4}
      mx="auto"
      bg='white'
      shadow={{ sm: 'base' }}
      rounded={{ sm: 'lg' }}
      p={4}
      {...props}
      >
        {props.children}
      </GridItem>
  
    )
  }
