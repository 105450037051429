import React, { useState, useEffect } from 'react';
import {SimpleGrid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import WorkSchedule from '../prospectiveleave/WorkSchedule';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import {Header, Row, Cell} from '../../core/components/lists/List';
import { ca } from 'date-fns/locale';



function translate(text) {

    // Replace all _
    text = text.replace(/_/g, ' ');


    // Capitalise first letter of every word
    text = text.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});


    // Array of words that should be all caps
    let allCaps = ['Ai', 'Dob', 'Mrz', 'Ocr', 'Id', 'Pep']
    // Loop through array and replace
    allCaps.forEach(word => {
        text = text.replace(word + ' ', word.toUpperCase() + ' ');
    })

    // Array of words thats should be lower case
    let allLower = ['And', 'Of', 'The', 'In']
    // Loop through array and replace
    allLower.forEach(word => {
        text = text.replace(word + ' ', word.toLowerCase() + ' ');
    });

    // Remove Yoti
    text = text.replace(/Yoti/g, 'Partner');

    // Replace double spaces, loop until no more
    while (text.indexOf('  ') > -1) {
        text = text.replace(/  /g, ' ');
    }

    return text;

}


function DBSCheck(props) {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);                           
    let dbs = props.dbs;

    
    let [picture, setPicture] = useState(null);


    const getPicture = async (tokenIn) => {

      
      let config = {
        params: {
          userUuid: dbs.requesterUuid
        }
      }
      
      let picture = await API.getItems({
        url: 'users/picture', 
        config
      })
      .then(res => {
        setPicture(res.data.image);
      })
      .catch(err => {
        console.log("Error getting picture: ", err);
      });

    };

    useEffect(() => {
      getPicture();
    }, []);

    if (!dbs) {
        return null;
    }

    let user = dbs.requester;
    user.displayName = user.firstName + ' ' + user.lastName;


    let status = dbs.status;
    if (status === 'ABORTED'){
        status = 'FAILED';
        if (dbs.error) {
            status += ': ' + translate(dbs.error.reason);
        }
        if (dbs.json && dbs.json.state === 'EXPIRED') {
            status += ': Session Expired';
        }
    }


    function getStatus(sessionId) {

        console.log("Posting new session ID", sessionId);

        let url = 'https://api.pyrexia.io/yoti/callback';
        let data = {
            session_id: sessionId,
            encrypt: false
        }
        let config = {
            params: {
                session_id: sessionId,
                encrypt: false
            }
        };

        let response = API.postItemsRaw({url, data, config})
        .then(response => {

            console.log("response", response);
            //setData(response.data);
            //setLoading(false);

        })
        .catch(error => {

            
            console.log("error", error);

        }

        );
        
    }

    function viewDBS(uuid) {
        //console.log("View Session", uuid);
        navigate('/my/dbs/view', {state: {uuid: uuid}});
    }

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    return (

        <>
        <Row c={3}
            buttons={
                <>
                    {dbs.status === 'pending' ?
                        <Button onClick={() => viewDBS(dbs.uuid)}>Complete</Button>
                        :
                        <Button onClick={() => setOpen(!open)}>{open ? 'Hide Details' : 'Show Details'}</Button>
                    }
                    <Button display='' onClick={() => getStatus(dbs.identityID)}>Status</Button>

                </>
            }
            >

            <Cell>
                <HStack>
                    <Avatar name={user.displayName} src={picture ? 'data:image/jpeg;base64,' + picture : '/images/avatars/' + Math.floor(Math.random() * 4 + 1) + '.png'} size='md' mr={2} />
                    <Stack spacing={0}>
                        <Text p={0} m={0} color='emphasis' fontWeight='bold'>{user.displayName}</Text>
                        <Text p={0} m={0} fontSize='sm' color='subtle'>Requested on <Moment format="DD/MM/YYYY">{dbs.createdAt}</Moment></Text>
                    </Stack>
                </HStack>
            </Cell>

            <Cell>
                <Tag colorScheme={dbs.status === 'pending' ? 'yellow' : dbs.status === 'ABORTED' ? 'red' : 'blue'}>
                    {status === 'DONE' ? 'Complete' : capitalize(status)}
                </Tag>
            </Cell>

        </Row>

        {open && dbs.checks &&

            <Cell c={6} bg='white'>

                {dbs.checks.map(check => {

                    console.log("check", check);

                    let status = 'Unset';
                    let reason = null;
                    if (typeof check.report.recommendation === 'object') {
                        status = check.report.recommendation.value;
                        reason = check.report.recommendation.reason;
                    } else {
                        status = check.report.recommendation;
                    }


                
                    
                    return (

                        <SimpleGrid columns={3} bg='white' 
                            pl={4} pr={4}
                            borderBottom='1px solid whitesmoke'
                            >

                            <GridItem colSpan={{base: 3, md: 1}}
                                pl='35px'
                                borderLeft='12px solid gray'
                                borderColor={status === 'REJECT' ? 'red.200' : status ==='APPROVE' ? 'green.200' : 'grey'}
                                ml='18px'
                                pt={2} pb={2} 
                                >
                                <Text p={0} pt={4} pb={4} m={0} color='emphasis' fontWeight='bold'>{translate(check.type)}</Text>
                            </GridItem>

                            <GridItem colSpan={{base: 3, md: 1}} p={2} pt={4} pb={4}>
                                {check.state === 'DONE' ? 

                                    <Tag colorScheme={status === 'REJECT' ? 'red' : 'green'}>
                                        {status === 'REJECT' ? status + 'ED' : status + 'D'}{reason && ': ' + translate(reason)}
                                    </Tag>
                                    :
                                    <Tag colorScheme={'yellow'}>
                                        {check.state}
                                    </Tag>
                                }
                            </GridItem>
                            
                            <GridItem colSpan={{base: 3, md: 1}} p={2} pt={4} pb={4}>
                                <Wrap spacing={2}>
                                    {check.report.breakdown.map(breakdown => {

                                        let result = breakdown.result;
                                        let title = breakdown.subCheck;
                                        if (typeof breakdown.subCheck === 'object') {
                                            result = breakdown.subcheck.result;
                                            title = breakdown.subCheck + ': ' + breakdown.subcheck.result;
                                        }

                                        return (
                                            <Tag size='sm' 
                                                colorScheme={result === 'FAIL' ? 'red' : 'green'} 
                                                variant={result === 'FAIL' ? 'solid' : 'outline'}
                                                bg={result === 'FAIL' ? 'red.100' : 'none'}
                                                color={result === 'FAIL' ? 'red.500' : 'green.500'}
                                                order={result === 'FAIL' ? 0 : 1}
                                                >
                                                <TagLeftIcon boxSize="12px" as={result === 'FAIL' ? MdClose : MdCheck} />
                                                {translate(title)}
                                            </Tag>
                                        )

                                    })}
                                </Wrap>
                            </GridItem>

                        
                        </SimpleGrid>

                    )

                })}


            </Cell>


        }

    </>


    )
}


export default function DBSList(props) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [refreshing, setRefreshing] = useState(false);

    function updateList() {

        let url = 'dbs/list';
        let config = {
            params: {
            }
        };

        let response = API.getItems({url, config})
        .then(response => {

            console.log("response", response);
            setData(response.data);
            setLoading(false);

        })
        .catch(error => {

            
            console.log("error", error);
            setLoading(false);

        }

        );

    }

    useEffect(() => {

        updateList();

    }, []);




    return (

        <Layout
            title="Your ID Checks"
            description="This is a list of your ID checks."
            actionContent={<Button variant='outline' colorScheme='blue'  onClick={() => navigate('/my/dbs/idcheck')}>New Application</Button>}
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>

                    {loading ? <LoadingCircle /> : null}

                    {data &&

                        <Box >

                            <Header c={3}>

                                <Cell>
                                    Requested by
                                </Cell>
                                <Cell>
                                    Status
                                </Cell>

                            </Header>

                                {Object.keys(data).map((key, index) => {



                                    return (

                                        <DBSCheck dbs={data[key]} 
                                            refresh={updateList}
                                            />

                                    )

                                })}
                                
                        </Box>

                    }

                </GridItem>

        </Layout>

    )

}



