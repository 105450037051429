import {Input, Stack, HStack, Box, Tag, Badge, Button, ButtonGroup, Wrap, Heading, Text} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/Input';
import { useSearchParams } from 'react-router-dom';


export default function AddQuestion(props) {

    const [parameters, setParameters] = useState([]);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');


    function searchScore(attr) {

        let score = 0;
        let searchTerms = search.split(" ");

        for (const key in attr) {

            if (attr[key] !== null) {
                searchTerms.forEach((term) => {
                    if (attr[key].includes(term)) {
                        score = score + 1;
                    }
                })
            }

        }

        return score;
        

    }

    function getParameters() {

        console.log("Getting");
        let response = API.getItems({url: "usersparameters"})
        .then((response) => {

            let data = response.data;
            console.log(data);
            setParameters(data);
    
        })
        .catch((error) => {
            setError(error);
            
        });

    }

    useEffect(() => {
        getParameters();
    }, [] );


    function addParameter(uuid) {

        let data = {
                formUuid: props.uuid,
                userParameterUuid: uuid,
                order: props.order
        }

        let response = API.postItems({url: 'forms/parameters', data})
        .then((response) => {

            let data = response.data;
            console.log("SUCCESS", data);

            props.onSubmit();
    
        })
        .catch((error) => {
            console.log("FAIL");
            setError(error);
            
        });

    }


    return (

        <Box ml={-6} mr={-6}>

            <Box p={4} pl={6} pr={6} pb={6} borderBottom='1px solid black' >
                <Heading size='label' fontSize='sm' mb={2}>Search existing questions</Heading>
                <Input 
                    name='search-parameters' 
                    onChange={(e) => {setSearch(e.target.value);}}
                    />
            </Box>

            <Stack className='searchable-items'>

            {search !== '' && parameters.map((parameter) => {

                let score = searchScore(parameter);
                let order = 1000 - score;

                return (
                    <>  
                        {score !== 0 &&
                            <HStack order={order} p={4} pl={6} pr={6} borderBottom='1px solid whitesmoke' justifyContent='space-between'>
                                <Stack>
                                <Heading size='label'>{parameter.question}</Heading>
                                <Text fontSize='xs' color='subtle'>{parameter.description}</Text>
                                </Stack>
                                <Button onClick={() => {addParameter(parameter.uuid)}} variant='outline' colorScheme='blue' size='xs'>
                                    Add
                                </Button>
                            </HStack>
                        }
                    </>
                )
            })}

            </Stack>

        </Box>


    )
}