import {Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Modal from '../../core/components/modal/Modal';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import { useToast } from '@chakra-ui/react'
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';

export default function AssignForm(props) {

    let draftMessage = 'Please fill out this form, thank you';

    const [assignee, setAssignee] = useState(null);
    const [assigneeOptions, setAssigneeOptions] = useState({
        due: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16),
        message: draftMessage,
        expires: null,
    });
    const [showModal, setShowModal] = useState(false);
    const [showMore, setShowMore] = useState(false);

    let toast = useToast();


    function assign() {

        console.log("Assigning to", assignee);

        API.postItems({
            url: 'forms/' + props.formUuid + '/assign',
            data: {
                formUuid: props.formUuid,
                ...assigneeOptions,
            }
        }).then((res) => {

            console.log(res.data);
            setShowModal(false);

        })
        .catch((err) => {
            console.log(err);
        })
        
    }

    function updateOptions(option, value) {

        console.log("Updating options", option, value);

        // Check if expires and is before today or due date
        if (option == 'expires') {
            let expires = new Date(value);
            let due = new Date(assigneeOptions.due);
            let today = new Date();

            if (expires < today) {
                toast({
                    title: "Error",
                    description: "Expiration date cannot be before today.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                return;
            } 
            if (expires < due) {
                toast({
                    title: "Error",
                    description: "Expiration date cannot be before due date.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                return;
            }

        }

        // Check if due date is changed
        if (option == 'due') {
            let due = new Date(value);
            let today = new Date();

            if (due < today) {
                toast({
                    title: "Error",
                    description: "Due date cannot be before today.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                return;
            } 
            if (due < assigneeOptions.expires) {
                toast({
                    title: "Error",
                    description: "Due date cannot be before expiration date.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                return;
            }
        }


        let newOptions = assigneeOptions;
        newOptions[option] = value;
        setAssigneeOptions(newOptions);

    }

    return (

        <>

            <Button 
                variant='solid'
                colorScheme='red'
                onClick={() => setShowModal(true)}>
                Assign
            </Button>

            {showModal && 

                <Modal 
                    title='Assign'
                    onClose={() => setShowModal(false)}
                    onSave={() => assign()}
                    footer={
                        <Button 
                            colorScheme='blue'
                            onClick={() => assign()}
                            >
                            Assign
                        </Button>
                    }
                    
                    >

                        <Text>
                            Enter an email below to request a user to fill out the form.
                        </Text>

                        <Stack spacing={4}>

                            <InputWrapper
                                label='Email'
                                description='Provide the e-mail address of the person you would like to complete this form.'
                                placeholder='Email'
                                type='email'
                                onChange={(e) => updateOptions('emails', e.target.value)}
                                />
                            
                            <InputWrapper 
                                placeholder='Message'
                                label='Message'
                                type='textarea'
                                description='You can provide a message to the user you are requesting to fill out this form.'
                                onChange={(e) => updateOptions('message', e.target.value)}
                                defaultValue={'Please fill out this form, thank you'}
                                />


                            <InputWrapper 
                                placeholder='Due date'
                                type='datetime-local'
                                label='Due date'
                                description='Provide a due date for this form to be completed.'
                                onChange={(e) => updateOptions('due', e.target.value)}
                                // Create a default due datetime of 1 week from now
                                defaultValue={new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16)}
                                />

                            {showMore &&

                                <InputWrapper
                                    placeholder='Expiration date'
                                    label='Expiration date'
                                    description='Provide an expiration date for this form to be completed, the user will not be able to complete it after this date.'
                                    type='datetime-local'
                                    onChange={(e) => updateOptions('expires', e.target.value)}
                                    />

                            }

                            <Button 
                                size='xs'
                                variant='outline'
                                onClick={() => {
                                    if (showMore) {
                                        updateOptions('expires', null);
                                    }
                                    setShowMore(!showMore)
                                }}>
                                {showMore ? 'Show less' : 'Show more'}
                            </Button>

                        </Stack>




                </Modal>

            }


        </>
    )
}