import React, {useState, useEffect, useContext} from 'react';
import {Box, Badge, GridItem, Button, Stack, HStack, Flex, Heading, Input, Text, useToast} from '@chakra-ui/react';
import API from '../../core/utils/API';
import Layout from '../../core/layout/Layout';
import PricingCard from './PricingCard';
import Product from './ProductOld';
import moment from 'moment';
import { SubscriptionContext } from '../../auth/SubscriptionContext';
import { set } from 'date-fns';

import  secureLocalStorage  from  "react-secure-storage";
import { get } from 'react-hook-form';

import Products from './Products';

let products = [
    {
        name: 'Invoice Automation',
        description: 'Automate your invoicing process with our AI tools',
        price: '£1000',
        price_addendum: 'pcm',
        product_id: 'prod_QJFrJdHK23vjcH',
        price_id: 'price_1PXPu8CpM5K88EcGicIiTyGw',
        deal: 'Integrates with Xero and SBS',
        features: [
            'Integration with existing systems',
            'Granular control of invoice submission',
            'Beautiful intuitive interface',
            'Analytics'
        ],
    },
    {
        name: 'Invoice Automation (Test)',
        description: 'Automate your invoicing process with our AI tools',
        price: '£1',
        price_addendum: 'pcm',
        product_id: 'prod_QJFrJdHK23vjcH',
        price_id: 'price_1PYa9DCpM5K88EcGqBArol8t',
        deal: 'Integrates with Xero and SBS',
        features: [
            'Integration with existing systems',
            'Granular control of invoice submission',
            'Beautiful intuitive interface',
            'Analytics'
        ],
    },
    {
        name: 'Applicant Tracking System',
        description: 'Automate your recruitment process',
        price: '£1000',
        price_addendum: 'pcm',
        product_id: 'null',
        price_id: 'null',
        deal: '',
        features: [
            'Automated job posting',
            'AI supported candidate selection',
            'Automated interview scheduling',
            'Personalised candidate feedback',
        ],
    },
    {
        name: 'Automated Onboarding',
        description: 'Automate your onboarding process',
        price: '£1000',
        price_addendum: 'pcm',
        product_id: 'null',
        price_id: 'null',
        deal: '',
        features: [
            'Automated document collection',
            'Granular control of onboarding process',
            'Automated and personalised alerts',
            'Roles and permissions templates'
        ],
    },
    {
        name: 'Digital ID Checks',
        description: 'Remote digital ID checks for your employees',
        price: 'On request',
        price_addendum: '',
        product_id: 'null',
        price_id: 'null',
        deal: '',
        features: [
            'Automated ID checks',
            'International ID checks',
            'Granular control of ID checks',
            'Automated and personalised alerts'
        ],
    },
    {
        name: 'Compliance Monitoring',
        description: 'Automate compliance monitoring for your employees',
        price: '£1000',
        price_addendum: 'pcm',
        product_id: 'null',
        price_id: 'null',
        deal: '',
        features: [
            'Automatically monitor compliance',
            'Automated and personalised alerts',
            'Alerts and notifications',
            'Granular control of compliance monitoring',
        ],
    },
    {
        name: 'Feedback System',
        description: 'Automate your feedback process',
        price: '£1000',
        price_addendum: 'pcm',
        product_id: 'null',
        price_id: 'null',
        deal: '',
        features: [
            'Automated feedback collection',
            'Automated and personalised alerts',
            'Granular control of feedback process',
            'Feedback analysis'
        ],
    }

]

export default function Subscribe() {

    const [organisationUuid, setOrganisationUuid] = useState('');
    const [organisationTitle, setOrganisationTitle] = useState('');

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState('');
    const [coupon, setCoupon] = useState('');
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [stripeSubscriptions, setStripeSubscriptions] = useState([]);
    const [error, setError] = useState(null);
    const [highestSubscription, setHighestSubscription] = useState({});
    const [allSubscriptions, setAllSubscriptions] = useState([]);
    const [showMore, setShowMore] = useState(true);
    const [organisation, setOrganisation] = useState({});

    const [localSubscriptions, setLocalSubscriptions] = useState([]); 
    const [payUrlOptions, setPayUrlOptions] = useState('');

    const toast = useToast();

    function switchShowMore() {
        setShowMore(!showMore);
    }

    useEffect(() => {

        console.log("update payUrlOptions");


        let tmpPayUrlOptions = {};
        tmpPayUrlOptions['client_organisation_uuid'] = organisation ? organisation.uuid : null;
        tmpPayUrlOptions['client_organisation_title'] = organisation ? organisation.title : null;
        tmpPayUrlOptions['client_user_email'] = user ? user.email : null;
        tmpPayUrlOptions['client_user_uuid'] = user ? user.uuid : null;
        
        // Set the payUrlOptions
        let tmpPayUrlOptionsString = '';
        for (const [key, value] of Object.entries(tmpPayUrlOptions)) {
            tmpPayUrlOptionsString += '&' + key + '=' + value;
        }
        console.log('tmpPayUrlOptionsString', tmpPayUrlOptionsString);
        setPayUrlOptions('?' & tmpPayUrlOptionsString);

    }, [user, organisation]);


    useEffect(() => {

        // Get from secureLocalStorage
        let orgUuid = secureLocalStorage.getItem('organisationUuid');
        let orgTitle = secureLocalStorage.getItem('organisationTitle');

        setOrganisationUuid(orgUuid);
        setOrganisationTitle(orgTitle);

    }, [user]);
    
    useEffect(()=>{
        async function getUser(){


            try {


                const response = await API.getItems({url: 'users/me'});
                const a = (response.data['me']);
                setUser(a);
                console.log("User", a);

                let ans = await checkStripeCustomerId(a.uuid);

                console.log("answer", ans);
                setLoading(false);

                console.log("Getting organisations");
                let orgs = await getOrganisations();

                return;

            } catch (error) {
                setError(error);
                setLoading(false);
                return;
            }
        }

        async function getOrganisations() {
            try {
                let organisationUuid = secureLocalStorage.getItem('organisationUuid');

                const response = await API.getItems({url: 'organisations/', config: {params: {uuid: organisationUuid}}});
                const a = (response.data);
                console.log("Org ", a);
                setOrganisation(a.organisation);
                setSubscriptions(a.subscriptions);
                
                return a;
            } catch (error) {
                setError(error);
                return;
            }
        }

        getUser()
    }, [])

    
    async function checkStripeCustomerId(uuid) {
        try {
            console.log('checkStripeCustomerId');
            const response = await API.getItems({
                url: 'users/getStripeCustomerId',
                config: {
                    params: {
                        uuid: uuid
                    }
                }
            });
            const a = (response.data);
                
            console.log("Check Stripe ID", a);
            setStripeCustomerId(a.stripe_customer_id);
            setLoading(false);
            return a;

        } catch (error) {
            setError(error);
            setLoading(false);
            return;
        }

    }
    

    function createCheckoutSession(
        priceId
    ) {

        // Ensure that user.uuid, organisation.uuid and stripeCustomerId are set
        if (!user.uuid || !organisation.uuid || !stripeCustomerId) {
            console.log("User, Organisation or Stripe Customer ID not set");
            toast({
                title: "Error",
                description: "User, Organisation or Stripe Customer ID not set",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        let params = {
            priceId: priceId,
            stripeCustomerId: stripeCustomerId,
            userUuid: user.uuid,
            organisationUuid: organisation.uuid,
        }
        
        console.log('createCheckoutSession', params);
        
        API.postItems({
            url: 'stripe/create-checkout-session',
            config: {
                params: params
            }
        }).then((response) => {

            console.log(response);
            window.location.href = response.data.url;

        }).catch((error) => {
            console.log(error);

        })

    }
    


    return (
        <Layout
            title="Subscriptions"
            >

            <GridItem colSpan='6'>


            <Box display=''>
                <Text>
                    UUID: {user.uuid}
                </Text>
                <Text>
                    Stripe: {stripeCustomerId}
                </Text>
                <Text>
                    Organisation: {organisationUuid}
                </Text>
                <Text>
                    Organisation Title: {organisationTitle}
                </Text>
                <Text>
                    Subscriptions: {subscriptions.length}
                </Text>
                <Text>
                    Pay URL Options: {payUrlOptions}
                </Text>
            </Box>


            <HStack spacing={{base: 0, md: 4}} alignItems='stretch' pt={4} flexDirection={{base: 'column-reverse', md: 'row'}}>

            {!loading &&

                <>
                    {Object.keys(Products).map((key, index) => {
                        let product = Products[key];
                        
                        return (
                            <PricingCard
                                showMore={showMore}
                                switchShowMore={() => {switchShowMore()}}
                                product={product}
                                subscriptions={subscriptions}
                                userUuid={user.uuid} 
                                stripeCustomerId={stripeCustomerId} 
                                bannerColor='gradient-background'
                                createCheckoutSession={createCheckoutSession}
                            />
                        )
                    })}

                </>
            }

            </HStack >



            <Stack pt={8} display='none'>

                {subscriptions.map((subscription, index) => (
                    <>
                        {subscription.status != 'cancelled' &&
                    
                            <Box key={index}>
                                <Stack>
                                    <Heading size='label'>
                                        {subscription.stripeSubscriptionId}
                                        <Badge>
                                            {subscription.status}
                                        </Badge>
                                    </Heading>
                                </Stack>
                                <Text>
                                    £{subscription.plan_amount/100}
                                </Text>
                                <Text>
                                    {subscription.cancel_at_period_end &&
                                        <>
                                            {'Cancels on ' + moment(subscription.current_period_end).format('LLL')}
                                        </>
                                    }
                                    {!subscription.cancel_at_period_end && subscription.status === 'trialing' &&
                                        <>
                                            {'First payment on ' + moment(subscription.current_period_end).format('LLL')}
                                        </>
                                    }
                                    {!subscription.cancel_at_period_end && subscription.status !== 'trialing' &&
                                        <>
                                            {'Renews ' + moment(subscription.current_period_end).format('LLL')}
                                        </>
                                    }
                                </Text>
                            </Box>
                        }
                    </>
                ))}



            </Stack>
            </GridItem>

        </Layout>
    )
}