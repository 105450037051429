"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tokens_1 = require("../foundations/tokens");
var getBaseStyle = function (props) {
    return {
        container: {
            background: 'bg-muted',
            fontSize: 'sm',
            borderRadius: 'full',
            p: '1',
            pl: '4',
            pr: '4',
        },
        closeButton: {
            color: 'subtle',
        },
    };
};
var getSubtleStyle = function (props) {
    return {
        container: {
            color: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'brand-text-body',
        },
        closeButton: {
            color: 'subtle',
        },
    };
};
var getSolidStyle = function (props) {
    return {
        container: {
            bg: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'blue.500',
            color: 'invert',
        },
        closeButton: {
            color: 'subtle',
        },
    };
};
var getOutlineStyle = function (props) {
    return {
        container: {
            borderColor: 'bg-muted',
            color: props.colorScheme ? (0, tokens_1.getColorDefault)(props) : 'default',
            bg: 'unset',
        },
        closeButton: {
            color: 'subtle',
        },
    };
};
var getChipStyle = function (props) {
    return {
        container: {
            borderRadius: 'full',
            bg: props.colorScheme ? (0, tokens_1.getBgMuted)(props) : 'bg-muted',
            color: 'default',
        },
        closeButton: {
            color: 'subtle',
        },
    };
};

var sizes = {
    xs: {
        h: 6,
        minW: 6,
        fontSize: 'xs',
        px: 2,    
        borderWidth: '1px',
        color: 'red!important',
    },
    sm: {
        h: 8,
        minW: 8,
        fontSize: 'sm',
        px: 4,
        borderWidth: '1px'
    },
    md: {
        h: 10,
        minW: 10,
        fontSize: 'md',
        px: 4,
        borderWidth: '2px'
    },
    lg: {
        h: 12,
        minW: 12,
        px: 4,
        fontSize: '21px',
        borderWidth: '2px'
    },
    xl: {
        h: 16,
        minW: 16,
        px: 4,
        fontSize: '21px',
        borderWidth: '2px'
    },
};
var variants = {
    subtle: getSubtleStyle,
    solid: getSolidStyle,
    outline: getOutlineStyle,
    chip: getChipStyle,
};
exports.default = {
    baseStyle: getBaseStyle,
    defaultProps: {
        colorScheme: 'gray',
        color: 'brand-text-body',
    },
    variants: variants,
    sizes: sizes,
};