
import React from 'react';
import { Stack, Table, Thead, Tbody, Tr, Th, Td, Tag, Text, Wrap, Heading } from '@chakra-ui/react';
import Card from '../../../core/components/cards/Card';
import Moment from 'react-moment';
import { Row, Cell } from '../../../core/components/lists/List';


export default function(props) {

    if (props.job === null || props.job === undefined || props.job === {} || !props.job.details || !props.job.details.applicants ) {
        return (
            <></>
        )
    }



    return (

        <Stack spacing={4}>
            
            {props.showApplicants != false &&
                <Card title='Applicants' p={0}>

                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Td><Heading size='label'>Applicants</Heading></Td>
                                <Td><Heading size='label'>Status</Heading></Td>
                                <Td><Heading size='label'>Date</Heading></Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.job.details.applicants.map((applicant, index) => {
                                return (
                                    <Tr>
                                        <Td>{applicant.name}</Td>
                                        <Td>
                                            <Tag size='sm' variant='outline' colorScheme={applicant.status === 'Shortlisted' ? 'blue' : 'red'}>
                                                {applicant.status}
                                            </Tag>
                                        </Td>
                                        <Td color={applicant.status === 'Shortlisted' ? 'strong' : 'subtle'}
                                            ><Moment format='MMMM Do'>{applicant.date}</Moment>
                                        </Td>
                                    </Tr>
                                )
                            })}

                            {props.job.details.applicants.length === 0 &&
                                <Tr>
                                    <Td colSpan={3}>
                                        <Text color='subtle'>No applicants yet.</Text>
                                    </Td>
                                </Tr>
                            }
                        </Tbody>

                    </Table>

                </Card>
            }

            <Card title='Requirements' p={0}>

                <Heading fontSize='xl' mb={1} p={4} lineHeight='1'>Requirements</Heading>


                    <Row c={4} buttons='hide'>
                        <Cell c={4}>
                            <Text fontWeight='bold' pb={2}>Role</Text>
                            <Wrap spacing={2}>
                                {props.job.details && props.job.details.requirements && props.job.details.requirements['roles'].map((role, index) => {
                                    return (
                                        <Tag size='sm' variant='outline' colorScheme='blue' mr={2}>{role}</Tag>

                                    )
                                }
                                )}
                                </Wrap>
                        </Cell>
                    </Row>


                    <Row c={4} buttons='hide'>
                        <Cell c={4}>
                            <Text fontWeight='bold' pb={2}>DBS</Text>
                            <Text>{props.job.details && props.job.details.requirements && props.job.details.requirements['dbs']}</Text>     
                        </Cell>
                    </Row>


                    <Row c={4} buttons='hide'>
                        <Cell c={4}>
                            <Text fontWeight='bold' pb={2}>Records</Text>
                            <Wrap spacing={2}>
                                {props.job.details && props.job.details.requirements && props.job.details.requirements['requirementGroups'].map((group, index) => {
                                    return (
                                        <Tag size='sm' variant='outline' colorScheme='blue' mr={2}>{group}</Tag>

                                    )
                                }
                                )}
                                </Wrap>
                        </Cell>
                    </Row>
                </Card>

            </Stack>
    )
}