import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { Line, Bar, Pie, Radar} from 'react-chartjs-2';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import { useToast } from "@chakra-ui/react"

function currency(value) {
    return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
}

export default function Accounts() {

    const [data, setData] = useState({});

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(null);


    useEffect(() => {
        API.getItems({url: 'organisations/accounts/summary'})
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box w='100%' h='100%' p={8}>
                <Loading 
                    show={true}
                    title='Loading accounts'
                    blankTimeout={0}
                    />
            </Box>
        )
    }

    if (error) {
        return <Splash title="Error" message={error.message} />;
    }

    return (

        <>
            <Heading size='smallertitle' mb={4}>Accounts</Heading>
            
            <Text mb={4} fontWeight='600'>
                Your timesheets, expenses and invoices
            </Text>
            <Text mb={4}>
                The organisation has {data.accountSummary.total} {pluralize('invoices', data.accountSummary.total)}, for a total of {currency(data.accountSummary.totalAmount)}.
            </Text>

            <Box maxW='400px' minH='300px' p={8} pt={12}>
                <Pie data={
                    {
                        labels: Object.keys(data.accountSummary.statuses),
                        datasets: [{
                            label: 'Invoices',
                            data: Object.keys(data.accountSummary.statuses).map(key => data.accountSummary.statuses[key].count),
                            backgroundColor: [
                              'rgba(72, 149, 239, 0.7)',
                              'rgba(63, 55, 201, 0.7)',
                              'rgba(114, 9, 183, 0.7)',
                              'rgba(181, 23, 158, 0.7)',
                              'rgba(247, 37, 133, 0.7)'
                            ],
                            borderColor: [
                              '#4895EF',
                              '#3F37C9',
                              '#7209B7',
                              '#B5179E',
                              '#F72585',
                            ],
                            borderWidth: 1,
                        }]
                    }
                 }
                />
            </Box>
            <Box >
                <Icon as={MdLock} color='black' w={6} h={6} position='absolute' right={4} top={4} />
                <Text color='blue.500' className='show-on-sibling-hover'>Docments and sharing secured with multi-factor authentication.</Text>
            </Box>
        </>

    );

}