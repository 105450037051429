import React, {useState, useEffect} from 'react';
import { Box, IconButton, Icon, Button, Circle, Container, Divider, Flex, FormControl, FormHelperText, FormLabel, Input, InputGroup, InputLeftAddon, Stack, StackDivider, Text, Textarea } from '@chakra-ui/react';
import API from '../../core/utils/API';
import { MdCheckCircle, MdCheckCircleOutline, MdClose, MdCheck } from 'react-icons/md';
export default function Validate(props) {

    // Pass the following:
    // - evidenceUuid
    // - validated
    console.log("Validate props.validations", props.validations);

    const [validated, setValidated] = useState();
    const [validations, setValidations] = useState(props.validations);

    useEffect(() => {

        console.log("Validate props.validations", props.validations);
        let found = null;
        if (props.validations === undefined || props.validations === null) {
            return;
        }
        props.validations.map((validation) => {
            console.log(validation);
            if (validation.cancelled === null || validation.cancelled === undefined) {
                console.log("FOUND", validation.uuid)
                found = validation.uuid;
            }
        })
        console.log("FOUNDFK", found);
        setValidated(found);

    }, [props.validations]);



    const update = async () => {

        console.log("Updating validation", props.evidenceUuid, validated);

        const response = await API.postItems({url: 'evidences/' + props.evidenceUuid + '/validate', data: {uuid: validated}});
        console.log("Response", response);

        if (response.status === 200) {
            console.log("Setting " + response.data.uuid);
            setValidated(response.data.uuid);
        } else {
            alert('Error'); // TODO - add error toast for doc validation
        }

    }

    return (
        <>


            {(validated === undefined || validated === null) ?
                <IconButton border='none' 
                fontSize='32px' onClick={() => update()} variant='outline' icon={<MdCheck/>}  color='gray.300' _hover={{bg: 'green.50', color:'green.500'}} _active={{bg: 'green.500', color: 'green.50'}}/>
                :
                <IconButton border='none' 
                fontSize='32px' onClick={() => update()} variant='outline' icon={<MdCheck />}  color='green.500' _hover={{bg: 'red.50', color: 'red.500'}} _active={{bg: 'red.500', color: 'red.50'}}/>
            }
                
        </>
    )
}