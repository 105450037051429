import {Badge, HStack, Divider, Tag, Input, GridItem, Icon,Circle, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';
import Flag from "react-world-flags";

export default function Language(props) {


    // Something here
    let languages = [
        { "language": "English", "translatedLanguage": "English", "countryName": "United Kingdom", "isoCode": "GB", "designOffset": "14", "hOffset": "-1", "scale": 1.25}, // The USA flag has no off-center emblem.
        { "language": "Urdu", "translatedLanguage": "اردو", "countryName": "Pakistan", "isoCode": "PK", "hOffset": "-2", "designOffset": "14", "scale": 1 }, // Pakistan's flag has a crescent and star towards the hoist side.
        { "language": "Punjabi", "translatedLanguage": "ਪੰਜਾਬੀ / پنجابی", "countryName": "India", "isoCode": "IN", "designOffset": "8", "hOffset": "-1",  "scale": 1}, // India's flag has a centered emblem.
        { "language": "Bengali", "translatedLanguage": "বাংলা", "countryName": "Bangladesh", "isoCode": "BD", "designOffset": "6", "hOffset": "0", "scale": 0.95 }, // Bangladesh's flag has a slightly off-center circle.
        { "language": "Pakistani Pahari", "translatedLanguage": "پاکستانی پہاڑی", "countryName": "Pakistan", "isoCode": "PK", "hOffset": "-2", "designOffset": "14" , "scale": 1}, // Same as Urdu for Pakistan.
        { "language": "Polish", "translatedLanguage": "Polski", "countryName": "Poland", "isoCode": "PL", "designOffset": "0", "hOffset": "-1", "scale": 1 }, // Poland's flag has no off-center emblem.
        { "language": "Somali", "translatedLanguage": "Soomaali", "countryName": "Somalia", "isoCode": "SO", "designOffset": "8", "hOffset": "-2", "scale": 1 }, // Somalia's flag has a centered star.
        { "language": "Arabic", "translatedLanguage": "العربية", "countryName": "Egypt", "isoCode": "EG", "designOffset": "8", "hOffset": "-2", "scale": 1 }, // Egypt's flag has centered emblems.
        { "language": "Pashto", "translatedLanguage": "پښتو", "countryName": "Afghanistan", "isoCode": "AF", "designOffset": "7", "hOffset": "-1", "scale": 0.9 }, // Afghanistan's flag has a centered emblem.
        { "language": "Mandarin", "translatedLanguage": "普通话", "countryName": "China", "isoCode": "CN", "designOffset": "-12", "hOffset": "0", "scale": 1 }, // China's flag has a centrally aligned design.
        { "language": "Gujarati", "translatedLanguage": "ગુજરાતી", "countryName": "India", "isoCode": "IN", "designOffset": "8", "hOffset": "-1", "scale": 1 } // Same as Punjabi for India.
    ]
    

    return (

        <Box>

            <Flex mb={4} width='fit-content' flex={1} mb={4} borderRadius='md' bg='white' border='1px solid #E2E8F0' borderColor={'blue.500'} bg={'blue.500'}
                cursor='pointer'
                _hover={{
                    boxShadow: 'sm'
                }}
                onClick={() =>{
                    props.changeLanguage('English');
                }}
                >
                <Flag width="80px" code="GB" />
                <Text color='white' ml={4} mt={2} mr={4} fontSize='xlarge'>English</Text>
            </Flex>

            <Box minH={4}>

            </Box>


            <Divider />

            <Heading mt={6} mb={4} size='label'>
                {props.translate("Alternative languages", props.lang)}
            </Heading>
        
            <Wrap spacing={2}>

                {languages.sort((a, b) => a.language.localeCompare(b.language)).map((language, index) => {

                    return (
                        <Flex borderRadius='4px' height='26px' borderRadius='14px' boxShadow='none' bg='white' ml='4px' mb='4px'
                            cursor={'pointer'}
                            pr={2}
                            border='1px solid #E2E8F0'
                            _hover={{
                                boxShadow: 'sm',
                                borderColor: 'blue.500',
                                color: 'white',
                            }}
                            onClick={() =>{
                                props.changeLanguage(language.language);
                            }}
                            >
                            
                            <Box borderRadius='50%' overflow='hidden' width='24px' height='24px' bg='white' border='1px solid #E2E8F0' >
                                <Box ml={'-' + (language.designOffset) + 'px'} mt={language.hOffset + "px"} minW={(39 * language.scale) + 'px !important'}>
                                    <Flag code={language.isoCode} ml='-14px'/>
                                </Box> 
                            </Box>
                            <Text pl={2} fontSize='sm'>{language.translatedLanguage}<Text as='span' color='subtle' pl='4px'>({language.language})</Text></Text>
                        </Flex>
                    )
                })}

            </Wrap>

            <Text mt={6} fontSize='sm' color='subtle'>
                {props.translate("These languages were chosen as they are the ten most common languages in the area, they have been ordered alphabetically. If you would like to see your language here, please contact us.", props.lang)}
            </Text>

        
        </Box>
    )

}