import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect , useContext} from "react";
import { AccountContext } from '../../auth/cognito/components/Accounts';
import {Alert, AlertIcon, AlertTitle, AlertDescription, Box, IconButton, useToast} from '@chakra-ui/react';
import Kyber1024 from '../../auth/Kyber1024';
import CryptoJS from 'crypto-js';
import Encryption from './Encryption';
import { AxiosRequestTransformer } from "axios"
import { da } from 'date-fns/locale';
import e from 'cors';
import pdf from 'pdf-page-counter';
import {Buffer} from 'buffer';
import secureLocalStorage from 'react-secure-storage';
//import { type } from 'os';


/*
const encryptData: AxiosRequestTransformer  = (data: any) => {
  
  // Check if request has any data
  // If it does, encrypt it using the symmetric key
  // If it doesn't, return the request

  if (!data) {
    console.log("No data to encrypt");
    return data;
  }

  // check if we are meant to encrypt the data
  if (data.encryption === false) {
    console.log("Request has been marked as not requiring encryption");
    return data;
  }

  console.log("We need to encrypt a request");
  console.log("Encrypting data: ", data);
  // We need to encrypt everything else
  let originalData = JSON.stringify(data);
  // Encrypt the data using the symmetric key
  console.log("Encrypting data: ", originalData);
  let encryptedData = Encryption.AES256_GCM_ENCRYPT(originalData)
  .then((encryptedData) => {

    data = {encryptedData};

    // Create object to hold the encrypted data
    let encryptedDataObject = {
      encryptedData: encryptedData,
    };

    // Add the encrypted data to the request
    data = Object.assign(data, encryptedDataObject);


    // Create plain object to hold the encryption flag
    let encryptionFlagObject = {
      encryption: false,
    };

    console.log("Encrypted data: ", data);
    console.log("Encrypted data object: ", encryptedDataObject);

//    let JSONData = JSON.stringify(data);
    console.log("Encrypted data: ", data);

    let appendedOriginalData = originalData.append(encryptedData, encryptedData);
    return appendedOriginalData;
  })
  .catch((error) => {

    console.log("Error encrypting data: ", error);
    return data;

  });
}

*/

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  headers: {
      Accept: 'application/json',
     // 'Content-Type': 'application/json',
  },
  timeout: 10000, // TODO - reduce timeout on axios
});

const setupInterceptors = (token) => {

  //console.log("Setting up interceptors");

  httpClient.interceptors.request.use(
    async (request) => {
       // console.log("AxiosConfig.js Request Interceptor triggered");
       //console.log("Request: ", request);
        request.headers = {
          'Accept': 'application/json',
       //   'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${token}`
        };

        
        // Return if environment is not production
        if (process.env.NODE_ENV === 'development') {
          return request;
        }
        //console.log("REQUEST INTERCEPTOR TRIGGERED");

        //return request;

        // Check if request has any data
        // If it does, encrypt it using the symmetric key
        // If it doesn't, return the request
        if (!request.data && !request.params) {
          return request; 
        }
 


        // Generate random 4 digit number for reference
        let reference = Math.floor(1000 + Math.random() * 9000);
        let tmpRequest = request;
        let originalData = request.data;
        let originalParams = request.params;
        let originalBody = request.body;
        let originalQuery = request.query;
        
        //console.log("Request Data: ", reference, originalData);

        if (originalData instanceof FormData) {
          
          console.log("Original Data is formData", reference, originalData);

          // Create new formData object
          let encryptedFormData = new FormData();

          // Loop through originalData
          for (let file of originalData) {
            //console.log("AxiosConfig File: ", file);
            if (file[1] instanceof File) {
              //console.log("File is a file");
              // get file blob
              let blob = await file[1].slice(0, file[1].size, file[1].type);
              //console.log("Blob: ", blob);

              let base64data = await blobToBase64(blob);

              let fileName = file[1].name;
              //console.log("File name: ", reference, fileName, base64data.substring(0,100));

              let encryptedBase64Data = await Encryption.AES256_GCM_ENCRYPT(base64data);
              let decryptedBase64Data = await Encryption.AES256_GCM_DECRYPT(encryptedBase64Data);
              //console.log("File Body", reference, "(", fileName, ")",  encryptedBase64Data.substring(0,30), decryptedBase64Data === base64data);
              
              let encryptedFileName = await Encryption.AES256_GCM_ENCRYPT(fileName, true);
              let decryptedFileName = await Encryption.AES256_GCM_DECRYPT(encryptedFileName);
              //console.log("File name: ", reference, fileName, encryptedFileName, decryptedFileName, fileName === decryptedFileName);
              
              let encryptedFileType = await Encryption.AES256_GCM_ENCRYPT(file[1].type);
              let decryptedFileType = await Encryption.AES256_GCM_DECRYPT(encryptedFileType);
              //console.log("File type: ", reference, file[1].type, encryptedFileType, decryptedFileType, file[1].type === decryptedFileType); 
              
              
              // EncryptedBase64Data is a string, so we need to convert it to a blob
              // Convert base64 to blob
              let byteCharacters = atob(encryptedBase64Data);
              let byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              let byteArray = new Uint8Array(byteNumbers);
              let encryptedBlob = new Blob([byteArray], {type: 'encrypted'});//encryptedFileType});

              let encryptedFile = new File([encryptedBlob], encryptedFileName, {type: encryptedFileType});
              //console.log("Encrypted file", encryptedFile);

              
              // Recreate the file object using the decrypted data
              //console.log("Decrypted data", decryptedBase64Data.substring(0,100), reference);
              
              // Create file from data url
              let decryptedFile = await fetch(decryptedBase64Data).then(r => r.blob());
              //console.log("Decrypted file", decryptedFile);

              // Download the file
              //let downloadLink = document.createElement("a");
              //downloadLink.href = window.URL.createObjectURL(decryptedFile);
              //downloadLink.download = encryptedFileName;
              //downloadLink.click();


              // Add encrypted file to encryptedFormData
              encryptedFormData.append(file[0], encryptedFile);

            } else {
              //console.log("File is not a file", file[0], file[1]);
              encryptedFormData.append(file[0], file[1]);
            }
          }

          //console.log("Form Data", originalData, encryptedFormData);
          tmpRequest.data = encryptedFormData;

        } 
        else {
          
          // Don't need to check for key, it will fail on encryption if there is no key
          
          if (originalData === undefined || originalData === null) {

            //console.log("Skipping encryption (data) as it is empty", reference, originalData);

          } else if (typeof originalData === 'object' && Object.keys(originalData).length === 0) {

            //console.log("Skipping encryption (data) OBJECT it is empty", reference, originalData);
          } else if (originalData.encryptedData !== undefined) {
            
            //console.log("Catching double encryption (data)")

          } else if (originalData.encryption !== false ) {

            //console.log("Encrypting data", reference, originalData);
            
            originalData = JSON.stringify(originalData);
            //console.log("Encrypting data", reference, originalData);
            let encryptedData = await Encryption.AES256_GCM_ENCRYPT(originalData);
            //console.log("Encrypted Data: ", reference, encryptedData);
            request.data = {encryptedData};   
            //console.log("Request Data: ", reference, request.data);

          } 

        }

        // Parameters, Query and Body are all the same
        // Loop through each one and encrypt the data
        // Stored in originalParams, originalQuery, originalBody
        let info = {
          params: originalParams,
          query: originalQuery,
          body: originalBody
        };

        // Looping is difficult with async functions
        // So we will have to do it manually
        info['params'] = await encryptRequestElement(originalParams, 'params', reference);
        info['query'] = await encryptRequestElement(originalQuery, 'query', reference);
        info['body'] = await encryptRequestElement(originalBody, 'body',  reference);


        //console.log("Info: ", reference, info);

        // Set the request data to the encrypted data
        request.params = info.params;
        request.query = info.query;
        request.body = info.body;

        

        /*
        if (originalParams === undefined || originalParams === null || originalParams === {} || originalParams.encrypt === false) {

        } else if (originalParams.encryptedParams !== undefined) {
          
          //console.log("Catching double encryption (params)")

        } else if (originalParams.encryption !== false) {

          originalParams = JSON.stringify(originalParams);
          //console.log("Encrypting params", reference, originalParams);
          let encryptedParams = await Encryption.AES256_GCM_ENCRYPT(originalParams);
          //console.log("Encrypted Params: ", reference, encryptedParams);
          //console.log("Decrypted Params: ", reference, await Encryption.AES256_GCM_DECRYPT(encryptedParams));
          request.params = {encryptedParams};          
          //console.log("Request Params: ", reference, request.params);

        }
        */

        //console.log("Request: ", reference, request);
        return request;
    }, (error) => {
    // console.log(error);
      return Promise.reject(error);
    }
  );

  httpClient.interceptors.response.use(
    async (response) => {
      //console.log("AxiosConfig.js Response Interceptor triggered");

      // Check if response data is JSON or an encrypted string
      // If it is an encrypted string, decrypt it using the symmetric key
      // If it is JSON, check if it contains an encrypted string
      // If it does, decrypt it using the symmetric key
      // If it doesn't, return the JSON data

      //console.log("RESPONSE TRIGGERED");

      // Generate random 4 digit number for reference
      let reference = Math.floor(1000 + Math.random() * 9000);
      
      // Get URL from the request
      let requestURL = response.config.url;

      //console.log("RESPONSE", reference, response.data, requestURL);



      // Check if response data is JSON or an encrypted string
      if (response.data !== undefined && response.data.encryptedData !== undefined) {

          //console.log("Response data has an encryptedData property");

          //let tmp = JSON.parse(response.data.toString());
          //console.log("Response data is JSON: ", reference, tmp);

          //tmp = tmp.encryptedData;

          
          // Decrypt the data using the symmetric key
          let decryptedData = await Encryption.AES256_GCM_DECRYPT(response.data.encryptedData);
          //console.log("Decrypted data: ", reference, decryptedData);
          response.data = JSON.parse(decryptedData.toString());
          


          //console.log("Decrypted data: ", reference, response.data);
          return response;


      } else {

        //console.log("Response data is not encrypted", reference, response.data);

        // No need to change response
        return response;

      }




    },
    (error) => {

      //console.log(error, "RESPONSE");
      //console.log("AxiosConfig.js Response Interceptor triggered - error");
      //console.log(error.response);
      //console.log("STATUS", error.response.status, error.response.status === 401);
     //const { logout } = useContext(AccountContext);
/*     const toast = useToast();

     toast({
      title: 'Analysis Complete',
      description: "We've finished analysing your files, please refresh the page to see the latest analysis.",
      status: 'success',
      //duration: 9000,
      isClosable: true,
      render: () => (

          <Alert status='success'>
              <AlertIcon />
              <Box pr={1}>
                  <AlertTitle>Error {error.response.status}</AlertTitle>
                  <AlertDescription>
                      {error.response.message}
                  </AlertDescription>
              </Box> 

          </Alert>


      ),
      });*/

      if (!error.response) {
        console.log("Network error", error);

      } else if (error.response.status === 401) {
        //console.log("unauthorized, refreshing...");
        // TODO - API Error - expired token - should add functionality to refresh token here if I want to keep the user logged in
        //logout(window.location.origin);
        //logout({ returnTo: window.location.origin });
        // Check if url contains code and state parameters

        // TODO - Refresh the aws cognito token
        
      
        // Prevent refresh if doing MFA validation
        if (error.response.data.message === "Token is invalid.") {
          window.location.reload();

        }
      } else if (error.response.status === 402) {
        console.log("payment required");
      } else if (error.response.status === 403) {
        console.log("forbidden");
      } else if (error.response.status === 404) {
        console.log("not found");
      } else if (error.response.status === 500) {
        console.log("internal server error");
      } else if (error.response.status === 503) {
        console.log("service unavailable");
      } else if (error.response.status === 504) {
        console.log("gateway timeout");
      } else {
        console.log("unknown error");
      }
      return Promise.reject(error);
    }
  );

  return httpClient;
};

async function encryptRequestElement(info, type, reference) {

  //("Encrypting request ", type, reference);

  if (
      info === undefined ||  // skip if undefined
      info === null || // skip if null
      info === ''  // skip if empty string
  ) {

    //console.log("Skipping encryption (", type, ") as it is empty", reference, info);
    return info;

  } else if (
      (typeof info === 'object' && type !== 'body' && Object.keys(info).length === 0) // skip if no keys AND not body 
  ) {

      //console.log("Skipping encryption (", type, ") ", reference, info); 

    // Check if info is an object
    // If it is, encrypt it using the symmetric key
    if (typeof info === 'object') {

      // Show keys
      //console.log("Keys: ", Object.keys(info), type, reference, info);

    }

    return info;

  } else if (typeof info === 'object' && info.encrypt === false) {

    //console.log("Skipping encryption (", type, ") as it is marked as not requiring encryption", reference, info);
    return info;

  } else if (info['encrypted' + type] !== undefined) {

    //console.log("Catching double encryption (", type, ")", reference, info);
    return info;

  } else if (info.encryption !== false) {

    let tmpInfo = JSON.stringify(info);

    let encryptedData = await Encryption.AES256_GCM_ENCRYPT(tmpInfo);

    let key = 'encrypted' + type.charAt(0).toUpperCase() + type.slice(1);

    //console.log("Encrypted data (", type, "): ", encryptedData);
  
    return {
      [key]: encryptedData
    }

  }

}




function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob); // ?readAsArrayBuffer
    
  });
}

// Create a new interceptor for any requests from a component or their children
// This will add the organisationUuid to the request
// We also want the previous interceptor to run AFTER this one (as the previous interceptor will encrypt the data)
// So we need to return the request from this interceptor
// This will allow the previous interceptor to run after this one

// This is the interceptor that will add the organisationUuid to the request
// It will also add the previous interceptor to the request
// This will allow the previous interceptor to run after this one

function addOrganisationUuidInterceptor(organisationUuid) {

  //console.log("Adding OrganisationUuid Interceptor");

  httpClient.interceptors.request.use(
    async (request) => {
      //console.log("AxiosConfig.js Request Interceptor triggered");
      //console.log("Request: ", request);
      //console.log("OrganisationUuid: ", organisationUuid));
      //console.log("Request: ", request);

      //console.log("OrgUuuid Interceptor Request 1: ", organisationUuid, request);

      // Add the organisationUuid to the request
      // This will allow the request to be sent to the correct organisation
      // Add it to the query / params / body of the request

      // Create arrays for the different types of requests
      // This will allow us to add the organisationUuid to the request

      let paramsRequestTypes = ['GET', 'DELETE', 'HEAD', 'OPTIONS', 'CONNECT', 'TRACE'];
      let bodyRequestTypes = ['POST', 'PUT', 'PATCH', 'COPY', 'LOCK', 'MKCOL', 'MOVE', 'SEARCH', 'UNLOCK', 'BIND', 'REBIND'];
      let queryRequestTypes = ['GET', 'DELETE', 'HEAD', 'OPTIONS', 'CONNECT', 'TRACE'];

      let requestType = request.method.toUpperCase();

      // Lets just add it to the request object
      request.addedOrganisationUuid = organisationUuid;

      // check if the request has a body / params / query
      if (request.params) {
        //console.log("Request has params");
        request.params = {
          ...request.params,
          addedOrganisationUuid: organisationUuid
        };

      }
      /*
      if (request.data) {
        //console.log("Request has data");
        request.data = {
          ...request.data,
          addedOrganisationUuid: organisationUuid
        };
      }
      */
      if (request.query) {
        //console.log("Request has query");
        request.query = {
          ...request.query,
          addedOrganisationUuid: organisationUuid
        };
      }

      // Check if the request type is a GET request
      if (paramsRequestTypes.includes(requestType)) {
        //console.log("GET Request");
        // Add the organisationUuid to the request
        request.params = {
          ...request.params,
          addedOrganisationUuid: organisationUuid
        };
      } else if (bodyRequestTypes.includes(requestType)) {
        //console.log("POST Request");
        // Add the organisationUuid to the request
        /*
        request.data = {
          ...request.data,
          addedOrganisationUuid: organisationUuid
        };
        */
      } else if (queryRequestTypes.includes(requestType)) {
        //console.log("Query Request");
        // Add the organisationUuid to the request
        request.query = {
          ...request.query,
          addedOrganisationUuid: organisationUuid
        };
      } else {
        // Check if the request has a body / params / query and add the organisationUuid to the request
        if (request.params) {
          request.params = {
            ...request.params,
            addedOrganisationUuid: organisationUuid
          };
        }
        /*
        if (request.data) {
          request.data = {
            ...request.data,
            addedOrganisationUuid: organisationUuid
          };
        }
        */
        if (request.query) {
          request.query = {
            ...request.query,
            addedOrganisationUuid: organisationUuid
          };
        }
       // console.log("Unknown request type", request.method);
      }

     // console.log("OrgUuuid Interceptor Request 2: ", organisationUuid, request, request.params, request.data, request.query);

      // Return the request
      return request;

    }
  );

  return httpClient;

}



export { setupInterceptors, addOrganisationUuidInterceptor };
export default httpClient;