"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = function () {
    return {
        _focus: {
            boxShadow: 'none',
        },
        _focusVisible: {
            boxShadow: 'none',
        },
    };
};
exports.default = {
    baseStyle: baseStyle,
};
