import {
    Avatar,
    Box,
    Button,
    Wrap,
    Tag,
    TagLabel,
    Container,
    Divider,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    Stack,
    StackDivider,
    Text,
    Textarea,
  } from '@chakra-ui/react';
import * as React from 'react';
import { Dropzone } from '../../../core/components/forms/Dropzone';
  
import { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import API from '../../../core/utils/API';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ProfilePhotos from '../../onboarding/Photo';
import { useNavigate } from 'react-router-dom';
import PicturesManage from '../../images/Manage';

export default function ProfileUpdate(props) {

    const [data, setData] = useState("");
    const [formMessage, setFormMessage] = useState("");
    const {
      register,
      handleSubmit,
      reset,
      formState,
      formState: { errors },
    } = useForm();
    const onError = (errors, e) => console.log(errors, e);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [gmc, setGMC] = useState([]);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    React.useEffect(()=>{
        async function getUser(){


            try {


                const response = await API.getItems({url: 'users/me'});
                const a = (response.data['me']);

 //               console.log(a);
                setUser(a);
                setGMC(JSON.parse(a.extraJSON).GMC);
                setLoading(false);

                return;


            } catch (error) {
       //         console.log(error);
                setError(error);
                setLoading(false);
                return;
            }
        }
        getUser()
    }, [])

    const onSubmit = async (data, e) => {
        //console.log("SUBMITTING");
        setLoading(true);
        //console.log(data);
        let url = 'users/me';
        
        let response = await API.patchItems({url: "users/me", data})
        .then(function (response) {
            //console.log(response);
            window.location.reload(false);
            setFormMessage("Record updated successfully");
            // Reset inputs
            setLoading(false);
            window.location.reload(false);

        })
        .catch(function (error) {
            //console.log(error);
            window.location.reload(false);
            setFormMessage("Error creating record");
            setLoading(false);
            window.location.reload(false);

        });

    }


    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            {!loading &&

        <Stack spacing={5}>
            <Stack
            spacing="4"
            direction={{
                base: 'column',
                sm: 'row',
            }}
            justify="space-between"
            > 
            <Box>
                <Text fontSize="lg" fontWeight="medium">
                Your Profile
                </Text>
                <Text color="muted" fontSize="sm">
                Everyone who can see your records will see this information.
                </Text>
            </Box>
            <Button type='submit'  variant="outline" className='btn-8' colorScheme='blue' alignSelf="start">
                Save
            </Button>
            </Stack>
            <Divider />
            <FormControl id="name">
                <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
                >
                <FormLabel variant="inline">Name</FormLabel>
                    <Stack direction={{base: 'column', md: 'row'}} w='100%'>
                    <Input
                        maxW={{
                        md: '3xl',
                        }}
                        placeholder="First Name"
                        defaultValue={user.firstName}
                        {...register('firstName', { required: true })}
                    />
                    <Input
                        maxW={{
                        md: '3xl',
                        }}
                        placeholder="Last Name"
                        defaultValue={user.lastName}
                        {...register('lastName', { required: true })}
                    />
                    </Stack>
                
                </Stack>
            </FormControl>
            <Divider />

            <FormControl id="profession">
                <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
                >
                <FormLabel variant="inline">Profession</FormLabel>
                    <Stack direction={{base: 'column', md: 'row'}} w='100%'>
                    <Input
                        maxW={{
                        md: '3xl',
                        }}
                        placeholder="Organisation"
                        defaultValue={user.profession}
                        {...register('profession', { required: true })}
                    />
                    </Stack>
                
                </Stack>
            </FormControl>
            <Divider />

            <FormControl id="organisation">
                <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
                
                >
                <FormLabel variant="inline">Organisation</FormLabel>
                <Stack direction={{base: 'column', md: 'row'}} w='100%'>
                    <Input
                        maxW={{
                        md: '3xl',
                        }}
                        placeholder="Organisation"
                        defaultValue={user.organisation}
                        {...register('organisation', { required: true })}
                    />
                    </Stack>
                
                </Stack>
            </FormControl>

            <Divider />
            <Stack
            spacing="4"
            direction={{
                base: 'column',
                sm: 'row',
            }}
            justify="space-between"
            > 
            <Box>
              
            </Box>
            <Button type='submit'  variant="outline" className='btn-8' colorScheme='blue' alignSelf="start">
                Save
            </Button>
            </Stack>

            <Box/>
        <Divider bg='black' minH='1px' />
        <Box/>

            <Stack
            spacing="4"
            direction={{
                base: 'column',
                sm: 'row',
            }}
            justify="space-between"
            > 
            </Stack>
            <FormControl id="picture">
                <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
                >
                <FormLabel variant="inline" minW={{md: '18em'}} maxW={{md:'18em'}}>
                    <Text fontSize="lg" fontWeight="medium">
                    Your Profile Pictures
                    </Text>
                    <Text color="muted" fontSize="sm">
                    The primary photo is visible to other users.
                    </Text>
                </FormLabel>

                <Stack direction={{base: 'column', md: 'row'}} w='100%'
                    maxW={{md: '3xl'}}
                    pl='5px !important'
                    >

                    <PicturesManage />
                
                </Stack>

                </Stack>
            </FormControl>
            
        <Divider />

            <FormControl id="gmc">
                <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
                >
                <FormLabel variant="inline" minW={{md: '18em'}} maxW={{md:'18em'}}>
                    <Text fontSize="lg" fontWeight="medium">
                    GMC Details
                    </Text>
                    <Text color="muted" fontSize="sm">
                        To change these please <Text onClick={() => {navigate('/onboarding')}} as='span' _hover={{textDecoration: 'underline'}} color='blue.500' cursor='pointer'>repeat the registration process</Text>.
                    </Text>
                </FormLabel>
                
                <Stack direction={{base: 'column', md: 'row'}} w='100%'
                    maxW={{md: '3xl'}}
                    >

                <Wrap spacing={2} pb={4}>
                    {Object.keys(gmc).map((innerAttr, index) => {
                                            return (
                                                <>
                                                    {gmc[innerAttr] != "" && gmc[innerAttr] != '0000-00-00' &&
                                                        <Tag size='xs' isAttached variant='outline'>
                                                            <TagLabel>{innerAttr.replaceAll("_", " ")}</TagLabel>
                                                            <Divider bg='black' ml={1} mr={1} orientation='vertical' />
                                                            <TagLabel>{gmc[innerAttr]}</TagLabel>
                                                        </Tag>
                                                    }
                                                </>
                                            )})
                                        }
                    
                </Wrap> 
                </Stack>

                </Stack>
            </FormControl>

        </Stack>
}
        </form>
    )
}