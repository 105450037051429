import { Box , Text} from '@chakra-ui/react';



export default function Card (props) {

    return (
        
        <Box p={10}
        overflow='hidden'
            bg='white'
            border='2px solid' borderColor='gray.500' borderRadius='20px'
            {...props}
            >
            {props.children}
        </Box>
    )
}
