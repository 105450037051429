import React from 'react';
import { Wrap, Tag, Table, Tbody, Tr, Th, Td, Icon, Circle, Avatar, Stack, HStack, GridItem, Flex, useToast, Box, Text, Heading, Input, Button, List, ListItem } from '@chakra-ui/react';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import { useState, useEffect } from 'react';
import API from '../../core/utils/API';
import {MdCheck, MdClose, MdList} from 'react-icons/md';
import Moment from 'react-moment';
import Splash from '../../static/layout/Splash';
import axios from 'axios';
import Loading from '../../static/layout/LoadingCircle';

export default function RegistrationAPI() {

    const [registration, setRegistration] = useState('7528894');
    const [body, setBody] = useState('gmc');
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const toast = useToast();
    const [showJSON, setShowJSON] = useState(false);

    let demo = {
        firstnames: 'Oliver James',
        lastname: 'Trampleasure',
        body: 'gmc',
        registration: '7528894',
        active: true,
        bodyComment: 'Registered with a licence to practise',
        additional: [
            /*
                {
                    type: [qualification|information|relationship|demographic|training]
                    title: 'string',
                    awarded: 'datetime',
                    expires: 'datetime
                }
            */
           {
                type: 'qualification',
                title: 'Provisional Registration',
                issued: '2016-07-18'
           },
           {
                type: 'qualification',
                title: 'Full Registration',
                issued: '2017-08-02'
           },
           {
                type: 'information',
                title: 'Designated Body',
                value: 'NHSE Education North Central and East London'
           },
           {
                type: 'relationship',
                title: 'Responsible Officer',
                value: 'Gary Wares'
           },
           {
                type: 'demographic',
                title: 'Gender',
                value: 'Male'
           }, 
           {
                type: 'training',
                title: 'Postgraduate Training Programme',
                value: 'Paediatrics',
           }
        ]
    };

    const [person, setPerson] = useState(null);



    let detailsRequired = [
        'goc',
        'psni'
    ]

    function checkRegistration(regulator, registration, profession, uuid = null) {

        setLoading(true);

        let url = 'https://registrations.pyrexia.io/check';
        let params = { 
            registration,
            regulator,
            profession,
            uuid
        };

        // Timeout should be 35 seconds

        axios.get(url, {params, timeout: 35000})
        .then((response) => {
            console.log(response);
            setPerson(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error(error);
            toast({
                title: "Error",
                description: "There was an error checking the registration",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            setLoading(false);
        });

    }

  return (
    
    <Splash 
        title="Registration API"
        description="API for checking registration details"
        maxW='600px'
        >

        <GridItem colSpan={12}>


            <Box p={4} borderWidth="1px" borderRadius="md"
                minH='400px'
                minW='600px'
                display={loading ? 'flex' : 'block'}
                alignItems='center'
                justifyContent='center'
                flexDirection='column'
                >

            {loading &&
                <Loading />
            }
    {person === null && !loading &&

        <>
                <Heading as="h3" size="sm" mb={2}>Check Registration</Heading>
                <Text fontSize='sm' mb={5}>You can check any regulated healthcare professional's registration by entering it below, and selecting their designated body, some of the regulators require extra information to accurately check status.</Text>
                <Stack spacing={2}>
                    <InputWrapper type='input' label="Registration" name="registration" placeholder="Enter registration number"
                        defaultValue='7528894'
                    />
                    <InputWrapper label="Registration Body" name="body" 
                        type="select" 
                        placeholder="Select the registration body"
                        onChange={(v) => setBody(v['value'])}
                        defaultValue='gmc'
                        options={[
                            {
                                label: 'General Medical Council',
                                value: 'gmc'
                            },
                            {
                                label: 'Nursing and Midwifery Council',
                                value: 'nmc'
                            },
                            {
                                label: 'Health and Care Professions Council',
                                value: 'hcpc'
                            },
                            {
                                label: 'Pharmacy NI',
                                value: 'psni'
                            }
                        ]} />
                    <Stack key={body} spacing={2} >

                            {// Check if body is in details required
                                detailsRequired.includes[body] && 
                                <Heading size='label'>
                                    Details
                                </Heading>
                            }
                        

                            {(body === 'psni' || body === 'psi') &&
                            
                                <InputWrapper label='Registration Type'
                                    type='radio'
                                    options={[
                                        'Qualified',
                                        'Trainee'
                                    ]}
                                    />

                            }

                            {(body === 'goc') &&
                            
                                <InputWrapper label='Profession'
                                    type='radio'
                                    options={[
                                        'Opthalmologist',
                                        'Optician'
                                    ]}
                                    />
                            }


                    </Stack>
                    <Flex justifyContent='flex-end' pt={2}>
                        <Button colorScheme="blue" w='min-content' variant='outline'
                            onClick={() => {
                                let registration = document.querySelector('input[name="registration"]').value;
                                checkRegistration(body, registration, 'doctor');
                            }}
                            size='sm'

                            >
                            Check
                        </Button>
                    </Flex>

                    <Box>
                        <Heading size='label' pt={5} mb={2}>
                            Demos
                        </Heading>
                        <Wrap spacing={2}>

                            <Button colorScheme="blue" w='min-content' variant='outline'
                                onClick={() => {
                                    checkRegistration('gmc', '7528894', 'doctor');
                                }}
                                size='sm'
                                >
                                Dr Oliver
                            </Button>
                            <Button colorScheme="red" w='min-content' variant='outline'
                                onClick={() => {
                                    checkRegistration('gmc', '7528892', 'doctor');
                                }}
                                size='sm'
                                >
                                Dr April
                            </Button>
                            <Button colorScheme="blue" w='min-content' variant='outline'
                                onClick={() => {
                                    checkRegistration('hcpc', 'PA42091', 'paramedic');
                                }}
                                size='sm'
                                >
                                Paramedic Jo
                            </Button>
                            <Button colorScheme="red" w='min-content' variant='outline'
                                onClick={() => {
                                    checkRegistration('nmc', '', 'nurse', 'NeLXl2mbNYPYlBmoKXv7EA2');
                                }}
                                size='sm'
                                >
                                Nurse Hannah
                            </Button>
                            <Button colorScheme="blue" w='min-content' variant='outline'
                                onClick={() => {
                                    checkRegistration('nmc', '', 'nurse', '8v8J7bZRmFGFlvtgBRKdRQ2');
                                }}
                                size='sm'
                                >
                                Nurse Sabrina
                            </Button>
                        </Wrap>
                    </Box>
                </Stack>

            </>
}
        {person !== null && !loading &&

            <Stack spacing={2}>
                <Button colorScheme="red" w='min-content' variant='outline'
                    onClick={() => {
                        setPerson(null);
                    }
                    }
                    size='sm'
                    >
                    Reset
                </Button>

                <HStack spacing={2}>
                    <Avatar size='md' name={ (person.fullname === '' || person.fullname === null) ? person.fullname : person.firstname + ' ' + person.lastname }
                        mr='10px'
                        border='2px solid black'
                        />
                    <Stack spacing={0}>
                        <Text lineHeight='1em' fontSize='lg'>
                            {
                                <>
                                {person.firstnames}
                                <Text as='span' textTransform='uppercase' pl={1} fontWeight='strong'>
                                    {person.lastname}
                                </Text>
                                </>
                            }
                            {
                                <>
                                {person.fullname}
                                </>
                            }
                        </Text>
                    </Stack>
                </HStack>

                <HStack spacing={2}>
                    <Circle w='48px' h='48px'
                        bg={person.active ? 'green.500' : 'red.500'}
                        mr='10px'
                        >
                        <Icon color='white' as={person.active ? MdCheck : MdClose} boxSize='32px' />
                   </Circle>

                    <Stack spacing={1}>
                   <Text lineHeight='1em'>
                            <Text as='span' color='black' textTransform='uppercase' mr={1}>
                                {person.submittedRegulator.toUpperCase()}
                            </Text>
                            <Text as='span'>
                                {person.registration}
                            </Text>
                    </Text>
                   <Text lineHeight='1em'>
                    {person.regulatorComment}
                   </Text>
                   </Stack>
                </HStack>

                <Stack spacing={0}>
                <HStack spacing={2}>

                    
                    <Circle w='48px' h='48px'
                        bg='white'
                        mr='10px'
                        >
                        <Icon color='black' as={MdList} boxSize='32px' />
                   </Circle>

                   <Text>
                        Details
                   </Text>
                </HStack>
                {person.additional.map((item) => {

                    return (
                    <HStack pt='0.5em' pb='0.5em' spacing={2} borderTop='1px solid whitesmoke' pl='64px'>

            
                            <HStack >
                                <Text lineHeight='1.2em'>
                                    <Text as='span' color='black' mr={1}>
                                        {item.title}{item.value !== null && item.value !== '' && ":"}
                                    </Text>
                                    {item.value.toString()}
                                </Text>
                                {item.issued &&
                                    <Tag variant='subtle'>
                                        {item.issued}
                                    </Tag>
                                }
                            </HStack>



                    </HStack>
                    )


                })}
                </Stack>

                <Stack pt='0.5em' pb='0.5em' spacing={2} borderTop='1px solid whitesmoke' pl='64px' spacing={2}>

                    <Text onClick={() => {setShowJSON(!showJSON)}}
                        cursor='pointer'
                        color='blue.500'
                        fontSize='sm'
                        >
                        {showJSON ? 'Hide' : 'Show'} JSON
                    </Text>

                    {showJSON &&
                            <Text fontSize='sm' maxW='400px'>
                                <pre>
                                {JSON.stringify(person, null, 2)}
                                </pre>
                            </Text>
                    }

                </Stack>


                
            </Stack>

            }

            </Box>


        </GridItem>


    </Splash>
  )
}