
import React, { useState, useContext } from 'react';
import { Flex, Heading, Text, Box, Stack, Button, Input, InputGroup, InputRightElement, Alert, AlertTitle, AlertDescription, CloseButton } from '@chakra-ui/react';
import { AccountContext } from './Accounts';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from '../UserPool';
import Code from '../../../core/components/forms/Code';

export default (props) => {
    
    const [email, setEmail] = useState(props.email);
    const [code, setCode] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [error, setError] = useState(null);

    const { getSession, authenticate, forgotPassword, forgotPasswordSubmit} = useContext(AccountContext);

  
    const onSubmit = event => {

        //event.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setError('Passwords do not match');
            return;
        }

        getSession()
            .then(({user, email}) => {
                console.log("Session:", email);

                user.changePassword(oldPassword, newPassword, (err, result) => {
                    if (err) {
                        setError(err.message);
                    } else {
                        console.log(result);
                        setError(null);
                        setOldPassword('');
                        setNewPassword('');
                        setConfirmNewPassword('');
                    }
                }
            )})
            .catch(err => {
                console.error(err);
                console.error(err.message);
                setError(err.message);
            }
        );

            
    };

    
    return (

        <Box>
                {error && 
                            <Alert status="error" mb={4}>
                                <AlertDescription>{error}</AlertDescription>
                                <CloseButton position="absolute" right="4px" top="4px" onClick={() => setError('')} />
                            </Alert>
                        }
                <form onSubmit={onSubmit}>
                    <Stack spacing={3}>
                        <Text>
                            Changing password for {email}.
                        </Text>
                        <Box>
                            <Text>
                                Please enter the code sent to your e-mail.
                            </Text>
                            <InputGroup size='md' pb={4}>
                                    <Code 
                                        codeLength={6}
                                        align='left'
                                        return={(code2) => {setCode(code2)}}
                                        name='confirmationCode'
                                        align='center'
                                        hideClear={true}
                                    />
                            </InputGroup>
                        </Box>
                            <InputGroup size='md' pb={4}>
                            <Input type={showNewPassword ? 'text' : 'password'} placeholder="New Password" value={newPassword} onChange={event => setNewPassword(event.target.value)} />
                                <InputRightElement width='4.5rem'>
                                    <Button colorScheme='blue' variant='outline' className='btn-8' h='1.75rem' size='sm' onClick={()=>setShowNewPassword(!showNewPassword)}>
                                        {showNewPassword ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        <Flex direction='row' justify='space-between'>
                            <Button variant='outline'
                                onClick={() => {
                                    props.setStage('Login');
                                }}
                                >
                                Back
                            </Button>
                            <Button w='min-content' colorScheme='blue' variant='solid'
                                onClick={() => {

                                    try {
                                        forgotPasswordSubmit(email, code, newPassword)
                                        .then((resp) => {
                                            console.log("Response:", resp);
                                            props.setMasterEmail(email);
                                            props.setMasterPassword(newPassword);
                                            props.setStage('Login');
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            setError(err.message);
                                        });
                                    } catch (e) {
                                        console.error(e);
                                        setError(e.message);
                                    }

                                    // confirmForgotPassword(email, code, newPassword);

                                }}>
                                Change        
                            </Button>
                        </Flex>
                    </Stack>
                </form>
        </Box>
    )
}
