import React, { createContext } from 'react';
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import Pool from '../UserPool';

 const AccountContext = createContext();

 const Account = props => {

    const forgotPassword = async (Username) => {
        console.log("Forgot Password inside")
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });
            console.log("User:", user);
            user.forgotPassword({
                onSuccess: data => {
                    console.log('onSuccess:', data);
                    resolve(data);
                },
                onFailure: err => {
                    console.error('onFailure:', err);
                    reject(err);
                },
                inputVerificationCode: data => {
                    console.log('inputVerificationCode:', data);
                    resolve(data);
                }
            });
        });
    }

    const forgotPasswordSubmit = async (Username, code, newPassword) => {
        console.log("Forgot Password Submit inside")
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });
            
            user.confirmPassword(code, newPassword, {
                onSuccess: data => {
                    console.log('onSuccess:', data);
                    resolve(data);
                },
                onFailure: err => {
                    console.error('onFailure:', err);
                    reject(err);
                }
            });

        });

    }

    const getSession = async () =>
        await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession(async (err, session) => {
                    if (err) {
                        reject();
                    } else {
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err);
                                } else {
                                    const results = {};

                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value;
                                    }

                                    resolve(results);

                                }
                            });
                        });

                        resolve({
                            user,
                            ...session,
                            ...attributes
                        });
                    }
                });
            } else {
                reject();
            }
        });


    const authenticate = async (Username, Password) => {

        await new Promise((resolve, reject) => {
            
            const user = new CognitoUser({ Username, Pool });
            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    console.log('onSuccess:', data);
                    resolve(data);
                },
                onFailure: err => {
                    console.error('onFailure:', err);
                    reject(err);
                },
                newPasswordRequired: data => {
                    console.log('newPasswordRequired:', data);
                    resolve(data);
                }
            });
        });

    }

    const logout = (redirectTo) => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
        }
        if (redirectTo) {
            window.location.href = redirectTo;

        } else {
            window.location.reload();
        }

    }


    return (
        <AccountContext.Provider value={{
            authenticate,
            getSession,
            logout,
            forgotPassword,
            forgotPasswordSubmit
        }}>
            {props.children}
        </AccountContext.Provider>
    );
 }

 export {Account, AccountContext}