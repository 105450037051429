
import React, {useContext, useState, useEffect} from 'react';
import {Heading, Text, Box, Stack, Button, Input} from '@chakra-ui/react';
import { AccountContext } from './Accounts';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';

export default () => {

    const [ loggedIn, setLoggedIn ] = useState(false);
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                setLoggedIn(true);
            });
    }, []);

    return (

        <Box>
            {loggedIn && 
                <> 
                    <Heading size='smalltitle' mb={6}>Settings</Heading>
                    <ChangePassword/>
                    <ChangeEmail/>
                </>
            }
        </Box>
    )
}
