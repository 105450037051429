import React, { useState, useEffect, useContext } from 'react';
import { SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';


export default function Complaints(props) {


    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    

    function updateList() {


        let url = 'complaints/all';
        let config = {
            params: {
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log("List", response.data.complaints);
            let sorted = response.data.complaints.sort((a, b) => {
                return moment(b.createdAt).diff(moment(a.createdAt));
            });

            setList(sorted);
            setLoading(false);

        })
        .catch(error => {
            console.log(error);
        }

        );

    }
    useEffect(() => {

        updateList();

    }, []);

    function viewItem(uuid) {
        //console.log("View Schedule", uuid);
        navigate('view', {state: {uuid}});
    }

    let colorOptions = {
        'APOLOGY': 'red',
        'ADVICE': 'orange',
        'INFORMATION': 'yellow',
        'EXPLANATION': 'green',
        'COMPLIMENT': 'green',
        'OTHER': 'blue',
        'LEARNING': 'purple',
        'COMPLAINT': 'red',
        'OPEN': 'orange',
        'CLOSED': 'green',
        'PENDING': 'yellow',
        'DRAFT': 'orange',
        'SENT': 'green',
        'RECEIVED': 'yellow',
        'APPROVAL': 'yellow',
        'LEGAL': 'purple',
        'PALS': 'purple',
        'INVESTIGATION': 'red',
        'CLINICAL': 'red',
        'SIGN OFF': 'green',
    }



    return (

        <>

        <Layout
            title="Feedback"
            layout='dense'
            description="This is a list of complaints, compliments and suggestions for your organisation."
            actionContent={
                <>
                </>
            }
            >

                <GridItem colSpan={{ base: 12, md: 12 }}>


                    {loading ? <LoadingCircle /> : null}

                    {list ?

                        
                        <Box>
                
                            <Header c={10}>
                                <Cell>
                                    Reference
                                </Cell>
                                <Cell>
                                    Created
                                </Cell>
                                <Cell>
                                    Reason for Contact
                                </Cell>
                                <Cell>
                                    Service
                                </Cell>
                                <Cell c={2}>
                                    Department
                                </Cell>
                                <Cell>
                                    Expected Outcome
                                </Cell>
                                <Cell>
                                    Status
                                </Cell>
                                <Cell>
                                    Assignees
                                </Cell>
                            </Header>


                        {list.map((item) => {


                            let teams = item.team ? item.team.split(",") : [];

                            let color = 'red';
                            let style = 'solid';

                            let state = item.state;
                            // capitalise
                            state = state.charAt(0).toUpperCase() + state.slice(1);

                            // Get age of item
                            let now = moment();
                            let created = moment(item.created);
                            let age = now.diff(created, 'days');

                            let ageColor = 'green';
                            if (age > 28) {
                                ageColor = 'red';
                            } else if (age > 14) {
                                ageColor = 'orange';
                            }

                            let names = [
                                'Oliver Trampleasure',
                                'Ali Jawad',
                                'Sarah Smith',
                                'John Smith',
                                'Jane Smith',
                            ]

                            // Randomly select between 1-3 names
                            let randomNames = [];
                            let randomNamesCount = Math.floor(Math.random() * 3) + 1;
                            for (let i = 0; i < randomNamesCount; i++) {
                                let randomName = names[Math.floor(Math.random() * names.length)];
                                // Check if name already exists
                                if (randomNames.includes(randomName)) {
                                    // If it does don't add
                                } else {
                                    randomNames.push(randomName);
                                }
                            }

                            
                            return (
                                <Row c={10}
                                    buttons={
                                            <Button 
                                                colorScheme='blue'
                                                variant='outline'
                                                onClick={() => {
                                                    viewItem(item.id);
                                                }}
                                                >
                                                View
                                            </Button>
                                    }
                                    >
                                    
                                    <Cell>
                                        <Text>{item.trackingNumber.toUpperCase()}</Text>
                                    </Cell>
                                    <Cell>
                                        {moment(item.createdAt).format('HH:mm DD/MM/YYYY')}
                                    </Cell>
                                    <Cell>
                                        <Tag size='sm' colorScheme={item.type && colorOptions[item.type.toUpperCase()]}>{item.type}</Tag>
                                    </Cell>
                                    <Cell>
                                        {item.location}
                                    </Cell>
                                    <Cell c={2}>
                                        {item.team.replace(",", ", ")}
                                    </Cell>
                                    <Cell>
                                        <Tag size='sm' colorScheme={item.expectedOutcome && colorOptions[item.expectedOutcome.toUpperCase()]}>{item.expectedOutcome}</Tag>
                                    </Cell>
                                    <Cell>
                                        <Tag size='sm' colorScheme={item.state && colorOptions[item.state.toUpperCase()]}>{item.state.charAt(0).toUpperCase() + item.state.slice(1)}</Tag>
                                    </Cell>
                                    <Cell>
                                        {randomNames.join(", ")}
                                    </Cell>
                                </Row>
                            )
                        })}

                        </Box>

                        :

                        <>

                            {!loading &&

                                <Box>
                                    <Text>
                                        You have no feedback.
                                    </Text>
                                </Box>

                            }

                        </>

                    }

                </GridItem>

            </Layout>

        </>
    )
}