import { Box, Text, Button, Stack, Wrap, Tag, TagLabel, Image, Skeleton} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingCircle from "../../../static/layout/LoadingCircle";

let slides = [
    {
        heading: 'Searching previous procurements',
        image: '/images/external/onedrive.jpg',
        content: <Box>
            <Text>
                Found 3 similar procurements.
            </Text>
            <Wrap mt={2}>
                {['Digital tool for CKD management', 'Autism assessment service', 'Mental health service'].map((service) => {
                    
                    return (
                    <Tag>
                        <TagLabel>
                            {service}
                        </TagLabel>
                    </Tag>
                    )
                })}
            </Wrap>
        </Box>
    },
    {
        heading: 'Checking for external providers',
        content: 
        <Box>
            <Text>
                Found 2 external providers: MedTech Solutions and HealthTech Innovations
            </Text>
            <Wrap mt={2}>
                {['MedTech Solutions', 'HealthTech Innovations'].map((service) => {
                        
                        return (
                        <Tag>
                            <TagLabel>
                                {service}
                            </TagLabel>
                        </Tag>
                        )
                    }
                )}
            </Wrap>
        </Box>
    },
    {
        heading: 'Checking for similar workflows',
        content: 
        <Box>
            <Text>
                Best match found: Open Competition Tender
            </Text>
            <Tag>
                <TagLabel>
                    Open Competition Tender workflow created
                </TagLabel>
            </Tag>
        </Box>
    },
    {
        heading: 'Checking legislation',
        image: '/images/external/govuk.jpg',
        content:
        <Box>
            <Text>
                Found 2 relevant legislations: Public Procurement Act and Data Protection Act
            </Text>
        </Box>
    },
    {
        heading: 'Searching trusted sources', 
        image: '/images/external/nice.png',
        content:
        <Box>
            <Text>
                Found 3 public resources from two sources: NICE and BMJ Publications
            </Text>
            <Wrap mt={2}>
                {[{
                    source: 'NICE',
                    title: 'NICE recommends digital technology to help diagnose ADHD in children and young people',
                },
                {
                    source: 'BMJ',
                    title: 'ADHD in children: Digital test could speed up diagnosis, says NICE',
                }].map((service) => {
                        
                        return (
                        <Tag>
                            <TagLabel>
                                {service.title}
                            </TagLabel>
                        </Tag>
                        )
                    }
                )}
            </Wrap>
        </Box>
    },
    {
        heading: 'Checking for relevant staff',
        image: '/images/external/activedirectory.jpg',
        content:
        <Box>
            <Text>
                Found 9 relevant departments
            </Text>
            <Wrap mt={2}>
                {['Procurement', 'IT', 'Legal', 'Finance', 'Clinical', 'Paediatrics', 'Psychology', 'Psychiatry', 'Safeguarding'].map((service) => {
                        
                        return (
                        <Tag>
                            <TagLabel>
                                {service}
                            </TagLabel>
                        </Tag>
                        )
                    }
                )}
            </Wrap>
        </Box>
    }

]


export default function Process() {

    const [index, setIndex] = useState(-1);

    useEffect(() => {
        // Stop when index = slides.length
        if (index/2 === slides.length) {
            return;
        }
        const interval = setTimeout(() => {
            setIndex(index + 1);
        }
        , 1000);
        
    }, [index]);

    const navigate = useNavigate();

    return (

        <Stack spacing={0} p={10}>

            {slides.map((slide, i) => {

                let actualIndex = (index / 2);

                // If actualIndex is a whole number, display the content
                // If actualIndex is a decimal, display the title
                
                // If actualIndex is greater than i (whole number), display the content
                let displayContent = actualIndex >= i ? 1 : 0;

                // If actualIndex + 0.5 is greater than i (decimal), display the title
                let displayTitle = actualIndex + 1 >= i ? 1 : 0;

                return (

                    <Box display='flex' justifyContent='space-between' alignItems='flex-start' flexDirection='row' opacity={displayTitle} transition='all 0.5s' key={i} h={displayTitle ? 'fit-content' : '0px'}>
                        <Box>
                            <Text color='black' fontWeight='600'>
                                {slide.heading}
                            </Text>
                            <Box pb={8} transition='opacity 0.5s'>
                                {!displayContent ?
                                    <Skeleton height='1em' width='240px' mt={2} opacity={0.5}/>
                                :
                                    <>{slide.content}</>
                                }
                            </Box>
                        </Box>
                        {slide.image &&
                        <Image src={slide.image} objectFit='contain'  w='152px'  ml={8} objectAlign='top' />
                        }
                    </Box>
                )
            })}

            {index / 2 < slides.length -1 &&
                <LoadingCircle />
            }

            {index / 2  >= slides.length -1 &&
            
                <Box>
                    <Text color='black' fontWeight='600'>
                        Completed
                    </Text>
                    <Box>

                        <Button variant='outline' colorScheme='blue' mt={2}
                        onClick={() => navigate('/orgtest/procurement/flow?id=2d5748b7-0dfd-40d7-934f-10a1dca4d397')}
                            >
                            View workflow
                        </Button>
                    </Box>
                </Box>
            }

        </Stack>
    )
    
}