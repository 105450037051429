import React, { useState, useEffect, useContext } from 'react';
import { useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';

import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';


function Card(props) {
    return (
        <GridItem colSpan={{base: 0, md: 2}} borderRadius='md' boxShadow='md' p={4} mb={4} bg='white'>
            {props.children}
        </GridItem>
    )
}

export default function Organisation(props) {



    const [organisation, setOrganisation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let toast = useToast();
    let navigate = useNavigate();


    function getOrganisation() {

        let uuid = secureLocalStorage.getItem('organisationUuid');
        let url = 'organisations/';
        let config = {
            params: {
                uuid: uuid
            }
        };

        let resp = API.getItems({url, config})
        .then(response => {

            console.log("Organisation", response.data);
            setOrganisation(response.data.organisation);
            setLoading(false);

        })
        .catch(error => {
            console.log(error);
            setError(error);
        }

        );

    }



    useEffect(() => {

        getOrganisation();

    }, []);


    return (

        <Layout 
            title='Organisation Details'
            layout='dense'
            actionContent={ 
                <ButtonGroup>
                    <Button display='none' colorScheme='red' variant='outline' onClick={() => navigate('edit', {state: {uuid: organisation.uuid}})}>Edit</Button>
                </ButtonGroup>
            }
            >

            {loading && <Loading />}
            {error && <Splash message={error} />}

            {organisation &&
                <GridItem colSpan='6' alignItems='flex-start' width='fill-available'>
                <Stack spacing={4}>
                    <Card>
                        <Heading as='subtitle' size='lg'>Contact Details</Heading>
                        <Text>
                            {[
                                organisation.address1,
                                organisation.address2,
                                organisation.address3,
                                organisation.address4,
                                organisation.postcode, 
                            ].map((item, index) => {
                                return <span key={index}>{item}<br /></span>
                            })}
                        </Text>
                        <Button size='xs' variant='ghost' colorScheme='blue' 
                            onClick={() => {
                                // Open mailto link
                                window.open('mailto:' + organisation.email);
                            }}
                            >
                                <Icon as={MdMail} mr={2} />
                                {organisation.email}
                        </Button>
                        <Button size='xs' variant='ghost' colorScheme='blue' 
                            onClick={() => {
                                // Open mailto link
                                window.open('tel:' + organisation.phone);
                            }}
                            >
                                <Icon as={MdPhone} mr={2} />
                                {organisation.phone}
                        </Button>

                    </Card>
                    

                    </Stack>
                </GridItem>
            }

        </Layout>


    )

}