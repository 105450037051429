import {Input, GridItem, Icon, Box, Stack, Button, ButtonGroup, Wrap, Flex, Heading, Text, MenuItem, MenuButton, Menu, MenuList, IconButton} from '@chakra-ui/react';
import Layout from '../../core/layout/Layout';
import React, {useState, useEffect} from 'react';
import API from '../../core/utils/API';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Modal from '../../core/components/modal/Modal';
import { useToast } from '@chakra-ui/react'
import pluralize from 'pluralize';
import sc from "states-cities-db";
import { set } from "date-fns";
import Flag from "react-world-flags";

const COUNTRIES = sc.getCountries();

export default function Details(props) {

    return (

        <Stack spacing={4}>

            <InputWrapper
                name='body'
                type='textarea'
                label={props.translate('Description', props.lang)}
                description={props.translate('Please describe the event in as much detail as possible.', props.lang)}
                required={true}
                setValue={props.setValue}
                minH='200px'
                />

        </Stack>

    )

}