
 
export default function FailedCookies() {

    return(

        <>Cannot save cookies from a third party on this device (are you using an iOS device?). Please move to a desktop or update authenitcation settings.</>

    )

}