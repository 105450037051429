import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Image, Menu, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, MenuOptionGroup, border } from '@chakra-ui/react';
import API from "../../core/utils/API";
import {MdOutlineErrorOutline, MdWarning, MdArrowForwardIos, MdFilterList, MdUpload,  MdOutlineRefresh, MdSettings, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward, MdFilter, MdArrowBackIos, MdList, MdInfoOutline, MdRefresh } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import Modal from '../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import Card from './components/Card';
import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import e from 'cors';

import FileView from '../../core/components/files/View';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { set } from 'date-fns';


import { load } from 'webfontloader';
import { bg } from 'date-fns/locale';

import demoFlows from './demo/CurrentProcurements';


function Currency({value}) {
    return (
        <Text>
            £{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
    )
}

function OverviewCard(props) {  

    let internalStatus = props.currentStatus;

    let navigate = useNavigate();
    let colours = {
      'error': 'red.500',
      'inprogress': 'yellow.500',
      'completed': 'green.500',
      'locked': 'gray.500',
    }

    let colourSchemes = {
      'error': 'red',
      'inprogress': 'yellow',
      'completed': 'green',
      'locked': 'gray',
    }

    let highlightColours = {
      'error': 'red.50',
      'inprogress': 'yellow.50',
      'completed': 'green.50',
      'locked': 'gray.50',
    }

    let bids = false;
    if (props.currentSteps && props.currentSteps.includes('Bids received')) {
      bids = true;
      internalStatus = 'completed';
    }


    return (
        <GridItem 

            onClick={() => {
              if (bids) {
                navigate('/orgtest/procurement/bids?id=' + props.uuid);
              } else {
                navigate('/orgtest/procurement/flow?id=' + props.uuid);
              }
            }}

            >
            <Card
                p={4}
                borderRadius='md'
                _hover={{   
                    cursor: 'pointer',
                    bg: highlightColours[internalStatus],
                }}
                onClick={() => {
                    props.updateFilters(props.filters);
                    props.setTemplate(props.cardKey);
                    props.setSubtitle(props.title);
                }}
                borderBottom='3px solid white'
                borderBottomColor={colours[internalStatus]}
                role='group'
                >
                
                <Stack w='100%'>
                    <Heading size='label'>
                        {props.title}
                    </Heading>
                    <Flex justifyContent='space-between' w='100%'>
                      <Moment format='DD/MM/YYYY'>
                        {props.deadline}
                      </Moment>
                      
                        <Tag size='sm' px={2}
                        _groupHover={{
                          /*
                          color: colours[props.currentStatus],
                          bg: 'none',
                          borderColor: colours[props.currentStatus],
                          */
                        }} 
                        variant='outline'
                        colorScheme={colourSchemes[internalStatus]}
                        >
                          {bids ? 
                            'View Bids' 
                            :
                            <>{props.completedSteps} of {props.totalSteps} steps</>
                          }
                      </Tag>
                      
                    </Flex>
                </Stack>
            </Card>
        </GridItem>


    )

}

export default function Dashbaord(props) {

  console.log('demoFlows', demoFlows);


    const [flows, setFlows] = useState(demoFlows);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    let toast = useToast();
    let navigate = useNavigate();


 
    return (

        <Layout 
            layout='dense'
            title='Procurements'
            description={'View and manage procurements'}
            actionContent={ 
                <ButtonGroup
                  variant='outline'
                  colorScheme='blue'
                  >
                    <Button 
                      onClick={() => {
                        navigate('/orgtest/procurement/create');
                      }}
                      >
                        New Procurement
                    </Button>
                </ButtonGroup>
            }
            >


<>
            <GridItem colSpan='12'>


                {error && <Alert status='error'mb={4}>
                    <AlertIcon />
                        <AlertTitle mr={2}>Error</AlertTitle>
                        <AlertDescription>{error.message}</AlertDescription>
                        <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError(null)} />
                    </Alert>
                }

                <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing={4} pb={12}>

                    {Object.keys(flows).map((key) => {
                        if (flows[key].status === 'incomplete') {
                          console.log('incomp flow', flows[key]);
                            return (
                                <OverviewCard 
                                    {...flows[key]}
                                    />
                            )
                        }
                    })}

                </SimpleGrid>
            </GridItem>



            <GridItem colSpan={12} pb={48}>

            <Flex justifyContent='space-between' alignItems='center' w='fill-available' mb={8}>
                            <Heading size='smallertitle'>
                                Completed Procurements
                            </Heading>
                            <ButtonGroup variant='outline'
                                colorScheme='blue'
                                display='none'
                                >

                                <Button>
                                    Select Multiple
                                </Button>                            

                                
                            </ButtonGroup>
                        </Flex>

                {!loading &&


              
                    <Box>


                        <Header c={7}
                            >
                            <Cell c={2}>
                              <Text>Contract</Text>
                            </Cell>
                            <Cell c={2}>
                                <Text>Contractor</Text>
                            </Cell>
                            <Cell>
                                <Text>Awarded</Text>
                            </Cell>
                            <Cell>
                                <Text>Amount</Text>
                            </Cell>
                        </Header>

                        {loading && <LoadingCircle />}

                        {!loading && flows && Object.keys(flows).map((key) => {

                          console.log('flow', flows[key]);
                          let flow = flows[key];
                          if (flow.status === 'incomplete') {
                            return;
                          }
                            // Create timestamp string
                            let time = new Date();
                            let timestamp = time.getTime();
                            let timestampstring = timestamp.toString(); 

                            return (
                                <Row
                                  c={7} 
                                  buttons={
                                    <ButtonGroup
                                      size='xs'
                                      colorScheme='blue'
                                      variant='outline'

                                      >
                                      <Button
                                        onClick={() => {
                                          navigate('/orgtest/procurement/flow?id=' + flow.uuid);
                                        }}
                                        >
                                        View
                                      </Button>
                                    </ButtonGroup>
                                  }
                                  bg='none'
                                  boxShadow='none'
                                  >
                                    <Cell c={2}>
                                        <Text>{flow.title}</Text>
                                    </Cell>
                                    <Cell c={2}>
                                        <Text>{flow.contractor}</Text>
                                    </Cell>
                                    <Cell>
                                        <Moment format='DD/MM/YYYY'>
                                            {flow.awarded}
                                        </Moment>
                                    </Cell>
                                    <Cell>
                                        <Currency value={flow.amount} />

                                    </Cell>
                                    
                                </Row>
                            )
                            
                            })
                        }

                {!loading && flows && flows.length === 0 &&
                    <Box p={4}>
                        <Text>
                            No workflows found.
                        </Text>
                    </Box>
                }

                {!loading && flows === null &&
                    <Box p={4}>
                        <Text color='red.500'>
                            Issue loading workflows
                        </Text>
                    </Box>
                }


                    </Box>
                
                }


            </GridItem>

</>
        </Layout>

    )

}