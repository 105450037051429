import {Heading, List, ListItem, Text, Box, Container, Stack, SimpleGrid, Center} from '@chakra-ui/react';

export default function Security(props) {


    return (
        <Box 
        bg='black'
        color='white'>
        <Container
          py={{
            base: '16',
            md: '24',
          }}
        >
        <Stack
          spacing={{
            base: '4',
            md: '5',
          }}
        >
          <Heading
            size='subtitle'
            color='invert'
            id='security'
          >
            Security
          </Heading>

          <Text color='invert'>
          We use industry-leading encryption to protect your data. Your data is secured at rest, and at any point it is being transmitted. All data is encrypted with AES-256
encryption, meaning each piece of data completes 14 rounds of encryption. That makes it the longest and most powerful encryption standard in the world. It’s so
secure that it would take *billions* of years to crack using current computing technology.</Text>

          <Text color='invert'>
          Additionally, part of our systems run Quantum-Safe Encryption. As Quantum Computing approaches the capability to decipher today's top-tier encryption standards in the coming years, we've proactively equipped ourselves. Your data's future security is protected today, safeguarded by our Quantum-Safe Encryption.
          </Text>

          <Heading pt={12} size='title' color='invert' >Who can see your personal documents:</Heading>
          <Text color='invert'>
          No one but you and the person you decide to share them with. We have specifically designed and built Pyrexia so that we can’t see your personal documents. We never sell or rent your information.
          </Text>

        <Heading pt={12}  size='title' color='invert'>Where your data is stored:</Heading>
        <Text color='white'>
        We use Amazon Web Services (AWS). Our servers are all based in the UK. Your data never leaves the UK. Amazon AWS is the same service the NHS, and
many companies that handle patient information store data. It is also what the NHS uses for NHS login, e-Referral Service, NHS Business Services Authority, and many
other programmes. We follow strict privacy policies and safeguard your data with the utmost care.
        </Text>

        <Heading pt={12}  size='title' color='invert'>What standards we are compliant with:</Heading>
        <Text color='invert'> AWS is compliant to a series of UK, EU, and global data protection standards, including: </Text>
        <List>
            <ListItem>NHS Digital – Data Security and Protection (DSP) Toolkit</ListItem>
            <ListItem>International Organisation for Standardization (ISO) 27001, 27017, 27018, 9001 </ListItem>
            <ListItem>Cyber Essentials Plus </ListItem>
            <ListItem>General Data Protection Regulation (GDPR)/Data Protection Act 2018 </ListItem>
            <ListItem>National Cyber Security Center (NCSC) Cloud Security Principles  </ListItem>

        </List>

        <Text  color='invert'>AWS compliance Programs - this illustrates the robust controls in place at AWS to maintain security and compliance.
https://aws.amazon.com/compliance/programs/ 

AWS is also evaluated by the NHS Digital Data Security and Protection Toolkit:
AWS holds a “Standards Exceeded” status. https://www.dsptoolkit.nhs.uk/OrganisationSearch/8JX11 

</Text>
          
          
          
          </Stack>

          </Container>

            </Box>  

    )
}