import Team from './Team';
import Hero from './Hero';
import Security from './Security';
import Footer from '../layout/Footer';

export default function About(props) {


    return (
        <>
            <Hero />
            <Security/>
            <Team/>
            <Footer />
        </>
    )
}