import React, { useState, useEffect, useContext } from 'react';
import { Image, Menu, MenuList, MenuItem, useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuButton, Divider } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdArrowForward } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/Splash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import Modal from '../../core/components/modal/Modal';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import e from 'cors';

import FileView from '../../core/components/files/View';
import { de } from 'date-fns/locale';
import { set } from 'date-fns';

let demoLinked = {
    company_name: 'Exampled Organisation',
    organisations: [
        {   
            uuid: '1',
            title: 'Company 1',
            recommended: true
        },
        {   
            uuid: '2',
            title: 'Company 2',
            recommended: true
        },
        {   
            uuid: '3',
            title: 'Company 3',
            recommended: false
        }
    ]
}

function ExternalList(props) {

    const [selectedUuid, setSelectedUuid] = useState(null);

    const [clients, setClients] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showAll, setShowAll] = useState(false);

    // create toast
    const toast = useToast();

    function matchmaking() {

       // console.log('Matchmaking ' + props.clientUuid + ' with ' + props.source);
        
        let url = '/organisations/accounts/clients/matchmaking';
        let config = {
            params: {
                clientUuid: props.clientUuid,
                source: props.source
            }
        };

        API.getItems({url, config})
        .then(response => {

            console.log(response.data);
            setClients(response.data.clients);
            setLoading(false);

        })
        .catch(error => {

            //console.log(error);

            setError(true);
            setLoading(false);

        });
    }

    useEffect(() => {
        matchmaking();
    }, []);

    function link(clientUuid, source, sourceId) {

        setLoading(true);
        //console.log('Linking ' + clientUuid + ' with ' + source + ' ' + sourceId);

        let url = '/organisations/accounts/clients/link';
        let config = {
            params: {
                clientUuid: clientUuid,
                source: source,
                sourceId: sourceId
            }
        };

        API.getItems({url, config})
        .then(response => {

            //console.log(response.data);

            toast({
                title: 'Linked',
                description: props.company_name + ' linked to ' + source,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            
            props.onClose();
            props.onSelect();

            setLoading(false);

        })
        .catch(error => {

            console.log(error);

            setError(true);
            setLoading(false);

        });
    }
    


    return (
        <Modal
            title='Linking Companies'
            onClose={props.onClose}
            actionContent={
                <ButtonGroup variant='outline' colorScheme='blue' size='sm'>
                    <Button onClick={()=>{setShowAll(!showAll)}}>{showAll ? 'Show Recommended' : 'Show All'}</Button>
                    <Button isDisabled={selectedUuid === null}
                        colorScheme='blue'
                        variant='solid'
                        onClick={() => {link(props.clientUuid, props.source, selectedUuid)}}
                        >Select</Button>
                </ButtonGroup>
            }
            >

            <Stack spacing={2}  mb={2}>
                <Text>
                    Select a company to link to:
                </Text>
                <Stack spacing={0}>
                    <Text >
                        {props.company_name}
                    </Text>
                    <Text color='subtle'>
                        {props.company_address}
                    </Text>
                </Stack>
                {!loading && 
                    <Text>
                        {Object.keys(clients).length} {pluralize('company', Object.keys(clients).length)} found. 
                    </Text>
                }
            </Stack>
            <Divider />
            <Stack>
                {loading && <LoadingCircle />}
                {error && 
                    <Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>There was an error loading the data.</AlertDescription>
                    </Alert>
                }
                {clients && Object.keys(clients).map(clientKey => {
                    let client = clients[clientKey];
                    if (!showAll && client.recommended !== true) {
                        return;
                    }
                    return (
                        <Box onClick={()=>{setSelectedUuid(client.id)}} 
                            border={selectedUuid === client.id ? '2px solid blue' : '2px solid transparent'}
                            borderColor={selectedUuid === client.id ? 'blue.500' : 'transparent'}
                            p={2} cursor='pointer'>
                            <Text>{client.name} {client.recommended && <Tag size='sm' colorScheme='green'>Recommended</Tag>}</Text>
                            {client.justification && <Text color='subtle'>
                            {'Recommended for matching ' + client.justification.join(", ")}</Text>}
                        </Box>
                    )
                })}
            </Stack>
            <Divider />
        </Modal>
    )
}


export default function Accounts(props) {


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [clients, setClients] = useState([]);

    const [linkedSources, setLinkedSources] = useState({});

    const toast = useToast();

    function getClients() {

        let url = '/organisations/accounts/clients/get';
        let config = {
            params: {
            }
        };

        API.getItems({url, config})
        .then(response => {

            console.log(response.data);

            setClients(response.data);

            setLoading(false);

        })
        .catch(error => {

         //   console.log(error);

            setError(true);

        });

    }

    function getClientsExternal(source) {

        setLoading(true);
            
        let url = '/organisations/accounts/clients/get/external/' + source;
        let config = {
            params: {
            }
        };

        API.getItems({url, config})
            .then(response => {
    
               // console.log(response.data);

                getClients();
    
            })
            .catch(error => {
    
               // console.log(error);
    
                setError(true);
                setLoading(false);
    
            });


    }

    function deleteAll() {

        let url = '/organisations/accounts/clients/delete/all';
        let config = {
            params: {
            }
        };

        API.getItems({url, config})
        .then(response => {

            //console.log(response.data);

            setLoading(false);

        })
        .catch(error => {

            //console.log(error);

            setError(true);

        });
    }

    useEffect(() => {
        getClients();
    }, []);


    return (

        <Layout 
            layout='dense'
            title='Clients'
            description={
                <>
                    <Text>An overview of all your clients.</Text>
                    <Text color='subtle'>Clients only imported from Tradeshift and then linked to Xero and ETZ. Must be linked to Xero for automated invoice submission.</Text>
                </>
            }
            loading={loading}
            error={error}
            actionContent={
                <ButtonGroup spacing={4} mb={4} variant='outline' display='none'>
                    <Button onClick={() => {
                            // Dialogue box to confirm
                            if (window.confirm('Are you sure you want to reset all Tradeshift clients? This will delete all clients and re-import from Tradeshift.')) {

                                getClientsExternal('tradeshift')
                            }
                                    
                        }}
                        >
                        Reset TS Clients
                    </Button>
                    <Button display='none' onClick={() => deleteAll()} colorScheme='red' >Delete All</Button>
                </ButtonGroup>
                }
            >

            <GridItem colSpan={12}>

                {Object.keys(linkedSources).includes('company_name') &&
                    <ExternalList {...linkedSources}
                        onSelect={() => {getClients()}}
                        onClose={() => {setLinkedSources([])}}
                        />
                }

                <Header c={5} buttons='hide'>
                    <Cell c={2}>
                        <Text>Client</Text>
                    </Cell>
                    <Cell c={2}>
                        <Text>Details</Text>
                    </Cell>
                    <Cell>
                        <Text>Linked Sources</Text>
                    </Cell>
                </Header>

                {clients.map(client => {

                    let skip = ['uuid', 'company_name', 'description', 'createdAt', 'updatedAt',
                        'address_buildingnumber', 'address_street', 'address_city', 'address_county', 'address_zip', 'address_country',
                        'contact_email', 'contact_phone'
                    ];
                    let objects = [];

                    let blocked = client.ClientTags && client.ClientTags[0].blocked ? true : false;

                    let externalSources = [
                        {
                            title: 'Tradeshift',
                            column_suffix: 'tradeshift',
                            icon: '/images/partners/tradeshift.png'
                        },
                        {
                            title: 'Xero',
                            column_suffix: 'xero',
                            icon: '/images/partners/xero.png'
                        }/*,
                        {
                            title: 'ETZ',
                            column_suffix: 'etz',
                            icon: '/images/partners/etz.png'
                        }*/
                    ]

                    // Add externalSources to skip
                    skip = skip.concat(externalSources.map(source => {
                        return 'external_id_' + source;
                    }));

                    let addressKeys = [
                        {
                            key: 'address_buildingnumber',
                            suffix: ' '
                        },
                        {
                            key: 'address_street',
                            suffix: ', '
                        },
                        {
                            key: 'address_city',
                            suffix: ', '
                        },
                        {
                            key: 'address_county',
                            suffix: ', '
                        },
                        {
                            key: 'address_zip',
                            suffix: ', '
                        },
                        {
                            key: 'address_country',
                            suffix: ' '
                        }
                    ]
                    let address = '';
                    addressKeys.map(key => {
                        if (client[key.key] !== null && client[key.key] !== '') {
                            address += client[key.key] + key.suffix;
                        }
                    });

                    let contactMethods = [
                        /*
                        {
                            key: 'name',
                            icon: <MdOutlineVisibility />,
                            value: client.contact_name,
                            url: '',
                            disabled: client.contact_name && client.contact_name !== '' ? false : true
                        },*/
                        {
                            key: 'phone',
                            title: 'Phone',
                            icon: <MdPhone />,
                            value: client.contact_phone,
                            url: 'tel:' + client.contact_phone,
                            disabled: client.contact_phone && client.contact_phone !== '' ? false : true
                        },
                        {
                            key: 'email',
                            title: 'Email',
                            icon: <MdMail />,
                            value: client.contact_email,
                            url: 'mailto:' + client.contact_email,
                            disabled: client.contact_email && client.contact_email !== '' ? false : true
                        },
                    ]

                    // Order contact methods by disabled, false to true
                    contactMethods.sort((a, b) => {
                        return a.disabled - b.disabled;
                    });




                    return (
                        <Row c={5} buttons='hide'>
                            <Cell c={2}>
                                <Text>{client.company_name}</Text>
                                <Text color='subtle' mb={2}>
                                    {client.description}
                                </Text>
                                {client.ClientTags && client.ClientTags.map(tag => {
                                    return (
                                        <Tag size='sm' colorScheme={tag.blocked ? 'red' : 'blue'}>
                                            {tag.blocked ? 'Blocked' : 'Active'}
                                            <TagRightIcon as={tag.blocked ? MdClose : MdCheck} />
                                        </Tag> 
                                    )
                                })}
                            </Cell>
                            <Cell c={2}>
                                <Stack spacing={1}>
                                    <Wrap spacing={2} mb={1}>
                                        {contactMethods.map(method => {
                                            return (
                                                <Button size='xs' variant='outline' colorScheme={method.disabled ? 'red' : 'blue'}
                                                    cursor={method.disabled ? 'not-allowed' : 'pointer'}
                                                    as={method.disabled ? 'span' : 'a'}
                                                    href={method.url} target='_blank'
                                                    leftIcon={method.icon}
                                                    //isDisabled={method.disabled}
                                                    >
                                                    {method.value ? method.value : 'No ' + method.title}
                                                </Button>
                                            )
                                        })}
                                    </Wrap>
                                    <Text color='subtle'>
                                        <Text as='span' color='black'>Contact: </Text>{client.contact_name ? client.contact_name : 'No contact name'}
                                    </Text>
                                    <Text color='subtle'>
                                        <Text as='span' color='black'>Address: </Text>{address}
                                    </Text>
                                </Stack>

                                <Wrap spacing={2} display='none'>
                                    {Object.keys(client).map(key => {


                                        if (skip.includes(key)) {
                                            return;
                                        }

                                        // Check if key value is an object
                                        if (typeof client[key] === 'object') {
                                            objects.push(key);
                                            return;
                                        }

                                        if (client[key] === null || client[key] === '') {
                                            return;
                                        }

                                        return (
                                            <Tag size='xs'>{key}: {client[key]}</Tag>
                                        )
                                    })}

                                    {// Create a comma separated list of objects
                                        "Objects: " + objects.join(', ')
                                    }
                                </Wrap>
                            </Cell>
                            <Cell>
                                <Wrap spacing={2}>
                                    {externalSources.map(source => {

                                        let color = 'green';
                                        let text = 'Linked';
                                        if (client['external_id_' + source.column_suffix] === null || client['external_id_' + source.column_suffix] === '') {
                                            color = 'red';
                                            text = 'Not Linked';
                                        }
                                        return (
                                            <Button size='xs' variant='outline' colorScheme={color} 
                                                onClick={() => {
                                                    setLinkedSources({
                                                        company_name: client.company_name,
                                                        company_address: address,
                                                        source: source.column_suffix,
                                                        clientUuid: client.uuid
                                                    }
                                                )}}
                                                >
                                                {source.title} {text}
                                            </Button>
                                        )
                                    })}

                                    <Menu>
                                        <MenuButton as={Button} size='xs' variant='outline' colorScheme='blue' rightIcon={<MdExpandMore />}>
                                            Actions
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => {
                                                let url = '/organisations/accounts/clients/toggleblock';
                                                let config = {
                                                    params: {
                                                        clientUuid: client.uuid,
                                                        clientTagUuid: client.ClientTags[0].uuid    
                                                    }
                                                };
                                                API.getItems({url, config})
                                                .then(response => {
                                                    getClients();
                                                    toast({
                                                        title: blocked ? 'Unblocked' : 'Blocked',
                                                        description: client.company_name + ' ' + (blocked ? 'unblocked' : 'blocked') + ' from all sources.',
                                                        status: 'success',
                                                        duration: 3000,
                                                        isClosable: true,
                                                    });
                                                })
                                                .catch(error => {
                                                    setError(true);
                                                });

                                            }}>
                                                {blocked ? 'Unblock' : 'Block'}
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                let url = '/organisations/accounts/clients/unlink';
                                                let config = {
                                                    params: {
                                                        clientUuid: client.uuid,
                                                        clientTagUuid: client.ClientTags[0].uuid    
                                                    }
                                                };
                                                API.getItems({url, config})
                                                .then(response => {
                                                    getClients();
                                                    toast({
                                                        title: 'Unlinked',
                                                        description: client.company_name + ' unlinked from all sources.',
                                                        status: 'success',
                                                        duration: 3000,
                                                        isClosable: true,
                                                    });
                                                })
                                                .catch(error => {
                                                    setError(true);
                                                });
                                            }}>
                                                Cancel Link
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Wrap>
                            </Cell>
                        </Row>
                    )})
                }


            </GridItem>

            

        </Layout>

    )

}