import {
    Badge,
    Box,
    Button,
    Heading,
    Container,
    Grid,
    Stack,
    Link,
    Text,
    Tag,
    useBreakpointValue,
    useColorModeValue,
  } from '@chakra-ui/react';
  import * as React from 'react';
  import { FiDownloadCloud } from 'react-icons/fi';
import { Link as ReachLink } from "react-router-dom";
import { useState } from "react";
import CustomModal from "../components/modal/Modal";

export default function DashLayout(props) {

    const [showModal, setShowModal] = useState(false);
    
    const handleClose = () => {
        setShowModal(false);
    }
    const handleShow = () => {
        setShowModal(true);
    };

    return (
  
        <Box as="section" w='100%'
        
        id='main-layout'
        overflowY='scroll'
        h='100%'
        {...props}
            >
        
            <Container
                pt={{base: '8', lg: '12', }}
                pb={{ base: '12',lg: '12',}}
                mx='auto' 
                maxW={props.layout === 'dense' ? 'unset !important' : 'container.xl'}
                h='100%'
                >

                <Stack spacing={{base: '8', lg: '6',}} 
                    h='100%'
                    >

                    <Stack
                        spacing="4"
                        direction={{ base: 'column'}}
                        justify="space-between"
                        mb={{
                            base: 8,
                            md: 8
                        }}
                        mt={{
                            base: 4,
                            md: 8
                        }}
                        mt={props.titleLayout === 'dense' ? '0' : '8'}
                        mb={props.titleLayout === 'dense' ? '0' : '8'}

                        
                        >
                        
                        <Stack spacing="1" direction={{base: 'column', md: 'row'}} justifyContent='space-between'
                        
                            >
                            <Heading size={props.titleSize === undefined ? 'title' : props.titleSize} maxW='800px'>
                                {props.title}
                                {props.supplementalTitle &&
                                    <Tag ml={4} verticalAlign='middle' colorScheme='blue'>
                                        {props.supplementalTitle}
                                    </Tag>
                                }
                                {props.titleSecondLine &&   
                                    <>
                                        <br />
                                        {props.titleSecondLine}
                                    </>
                                }
                            </Heading>

                            <Stack direction="row" spacing="3" pt='25px'>
                                {props.actionContent}
                                {props.secondaryButtonText && props.secondaryButtonURL && (
                                    <Link as={ReachLink} to={props.secondaryButtonURL}>
                                        <Button variant='outline' borderColor='#DC564C' borderWidth='2px' color='#DC564C' leftIcon={<FiDownloadCloud fontSize="1.25rem" />}>
                                            {props.secondaryButtonText}
                                        </Button>
                                    </Link>
                                )}
                                {props.primaryButtonText && props.primaryButtonURL && (props.primaryButtonModal !== true) && (
                                    <Link as={ReachLink} to={props.primaryButtonURL}>
                                        <Button variant="outline" borderColor='#5321BF'>
                                            {props.primaryButtonText}
                                        </Button>
                                    </Link>
                                )}
                                {props.primaryButtonText && (props.primaryButtonModal === true) && (
                                    <Button onClick={handleShow} variant="outline" borderColor='#DC564C' borderWidth='2px' color='#DC564C' >
                                        {props.primaryButtonText}
                                    </Button>
                                )}
                            </Stack>
                        </Stack>

                        <Text size='pagetitle-description'>{props.description}</Text>

                    </Stack>

                    {showModal && 
                        <CustomModal title={props.modalTitle} onClose={handleClose}>
                            {props.modalContent}
                        </CustomModal>
                    }

                    <Grid 
                        //templateRows='repeat(2, 1fr)'
                        templateColumns={useBreakpointValue({base: 'repeat(1, 1fr)', lg: 'repeat(6, 1fr)', })}
                        gap={0}//{useBreakpointValue({base: 1, lg: 4, })}
                        margin-right={8}
                        margin-left={8}
                         h='fill-available'
                         as={props.gridAs ? props.gridAs : 'div'}
                        >
                        {props.children}
                    </Grid>
                    

                </Stack>

        </Container>
    </Box>
)}