import Card from '../components/cards/Card';
import {
    AvatarGroup,
    Avatar,
    Box,
    Button,
    Divider,
    Image,
    Heading,
    HStack,
    Icon,
    IconButton,
    Stack,
    Tag,
    Link, 
    TagLabel,
    TagLeftIcon,
    Text,
    useColorModeValue,
    ButtonGroup,
    Wrap,
    Badge,
    Table, 
    Tr, 
    Td,
    Th,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Grid, GridItem,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { HiCash, HiLocationMarker, HiShieldCheck } from 'react-icons/hi'
import {MdLabel, MdAdd, MdLink, MdPictureAsPdf, MdOutlineExpandMore, MdCheck, MdClose, MdLabelOutline} from 'react-icons/md';
import { Link as ReachLink } from "react-router-dom";
import Moment from 'react-moment';
import PDFIcon from './pdf.jpg';
import DocumentRow from './DocumentRow';
import moment from 'moment';
import DocumentList from './DocumentList';
import AccessList from '../../access/AccessList';

export default function GroupCard(props) {

    const [show, setShow] = React.useState(true);

    let requirementGroup = props.requirementGroup;
    //console.log("GroupCard requirementGroup ", requirementGroup);

    let requirements = [];
    let taggedRequirements = [];
    let untaggedRequirements = [];
    let documents = [];

    // RequirementGroupings - Requirement - Records [array] - Evidence [single]

    let requirementsSummary = [];
    requirementGroup.RequirementGroupings.map((requirementGrouping) => {

      
      let tempObj = {
        title: requirementGrouping.Requirement.title,
        totalRecordCount: requirementGrouping.Requirement.Records.length
      };

      let taggedAndCurrentcount = 0;

      if (requirementGrouping.Requirement.Records.length > 0) {

        taggedRequirements.push(requirementGrouping.Requirement.title);

        // Check if file already added to list of evidence
        requirementGrouping.Requirement.Records.map((record) => {

          if (record.Evidence === null) {
            return;
          }

          //console.log("EXPIRED", record.expiration, (new Date(record.expiration).getTime() > Date.now()))
          if (record.expiration === undefined || record.expiration === null || record.expiration === '' || new Date(record.expiration).getTime() > Date.now()) {
            taggedAndCurrentcount++;
          }

          var index = documents.findIndex(x => x.uuid==record.Evidence.uuid); 
          // If not found create a new entry, if found add requirement to existing entry
          index === -1 ? documents.push({
            title: record.Evidence.title,
            uuid: record.Evidence.uuid,
            userUuid: record.Evidence.userUuid,
            requirements: [requirementGrouping.Requirement.title],
            archived: record.Evidence.archived,
            issued: record.Evidence.issued,
            expiration: record.Evidence.expiration,
            DocumentValidations: record.Evidence.DocumentValidations,
            expirationTime: moment.duration(moment(record.Evidence.expiration).diff(moment(new Date()))),
            issuer: record.Evidence.issuer,
          }) : documents[index].requirements.push(requirementGrouping.Requirement.title);


        })


      } 
      
      if (taggedAndCurrentcount === 0) {
        untaggedRequirements.push({
          title: requirementGrouping.Requirement.title,
          recommendedProviderName: requirementGrouping.Requirement.recommendedProviderName,
          recommendedProviderURL: requirementGrouping.Requirement.recommendedProviderURL,
          recommendedProviderLogoURL: requirementGrouping.Requirement.recommendedProviderLogoURL,
        });
      }

      requirements.push(tempObj);

    });

    //evidence = [...new Map(evidence.map(item =>
    //  [item['uuid'], item])).values()];

    
    let accessCurrents = [];
    let accessRequests = [];
    let accessInvites = [];

    requirementGroup.Accesses.map((access) => {

      //console.log("Access ", access);
      //console.log((new Date(access.expiration).getTime() > Date.now()) );
      //console.log((access.expiration == undefined));
      //console.log(!access.cancelled);

      if ((new Date(access.expiration).getTime() > Date.now() || access.expiration == undefined) && !access.cancelled) {

       // console.log("In a current access / invite / request");
      
        let tempAccess = {
          uuid: access.uuid,
          createdAt: access.createdAt,
        }
        if (access.accessor != null) {
          tempAccess.firstName = access.accessor.firstName;
          tempAccess.lastName = access.accessor.lastName;
          tempAccess.email = access.accessor.email;
          tempAccess.avatar = 'https://randomuser.me/api/portraits/med/men/' + Math.floor(Math.random() * (100 - 1) + 1) + '.jpg';
        } else {
          tempAccess.firstName = access.email;
          tempAccess.lastName = '';
          tempAccess.email = access.email;
          tempAccess.avatar = '';
        }


        if (access.approved && access.accepted) {

          //console.log("Found current access", access);
            accessCurrents.push({
              ...tempAccess,
              actionRequired: false
            });

        } else if (access.approved === null && access.accepted ) {

          //console.log("Request found");
            accessRequests.push({
              ...tempAccess,
              actionRequired: true
            });

        } else if (access.approved && access.accepted === null) {

          //console.log("Invite found");
            accessInvites.push({
              ...tempAccess,
              actionRequired: false
            });

        }
      }

    });
    
    //console.log("Documents:", documents);

    //console.log("Accesses ", accessCurrents, accessRequests, accessInvites);

    return (

        <Box 
        mb={0} >
        <Stack direction={{
            base: 'column',
            md: 'row'

        }}
        pt={6}
        justifyContent='space-between'
        >
              <Heading size='smalltitle' mb={{base: 0, md: 4}}>
                {requirementGroup.title}
              </Heading>

              {props.mode === 'me' &&
                <AccessList 
                  requirementGroupUuid={requirementGroup.uuid} 
                  requirementGroupTitle={requirementGroup.title}
                  userUuid={props.userUuid}
                  accessCurrents={accessCurrents}
                  accessRequests={accessRequests}
                  accessInvites={accessInvites}
                  refresh={props.refresh}
                  />
              }

              {props.mode === 'hr' && 
                <Box textAlign={{base: 'left', md: 'right'}} fontSize='sm' opacity='0.4' pt={{base: 0, md: 4}} pb={{base: 2, md: 0}}>
                  <Text>Access expires on</Text>
                  <Text>
                    {requirementGroup.Accesses[0].expiration === null ?
                      'No expiry set'
                      :
                      moment(requirementGroup.Accesses[0].expiration).format('LLL')
                    }
                  </Text>
                </Box>

              }

        </Stack>
        <Card 
          p={0}
        > 
          <Stack
            align="flex-start"
            >
              
            
            <DocumentList 
              documents={documents} 
              mode={props.mode}
              requirementGroupUuid={requirementGroup.uuid}
              staleTime={props.staleTime}
              refresh={props.refresh}
              mode={props.mode}
              footer={
               
                <Box  pl={4} colSpan={4} borderTop='1px solid whitesmoke' pt={4}>  
                  {untaggedRequirements.length == 0 ?
                    <Tag mb={4} colorScheme='green' variant='outline' borderRadius='full' size='sm'><Icon as={MdCheck} mr={1} /> All required tags assigned to documents</Tag>
                  :
                  <>
                    <Text fontSize='sm' lineHeight='1em' m={0} mb={4}>
                      Missing Tags:
                    </Text>
                    <Wrap mb={2} pb={2}>
                      {untaggedRequirements.map((e) => {
                            return (
                              <Tag bg='gray.100' color='red.500' borderRadius='full' size='sm'>
                                {e.title}
                                {(e.recommendedProviderName != undefined && e.recommendedProviderURL != undefined) &&
                                  <Link href={e.recommendedProviderURL} isExternal pl='2px' mb='-3px'>
                                    <Icon as={MdLink} />
                                  </Link>
                                }
                              </Tag>
                            )
                          })
                      }
                    </Wrap>

                  </>
                  }
                </Box> 
                
              }/>






            </Stack>
        </Card>
    
        </Box >
    )
  
}