import {
    Avatar,
    AvatarGroup,
    Box,SkeletonText,
    Badge, 
    Button,
    Heading,
    Input, 
    Container,
    Flex,
    Checkbox,
    List,
    Image,
    SimpleGrid,
    Grid, GridItem,
    Progress, 
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider,
    HStack,
    Tooltip,
    Link,
    Icon,
    Wrap,
    Tag, Menu, MenuItem, MenuButton, MenuList,
    Tab, TabList, TabPanels, TabPanel, Tabs,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td, TableCaption,
    FormControl, FormLabel, FormHelperText, FormErrorMessage,
  } from '@chakra-ui/react';
import react, { useState, useEffect, useRef } from 'react';
import Layout from '../core/layout/Layout';
import {MdOutlineExpandMore, MdArrowForward, MdLock} from 'react-icons/md';
import moment from 'moment';
import pluralize from 'pluralize';
import { useNavigate } from 'react-router-dom';

function AIToolBox(props) {
    const navigate = useNavigate();

    function navigateOptions(e) {
        
        // check if props.url has mailto: or http: or https: or www. or .com or .co.uk or .org or .net
        // if so, navigate to props.url
        // else, navigate to /ai/props.url

        if (props.url.substring(0, 7) == 'mailto:') {

            // Open new window
            window.open(props.url, "_blank");
            
        } else if (props.url.substring(0, 5) == 'http:' || props.url.substring(0, 6) == 'https:') {

            window.open(props.url, "_blank");
        } else if (props.url.substring(0, 4) == 'www.') {

            window.open(props.url, "_blank");
        } else if (props.url.substring(props.url.length - 4, props.url.length) == '.com' || props.url.substring(props.url.length - 7, props.url.length) == '.co.uk' || props.url.substring(props.url.length - 4, props.url.length) == '.org' || props.url.substring(props.url.length - 4, props.url.length) == '.net') {

            window.open(props.url, "_blank");
        } else {

            navigate(props.url);

        }

    }

    return (

        <Box onClick={(e)=> {navigateOptions(e)}} className='gradient-background hover-only' p='2px' cursor='pointer' borderRadius="lg" overflow="hidden" {...props}>
            <Box position='relative' p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" bg='white'>

                {props.children}

                <Icon as={MdArrowForward} color='black' w={6} h={6} position='absolute' right={4} bottom={4} />

            </Box>
        </Box>


    )
}


export default function Dashboard(props) {


    const navigate = useNavigate();

    function subLink(url) {

        // Open a new window
        window.open(url, "_blank");
        
    }

    return (

        <Layout
            title="Dashboard"
            actionContent={
                <>
                    <Button display='none' variant='outline' colorScheme='blue'  onClick={()=> {navigate('/my/profile')}}>Profile</Button>
                    <Button variant='outline' colorScheme='blue'  onClick={()=> {navigate('/my/organisations')}}>Switch Role</Button>

                    <Button variant='outline' colorScheme='red' onClick={()=> {navigate('/my/subscriptions')}}>Subscribe</Button>
                </>
            }
            >


            <GridItem pr={{base: '0', lg: '0'}} pb={{base: '1em', lg: '72px'}} colSpan={{base: '6', md: '2'}} rowSpan='2' alignItems='stretch' display='flex' width='fill-available'>

                <AIToolBox url='/my/passport' display='flex' alignItems='stretch'>
                    
                    <Heading size='smallertitle' mb={4}>Pyrexia<br/>Passport</Heading>
                    <Text mb={4} fontWeight='600'>
                        Your digital staff passport.
                    </Text>
                    <Text mb={4}>
                        Just one secure document upload, and you can rest easy knowing your files are safe and protected, no matter how many times you switch between departments or organisations.
                    </Text>


                    <Box >
                        <Icon as={MdLock} color='black' w={6} h={6} position='absolute' right={4} top={4} />
                        <Text color='blue.500' className='show-on-sibling-hover'>Docments and sharing secured with multi-factor authentication.</Text>
                    </Box>

                </AIToolBox>

            </GridItem>


            <GridItem pb='72px' colSpan='4' rowSpan='2' alignItems='flex-start' width='fill-available'>

                <Stack spacing={4}>

                    <AIToolBox url='/pay/schedules'>

                        <Heading size='smallertitle' mb={4}>Salary Checker</Heading>
                        <Text mb={4}>Upload a PDF of your work schedule and we’ll check if your salary is correct, including <Text as='span' color='blue.500' cursor='pointer' _hover={{textDecoration: 'underline'}} onClick={(e) => {e.preventDefault(); subLink('https://www.pyrexia.io/prospectiveleave')}}>prospective leave underpayment</Text> checks.</Text>

                    </AIToolBox>

                    <AIToolBox url='/my/ai/reflection' display='none'>

                        <Heading size='smallertitle' mb={4}>Reflection Generator</Heading>
                        <Text mb={4}>Create a draft clinical case reflection for any portfolio.</Text>

                    </AIToolBox>
                    
                    <AIToolBox url='/my/ai/teaching'>

                        <Heading size='smallertitle' mb={4}>Teaching Sessions</Heading>
                        <Text mb={4}>Generate learning objectives and a detailed lesson plan on a subject of your choice.</Text>

                    </AIToolBox>
                    
                    <AIToolBox url='mailto:oliver@pyrexia.io'>

                        <Heading size='smallertitle' mb={4}>Contact Us</Heading>
                        <Text mb={4}>We're always happy to chat, drop us an e-mail and we'll get back to you.</Text>

                    </AIToolBox>                    

                </Stack>

            </GridItem>


        </Layout>

    )
}