import { Textarea } from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";
import React from "react";

export const AutoResizeTextarea = React.forwardRef((props, ref) => {


    let onChange = props.onChange;

    if (onChange === undefined ) {
        
        onChange = function(e) {
            props.setValue( props.name, e.target.value);
        }
    }


  return (
    <Textarea
      minH={props.minH || "unset"}
      overflow="hidden"
      w="100%"
      resize="none"
      transition="height none"
      ref={ref}
      minRows={1}
      as={ResizeTextarea}
      {...props}
      onChange={onChange}
      
    />
  );
});