/*

    This is the main component for the onboarding process. It will render the sub-components

    We need the following:

    

*/

import React, { useState, useEffect } from 'react';
import { Wrap, Circle, Flex, Box, Text, Button, Icon, Heading, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdExpandMore } from 'react-icons/md';
//import Subscription from './Subscription';
import { bg, is } from 'date-fns/locale';
import Splash from '../../../static/layout/NewSplash';
import Summary from './Summary';
import Panel from './Panel';
import Modal from '../modal/Modal';
import API from '../../utils/API';

const allowSkip = false;

const sidebarPosition = 'left';

const colorSchemes = {
    completed: {
        bg: 'blue.500',
        borderColor: 'blue.500',
        color: 'white'
    },
    current: {
        borderColor: 'blue.500',
        color: 'white'
    },
    incomplete: {
        bg: 'gray.100',
        borderColor: 'gray.200',
        color: 'gray.700'
    }
}


function Wrapper(props) {
    return (
        <>
            {props.modal ?

                <Modal
                    maxW='632px'
                    bodyPadding={0}
                    hideFooter={true}
                    >
                    {props.children}
                </Modal>

                :


                <Splash 
                    w='fit-content'
                    h='fill-available'
                    mt={0}
                    p={0}
                    pb={0}
                    externalPy={4}
                    >
                    {props.children}
                </Splash>
        
        
            }
        </>
    )
}

export default function Process(props = {
}) {

    // Get state
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState(props.summary === true ? [...props.steps, {title: 'Summary', component: <Summary />}] : props.steps );
    const [data, setData] = useState(props.data);
    const [requiredCompleted, setRequiredCompleted] = useState(false);
    const [formMessage, setFormMessage] = useState(null);

    console.log("Steps", steps);
    console.log("Data", data);

    function updateData(newData) {

        let tmp = data;

        console.log("New data", newData);


        // loop through data which will be an an object with key values
        Object.keys(newData).forEach((key) => {

            console.log("Checking for key", key, newData[key], tmp[key]);

            let value = newData[key]; // Get the new value
            let required = tmp[key] && tmp[key].required ? tmp[key].required : undefined;
            let error = tmp[key] && tmp[key].error ? tmp[key].error : undefined;

            // Create new object to replace

            // Check if value is an object with a value
            if (typeof value === 'object') {
                console.log("Value is an object", value);
                required = value.required;
                error = value.error;
                value = value.value;
            } else {
                console.log("Value is not an object", value);
            }

            // Check if key exists in data
            if (tmp[key] === undefined) {
                // Create it
                tmp[key] = {};
            }

            let val = {
                value,
                required,
                error
            }
            tmp[key] = val;
            

        })

        // Loop through all data and check if all required fields are completed
        let tmpRequiredCompleted = true;
        Object.keys(data).forEach((key) => {
            if (data[key].required && (data[key].value === '' || data[key].value === undefined || data[key].value === null)) {
                tmpRequiredCompleted = false;
            }
        });
        if (requiredCompleted !== tmpRequiredCompleted) {
            setRequiredCompleted(tmpRequiredCompleted);
        }
        
        setData(tmp);

        console.log("Updated data", data);  

        
    }


    useEffect(() => {
    }, [steps, data]);


    const onSubmit = () => {

        setLoading(true);

        let flatData = {};
        Object.keys(data).forEach((key) => {
            flatData[key] = data[key].value;
        });

        console.log("Flat Data:", flatData);

        if (props.item != undefined) {

            let response = API.patchItems({url: props.url, data: flatData})
            .then(function (response) {
                console.log(response);
                setFormMessage("Item updated successfully");
                setLoading(false);
                if (props.refresh) {
                    props.refresh();
                }
            })
            .catch(function (error) {
                console.log(error);
                setFormMessage("Error updating item");
                setLoading(false);
            });
        } else {
            let response = API.postItems({url: props.url, data: flatData})
            .then(function (response) {
                console.log(response);
            // window.location.reload(false);
                setFormMessage("Item created successfully");
                setLoading(false);
                if (props.refresh) {
                    props.refresh();
                }

                //window.location.reload(false);

            })
            .catch(function (error) {
                console.log(error);
                //window.location.reload(false);
                setFormMessage("Error creating item");
                setLoading(false);
                //props.refresh();
                //window.location.reload(false);

            });
        }
    }

    
    return (
        <>
        {!loading &&

        <Wrapper modal={props.modal} onCancel={props.onCancel}>
            
            <Flex h='fill-available' minH={steps.length * 100}
                alignItems='stretch'
                >


                <Box
                    display='none'
                    w={showSidebar ? 'auto' : '50px'}
                    p={showSidebar ? 4 : 4}
                    py={5}
                    borderRight='solid 1px whitesmoke'
                    position='relative'
                    >
                    <Box position='absolute' top={6} bottom={6} w='0px' borderLeft='dashed 1px black' borderColor='gray.300' left='50%' />
                    <Flex flexDirection='column' justifyContent='space-between' h='100%'>
                        {steps.map((step, index) => {

                            let status = currentStep === index ? 'current' : (currentStep > index ? 'completed' : 'incomplete');


                            return (
                                <Box
                                    key={index}
                                    onClick={() => {
                                        if (status === 'incomplete' && !allowSkip) {
                                            return;
                                        }
                                        setCurrentStep(index);
                                    }}
                                    variant='solid'                                
                                    border='4px solid black'
                                    borderWidth='0px !important'
                                    {...colorSchemes[status]}
                                    className='gradient-background'
                                    w={showSidebar ? 'unset' : '32px'}
                                    h='32px'
                                    fontSize='14px'
                                    align='center'
                                    zIndex='1'
                                    lineHeight='32px' 
                                    px={showSidebar ? 6 : 0}
                                    borderRadius={showSidebar ? '16px' : 'full'}
                                    cursor={status === 'incomplete' ? 'default' : 'pointer'}
                                    transition='all 0.2s ease-in-out'
                                    >
                                        {showSidebar && steps.length < 5 && step.title}
                                        {showSidebar && index === currentStep && step.title} 
                                        {showSidebar && index !== currentStep && steps.length < 5 && step.title}
                                        {showSidebar && index !== currentStep && steps.length >= 5 && step.title.substring(0, 5) + '.'}
                                </Box>
                        )})}
                    </Flex>
                </Box>

                <Flex
                    transition='all 0.2s ease-in-out'
                    flexDirection='column'
                    flexGrow='1'
                    >

                    <Flex 
                        justifyContent='space-between'
                        alignItems='center'
                        position='relative'
                        mb={4}
                        borderBottom='solid 1px whitesmoke'
                        p={4}
                        >
                            <Box position='absolute' left={6} right={6} w='fill-available' borderTop='dashed 1px black' borderColor='gray.300' top='50%' />
                            <Box position='absolute' left={6} right={6} w={currentStep / steps.length * 100 + '%'} borderTop='solid 1px black' borderColor='blue.500' top='50%' />

                            {steps.map((step, index) => {

                                let status = currentStep === index ? 'current' : (currentStep > index ? 'completed' : 'incomplete');

                                let indexOnly = false;
                                if (steps.length >= 5 && index !== currentStep) {
                                    indexOnly = true;
                                }

                                return (
                                    <Box
                                        key={index}
                                        onClick={() => {
                                            if (status === 'incomplete' && !allowSkip) {
                                                return;
                                            }
                                            setCurrentStep(index);
                                        }}
                                        variant='solid'                                
                                        border='4px solid black'
                                        borderWidth='0px !important'
                                        {...colorSchemes[status]}
                                        className='gradient-background'
                                        w={showSidebar && !indexOnly ? 'unset' : '32px'}
                                        h='32px'
                                        fontSize='14px'
                                        align='center'
                                        zIndex='1'
                                        lineHeight='32px' 
                                        px={showSidebar && !indexOnly ? 6 : 0}
                                        borderRadius={showSidebar ? '16px' : 'full'}
                                        cursor={status === 'incomplete' ? 'default' : 'pointer'}
                                        transition='all 0.2s ease-in-out'
                                        >
                                            {showSidebar && index === currentStep && step.title} 
                                            {showSidebar && index !== currentStep && steps.length < 5 && step.title}
                                            {showSidebar && index !== currentStep && steps.length >= 5 && index+1}
                                    </Box>
                                )})}
                    </Flex>

                    <Box
                        p={4}
                        pl={4}
                        h='fill-available'
                        >

                    <Heading as='h3' size='md' mb={2}>
                        {steps[currentStep >= steps.length ? steps.length - 1 : currentStep].title}
                    </Heading>


                    <Box h='fill-available' flexGrow='1' w='600px'>
                        {
                            // We want to use cloneComponent to get the steps[].component but add an additional function
                            // to update the data object

                            // Check if the current step is a component or an array
                            Array.isArray(steps[currentStep].component) ?

                                // If it is then use the Panel generator
                                <Panel
                                    questions={steps[currentStep].component}
                                    data={data}
                                    updateData={updateData}
                                    preamble={steps[currentStep].preamble}
                                />

                                :

                                React.cloneElement(steps[currentStep >= steps.length ? steps.length - 1 : currentStep].component, { updateData: updateData, data: data })

                            
                        }
                    </Box>

                    </Box>

                    <Flex
                        justifyContent='space-between'
                        mt={4}
                        p={4}
                        borderTop='solid 1px whitesmoke'
                        >
                        {props.onCancel &&
                            <Button onClick={props.onCancel} variant='outline' colorScheme='gray'>
                                Cancel
                            </Button>
                        }
                        <Box></Box>
                        <Wrap spacing={2}>
                        <Button
                            variant='outline'
                            colorScheme='gray'
                            onClick={() => {
                                    if (currentStep > 0) {
                                        setCurrentStep(currentStep - 1);
                                    }
                                }
                            }
                            isDisabled={currentStep === 0}
                        >
                            Previous
                        </Button>
                            <Button
                                variant={currentStep === steps.length - 1 ? 'solid' : 'outline'}
                                colorScheme='blue'
                                isDisabled={currentStep === steps.length - 1 && !requiredCompleted}
                                onClick={() => {
                                    // Add to step if we're not at the end
                                    if (currentStep < steps.length - 1) {
                                        setCurrentStep(currentStep + 1);
                                    } else {
                                        // Submit if we are at the end
                                        onSubmit();
                                    }
                                }}
                                >
                                    {currentStep < steps.length - 1 ? 'Next' : 
                                        props.finishMessage ? props.finishMessage : 'Finish'
                                    }
                            </Button>
                            </Wrap>
                    </Flex>

                </Flex>

            </Flex>

        </Wrapper>
        }
        </>

    )
}