import { PromoWrapper } from "../PromoWrapper";
import { Stack, Heading, Text, Link, Box, useColorModeValue } from "@chakra-ui/react";
import LoginManager from "./components/LoginManager";
import LoginForm from "./components/LoginForm";
export default function Login(props) {

    return (
        <PromoWrapper
            >
            <LoginForm
                action={props.action}
                />
        </PromoWrapper>
    );
}