import React, { useState } from 'react';
import API from '../core/utils/API';
import { Image, Tag, Center, Input, Square, Tabs, SimpleGrid, Tab, TabPanel, TabPanels, TabList, ButtonGroup, IconButton, HStack, Tooltip, Spinner, Table, Icon, Thead, Th, Tr, Td, Tbody, Heading, VStack, Checkbox, WrapItem, useDisclosure, Collapse,  Divider, SkeletonText, Grid, GridItem, Flex, Button, List, ListItem, Badge, Wrap, Box, Stack, Text } from '@chakra-ui/react';
import { MdLabel, MdOutlineLabel, MdCheck, MdClose, MdAdd, MdAttachment, MdOutlineWarning, MdExpandLess, MdExpandMore, MdCheckCircle, MdHelp} from "react-icons/md";

import FullPageModal from '../core/components/modal/FullPageModal';
import { Link as ReachLink } from 'react-router-dom';
import DisplayPDF from './DisplayPDF';
import { useLocation } from 'react-router-dom';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import AutoForm from '../core/models/AutoForm';
import AutoEdit from '../core/models/AutoEdit';
import Moment from 'moment';
import {MdFirstPage, MdEdit, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdLastPage, MdZoomIn, MdZoomOut} from 'react-icons/md';
import { useMediaQuery } from '@chakra-ui/react'

function EditForm(props) {

    const [evidence, setEvidence] = useState(props.evidence);
    const [show, setShow] = useState(false);

    return (



                <AutoEdit 
                    url = 'evidences/'
                    uuid = {evidence.uuid}
                    object = {evidence}
                    inputs = {[
                        {
                            name: 'title',
                            value: evidence.title.replace(/\.[^/.]+$/, "")
                        },
                        {
                            name: 'issuer',
                            value: evidence.issuer
                        },
                        {
                            name: 'issued',
                            value: evidence.issued,
                            type: 'date'
                        },
                        {
                            name: 'expiration',
                            value: evidence.expiration,
                            type: 'date'
                        },
                    ]}
                    footer = {props.footer}
                />     
            
    )
}


export default function Document(props) {

    const location = useLocation();
    const { uuid, requirementGroupUuid, returnURL, returnState } = location.state;

    console.log("RETURN URL:", returnURL, returnState);
    const [records, setRecords] = React.useState([]);
    const [evidence, setEvidence] = React.useState([]);
    const [requirements, setRequirements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loaded, setLoaded] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [showRequirements, setShowRequirements] = React.useState([]);
    const [filter, setFilter] = React.useState(false);
    const [issuedDate, setIssuedDate] = useState(evidence.issued); //(new Date());
    const [expiryDate, setExpiryDate] = useState(evidence.expiration);
    const [page, setPage] = React.useState(1);
    const [showEdit, setShowEdit] = React.useState(false);
    const [pagesDetails, setPagesDetails] = React.useState(null);
    const [splitPages, setSplitPages] = React.useState([]);
    const [zoom, setZoom] = React.useState(5);
    const [offsetX, setOffsetX] = React.useState(0);
    const [offsetY, setOffsetY] = React.useState(0);
    const [showScreenshot, setShowScreenshot] = React.useState(false);
    const [isPrint] = useMediaQuery('print')

    let zooms = [0.25, 0.33, 0.4, 0.5, 0.66, 0.75, 0.8, 0.9, 1, 1.25, 1.5, 2];

    const url = 'evidences/show';
    async function getData(getPDF = false){
        try {

            setLoading(true);

            console.log("Getting data", getPDF);
            
            let configData = {
                params: {
                    //requirementGroupUuid: requirementGroupUuid,
                    evidenceUuid: uuid,
                    getPDF: getPDF
                }
            };

            if (requirementGroupUuid !== undefined) {
                setFilter(true);
            }
            const response = await API.getItems({url, config: configData});

            const a = (response.data);
            const ev = a.evidence;
            if (getPDF) {
                const tmpPages = a.pages;
                setSplitPages(tmpPages);
            }
            // Remove file extension from title
            ev.title = ev.title.replace(/\.[^/.]+$/, "");
            const rec = Array.from(ev.Records);
            const req = Array.from(a.requirement);
            
            let recordRes = [];
            req.forEach(function (value, i) {
                let found = true;
                console.log("Value", value);
                value.RequirementGroupings.forEach(function (value2, i2) {
                    if (value2.requirementGroupUuid === requirementGroupUuid) {
                        found = false;
                    }
                });

                req[i].filter = found;
            
            });
            
           // setSplitPages(a.data.pages);
            setEvidence(ev);
            setRequirements(req);
            setShowRequirements(recordRes);
            setRecords(rec);
            //setAnalysis(JSON.parse(JSON.parse(ev.rawTextJSON)));
            setLoaded(true);
            setLoading(false);

        } catch (error) {
            console.log("Tried and failed to set data");
            setError(error);
            setLoading(false);
        }
    }

    React.useEffect(()=>{
        
        getData(true);
    }, [])

    function approveRecord(uuid) {

        const data = {
            recordUuid: uuid,
        };

        let response = API.postItems({url: 'records/approve', data})
        .then(response => {
            // Do something with the response
            console.log("success");
            getData(false);

        })
        .catch(error => {
            // Handle the error if something went wrong
            console.log("failed");
        });

    }

    function rejectRecordByPage(reqUuid) {

        let data = {
            requirementUuid: reqUuid,
            evidenceUuid: uuid,
            page: page
        };

        console.log("Rejecting record by page: " + page);

        let response = API.postItems({url: 'records/rejectByPage', data})
        .then(response => {
            console.log("success");
            getData(false);
        })
        .catch(error => {
            console.log("failed");
        });

    }

    function rejectRecord(uuid) {

        console.log("Rejecting record");
        const data = {
            recordUuid: uuid,
        };

        API.postItems({url: 'records/reject', data})
        .then(response => {
            // Do something with the response
            console.log("success");
            getData(false);
        })
        .catch(error => {
            // Handle the error if something went wrong
            console.log("failed");
        });

    }
    function addRecord(requirementUuid, evidenceUuid) {

        const data = {
            requirementUuid: requirementUuid,
            creatorUuid: 'this_user',
            evidenceUuid: evidenceUuid,
            page: page
        };

        let response = API.postItems({url: 'records/add', data})
        .then(response => {
            // Do something with the response
            console.log("success");
            getData();

        })
        .catch(error => {
            // Handle the error if something went wrong
            console.log("failed");
        });


    }


    function changePage(number) {
        setPage(number);
    }
    function updateFilter() {
        setFilter(!filter);
        let tempFilter = !filter;
        setFilter(tempFilter);
        if (tempFilter) {
            setShowRequirements(requirements);
        } else {
            setShowRequirements([]);
        }

    }

    // Monitor for screenshots via keyboard shortcuts
    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    function handleKeyDown(e) {
        setShowScreenshot(false);
        console.log("Keydown", e);
        if (e.ctrlKey && e.shiftKey && e.code === 'KeyS') {
            setShowScreenshot(true);
        }
        // MacOS Command + Shift + 4
        if (e.metaKey && e.shiftKey) {
            setShowScreenshot(true);
        }
    }

    React.useEffect(() => {
        let viewer = document.getElementById('page-viewer');
        
        if (viewer !== null) {
            viewer.focus();
        }

    }, [page, zoom, offsetX, offsetY]);

    return (

        <FullPageModal >

            {showScreenshot &&
                <Box 
                    position='fixed'
                    top='0'
                    left='0'
                    w='100vw'
                    h='100vh'
                    bg='white'
                    zIndex='1000'

                >

                    <Center h='100vh' p={8}>
                        <Stack spacing={4} textAlign='center' maxW='400px'>
                        <Box className="loading" w='100%' borderBottom='1px solid whitesmoke' pb={4}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>

                        </Box>
                        <Heading size='label'>
                            Screenshot detected
                        </Heading>
                        <Text>
                            We do not permit screenshots or downloading of files for security reasons. Please contact your administrator if you need to download this file.
                        </Text>
                        <Text pb={4}>
                            We have recorded this attempt to download the file.
                        </Text>
                        <Button ml='auto' mr='auto' alignSelf='center' w='fit-content' variant='outline' colorScheme='blue' onClick={() => setShowScreenshot(false)}>Close</Button>
                        </Stack>
                    </Center>
                </Box>

            }



                <SimpleGrid bg='whitesmoke' columns={{base: 1, md: 2}}  display={{base: 'inline-block', md: 'grid'}} 
                minH={{base: 'fit-content', lg: 'fill-available'}}
                overflow='scroll'>
                    
                    <Center maxW='100vh' overflow='hidden' 
                    h={{base: 'fit-content', lg: 'fill-available'}}
                    p={{base: 0, md: 0}} pb={8} position='relative'>
                        {loading & !loaded && evidence !== null && evidence.title !== null ? 
                        
                        <Box className="loading" w='100%' borderBottom='1px solid whitesmoke' pb={4}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Box>
                        
                        :
                        <Stack justifyContent='space-between' maxW='fill-available' maxH='100vh' spacing={4} p={2} alignItems='center'
                        h={{base: 'fit-content', lg: 'fill-available'}}>
                            <Box>

                            </Box>
                       <Box justifySelf='center' tabindex="0" id='page-viewer' position='relative' boxShadow='md'  
                         mb={4} h='fit-content' overflow='scroll' maxW='100%'>
                            <Box fontSize='0px' 
                                h={splitPages[page-1]['details']['totalPieceHeight'] * zooms[zoom]  } 
                                w={splitPages[page-1]['details']['totalPieceWidth'] * zooms[zoom] }
                                ml={offsetX}
                                mt={offsetY}
                                className='no-print blur'
        
                                >
                                {splitPages[page-1]['page'].map((p, index) => (
                                    <>

                                        <Image 
                                            src={p} 
                                            htmlWidth={splitPages[page-1]['details']['pieceWidth'] * zooms[zoom] + 'px'} 
                                            display={isPrint ? 'none' : 'inline-block'}
                                            />
                                        {(index+1) % splitPages[page-1]['details']['cols'] === 0 &&
                                            <br/>
                                        }
                                    </>
                                ))}
                                <Box
                                    position='absolute'
                                    top='0'
                                    left='0'
                                    h={splitPages[page-1]['details']['totalPieceHeight'] * zooms[zoom]  } 
                                    w={splitPages[page-1]['details']['totalPieceWidth'] * zooms[zoom] }
                                    bg='transparent'
                                />

                            </Box>
                        </Box>
                        
                        <Stack pt={2} alignItems='center' justifySelf='flex-end' spacing={4} >
                        <ButtonGroup isAttached colorScheme='blue' variant='outline' size='xs' bottom='80px' ml='auto' mr='auto'>
                            {page !== 1 ?
                            <Button onClick={() => setPage(1)}>
                                <Icon as={MdFirstPage} />
                            </Button>
                            :
                            <Button disabled>
                                <Icon as={MdFirstPage} />
                            </Button>
                            }
                            {page !== 1 ?
                            <Button onClick={() => setPage(page-1)}>
                                <Icon as={MdKeyboardArrowLeft} />
                            </Button>
                            :
                            <Button disabled>
                                <Icon as={MdKeyboardArrowLeft} />
                            </Button>
                            }
                            {splitPages.map((p, index) => (
                                <>
                                    {index+1 === page ?
                                        <Button bg='blue.100 !important' borderColor='blue.500!important' opacity='1 !important' disabled fontWeight='bold' color='blue.500 !important'>{index+1}</Button>
                                        :
                                        <Button onClick={() => setPage(index+1)}>{index+1}</Button>
                                    }
                                </>
                            ))}
                            {page !== splitPages.length ?
                            <>
                            <Button onClick={() => setPage(page+1)}>
                                <Icon as={MdKeyboardArrowRight} />
                            </Button>
                            <Button onClick={() => setPage(splitPages.length)}>
                                <Icon as={MdLastPage} />
                            </Button>
                            </>
                            :
                            <>
                            <Button disabled>
                                <Icon as={MdKeyboardArrowRight} />
                            </Button>
                            <Button disabled>
                                <Icon as={MdLastPage} />
                            </Button>
                            </>
                            }

                        </ButtonGroup>



                        <ButtonGroup isAttached colorScheme='blue' variant='outline' size='xs' ml='auto' mr='auto'>
                                    {zoom === 0 ?
                                        <Button disabled>
                                            <Icon as={MdZoomOut} />
                                        </Button>
                                        :
                                    <Button onClick={() => setZoom(zoom-1)}>
                                        <Icon as={MdZoomIn} />
                                    </Button>}

                                    <Button onClick={() => setZoom(8)}>
                                        {zooms[zoom]*100}%
                                    </Button>
                                    {zoom === zooms.length-1 ?
                                        <Button disabled>
                                            <Icon as={MdZoomIn} />
                                        </Button>
                                        :
                                    <Button onClick={() => setZoom(zoom+1)}>
                                        <Icon as={MdZoomIn} />
                                    </Button>
                                    }

                                </ButtonGroup>
                                </Stack>
                        </Stack>
                        
                        }

                    </Center>

                    <Box height={{base: 'min-content', md: 'fill-available'}} bg='white' overflow='scroll' flex='1' boxShadow='md' borderLeft={{base: 'none', md: '1px solid black'}}>

                    {(loading & !loaded && evidence !== null && evidence.title !== null) ? 
                    
                    <Center h='100vh'>
                    <Box className="loading" w='100%' borderBottom='1px solid whitesmoke' pb={4}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Box>
                    </Center>
                    :

                        <>

                        <HStack bg='blue.500' justifyContent='space-between' alignItems='center' p={4} >
                            <Stack onClick={() => setShowEdit(!showEdit)} cursor='pointer'>
                                <Heading color='invert' size='smalltitle'>{evidence.title}</Heading>
                                <Wrap pt={2}>
                                {evidence.issuer ?
                                                    <Tag variant='outline' color='white' borderColor='white'>
                                                        Issued by {evidence.issuer}
                                                    </Tag>
                                                    :
                                                    <Tag variant='outline'>
                                                        No issuer set
                                                    </Tag>
                                                }
                                                {evidence.issued ?
                                                    <Tag variant='outline' color='white' borderColor='white'>
                                                        Issued: {Moment(evidence.issued).format("DD/MM/YYYY")}
                                                    </Tag>
                                                    :
                                                    <Tag variant='outline'>
                                                        No issue date set
                                                    </Tag>
                                                }
                                                {evidence.expiration ?
                                                    <Tag variant='outline' color='white' borderColor='white'>
                                                        Expires: {Moment(evidence.expiration).format("DD/MM/YYYY")}
                                                    </Tag>
                                                    :
                                                    <Tag variant='outline'>
                                                        No expiration date set
                                                    </Tag>
                                                }
                                        <Button size='xs' color='white' borderColor='white' _hover={{bg:'white', color: 'blue.500'}} colorScheme='white' variant='outline' pl='0px' pr='0px'>
                                            <Icon as={MdEdit}/>
                                        </Button>

                                </Wrap>
                            </Stack>
                            <Button alignSelf='flex-start' bg='white' as={ReachLink} variant='outline' className='btn-8' colorScheme='blue' to={returnURL} state={returnState} mr={2}>Close</Button>
                        </HStack>
        
                        <Table w='fill-available' pt={1} bg='white'>

                            <Tbody>

                                {showEdit &&
                                    <Tr>
                                        <Td colSpan='2' borderBottom='1px solid black !important'>
                                        <AutoEdit 
                                            url = 'evidences/'
                                            uuid = {evidence.uuid}
                                            onSubmit={() => {
                                                    setShowEdit(!showEdit);
                                                    getData();
                                                }
                                            }
                                            object = {evidence}
                                            inputs = {[
                                                {
                                                    name: 'title',
                                                    value: evidence.title.replace(/\.[^/.]+$/, "")
                                                },
                                                {
                                                    name: 'issuer',
                                                    value: evidence.issuer
                                                },
                                                {
                                                    name: 'issued',
                                                    value: evidence.issued,
                                                    type: 'date'
                                                },
                                                {
                                                    name: 'expiration',
                                                    value: evidence.expiration,
                                                    type: 'date'
                                                },
                                            ]}
                                            footer = {
                                                <Button order='2' variant='ghost' onClick={() => setShowEdit(!showEdit)}>Close</Button>
                                            }
                                        />     

                                        </Td>
                                    </Tr>
                                }

                                <Tr className='header-row'>
                                    <Th bg='none' fontSize='sm' pt={0} pb={0}>Tags</Th>
                                    <Td  pt={0} pb={0} textAlign='right'  borderBottom='1px solid black !important'>
                                        {requirementGroupUuid !== undefined &&
                                            <Button variant='ghost'  colorScheme='white' size='xs' onClick={() => updateFilter()}>{filter ? 'Show All' : 'Show Related'} Tags</Button>
                                        }
                                    </Td>
                                </Tr>


                                {requirements.sort((a, b) => a.title > b.title ? 1 : -1).map((requirement) => (

                                    <> { ((filter && requirement.filter == false) || !filter ) &&

                                    <Tr className='opacity-hover' order={requirement.Records.length}>

                                    <Th bg='none' fontSize='sm'  h='auto' p={2} pl={6} borderBottom='1px dotted grey !important'>
                                        <Heading size='label'>
                                            {requirement.title}
                                        </Heading>
                                        <Text as='span'></Text>
                                    </Th>


                                    <Td p={2} borderBottom='1px dotted black !important'>

                                        <Wrap is-hover="hover">
                                        <ButtonGroup size='xs' isAttached variant='outline' >
                                            <Button disabled cursor='default!important' opacity='1!important' borderColor='blackAlpha.700 !important' color='blackAlpha.700 !important'>
                                                Page
                                            </Button>
                                            {[...new Set(requirement.Records.filter(record => record.evidenceUuid === uuid).map(item => item.page))].sort().map(recordPage=> 
                                                <>
                                                    {recordPage !== page ?
                                                        <Button colorScheme='blue' borderColor='blackAlpha.700 !important' onClick={() => setPage(recordPage)}>{recordPage}</Button>
                                                        :
                                                        <Button colorScheme='blue' borderColor='blue.500!important' bg='blue.50 !important' opacity='1 !important' disabled fontWeight='bold' color='blue.500 !important'>{recordPage}</Button>
                                                    }
                                                </>
                                            )}
                                        {requirement.Records.filter(record => (record.evidenceUuid === uuid && record.page === page)).length === 0 &&

                                            <IconButton colorScheme='blue' borderColor='blackAlpha.700 !important' onClick={() => addRecord(requirement.uuid, evidence.uuid)} className='opacity-on-wrapper-hover' aria-label='Approve'  icon={<MdAdd />} />
                                        }
                                        </ButtonGroup>

                                        {requirement.Records.filter(record => (record.evidenceUuid === uuid && record.page === page)).length > 0 &&
                                            <Button size='xs' variant='ghost' colorScheme='red' onClick={() => rejectRecordByPage(requirement.uuid)}>Remove tags for Page {page}</Button>
                                        }


                                        </Wrap>

                                    </Td>

                                    </Tr>
                                }
                                    </>

                                ))}
                            </Tbody>
                        </Table>
                                


            </>
            }

                    </Box>
                </SimpleGrid>
        </FullPageModal>
           
    )
}
