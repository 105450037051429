import React, { useEffect, useState } from "react";
import {Button, Box, Text} from "@chakra-ui/react";

export default function FileImport(props) {

  const [file, setFile] = useState();
    const [array, setArray] = useState([]); // This will hold the array of objects from the CSV file.

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };


  

  const csvFileToArray = string => {
    console.log("Converting", string);
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    
    props.setCsv(array);    

    setFile(null);
    
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(file);

    if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          csvFileToArray(text);
        };
  
        fileReader.readAsText(file);
    }
  };

  useEffect(() => {
    console.log("File", file);
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);

    }

  }
    , [file]);


  return (
    <>
        <Button
                variant='outline'
                colorScheme='red'
                onClick={(e) => {
                    // Open dialogue to select file to upload
                    // must be CSV
                    document.getElementById("csvFileInput").click();
                }
            }>
            Upload
        </Button>
        <Box style={{ textAlign: "center" }} display='none'>
        <form>
            <input
                display={"none"}
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
            />
        
        </form>
        </Box>
    </>
    
  );
}