import React, { useState, useEffect, useContext } from 'react';
import { useToast, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton } from '@chakra-ui/react';
import API from "../../core/utils/API";
import { MdPerson, MdPhone, MdMail, MdOutlineModelTraining,MdOutlineVisibility,  MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen } from "react-icons/md";
import LoadingCircle from '../../static/layout/LoadingCircle';
import { useLocation } from "react-router-dom";
import Loading from '../../static/layout/Loading';
import Splash from '../../static/layout/NewSplash';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {SubscriptionContext} from '../../auth/SubscriptionContext';
import SubscriptionProtectedRoute from '../../auth/SubscriptionWrapper';
import InputWrapper from '../../core/components/forms/inputs/InputWrapper';
import {Header, Row, Cell} from '../../core/components/lists/List';
import pluralize from 'pluralize';

import AutoForm from '../../core/models/AutoForm';

import GoogleMap from '../../core/components/maps/GoogleMap';
import secureLocalStorage from 'react-secure-storage';
import { random } from 'sjcl';

let items = {
    'Airway': [
        'ETT Tube Size 3',
        'ETT Tube Size 4',
        'Guedel Airway Size 3',
        'Guedel Airway Size 4',
        'Boogie',
        'Suction Catheter',
        'Suction Tubing',
    ],
    'Breathing': [
        'Oxygen Mask',
        'Nasal Cannula'
    ],
    'Circulation': [
        'Cannula 14g',
        'Cannula 16g',
        'Cannula 18g',
        'Cannula 20g',
        'Octopus',
        'Tagaderm',
        'Adrenaline',
    ],
    'Drugs': [
        'Adrenaline',
        'Atropine',
        'Amiodarone',
    ]
}

export default function CheckForm(props) {


    const [mode, setMode] = useState(null);

    return (

        <Splash maxW={(mode === null || mode === 'submitted') ? '400px' : '800px'} mb='70px'>
            <Stack spacing={4} pt={4}>

                <Stack spacing={4}>
                    <Box>
                    
                    <Heading fontSize='small' lineHeight='1.2em' mb={2} color='blue.500'>
                        Tropical Lagoon
                    </Heading>
                    <Heading fontSize='32px' lineHeight='1.2em' mb={4}>
                        Resus Trolley
                    </Heading>

                    {mode === null &&
                        <Text mb={2}>
                            This is the checklist for the resuscitation trolley on Tropical Lagoon. This includes life saving equipment that needs to be restocked immediately after use.
                        </Text>
                    }
                    </Box>

                    {mode === 'complete' &&
                    
                        <Button size='xs' variant='outline' variant='outline' w='min-content'
                            onClick={() => {setMode(null)}}
                            >
                            Back
                        </Button>
                    }

                    {mode === null &&

                        

                        

                        <>
                        <HStack spacing={4} pb={4}>
                            <Box>
                                <Heading fontSize='small' lineHeight='1.2em'>
                                    Frequency
                                </Heading>
                                <Text>
                                    Every Shift (twice a day)
                                </Text>
                            </Box>
                            <Box>
                                <Heading fontSize='small' lineHeight='1.2em'>
                                    Responsibility
                                </Heading>
                                <Text>
                                    Nursing Staff
                                </Text>
                            </Box>
                        </HStack>

                        <Button mt={4} w='fit-content' colorScheme='blue' colorScheme='blue' variant='solid'
                            onClick={() => {setMode('complete')}}>
                                Complete Check
                        </Button>
                        <ButtonGroup size='xs' variant='outline' pt={2}>
                            <Button colorScheme='blue'>
                                Report Usage
                            </Button>
                            <Button colorScheme='red'>
                                Incorrect Form
                            </Button>
                        </ButtonGroup>
                        </>

                    }

                </Stack>

                {mode === 'complete' &&
                    <Stack spacing={4}  
                    
                        >

                        <Table >
                            <Tbody>
                                {(window.innerWidth >= 768) ?
                                    <Tr>
                                        <Th fontSize='1em'>
                                            Item
                                        </Th>
                                        <Th fontSize='1em'>
                                            Quantity
                                        </Th>
                                        <Th fontSize='1em'>
                                            Expiry
                                        </Th>
                                        <Th fontSize='1em'>
                                            Checked
                                        </Th>
                                    </Tr>
                                    :
                                    <Tr>
                                        <Th colSpan='3' fontSize='1em'>
                                            Details
                                        </Th>
                                        <Th fontSize='1em'>
                                            Check
                                        </Th>
                                    </Tr>
                                }

                                {Object.keys(items).map((section, index) => {
                                    return (
                                        <>
                                        
                                        <Tr borderBotttom='1px solid blue' borderBottomColor='blue.500'>
                                            <Td colSpan={4} borderBotttom='1px solid blue' borderBottomColor='blue.500' bg='blue.50'>
                                                <Heading fontSize='large' lineHeight='1.2em' color='blue.500'>
                                                    {section}
                                                    <Tag ml={4} colorScheme='blue'>
                                                        {items[section].length + ' items'}
                                                    </Tag>
                                                </Heading>
                                            </Td>
                                        </Tr>
                                        {items[section].map((item, index) => {

                                            // Generate random number between 12 and 75
                                            
                                            let date = moment().add(Math.random() * 365, 'days');
                                            let expiringLimit = moment().add(14, 'days');

                                            if (index === 6) {
                                                date = moment().subtract(Math.random() * 7, 'days');
                                            };
                                            if (index === 4) {
                                                date = moment().add(Math.random() * 7, 'days');
                                            }
                                            console.log(date);

                                            let state = 'OK';
                                            
                                            // Check if date is in the past
                                            if (date.isBefore(moment())) {
                                                state = 'EXPIRED';
                                            } else if (date.isBefore(moment().add(14, 'days'))) {
                                                state = 'EXPIRING';
                                            }

                                            date = date.format('YYYY-MM-DD');

                                            return (
                                                <Tr>
                                                    {
                                                        // Check if screen is a mobile size
                                                        (window.innerWidth < 768) ?
                                                        <>
                                                            <Td colSpan='3'>
                                                                <Heading fontSize='small' lineHeight='1.2em' mb={2}>
                                                                    {item}
                                                                </Heading>
                                                                <HStack>
                                                                    <InputWrapper 
                                                                        type='integer'
                                                                        defaultValue={1}
                                                                        default={1}
                                                                        size='sm'
                                                                        w='min-content'
                                                                        maxW='60px'
                                                                        />
                                                                    <InputWrapper
                                                                        type='date'
                                                                        size='sm'
                                                                        defaultValue={date}
                                                                        />
                                                                </HStack>
                                                                {state === 'EXPIRED' &&
                                                                    <Tag colorScheme='red' size='xs' mt={2}>
                                                                        EXPIRED
                                                                    </Tag>
                                                                }
                                                                {state === 'EXPIRING' &&
                                                                    <Tag colorScheme='orange' size='xs' mt={2}>
                                                                        EXPIRING
                                                                    </Tag>
                                                                }
                                                            </Td>
                                                            <Td>
                                                                <Box pl={2} pt={2}>
                                                                    <InputWrapper type='checkbox' size='lg'/>
                                                                </Box>
                                                            </Td>
                                                        </>
                                                        : 
                                                        <>
                                                            <Td>
                                                                {item}
                                                            </Td>
                                                            <Td>
                                                                <InputWrapper 
                                                                    type='integer'
                                                                    defaultValue='1'
                                                                    maxW='80px'
                                                                    />
                                                            </Td>
                                                            <Td>
                                                                <InputWrapper
                                                                    type='date'
                                                                    defaultValue={moment().add(Math.random(1, 365), 'days').format('YYYY-MM-DD')}
                                                                    />
                                                                    {state === 'EXPIRED' &&
                                                                        <Tag colorScheme='red' size='xs' mt={2}>
                                                                            EXPIRED
                                                                        </Tag>
                                                                    }
                                                                    {state === 'EXPIRING' &&
                                                                        <Tag colorScheme='orange' size='xs' mt={2}>
                                                                            EXPIRING
                                                                        </Tag>
                                                                    }
                                                            </Td>
                                                            <Td alignItems='center' textAlign='center'>
                                                                <InputWrapper type='checkbox' />
                                                            </Td>
                                                            </>
                                                    }


                                                </Tr>
                                            )
                                        })}
                                        </>
                                    )
                                })}

                                <Tr borderBotttom='1px solid blue' borderBottomColor='blue.500'>
                                            <Td colSpan={4} borderBotttom='1px solid blue' borderBottomColor='blue.500' bg='blue.50'>
                                                <Heading fontSize='large' lineHeight='1.2em' color='blue.500'>
                                                    Additional Comments
                                                </Heading>
                                            </Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan='4'>
                                        <InputWrapper type='textarea' label='Comments' />
                                    </Td>
                                </Tr>

                            </Tbody>
                        </Table>

                        <Button colorScheme='blue' variant='solid' w='fit-content' mt={4}
                            onClick={() => {setMode('staffid')}}
                            alignSelf='flex-end'
                            >
                            Submit
                        </Button>

                    </Stack>
                }

                {mode === 'staffid' &&

                    <Stack spacing={2}>

                        <Heading fontSize='24px' lineHeight='1.4em'>
                            Almost done!
                        </Heading>
                        <Text fontSize='18px' pb={4}>
                            We just need to identify you before submitting the checklist.
                        </Text>
                        
                        <InputWrapper
                        type='input'
                            />
                        
                        <Text fontSize='18px' pb={4} color='subtle' pt={2}>
                            You can find the number on the back of your staff ID card.
                        </Text>

                        <Button colorScheme='blue' variant='solid' w='fit-content' mt={6}
                            onClick={() => {setMode('warning')}}
                            alignSelf='flex-end'

                            >
                            Submit
                        </Button>


                    </Stack>

                }

                {mode === 'warning' && 

                    <Stack spacing={2}>

                    <Heading fontSize='24px' lineHeight='1.4em' color='red.500'>
                        Warning!
                    </Heading>
                    <Text fontSize='18px' pb={4} color='red.500'>
                        Critical equipment has expired or is missing.
                    </Text>

                    <Stack spacing={4} pb={6}>

                    <InputWrapper
                        label='Who have you informed?'
                        description='We suggest that you inform the Nurse in Charge of the shift.'
                        type='input'
                        />
                    <InputWrapper
                        label='I have resolved the issues identified'
                        type='radio'
                        options={['Yes', 'No', 'In progress']}
                        />

</Stack>
                    <Button colorScheme='blue' variant='solid' w='fit-content' mt={6}
                        onClick={() => {setMode('submitted')}}
                        alignSelf='flex-end'

                        >
                        Submit
                    </Button>


                    </Stack>
                
                }

                {mode === 'submitted' &&
                
                    <Stack spacing={2}>
                        <Icon as={MdCheck} boxSize='100px' color='green.500' mb={6} />
                        <Box pb={6}>
                        <Heading fontSize='large' lineHeight='1.4em' color='green.500'>
                            Submitted
                        </Heading>
                        <Text>
                            Thank you for submitting a <strong>Resus Trolley</strong> checklist for <strong>Tropical Lagoon</strong>.
                        </Text>
                        </Box>
                        <Button colorScheme='blue'  w='fit-content' mt={6}
                            onClick={() => {setMode(null)}}
                            variant='outline'
                            >
                            Scan a new QR code
                        </Button>
                    
                    </Stack>
                
                }

            </Stack>

        </Splash>
    )
}