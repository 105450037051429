import Footer from './Footer';

import {MdErrorOutline} from 'react-icons/md';
import {Center, Heading, Icon, Stack, Text} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export default function ErrorPage(props) {

    const [timeout, setTimeout] = useState(false);

    // Create a timer for 1 second
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeout(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);


    return (
        <>
        {timeout &&
        <>
        <Center pt='12'>
            <Stack alignItems='center'>
            <Icon as={MdErrorOutline} width='160px' h='160'/>
            <Heading size='title'>Whoops!</Heading>
            <Text size='pagetitle-description'>Something went wrong, sorry about that.</Text>
            {props.message && 
                <Text pt={4} align='center'>Turns out we have some idea what went wrong:<br/>{props.message}</Text>
            }
            </Stack>
        </Center>
        <Footer/>
        </>
        }
    </>
    )
}