import { mode, Styles } from '@chakra-ui/theme-tools'

export const styles: Styles = {
  global: (props) => ({
    body: {
      color: 'default',
      bg: 'bg-canvas',
    },
    fonts: {
      heading: `'Satoshi-Regular', sans-serif`,//`'Raleway', sans-serif`,
      body: `'DM Sans', sans-serif`,
    },
    '*::placeholder': {
      color: mode('gray.500', 'gray.500')(props),
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.300', 'gray.300')(props),
    },
  }),
}
