import React, {  useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Stack, Circle, Heading, Button, Flex, Box, Text, Icon,Wrap,  Tag, Avatar, TagLabel } from '@chakra-ui/react';
import { MdCheck, MdClose, MdAccessTime, MdLock } from 'react-icons/md';
import Node from './Node';
import { BsBluetooth } from 'react-icons/bs';

const handleStyle = { left: 10 };

const colors = {
  'completed': 'green.500',
  'inprogress': 'yellow.500',
  'notstarted': 'red.500',
  'locaked': 'gray.500',
}

const icons = {
  'completed': MdCheck,
  'inprogress': MdAccessTime,
  'notstarted': MdClose,
  'locked': MdLock,
}

function GroupNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const [collapsed, setCollapsed] = React.useState(false);

  let defaultHeight = '42px';

  return (
    <Stack spacing={0} maxH={collapsed ? defaultHeight : 'auto'} transition='height 0.5s' overflow='hidden'
      borderRadius='md'
    >
    <Box position='relative' >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />

      <Box
        border='1px solid #e2e8f0'
        borderBottom='0px solid'
        borderBottomColor={colors[data.status]}
        boxShadow='md'
        px={4}
        minW='230px'
        py={2}
        // On double click, open the modal
        onDoubleClick={data.onDoubleClick}
        w='fill-available'
        bg='blue.500'
        h={defaultHeight}
        bottomLeftRadius={collapsed ? 'md' : '0'}
        bottomRightRadius={collapsed ? 'md' : '0'}
        >
        <Flex alignItems='center' justifyContent='space-between'>
            <Text color='invert'>
              {data.label}
            </Text>
            <Button size='xs' variant='outline' color='white' onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? 'Expand' : 'Collapse'}
            </Button>
        </Flex>
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
      </Box>

      { data.children.map((child, index) => {
        return (
          <Box
            border='1px solid #e2e8f0'
            borderBottom='4px solid'
            borderBottomColor={colors[child.status]}
            bg='white'
            boxShadow='md'
            px={4}
            py={2}
            pl={8}
            // On double click, open the modal
            onDoubleClick={child.onDoubleClick}
            w='fill-available'
            position='relative'
            >

          {child.status === 'notstarted' ?
          <Circle borderRadius='full' size='14px' bg='white' border='2px solid' borderColor={colors[data.status]} position='absolute' left={3} top='14px' />


          :
          <Icon as={icons[child.status]} color={colors[child.status]} mr={2}
            position='absolute'
            left={3}
            top={3}
            />
          }
            <Text>
              {child.label}
            </Text>
            <Flex spacing={2} pt={1}
              flexDirection='row-reverse'
              >  
              {child.completedSteps !== undefined && child.totalSteps !== undefined &&
                <Tag size='xs'
                  px={2} py={1}
                  fontSize='xs'
                  >{child.completedSteps}/{child.totalSteps} completed</Tag>
              }
            </Flex>
          </Box>
        );
      })}

    </Stack>
  );
}

export default GroupNode;
