"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var baseStyle = {
    fontWeight: '600',
    color: 'heading',
    lineHeight: '1.2em',
};
var sizes = {
    'pagetitle': { 
        fontSize: {
          base:'58px',
          lg:"92px"
         },
        lineHeight: '1.2em',
        fontWeight: '600', 
        mt: "8" 
    },
    'statictagline': {
        fontSize: {
            base: 'md',
            md: '42px',
        },
        mb: '1.5em',
        mt: '1.5em'
    },
    'subtitle': {
        fontSize: {
          base: '48px',
          md: '96px',
        },
        mb: '0.5em'

    },
    'title': {
        fontSize: {
            base: '64px',
            md: '72px',
        }
    },
    'smalltitle': {
        fontSize: {
            base: '36px',
            md: '48px',
        }
    },
    'smallertitle': {
        fontSize: {
            base: '24px',
            md: '32px',
        }
    },
    'label': {
        fontSize: {
            base: '12px',
            md: '16px',
        }
    },
    '2xl': {
        fontSize: '7xl',
        lineHeight: '5.625rem',
        letterSpacing: 'tight',
    },
    xl: {
        fontSize: '6xl',
        lineHeight: '4.5rem',
        letterSpacing: 'tight',
    },
    lg: {
        fontSize: '5xl',
        lineHeight: '3.75rem',
        letterSpacing: 'tight',
    },
    md: {
        fontSize: '4xl',
        lineHeight: '2.75rem',
        letterSpacing: 'tight',
    },
    sm: {
        fontSize: '3xl',
        lineHeight: '2.375rem',
    },
    xs: {
        fontSize: '2xl',
        lineHeight: '2rem',
    },
};
exports.default = {
    baseStyle: baseStyle,
    sizes: sizes,
};
