import {
    Button,
    Center,
    HStack,
    Icon,
    Square,
    Text,
    useColorModeValue,
    VStack,
    Input
  } from '@chakra-ui/react'
  import React from 'react'
  import { FiUploadCloud } from 'react-icons/fi'
  import { Outlet  } from "react-router-dom";

  export const Dropzone = (props) => (
    <Center
      borderWidth="1px"
      borderRadius="lg"
      position='relative'
      px="6"
      py="4"
      bg={useColorModeValue('white', 'gray.800')}
      {...props}
    >
      <VStack spacing="3">
        <Square size="10" bg="bg-subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            <Button borderWidth='0' variant="link" colorScheme="blue" size="sm">
              Click to upload
            </Button>
            <Text fontSize="sm" color="muted">
              or drag and drop
            </Text>
          </HStack>
          <Text fontSize="xs" color="muted">
            {props.filetypes}<Text as='span' display='none'> up to {props.filesize}</Text>
          </Text>
        </VStack>
      </VStack>
      {props.children}
    </Center>
  )