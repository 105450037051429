import {Buffer} from 'buffer';
import  secureLocalStorage  from  "react-secure-storage";



    
    async function CollectLocalKey() {


        //secureLocalStorage.setItem('symmetricKey', ss1);

        // Get the local key from local storage
        let localKey = await secureLocalStorage.getItem('symmetricKey');

        // If the local key is null, then we need to generate a new one
        if (localKey === null) {
            console.log("Local key is null");
            // Throw an error
            throw new Error("Local key is null");
        } else {
           // console.log("Local Key", localKey);
        }

        // Convert the local key from base64 to a buffer
        localKey = Buffer.from(localKey, 'base64');

        // Import the local key into the browser
        return window.crypto.subtle.importKey(
            'raw', 
            localKey, 
            {
                name: 'AES-GCM', 
                length: 256
            }, 
            false, 
            ['encrypt', 'decrypt']
        ).then(function(key) {
            //console.log("Local Key (Imported):", key);
            return key;
        }).catch(function(err) {
            console.error(err);
            return null;
        });

    }

    // Synchronous version
    function AES256_GCM_ENCRYPT_SYNC(plainText, urlSafe = false) {

        console.log("Encrypting: ", plainText);
        let localKey = secureLocalStorage.getItem('symmetricKey');

        // Convert the local key from base64 to a buffer
        localKey = Buffer.from(localKey, 'base64');
        window.crypto.subtle.importKey(
            'raw', 
            localKey, 
            {
                name: 'AES-GCM', 
                length: 256
            }, 
            false, 
            ['encrypt', 'decrypt']
        )
        .then(function(secretKey) {


            if (secretKey == null) {
                console.log("Failed to Generate AES Keys Check The browser Comptabilty ")
                return null;
            } else {
                //console.log("Encryption Key: ", secretKey);
            }

            let iv = window.crypto.getRandomValues(new Uint8Array(12));
            //console.log("Encrypt IV", iv);

            let cipherText = crypto.subtle.encrypt({
                name: "aes-gcm",
                iv: iv,
                tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
            }, secretKey, asciiToUint8Array(plainText)).then(function(cipherText) {

                // Split the data from the tag (16 bytes)
                let tag = cipherText.slice(cipherText.byteLength - 16);
                //console.log("Cipher tag", tag);
                //console.log("Cipher text original", cipherText);

                // Remove 16 bytes from end of cipher text
                cipherText = cipherText.slice(0, cipherText.byteLength - 16);

                //console.log("Cipher Text data only", cipherText);

                // Add the iv back in

                cipherText = appendBuffer(cipherText, iv);

                //console.log("Cipher Text with iv", cipherText);

                // Add the tag back in
                cipherText = appendBuffer(cipherText, tag);

                //console.log("Cipher Text with tag", cipherText);

                let final = bytesToHexString(cipherText);

                // Convert hex string to base64
                final = Buffer.from(final, 'hex').toString('base64');
                //console.log(final);

                return final;

            }, failAndLog);

            return cipherText;

        });
    }

    


    async function AES256_GCM_ENCRYPT(plainText, urlSafe = false) {

        let secretKey = await CollectLocalKey();

        if (secretKey == null) {
            console.log("Failed to Generate AES Keys Check The browser Comptabilty ")
            return;
        } else {
            //console.log("Encryption Key: ", secretKey);
        }
    
        let iv = window.crypto.getRandomValues(new Uint8Array(12));
        //console.log("Encrypt IV", iv);

        return crypto.subtle.encrypt({
            name: "aes-gcm",
            iv: iv,
            tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
        }, secretKey, asciiToUint8Array(plainText)).then(function(cipherText) {

            // Split the data from the tag (16 bytes)
            let tag = cipherText.slice(cipherText.byteLength - 16);
            //console.log("Cipher tag", tag);
            //console.log("Cipher text original", cipherText);

            // Remove 16 bytes from end of cipher text
            cipherText = cipherText.slice(0, cipherText.byteLength - 16);

            //console.log("Cipher Text data only", cipherText);

            // Add the iv back in
            cipherText = appendBuffer(cipherText, iv);
            //console.log("Cipher Text with iv", cipherText);
            
            // Add the tag back in
            cipherText = appendBuffer(cipherText, tag);
            
            //console.log("Cipher Text with tag", cipherText);

            let final = bytesToHexString(cipherText);

            // Convert hex string to base64
            final = Buffer.from(final, 'hex').toString('base64');
            if (urlSafe) {            
                final = base64url_encode(final);
            }
            //console.log(final);
            return final;

        }, failAndLog);
    
    }

    var appendBuffer = function(buffer1, buffer2) {
        var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
        tmp.set(new Uint8Array(buffer1), 0);
        tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
        return tmp.buffer;
      };

    function typedArrayToBuffer(array: Uint8Array): ArrayBuffer {
        return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
    }

    function base64url_encode(s) {

        // Replace + with -
        // Replace / with _
        s = s.replace(/\+/g, '-');
        s = s.replace(/\//g, '_');
        return s;

    }
    
    function base64url_decode(s) {
        // Replace - with +
        // Replace _ with /
        try {
            s = s.replace(/-/g, '+');
            s = s.replace(/_/g, '/');
            return s;
        } catch (e) {
            console.log(e);
        }

        return s;
    }
    
    async function AES256_GCM_DECRYPT(cipherText) {
    
        // TODO - check correct object type and string format (i.e. base64);
        
        let secretKey = await CollectLocalKey();

        if (secretKey == null) {
            console.log("Failed to Generate AES Keys Check The browser Comptabilty ")
            return;
        } else {
            //console.log("Decryption Key: ", secretKey);
        }

        cipherText = base64url_decode(cipherText);

        // Convert base64 string to hex string
        cipherText = Buffer.from(cipherText, 'base64').toString('hex');

        let cipherArray = hexStringToUint8Array(cipherText);
        //console.log("Cipher Array", cipherArray);

        let cipherIV = cipherArray.slice(cipherArray.length - 28, cipherArray.length - 16);
        
        //console.log('Cipher IV', cipherIV);

        let cipherTag = cipherArray.slice(cipherArray.length - 16);
        let cipherData = cipherArray.slice(0, cipherArray.length - 28);

        // Join data and tag again
        cipherData = appendBuffer(cipherData, cipherTag);

    //    console.log("Cipher Data", cipherData);

       

        return crypto.subtle.decrypt({
                name: "aes-gcm",
                iv: cipherIV,
                tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
            },
            secretKey,
            cipherData
        ).then(function(decryptedText) {


            let final = bytesToASCIIString(decryptedText);

            return final;
        }, failAndLog);
    }
    
    function bytesToHexString(bytes) {
        if (!bytes)
            return null;
    
        bytes = new Uint8Array(bytes);
        var hexBytes = [];
    
        for (var i = 0; i < bytes.length; ++i) {
            var byteString = bytes[i].toString(16);
            if (byteString.length < 2)
                byteString = "0" + byteString;
            hexBytes.push(byteString);
        }
    
        return hexBytes.join("");
    }
    
    
    function bytesToASCIIString(bytes) {
        //return String.fromCharCode.apply(null, new Uint8Array(bytes));
        return new Uint8Array(bytes).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
          }, '');
    }

    


    
    function hexStringToUint8Array(hexString) {
        if (hexString.length % 2 != 0)
            throw "Invalid hexString";
        var arrayBuffer = new Uint8Array(hexString.length / 2);
    
        for (var i = 0; i < hexString.length; i += 2) {
            var byteValue = parseInt(hexString.substr(i, 2), 16);
            if (byteValue == NaN)
                throw "Invalid hexString";
            arrayBuffer[i / 2] = byteValue;
        }
    
        return arrayBuffer;
    }
    
    function failAndLog(error) {
        console.log(error);
    }
    
    function asciiToUint8Array(str) {
        var chars = [];
        for (var i = 0; i < str.length; ++i)
            chars.push(str.charCodeAt(i));
        return new Uint8Array(chars);
    }


    


export default {AES256_GCM_ENCRYPT, AES256_GCM_DECRYPT};