import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid, Button, Input, Textarea, Wrap, Stack } from '@chakra-ui/react'

import API from '../../core/utils/API';
import { useForm, Controller } from "react-hook-form";

export default function ShowTemplate(props) {

    const [data, setData] = useState("");
    const [formMessage, setFormMessage] = useState("");
    const [showHTML, setShowHTML] = useState(true);
    const { register, formState: { errors }, handleSubmit, reset, control } = useForm();
    const onError = (errors, e) => console.log(errors, e);

    let [loading, setLoading] = useState(true);
    let [template, setTemplate] = useState({});
    let [templateName, setTemplateName] = useState(props.templateName);

    useEffect(() => {
        const getTemplate = async () => {

        API.getItems({url: 'utils/emails/templates/' + templateName})
        .then(response => {
            console.log("SHOW", response.data);
            setTemplate(response.data.template);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }
    getTemplate();
    }, []);

    const onSubmit = (data, e) => {
        //setLoading(true);
        console.log(data);
        let response = API.patchItems({url: "utils/emails/templates", data})
        .then(function (response) {
            console.log(response);
            //window.location.reload(false);
            setFormMessage("Record updated successfully");
            htmlChanged();
            // Reset inputs
            setLoading(false);
            //window.location.reload(false);

        })
        .catch(function (error) {
            console.log(error);
            //window.location.reload(false);
            setFormMessage(error.response.message);
           // setLoading(false);
           // window.location.reload(false);

        });
    }

    function deleteTemplate(templateName) {
        console.log("DELETE", templateName);

        let response = API.deleteItems({url: "utils/emails/templates/" + templateName})
        .then(function (response) {
            console.log(response);
            setTemplateName("");
            setFormMessage("Record deleted successfully");
            setLoading(false);
            window.location.reload(false);
        })
        .catch(function (error) {
            console.log(error);
            setFormMessage(error.response.message);
            setLoading(false);
        });

    }


    const htmlChanged = (e) => {
        console.log("HTML CHANGED");
        let html = document.getElementById('HtmlPartInput');
        if (html) {
            html = html.value;
        } else {
            return;
        }
        let display = document.getElementById('display');
        display.innerHTML = html;
    }


    return (
        
    <SimpleGrid columns={{base: 1, md: 2}} spacing={10}>

        <form onSubmit={handleSubmit(onSubmit, onError)} >
            {!loading &&

                    <Stack spacing={4} >

                        <Heading size='smalltitle'>{template.TemplateName}</Heading>
                        <p>{formMessage}</p>
                        <Input
                            name='TemplateName'
                            label='Template Name'
                            defaultValue={template.TemplateName}
                            {...register('TemplateName', { required: true })}
                            hidden
                        />
                        <Input
                            name='SubjectPart'
                            label='Subject'
                            defaultValue={template.SubjectPart}
                            {...register('SubjectPart', { required: true })}
                        />
                        {showHTML &&
                            <Textarea
                                id='HtmlPartInput'
                                onChange={htmlChanged()}
                                //onKeyPress={() => htmlChanged()}
                                onKeyUp={() => htmlChanged()}
                                name='HtmlPart'
                                label='HTML'
                                defaultValue={template.HtmlPart}
                                minH='400px'
                                {...register('HtmlPart', { required: true })}
                            />
                        }
                        {!showHTML &&
                            <Textarea
                                name='TextPart'
                                label='Text'
                                minH='200px'
                                defaultValue={template.TextPart}
                                {...register('TextPart', { required: true })}
                            />
                        }
                        <Wrap spacing={4}>
                            <Button colorScheme='red' variant='outline' className='btn-8' onClick={() => deleteTemplate(template.TemplateName)}>Delete</Button>
                            <Button colorScheme='blue' variant='outline' className='btn-8' onClick={() => setShowHTML(!showHTML)}>{showHTML ? "Show Text" : "Show HTML"}</Button>
                            <Button colorScheme='blue' type="submit">Submit</Button>
                        </Wrap>

                    </Stack>


            
            }

        </form>


        <Box>
        <Heading size='smalltitle' mb={4}>Mockup</Heading>
        <Box  id='displayWrapper' p={12} boxShadow='md'>
            <Box id='display'>
            </Box>
        </Box>
        </Box>

    </SimpleGrid>

    )

}