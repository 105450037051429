import { Wrap, CheckboxGroup, Checkbox } from "@chakra-ui/react";

export default function CheckboxWrapper(props) {

    let defaults = [];

    function returnValue(val) {

        if (props.setValue != undefined) {
            props.setValue(props.name, val)
        }
    }

    return (

        <CheckboxGroup onChange={(e) => {console.log(e); returnValue(e);}} defaultValue={JSON.parse(props.options).defaultValues} >
            <Wrap>
                {JSON.parse(props.options).values.map((option, i) => {
                    return (
                        <Checkbox value={option}>
                            {option}
                        </Checkbox>
                    )
                })}
            </Wrap>
        </CheckboxGroup>
    )


}
