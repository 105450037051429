
import Costings from './Costings';
import CostingsOrganisations from './CostingsOrganisations'
import Hero from './Hero';
import Footer from '../layout/Footer';
import { useEffect } from 'react';
import useScript from '../../core/utils/hooks/useScript';

export default function Pricing() {

    useScript('https://js.stripe.com/v3/pricing-table.js');


    return (

        <>
        <stripe-pricing-table pricing-table-id="prctbl_1NHZtQCpM5K88EcGt0Vy1gzT"publishable-key="pk_live_51NEthXCpM5K88EcGcKDy1G0ZbxHvXWkh4XLIH2yvu4qctMwnFq3gxRvSSWYIHFflTlFpFijPqaeVLlfxpQdvsPf700fTdkCsix"></stripe-pricing-table>
        <Costings/>
        <CostingsOrganisations />
        <Footer />

        </>   
    )

}