import React, { useState, useEffect, useContext } from 'react';
import { Textarea, useToast, Tooltip, Menu, MenuButton, MenuList, SimpleGrid, Grid, Box, ButtonGroup, GridItem, Heading, Container, Input, Button, Text, Flex, Tag, TagLabel, TagLeftIcon, TagRightIcon, HStack, List, ListItem, ListIcon, Stack, Alert, AlertTitle, AlertIcon, AlertDescription, CloseButton, Circle, CircularProgress, Icon, Wrap, useColorModeValue as mode, Badge, Avatar, Progress, Link, Tbody, Thead, Th, Td, Tr, Table, IconButton, MenuItem, color, border } from '@chakra-ui/react';
import {MdAttachFile, MdAdd, MdAutoFixHigh,  MdFlag, MdInsertLink, MdWarning, MdArrowForward, MdOutlineModelTraining,MdOutlineVisibility, MdPhone, MdExpandCircleDown, MdHelpOutline, MdExpandLess, MdExpandMore, MdCheck, MdClose, MdOutlineCloudDone, MdModelTraining, MdKeyboardVoice, MdLabelOutline, MdFolderOpen, MdOutlineNewLabel, MdIosShare, MdLock, MdLockOpen, MdMail, MdContentCopy, MdInfoOutline } from "react-icons/md";
import LoadingCircle from '../../../static/layout/LoadingCircle';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import Modal from '../../../core/components/modal/Modal';
import InputWrapper from '../../../core/components/forms/inputs/InputWrapper';
import Card from '../components/Card';
import Conflicts from './Conflicts';
import Bids from './Bids';
import Source from '../stages/Source';
import Weighting from './Weighting';
import { adhdInitialNodes } from '../demo/ADHD';
import { set } from 'date-fns';
import { bg } from 'date-fns/locale';
import { H } from 'highlight.run';

import people from '../demo/People';

let sourceIcons = {
    'folder': MdFolderOpen,
    'website': MdInsertLink,
    'document': MdAttachFile,
}
let sourceIconsComponents = {
    'folder': <MdFolderOpen/>,
    'website': <MdInsertLink/>,
    'document': <MdAttachFile/>,
}


function Person(props) {

    return (
        <Modal onClose={props.reset} p={4}
            hideFooter={true}
            >
            <HStack py={4} mb={3}>
                <Avatar size='lg' name={props.name} 
                    src={people[props.name].url}
                    />
                <Stack spacing={0} pl={4}>
                    <Text>
                        {props.name}
                    </Text>
                    <Text color='subtle'>
                        {props.role}
                    </Text>
                    <Text color='subtle'>
                        {props.email}
                    </Text>
                </Stack>
            </HStack>
        </Modal>
    )
}



function Node(props) {


    console.log("STAGE STEP", props.step);  

    const [step, setStep] = useState(props.step);
    const [node, setNode] = useState(props.node);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [open, setOpen] = useState(true); 
    const [showDescription, setShowDescription] = useState(false);



/*

                {step.locked !== null &&
                    <Icon as={MdCheck} color='green' boxSize='20px' mb={2} position='absolute' left={4}/>
                }
                {(step.locked === null || step.locked === undefined) &&
                    <>
                        {(step.value === step.aiValue) ?
                            <Circle size='20px' border='2px solid' borderColor='red.500' bg='white' color='white' position='absolute' left={4} mb={2}/>
                            :
                            <Circle size='20px' border='2px solid' borderColor='yellow.500' bg='white' color='white' position='absolute' left={4} mb={2}
                                overflow='hidden'
                                >
                                <Box position='absolute' top={0} left={0} w='50%' h='100%' bg='yellow.500'/>
                            </Circle>
                        }
                    </>
                }
*/


    return (

        <Box>
        {loading ? <LoadingCircle /> : 
        <Box key={props.index}>
            <Flex onClick={() => setOpen(!open)}  position='relative' pb={4} 
                justifyContent='space-between'

                >
                
                <Heading fontSize='lg' lineHeight='1em'>
                    
                    {step.label}
                    <Tooltip label={step.description} aria-label={step.description} placement='right' hasArrow>

                    <IconButton
                        isRound={true}
                        variant='ghost'
                        colorScheme='gray'
                        _hover={{color: 'gray.500'}}
                        fontSize='1em'
                        p={0}
                        h='12px'
                        mt='-1px'
                        onClick={(e) => { e.stopPropagation(); setShowDescription(!showDescription); }}
                        icon={<MdInfoOutline />}
                        />
                    </Tooltip>
                </Heading>
                
                <Tag colorScheme='' variant='subtle' borderRadius='full' p={0} px={0}
                    position='absolute'
                    right={0}
                    top={0}
                    _hover={{color: 'blue.500', bg: 'blue.50'}}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setPerson({
                            name: step.assigned,
                            role: people[step.assigned].role,
                            email: people[step.assigned].email,
                        });
                    }}
                    cursor='pointer'

                    >
                   
                    <TagLabel px={2} pr={2} fontSize='lg'>{step.assigned}</TagLabel>
                    <Avatar
                    size='sm'
                    name={step.assigned}
                    src={people[step.assigned].url}
                    />
                </Tag>
            </Flex>

        {open &&
            <Box mb={8}>
                {step.locked === null &&
                <>

                <Flex justifyContent='space-between' display='none'>
                    <Wrap mb={4}>

                        <Text fontSize='lg'>Sources:</Text>
                        {step.sources ?
                            step.sources.map((source, index) => {
                                return (
                                    <Button size='xs' key={index} variant='outline' 
                                        leftIcon={sourceIconsComponents[source.type]}
                                        _hover={{color: 'blue.500', borderColor: 'blue.500'}}
                                        _active={{bg: 'blue.500', color: 'white'}}
                                        >
                                        {source.name}
                                    </Button>
                                )
                            })
                            :
                            <Tag size='lg' order='1'>
                                None
                            </Tag>
                        
                        }
                        <IconButton icon={<MdAdd />} size='lg' colorScheme='gray' variant='outline' 
                                        _hover={{color: 'blue.500', borderColor: 'blue.500'}}
                            _active={{bg: 'blue.500', color: 'white'}}
                            />
                    </Wrap>
                </Flex>
                    </>
                }
                <Box position='relative' mb={4} pt={0} role='group'>
                    {step.locked ?
                    <Stack spacing={4} pt={4}>
                        <Text fontSize='lg'>
                            {step.value}
                        </Text>
                        <Wrap pt={0}>
                            <Text color='subtle' fontSize='lg' >
                                Submitted by {step.assigned} on <Moment format='DD/MM/YYYY'>{step.locked.date}</Moment>.
                            </Text>
                            <Text color='subtle' cursor='pointer' fontSize='lg'  _hover={{color: 'blue.500'}}
                                onClick={() => {
                                    props.setSources({
                                        sources: step.sources,
                                    });
                                }}
                                >
                                {step.sources && step.sources.length} Sources.
                            </Text>
                            <Text color='subtle' cursor='pointer' fontSize='lg' _hover={{color: 'blue.500'}}
                                onClick={() => {
                                    setStep({
                                        ...step,
                                        locked: null,
                                    });
                                }}
                                >
                                Re-open.
                            </Text>
                        </Wrap>
                    </Stack>
                    :
                    <>
                    <Box position='relative' mb={0} pt={4} role='group'>
                    <InputWrapper type='textarea' defaultValue={step.value}
                        mb={4}
                        mt={2}
                        setVal={(e) => {  
                            setStep({
                                ...step,
                                value: e.target.value,
                            });
                        }}
                        _hover={{
                            bg: 'none',
                            borderColor: 'blue.500',
                        }}
                        _groupHover={{borderColor: 'blue.500'}}
                        /*
                        _groupHover={{borderColor: 'blue.500'}}
                        _groupFocus={{borderColor: 'blue.500'}}
                        _hover={{bg: 'none', color: 'blue.500'}}
                        _focus={{bg: 'none', color: 'blue.500',
                            border: '1px solid blue.500',
                            borderColor: 'transparent',
                            outline: 'none',
                            boxShadow: 'none',
                        }}
                        border='none !important'

                        px={0}
                        py={2}
                        pb={4}
                        pr={4}
                        mt={-2}
                        */
                       minH='6em'
                        transition='0.5s all'
                        fontSize='lg'
                        />

                    <IconButton icon={<MdAutoFixHigh/>} 
                        isRound={true}
                        colorScheme='gray'
                        size='sm' position='absolute' variant='outline' right={4} bottom={4} 
                        _groupHover={{borderColor: 'blue.500', color: 'blue.500', boxShadow: 'sm'}}
                        _groupFocus={{borderColor: 'blue.500', color: 'blue.500', boxShadow: 'sm'}}
                        _hover={{color: 'invert !important', bg: 'blue.500 !important', borderColor: 'blue.500',
                            boxShadow: 'md'

                        }}
                        zIndex='1'
                        onClick={() => {
                            props.setGenerateWarning(true);
                        }}
                        display='none'
                        />

                        </Box>
                        <Flex justifyContent='space-between' alignItems='center' pb={4} pt={2}>
                            <Wrap pt={0} >
                                {step.aiValue === step.value ?
                                    <Text color='subtle' fontSize='lg'>
                                        AI generated response.
                                    </Text>
                                    :
                                    <Text color='subtle' fontSize='lg'>
                                        Updated by {step.assigned} on <Moment format='DD/MM/YYYY'>{step.updatedAt}</Moment>.
                                    </Text>
                                }
                            </Wrap>
                            <Box>

                            </Box>
                            <ButtonGroup size='md' variant='outline' colorScheme='blue'>
                                <Button variant='ghost'
                                    colorScheme='gray'
                                    _groupHover={{color: 'blue.500', borderColor: 'blue.500'}}
                                    _groupFocus={{color: 'blue.500', borderColor: 'blue.500'}}
                                    onClick={() => {
                                        props.setSources({
                                            sources: step.sources,
                                        });
                                    }}
                                    _hover={{
                                        bg: 'blue.50',
                                    }}
                                    >
                                    {step.sources && step.sources.length + ' Sources'}
                                </Button>

                    <IconButton icon={<MdAutoFixHigh/>} 
                        isRound={true}
                        colorScheme='gray'
                        variant='outline'
                        _groupHover={{borderColor: 'blue.500', color: 'blue.500', boxShadow: 'sm'}}
                        _groupFocus={{borderColor: 'blue.500', color: 'blue.500', boxShadow: 'sm'}}
                        _hover={{color: 'invert !important', bg: 'blue.500 !important', borderColor: 'blue.500',
                            boxShadow: 'md'

                        }}
                        
                        zIndex='1'
                        onClick={() => {
                            props.setGenerateWarning(true);
                        }}
                        />

                                <Button colorScheme='gray' variant='outline'
                                    _groupHover={{color: 'blue.500', borderColor: 'blue.500'}}
                                    _groupFocus={{color: 'blue.500', borderColor: 'blue.500'}}
                                    _hover={{
                                        color: 'white !important',
                                        bg: 'blue.500',
                                    }}
                                    onClick={() => {
                                        setStep({
                                            ...step,
                                            locked: {
                                                date: new Date(),
                                            },
                                        });
                                    }}
                                    >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </Flex>

                        </>
                    }
                </Box>
            </Box>

        }
    </Box>
}
</Box>

    )

}



export default function Complaint(props) {

    let toast = useToast();

    let navigate = useNavigate();

    
    const [item, setItem] = useState(null);
    const [node, setNode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [insights, setInsights] = useState(null);
    const [detailTab, setDetailTab] = useState('people');
    const [assigned, setAssigned] = useState([]);
    const [filter, setFilter] = useState(false);
    const [showComplete, setShowComplete] = useState(true);
    const [person, setPerson] = useState(null);
    const [sources, setSources] = useState(null);
    const [allSources, setAllSources] = useState([]);
    const [generateWarning, setGenerateWarning] = useState(false);

    let colorOptions = {
        'APOLOGY': 'red',
        'ADVICE': 'orange',
        'INFORMATION': 'yellow',
        'EXPLANATION': 'green',
        'COMPLIMENT': 'green',
        'OTHER': 'blue',
        'LEARNING': 'purple',
        'COMPLAINT': 'red',
        'OPEN': 'orange',
        'CLOSED': 'green',
        'PENDING': 'yellow',
        'DRAFT': 'orange',
        'SENT': 'green',
        'RECEIVED': 'yellow',
        'APPROVAL': 'yellow',
        'LEGAL': 'purple',
        'PALS': 'purple',
        'INVESTIGATION': 'red',
        'CLINICAL': 'red',
        'SIGN OFF': 'green',
    }

    let insightsArray = {};



    function getItem() {


        if (props.stage === null) {
            return;
        }

        setNode(props.stage);
        setItem(props.stage.data);
        //setAssigned(ClinicalDemoData.steps.map(step => step.assigned).filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i));
        
        // Loop through steps
        // Add all sources
        let tmpSources = [];
        let tmpAssigned = [];   
        props.stage.data.steps && props.stage.data.steps.forEach(step => {
            if (step.sources) {
                step.sources.forEach(source => {
                    tmpSources.push(source);
                })
            }
            tmpAssigned.push(step.assigned);
        });

        // Check unique
        let uniqueSources = tmpSources.filter((v, i, a) => a.findIndex(t => (t === v)) === i);

        console.log("Unique sources", uniqueSources);
        setAllSources(uniqueSources);
        
        tmpAssigned = tmpAssigned.filter((v, i, a) => a.findIndex(t => (t === v)) === i);
        setAssigned(tmpAssigned);

        // Loop
        
        setLoading(false);
        /*
        let resp = API.getItems({url, config})
        .then(response => {

            setItem({});
            setLoading(false);

        })
        .catch(error => {
            setItem(ClinicalDemoData);
            setAssigned(ClinicalDemoData.steps.map(step => step.assigned).filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i));
            
            toast({
                title: "Error loading item",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.log(error);
            setError("Error loading item");
            setLoading(false);
        });
        */

    }
    
    useEffect(() => {
        getItem();
    }, []);



    let names = [
        'Oliver Trampleasure',
        'Ali Jawad',
        'Sarah Smith',
        'John Smith',
        'Jane Smith',
    ]




    return (


                <GridItem colSpan={{ base: 12, md: 12 }} mb={10} spacing={0} pt={0}>


                    {loading ? <LoadingCircle /> : null}

                    {person && <Person {...person} reset={() => setPerson(null)} />}

                    {sources && 
                        <Modal onClose={() => setSources(null)} bodyPadding={0}>
                            <Source {...sources} />
                        </Modal>
                    }


                    {generateWarning &&
                        <Modal onClose={() => setGenerateWarning(false)} p={4}
                            hideClose={true}
                            footer={
                                <ButtonGroup size='sm'>
                                    <Button colorScheme='gray' onClick={() => {
                                        setGenerateWarning(false);
                                    }
                                    }
                                    variant='outline'
                                    colorScheme='red'
                                    >
                                        Cancel
                                    </Button>
                                    <Button colorScheme='blue' onClick={() => {
                                        setGenerateWarning(false);
                                    }
                                    }>
                                        Generate
                                    </Button>

                                </ButtonGroup>
                            }
                            >
                            <Box pb={4}>
                                <Heading size='sectiontitle' fontSize='32px' mb={4} pt={4}>
                                    Generate Response
                                </Heading>
                                <Text fontSize='md'>
                                    Are you sure you want to generate this response?
                                </Text>
                                <Text fontSize='md'>
                                    This will replace the current response.
                                </Text>
                            </Box>
                        </Modal>
                    
                    }

                    {item &&

                        <Box pt={0} >



                            {node.id === 'bids' &&  
                                    
                                <Bids />
                            }

                            {node.id === 'conflictsOfInterest' &&
   
                                <Conflicts />

                            }
                                

                            {['bids', 'conflictsOfInterest'].indexOf(node.id) === -1 &&

                            <SimpleGrid columns={{base: 1, md: 3}} spacing={10} pt={0}>


                                <GridItem colSpan={{base: 0, md: 2}} >

                                <Card mb={8}>
                                      


                                    <Flex pb={10} justifyContent='space-between' alignItems='center'>
                                            <Heading size='sm' color='blue.500'>
                                                Tasks
                                            </Heading>
                                            <Box>
                                            {node.id !== 'weighting' &&
                                            <>
                                            <Menu >
                                                <MenuButton as={Button} rightIcon={<MdExpandMore/>} 
                                                    variant={filter || !showComplete ? 'outline' : 'ghost'}
                                                    colorScheme={filter || !showComplete ? 'blue' : 'gray'}
                                                >
                                                    {filter && !showComplete && 'Your outstanding tasks'}
                                                    {filter && showComplete && 'Your tasks'}
                                                    {!filter && showComplete  && 'All tasks'}
                                                    {!filter && !showComplete && 'All outstanding tasks'}
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem onClick={() => setFilter(!filter)}>
                                                        {filter ? 'Show all' : 'Filter your tasks only'}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => setShowComplete(!showComplete)}>
                                                        {showComplete ? 'Hide completed' : 'Show completed'}
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>

                                            <Button ml={4} colorScheme='blue' size='md' variant='outline' onClick={() => setSources({sources: allSources})}>
                                                Add
                                            </Button>
                                            </>
                                            }
                                            </Box>
                                        </Flex>


                                    {node.id === 'weighting' && 
                                    
                                        <Weighting />
                                    }



                                        <Stack spacing={10}>
                                    {item.steps && item.steps.map((step, index) => {

                                        if (filter && step.assigned !== 'Oliver Trampleasure') {
                                            return null;
                                        }

                                        if (!showComplete && step.locked !== null) {
                                            return null;
                                        }
                                        return (

                                            <Node step={step} index={index} key={index} 
                                                setPerson={setPerson}
                                                resetPerson={() => setPerson(null)}
                                                setSources={setSources}
                                                setGenerateWarning={setGenerateWarning}
                                                />
                                        )


                                    })}

                                    {node.id !== 'weighting' && (!item.steps || item.steps.length === 0 )&&
                                    
                                        <Box>
                                            <Text>
                                                No tasks assigned.
                                            </Text>
                                        </Box>
                                    }
                                    </Stack>

                                </Card>

                                </GridItem>


                                <GridItem >

                                <Stack spacing={8}>
                                    
                                    <Card >

                                        <Input defaultValue={node.data.label} 
                                            placeholder='Stage name'
                                            border='none'
                                            p={0}
                                            fontSize='3xl'
                                            mb={10}
                                            color='black'
                                            borderBottom='1px solid white'
                                            borderRadius='none'
                                            onFocus={{
                                            border: 'none',
                                            bg: 'none',
                                            borderBottomColor: 'blue.500',

                                            }}
                                            onHover={{
                                            bg: 'none !important',
                                            }}
                                            onChange={(e) => {
                                                props.updateNodeData(node.id, 'label', e.target.value);
                                            
                                            }} />


                                        <Text color='subtle' fontSize='md'
                                            display='none'
                                            >
                                            {node.parentId ?
                                                props.nodes.find(n => n.id === node.parentId).data.label
                                                :
                                                'No Parent'
                                            }
                                        </Text>

                                        

                                        <InputWrapper
                                            type='textarea'
                                            placeholder='Description of this stage'
                                            defaultValue={node.data.description} 
                                            border='none'
                                            p={0}
                                            fontSize='md'
                                            mb={2}
                                            borderBottom='1px solid white'
                                            borderRadius='none'
                                            onFocus={{
                                            border: 'none',
                                            bg: 'none',
                                            borderBottomColor: 'blue.500',

                                            }}
                                            onHover={{
                                            bg: 'none !important',
                                            }}
                                            onChange={(e) => {
                                                props.updateNodeData(node.id, 'description', e.target.value);
                                            }} 
                                            resize='none'
                                            />


                                        <Wrap display='none' >
                                            <Tag colorScheme='red' variant='subtle' w='fit-content' mb={2}>
                                                Due in {moment(item.due).fromNow()}
                                            </Tag>
                                                <Tag>
                                                    <Text pr={1}>
                                                        Start:
                                                    </Text>
                                                    <Moment format='DD/MM/YYYY'>{item.start}</Moment>
                                                </Tag>
                                                <Tag>
                                                    <Text pr={1}>
                                                        Due:
                                                    </Text>
                                                    <Moment format='DD/MM/YYYY'>{item.due}</Moment>
                                                </Tag>
                                        </Wrap>


                                    </Card>
                                    <Card pb={6}>

                                        <Flex pb={10} justifyContent='space-between' alignItems='center'>
                                            <Heading size='sm' >
                                                Sources
                                            </Heading>
                                            <Button colorScheme='blue' size='md' variant='outline' onClick={() => setSources({sources: allSources})}>
                                                Add
                                            </Button>
                                        </Flex>


                                        
                                        <Box >
                                            <Stack>
                                                {allSources.map((source, index) => {



                                                    return (
                                                        <Box pb={4} role='group' cursor='pointer'>
                                                            <HStack>
                                                                <Icon as={sourceIcons[source.type]

                                                                    ? sourceIcons[source.type]
                                                                    : MdInsertLink
                                                                } 
                                                                _groupHover={{
                                                                    color: 'blue.500'
                                                                }}
                                                                boxSize='20px' />
                                                                <Text
                                                                    _groupHover={{
                                                                        color: 'blue.500'
                                                                    }}
                                                                    >
                                                                    {source.name ? source.name : source}
                                                                </Text>
                                                            </HStack>
                                                        </Box>
                                                    )

                                                })}


                                                {allSources.length === 0 &&   
                                                <Box pb={8}>
                                                    <Text>
                                                        No sources assigned.
                                                    </Text>
                                                </Box>
                                                }

                                            </Stack>
                                        </Box>


                                    </Card>


                                    
                                    <Card mb={8} pb={2}>

                                        <Flex pb={10} justifyContent='space-between' alignItems='center'>
                                            <Heading size='sm'>
                                                People
                                            </Heading>
                                            <Button colorScheme='blue' variant='outline' onClick={() => setSources({sources: allSources})}>
                                                Add
                                            </Button>
                                        </Flex>


                                            <Box borderTop='1px solid whitesmoke'>

                                                {assigned.map((person, index) => {

                                                    return (

                                                        <Box pb={8}
                                                            onClick={() => setPerson(person)}
                                                            _hover={{ cursor: 'pointer'}}
                                                            role='group'
                                                            >
                                                            <HStack>
                                                            <Avatar
                                                                size='md'
                                                                name={person}
                                                                mr={1}
                                                                src={people[person].url}
                                                                />
                                                                <Stack spacing={0}>
                                                                    <Text mb={0} color='black' fontWeight='600'
                                                                        _groupHover={{
                                                                            color: 'blue.500'
                                                                        }}
                                                                        >
                                                                        {people[person].name}
                                                                    </Text>
                                                                    <Text 
                                                                        _groupHover={{
                                                                            color: 'blue.500'
                                                                        }}
                                                                        >
                                                                        {people[person].role}
                                                                    </Text>
                                                                </Stack>

                                                            </HStack>   
                                                        </Box>

                                                    )

                                                })}

                                                {assigned.length === 0 &&
                                                       <Box pb={8}>
                                                       <Text>
                                                           No people assigned.
                                                       </Text>
                                                   </Box>
                                                }

                                            </Box>



                                    </Card>


                                    </Stack>

                                </GridItem>


                            </SimpleGrid>

                            }

                        </Box>
                        

                    }

                </GridItem>
    )
}