import React from 'react';
import { useState} from 'react';
import { useEffect } from "react";
import { 
    Wrap,
    ButtonGroup,
    Select,
    Divider,
    Link,
    FormControl,
    FormLabel,
    Tooltip,
    FormErrorMessage,
    FormHelperText,
    Radio,
    RadioGroup,
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    ListItem,
    OrderedList,
    UnorderedList,
    SliderMark,
    Editable,
    EditableInput,
    EditableTextarea,
    Tag, 
    EditablePreview, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Spinner, chakra, useCheckboxGroup, useCheckbox, GridItem, Checkbox, Box, Heading, Text, Button, Input, Stack, useColorModeValue, Center, Image, Flex, Spacer, useToast, CheckboxGroup, TagCloseButton, InputGroup, TagLeftIcon, Grid, SimpleGrid, InputLeftAddon} from '@chakra-ui/react';
import API from '../../core/utils/API';
import { useForm, useController, Controller } from "react-hook-form";
import Splash from '../../static/layout/Splash';
import CreatableSelect from 'react-select/creatable';
import Specialties from './GeneralPractice';
import Loading from '../../static/layout/Loading';
import { MdCheck, MdClose, MdContentCopy } from 'react-icons/md';
import Layout from '../../core/layout/Layout';
import { useNavigate } from 'react-router-dom';
import e from 'cors';
import { set } from 'date-fns';

function CheckBoxButtons(props) {

    return (
        <Box>
            {props.data.values &&
                <Wrap spacing={2}>
                    {props.data.values.map((option, index) => {

                        return (
                            <Button colorScheme='blue' variant='outline' className='btn-8' onClick={() => {console.log("clicked", props.data.label, option.valueLabel); props.setCheckboxButtonValue(props.data.valueLabel, option.label, index);}}>{option.label}</Button>
                        )
                    })}
                </Wrap>
            }
        </Box>
    )
}


function CopyeableText(props) {

    const toast = useToast();

    const generateToast = () => {
        toast({
            title: "Copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
        })
    }

    return (
        <Box cursor='pointer' _hover={{color: 'blue.500'}}
            onClick={() => {
                navigator.clipboard.writeText(props.text); 
                generateToast();
            }}>
            <Heading size='label' mb='0.5em' {...props.titleProps}>
                {props.title}
                <Icon ml='0.5em' 
                    boxSize='12px' 
                    opacity='0.5' 
                    as={MdContentCopy} color='black' w={4} h={4} />
            </Heading>
            <Text>
                {props.text} 
            </Text>
        </Box>
    )
}


function DesignBox(props) {

    return (

        <Box className='hover-only' p='2px' borderRadius="lg" overflow="hidden" {...props}>
            <Box position='relative' p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" bg='white'>

                {props.children}

            </Box>
        </Box>


    )
}

function ListWithReflection(props) {

    console.log(props.title, props.array);

    return (

        <Stack spacing={2}>
            {props.array.map((el) => {
                return (

                    <CopyeableText 
                        title={el.title}
                        titleProps={{size: 'label', mb: '4px', color: 'blue.500'}}
                        text={el.reflection}
                        />

                    )
                }
            )}    
        </Stack>

    )
}

export default function Reflection(props) {


    // Import JSON data from ./GeneralPractice.js
    const [specialties, setSpecialties] = useState(Specialties);
    console.log(specialties.array);
    console.log(Specialties);

    const [tags, setTags] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const { setValue, getValues, control, register, handleSubmit, watch, formState: { errors } } = useForm();
    const [apiResponse, setApiResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [data, setData] = useState({});
    const [json, setJson] = useState({});
    const [showOther, setShowOther] = useState(false);

    const [questionObject, setQuestionObject] = useState(Specialties);

    const reset = () => {

        setStep(1);
        setTags([]);
        setKeywords([]);
        setQuestionObject(Specialties);
        setApiResponse("");

    }

    const toast = useToast();

    const onSubmit = async (data = {}) => {

        setLoading(true);

        data['tree'] = tags;
        data['keywords'] = keywords;

        console.log(data);

        const result = await API.postItems({timeout: 40, url: "generate/reflection", data}).then(function (response) {
            console.log(response);

            // Extract JSON data from response
            // there is other text before and after the json object
            // so we need to extract it
            let json = response.data.reflection;
            json = json.substring(json.indexOf("{"), json.lastIndexOf("}") + 1);
            console.log("PREPARSE",json);

            json = JSON.parse(json);
            console.log(json);

            // Set JSON data to state
            setJson(json);

            setApiResponse(response.data.reflection);
        })
        setLoading(false);
    }

    return (

        <>

        {loading &&
            
            <Loading 
                message='Generating a draft reflection'
                show='true'
                />
        
        }

        {!apiResponse && !loading && 

            <Splash
            
                maxW={'600px'}
                p={4}
                >

                <Box p={4} >


                    <Stack spacing={4}>


                        { step === 0 &&

                            <Text>
                                This tool will generate a draft reflection on a consultation in General Practice.
                            </Text>
                        
                        }

                        { step === 1 && questionObject !== undefined &&

                            <FormControl>

                                { !showOther ?

                                    <>
                                    <Heading size='smallertitle' mb='1em'>What {questionObject.children[0].type.toLowerCase()} was covered?</Heading>

                                    <Wrap key={questionObject.type+questionObject.children.length}>

                                        {questionObject.children.sort((a, b) => a.title.localeCompare(b.title)).map((option, index) => {
                                                
                                            return (
                                                <Button colorScheme='blue' variant='outline'
                                                    size='xs'
                                                    onClick={() => {
                                                        let key = option.type;
                                                        let value = option.title;
                                                        let obj = {
                                                            key: key,
                                                            value: value
                                                        }
                                                        let newTags = tags;
                                                        newTags.push(obj);
                                                        setTags(newTags);
                                                        if (option.children === undefined || option.children === null) {
                                                            setStep(step+1);
                                                        } else {
                                                            setQuestionObject(option);
                                                        }
                                                        }}
                                                        >
                                                    {option.title}
                                                </Button>
                                            )
                                        })}

                                        <Button colorScheme='' variant='outline'
                                            size='xs'
                                            onClick={() => {setShowOther(true);}}
                                            >
                                            Other
                                        </Button>

                                    </Wrap>

                                    </>

                                    :

                                    <Wrap spacing={4} mt='1em'>
                                        <Heading size='smallertitle' mb='1em'>What specialty and topic was covered?</Heading>

                                        <InputGroup>
                                            <InputLeftAddon children='Specialty' />
                                            <Input placeholder='' id='Specialty' />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputLeftAddon children='Condition' />
                                            <Input placeholder='' id='Condition'  />
                                        </InputGroup>
                                        <Button variant='outline'
                                            onClick={() => {
                                                setShowOther(false);
                                            }}
                                            >
                                            Back
                                        </Button>

                                        <Button colorScheme='blue' variant='solid'
                                            onClick={() => {
                                                if (document.getElementById('Specialty').value === '' || document.getElementById('Condition').value === '') {
                                                    // Generate toast
                                                    toast({
                                                        title: "Please enter a specialty and condition",
                                                        status: "error",
                                                        duration: 3000,
                                                        isClosable: true,
                                                    })
                                                    return;
                                                }
                                                let obj = {
                                                    key: 'Specialty',
                                                    value: document.getElementById('Specialty').value
                                                }
                                                let obj2 = {
                                                    key: 'Condition',
                                                    value: document.getElementById('Condition').value
                                                }
                                                let newTags = tags;
                                                newTags.push(obj);
                                                newTags.push(obj2);
                                                setTags(newTags);
                                                setStep(step+1);
                                            }}
                                            >
                                            Next
                                        </Button>
                                    </Wrap>

                                }


                            </FormControl>

                        }
                

                        {step === 2 &&

                            <FormControl>
                                <Heading size='smallertitle' mb='1em'>What <Tooltip label="Event specific which will be included to personalise the reflection" aria-label='A tooltip'><Text as='span' color='blue.500' >keywords or phrases</Text></Tooltip> should be included?</Heading>
                                <CreatableSelect 
                                    isMulti 
                                    placeholder='Keywords'
                                    onChange={(val) => {
                                        let newKeywords = [];
                                        val.map((el) => {
                                            newKeywords.push(el.value);
                                        })
                                        setKeywords(newKeywords);
                                    }}
                                    />
                                <FormHelperText></FormHelperText>
                            </FormControl>

                        }


                    </Stack>
            
                    <ButtonGroup variant='outline' spacing={2} mt={4}>

                        { step === 0 &&
                            
                            <Button variant='outline' colorScheme='blue' onClick={() => {setStep(step+1);}} variant='outline' colorScheme='blue'>
                                Start
                            </Button>
                        
                        }
                        {step !== 3 && tags !== undefined && tags.length > 0 &&
                            <Button variant='outline' onClick={() => reset()} variant='outline' colorScheme='red'>
                                Reset
                            </Button>
                        }
                        

                        { step === 2 &&
                            <Button variant='outline' colorScheme='blue' onClick={() => {onSubmit(); setStep(step+1);}} variant='outline' colorScheme='blue'>
                                Generate
                            </Button>
                        }



                    </ButtonGroup>

                </Box>  

            </Splash>

        }

        {apiResponse &&
        
            <Layout
                title='Reflection'
                actionContent={
                    <>
                        <Button variant='outline' colorScheme='blue' onClick={() => {reset()}}>New Reflection</Button>
                    </>
                }
                >

                <GridItem colSpan={6} h='0' pb='72px'>

                    <Stack>

                        <Heading size='smalltitle' mb='16px'>
                            {json.topic}
                        </Heading>
                        <Wrap spacing={2} pb='24px'>
                                    {tags.map((tag, index) => {
                                        return (
                                            <Tag size='sm' variant='outline' colorScheme='blue' key={tag.key+tag.value}>
                                                <Text mr='4px' as='span' color='blue.500' fontWeight='bold'>{tag.key + ": "}</Text>{" " + tag.value}
                                            </Tag>
                                        )
                                    })}
                                    
                                    {keywords.map((keyword, index) => {
                                        let found = false;
                                        if (JSON.stringify(json).includes(keyword)) {
                                            found = true;
                                        }
                                        return (
                                            <Tag size='sm' variant='outline' colorScheme={found ? 'green' : 'red'} key={keyword}>
                                                <TagLeftIcon boxSize="12px" as={found ? MdCheck : MdClose} />
                                                {keyword}
                                            </Tag>
                                        )
                                    })}
                            </Wrap>
                    
                    </Stack>


                    <SimpleGrid columns={6} spacing={4} pb='72px'>
                        <GridItem colSpan={2}  pb='72px'>
                            <DesignBox>
                                <Stack spacing={4}>

                                    <CopyeableText 
                                        title='Description'
                                        text={json.description}
                                        />

                                    <CopyeableText 
                                        title='Reflection'
                                        text={json.reflection}
                                        />

                                </Stack>
                            </DesignBox>
                        </GridItem>

                        <GridItem colSpan={4}  pb='72px'>
                            <Stack spacing={4}>

                                <DesignBox>
                                    {json.themes !== undefined &&
                                            <Box>
                                                <Heading size='label' mb='1em'>Themes</Heading>
                                                {json.themes[0].reflection !== undefined ?
                                                    <ListWithReflection title='Themes' array={json.themes} />
                                                :
                                                    <UnorderedList>
                                                        {json.themes.map((theme, index) => {
                                                            return (
                                                                <ListItem>
                                                                    {theme.title}
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </UnorderedList>
                                                }
                                            </Box>
                                    }
                                </DesignBox>
                                <DesignBox>
                                    {json.capabilities !== undefined &&
                                        <Box>
                                            <Heading size='label' mb='1em'>Capabilities</Heading>
                                            {json.capabilities[0].reflection !== undefined ?
                                                <ListWithReflection title='Capabilities' array={json.capabilities} />
                                            :
                                                <UnorderedList>
                                                    {json.capabilities.map((capability, index) => {
                                                        return (
                                                            <ListItem>
                                                                {capability.title}
                                                            </ListItem>
                                                        )
                                                    })}
                                                </UnorderedList>
                                            }
                                        </Box>
                                    }
                                </DesignBox>
                                <DesignBox>
                                    {json.learningNeeds !== undefined &&
                                        <Box>
                                            <Heading size='label' mb='1em'>Learning Needs</Heading>
                                            {json.learningNeeds[0].reflection !== undefined ?
                                                <ListWithReflection title='Learning Needs' array={json.learningNeeds} />
                                            :
                                                <OrderedList>
                                                    {json.learningNeeds.map((learningNeed, index) => {
                                                        return (
                                                            <ListItem>
                                                                {learningNeed.title}
                                                            </ListItem>
                                                        )
                                                    })}
                                                </OrderedList>
                                            }
                                        </Box>
                                    }
                                </DesignBox>

                                </Stack>

                        </GridItem>

                    </SimpleGrid>

                </GridItem>


            </Layout>                            

        
        
        
        
        }

            
        {apiResponse && 
                    <Stack display='none' spacing={4}>

                        {json.topic !== undefined &&
                            <Heading size='smallertitle'>
                                {json.topic}
                            </Heading>
                        }

                        {tags !== undefined && tags.length > 0 &&
                            <Wrap spacing={2}>
                                {tags.map((tag, index) => {
                                    return (
                                        <Tag size='sm' variant='outline' colorScheme='blue' key={tag.key+tag.value}>
                                            {tag.key}: {tag.value}
                                        </Tag>
                                    )
                                })}
                            </Wrap>
                        }

                        {json.description !== undefined &&
                            <Box>
                                <Heading size='label'>Description</Heading>
                                <Editable defaultValue={json.description}>
                                    <EditablePreview whiteSpace='pre-line' />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                        }
                        {json.themes !== undefined &&
                            <Box>
                                <Heading size='label'>Themes</Heading>
                                {json.themes[0].reflection !== undefined ?
                                    <ListWithReflection title='Themes' array={json.themes} />
                                :
                                    <UnorderedList>
                                        {json.themes.map((theme, index) => {
                                            return (
                                                <ListItem>
                                                    {theme.title}
                                                </ListItem>
                                            )
                                        })}
                                    </UnorderedList>
                                }
                            </Box>
                        }
                        {json.capabilities !== undefined &&
                            <Box>
                                <Heading size='label'>Capabilities</Heading>
                                {json.capabilities[0].reflection !== undefined ?
                                    <ListWithReflection title='Capabilities' array={json.capabilities} />
                                :
                                    <UnorderedList>
                                        {json.capabilities.map((capability, index) => {
                                            return (
                                                <ListItem>
                                                    {capability.title}
                                                </ListItem>
                                            )
                                        })}
                                    </UnorderedList>
                                }
                            </Box>
                        }
                        {json.reflection !== undefined &&
                            <Box>
                                <Heading size='label'>Reflection</Heading>
                                <Editable defaultValue={json.reflection}>
                                    <EditablePreview whiteSpace='pre-line' />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                        }
                        {json.learningNeeds !== undefined &&
                            <Box>
                                <Heading size='label'>Learning Needs</Heading>
                                {json.learningNeeds[0].reflection !== undefined ?
                                    <ListWithReflection title='Learning Needs' array={json.learningNeeds} />
                                :
                                    <OrderedList>
                                        {json.learningNeeds.map((learningNeed, index) => {
                                            return (
                                                <ListItem>
                                                    {learningNeed.title}
                                                </ListItem>
                                            )
                                        })}
                                    </OrderedList>
                                }
                            </Box>
                        }

                        <Box display='none'>
                            <strong>Reflection:</strong>
                            <Editable defaultValue={apiResponse.trim()}>
                                <EditablePreview whiteSpace='pre-line' />
                                <EditableTextarea />
                            </Editable>
                        </Box>
                    </Stack>
                }      
            
        
        

        </>

    )
}
