const features = ['Artificial intelligence text detection', 'Natural language processing to extract key information', 'Automatic tagging and sorting', 'Unlimited document sharing']

export const products = [
  {
    name: 'Basic',
    price: 'Free',
    price_suffix: '',
    price_addendum: '',
    description: 'For your first year',
    features: ['Up to 15 pages', '5 shares', ...features],
  },
  {
    name: 'Premium',
    price: '99',
    price_suffix: 'p',
    price_addendum: '/month',
    deal: '33% discount when paid annually',
    description: 'For all your records',
    features: ['Unlimited pages', 'Unlimited sharing', ...features],
    isPopular: true,
  },
]